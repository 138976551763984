import React from "react";

const IconTop = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1126 0H2.88672V1.17188H4.49727V7.00129C4.49922 9.99406 6.92922 12.458 9.92168 12.4995C12.9963 12.542 15.502 10.0625 15.502 6.99762V1.17188H17.1126V0Z"
      fill="#D0D1E1"
    />
    <path
      d="M13.8078 17.4325C13.8078 16.7853 13.2832 16.2607 12.6359 16.2607H11.7172V13.4479C11.1599 13.5963 10.5848 13.6717 9.99844 13.6717C9.96727 13.6717 9.93551 13.6715 9.90426 13.671C9.3507 13.6634 8.80723 13.5881 8.27969 13.4465V16.2607H7.36094C6.71371 16.2607 6.18906 16.7853 6.18906 17.4325V18.8279H4.49609V19.9998H15.5008V18.8279H13.8078V17.4325Z"
      fill="#D0D1E1"
    />
    <path
      d="M16.6752 2.34375V3.51563H18.8291V6.15227C18.8291 7.61301 17.7545 8.82727 16.3543 9.04648C16.2944 9.23184 16.2267 9.41516 16.1503 9.59594C16.0546 9.82207 15.9469 10.0413 15.8281 10.2538H15.8994C18.161 10.2538 20.001 8.41387 20.001 6.15227V2.34375H16.6752Z"
      fill="#D0D1E1"
    />
    <path
      d="M3.65 9.04707C2.2482 8.82926 1.17188 7.61418 1.17188 6.15227V3.51563H3.32574V2.34375H0V6.15227C0 8.41387 1.83996 10.2538 4.10156 10.2538H4.17926C4.05562 10.0338 3.94395 9.80645 3.84523 9.57203C3.77238 9.39914 3.70762 9.22402 3.65 9.04707Z"
      fill="#D0D1E1"
    />
  </svg>
);

export default IconTop;
