import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "antd";
import { SimpleButton } from "components/Controls/Buttons";
import { IconClose } from "components/Svgs";
import { logOut } from "utils/helpers/userHelper";
import "../styles.scss";

const LogOutModal = (props) => {
  const { modalVisible, handleModalVisible } = props;

  const onLogOut = () => {
    handleModalVisible()
    logOut();
  };

  return (
    <Modal
      className="logout-modal"
      visible={modalVisible}
      footer={null}
      onCancel={handleModalVisible}
      closable={false}
    >
      <div className="logout-modal-container">
        <div className="logout-image-container">
          <div className="logout-image">
            <SimpleButton customClass="modal-close" onClick={handleModalVisible}>
              <IconClose />
            </SimpleButton>
          </div>
        </div>
        <div className="logout-content">
          <div className="logout-content__title">
            <FormattedMessage id="sideMenu.modal.logoutMessage" />
          </div>
          <div className="logout-content__description">
            <FormattedMessage id="sideMenu.modal.descriptionMessage" />
          </div>
          <div className="logout-content__buttons">
            <SimpleButton onClick={handleModalVisible} customClass="btn-default white-button">
              <FormattedMessage id="general.cancelMessage" />
            </SimpleButton>
            <SimpleButton onClick={onLogOut} customClass="btn-default">
              <FormattedMessage id="general.yesMessage" />
            </SimpleButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogOutModal;
