import React, { useState } from "react";
import { NumberInput } from "components/Controls/Inputs";
import { CustomSlider } from "components/Controls/Sliders";
import RangeHistogram from "components/DataDisplay/Histogram";
import { CRITERIA_MAP } from "constants/criteria";
import { normalizeMinMax, normalizeIdeal, parseNumberToInt, absValue} from "utils/helpers/criteriaHelper";
import { IconQuestion } from "components/Svgs";
import { Tooltip } from "antd";
import "../styles.scss";
import {FormattedMessage} from "react-intl";


const SliderRangeInputField = ({
  value = {},
  form,
  min,
  max,
  step,
  histogramSteps,
  kind,
  minMessage,
  idealMessage,
  maxMessage,
  maxInputValue,
  sliderDiff,
  dataKind
}) => {
  const [sliderValue, setSliderValue] = useState([
    value.min,
    value.ideal,
    value.max,
  ]);
  const [minValue, setMinValue] = useState(value.min);
  const [idealValue, setIdealValue] = useState(value.ideal);
  const [maxValue, setMaxValue] = useState(value.max);

  const getValue = inputValue => absValue(parseInt(inputValue)) || 0;

  const onChangeMinInput = (inputValue) => {
    const value = getValue(inputValue);
    let [min, ideal, max] = [value, idealValue, maxValue];
    form.setFieldsValue({
      range: { min, ideal, max },
    });
    [min, ideal, max] = normalizeMinMax(value, idealValue, maxValue, sliderDiff);
    setSliderValue([min, ideal, max]);
  };
  
  const onBlurMinInput = (inputValue) => {
    const value = getValue(inputValue);
    const [min, ideal, max] = normalizeMinMax(value, idealValue, maxValue, sliderDiff);
    overrideValues(min, ideal, max);
  };

  const onChangeIdealInput = (inputValue) => {
    const value = getValue(inputValue);
    let [min, ideal, max] = [minValue, value, maxValue];
    form.setFieldsValue({
      range: { min, ideal, max },
    });
    [min, ideal, max] = normalizeIdeal(minValue, value, maxValue, sliderDiff);
    setSliderValue([min, ideal, max]);
  };

  const onBlurIdealInput = (inputValue) => {
    const value = getValue(inputValue);
    const [min, ideal, max] = normalizeIdeal(minValue, value, maxValue, sliderDiff);
    overrideValues(min, ideal, max);
  };

  const onChangeMaxInput = (inputValue) => {
    const value = getValue(inputValue);
    let [min, ideal, max] = [minValue, idealValue, value]
    form.setFieldsValue({
      range: { min, ideal, max },
    });
    [min, ideal, max] = normalizeMinMax(minValue, idealValue, value, sliderDiff);
    setSliderValue([min, ideal, max]);
  };

  const onBlurMaxInput = (inputValue) => {
    const value = getValue(inputValue);
    const [min, ideal, max] = normalizeMinMax(minValue, idealValue, value, sliderDiff);
    overrideValues(min, ideal, max);
  };

  const overrideValues = (min, ideal, max) => {
    setSliderValue([min, ideal, max]);
    setMinValue(min);
    setIdealValue(ideal);
    setMaxValue(max);
    form.setFieldsValue({
      range: { min, ideal, max },
    });
  }

  const onChangeSlider = (_, value) => {
    if (value) {
      setSliderValue(value);
      setMinValue(value[0]);
      setIdealValue(value[1]);
      setMaxValue(value[2]);
      form.setFieldsValue({
        range: { min: value[0], ideal: value[1], max: value[2] },
      });
    }
  };

  const renderCustomInput = (message, currentValue, defaultValue, onBlur, onChange, tooltipMessageId) => (
    <>
      <div className="input-number__title">
        {message}
        {kind === CRITERIA_MAP.price && (
          <Tooltip title={<FormattedMessage id={tooltipMessageId} />} placement="rightTop" >
            <div className="input-number-icon">
              <IconQuestion />
            </div>
          </Tooltip>
        )}
      </div>
      <NumberInput
        addonBefore={message}
        value={currentValue}
        defaultValue={defaultValue}
        onBlur={event => onBlur(parseNumberToInt(event.target.value))}
        onChange={(value) => onChange(value)}
        maxInputValue={maxInputValue}
      />
    </>
  );

  return (
    <div className="select-range">
      <div className="select-range__header">
        <div className="select-range__header-description">
          <RangeHistogram
            dataKind={dataKind}
            kind={kind}
            steps={histogramSteps}
            minValue={value.min}
            maxValue={value.max}
            min={min}
            max={max}
          />
        </div>
      </div>
      <div className="select-range-field">
        <div>
          <CustomSlider
            range
            step={step}
            min={min}
            max={max}
            defaultValue={[value.min, value.ideal, value.max]}
            value={sliderValue}
            onChange={onChangeSlider}
          />
        </div>
        <div className="select-range-field__inputs">
          <div className="input-number-wrapper">
            {renderCustomInput(minMessage, value.min, value.min, onBlurMinInput, onChangeMinInput, "dashboard.form.priceTooltipMinMessage")}
          </div>
          <span className="dash">&ndash;</span>
          <div className="input-star">
            {renderCustomInput(idealMessage, value.ideal, value.ideal, onBlurIdealInput, onChangeIdealInput, "dashboard.form.priceTooltipIdealMessage")}
          </div>
          <span className="dash">&ndash;</span>
          <div className="input-number-wrapper">
            {renderCustomInput(maxMessage, value.max, value.max, onBlurMaxInput, onChangeMaxInput, "dashboard.form.priceTooltipMaxMessage")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderRangeInputField;
