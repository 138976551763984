import React from 'react';

const IconStarFilled = props => (
  <svg {...props} viewBox="0 0 14 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2441 12.2241C10.1513 12.2241 10.0586 12.2025 9.97399 12.1582L6.99957 10.6042L4.02516 12.1582C3.82799 12.2608 3.58999 12.2427 3.41207 12.1121C3.23241 11.9814 3.14316 11.7603 3.18049 11.5416L3.74749 8.25858L1.34416 5.93399C1.18374 5.77941 1.12657 5.54724 1.19482 5.33549C1.26249 5.12374 1.44566 4.96974 1.66557 4.93766L4.99116 4.45466L6.47691 1.46449C6.67407 1.06783 7.32507 1.06783 7.52224 1.46449L9.00799 4.45466L12.3336 4.93766C12.5535 4.96974 12.7367 5.12374 12.8043 5.33549C12.8726 5.54724 12.8154 5.77941 12.655 5.93399L10.2517 8.25858L10.8187 11.5416C10.856 11.7603 10.7667 11.9814 10.5871 12.1121C10.4862 12.1867 10.3654 12.2241 10.2441 12.2241Z"
      fill="currentColor"
    />
  </svg>
);

export default IconStarFilled;
