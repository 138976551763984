import React from "react";

const IconPublicTransitDetails = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6660_16744)">
    <path d="M2.19629 2.35416C2.19629 2.53753 2.31488 2.69984 2.48958 2.75557L2.51896 2.76494C3.28531 3.00937 3.80555 3.72137 3.80555 4.52572C3.80555 5.54647 4.633 6.37392 5.65374 6.37392H6.31084V1.93286H2.61762C2.38492 1.93286 2.19629 2.12149 2.19629 2.35416Z" fill="url(#paint0_linear_6660_16744)"/>
    <path d="M2.19629 8.20087C2.19629 8.38425 2.31488 8.54656 2.48958 8.60229L2.51896 8.61166C3.28531 8.85609 3.80555 9.56809 3.80555 10.3724C3.80555 11.3932 4.633 12.2206 5.65374 12.2206H6.31084V7.77954H2.61762C2.38492 7.77954 2.19629 7.96817 2.19629 8.20087Z" fill="url(#paint1_linear_6660_16744)"/>
    <path d="M2.19629 14.0475C2.19629 14.2309 2.31488 14.3932 2.48958 14.4489L2.51896 14.4583C3.28531 14.7027 3.80555 15.4147 3.80555 16.2191C3.80555 17.2398 4.633 18.0673 5.65374 18.0673H6.31084V13.6262H2.61762C2.38492 13.6262 2.19629 13.8148 2.19629 14.0475Z" fill="url(#paint2_linear_6660_16744)"/>
    <path d="M17.804 2.35416C17.804 2.53753 17.6854 2.69984 17.5107 2.75557L17.4813 2.76494C16.715 3.00937 16.1947 3.72137 16.1947 4.52572C16.1947 5.54647 15.3673 6.37392 14.3466 6.37392H13.6895V1.93286H17.3827C17.6154 1.93286 17.804 2.12149 17.804 2.35416Z" fill="url(#paint3_linear_6660_16744)"/>
    <path d="M17.804 8.20087C17.804 8.38425 17.6854 8.54656 17.5107 8.60229L17.4813 8.61166C16.715 8.85609 16.1947 9.56809 16.1947 10.3724C16.1947 11.3932 15.3673 12.2206 14.3466 12.2206H13.6895V7.77954H17.3827C17.6154 7.77954 17.804 7.96817 17.804 8.20087Z" fill="url(#paint4_linear_6660_16744)"/>
    <path d="M17.804 14.0475C17.804 14.2309 17.6854 14.3932 17.5107 14.4489L17.4813 14.4583C16.715 14.7027 16.1947 15.4147 16.1947 16.2191C16.1947 17.2398 15.3673 18.0673 14.3466 18.0673H13.6895V13.6262H17.3827C17.6154 13.6262 17.804 13.8148 17.804 14.0475Z" fill="url(#paint5_linear_6660_16744)"/>
    <path d="M16.3444 3.79759C16.2476 4.02297 16.1948 4.26983 16.1948 4.52575C16.1948 5.03618 15.9878 5.49802 15.6535 5.83238C15.3191 6.16677 14.8573 6.37371 14.3468 6.37371H13.6895V1.93262H14.4794L16.3444 3.79759Z" fill="url(#paint6_linear_6660_16744)"/>
    <path d="M16.3444 9.64431C16.2476 9.86968 16.1948 10.1165 16.1948 10.3725C16.1948 10.8829 15.9878 11.3447 15.6535 11.6791C15.3191 12.0135 14.8573 12.2204 14.3468 12.2204H13.6895V7.7793H14.4794L16.3444 9.64431Z" fill="url(#paint7_linear_6660_16744)"/>
    <path d="M16.3444 15.4912C16.2476 15.7166 16.1948 15.9635 16.1948 16.2194C16.1948 16.7298 15.9878 17.1917 15.6535 17.526C15.3191 17.8604 14.8573 18.0674 14.3468 18.0674H13.6895V13.6262H14.4794L16.3444 15.4912Z" fill="url(#paint8_linear_6660_16744)"/>
    <path d="M12.9909 20H7.00882C6.18686 20 5.52051 19.3336 5.52051 18.5117V1.48831C5.52055 0.666353 6.18686 0 7.00882 0H12.9909C13.8128 0 14.4792 0.666353 14.4792 1.48831V18.5117C14.4792 19.3336 13.8129 20 12.9909 20Z" fill="url(#paint9_linear_6660_16744)"/>
    <path d="M10.0004 6.13666C11.5723 6.13666 12.8466 4.86239 12.8466 3.2905C12.8466 1.7186 11.5723 0.444336 10.0004 0.444336C8.42856 0.444336 7.1543 1.7186 7.1543 3.2905C7.1543 4.86239 8.42856 6.13666 10.0004 6.13666Z" fill="url(#paint10_linear_6660_16744)"/>
    <path d="M14.4794 3.52143L12.1997 1.24174C11.6509 0.652644 10.8687 0.353271 10.0001 0.353271C8.58643 0.353271 7.47028 1.25951 7.14898 2.57417C7.10698 2.74602 7.12377 2.91292 7.26808 2.98868L14.4794 10.2V3.52143Z" fill="url(#paint11_linear_6660_16744)"/>
    <path d="M10.001 5.55612C11.2522 5.55612 12.2666 4.54177 12.2666 3.29051C12.2666 2.03925 11.2522 1.0249 10.001 1.0249C8.7497 1.0249 7.73535 2.03925 7.73535 3.29051C7.73535 4.54177 8.7497 5.55612 10.001 5.55612Z" fill="url(#paint12_linear_6660_16744)"/>
    <path d="M10.001 1.0249C8.7497 1.0249 7.73535 2.03926 7.73535 3.29051C7.73535 3.34835 7.73821 3.40549 7.74249 3.46227L9.82919 5.54898C9.88594 5.55326 9.94312 5.55612 10.001 5.55612C11.2522 5.55612 12.2666 4.54176 12.2666 3.29051C12.2666 2.03926 11.2522 1.0249 10.001 1.0249Z" fill="url(#paint13_linear_6660_16744)"/>
    <path d="M7.4407 3.03127C7.6096 3.03127 7.7596 2.91766 7.79948 2.75354C8.04062 1.76154 8.93434 1.02495 10.0005 1.02495C11.0667 1.02495 11.9604 1.76154 12.2015 2.75354C12.2414 2.9177 12.3914 3.03127 12.5603 3.03127C12.7999 3.03127 12.9779 2.80703 12.921 2.57429C12.5998 1.25962 11.4142 0.283936 10.0005 0.283936C8.58689 0.283936 7.40129 1.25962 7.07999 2.57429C7.02313 2.80703 7.20113 3.03127 7.4407 3.03127Z" fill="url(#paint14_linear_6660_16744)"/>
    <path d="M10.0004 12.5622C11.5723 12.5622 12.8466 11.2879 12.8466 9.71603C12.8466 8.14414 11.5723 6.86987 10.0004 6.86987C8.42856 6.86987 7.1543 8.14414 7.1543 9.71603C7.1543 11.2879 8.42856 12.5622 10.0004 12.5622Z" fill="url(#paint15_linear_6660_16744)"/>
    <path d="M14.4794 9.94697L12.1997 7.66728C11.6509 7.07818 10.8687 6.77881 10.0001 6.77881C8.58643 6.77881 7.47028 7.68504 7.14898 8.99971C7.10698 9.17155 7.12377 9.33846 7.26808 9.41422L14.4794 16.6256V9.94697Z" fill="url(#paint16_linear_6660_16744)"/>
    <path d="M10.001 11.9816C11.2522 11.9816 12.2666 10.9673 12.2666 9.71604C12.2666 8.46478 11.2522 7.45044 10.001 7.45044C8.7497 7.45044 7.73535 8.46478 7.73535 9.71604C7.73535 10.9673 8.7497 11.9816 10.001 11.9816Z" fill="url(#paint17_linear_6660_16744)"/>
    <path d="M10.001 7.45044C8.7497 7.45044 7.73535 8.46479 7.73535 9.71604C7.73535 9.77388 7.73821 9.83102 7.74249 9.8878L9.82919 11.9745C9.88594 11.9788 9.94312 11.9816 10.001 11.9816C11.2522 11.9816 12.2666 10.9673 12.2666 9.71604C12.2666 8.46479 11.2522 7.45044 10.001 7.45044Z" fill="url(#paint18_linear_6660_16744)"/>
    <path d="M7.4407 9.45681C7.6096 9.45681 7.7596 9.3432 7.79948 9.17908C8.04062 8.18708 8.93434 7.45049 10.0005 7.45049C11.0667 7.45049 11.9604 8.18708 12.2015 9.17908C12.2414 9.34324 12.3914 9.45681 12.5603 9.45681C12.7999 9.45681 12.9779 9.23257 12.921 8.99983C12.5998 7.68516 11.4142 6.70947 10.0005 6.70947C8.58689 6.70947 7.40129 7.68516 7.07999 8.99983C7.02313 9.23253 7.20113 9.45681 7.4407 9.45681Z" fill="url(#paint19_linear_6660_16744)"/>
    <path d="M10.0004 18.9875C11.5723 18.9875 12.8466 17.7132 12.8466 16.1413C12.8466 14.5694 11.5723 13.2952 10.0004 13.2952C8.42856 13.2952 7.1543 14.5694 7.1543 16.1413C7.1543 17.7132 8.42856 18.9875 10.0004 18.9875Z" fill="url(#paint20_linear_6660_16744)"/>
    <path d="M5.52148 18.407V18.5116C5.52148 19.3335 6.18784 19.9999 7.0098 19.9999H12.9919C13.8138 19.9999 14.4802 19.3335 14.4802 18.5116V18.407H5.52148Z" fill="url(#paint21_linear_6660_16744)"/>
    <path d="M14.4804 16.3723L12.2007 14.0926C11.6519 13.5035 10.8697 13.2041 10.0011 13.2041C8.58741 13.2041 7.47125 14.1103 7.14996 15.425C7.10796 15.5969 7.12474 15.7638 7.26906 15.8395L11.4293 19.9998H12.9921C13.814 19.9998 14.4804 19.3334 14.4804 18.5115V16.3723Z" fill="url(#paint22_linear_6660_16744)"/>
    <path d="M10.001 18.4069C11.2522 18.4069 12.2666 17.3926 12.2666 16.1413C12.2666 14.8901 11.2522 13.8757 10.001 13.8757C8.7497 13.8757 7.73535 14.8901 7.73535 16.1413C7.73535 17.3926 8.7497 18.4069 10.001 18.4069Z" fill="url(#paint23_linear_6660_16744)"/>
    <path d="M10.001 13.8757C8.7497 13.8757 7.73535 14.8901 7.73535 16.1413C7.73535 16.1992 7.73821 16.2563 7.74249 16.3131L9.82919 18.3998C9.88594 18.4041 9.94312 18.4069 10.001 18.4069C11.2522 18.4069 12.2666 17.3926 12.2666 16.1413C12.2666 14.8901 11.2522 13.8757 10.001 13.8757Z" fill="url(#paint24_linear_6660_16744)"/>
    <path d="M7.44075 15.8821C7.60965 15.8821 7.75965 15.7685 7.79953 15.6044C8.04067 14.6124 8.93439 13.8758 10.0005 13.8758C11.0667 13.8758 11.9605 14.6124 12.2016 15.6044C12.2414 15.7685 12.3914 15.8821 12.5603 15.8821C12.8 15.8821 12.9779 15.6579 12.921 15.4251C12.5998 14.1105 11.4142 13.1348 10.0005 13.1348C8.58686 13.1348 7.40126 14.1105 7.07997 15.4251C7.02318 15.6579 7.20118 15.8821 7.44075 15.8821Z" fill="url(#paint25_linear_6660_16744)"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_6660_16744" x1="4.06213" y1="2.14121" x2="5.65943" y2="4.36353" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6660_16744" x1="4.06213" y1="7.98793" x2="5.65943" y2="10.2102" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6660_16744" x1="4.06213" y1="13.8346" x2="5.65944" y2="16.0569" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint3_linear_6660_16744" x1="15.9382" y1="2.14121" x2="14.3409" y2="4.36352" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint4_linear_6660_16744" x1="15.9382" y1="7.98793" x2="14.3409" y2="10.2102" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint5_linear_6660_16744" x1="15.9382" y1="13.8346" x2="14.3408" y2="16.0569" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint6_linear_6660_16744" x1="16.5417" y1="4.5832" x2="13.7638" y2="4.09708" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint7_linear_6660_16744" x1="16.5417" y1="10.4299" x2="13.7638" y2="9.94376" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint8_linear_6660_16744" x1="16.5417" y1="16.2768" x2="13.7638" y2="15.7907" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint9_linear_6660_16744" x1="4.89412" y1="4.89423" x2="16.8057" y2="16.8058" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint10_linear_6660_16744" x1="12.5706" y1="5.86062" x2="5.47192" y2="-1.23799" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint11_linear_6660_16744" x1="12.3001" y1="6.78394" x2="9.39812" y2="-0.240651" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint12_linear_6660_16744" x1="8.79876" y1="2.08827" x2="11.6035" y2="4.89302" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FF7044"/>
    <stop offset="1" stopColor="#F82814"/>
    </linearGradient>
    <linearGradient id="paint13_linear_6660_16744" x1="10.2236" y1="3.60984" x2="9.01986" y2="0.970824" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F82814" stopOpacity="0"/>
    <stop offset="1" stopColor="#C0272D"/>
    </linearGradient>
    <linearGradient id="paint14_linear_6660_16744" x1="10.0005" y1="0.444367" x2="10.0005" y2="2.65617" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint15_linear_6660_16744" x1="12.5706" y1="12.2862" x2="5.47193" y2="5.18758" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint16_linear_6660_16744" x1="12.3001" y1="13.2095" x2="9.39812" y2="6.18493" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint17_linear_6660_16744" x1="8.79876" y1="8.51385" x2="11.6035" y2="11.3186" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint18_linear_6660_16744" x1="10.2236" y1="10.0354" x2="9.01987" y2="7.3964" gradientUnits="userSpaceOnUse">
    <stop stopColor="#E87264" stopOpacity="0"/>
    <stop offset="0.6519" stopColor="#F67150" stopOpacity="0.652"/>
    <stop offset="1" stopColor="#FF7044"/>
    </linearGradient>
    <linearGradient id="paint19_linear_6660_16744" x1="10.0005" y1="6.8699" x2="10.0005" y2="9.08167" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint20_linear_6660_16744" x1="12.5706" y1="18.7114" x2="5.47193" y2="11.6128" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint21_linear_6660_16744" x1="10.0008" y1="18.6742" x2="10.0008" y2="19.5797" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint22_linear_6660_16744" x1="12.1526" y1="19.1387" x2="8.54892" y2="10.7847" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint23_linear_6660_16744" x1="8.79876" y1="14.9391" x2="11.6035" y2="17.7438" gradientUnits="userSpaceOnUse">
    <stop stopColor="#BADA00"/>
    <stop offset="0.3706" stopColor="#B8D800"/>
    <stop offset="0.5625" stopColor="#B2D000"/>
    <stop offset="0.7133" stopColor="#A6C300"/>
    <stop offset="0.8428" stopColor="#97B000"/>
    <stop offset="0.9575" stopColor="#829700"/>
    <stop offset="1" stopColor="#798C00"/>
    </linearGradient>
    <linearGradient id="paint24_linear_6660_16744" x1="10.2275" y1="16.8525" x2="8.88488" y2="12.1301" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5A6313" stopOpacity="0"/>
    <stop offset="1" stopColor="#544F43"/>
    </linearGradient>
    <linearGradient id="paint25_linear_6660_16744" x1="10.0006" y1="13.2952" x2="10.0006" y2="15.507" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <clipPath id="clip0_6660_16744">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconPublicTransitDetails;
