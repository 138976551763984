import { HOUSE_VIEWS } from "constants/houses";
import {
  emptyFavorites,
  emptyFavoritesNonAuth,
  listViewNoResults,
  mapViewNoResults
} from "images";

const NO_RESULTS_DATA_MAP = {
  [HOUSE_VIEWS.favorites]: {
    auth: {
      description: "houses.list.emptyFavoritesDescription",
      image: emptyFavorites,
      imageAlt: "Empty Favorites for authenticated users",
      message: "houses.list.emptyFavoritesMessage",
    },
    non_auth: {
      description: "houses.list.nonAuthEmptyFavoritesDescription",
      image: emptyFavoritesNonAuth,
      imageAlt: "Empty Favorites for non-auth users",
      message: "houses.list.nonAuthEmptyFavoritesTitle",
    },
  },
  [HOUSE_VIEWS.topMatches]: {
    list: {
      image: listViewNoResults,
      imageAlt: "List View: No results",
      imageCustomClass: "top-matches-list",
    },
    map: {
      image: mapViewNoResults,
      imageAlt: "Map View: No results",
      imageCustomClass: "top-matches-map",
    }
  },
};

export { NO_RESULTS_DATA_MAP };
