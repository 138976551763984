import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { IconButton } from "components/Controls/Buttons";
import { checkIsFill } from "utils/helpers/criteriaHelper";
import { BASIC_CRITERIA, COMING_SOON, ADDITIONAL_CRITERIA, LOCATION_CRITERIA } from "../constants";
import "../styles.scss";


const Criteria = ({ criteriaData, activeFilter, handleActiveFilter }) => {
  const renderIconButton = criteria => (
    <IconButton
      id={criteria.key}
      onClick={() => handleActiveFilter(criteria.key)}
      leftIcon={criteria.icon}
      isActive={activeFilter === criteria.key}
      isFill={checkIsFill(criteria, criteriaData)}
      message={!activeFilter && <FormattedMessage id={criteria.message} />}
    />
  );

  const renderBasicCriteria = () => (
    <div className="criteria-content">
      <div className="criteria-title fs-16">
        <FormattedMessage id="dashboard.criteria.basicCriteriaTitle" />
      </div>
      <div className="criteria-content__content">
        {BASIC_CRITERIA.map((criteria) => (renderIconButton(criteria)))}
      </div>
    </div>
  );

  const renderLocationCriteria = () => (
    <div className="criteria-content">
      <div className="criteria-title fs-16">
        <FormattedMessage id="dashboard.criteria.locationCriteriaTitle" />
      </div>
      <div className="criteria-content__content">
        {LOCATION_CRITERIA.map((criteria) => (renderIconButton(criteria)))}
      </div>
    </div>
  );

  const renderAdditionalCriteria = () => (
    <div className="criteria-content">
      <div className="criteria-title fs-16">
        <FormattedMessage id="dashboard.criteria.additionalHomeCriteriaTitle" />
      </div>
      <div className="criteria-content__content">
        {ADDITIONAL_CRITERIA.map((criteria) => (renderIconButton(criteria)))}
      </div>
    </div>
  );

  const renderComingSoon = () => (
    <>
      <div className="coming-soon__title">
        <FormattedMessage id="dashboard.criteria.comingSoonTitle" />
      </div>
      <div className="coming-soon__content">
        {COMING_SOON.map((comingSoon) => (
          <IconButton
            leftIcon={comingSoon.icon}
            message={!activeFilter && <FormattedMessage id={comingSoon.message} />}
            disabled="disabled"
          />
        ))}
      </div>
    </>
  );

  return (
    <div className="criteria-wrapper">
      <div className="criteria">
        {renderBasicCriteria()}
        {renderLocationCriteria()}
        {renderAdditionalCriteria()}
      </div>
      <div className="coming-soon">{renderComingSoon()}</div>
    </div>
  );
};

export default injectIntl(Criteria);
