import { makeAutoObservable } from 'mobx';
import { TOKEN_KEY, USER_KEY } from "constants/localStorage";
import updateStore from '../utils/updateStore';
import {
  firebaseApplyVerification,
  firebaseCheckActionCode,
  firebaseLogin,
  firebaseReauthenticateWithCredential,
  firebaseSignUp,
  firebaseSendEmailVerification,
  firebaseSignInWithGoogle,
  firebaseUpdateEmail,
  firebaseUpdatePassword,
  firebaseUpdateProfile, firebaseCheckAndUpdateIdToken,
} from 'utils/firebase';

import {userDetailsApi, updateUserDetailsApi} from './requests';


const INITIAL_AUTH_DATA = {
  loading: false,
}

class AuthStore {
  loading = INITIAL_AUTH_DATA.loading;

  constructor() {
    makeAutoObservable(this);
  }

  setEmail = email => this.email = email;

  retrieveEmail = () => this.email;

  logInFirebaseRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.logInFirebase(requestBody, handleError, handleSuccess);
  }

  getUserDetailsRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.getUserDetails(requestBody, handleError, handleSuccess);
  }

  updateUserDetailsRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.updateUserDetails(requestBody, handleError, handleSuccess);
  }

  signInWithGoogleRequest(handleSuccess, handleError) {
    this.loading = true;
    this.signInWithGoogle(handleSuccess, handleError);
  }

  signUpFirebaseRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.signUpFirebase(requestBody, handleError, handleSuccess);
  }

  sendEmailVerificationFirebaseRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.sendEmailVerificationFirebase(requestBody, handleError, handleSuccess);
  }

  applyVerificationFirebaseRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.applyVerificationFirebase(requestBody, handleError, handleSuccess);
  }

  checkActionCodeFirebaseRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.checkActionCodeFirebase(requestBody, handleError, handleSuccess);
  }

  updateEmailRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.updateEmailFirebase(requestBody, handleError, handleSuccess);
  }

  updateProfileRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.updateProfileFirebase(requestBody, handleError, handleSuccess);
  }

  updatePasswordRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.updatePasswordFirebase(requestBody, handleError, handleSuccess);
  }

  reauthenticateRequest(requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.reauthenticate(requestBody, handleError, handleSuccess);
  }

  requestSuccess(handleSuccess, data) {
    this.loading = false;
    if (handleSuccess) {
      handleSuccess(data);
    }
  }

  requestFailure(handleError, error) {
    this.loading = false;
    updateStore(this, INITIAL_AUTH_DATA);
    if (handleError) {
      handleError(error);
    }
  }

  *logInFirebase(requestBody, handleError, handleSuccess) {
    try {
      const response = yield firebaseLogin(requestBody);
      if (response.user.emailVerified) {
        localStorage.setItem(TOKEN_KEY, response.user.accessToken);
        const userResponse = yield userDetailsApi(requestBody);
        localStorage.setItem(USER_KEY, JSON.stringify({...userResponse.data}));
      }
      this.requestSuccess(handleSuccess, response.user);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *getUserDetails(requestBody, handleError, handleSuccess) {
    try {
      const response = yield userDetailsApi(requestBody);
      localStorage.setItem(USER_KEY, JSON.stringify({...response.data}));
      this.requestSuccess(handleSuccess, response.user);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *updateUserDetails(requestBody, handleError, handleSuccess) {
    try {
      const response = yield updateUserDetailsApi(requestBody);
      localStorage.setItem(USER_KEY, JSON.stringify({...response.data}));
      this.requestSuccess(handleSuccess, response.user);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *signInWithGoogle(handleSuccess, handleError) {
    try {
      const response = yield firebaseSignInWithGoogle();
      localStorage.setItem(TOKEN_KEY, response.user.accessToken);
      const userResponse = yield userDetailsApi();

      // if the user does not have a set name, then take the name from google
      if (!userResponse.data.first_name && !userResponse.data.last_name) {
        const displayName = response.user.displayName.split(' ');
        yield this.updateUserDetails({first_name: displayName[0], last_name: displayName[1]});
      } else {
        localStorage.setItem(USER_KEY, JSON.stringify({...userResponse.data}));
      }

      this.requestSuccess(handleSuccess, response.user);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *signUpFirebase(requestBody, handleError, handleSuccess) {
    try {
      const response = yield firebaseSignUp(requestBody);
      this.requestSuccess(handleSuccess, response.user);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *sendEmailVerificationFirebase(requestBody, handleError, handleSuccess) {
    try {
      yield firebaseSendEmailVerification(requestBody);
      this.requestSuccess(handleSuccess);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *applyVerificationFirebase(requestBody, handleError, handleSuccess) {
    try {
      yield firebaseApplyVerification(requestBody);
      this.requestSuccess(handleSuccess);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *checkActionCodeFirebase(requestBody, handleError, handleSuccess) {
    try {
      const response = yield firebaseCheckActionCode(requestBody);
      this.requestSuccess(handleSuccess, response.data);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *updateEmailFirebase(requestBody, handleError, handleSuccess) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      yield firebaseUpdateEmail(requestBody);
      this.requestSuccess(handleSuccess);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *updateProfileFirebase(requestBody, handleError, handleSuccess) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      yield firebaseUpdateProfile(requestBody);
      this.requestSuccess(handleSuccess);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *updatePasswordFirebase(requestBody, handleError, handleSuccess) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      yield firebaseUpdatePassword(requestBody);
      this.requestSuccess(handleSuccess);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }

  *reauthenticate(requestBody, handleError, handleSuccess) {
    try {
      yield firebaseReauthenticateWithCredential(requestBody);
      this.requestSuccess(handleSuccess);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  }
}

export default AuthStore;
