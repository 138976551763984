import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { inject } from "mobx-react";
import "./styles.scss";

const CardView = ({ getHousesData }) => {
  useEffect(() => {
      getHousesData();
   }, [getHousesData]);

  // const history = useHistory();

  return (
    <div className="house-card-view-wrapper">
      <div className="card-view">

      </div>
    </div>
  );
}

export default inject('criteriaStore', 'housesStore')(CardView);
