import React from "react";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import TagManager from 'react-gtm-module';
import { IconEyeHidden, IconEyeShow } from "components/Svgs";
import { NON_AUTHENTICATED_USER_DATA_KEY, USER_KEY } from "constants/localStorage";
import { LOGIN_PATH } from "constants/paths";
import { getFullAddress } from "utils/helpers/housesHelper/getter";
import { localStorageStrToObjectOrNull } from "./common";
import { firebaseAuth } from "../firebase";

const getInitialUserValues = (fieldList = null, storageKey = null, fieldKey = null) => {
  const userFirebase = firebaseAuth.currentUser;
  const fields = fieldList || ["first_name", "last_name", "email"];
  const initialValues = {};
  if (userFirebase) {
    const user = getUser();
    fields.map(field => {
      initialValues[field] = field === "email" ? userFirebase.email : user[field];
    });
    return initialValues;
  }
  if (storageKey) {
    let data = localStorage.getItem(storageKey);
    if (data) {
      data = JSON.parse(data);
      if (fieldKey) {
        fields.forEach(field => {
          initialValues[field] = data[fieldKey][field];
        });
        return initialValues;
      }
    }
  }
  return {};
};

const getItemValueOrNull = (item, key) => item && _.has(item, key) ? item[key] : null;

const getUser = () => JSON.parse(localStorage.getItem(USER_KEY)) || {};

const logOut = () => {
  localStorage.clear();
  // window.Intercom("shutdown");
  window.location.href = LOGIN_PATH;
};

const updateIntercomWidget = async () => {
  // available for both registered and unregistered users
  let user = firebaseAuth.currentUser;
  if (!user) {
    user = await new Promise((resolve) => {
      firebaseAuth.onAuthStateChanged(user => resolve(user));
    });
  }

  const userDetails = getUser();
  const name = userDetails && !_.isEmpty(userDetails) ? `${userDetails.first_name} ${userDetails.last_name}` : null;
  // widget start
  window.Intercom("boot", {
    app_id: process.env.REACT_APP_BASE_INTERCOM_APP_ID,
    name,
    email: getItemValueOrNull(user, "email"),
    user_id: getItemValueOrNull(user, "uid"),
  });
};

const trackScheduleTour = (houseDetails) => {
  const data = {
    address: getFullAddress(houseDetails.address),
    house_url: window.location.toString()
  };
  trackEvent("request-tour", data);
};

const trackEvent = (eventName, eventData) => {
  if (firebaseAuth.currentUser) {
    eventData = _.extend(eventData,
      {
        user_id: firebaseAuth.currentUser.uid,
        ...getInitialUserValues(),
        date: Date.now()
      });
  }
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    const GTMData = {
      dataLayer: { event: eventName, metaData: eventData }
    };
    TagManager.dataLayer(GTMData);
  }
  window.Intercom('trackEvent', eventName, eventData);
};

const initializeGTM = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_CONTAINER_ID
    };
    TagManager.initialize(tagManagerArgs);
  }
};

const DEFAULT_USER_ABBREVIATION = "HI";

const getUserAbbreviation = () => {
  const isNonAuthUser = _.isNull(localStorage.getItem(USER_KEY));
  const user = isNonAuthUser ? localStorageStrToObjectOrNull(NON_AUTHENTICATED_USER_DATA_KEY) : getUser();
  if (user && user.first_name && user.last_name) {
    return user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase();
  }
  return DEFAULT_USER_ABBREVIATION;
};

const getPasswordIcon = visible => (
  <Tooltip title={<FormattedMessage id={visible ? "login.hidePassword" : "login.showPassword"} />}>
    {visible ? <IconEyeHidden /> : <IconEyeShow />}
  </Tooltip>
);

const cleanUpPhone = phone => _.isString(phone) ? phone.replace(/[\s()X\-]/mg, "") : "";

const normalizedPhone = phone => {
  if (!_.isNull(phone)) {
    const cleanedPhone = cleanUpPhone(phone);
    return cleanedPhone.length === 12 ? cleanedPhone : null;
  }
  return null;
};

const USER_NAME_DATA = ["first_name", "last_name"];

export {
  getInitialUserValues,
  getPasswordIcon,
  getUser,
  getUserAbbreviation,
  logOut,
  updateIntercomWidget,
  trackEvent,
  trackScheduleTour,
  initializeGTM,
  cleanUpPhone,
  normalizedPhone,
  USER_NAME_DATA,
};
