import React from "react";
import { FormattedMessage } from "react-intl";
import { firebaseAuth } from 'utils/firebase';
import { Modal } from "antd";
import { SimpleButton } from "components/Controls/Buttons";
import {IconLetterSmall} from "components/Svgs";
import "../styles.scss";

const EmailConfirmModal = (props) => {
  const { modalVisible, handleModalVisible, resentLink } = props;
  const user = firebaseAuth.currentUser;

  const onClickOk = () => {
    handleModalVisible();
  };

  return (
    <Modal
      className="general-modal"
      visible={modalVisible}
      footer={null}
      width={553}
      onCancel={handleModalVisible}
      closable={false}
    >
      <div className="general-modal-container">
        <div className="general-image-container">
          <IconLetterSmall />
        </div>
        <div className="general-content">
          <div className="general-content__title">
            <FormattedMessage id="modal.confirmNewEmailTitle" />
          </div>
          <div className="general-content__description">
            <FormattedMessage id="modal.confirmNewEmailDescription" values={{email: user && user.email}} />
          </div>
          <div className="general-content__buttons">
            <SimpleButton onClick={resentLink} customClass="btn-default white-button">
              <FormattedMessage id="modal.resendLink" />
            </SimpleButton>
            <SimpleButton onClick={onClickOk} customClass="btn-default">
              <FormattedMessage id="modal.okThanks" />
            </SimpleButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmailConfirmModal;
