import React from "react";

const IconLocation = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_716_1797)">
      <path
        d="M5.08438 28.0975L2.23876 30.841C1.80226 31.2619 2.1002 32.0001 2.70657 32.0001H29.2909C29.8988 32.0001 30.196 31.2587 29.7563 30.8388L26.8816 28.0934C26.6552 27.8772 26.3542 27.7566 26.0411 27.7566H5.92895C5.61401 27.7567 5.3112 27.8788 5.08438 28.0975Z"
        fill="url(#paint0_linear_716_1797)" pointerEvents="visible"
      />
      <path
        d="M19.4909 27.759L19.4875 27.7566H12.5273C13.2497 28.5646 13.9105 29.2738 14.4576 29.8477L16.6119 32H23.7319L19.4909 27.759Z"
        fill="url(#paint1_linear_716_1797)"
      />
      <path
        d="M16.0061 0C9.12734 0 3.55078 5.57656 3.55078 12.4552C3.55078 17.8177 11.1195 26.3471 14.4563 29.8476C15.3002 30.7331 16.712 30.7331 17.556 29.8476C20.8936 26.3471 28.4623 17.8177 28.4623 12.4552C28.4622 5.57656 22.8857 0 16.0061 0ZM16.0061 17.1933C13.3894 17.1933 11.2682 15.0719 11.2682 12.4553C11.2682 9.83869 13.3895 7.71737 16.0061 7.71737C18.6227 7.71737 20.744 9.83869 20.744 12.4553C20.744 15.0719 18.6227 17.1933 16.0061 17.1933Z"
        fill="url(#paint2_linear_716_1797)"
      />
      <path
        d="M16.0064 4.92529C11.8478 4.92529 8.47656 8.29655 8.47656 12.4551C8.47656 16.6137 11.8478 19.9849 16.0064 19.9849C20.1649 19.9849 23.5362 16.6137 23.5362 12.4551C23.5362 8.29655 20.1649 4.92529 16.0064 4.92529ZM16.0064 17.1931C13.3897 17.1931 11.2684 15.0717 11.2684 12.4551C11.2684 9.83849 13.3897 7.71717 16.0064 7.71717C18.623 7.71717 20.7443 9.83849 20.7443 12.4551C20.7443 15.0717 18.623 17.1931 16.0064 17.1931Z"
        fill="url(#paint3_linear_716_1797)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_716_1797"
        x1="15.9984"
        y1="29.1873"
        x2="15.9984"
        y2="31.2917"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7F3CE" />
        <stop offset="1" stopColor="#61DB99" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_1797"
        x1="19.5173"
        y1="31.3469"
        x2="16.7014"
        y2="28.5309"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#61DB99" stopOpacity="0" />
        <stop offset="1" stopColor="#009E74" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_1797"
        x1="11.6889"
        y1="8.80279"
        x2="24.1517"
        y2="21.2656"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4C54" />
        <stop offset="1" stopColor="#BE3F45" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_1797"
        x1="18.8134"
        y1="9.6479"
        x2="10.6774"
        y2="17.7845"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4C54" />
        <stop offset="1" stopColor="#BE3F45" />
      </linearGradient>
      <clipPath id="clip0_716_1797">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLocation;
