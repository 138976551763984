import React from "react";

const IconArrowLeft = (props) => (
  <svg width="16" height="9" viewBox="0 0 16 9" {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M3.32949 8.28794L0.343036 5.30149C-0.107433 4.80514 -0.124119 3.92923 0.343036 3.39117L3.32949 0.404725C4.73095
     -0.838236 6.45358 1.06374 5.23981 2.31505L4.54325 3.01161H14.6705C16.4432 3.01161 16.4432 5.68105 14.6705
     5.68105H4.54325L5.23981 6.37761C6.48695 7.70399 4.61833 9.52673 3.32949 8.28794Z"
    />
  </svg>
);

export default IconArrowLeft;
