import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import PriorityField from "components/Fields/PriorityField";
import { SliderMinIdealInputField } from "components/Fields";
import { MAX_BEDROOMS } from "constants/criteria";
import { IconBedrooms } from "components/Svgs";
import { createHistogramSteps, getInitialRangePriorityValues, onCriteriaRangeFormFinish } from "utils/helpers/criteriaHelper";

const DEFAULT_VALUES = {
  min: 0,
  max: 6,
  ideal: 5,
  step: 1,
}

const HISTOGRAM_STEPS = createHistogramSteps(DEFAULT_VALUES.min, DEFAULT_VALUES.max + 1, DEFAULT_VALUES.step);

const BedroomsForm = ({ dataKind, formName, initialData, kind, withPriority, onSubmitForm }) => {
  const [form] = Form.useForm();
  const initialValues = getInitialRangePriorityValues(DEFAULT_VALUES, initialData, false, true, withPriority);

  const onFinish = values => onCriteriaRangeFormFinish(kind, values, withPriority, onSubmitForm);

  return (
    <Form
      name={formName}
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item name="range">
        <SliderMinIdealInputField
          dataKind={dataKind}
          kind={kind}
          form={form}
          min={DEFAULT_VALUES.min}
          max={DEFAULT_VALUES.max}
          step={DEFAULT_VALUES.step}
          histogramSteps={HISTOGRAM_STEPS}
          maxInputValue={MAX_BEDROOMS}
          icon={<IconBedrooms />}
          minMessage={<FormattedMessage id="dashboard.form.minBedroomsMessage" />}
          idealMessage={<FormattedMessage id="dashboard.form.idealBedroomsMessage" />}
        />
      </Form.Item>
      {withPriority && (
        <Form.Item name="priority">
          <PriorityField />
        </Form.Item>
      )}
    </Form>
  );
};

BedroomsForm.propTypes = {
  dataKind: PropTypes.string,
  formName: PropTypes.string,
  initialData: PropTypes.object,
  kind: PropTypes.string,
  withPriority: PropTypes.bool,
  onSubmitForm: PropTypes.func,
};

export default BedroomsForm;
