import React from "react";
import { FormattedMessage } from "react-intl";
import { Spin } from "antd";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import { HOUSE_VIEWS } from "constants/houses";
import { USER_KEY } from "constants/localStorage";
import { AccountButtonView } from "containers/Houses/Views";
import { NO_RESULTS_DATA_MAP } from "./constants";
import "./styles.scss";

const NoResultsView = ({ activeTab, isLoading, view }) => {
  const userType = _.isNull(localStorage.getItem(USER_KEY)) ? "non_auth" : "auth";
  const secondParameter = view === HOUSE_VIEWS.favorites ? userType : activeTab;
  const data = _.get(NO_RESULTS_DATA_MAP[view], secondParameter, {});
  const isShowBottomButton = view === HOUSE_VIEWS.favorites && userType === "non_auth";

  return (
    <div className={classNames("no-results-wrapper", { isMap: activeTab === "map" })}>
      <Spin spinning={isLoading}>
        {!isLoading && (
          <div className={classNames(
            "no-results",
            { topMatches: view === HOUSE_VIEWS.topMatches, isMap: activeTab === "map" }
          )}>
            {(_.has(data, "message") || _.has(data, "description")) && (
              <div className="no-results__messages">
                {data.message && (
                  <div className="no-results__messages-title">
                    <FormattedMessage id={data.message}/>
                  </div>
                )}
                {data.description && (
                  <div className="no-results__messages-description">
                    <FormattedMessage id={data.description}/>
                  </div>
                )}
              </div>
            )}
            <div className={classNames("no-results__image", { withBottomSpace: isShowBottomButton })}>
              <img className={data.imageCustomClass} src={data.image} alt={data.imageAlt} />
            </div>
            {isShowBottomButton && <AccountButtonView maxSize />}
          </div>
        )}
      </Spin>
    </div>
  );
};

NoResultsView.propTypes = {
  activeTab: PropTypes.string,
  isLoading: PropTypes.bool,
  view: PropTypes.string,
};

export default NoResultsView;
