import React from "react";

const IconLowCrime = (props) => (
  <svg
    width="32"
    height="26"
    viewBox="0 0 32 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.8582 4.62793V12.2025C29.8582 13.1958 29.5307 14.1119 28.9779 14.8491V24.7143C28.9779 25.3422 28.4689 25.8512 27.841 25.8512H20.002C19.374 25.8512 18.8649 25.3426 18.8651 24.7146C18.8655 22.3159 18.866 16.9514 18.866 16.8149C18.866 16.2119 18.6269 15.6297 18.1889 15.2155C17.1994 14.28 16.582 12.9555 16.582 11.4866V4.62793H29.8582Z"
      fill="url(#paint0_linear_1284_8087)"
    />
    <path
      d="M18.8635 23.0486C18.8635 23.6753 18.8633 24.2505 18.8633 24.7146C18.8632 25.3425 19.3722 25.8511 20.0002 25.8511H27.8392C28.4671 25.8511 28.9761 25.3422 28.9761 24.7143V23.0486H18.8635Z"
      fill="url(#paint1_linear_1284_8087)"
    />
    <path
      d="M28.9799 24.178V20.5388H18.8676C18.8675 21.8042 18.8674 23.1548 18.8672 24.178H28.9799Z"
      fill="url(#paint2_linear_1284_8087)"
    />
    <path
      d="M16.582 4.62817V11.4864C16.582 14.3216 18.8801 16.6197 21.7153 16.6197H25.4421C27.8809 16.6197 29.8582 14.6424 29.8582 12.203V4.62817H16.582Z"
      fill="url(#paint3_linear_1284_8087)"
    />
    <path
      d="M23.2216 2.64188V5.77374C23.2216 6.69032 22.4785 7.43335 21.5619 7.43335C20.6454 7.43335 19.9023 6.69032 19.9023 5.77374V2.60906C19.9023 1.70378 20.6275 0.96734 21.529 0.949771C22.4597 0.9317 23.2216 1.71099 23.2216 2.64188Z"
      fill="url(#paint4_linear_1284_8087)"
    />
    <path
      d="M28.1987 1.06836C27.2819 1.06836 26.5391 1.8117 26.5391 2.72797V4.62808H26.9893C28.5734 4.62808 29.8578 5.91241 29.8578 7.4966V2.72797C29.8578 1.81164 29.1149 1.06836 28.1987 1.06836Z"
      fill="url(#paint5_linear_1284_8087)"
    />
    <path
      d="M26.5419 1.84128V5.77376C26.5419 6.69034 25.7988 7.43337 24.8823 7.43337C23.9657 7.43337 23.2227 6.69034 23.2227 5.77376V1.80853C23.2227 0.903241 23.9477 0.166802 24.8493 0.149234C25.7801 0.131163 26.5419 0.910457 26.5419 1.84128Z"
      fill="url(#paint6_linear_1284_8087)"
    />
    <path
      d="M19.9012 3.50126V5.77395C19.9012 6.69053 19.1582 7.43355 18.2416 7.43355C17.3251 7.43355 16.582 6.69053 16.582 5.77395V3.46844C16.582 2.56315 17.3072 1.82665 18.2088 1.80915C19.1395 1.79108 19.9012 2.57043 19.9012 3.50126Z"
      fill="url(#paint7_linear_1284_8087)"
    />
    <path
      d="M29.3124 4.62817H23.4695C22.8456 4.62817 22.3398 5.1339 22.3398 5.75784C22.3398 6.96706 23.3202 7.94739 24.5294 7.94739H27.1238C27.8034 7.94739 28.3544 8.49835 28.3544 9.17801V11.008C28.3544 12.4463 27.8741 13.8434 26.9897 14.9777L25.7095 16.6196C29.1824 16.6196 31.9979 13.8043 31.9979 10.3313V7.31373C31.9979 5.8305 30.7956 4.62817 29.3124 4.62817Z"
      fill="url(#paint8_linear_1284_8087)"
    />
    <path
      d="M27.1251 7.94727H27.4555C29.1083 7.94727 30.4481 9.28706 30.4481 10.9398V12.4079C30.4481 14.2232 29.2631 15.8309 27.5248 16.3539C26.9505 16.5266 26.3415 16.6195 25.7109 16.6195L26.9911 14.9776C27.8755 13.8432 28.3558 12.4462 28.3558 11.0079V9.17789C28.3558 8.49823 27.8048 7.94727 27.1251 7.94727Z"
      fill="url(#paint9_linear_1284_8087)"
    />
    <path
      d="M23.743 8.23682C25.4932 8.23682 27.0994 8.8521 28.358 9.87766V11.0078C28.358 12.4461 27.8777 13.8433 26.9933 14.9776L25.7205 16.61C25.629 16.6156 25.537 16.6194 25.4441 16.6194H21.7175C19.1916 16.6194 17.0917 14.7949 16.6641 12.3917C17.4799 9.24388 20.34 8.23682 23.743 8.23682Z"
      fill="url(#paint10_linear_1284_8087)"
    />
    <path
      d="M2.14062 4.62793V12.2025C2.14062 13.1958 2.46753 14.1119 3.02094 14.8491V24.7143C3.02094 25.3422 3.52993 25.8512 4.15782 25.8512H11.9969C12.6248 25.8512 13.1339 25.3426 13.1338 24.7146C13.1333 22.316 13.1329 16.9519 13.1329 16.8149C13.1329 16.5131 13.1925 16.217 13.3073 15.9446C13.4215 15.6717 13.5909 15.4226 13.8099 15.2155C14.7994 14.28 15.4168 12.9555 15.4168 11.4866V4.62793H2.14062Z"
      fill="url(#paint11_linear_1284_8087)"
    />
    <path
      d="M3.01953 24.7145C3.01953 25.3424 3.52852 25.8514 4.15641 25.8514H11.9955C12.6234 25.8514 13.1325 25.3428 13.1323 24.7148C13.1323 24.2508 13.1322 23.6755 13.1321 23.0488H3.01953V24.7145Z"
      fill="url(#paint12_linear_1284_8087)"
    />
    <path
      d="M13.1323 24.178C13.1321 23.1548 13.132 21.8042 13.1318 20.5388H3.01953V24.178H13.1323Z"
      fill="url(#paint13_linear_1284_8087)"
    />
    <path
      d="M15.4168 4.62817V11.4864C15.4168 14.3216 13.1187 16.6197 10.2836 16.6197H6.55675C4.11791 16.6197 2.14062 14.6424 2.14062 12.203V4.62817H15.4168Z"
      fill="url(#paint14_linear_1284_8087)"
    />
    <path
      d="M12.0966 2.64188V5.77374C12.0966 6.69032 11.3535 7.43335 10.4369 7.43335C9.52037 7.43335 8.77734 6.69032 8.77734 5.77374V2.60906C8.77734 1.70378 9.50249 0.96734 10.404 0.949771C11.3348 0.9317 12.0966 1.71099 12.0966 2.64188Z"
      fill="url(#paint15_linear_1284_8087)"
    />
    <path
      d="M9.51032 5.19775C9.60481 5.36284 9.65909 5.5539 9.65909 5.75775C9.65909 6.96698 8.67876 7.94731 7.46954 7.94731H4.87515C4.1955 7.94731 3.64453 8.49827 3.64453 9.17793V11.0079C3.64453 12.4462 4.12484 13.8433 5.00924 14.9776L6.28202 16.61C6.3735 16.6157 6.46549 16.6195 6.55841 16.6195H10.285C13.1199 16.6195 15.4181 14.3213 15.4181 11.4864V11.1055L9.51032 5.19775Z"
      fill="url(#paint16_linear_1284_8087)"
    />
    <path
      d="M5.45934 2.72797V4.62808H5.00914C3.42495 4.62808 2.14062 5.91241 2.14062 7.4966V2.72797C2.14062 1.81164 2.88346 1.06836 3.79973 1.06836C4.7165 1.06836 5.45934 1.81164 5.45934 2.72797Z"
      fill="url(#paint17_linear_1284_8087)"
    />
    <path
      d="M8.78016 1.84128V5.77376C8.78016 6.69034 8.03713 7.43337 7.12055 7.43337C6.20397 7.43337 5.46094 6.69034 5.46094 5.77376V1.80853C5.46094 0.903241 6.18608 0.166802 7.08761 0.149234C8.01837 0.131163 8.78016 0.910457 8.78016 1.84128Z"
      fill="url(#paint18_linear_1284_8087)"
    />
    <path
      d="M15.4169 3.50126V5.77395C15.4169 6.69053 14.6738 7.43355 13.7573 7.43355C12.8407 7.43355 12.0977 6.69053 12.0977 5.77395V3.46844C12.0977 2.56315 12.8228 1.82665 13.7243 1.80915C14.6551 1.79108 15.4169 2.57043 15.4169 3.50126Z"
      fill="url(#paint19_linear_1284_8087)"
    />
    <path
      d="M8.52838 4.62817H2.68549C1.20232 4.62817 0 5.8305 0 7.3136V10.3311C0 13.8042 2.81537 16.6195 6.28838 16.6195L5.00819 14.9776C4.1238 13.8433 3.64348 12.4462 3.64348 11.0079V9.17789C3.64348 8.49823 4.19445 7.94727 4.8741 7.94727H7.46849C8.67771 7.94727 9.65804 6.96694 9.65804 5.75771C9.65804 5.13396 9.15225 4.62817 8.52838 4.62817Z"
      fill="url(#paint20_linear_1284_8087)"
    />
    <path
      d="M4.8737 7.94727H4.54335C2.89064 7.94727 1.55078 9.28706 1.55078 10.9398V12.4079C1.55078 14.2232 2.73579 15.8309 4.47408 16.3539C5.04838 16.5266 5.65732 16.6195 6.28797 16.6195L5.00779 14.9776C4.12339 13.8432 3.64308 12.4462 3.64308 11.0079V9.17789C3.64308 8.49823 4.19404 7.94727 4.8737 7.94727Z"
      fill="url(#paint21_linear_1284_8087)"
    />
    <path
      d="M8.2595 8.23682C6.50928 8.23682 4.90314 8.8521 3.64453 9.87766V11.0078C3.64453 12.4461 4.12484 13.8433 5.00924 14.9776L6.28202 16.61C6.3735 16.6156 6.46549 16.6194 6.55841 16.6194H10.285C12.811 16.6194 14.9108 14.7949 15.3385 12.3917C14.5225 9.24388 11.6625 8.23682 8.2595 8.23682Z"
      fill="url(#paint22_linear_1284_8087)"
    />
    <path
      d="M12.7064 22.2956H3.48565C2.94773 22.2956 2.51172 21.8596 2.51172 21.3217V19.6722C2.51172 19.1343 2.94773 18.6982 3.48565 18.6982H12.7064C13.2443 18.6982 13.6803 19.1343 13.6803 19.6722V21.3217C13.6803 21.8596 13.2443 22.2956 12.7064 22.2956Z"
      fill="url(#paint23_linear_1284_8087)"
    />
    <path
      d="M12.6289 20.8296L13.571 21.7717C13.6414 21.637 13.6818 21.4841 13.6818 21.3216V20.8296H12.6289Z"
      fill="url(#paint24_linear_1284_8087)"
    />
    <path
      d="M28.5189 22.2956H19.2981C18.7602 22.2956 18.3242 21.8596 18.3242 21.3217V19.6722C18.3242 19.1343 18.7602 18.6982 19.2981 18.6982H28.5189C29.0568 18.6982 29.4928 19.1343 29.4928 19.6722V21.3217C29.4928 21.8596 29.0568 22.2956 28.5189 22.2956Z"
      fill="url(#paint25_linear_1284_8087)"
    />
    <path
      d="M19.4216 20.2166H18.3242V21.3217C18.3242 21.8596 18.7602 22.2956 19.2981 22.2956H21.5007L19.4216 20.2166Z"
      fill="url(#paint26_linear_1284_8087)"
    />
    <path
      d="M15.9204 22.0226L15.8363 22.1067C15.358 22.5851 14.5797 22.5851 14.1013 22.1067L13.5736 21.579C13.0953 21.1007 13.0953 20.3223 13.5736 19.8439L13.6577 19.7599C14.136 19.2815 14.9143 19.2815 15.3927 19.7599L15.9204 20.2876C16.3987 20.766 16.3987 21.5443 15.9204 22.0226ZM14.2391 20.5095C14.1277 20.6208 14.1277 20.8021 14.2391 20.9134L14.7669 21.4412C14.8782 21.5525 15.0594 21.5525 15.1709 21.4412L15.255 21.3571C15.3663 21.2457 15.3663 21.0645 15.255 20.9531L14.7272 20.4253C14.6158 20.314 14.4346 20.314 14.3232 20.4253L14.2391 20.5095Z"
      fill="url(#paint27_linear_1284_8087)"
    />
    <path
      d="M15.3085 21.2761C15.2947 21.3056 15.2759 21.3332 15.2514 21.3576L15.1673 21.4411C15.0562 21.5528 14.8749 21.5528 14.7632 21.4411L14.2355 20.9134C14.1339 20.8118 14.1251 20.6505 14.2104 20.5388H13.2228C13.1714 20.9078 13.2868 21.2961 13.5698 21.5791L14.0981 22.1068C14.5762 22.5849 15.3543 22.5849 15.833 22.1068L15.9046 22.0359L15.9171 22.0227C16.1254 21.8144 16.2428 21.549 16.2697 21.2761H15.3085Z"
      fill="url(#paint28_linear_1284_8087)"
    />
    <path
      d="M15.8939 20.2876L16.4217 19.7599C16.9001 19.2815 17.6783 19.2815 18.1567 19.7599L18.2408 19.8439C18.7192 20.3222 18.7192 21.1006 18.2408 21.579L17.713 22.1067C17.2347 22.5851 16.4564 22.5851 15.978 22.1067L15.8939 22.0226C15.4156 21.5443 15.4156 20.766 15.8939 20.2876ZM17.4911 20.4254C17.3797 20.314 17.1985 20.314 17.0871 20.4254L16.5593 20.9531C16.448 21.0645 16.448 21.2458 16.5593 21.3572L16.6434 21.4412C16.7548 21.5526 16.936 21.5526 17.0474 21.4412L17.5752 20.9135C17.6866 20.8021 17.6866 20.6209 17.5752 20.5095L17.4911 20.4254Z"
      fill="url(#paint29_linear_1284_8087)"
    />
    <path
      d="M18.5853 20.5388H17.5984C17.6843 20.6505 17.6755 20.8118 17.5733 20.9134L17.0456 21.4411C16.9339 21.5528 16.7532 21.5528 16.6415 21.4411L16.5574 21.3576C16.5329 21.3332 16.5141 21.3056 16.5009 21.2761H15.5391C15.566 21.549 15.6834 21.8144 15.8917 22.0227L15.9758 22.1068C16.4545 22.5849 17.2326 22.5849 17.7113 22.1068L18.239 21.5791C18.522 21.2961 18.6374 20.9078 18.5853 20.5388Z"
      fill="url(#paint30_linear_1284_8087)"
    />
    <path
      d="M14.8726 20.4969C14.8726 20.7568 14.6619 20.9675 14.402 20.9675H12.9589C12.699 20.9675 12.4883 20.7568 12.4883 20.4969C12.4883 20.2371 12.699 20.0264 12.9589 20.0264H14.402C14.6619 20.0264 14.8726 20.2371 14.8726 20.4969Z"
      fill="url(#paint31_linear_1284_8087)"
    />
    <path
      d="M19.5132 20.4969C19.5132 20.7568 19.3025 20.9675 19.0426 20.9675H17.5995C17.3396 20.9675 17.1289 20.7568 17.1289 20.4969C17.1289 20.2371 17.3396 20.0264 17.5995 20.0264H19.0426C19.3025 20.0264 19.5132 20.2371 19.5132 20.4969Z"
      fill="url(#paint32_linear_1284_8087)"
    />
    <path
      d="M17.1929 21.3439C17.1929 21.6038 16.9822 21.8145 16.7223 21.8145H15.2792C15.0193 21.8145 14.8086 21.6038 14.8086 21.3439C14.8086 21.084 15.0193 20.8733 15.2792 20.8733H16.7223C16.9822 20.8733 17.1929 21.084 17.1929 21.3439Z"
      fill="url(#paint33_linear_1284_8087)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1284_8087"
        x1="18.0085"
        y1="8.55364"
        x2="27.749"
        y2="18.2942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1284_8087"
        x1="23.9197"
        y1="24.3349"
        x2="23.9197"
        y2="25.7811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1284_8087"
        x1="23.9236"
        y1="23.8643"
        x2="23.9236"
        y2="22.038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1284_8087"
        x1="23.2201"
        y1="9.57023"
        x2="23.2201"
        y2="4.85657"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB09E" stopOpacity="0" />
        <stop offset="1" stopColor="#EC806B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1284_8087"
        x1="19.909"
        y1="4.19137"
        x2="23.5528"
        y2="4.19137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1284_8087"
        x1="26.8926"
        y1="4.28248"
        x2="29.9326"
        y2="4.28248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1284_8087"
        x1="23.2294"
        y1="3.79114"
        x2="26.8732"
        y2="3.79114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1284_8087"
        x1="16.5888"
        y1="4.62119"
        x2="20.2326"
        y2="4.62119"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1284_8087"
        x1="22.0018"
        y1="4.55846"
        x2="31.7423"
        y2="14.299"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1284_8087"
        x1="28.0794"
        y1="10.8857"
        x2="28.0794"
        y2="-0.448796"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1284_8087"
        x1="22.125"
        y1="12.9657"
        x2="29.2566"
        y2="2.71174"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1284_8087"
        x1="0.978084"
        y1="6.66577"
        x2="15.6425"
        y2="21.3302"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1284_8087"
        x1="8.07597"
        y1="24.335"
        x2="8.07597"
        y2="25.7812"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1284_8087"
        x1="8.07591"
        y1="23.8643"
        x2="8.07591"
        y2="22.038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1284_8087"
        x1="8.77868"
        y1="9.57023"
        x2="8.77868"
        y2="4.85657"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB09E" stopOpacity="0" />
        <stop offset="1" stopColor="#EC806B" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1284_8087"
        x1="8.78406"
        y1="4.19137"
        x2="12.4278"
        y2="4.19137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1284_8087"
        x1="9.28826"
        y1="11.357"
        x2="6.581"
        y2="6.05416"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB09E" stopOpacity="0" />
        <stop offset="1" stopColor="#EC806B" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_1284_8087"
        x1="1.47509"
        y1="4.28248"
        x2="6.36211"
        y2="4.28248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_1284_8087"
        x1="5.46765"
        y1="3.79114"
        x2="9.11145"
        y2="3.79114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_1284_8087"
        x1="12.1044"
        y1="4.62119"
        x2="15.7482"
        y2="4.62119"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_1284_8087"
        x1="1.68044"
        y1="5.96433"
        x2="16.3449"
        y2="20.6287"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#FFA78F" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_1284_8087"
        x1="3.91935"
        y1="10.8857"
        x2="3.91935"
        y2="-0.448796"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_1284_8087"
        x1="9.87744"
        y1="12.9657"
        x2="2.7459"
        y2="2.71173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F89580" stopOpacity="0" />
        <stop offset="0.9972" stopColor="#C5715A" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_1284_8087"
        x1="8.09603"
        y1="19.2378"
        x2="8.09603"
        y2="22.1611"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EEFF" />
        <stop offset="1" stopColor="#BAC8FA" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_1284_8087"
        x1="13.7729"
        y1="21.4471"
        x2="12.9076"
        y2="20.5818"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" />
        <stop offset="1" stopColor="#A7B7F1" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_1284_8087"
        x1="23.9085"
        y1="19.2378"
        x2="23.9085"
        y2="22.1611"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EEFF" />
        <stop offset="1" stopColor="#BAC8FA" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_1284_8087"
        x1="20.678"
        y1="22.7759"
        x2="18.2645"
        y2="20.3624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" />
        <stop offset="1" stopColor="#A7B7F1" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_1284_8087"
        x1="15.4212"
        y1="20.2591"
        x2="14.0703"
        y2="21.6101"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EEFF" />
        <stop offset="1" stopColor="#BAC8FA" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_1284_8087"
        x1="14.3465"
        y1="21.8169"
        x2="14.6289"
        y2="21.2365"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" />
        <stop offset="1" stopColor="#A7B7F1" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_1284_8087"
        x1="16.526"
        y1="20.3919"
        x2="17.5438"
        y2="21.4097"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EEFF" />
        <stop offset="1" stopColor="#BAC8FA" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_1284_8087"
        x1="17.5342"
        y1="21.9343"
        x2="17.1421"
        y2="21.1579"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" />
        <stop offset="1" stopColor="#A7B7F1" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_1284_8087"
        x1="13.6804"
        y1="20.1623"
        x2="13.6804"
        y2="20.8737"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EEFF" />
        <stop offset="1" stopColor="#BAC8FA" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_1284_8087"
        x1="18.3211"
        y1="20.1623"
        x2="18.3211"
        y2="20.8737"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EEFF" />
        <stop offset="1" stopColor="#BAC8FA" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_1284_8087"
        x1="16.0008"
        y1="21.0093"
        x2="16.0008"
        y2="21.7207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EEFF" />
        <stop offset="1" stopColor="#BAC8FA" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconLowCrime;
