import React from "react";
import { injectIntl } from "react-intl";
import { MaskedInput as BaseInput}  from "antd-mask-input";
import PropTypes from "prop-types";
import "./styles.scss";

const MaskedInput = (props) => (
  <div className="masked-input-wrapper">
    <BaseInput
      {...props}
    />
  </div>
);

MaskedInput.props = {
  placeholderChar: PropTypes.string,
  mask: PropTypes.string,
};

export default injectIntl(MaskedInput);

