import React from "react";

const IconSchoolDetails = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.50977 5.10083V12.4935C2.50977 13.1446 2.8891 13.7384 3.48306 14.005C7.68639 15.8914 12.3135 15.8914 16.5169 14.005C17.1108 13.7384 17.4902 13.1446 17.4902 12.4935V5.10083H2.50977Z" fill="url(#paint0_linear_6660_16007)"/>
    <path d="M2.50977 11.7471V12.4935C2.50977 13.1446 2.8891 13.7385 3.48306 14.005C7.68639 15.8915 12.3135 15.8915 16.5169 14.005C17.1108 13.7384 17.4902 13.1446 17.4902 12.4935V11.7471H2.50977Z" fill="url(#paint1_linear_6660_16007)"/>
    <path d="M4.78418 14.5239C8.62775 15.8714 12.743 15.6986 16.5168 14.005C17.1107 13.7384 17.4901 13.1446 17.4901 12.4935V5.10083H4.78418V14.5239Z" fill="url(#paint2_linear_6660_16007)"/>
    <path d="M2.50977 5.10083V7.9235L10.006 15.4198C12.2116 15.4189 14.4171 14.9473 16.5169 14.005C17.1108 13.7384 17.4902 13.1446 17.4902 12.4935V5.10083H2.50977Z" fill="url(#paint3_linear_6660_16007)"/>
    <path d="M8.70314 1.99316L0.425412 5.68555C-0.141804 5.93857 -0.141804 6.74371 0.425412 6.99673L8.7031 10.6891C9.52847 11.0572 10.4715 11.0572 11.2969 10.6891L19.5746 6.99673C20.1418 6.74371 20.1418 5.93857 19.5746 5.68555L11.2969 1.99316C10.4715 1.625 9.52851 1.625 8.70314 1.99316Z" fill="url(#paint4_linear_6660_16007)"/>
    <path d="M13.0997 9.88493L11.297 10.6892C10.4716 11.0572 9.52832 11.0572 8.70287 10.6892L0.425647 6.99669C-0.141882 6.74379 -0.141882 5.93834 0.425647 5.68543L2.05318 4.95947C2.05314 4.95947 13.0602 9.86822 13.0997 9.88493Z" fill="url(#paint5_linear_6660_16007)"/>
    <path d="M6.90039 9.88493L8.70302 10.6892C9.52847 11.0572 10.4717 11.0572 11.2972 10.6892L19.5744 6.99669C20.1419 6.74379 20.1419 5.93834 19.5744 5.68543L17.9469 4.95947C17.9469 4.95947 6.93988 9.86822 6.90039 9.88493Z" fill="url(#paint6_linear_6660_16007)"/>
    <path d="M10.4471 6.15503L4.40625 8.77256L8.70252 10.689C9.52789 11.0571 10.4709 11.0571 11.2963 10.689L13.8444 9.55233L10.4471 6.15503Z" fill="url(#paint7_linear_6660_16007)"/>
    <path d="M4.49215 13.845C4.22144 13.845 4.00195 13.6255 4.00195 13.3548V9.49538C4.00195 8.88679 4.36109 8.33338 4.91686 8.08546L9.80035 5.90714C10.0476 5.79687 10.3374 5.90785 10.4477 6.15514C10.558 6.4024 10.4469 6.69224 10.1997 6.80252L5.31627 8.98083C5.11344 9.0713 4.98235 9.2733 4.98235 9.49542V13.3548C4.98235 13.6255 4.76286 13.845 4.49215 13.845Z" fill="url(#paint8_linear_6660_16007)"/>
    <path d="M4.68119 18.2829H4.26052C3.9435 18.2829 3.68652 18.0259 3.68652 17.7089V15.6423H5.25515V17.7089C5.25519 18.0259 4.99821 18.2829 4.68119 18.2829Z" fill="url(#paint9_linear_6660_16007)"/>
    <path d="M5.25515 17.7089V15.6423H3.68652V16.3548L5.22452 17.8928C5.24409 17.835 5.25515 17.7733 5.25515 17.7089Z" fill="url(#paint10_linear_6660_16007)"/>
    <path d="M4.47046 16.5564C5.43036 16.5564 6.2085 15.7783 6.2085 14.8184C6.2085 13.8585 5.43036 13.0803 4.47046 13.0803C3.51057 13.0803 2.73242 13.8585 2.73242 14.8184C2.73242 15.7783 3.51057 16.5564 4.47046 16.5564Z" fill="url(#paint11_linear_6660_16007)"/>
    <path d="M5.43134 13.8904C5.43134 14.1434 5.25083 14.3603 5.00204 14.4063L4.68428 14.4651C4.6562 14.4703 4.63573 14.4949 4.63573 14.5235V15.2349H4.63534C4.64181 15.4196 4.53859 15.6197 4.3502 15.7494C4.07969 15.9357 3.73624 15.9063 3.58295 15.6837C3.42977 15.4612 3.52487 15.1299 3.79522 14.9435C3.95232 14.8354 4.13397 14.8002 4.28883 14.8329V14.0371C4.28883 13.9869 4.32456 13.944 4.37389 13.9348L5.28279 13.7668C5.36004 13.7525 5.43134 13.8118 5.43134 13.8904Z" fill="url(#paint12_linear_6660_16007)"/>
    <path d="M2.75781 15.1096C2.89687 15.9297 3.61189 16.5563 4.47095 16.5563C5.33001 16.5563 6.04503 15.9297 6.18409 15.1096H2.75781Z" fill="url(#paint13_linear_6660_16007)"/>
    <defs>
    <linearGradient id="paint0_linear_6660_16007" x1="8.90431" y1="7.12852" x2="13.9376" y2="15.8224" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5A5A5A"/>
    <stop offset="1" stopColor="#464646"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6660_16007" x1="9.99996" y1="12.3222" x2="9.99996" y2="15.707" gradientUnits="userSpaceOnUse">
    <stop stopColor="#464646" stopOpacity="0"/>
    <stop offset="1" stopColor="#202020"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6660_16007" x1="8.54347" y1="9.78957" x2="5.32779" y2="9.47585" gradientUnits="userSpaceOnUse">
    <stop stopColor="#464646" stopOpacity="0"/>
    <stop offset="1" stopColor="#202020"/>
    </linearGradient>
    <linearGradient id="paint3_linear_6660_16007" x1="9.99996" y1="15.8059" x2="9.99996" y2="7.53969" gradientUnits="userSpaceOnUse">
    <stop stopColor="#464646" stopOpacity="0"/>
    <stop offset="0.0267" stopColor="#454545" stopOpacity="0.027"/>
    <stop offset="0.6388" stopColor="#2A2A2A" stopOpacity="0.639"/>
    <stop offset="1" stopColor="#202020"/>
    </linearGradient>
    <linearGradient id="paint4_linear_6660_16007" x1="9.12083" y1="4.82249" x2="12.7893" y2="11.1589" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5A5A5A"/>
    <stop offset="1" stopColor="#464646"/>
    </linearGradient>
    <linearGradient id="paint5_linear_6660_16007" x1="6.51675" y1="8.11701" x2="5.50769" y2="10.5076" gradientUnits="userSpaceOnUse">
    <stop stopColor="#464646" stopOpacity="0"/>
    <stop offset="1" stopColor="#202020"/>
    </linearGradient>
    <linearGradient id="paint6_linear_6660_16007" x1="13.4833" y1="8.11701" x2="14.4924" y2="10.5076" gradientUnits="userSpaceOnUse">
    <stop stopColor="#464646" stopOpacity="0"/>
    <stop offset="1" stopColor="#202020"/>
    </linearGradient>
    <linearGradient id="paint7_linear_6660_16007" x1="10.1886" y1="10.6927" x2="8.09052" y2="7.67311" gradientUnits="userSpaceOnUse">
    <stop stopColor="#464646" stopOpacity="0"/>
    <stop offset="0.0267" stopColor="#454545" stopOpacity="0.027"/>
    <stop offset="0.6388" stopColor="#2A2A2A" stopOpacity="0.639"/>
    <stop offset="1" stopColor="#202020"/>
    </linearGradient>
    <linearGradient id="paint8_linear_6660_16007" x1="3.1765" y1="9.85475" x2="9.97816" y2="9.85475" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F7E07D"/>
    <stop offset="1" stopColor="#E69642"/>
    </linearGradient>
    <linearGradient id="paint9_linear_6660_16007" x1="3.58197" y1="16.9626" x2="5.46032" y2="16.9626" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F7E07D"/>
    <stop offset="1" stopColor="#E69642"/>
    </linearGradient>
    <linearGradient id="paint10_linear_6660_16007" x1="4.47084" y1="17.8352" x2="4.47084" y2="15.7828" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D52C1C" stopOpacity="0"/>
    <stop offset="0.28" stopColor="#D12C1E" stopOpacity="0.28"/>
    <stop offset="0.5735" stopColor="#C42C23" stopOpacity="0.574"/>
    <stop offset="0.8721" stopColor="#AF2B2C" stopOpacity="0.872"/>
    <stop offset="1" stopColor="#A42B31"/>
    </linearGradient>
    <linearGradient id="paint11_linear_6660_16007" x1="3.64066" y1="13.9886" x2="5.2527" y2="15.6006" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F7E07D"/>
    <stop offset="1" stopColor="#E69642"/>
    </linearGradient>
    <linearGradient id="paint12_linear_6660_16007" x1="5.26373" y1="15.5224" x2="3.65702" y2="13.9157" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F7E07D"/>
    <stop offset="1" stopColor="#E69642"/>
    </linearGradient>
    <linearGradient id="paint13_linear_6660_16007" x1="4.47095" y1="15.2175" x2="4.47095" y2="16.7335" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D52C1C" stopOpacity="0"/>
    <stop offset="0.28" stopColor="#D12C1E" stopOpacity="0.28"/>
    <stop offset="0.5735" stopColor="#C42C23" stopOpacity="0.574"/>
    <stop offset="0.8721" stopColor="#AF2B2C" stopOpacity="0.872"/>
    <stop offset="1" stopColor="#A42B31"/>
    </linearGradient>
    </defs>
  </svg>
);

export default IconSchoolDetails;
