import React from "react";

const IconSolarPower = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_716_2603)">
      <path
        d="M18.6322 28.6203C26.0143 28.6203 31.9987 22.6359 31.9987 15.2537C31.9987 7.87161 26.0143 1.88721 18.6322 1.88721C11.25 1.88721 5.26562 7.87161 5.26562 15.2537C5.26562 22.6359 11.25 28.6203 18.6322 28.6203Z"
        fill="url(#paint0_radial_716_2603)"
      />
      <path
        d="M18.631 4.646C18.5666 4.85187 18.5333 5.05775 18.5095 5.26356C18.4869 5.46943 18.4763 5.67531 18.4766 5.88112C18.4768 6.08699 18.4873 6.29287 18.5099 6.49868C18.5339 6.70456 18.567 6.91043 18.631 7.11624C18.695 6.91037 18.7282 6.70449 18.7521 6.49868C18.7747 6.29281 18.7852 6.08693 18.7854 5.88112C18.7857 5.67524 18.7751 5.46937 18.7525 5.26356C18.7287 5.05762 18.6954 4.85175 18.631 4.646Z"
        fill="url(#paint1_radial_716_2603)"
      />
      <path
        d="M18.631 23.3916C18.5666 23.5975 18.5333 23.8033 18.5095 24.0092C18.4869 24.215 18.4763 24.4209 18.4766 24.6267C18.4768 24.8326 18.4873 25.0384 18.5099 25.2443C18.5339 25.4502 18.567 25.656 18.631 25.8618C18.695 25.656 18.7282 25.4502 18.7521 25.2443C18.7747 25.0384 18.7852 24.8326 18.7854 24.6267C18.7857 24.4208 18.7751 24.215 18.7525 24.0092C18.7287 23.8033 18.6954 23.5975 18.631 23.3916Z"
        fill="url(#paint2_radial_716_2603)"
      />
      <path
        d="M28.0047 15.0996C27.7988 15.0998 27.5929 15.1104 27.3871 15.133C27.1812 15.1569 26.9754 15.1901 26.7695 15.2541C26.9754 15.3181 27.1812 15.3512 27.3871 15.3751C27.593 15.3977 27.7988 15.4083 28.0047 15.4085C28.2105 15.4087 28.4163 15.3981 28.6222 15.3756C28.8281 15.3518 29.0339 15.3184 29.2398 15.2541C29.0339 15.1897 28.8281 15.1564 28.6222 15.1326C28.4163 15.1099 28.2105 15.0994 28.0047 15.0996Z"
        fill="url(#paint3_radial_716_2603)"
      />
      <path
        d="M9.26247 15.4085C9.46834 15.4087 9.67422 15.3981 9.88003 15.3756C10.0859 15.3518 10.2917 15.3184 10.4976 15.2541C10.2917 15.1897 10.0859 15.1564 9.88003 15.1326C9.67415 15.1099 9.46828 15.0994 9.26247 15.0996C9.05659 15.0998 8.85078 15.1104 8.6449 15.133C8.43903 15.1569 8.23316 15.1901 8.02734 15.2541C8.23322 15.3181 8.43903 15.3512 8.6449 15.3751C8.85084 15.3978 9.05659 15.4084 9.26247 15.4085Z"
        fill="url(#paint4_radial_716_2603)"
      />
      <path
        d="M24.909 9.14855C25.0705 9.01898 25.2236 8.88086 25.3693 8.73549C25.515 8.59011 25.6531 8.43705 25.7827 8.27549C25.9114 8.11312 26.0334 7.94399 26.1334 7.75293C25.9423 7.85293 25.7732 7.97499 25.6108 8.10374C25.4493 8.2333 25.2962 8.37136 25.1508 8.51711C25.0054 8.6628 24.8673 8.81586 24.7378 8.97742C24.6091 9.13986 24.487 9.30892 24.3867 9.49973C24.5775 9.39936 24.7465 9.27723 24.909 9.14855Z"
        fill="url(#paint5_radial_716_2603)"
      />
      <path
        d="M12.3569 21.359C12.1954 21.4885 12.0423 21.6266 11.8969 21.7724C11.7515 21.918 11.6134 22.071 11.4839 22.2327C11.3552 22.3951 11.2331 22.5642 11.1328 22.755C11.3236 22.6547 11.4926 22.5325 11.6551 22.4039C11.8167 22.2744 11.9697 22.1363 12.1154 21.9909C12.2612 21.8455 12.3992 21.6924 12.5288 21.5309C12.6576 21.3685 12.7796 21.1994 12.8796 21.0083C12.6884 21.1083 12.5193 21.2302 12.3569 21.359Z"
        fill="url(#paint6_radial_716_2603)"
      />
      <path
        d="M25.3693 21.7724C25.2237 21.627 25.0706 21.4889 24.909 21.3594C24.7466 21.2307 24.5775 21.1086 24.3867 21.0083C24.487 21.1991 24.6092 21.3681 24.7378 21.5306C24.8673 21.6922 25.0054 21.8452 25.1508 21.9909C25.2962 22.1367 25.4493 22.2747 25.6108 22.4043C25.7732 22.533 25.9423 22.655 26.1334 22.7551C26.0334 22.564 25.9113 22.3949 25.7827 22.2325C25.6532 22.0709 25.515 21.9178 25.3693 21.7724Z"
        fill="url(#paint7_radial_716_2603)"
      />
      <path
        d="M11.897 8.73549C12.0424 8.88124 12.1954 9.0193 12.357 9.14886C12.5194 9.27761 12.6885 9.39961 12.8796 9.49967C12.7796 9.30861 12.6576 9.13942 12.5288 8.97705C12.3992 8.81549 12.2612 8.66242 12.1154 8.51705C11.9697 8.37161 11.8167 8.23349 11.6551 8.10399C11.4927 7.9753 11.3236 7.85318 11.1328 7.75293C11.2331 7.94374 11.3552 8.11274 11.4839 8.27524C11.6134 8.43686 11.7516 8.5898 11.897 8.73549Z"
        fill="url(#paint8_radial_716_2603)"
      />
      <path
        d="M22.2628 6.61074C22.3396 6.41974 22.4063 6.22474 22.4618 6.02517C22.5162 5.8253 22.5618 5.6218 22.5786 5.40674C22.4423 5.57386 22.3348 5.75261 22.2362 5.93486C22.1387 6.11749 22.0523 6.30467 21.976 6.49586C21.8997 6.68705 21.8329 6.88205 21.7774 7.08155C21.723 7.28149 21.6773 7.48492 21.6602 7.69986C21.7961 7.53261 21.9034 7.3538 22.0022 7.17161C22.0997 6.98892 22.186 6.80174 22.2628 6.61074Z"
        fill="url(#paint9_radial_716_2603)"
      />
      <path
        d="M15.0034 23.8972C14.927 24.0884 14.8603 24.2834 14.8048 24.4829C14.7504 24.6829 14.7046 24.8863 14.6875 25.1012C14.8235 24.934 14.9308 24.7552 15.0295 24.573C15.1271 24.3904 15.2134 24.2032 15.2901 24.0121C15.3669 23.8211 15.4336 23.6261 15.4891 23.4265C15.5436 23.2266 15.5892 23.0232 15.6059 22.8081C15.4696 22.9752 15.3622 23.154 15.2636 23.3362C15.1661 23.5189 15.0797 23.706 15.0034 23.8972Z"
        fill="url(#paint10_radial_716_2603)"
      />
      <path
        d="M27.3915 18.5957C27.2003 18.5193 27.0053 18.4525 26.8058 18.397C26.6058 18.3427 26.4024 18.2969 26.1875 18.2798C26.3547 18.4157 26.5336 18.523 26.7158 18.6218C26.8984 18.7193 27.0856 18.8057 27.2766 18.8824C27.4676 18.9592 27.6626 19.0259 27.8622 19.0814C28.0622 19.1358 28.2656 19.1815 28.4806 19.1982C28.3135 19.0619 28.1347 18.9545 27.9525 18.8558C27.7699 18.7583 27.5827 18.6719 27.3915 18.5957Z"
        fill="url(#paint11_radial_716_2603)"
      />
      <path
        d="M9.87428 11.9121C10.0653 11.9889 10.2603 12.0556 10.4598 12.1111C10.6598 12.1656 10.8632 12.2112 11.0783 12.228C10.9112 12.0916 10.7324 11.9843 10.5502 11.8856C10.3675 11.7881 10.1803 11.7018 9.98915 11.6254C9.79797 11.5491 9.60297 11.4823 9.40347 11.4268C9.20353 11.3724 9.00009 11.3267 8.78516 11.3096C8.95241 11.4456 9.13122 11.5528 9.31341 11.6516C9.49609 11.7491 9.68328 11.8354 9.87428 11.9121Z"
        fill="url(#paint12_radial_716_2603)"
      />
      <path
        d="M26.6373 11.6978C26.4574 11.8008 26.2813 11.9123 26.1172 12.0521C26.3316 12.03 26.5339 11.9794 26.7325 11.9204C26.9306 11.8602 27.1241 11.789 27.3134 11.7081C27.5027 11.6273 27.6877 11.5366 27.8682 11.4348C28.0481 11.332 28.2242 11.2203 28.3881 11.0801C28.1736 11.1019 27.9712 11.1523 27.7726 11.2114C27.5745 11.2716 27.3811 11.3428 27.1919 11.4241C27.0027 11.5053 26.8176 11.596 26.6373 11.6978Z"
        fill="url(#paint13_radial_716_2603)"
      />
      <path
        d="M10.6337 18.8107C10.8136 18.7079 10.9897 18.5963 11.1537 18.4561C10.9392 18.4779 10.7368 18.5283 10.5382 18.5874C10.3401 18.6476 10.1467 18.7188 9.95756 18.8001C9.76837 18.8812 9.58331 18.9719 9.40294 19.0737C9.22312 19.1767 9.04694 19.2882 8.88281 19.428C9.09719 19.4059 9.29956 19.3553 9.49819 19.2963C9.69631 19.2361 9.88969 19.1649 10.0791 19.084C10.2683 19.0032 10.4534 18.9125 10.6337 18.8107Z"
        fill="url(#paint14_radial_716_2603)"
      />
      <path
        d="M22.4639 23.8101C22.3827 23.6209 22.292 23.4358 22.1902 23.2555C22.0872 23.0757 21.9757 22.8995 21.8359 22.7354C21.8581 22.9497 21.9086 23.1521 21.9676 23.3507C22.0278 23.5488 22.0991 23.7422 22.1799 23.9315C22.2607 24.1209 22.3514 24.3059 22.4532 24.4863C22.5561 24.6662 22.6677 24.8423 22.8079 25.0063C22.7861 24.7917 22.7356 24.5893 22.6766 24.3908C22.6164 24.1927 22.5452 23.9993 22.4639 23.8101Z"
        fill="url(#paint15_radial_716_2603)"
      />
      <path
        d="M15.0782 7.25289C15.1811 7.43276 15.2927 7.60889 15.4329 7.77289C15.4112 7.55832 15.3607 7.35595 15.3016 7.15739C15.2414 6.95926 15.1702 6.76583 15.0889 6.5767C15.0077 6.38751 14.9171 6.20245 14.8152 6.02208C14.7123 5.84226 14.6008 5.66608 14.4609 5.50195C14.4831 5.71633 14.5336 5.9187 14.5926 6.11726C14.6528 6.31539 14.7241 6.50883 14.8049 6.69814C14.8857 6.88745 14.9764 7.07251 15.0782 7.25289Z"
        fill="url(#paint16_radial_716_2603)"
      />
      <path
        d="M20.6267 6.09483C20.6675 5.89301 20.6975 5.68914 20.7159 5.48289C20.7331 5.27639 20.741 5.06802 20.7183 4.85352C20.6147 5.0427 20.5415 5.23795 20.4777 5.43508C20.415 5.63245 20.3642 5.8322 20.3239 6.03408C20.2836 6.23595 20.2535 6.43982 20.2352 6.64614C20.2181 6.8527 20.2101 7.06101 20.2324 7.27539C20.3356 7.08614 20.4087 6.89082 20.4726 6.69376C20.5352 6.49639 20.586 6.2967 20.6267 6.09483Z"
        fill="url(#paint17_radial_716_2603)"
      />
      <path
        d="M16.7902 23.8145C16.7275 24.0118 16.6767 24.2116 16.6364 24.4135C16.5961 24.6153 16.566 24.8192 16.5477 25.0255C16.5306 25.232 16.5226 25.4403 16.5449 25.6548C16.6481 25.4655 16.7212 25.2702 16.7851 25.0731C16.8478 24.8758 16.8987 24.676 16.9394 24.4742C16.9801 24.2724 17.0102 24.0685 17.0285 23.8623C17.0457 23.6558 17.0536 23.4474 17.0309 23.2329C16.9272 23.422 16.8541 23.6173 16.7902 23.8145Z"
        fill="url(#paint18_radial_716_2603)"
      />
      <path
        d="M27.8547 16.9465C27.6528 16.9062 27.4489 16.8761 27.2426 16.8577C27.0361 16.8407 26.8277 16.8327 26.6133 16.855C26.8025 16.9582 26.9978 17.0312 27.1949 17.0952C27.3923 17.1579 27.592 17.2087 27.7938 17.2494C27.9956 17.2902 28.1994 17.3202 28.4057 17.3386C28.6122 17.3558 28.8206 17.3637 29.0351 17.341C28.8459 17.2374 28.6507 17.1642 28.4535 17.1004C28.2563 17.0377 28.0565 16.9868 27.8547 16.9465Z"
        fill="url(#paint19_radial_716_2603)"
      />
      <path
        d="M9.41481 13.5614C9.61662 13.6021 9.8205 13.6322 10.0268 13.6506C10.2333 13.6677 10.4417 13.6756 10.6562 13.6529C10.467 13.5494 10.2717 13.4762 10.0746 13.4124C9.87725 13.3497 9.6775 13.2988 9.47562 13.2586C9.27375 13.2182 9.06987 13.1881 8.86362 13.1697C8.65706 13.1527 8.44875 13.1447 8.23438 13.1669C8.42362 13.2701 8.61894 13.3432 8.816 13.4071C9.01325 13.4698 9.213 13.5207 9.41481 13.5614Z"
        fill="url(#paint20_radial_716_2603)"
      />
      <path
        d="M25.4062 10.7445C25.6131 10.6837 25.8028 10.5972 25.9874 10.503C26.1713 10.4078 26.3485 10.3025 26.5199 10.1885C26.6914 10.0747 26.8569 9.9518 27.0157 9.81886C27.1739 9.68499 27.3267 9.54318 27.4624 9.37549C27.2554 9.43599 27.0656 9.5223 26.8811 9.61661C26.6972 9.71186 26.52 9.81718 26.3488 9.93143C26.1776 10.0456 26.0121 10.1685 25.8532 10.3014C25.6952 10.4354 25.5422 10.5771 25.4062 10.7445Z"
        fill="url(#paint21_radial_716_2603)"
      />
      <path
        d="M11.8609 19.7637C11.6538 19.8242 11.464 19.9105 11.2795 20.0048C11.0956 20.1 10.9184 20.2054 10.7472 20.3196C10.5759 20.4338 10.4104 20.5567 10.2516 20.6896C10.0935 20.8235 9.94056 20.9652 9.80469 21.1326C10.0115 21.0718 10.2012 20.9854 10.3858 20.8911C10.5697 20.7959 10.7469 20.6905 10.9183 20.5766C11.0898 20.4627 11.2553 20.3399 11.4141 20.2069C11.5722 20.0732 11.7251 19.9314 11.8609 19.7637Z"
        fill="url(#paint22_radial_716_2603)"
      />
      <path
        d="M23.9576 22.9703C23.8433 22.7991 23.7205 22.6336 23.5876 22.4748C23.4537 22.3166 23.312 22.1637 23.1445 22.0278C23.2053 22.2346 23.2918 22.4244 23.386 22.609C23.4812 22.7929 23.5865 22.9701 23.7005 23.1415C23.8143 23.313 23.9372 23.4785 24.0702 23.6373C24.204 23.7955 24.3458 23.9483 24.5135 24.0841C24.453 23.877 24.3667 23.6872 24.2724 23.5027C24.1772 23.3188 24.0718 23.1416 23.9576 22.9703Z"
        fill="url(#paint23_radial_716_2603)"
      />
      <path
        d="M13.3098 7.53794C13.4237 7.70944 13.5465 7.87494 13.6795 8.03369C13.8133 8.19188 13.9552 8.34475 14.1228 8.48044C14.0623 8.27338 13.976 8.08356 13.8817 7.89913C13.7865 7.71525 13.6812 7.538 13.5669 7.36681C13.4527 7.19556 13.3298 7.03006 13.1969 6.87125C13.063 6.71313 12.9213 6.56019 12.7539 6.42432C12.8147 6.63113 12.9012 6.82088 12.9953 7.00544C13.0906 7.18938 13.1958 7.36656 13.3098 7.53794Z"
        fill="url(#paint24_radial_716_2603)"
      />
      <path
        d="M23.5252 7.99187C23.6568 7.832 23.7783 7.6655 23.8911 7.49325C24.0039 7.32106 24.1077 7.143 24.2014 6.95831C24.2941 6.773 24.3789 6.5825 24.4376 6.375C24.2711 6.51212 24.1306 6.66612 23.998 6.82544C23.8664 6.98537 23.7449 7.15181 23.6325 7.32431C23.5201 7.49675 23.4162 7.67481 23.3226 7.85943C23.2299 8.04481 23.1451 8.23525 23.0859 8.44256C23.2521 8.30518 23.3926 8.15112 23.5252 7.99187Z"
        fill="url(#paint25_radial_716_2603)"
      />
      <path
        d="M13.7402 22.5158C13.6086 22.6757 13.4871 22.8422 13.3747 23.0147C13.2622 23.1871 13.1584 23.3651 13.0647 23.5498C12.9721 23.7352 12.8872 23.9256 12.8281 24.1329C12.9943 23.9956 13.1347 23.8415 13.2674 23.6823C13.3989 23.5224 13.5204 23.3559 13.6332 23.1837C13.7461 23.0115 13.8499 22.8334 13.9436 22.6487C14.0363 22.4634 14.1211 22.2729 14.1798 22.0654C14.0132 22.2024 13.8727 22.3566 13.7402 22.5158Z"
        fill="url(#paint26_radial_716_2603)"
      />
      <path
        d="M27.0624 20.6186C26.9024 20.487 26.736 20.3655 26.5636 20.2531C26.3911 20.1406 26.2131 20.0368 26.0284 19.9432C25.8431 19.8505 25.6526 19.7657 25.4453 19.7065C25.5826 19.8727 25.7368 20.0132 25.8959 20.1457C26.0558 20.2773 26.2224 20.3988 26.3946 20.5116C26.5667 20.6244 26.7448 20.7282 26.9295 20.8219C27.1149 20.9147 27.3053 20.9994 27.5128 21.0582C27.3757 20.8917 27.2217 20.7512 27.0624 20.6186Z"
        fill="url(#paint27_radial_716_2603)"
      />
      <path
        d="M10.2046 9.88889C10.3645 10.0205 10.531 10.142 10.7032 10.2548C10.8754 10.3676 11.0535 10.4715 11.2382 10.5651C11.4235 10.6578 11.614 10.7426 11.8215 10.8013C11.6844 10.6348 11.5303 10.4943 11.371 10.3618C11.2111 10.2302 11.0446 10.1087 10.8722 9.99627C10.6997 9.88377 10.5217 9.77996 10.337 9.68633C10.1517 9.59364 9.96122 9.50883 9.75391 9.44971C9.89128 9.61589 10.0453 9.75633 10.2046 9.88889Z"
        fill="url(#paint28_radial_716_2603)"
      />
      <path
        d="M27.1773 13.3413C26.9807 13.4069 26.786 13.4815 26.5977 13.5863C26.8122 13.6068 27.0205 13.5971 27.2269 13.5782C27.433 13.5582 27.6367 13.5263 27.8382 13.4843C28.0397 13.4423 28.239 13.3898 28.4358 13.3255C28.6324 13.26 28.8271 13.1853 29.0154 13.0801C28.8007 13.0593 28.5925 13.0689 28.3861 13.0877C28.18 13.1077 27.9763 13.1396 27.7749 13.182C27.5735 13.2244 27.3741 13.2769 27.1773 13.3413Z"
        fill="url(#paint29_radial_716_2603)"
      />
      <path
        d="M10.0882 17.1673C10.2847 17.1018 10.4794 17.0271 10.6677 16.9219C10.4531 16.9011 10.2448 16.9107 10.0384 16.9295C9.83231 16.9495 9.62862 16.9814 9.42725 17.0238C9.22575 17.0662 9.02644 17.1187 8.82962 17.1831C8.63306 17.2487 8.43837 17.3233 8.25 17.4281C8.46456 17.4485 8.67287 17.4388 8.87925 17.42C9.08537 17.4 9.28906 17.3682 9.49056 17.3262C9.692 17.2843 9.89137 17.2317 10.0882 17.1673Z"
        fill="url(#paint30_radial_716_2603)"
      />
      <path
        d="M20.7047 24.3964C20.6624 24.1949 20.6098 23.9957 20.5455 23.7989C20.4799 23.6023 20.4052 23.4076 20.3005 23.2192C20.28 23.4338 20.2897 23.6421 20.3085 23.8485C20.3285 24.0546 20.3604 24.2583 20.4024 24.4598C20.4443 24.6614 20.4969 24.8606 20.5612 25.0575C20.6267 25.254 20.7015 25.4487 20.8067 25.637C20.8275 25.4224 20.8179 25.2141 20.799 25.0077C20.779 24.8015 20.7471 24.5979 20.7047 24.3964Z"
        fill="url(#paint31_radial_716_2603)"
      />
      <path
        d="M16.7175 6.7097C16.783 6.90627 16.8577 7.10095 16.9629 7.28926C16.9837 7.07464 16.9742 6.86633 16.9553 6.65995C16.9353 6.45383 16.9034 6.25014 16.861 6.04877C16.8186 5.84727 16.7661 5.64802 16.7017 5.45121C16.6362 5.25464 16.5615 5.05996 16.4567 4.87158C16.4363 5.08614 16.446 5.29446 16.4648 5.50083C16.4848 5.70696 16.5167 5.91064 16.5587 6.11208C16.6005 6.31352 16.6531 6.51283 16.7175 6.7097Z"
        fill="url(#paint32_radial_716_2603)"
      />
      <path
        d="M18.6328 23.5469C23.2129 23.5469 26.9258 19.834 26.9258 15.2539C26.9258 10.6738 23.2129 6.96094 18.6328 6.96094C14.0527 6.96094 10.3398 10.6738 10.3398 15.2539C10.3398 19.834 14.0527 23.5469 18.6328 23.5469Z"
        fill="url(#paint33_radial_716_2603)"
      />
      <path
        d="M18.6332 23.5473C19.5613 23.5473 20.4538 23.3947 21.287 23.1136L16.4522 18.2788C15.9364 17.6868 15.1772 17.3123 14.3302 17.3123C13.982 17.3123 13.6486 17.3757 13.3408 17.4913C13.2543 17.5239 13.1643 17.5354 13.077 17.5282L12.0261 16.4806C11.5528 15.9701 10.9805 15.553 10.3398 15.2593C10.3427 19.837 14.0546 23.5473 18.6332 23.5473Z"
        fill="url(#paint34_linear_716_2603)"
      />
      <path
        d="M17.552 20.1947C17.3197 20.1357 17.1484 19.9411 17.1127 19.7041C16.909 18.3501 15.7409 17.3121 14.3303 17.3121C13.982 17.3121 13.6486 17.3754 13.3409 17.4911C13.0736 17.5915 12.774 17.4941 12.6212 17.2529C11.6791 15.7656 10.0199 14.7778 8.12926 14.7778C5.38821 14.7778 3.13334 16.8531 2.84659 19.5179C2.82096 19.7561 2.65928 19.9594 2.42959 20.0275C1.0149 20.447 -0.0145323 21.7623 0.000155184 23.3166C0.0178427 25.1885 1.57778 26.6807 3.44977 26.6807H16.6843C18.5137 26.6807 20.0499 25.1839 20.0326 23.3546C20.0181 21.8303 18.9679 20.5543 17.552 20.1947Z"
        fill="url(#paint35_linear_716_2603)"
      />
      <path
        d="M27.8028 25.4913C27.3384 25.1983 27.0146 24.7258 26.889 24.1912C26.8194 23.8946 26.7365 23.5946 26.6492 23.3908C25.9202 21.6896 24.2399 20.4818 22.2873 20.4489C20.266 20.415 18.5226 21.6226 17.7694 23.3594C17.5172 23.9409 16.9596 24.3217 16.3267 24.3561C16.3003 24.3575 16.274 24.3593 16.2474 24.3614C14.8054 24.4791 13.6633 25.6726 13.6077 27.1183C13.5794 27.8519 13.8262 28.5269 14.2522 29.0499C14.732 29.6952 15.5068 30.1126 16.3694 30.1126H16.4858H22.2042H26.4252C27.8126 30.1126 28.9686 29.0024 28.9717 27.6151C28.9739 26.7206 28.5067 25.9355 27.8028 25.4913Z"
        fill="url(#paint36_linear_716_2603)"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6613 15.2541) scale(12.6209)"
      >
        <stop stopColor="#FFB500" />
        <stop offset="0.641" stopColor="#FFC72D" stopOpacity="0.359" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.8047 15.325) scale(10.1508 10.1513)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.8047 34.0703) scale(10.1508 10.1513)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(37.392 16.4351) scale(10.0848)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6502 16.4352) scale(10.0848)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(31.8982 15.3043) scale(10.0396)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6443 28.5596) scale(10.0396)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(31.8983 28.5597) scale(10.0396)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6443 15.3043) scale(10.0396)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.61 15.3203) scale(10.0641)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6374 32.7213) scale(10.0641)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.0489 22.2507) scale(10.0151)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6468 15.2805) scale(10.0151)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(35.8828 15.2826) scale(10.0164)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6486 22.658) scale(10.0164)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26.0155 32.5528) scale(10.0634)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6405 15.3195) scale(10.0635)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.4266 15.324) scale(10.0934 10.0936)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.7395 33.7029) scale(10.0937 10.0936)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(37.0284 19.0617) scale(10.0315 10.0316)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6498 15.3739) scale(10.0314 10.0316)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint21_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(34.2486 15.294) scale(10.0264)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.647 25.6821) scale(10.0264)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(29.0312 30.9169) scale(10.0528)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint24_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6407 15.3135) scale(10.0528)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint25_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28.8985 15.3137) scale(10.0534)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6406 31.0035) scale(10.0534)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint27_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(34.3365 25.5502) scale(10.0264)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint28_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6454 15.2933) scale(10.0264)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint29_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.9951 15.3572) scale(10.0283)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6476 19.1991) scale(10.0283)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint31_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.5653 33.6715) scale(10.0905)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.7215 15.3241) scale(10.0907 10.0904)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFCF42" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint33_radial_716_2603"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.3877 10.0465) scale(16.292)"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="0.086" stopColor="#FFC93E" />
        <stop offset="0.204" stopColor="#FFB731" />
        <stop offset="0.34" stopColor="#FF9A1D" />
        <stop offset="0.489" stopColor="#FF7201" />
        <stop offset="0.496" stopColor="#FF7000" />
        <stop offset="1" stopColor="#FF001C" />
      </radialGradient>
      <linearGradient
        id="paint34_linear_716_2603"
        x1="19.9595"
        y1="25.0434"
        x2="12.3456"
        y2="17.4294"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF001C" stopOpacity="0" />
        <stop offset="1" stopColor="#C1001C" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_716_2603"
        x1="8.13724"
        y1="19.1832"
        x2="12.9089"
        y2="29.741"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF2E8" />
        <stop offset="1" stopColor="#E7D2B9" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_716_2603"
        x1="19.9707"
        y1="24.0766"
        x2="23.1173"
        y2="32.1473"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF2E8" />
        <stop offset="1" stopColor="#E7D2B9" />
      </linearGradient>
      <clipPath id="clip0_716_2603">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconSolarPower;
