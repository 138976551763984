import React, {useEffect, useState} from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import { ProfileForm } from "components/Forms";
import { observer, inject } from "mobx-react";
import _ from "lodash";
import classNames from "classnames";
import {
  IconProfileHouses,
  IconProfileOutline,
  IconCheckCircle,
  IconClose,
} from "components/Svgs";
import { withModal } from "hocs/withModal";
import { EmailConfirmModal } from "components/Modals";
import { firebaseAuth, getEmailCredentials } from 'utils/firebase';
import { getUserAbbreviation, logOut, cleanUpPhone } from "utils/helpers/userHelper";
import "antd/dist/antd.css";
import { USER_KEY } from "constants/localStorage";
import { ERRORS_MESSAGES } from "constants/messages";
import { localStorageStrToObject } from "utils/helpers/common";
import { LOGIN_PATH } from "constants/paths";
import "./styles.scss";


const Profile = observer(({ authStore, modalVisible, handleModalVisible }) => {
  const [firebaseUser, setFirebaseUser] = useState({});
  const [messageId, setMessageId] = useState();
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  useEffect(async() => {
    let user = firebaseAuth.currentUser;
    if (!user) {
      user = await new Promise((resolve) => firebaseAuth.onAuthStateChanged(user => resolve(user)));
    }
    setFirebaseUser(user);
  }, []);

  const generalHandleError = error => {
    if (error && _.has(error, "response") && error.response.status === 401) {
      logOut();
    } else {
      setIsError(true);
      setMessageId(ERRORS_MESSAGES[error.code]);
    }
  };

  const generalHandleSuccess = (handleSuccess) => {
    handleSuccess && handleSuccess();
    setIsError(false);
    setMessageId(handleSuccess ? null : "profile.profileUpdated");
  };

  const onClickOkModal = () => {
    handleModalVisible();
    localStorage.clear();
    history.push(LOGIN_PATH);
  };

  const handleProfileSubmit = values => {
    const { firstName, lastName, email, password, currentPassword, phone, notifyingAllowed } = values;
    const cleanPhone = cleanUpPhone(phone);
    const user = localStorageStrToObject(USER_KEY);

    if (user.first_name !== firstName || user.last_name !== lastName
      || user.phone !== cleanPhone || user.notifying_allowed !== notifyingAllowed) {
      authStore.updateUserDetailsRequest({
        first_name: firstName,
        last_name: lastName,
        phone: cleanPhone,
        notifying_allowed: notifyingAllowed,
      }, generalHandleSuccess, generalHandleError);
    }

    if (password) {
      authStore.updatePasswordRequest(password, generalHandleSuccess, generalHandleError);
    }

    if (email && firebaseUser.email !== email && currentPassword) {
      const handleReAuthenticateSuccess = () => {
        const handleUpdateEmailSuccess = () => {
          const handleSendVerificationSuccess = () => {
            handleModalVisible();
          }
          authStore.sendEmailVerificationFirebaseRequest(firebaseUser, handleSendVerificationSuccess, generalHandleError);
        }
        authStore.updateEmailRequest(email, handleUpdateEmailSuccess, generalHandleError);
      }
      handleReAuthenticate(currentPassword, handleReAuthenticateSuccess);
    }
  };

  const handleReAuthenticate = (currentPassword, handleSuccess) => {
    const credential = getEmailCredentials(firebaseUser.email, currentPassword);
    authStore.reauthenticateRequest(credential, () => generalHandleSuccess(handleSuccess), generalHandleError);
  };

  const resentLink = () => {
    authStore.sendEmailVerificationFirebaseRequest(firebaseUser, generalHandleSuccess, generalHandleError);
  };


  const renderMessage = () => (
    <div className={classNames("profile-message-container", { "red": isError })}>
      <div className="profile-message">
        <div className="check-icon">
          {!isError && <IconCheckCircle />}
        </div>
        <FormattedMessage id={messageId} />
      </div>
      <div
        className="profile-message-close"
        onClick={() => {
          setMessageId(null);
        }}
      >
        <IconClose/>
      </div>
    </div>
  );

  return (
    <div className="profile-page">
      <Spin spinning={authStore.loading}>
      {messageId && renderMessage()}
      <EmailConfirmModal
        modalVisible={modalVisible}
        handleModalVisible={onClickOkModal}
        resentLink={resentLink}
      />
      <div className="profile-container">
        <div className="profile-header">
          <div className="profile-header__title">
            <FormattedMessage id="profile.profileSettingsTitle" />
          </div>
        </div>
        <div className="profile-content">
          <div className="profile-content__left">
            <div className="header-icons">
              <div className="hihome-icon">
                <IconProfileOutline name={getUserAbbreviation()} />
              </div>
            </div>
            <div className="profile-form">
              {!_.isEmpty(firebaseUser) && (
                <ProfileForm
                  handleProfileSubmit={handleProfileSubmit}
                  handleReAuthenticate={handleReAuthenticate}
                  isError={messageId}
                />
              )}
            </div>
          </div>
          <div className="profile-content__right">
            <div className="title">
              <FormattedMessage id="profile.hiHomeTitle" />
            </div>
            <div className="image">
              <IconProfileHouses />
            </div>
          </div>
        </div>
      </div>
      </Spin>
    </div>
  );
});

export default inject("authStore")(withModal(Profile));
