import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { ReactPhotoCollage } from "react-photo-collage";
import { Col, Row } from "antd";
import { getHouseDetailPhotoHeight } from "utils/helpers/housesHelper/common";
import { formatPhotoURL } from "utils/helpers/housesHelper/formatter";
import "./styles.scss";

const HouseAllPhotosView = ({ houseDetails, screenSize }) => {
  const [layout, setLayout] = useState([]);

  useEffect(() => {
    const layoutArray = [];
    if (_.has(houseDetails, "photos") && _.isArray(houseDetails.photos) && !_.isEmpty(houseDetails.photos)) {
      houseDetails.photos.forEach((photo, index) => {
        // calculates the number of house photos for each row
        const moduloFive = index % 5;
        if ([0, 2].includes(moduloFive)) {
          layoutArray.push(1);
        } else {
          layoutArray[layoutArray.length - 1] += 1;
        }
      });
    }
    setLayout(layoutArray);
  }, []);

  const getHeight = () =>  {
    const photoHeight = getHouseDetailPhotoHeight(screenSize.height);
    return _.fill(Array(layout.length), `${photoHeight}px`, 0, layout.length);
  };

  const getPhotos = () => {
    const photos = []
    if (_.has(houseDetails, "photos") && _.isArray(houseDetails.photos)) {
      houseDetails.photos.forEach(item => photos.push({'source': formatPhotoURL(item.href)}));
    }
    return photos;
  };

  const setting = {
    width: '600px',
    height: getHeight(),
    layout,
    photos: getPhotos(),
    showNumOfRemainingPhotos: true,
  };

  const collageContent  = () => (
    <div className="collage_photos">
      <ReactPhotoCollage {...setting} />
    </div>
  );

  if (_.isEmpty(layout)) {
    return null;
  }

  return (
    <div className="all-photos-container">
      <Row>
        <Col span={24}>
          {collageContent()}
        </Col>
      </Row>
    </div>
  );
};

HouseAllPhotosView.propTypes = {
  houseDetails: PropTypes.object,
  screenSize: PropTypes.object,
};

export default HouseAllPhotosView;
