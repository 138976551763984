import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import PropTypes from "prop-types";
import { ProgressBar } from "components/DataDisplay";
import { OnboardingLayout } from "components/Layouts";
import { ONBOARDING_STEPS, PROGRESS_BAR_STEPS } from "containers/Onboarding/config/stepsConfig";
import { TOP_MATCHES } from "constants/paths";
import { DATA_KIND } from "constants/common";
import {
  CRITERIA_DATA_KEY,
  LAST_SAVED_ONBOARDING_STEP_KEY,
  NON_AUTHENTICATED_USER_DATA_KEY,
  ONBOARDING_DATA_KEY,
  TOKEN_KEY
} from "constants/localStorage";
import { firebaseAuth } from "utils/firebase";
import {
  DATA_KEYS_BY_STEP,
  calculateProgressSubStepNumber,
  formatOnboardingData
} from "utils/helpers/onboardingHelper";
import { initializeGTM, trackEvent } from "utils/helpers/userHelper";
import { localStorageStrToObjectOrNull, setObjectToLocalStorage } from "utils/helpers/common";
import { CALCULATION_MILLISECONDS_TO_WAIT } from "./constants";
import "./styles.scss";

const Onboarding = observer(({ authStore, onboardingStore, queryParamGid }) => {
  const [firebaseUser, setFirebaseUser] = useState(null);
  const currentItem = ONBOARDING_STEPS[onboardingStore.currentStep];
  const currentStep = onboardingStore.retrieveCurrentStep();
  const history = useHistory();
  const onboardingData = onboardingStore.retrieveOnboardingData();
  const stepData = onboardingStore.retrieveStepData();

  const Step = currentItem ? currentItem.content : null;

  useEffect(async() => {
    let user = firebaseAuth.currentUser;
    if (!user) {
      user = await new Promise((resolve) => firebaseAuth.onAuthStateChanged(user => resolve(user)));
    }
    setFirebaseUser(user);

    if (!localStorage.getItem(TOKEN_KEY) && !localStorage.getItem(ONBOARDING_DATA_KEY)) {
      setObjectToLocalStorage(ONBOARDING_DATA_KEY, { data: {}, is_finished: false, step: null });
    }

    const handleSuccess = responseData => {
      const currentStep = onboardingStore.retrieveCurrentStep();
      if (currentStep >= ONBOARDING_STEPS.length - 1) {
        handleCalculatingStep(responseData.data);
      }
      if (responseData.is_finished) {
        onFinishOnboarding();
      }
    };
    onboardingStore.getOnboardingDataRequest(handleSuccess);
  }, []);

  const getCurrentSubStepNumber = () => {
    const progressBarProps = currentItem.progressBarProps;
    const data = {
      currentStepNumber: progressBarProps.currentStepNumber,
      initialSubStepNumber: progressBarProps.currentSubStepNumber,
      mode: progressBarProps.mode || null,
      onboardingData: stepData
    };
    return calculateProgressSubStepNumber(data);
  };

  const getNextButtonDisabledValue = () => {
    if (_.has(currentItem, 'checkNextButtonDisabled')) {
      return currentItem.checkNextButtonDisabled(stepData);
    }
    return false;
  };

  const handleFormChange = changedField => onboardingStore.updateStepData(changedField);

  const onBackButtonClick = () => {
    if (currentStep !== 0) {
      onboardingStore.setCurrentStep(currentStep - 1);
      onboardingStore.setStepData();
    }
  };

  const onFinishOnboarding = () => {
    authStore.getUserDetailsRequest();
    initializeGTM();
    trackEvent("onboarding_complete", {});
    const onboardingData = localStorageStrToObjectOrNull(ONBOARDING_DATA_KEY);
    if (onboardingData) {
      setObjectToLocalStorage(CRITERIA_DATA_KEY, _.omit(onboardingData.data, DATA_KEYS_BY_STEP[0]));
      setObjectToLocalStorage(NON_AUTHENTICATED_USER_DATA_KEY, _.pick(onboardingData.data, DATA_KEYS_BY_STEP[0]));
      localStorage.removeItem(ONBOARDING_DATA_KEY);
      localStorage.removeItem(LAST_SAVED_ONBOARDING_STEP_KEY);
    }
    history.push(TOP_MATCHES);
  };

  const onNextButtonClick = formName => {
    if (_.isNull(formName)) {
      onSubmitForm();
    }
  };

  const handleCalculatingStep = (data) => {
    setTimeout(() => onSubmitForm(data), CALCULATION_MILLISECONDS_TO_WAIT);
  };

  const trackOnboarding = step => {
    if (step < 6) {
      initializeGTM();
      trackEvent("onboarding_view", { step: step + 1 });
    }
  };

  const onSubmitForm = (formValues = {}) => {
    const handleSuccess = responseData => {
      if (responseData.is_finished) {
        onFinishOnboarding();
      } else {
        const nextStep = responseData.step + 1;
        onboardingStore.setCurrentStep(nextStep);
        onboardingStore.setStepData();
        if (nextStep === ONBOARDING_STEPS.length - 1 && !responseData.is_finished) {
          handleCalculatingStep(responseData.data);
        }
      }
    };
    const step = onboardingStore.retrieveCurrentStep();
    trackOnboarding(step);
    const requestData = {
      data: formatOnboardingData(formValues, stepData),
      // data: formatOnboardingData(formValues, onboardingStore.retrieveStepData()),
      step: step >= 6 ? 6 : step,
    };
    onboardingStore.saveOnboardingDataRequest(requestData, handleSuccess);
  };

  const renderProgressBar = () => (
    <div className="onboarding-progress-bar">
      <ProgressBar
        {...currentItem.progressBarProps}
        currentSubStepNumber={getCurrentSubStepNumber()}
        steps={PROGRESS_BAR_STEPS(stepData)}
      />
    </div>
  );

  if (_.isNil(currentItem)) {
    return null;
  }

  return (
    <OnboardingLayout
      formName={currentItem.formName}
      isLoading={onboardingStore.retrieveLoading()}
      isShowAuthButtons={currentStep === 0 && !localStorage.getItem(TOKEN_KEY)}
      nextButtonDisabled={getNextButtonDisabledValue()}
      onBackButtonClick={onBackButtonClick}
      onNextButtonClick={onNextButtonClick}
      {...currentItem.layoutProps}
    >
      <div className={classNames("onboarding-container", { withProgressBar: currentItem.isShowProgressBar })}>
        {currentItem.isShowProgressBar && renderProgressBar()}
        <Step
          dataKind={DATA_KIND.onboarding}
          formName={currentItem.formName}
          firebaseUser={firebaseUser}
          onboardingData={onboardingData}
          stepData={stepData}
          queryParamGid={queryParamGid}
          handleFormChange={handleFormChange}
          onSubmitForm={onSubmitForm}
        />
      </div>
    </OnboardingLayout>
  );
});

Onboarding.propTypes = {
  queryParamGid: PropTypes.string,
};

export default inject("authStore", "onboardingStore")(Onboarding);
