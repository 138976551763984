import React from "react";

const IconLogout = (props) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8484 8.9235H9.41541C8.90562 8.9235 8.49232 8.5102 8.49232 8.00041C8.49232 7.49063 8.90562 7.07733 9.41541 7.07733H16.8484L15.6549 5.8839C15.2944 5.52347 15.2944 4.93897 15.6549 4.57848C16.0153 4.21798 16.5998 4.21798 16.9603 4.57848L19.7294 7.34755C19.7508 7.3689 19.771 7.39124 19.7902 7.41462C19.7949 7.42035 19.7989 7.4265 19.8034 7.43235C19.8175 7.45026 19.8314 7.46823 19.8441 7.48718C19.8478 7.49272 19.8508 7.49856 19.8544 7.50416C19.8671 7.52398 19.8795 7.54398 19.8907 7.56484C19.8929 7.56896 19.8946 7.57333 19.8968 7.57746C19.9084 7.60004 19.9196 7.62293 19.9294 7.64657C19.9306 7.64946 19.9315 7.65253 19.9327 7.65549C19.9427 7.68035 19.952 7.70552 19.9599 7.73143C19.9609 7.73469 19.9615 7.73807 19.9624 7.7414C19.9698 7.76681 19.9767 7.79247 19.9819 7.81875C19.9834 7.82657 19.9841 7.83463 19.9855 7.84251C19.9894 7.86429 19.9932 7.88608 19.9953 7.90829C19.9985 7.93875 20 7.96952 20 8.00041C20 8.03131 19.9985 8.06208 19.9953 8.0926C19.9932 8.11512 19.9892 8.13709 19.9855 8.15906C19.9841 8.16669 19.9834 8.17451 19.9819 8.18208C19.9766 8.2086 19.9698 8.23451 19.9623 8.26017C19.9614 8.26319 19.9609 8.26639 19.9599 8.2694C19.952 8.29543 19.9427 8.32085 19.9326 8.34596C19.9314 8.34866 19.9306 8.35156 19.9295 8.35426C19.9196 8.37808 19.9084 8.40109 19.8966 8.4238C19.8946 8.4278 19.8929 8.43199 19.8908 8.43599C19.8794 8.45697 19.867 8.47716 19.8541 8.49716C19.8506 8.50257 19.8477 8.5083 19.8441 8.51359C19.8314 8.5326 19.8174 8.5507 19.8034 8.56867C19.7989 8.57445 19.7949 8.58054 19.7903 8.5862C19.771 8.60953 19.7508 8.63193 19.7295 8.65328L16.9604 11.4224C16.7802 11.6027 16.5439 11.6928 16.3077 11.6928C16.0714 11.6928 15.8352 11.6027 15.655 11.4224C15.2945 11.0619 15.2945 10.4774 15.655 10.1169L16.8484 8.9235Z"
    />
    <path
      d="M7.55246 0.449219C10.0721 0.449219 12.4164 1.6992 13.8235 3.79282C14.1078 4.2159 13.9953 4.78944 13.5722 5.07381C13.1491 5.35812 12.5756 5.24575 12.2912 4.82255C11.2277 3.24007 9.45623 2.29533 7.55246 2.29533C4.40689 2.29539 1.84773 4.85449 1.84773 8.00012C1.84773 11.1457 4.40689 13.7048 7.55246 13.7048C9.45063 13.7048 11.2192 12.7643 12.2835 11.1891C12.5689 10.7666 13.1426 10.6556 13.5651 10.9409C13.9875 11.2264 14.0987 11.8001 13.8133 12.2226C12.4052 14.3068 10.0647 15.551 7.55246 15.551C3.38892 15.551 0.00156116 12.1637 0.00156116 8.00012C0.00156116 3.83651 3.38892 0.449219 7.55246 0.449219Z"
    />
  </svg>
);

export default IconLogout;
