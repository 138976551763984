import React, { useState } from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import CustomRadio from "components/Controls/Radio/CustomRadio";
import { getPriorityCriteria } from "utils/helpers/criteriaHelper";
import {ALL_CRITERIA_DATA} from "../../../../../Onboarding/config/buttonsConfig";
import { PRIORITY_COLUMNS } from "../../../../../Onboarding/config/tablesConfig";
import "./styles.scss";

const SecondStep = props => {
  const { currentStepData, setCurrentStepData } = props;
  const [selectedPriority, setSelectedPriority] = useState(currentStepData);
  const data = getPriorityCriteria(currentStepData);

  const checkSelectedPriority = (criteria, priority) => selectedPriority[criteria.kind].priority === priority;

  const onRadioChange = e => {
    const newCurrentStepData = _.cloneDeep(currentStepData);
    let name = e.target.name;
    newCurrentStepData[name].priority = e.target.value;
    setSelectedPriority(newCurrentStepData);
    setCurrentStepData(newCurrentStepData);
  };

  const renderFirstColumn = record => {
    const criterionData = ALL_CRITERIA_DATA.filter(item => item.key === record.kind)[0];
    return (
      <div className="second-step__criteria_column">
        <div className="second-step__criteria_column_icon">
          {criterionData.icon}
        </div>
        <FormattedMessage id={criterionData.message} />
      </div>
    );
  };

  const renderRadioButton = (item, priority) => (
    <CustomRadio
      checked={checkSelectedPriority(item, priority)}
      onChange={onRadioChange}
      name={item.kind}
      value={priority}
    />
  );

  return (
    <div className="second-step">
      <Table
        bordered={false}
        columns={PRIORITY_COLUMNS(renderFirstColumn, renderRadioButton)}
        dataSource={data}
        pagination={false}
        size="middle"
      />
    </div>
  );
};

SecondStep.propTypes = {
  currentStepData: PropTypes.object,
  setCurrentStepData: PropTypes.func,
};

export default SecondStep;
