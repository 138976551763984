import {
  greenMapDot,
  greenMapDotViewed,
  orangeMapDot,
  orangeMapDotViewed,
  redMapDot,
  redMapDotViewed,
  saladMapDot,
  saladMapDotViewed,
  yellowMapDot,
  yellowMapDotViewed
} from "images";

const getBooleanValueByScore = (score, value) => {
  switch(true) {
    case score === 100:
      return 'green-value';
    case score === 0 && value!== null:
      return 'red-value';
    default:
      return 'grey-value';
  }
};

const getClassnameForRating = rating => {
  switch(true) {
    case rating >= 8:
      return 'green-background';
    case rating >= 6:
      return 'green-yellow-background';
    case rating >= 4:
      return 'yellow-background';
    case rating >= 2:
      return 'orange-background';
    default:
      return 'no-background';
  }
};

const getClassnameForScore = score => {
  switch(true) {
    case score >= 80:
      return 'green-background';
    case score >= 60:
      return 'green-yellow-background';
    case score >= 40:
      return 'yellow-background';
    case score >= 20:
      return 'orange-background';
    case score >= 0:
      return 'red-background';
    default:
      return 'no-background';
  }
};

const getClassnameForScoreViewed = score => {
  switch(true) {
    case score >= 80:
      return 'light-green-background';
    case score >= 60:
      return 'light-green-yellow-background';
    case score >= 40:
      return 'light-yellow-background';
    case score >= 20:
      return 'light-orange-background';
    case score >= 0:
      return 'light-red-background';
    default:
      return 'no-background';
  }
};

const getColorForScore = score => {
  switch(true) {
    case score >= 80:
      return '#4AB272';
    case score >= 60:
      return '#C9ED62';
    case score >= 40:
      return '#F5E237';
    case score >= 20:
      return '#FFA425';
    case score >= 0:
      return '#FB5D5D';
    default:
      return 'no-background';
  }
};

const getHouseDetailPhotoHeight = screenHeight => {
  switch (true) {
    case screenHeight < 600:
      return 200;
    case screenHeight < 750:
      return 240;
    case screenHeight < 1000:
      return 280;
    case screenHeight < 1200:
      return 320;
    default:
      return 360;
    }
};

const getImageByScore = score => {
  switch(true) {
    case score >= 80:
      return greenMapDot;
    case score >= 60:
      return saladMapDot;
    case score >= 40:
      return yellowMapDot;
    case score >= 20:
      return orangeMapDot;
    case score >= 0:
      return redMapDot;
    default:
      return null;
  }
};

const getValueClassnameByScore = (score, value) => {
  switch(true) {
    case score >= 80:
      return 'green-value';
    case score >= 60:
      return 'green-yellow-value';
    case score >= 40:
      return 'yellow-value';
    case score >= 0 && value!== null:
      return 'orange-value';
    default:
      return 'grey-value';
  }
};

const getValueClassnameByScoreRangeType = (score, value) => {
  switch(true) {
    case score === 100:
      return 'green-value';
    case score >= 67:
      return 'green-yellow-value';
    case score >= 34:
      return 'yellow-value';
    case score >= 0 && value!== null:
      return 'orange-value';
    default:
      return 'grey-value';
  }
};

const getViewedDotImageByScore = score => {
  switch(true) {
    case score >= 80:
      return greenMapDotViewed;
    case score >= 60:
      return saladMapDotViewed;
    case score >= 40:
      return yellowMapDotViewed;
    case score >= 20:
      return orangeMapDotViewed;
    case score >= 0:
      return redMapDotViewed;
    default:
      return null;
  }
};

export {
  getBooleanValueByScore,
  getClassnameForRating,
  getClassnameForScore,
  getClassnameForScoreViewed,
  getColorForScore,
  getImageByScore,
  getHouseDetailPhotoHeight,
  getValueClassnameByScore,
  getValueClassnameByScoreRangeType,
  getViewedDotImageByScore,
};
