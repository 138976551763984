import _ from "lodash";
import { firebaseAuth } from "../firebase";
import { HOUSE_VIEWS, HOUSES_PER_PAGE } from "constants/houses";

const getCurrentPageNumber = (currentPage, housesData, isLastItemOnPage = false) => {
  const numberOfPages = Math.ceil(housesData.total_results / HOUSES_PER_PAGE);
  const isCurrentPageLast = currentPage >= numberOfPages;
  return isCurrentPageLast && isLastItemOnPage ? (currentPage - 1 || 1) : currentPage;
};

const handleFavoritesListMapView = (propertyId, housesStore, onPaginationUpdate) => {
  const houseItem = _.find(housesStore.housesData.data, item => item.id === propertyId);
  const data = {
    user_id: firebaseAuth.currentUser.uid,
    house_id: propertyId,
  };

  const handleSuccess = () => {
    houseItem.is_favorite = !houseItem.is_favorite;
    if (housesStore.view === HOUSE_VIEWS.favorites) {
      housesStore.removeHouseFromFavorites(propertyId);
      const isLastItemOnPage = housesStore.housesData.total_results % HOUSES_PER_PAGE === 0;
      const isRemovedFromFavorites = houseItem.is_favorite === false;
      onPaginationUpdate && onPaginationUpdate(isLastItemOnPage, isRemovedFromFavorites);
    }
  };
  sendFavoritesRequest(data, houseItem, housesStore, handleSuccess);
};

const sendFavoritesRequest = (data, houseItem, housesStore, handleSuccess) => {
  if (houseItem.is_favorite) {
    housesStore.delHousesFavoritesRequest(data, handleSuccess);
  } else {
    housesStore.markHousesFavoriteRequest(data, handleSuccess);
  }
};

export { getCurrentPageNumber, handleFavoritesListMapView };
