import React from "react";

const IconCheckCircle = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5508 2.92188L9.92969 10.6602L7.13282 7.85937C5.99609 6.73047 4.23047 8.5 5.36719 9.62891L9.11719 13.3828C9.34376 13.6094 9.65626 13.75 10 13.75C10.3789 13.75 10.7188 13.5781 10.9492 13.3125L18.4492 4.54688C19.4883 3.33594 17.5899 1.72266 16.5508 2.92188Z" fill="white"/>
<path d="M10 20C4.48438 20 0 15.5156 0 10C0 4.48438 4.48438 0 10 0C11.0664 0 12.1172 0.167969 13.1211 0.496094C14.7109 1.01562 13.9023 3.38281 12.3438 2.87109C11.5898 2.625 10.8008 2.5 10 2.5C5.86328 2.5 2.5 5.86328 2.5 10C2.5 14.1367 5.86328 17.5 10 17.5C14.1367 17.5 17.5 14.1367 17.5 10C17.5 9.71484 17.4844 9.4375 17.4531 9.16406C17.3555 7.46875 19.7344 7.29688 19.9375 8.89063C19.9766 9.25391 20 9.625 20 10.0039C20 15.5156 15.5156 20 10 20Z" fill="white"/>
</svg>


);

export default IconCheckCircle;
