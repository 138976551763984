import React from "react";
import { FormattedMessage } from "react-intl";
import {
  IconArchitecturalStyle,
  IconBalconyPorch,
  IconBasement,
  IconBathrooms,
  IconBedrooms,
  IconBikeability,
  IconCentralAc,
  IconCommute,
  IconCurbAppeal,
  IconCustomDistanceTo,
  IconGarage,
  IconGraniteCountertops,
  IconHardwoodFloors,
  IconHomeType,
  IconInteriorAesthetics,
  IconInteriorLayout,
  IconBiking,
  IconDriving,
  IconFoot,
  IconTransport,
  IconLocation,
  IconLocationsMap,
  IconLotSize,
  IconLowCrime,
  IconLowTaxes,
  IconNewlyBuilt,
  IconParking,
  IconPriceRange,
  IconProximityToGrocery,
  IconProximityToParks,
  IconPublicTransit,
  IconQuietness,
  IconSchoolBag,
  IconSingleStory,
  IconSolarPower,
  IconSquareFootage,
  IconWalkability,
} from "components/Svgs";
import { CRITERIA_MAP } from "constants/criteria";

export const BASIC_CRITERIA = [
  {
    message: "dashboard.criteria.locationMessage",
    key: CRITERIA_MAP.location,
    icon: <IconLocation />,
  },
  {
    message: "dashboard.criteria.homeTypeMessage",
    key: CRITERIA_MAP.prop_type,
    icon: <IconHomeType />,
  },
  {
    message: "dashboard.criteria.priceRangeMessage",
    key: CRITERIA_MAP.price,
    icon: <IconPriceRange />,
  },
  {
    message: "dashboard.criteria.bedroomsMessage",
    key: CRITERIA_MAP.bedrooms,
    icon: <IconBedrooms />,
  },
  {
    message: "dashboard.criteria.bathroomsMessage",
    key: CRITERIA_MAP.bathrooms,
    icon: <IconBathrooms />,
  },
];

export const ADDITIONAL_CRITERIA = [
  {
    message: "dashboard.form.squareFootage",
    key: CRITERIA_MAP.building_size,
    icon: <IconSquareFootage />,
  },
  {
    message: "dashboard.form.lotSize",
    key: CRITERIA_MAP.lot_size,
    icon: <IconLotSize />,
  },
  {
    message: "dashboard.form.garage",
    key: CRITERIA_MAP.garage,
    icon: <IconGarage />,
  },
  {
    message: "dashboard.form.parking",
    key: CRITERIA_MAP.parking,
    icon: <IconParking />,
  },
  {
    message: "dashboard.form.centralAc",
    key: CRITERIA_MAP.central_ac,
    icon: <IconCentralAc />,
  },
  {
    message: "dashboard.form.newlyBuilt",
    key: CRITERIA_MAP.newly_built,
    icon: <IconNewlyBuilt />,
  },
  {
    message: "dashboard.form.singleStory",
    key: CRITERIA_MAP.single_story,
    icon: <IconSingleStory />,
  },
  {
    message: "dashboard.form.basement",
    key: CRITERIA_MAP.basement,
    icon: <IconBasement />,
  },
  {
    message: "dashboard.form.solarPowerPotential",
    key: CRITERIA_MAP.solar_power,
    icon: <IconSolarPower />,
  }
];

export const LOCATION_CRITERIA = [
  {
    message: "dashboard.form.schoolQuality",
    key: CRITERIA_MAP.school_quality,
    icon: <IconSchoolBag />,
  },
  {
    message: "dashboard.form.lowCrime",
    key: CRITERIA_MAP.crime,
    icon: <IconLowCrime />,
  },
  {
    message: "dashboard.form.commute",
    key: CRITERIA_MAP.commute,
    icon: <IconCommute />,
  },
  {
    message: "dashboard.form.customDistanceTo",
    key: CRITERIA_MAP.custom_distance,
    icon: <IconCustomDistanceTo />,
  },
  {
    message: "dashboard.form.publicTransitScore",
    key: CRITERIA_MAP.transit,
    icon: <IconPublicTransit />,
  },
  {
    message: "dashboard.form.proximityToGrocery",
    key: CRITERIA_MAP.grocery,
    icon: <IconProximityToGrocery />,
  },
  {
    message: "dashboard.form.proximityToParks",
    key: CRITERIA_MAP.parks,
    icon: <IconProximityToParks />,
  },
  {
    message: "dashboard.form.walkability",
    key: CRITERIA_MAP.walkability,
    icon: <IconWalkability />,
  },
  {
    message: "dashboard.form.quietness",
    key: CRITERIA_MAP.quietness,
    icon: <IconQuietness />,
  },
  {
    message: "dashboard.form.bikeability",
    key: CRITERIA_MAP.bikeability,
    icon: <IconBikeability />,
  },
];

export const COMING_SOON = [
  {
    message: "dashboard.form.locationsMap",
    icon: <IconLocationsMap />,
  },
  {
    message: "dashboard.form.interiorLayout",
    icon: <IconInteriorLayout />,
  },
  {
    message: "dashboard.form.curbAppeal",
    icon: <IconCurbAppeal />,
  },
  {
    message: "dashboard.form.hardwoodFloors",
    icon: <IconHardwoodFloors />,
  },
  {
    message: "dashboard.form.interiorAesthetics",
    icon: <IconInteriorAesthetics />,
  },
  {
    message: "dashboard.form.lowTaxes",
    icon: <IconLowTaxes />,
  },
  {
    message: "dashboard.form.architecturalStyle",
    icon: <IconArchitecturalStyle />,
  },
  {
    message: "dashboard.form.balconyPorch",
    icon: <IconBalconyPorch />,
  },
  {
    message: "dashboard.form.graniteCountertops",
    icon: <IconGraniteCountertops />,
  },
];

export const PRIORITY_MESSAGES = {
  0: "dashboard.form.notImportant",
  1: "dashboard.form.slightlyImportant",
  2: "dashboard.form.somewhatImportant",
  3: "dashboard.form.important",
  4: "dashboard.form.veryImportant",
  5: "dashboard.form.mostImportant"
};

export const CRITERIA_ICON_MAP = {
  [CRITERIA_MAP.price]: IconPriceRange,
  [CRITERIA_MAP.location]: IconLocation,
  [CRITERIA_MAP.prop_type]: IconHomeType,
  [CRITERIA_MAP.bedrooms]: IconBedrooms,
  [CRITERIA_MAP.bathrooms]: IconBathrooms,
  [CRITERIA_MAP.grocery]: IconProximityToGrocery,
  [CRITERIA_MAP.custom_distance]: IconCustomDistanceTo,
  [CRITERIA_MAP.commute]: IconCommute,
  [CRITERIA_MAP.school_quality]: IconSchoolBag,
  [CRITERIA_MAP.parks]: IconProximityToParks,
  [CRITERIA_MAP.crime]: IconLowCrime,
  [CRITERIA_MAP.transit]: IconPublicTransit,
  [CRITERIA_MAP.walkability]: IconWalkability,
  [CRITERIA_MAP.bikeability]: IconBikeability,
  [CRITERIA_MAP.quietness]: IconQuietness,
  [CRITERIA_MAP.garage]: IconGarage,
  [CRITERIA_MAP.parking]: IconParking,
  [CRITERIA_MAP.central_ac]: IconCentralAc,
  [CRITERIA_MAP.basement]: IconBasement,
  [CRITERIA_MAP.single_story]: IconSingleStory,
  [CRITERIA_MAP.solar_power]: IconSolarPower,
  [CRITERIA_MAP.newly_built]: IconNewlyBuilt,
  [CRITERIA_MAP.building_size]: IconSquareFootage,
  [CRITERIA_MAP.lot_size]: IconLotSize,
};

export const CRITERIA_MESSAGE_MAP = {
  [CRITERIA_MAP.price]: "dashboard.form.priceRangeTitle",
  [CRITERIA_MAP.location]: "dashboard.form.locationTitle",
  [CRITERIA_MAP.prop_type]: "dashboard.form.homeTypeTitle",
  [CRITERIA_MAP.bedrooms]: "dashboard.form.bedroomsTitle",
  [CRITERIA_MAP.bathrooms]: "dashboard.form.bathroomsTitle",
  [CRITERIA_MAP.grocery]: "dashboard.form.proximityToGrocery",
  [CRITERIA_MAP.custom_distance]: "dashboard.form.customDistanceTo",
  [CRITERIA_MAP.commute]: "dashboard.form.commute",
  [CRITERIA_MAP.school_quality]: "dashboard.form.schoolQuality",
  [CRITERIA_MAP.parks]: "dashboard.form.proximityToParks",
  [CRITERIA_MAP.crime]: "dashboard.form.lowCrime",
  [CRITERIA_MAP.transit]: "dashboard.form.publicTransitScore",
  [CRITERIA_MAP.walkability]: "dashboard.form.walkability",
  [CRITERIA_MAP.bikeability]: "dashboard.form.bikeability",
  [CRITERIA_MAP.quietness]: "dashboard.form.quietness",
  [CRITERIA_MAP.garage]: "dashboard.form.garage",
  [CRITERIA_MAP.parking]: "dashboard.form.parking",
  [CRITERIA_MAP.central_ac]: "dashboard.form.centralAc",
  [CRITERIA_MAP.basement]: "dashboard.form.basement",
  [CRITERIA_MAP.single_story]: "dashboard.form.singleStory",
  [CRITERIA_MAP.solar_power]: "dashboard.form.solarPowerPotential",
  [CRITERIA_MAP.newly_built]: "dashboard.form.newlyBuilt",
  [CRITERIA_MAP.building_size]: "dashboard.form.squareFootage",
  [CRITERIA_MAP.lot_size]: "dashboard.form.lotSize",
};

export const LOT_SIZE_VALUES = {
  0: 0,
  1: 1000,
  2: 2000,
  3: 3000,
  4: 4000,
  5: 5000,
  6: 7500,
  7: 10890,
  8: 21780,
  9: 43560,
  10: 87120,
  11: 217800,
  12: 435600,
  13: 871200,
  14: 21780000,
  15: 43560000,
};

export const LOT_SIZE_OPTIONS = [
  { value: '0',  label: <FormattedMessage id="dashboard.form.sqft" values={{number: 0}} /> },
  { value: '1',  label: <FormattedMessage id="dashboard.form.sqft" values={{number: "1,000"}} /> },
  { value: '2',  label: <FormattedMessage id="dashboard.form.sqft" values={{number: "2,000"}} /> },
  { value: '3',  label: <FormattedMessage id="dashboard.form.sqft" values={{number: "3,000"}} /> },
  { value: '4',  label: <FormattedMessage id="dashboard.form.sqft" values={{number: "4,000"}} /> },
  { value: '5',  label: <FormattedMessage id="dashboard.form.sqft" values={{number: "5,000"}} /> },
  { value: '6',  label: <FormattedMessage id="dashboard.form.sqft" values={{number: "7,500"}} /> },
  { value: '7',  label: <FormattedMessage id="dashboard.form.acre" values={{number: 0.25}} /> },
  { value: '8',  label: <FormattedMessage id="dashboard.form.acre" values={{number: 0.5}} /> },
  { value: '9',  label: <FormattedMessage id="dashboard.form.acre" values={{number: 1}} /> },
  { value: '10',  label: <FormattedMessage id="dashboard.form.acres" values={{number: 2}} /> },
  { value: '11',  label: <FormattedMessage id="dashboard.form.acres" values={{number: 5}} /> },
  { value: '12',  label: <FormattedMessage id="dashboard.form.acres" values={{number: 10}} /> },
  { value: '13',  label: <FormattedMessage id="dashboard.form.acres" values={{number: 20}} /> },
  { value: '14',  label: <FormattedMessage id="dashboard.form.acres" values={{number: 50}} /> },
  { value: '15',  label: <FormattedMessage id="dashboard.form.acresPlus" values={{number: 100}} /> },
];

export const SWITCH_MOVEMENT_TYPE = [
  { key: 'driving', icon: IconDriving, message: "dashboard.criteria.switcher.drivingMessage" },
  { key: 'cycling', icon: IconBiking, message: "dashboard.criteria.switcher.bikingMessage" },
  { key: 'walking', icon: IconFoot, message: "dashboard.criteria.switcher.footMessage" },
  { key: 'public_transport', icon: IconTransport, message: "dashboard.criteria.switcher.publicTransportMessage" },
];
