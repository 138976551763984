import React from "react";
import { Tooltip } from "antd";
import "./styles.scss";

const CustomTooltip = ({title, children, ...otherProps}) => (
  <Tooltip
    title={title}
    placement="rightBottom"
    {...otherProps}
  >
    {children}
  </Tooltip>
);

export default CustomTooltip;
