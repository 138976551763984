import React from "react";
import { Steps as AntdSteps } from "antd";
import PropTypes from "prop-types";
// import {IconCheck} from "../../Svgs";

import "./styles.scss";

const ProgressBar = (
  {
    currentStepNumber,
    currentSubStepNumber,
    customClassName,
    disabled,
    isLastStep,
    percent,
    steps
  }
) => {
  const { Step } = AntdSteps;
  const className = customClassName || "progress-bar-container";

  const getPercent = () => {
    const currentStepData = steps[currentStepNumber];
    const percentsForStep = 100 / currentStepData.subStepsNumber;
    return _.isNil(percent) ? percentsForStep * currentSubStepNumber : percent;
  };

  const getStatus = () => {
    const lastStepData = steps[steps.length - 1];
    return (
      isLastStep && currentSubStepNumber >= lastStepData.subStepsNumber - 1 ?
        "finish" :
        "process"
    );
  };

  return (
    <div className={className}>
      <AntdSteps current={currentStepNumber} percent={getPercent()} status={getStatus()}>
        {steps.map(step => (
          <Step
            description={step.description}
            disabled={disabled}
            // icon={index < currentStepNumber ? <IconCheck style={{ width: 50 }} /> : null}
            title={step.title}
            subTitle={step.subTitle}
          />)
        )}
      </AntdSteps>
    </div>
  );
};

ProgressBar.propTypes = {
  currentStepNumber: PropTypes.number,
  currentSubStepNumber: PropTypes.number,
  customClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isLastStep: PropTypes.bool, // needs to get progress bar status correctly
  percent: PropTypes.number,
  steps: PropTypes.array,
};

ProgressBar.defaultProps = {
  currentStepNumber: 0,
  currentSubStepNumber: 0,
  disabled: true,
  isLastStep: false,
};

export default ProgressBar;
