const MAX_BEDROOMS = 100;
const MAX_BATHROOMS = 100;
const MAX_MINUTES = 100;
const MAX_PRICE = 1000000000;
const MAX_SQUARE_FOOTAGE = 1000000;
const RANGE_TYPE_CRITERIA = ['price', 'bedrooms', 'bathrooms', 'building_size', 'lot_size', 'commute', 'custom_distance', 'grocery', 'parks'];
const SYB_TYPE_CRITERIA = ['crime', 'school_quality', 'solar_power', 'quietness', 'walkability', 'bikeability', 'transit', 'flood_risk'];

const CRITERIA_MAP = {
  basement: "basement",
  bathrooms: "bathrooms",
  bedrooms: "bedrooms",
  bikeability: "bikeability",
  building_size: "building_size",
  central_ac: "central_ac",
  crime: "crime",
  commute: "commute",
  custom_distance: "custom_distance",
  flood_risk: "flood_risk",
  garage: "garage",
  grocery: "grocery",
  location: "locations",
  lot_size: "lot_size",
  newly_built: "newly_built",
  parking: "parking",
  parks: "parks",
  price: "price",
  prop_type: "prop_type",
  quietness: "quietness",
  school_quality: "school_quality",
  single_story: "single_story",
  solar_power: "solar_power",
  transit: "transit",
  walkability: "walkability",
};

const NON_PRIORITY_CRITERIA = [
  CRITERIA_MAP.location,
  CRITERIA_MAP.prop_type
];

export {
  CRITERIA_MAP,
  MAX_BATHROOMS,
  MAX_BEDROOMS,
  MAX_MINUTES,
  MAX_PRICE,
  MAX_SQUARE_FOOTAGE,
  NON_PRIORITY_CRITERIA,
  RANGE_TYPE_CRITERIA,
  SYB_TYPE_CRITERIA
};
