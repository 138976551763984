import React from "react";

const IconList = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.75 0H2.25C2.66382 0 3 0.335999 3 0.75C3 1.164 2.66382 1.5 2.25 1.5H0.75C0.336182 1.5 0 1.164 0 0.75C0 0.335999 0.336182 0 0.75 0Z"
    />
    <path
      d="M0.75 5.25H2.25C2.66382 5.25 3 5.586 3 6C3 6.414 2.66382 6.75 2.25 6.75H0.75C0.336182 6.75 0 6.414 0 6C0 5.586 0.336182 5.25 0.75 5.25Z"
    />
    <path
      d="M2.25 10.5H0.75C0.336182 10.5 0 10.836 0 11.25C0 11.664 0.336182 12 0.75 12H2.25C2.66382 12 3 11.664 3 11.25C3 10.836 2.66382 10.5 2.25 10.5Z"
    />
    <path
      d="M5.24927 6.75H11.2493C11.6616 6.75 11.9993 6.41249 11.9993 6C11.9993 5.58751 11.6616 5.25 11.2493 5.25H5.24927C4.83691 5.25 4.49927 5.58751 4.49927 6C4.49927 6.41249 4.83691 6.75 5.24927 6.75Z"
    />
    <path
      d="M11.2493 1.5H5.24927C4.83691 1.5 4.49927 1.16249 4.49927 0.75C4.49927 0.337509 4.83691 0 5.24927 0H11.2493C11.6616 0 11.9993 0.337509 11.9993 0.75C11.9993 1.16249 11.6616 1.5 11.2493 1.5Z"
    />
    <path
      d="M5.24927 12H11.2493C11.6616 12 11.9993 11.6625 11.9993 11.25C11.9993 10.8375 11.6616 10.5 11.2493 10.5H5.24927C4.83691 10.5 4.49927 10.8375 4.49927 11.25C4.49927 11.6625 4.83691 12 5.24927 12Z"
    />
  </svg>
);

export default IconList;
