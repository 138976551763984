import React from "react";

const IconLocationsMap = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1_2)">
      <path
        d="M23.0668 22.9214H8.9341V24.5196H23.0668V22.9214Z"
        fill="url(#paint0_linear_1_2)"
      />
      <path
        d="M23.0668 26.4626H8.9341V28.0608H23.0668V26.4626Z"
        fill="url(#paint1_linear_1_2)"
      />
      <path
        d="M10.6889 24.5196H12.5142L10.916 22.9214H10.6889V24.5196Z"
        fill="url(#paint2_linear_1_2)"
      />
      <path
        d="M15.0452 24.5196H16.8704L15.2723 22.9214H15.0452V24.5196Z"
        fill="url(#paint3_linear_1_2)"
      />
      <path
        d="M19.4006 24.5196H21.2259L19.6278 22.9214H19.4006V24.5196Z"
        fill="url(#paint4_linear_1_2)"
      />
      <path
        d="M10.6889 28.0606H12.5142L10.916 26.4624H10.6889V28.0606Z"
        fill="url(#paint5_linear_1_2)"
      />
      <path
        d="M15.0452 28.0606H16.8704L15.2723 26.4624H15.0452V28.0606Z"
        fill="url(#paint6_linear_1_2)"
      />
      <path
        d="M19.4006 28.0606H21.2259L19.6278 26.4624H19.4006V28.0606Z"
        fill="url(#paint7_linear_1_2)"
      />
      <path
        d="M10.6569 29.7879H8.26646C8.12415 29.7879 8.00883 29.6726 8.00883 29.5302V22.0724C8.00883 21.9979 8.04115 21.9269 8.09746 21.8781L9.29271 20.8388C9.38965 20.7545 9.5339 20.7545 9.63084 20.8388L10.8261 21.8781C10.8824 21.927 10.9147 21.9979 10.9147 22.0724V29.5302C10.9145 29.6726 10.7992 29.7879 10.6569 29.7879Z"
        fill="url(#paint8_linear_1_2)"
      />
      <path
        d="M10.9149 24.3297L9.7921 23.2068C9.71116 23.1155 9.59329 23.0577 9.4616 23.0578C9.21791 23.0581 9.02047 23.2558 9.02072 23.4996C9.02085 23.6308 9.0786 23.7483 9.1696 23.829L10.9149 25.5743V24.3297H10.9149Z"
        fill="url(#paint9_linear_1_2)"
      />
      <path
        d="M9.46284 23.9404C9.70647 23.9404 9.90396 23.7429 9.90396 23.4993C9.90396 23.2557 9.70647 23.0582 9.46284 23.0582C9.21921 23.0582 9.02171 23.2557 9.02171 23.4993C9.02171 23.7429 9.21921 23.9404 9.46284 23.9404Z"
        fill="url(#paint10_linear_1_2)"
      />
      <path
        d="M9.69195 23.4259L9.53507 23.4261L9.53495 23.2692C9.53488 23.2288 9.5022 23.1962 9.46182 23.1962C9.42145 23.1962 9.38882 23.2289 9.38888 23.2693L9.38901 23.4262L9.23213 23.4263C9.19176 23.4264 9.15913 23.4591 9.15913 23.4994C9.15913 23.5398 9.19188 23.5724 9.23226 23.5724L9.38913 23.5723L9.38926 23.7292C9.38932 23.7696 9.42201 23.8022 9.46238 23.8022C9.50276 23.8021 9.53538 23.7694 9.53538 23.7291L9.53526 23.5722L9.69213 23.5721C9.73251 23.572 9.76513 23.5393 9.76513 23.4989C9.76495 23.4586 9.73226 23.4259 9.69195 23.4259Z"
        fill="url(#paint11_linear_1_2)"
      />
      <path
        d="M10.9149 28.1772L9.7921 27.0544C9.71116 26.963 9.59329 26.9053 9.4616 26.9054C9.21791 26.9056 9.02047 27.1033 9.02072 27.3471C9.02085 27.4784 9.0786 27.5959 9.1696 27.6766L10.9149 29.4218V28.1772H10.9149Z"
        fill="url(#paint12_linear_1_2)"
      />
      <path
        d="M9.46284 27.788C9.70647 27.788 9.90396 27.5905 9.90396 27.3468C9.90396 27.1032 9.70647 26.9057 9.46284 26.9057C9.21921 26.9057 9.02171 27.1032 9.02171 27.3468C9.02171 27.5905 9.21921 27.788 9.46284 27.788Z"
        fill="url(#paint13_linear_1_2)"
      />
      <path
        d="M9.69195 27.2734L9.53507 27.2735L9.53495 27.1166C9.53488 27.0762 9.5022 27.0436 9.46182 27.0436C9.42145 27.0437 9.38882 27.0764 9.38888 27.1168L9.38901 27.2736L9.23213 27.2738C9.19176 27.2738 9.15913 27.3065 9.15913 27.3469C9.15913 27.3872 9.19188 27.4199 9.23226 27.4199L9.38913 27.4198L9.38926 27.5766C9.38932 27.617 9.42201 27.6496 9.46238 27.6496C9.50276 27.6496 9.53538 27.6169 9.53538 27.5765L9.53526 27.4196L9.69213 27.4195C9.73251 27.4194 9.76513 27.3867 9.76513 27.3464C9.76495 27.306 9.73226 27.2734 9.69195 27.2734Z"
        fill="url(#paint14_linear_1_2)"
      />
      <path
        d="M15.0153 29.7879H12.6248C12.4825 29.7879 12.3672 29.6726 12.3672 29.5302V22.0724C12.3672 21.9979 12.3995 21.9269 12.4558 21.8781L13.6511 20.8388C13.748 20.7545 13.8923 20.7545 13.9892 20.8388L15.1845 21.8781C15.2408 21.927 15.2731 21.9979 15.2731 22.0724V29.5302C15.2729 29.6726 15.1575 29.7879 15.0153 29.7879Z"
        fill="url(#paint15_linear_1_2)"
      />
      <path
        d="M15.2733 24.3297L14.1505 23.2068C14.0695 23.1155 13.9517 23.0577 13.82 23.0578C13.5763 23.0581 13.3788 23.2558 13.3791 23.4996C13.3792 23.6308 13.437 23.7483 13.528 23.829L15.2732 25.5743V24.3297H15.2733Z"
        fill="url(#paint16_linear_1_2)"
      />
      <path
        d="M13.8207 23.9404C14.0643 23.9404 14.2618 23.7429 14.2618 23.4993C14.2618 23.2557 14.0643 23.0582 13.8207 23.0582C13.5771 23.0582 13.3796 23.2557 13.3796 23.4993C13.3796 23.7429 13.5771 23.9404 13.8207 23.9404Z"
        fill="url(#paint17_linear_1_2)"
      />
      <path
        d="M14.0503 23.4259L13.8934 23.4261L13.8933 23.2692C13.8933 23.2288 13.8606 23.1962 13.8202 23.1962C13.7798 23.1962 13.7472 23.2289 13.7473 23.2693L13.7474 23.4262L13.5905 23.4263C13.5501 23.4264 13.5175 23.4591 13.5175 23.4994C13.5175 23.5398 13.5503 23.5724 13.5906 23.5724L13.7475 23.5723L13.7476 23.7292C13.7477 23.7696 13.7804 23.8022 13.8208 23.8022C13.8611 23.8021 13.8938 23.7694 13.8938 23.7291L13.8936 23.5722L14.0505 23.5721C14.0909 23.572 14.1235 23.5393 14.1235 23.4989C14.1234 23.4586 14.0906 23.4259 14.0503 23.4259Z"
        fill="url(#paint18_linear_1_2)"
      />
      <path
        d="M15.2733 28.1772L14.1505 27.0544C14.0695 26.963 13.9517 26.9053 13.82 26.9054C13.5763 26.9056 13.3788 27.1033 13.3791 27.3471C13.3792 27.4784 13.437 27.5959 13.528 27.6766L15.2732 29.4218V28.1772H15.2733Z"
        fill="url(#paint19_linear_1_2)"
      />
      <path
        d="M13.8207 27.788C14.0643 27.788 14.2618 27.5905 14.2618 27.3468C14.2618 27.1032 14.0643 26.9057 13.8207 26.9057C13.5771 26.9057 13.3796 27.1032 13.3796 27.3468C13.3796 27.5905 13.5771 27.788 13.8207 27.788Z"
        fill="url(#paint20_linear_1_2)"
      />
      <path
        d="M14.0503 27.2734L13.8934 27.2735L13.8933 27.1166C13.8933 27.0762 13.8606 27.0436 13.8202 27.0436C13.7798 27.0437 13.7472 27.0764 13.7473 27.1168L13.7474 27.2736L13.5905 27.2738C13.5501 27.2738 13.5175 27.3065 13.5175 27.3469C13.5175 27.3872 13.5503 27.4199 13.5906 27.4199L13.7475 27.4198L13.7476 27.5766C13.7477 27.617 13.7804 27.6496 13.8208 27.6496C13.8611 27.6496 13.8938 27.6169 13.8938 27.5765L13.8936 27.4196L14.0505 27.4195C14.0909 27.4194 14.1235 27.3867 14.1235 27.3464C14.1234 27.306 14.0906 27.2734 14.0503 27.2734Z"
        fill="url(#paint21_linear_1_2)"
      />
      <path
        d="M19.3755 29.7879H16.985C16.8427 29.7879 16.7274 29.6726 16.7274 29.5302V22.0724C16.7274 21.9979 16.7597 21.9269 16.816 21.8781L18.0113 20.8388C18.1082 20.7545 18.2524 20.7545 18.3494 20.8388L19.5446 21.8781C19.601 21.927 19.6333 21.9979 19.6333 22.0724V29.5302C19.6331 29.6726 19.5177 29.7879 19.3755 29.7879Z"
        fill="url(#paint22_linear_1_2)"
      />
      <path
        d="M19.6316 24.3297L18.5088 23.2068C18.4279 23.1155 18.31 23.0577 18.1783 23.0578C17.9346 23.0581 17.7372 23.2558 17.7375 23.4996C17.7376 23.6308 17.7953 23.7483 17.8863 23.829L19.6316 25.5743V24.3297H19.6316Z"
        fill="url(#paint23_linear_1_2)"
      />
      <path
        d="M23.9903 29.4218V28.1773V25.5742V24.3297V22.0726C23.9903 21.998 23.958 21.9271 23.9017 21.8782L22.7064 20.8389C22.6095 20.7546 22.4652 20.7546 22.3683 20.8389L21.173 21.8782C21.1167 21.9271 21.0844 21.9981 21.0844 22.0726V22.9214H19.6315V22.0726C19.6315 21.998 19.5992 21.9271 19.5429 21.8782L18.3477 20.8389C18.2507 20.7546 18.1065 20.7546 18.0095 20.8389L16.8143 21.8782C16.758 21.9271 16.7257 21.9981 16.7257 22.0726V22.9214H15.2728V22.0726C15.2728 21.998 15.2405 21.9271 15.1842 21.8782L13.9889 20.8389C13.892 20.7546 13.7477 20.7546 13.6508 20.8389L13.2925 21.1504L16.6616 24.5196H16.7257V24.5836L20.2026 28.0606H21.0842V28.9422L21.9301 29.7881H23.7324C23.8747 29.7881 23.99 29.6727 23.99 29.5304V29.4217H23.9903V29.4218ZM21.0846 26.4624H19.6317V25.5742V24.5195H21.0846V26.4624Z"
        fill="url(#paint24_linear_1_2)"
      />
      <path
        d="M18.1786 23.9404C18.4222 23.9404 18.6197 23.7429 18.6197 23.4993C18.6197 23.2557 18.4222 23.0582 18.1786 23.0582C17.935 23.0582 17.7375 23.2557 17.7375 23.4993C17.7375 23.7429 17.935 23.9404 18.1786 23.9404Z"
        fill="url(#paint25_linear_1_2)"
      />
      <path
        d="M18.4087 23.4259L18.2518 23.4261L18.2517 23.2692C18.2516 23.2288 18.2189 23.1962 18.1785 23.1962C18.1382 23.1962 18.1055 23.2289 18.1056 23.2693L18.1057 23.4262L17.9489 23.4263C17.9085 23.4264 17.8759 23.4591 17.8759 23.4994C17.8759 23.5398 17.9086 23.5724 17.949 23.5724L18.1059 23.5723L18.106 23.7292C18.106 23.7696 18.1387 23.8022 18.1791 23.8022C18.2195 23.8021 18.2521 23.7694 18.2521 23.7291L18.252 23.5722L18.4089 23.5721C18.4492 23.572 18.4819 23.5393 18.4819 23.4989C18.4818 23.4586 18.449 23.4259 18.4087 23.4259Z"
        fill="url(#paint26_linear_1_2)"
      />
      <path
        d="M19.6316 28.1772L18.5088 27.0543C18.4279 26.9629 18.31 26.9052 18.1783 26.9053C17.9346 26.9055 17.7372 27.1032 17.7375 27.347C17.7376 27.4783 17.7953 27.5958 17.8863 27.6765L19.6316 29.4217V28.1772H19.6316Z"
        fill="url(#paint27_linear_1_2)"
      />
      <path
        d="M18.1786 27.788C18.4222 27.788 18.6197 27.5905 18.6197 27.3468C18.6197 27.1032 18.4222 26.9057 18.1786 26.9057C17.935 26.9057 17.7375 27.1032 17.7375 27.3468C17.7375 27.5905 17.935 27.788 18.1786 27.788Z"
        fill="url(#paint28_linear_1_2)"
      />
      <path
        d="M18.4087 27.2734L18.2518 27.2735L18.2517 27.1166C18.2516 27.0762 18.2189 27.0436 18.1785 27.0436C18.1382 27.0437 18.1055 27.0764 18.1056 27.1168L18.1057 27.2736L17.9489 27.2738C17.9085 27.2738 17.8759 27.3065 17.8759 27.3469C17.8759 27.3872 17.9086 27.4199 17.949 27.4199L18.1059 27.4198L18.106 27.5766C18.106 27.617 18.1387 27.6496 18.1791 27.6496C18.2195 27.6496 18.2521 27.6169 18.2521 27.5765L18.252 27.4196L18.4089 27.4195C18.4492 27.4194 18.4819 27.3867 18.4819 27.3464C18.4819 27.306 18.449 27.2734 18.4087 27.2734Z"
        fill="url(#paint29_linear_1_2)"
      />
      <path
        d="M23.7328 29.7879H21.3424C21.2001 29.7879 21.0847 29.6726 21.0847 29.5302V22.0724C21.0847 21.9979 21.1171 21.9269 21.1734 21.8781L22.3686 20.8388C22.4656 20.7545 22.6098 20.7545 22.7067 20.8388L23.902 21.8781C23.9583 21.927 23.9906 21.9979 23.9906 22.0724V29.5302C23.9904 29.6726 23.8751 29.7879 23.7328 29.7879Z"
        fill="url(#paint30_linear_1_2)"
      />
      <path
        d="M23.9908 24.3297L22.868 23.2068C22.7871 23.1155 22.6692 23.0577 22.5375 23.0578C22.2938 23.0581 22.0964 23.2558 22.0966 23.4996C22.0968 23.6308 22.1545 23.7483 22.2455 23.829L23.9908 25.5743V24.3297H23.9908Z"
        fill="url(#paint31_linear_1_2)"
      />
      <path
        d="M22.5383 23.9404C22.7819 23.9404 22.9794 23.7429 22.9794 23.4993C22.9794 23.2557 22.7819 23.0582 22.5383 23.0582C22.2946 23.0582 22.0971 23.2557 22.0971 23.4993C22.0971 23.7429 22.2946 23.9404 22.5383 23.9404Z"
        fill="url(#paint32_linear_1_2)"
      />
      <path
        d="M22.7679 23.4259L22.611 23.4261L22.6109 23.2692C22.6109 23.2288 22.5782 23.1962 22.5378 23.1962C22.4974 23.1962 22.4648 23.2289 22.4648 23.2693L22.4649 23.4262L22.308 23.4263C22.2677 23.4264 22.235 23.4591 22.235 23.4994C22.235 23.5398 22.2678 23.5724 22.3082 23.5724L22.465 23.5723L22.4652 23.7292C22.4652 23.7696 22.4979 23.8022 22.5383 23.8022C22.5787 23.8021 22.6113 23.7694 22.6113 23.7291L22.6112 23.5722L22.768 23.5721C22.8084 23.572 22.841 23.5393 22.841 23.4989C22.841 23.4586 22.8082 23.4259 22.7679 23.4259Z"
        fill="url(#paint33_linear_1_2)"
      />
      <path
        d="M23.9908 28.1772L22.868 27.0544C22.7871 26.963 22.6692 26.9053 22.5375 26.9054C22.2938 26.9056 22.0964 27.1033 22.0966 27.3471C22.0968 27.4784 22.1545 27.5959 22.2455 27.6766L23.9908 29.4218V28.1772H23.9908Z"
        fill="url(#paint34_linear_1_2)"
      />
      <path
        d="M22.5383 27.788C22.7819 27.788 22.9794 27.5905 22.9794 27.3468C22.9794 27.1032 22.7819 26.9057 22.5383 26.9057C22.2946 26.9057 22.0971 27.1032 22.0971 27.3468C22.0971 27.5905 22.2946 27.788 22.5383 27.788Z"
        fill="url(#paint35_linear_1_2)"
      />
      <path
        d="M22.7679 27.2734L22.611 27.2735L22.6109 27.1166C22.6109 27.0762 22.5782 27.0436 22.5378 27.0436C22.4974 27.0437 22.4648 27.0764 22.4648 27.1168L22.4649 27.2736L22.308 27.2738C22.2677 27.2738 22.235 27.3065 22.235 27.3469C22.235 27.3872 22.2678 27.4199 22.3082 27.4199L22.465 27.4198L22.4652 27.5766C22.4652 27.617 22.4979 27.6496 22.5383 27.6496C22.5787 27.6496 22.6113 27.6169 22.6113 27.5765L22.6112 27.4196L22.768 27.4195C22.8084 27.4194 22.841 27.3867 22.841 27.3464C22.841 27.306 22.8082 27.2734 22.7679 27.2734Z"
        fill="url(#paint36_linear_1_2)"
      />
      <path
        d="M16.0003 2.21183C11.1686 2.21183 7.25118 6.12827 7.25118 10.96C7.25118 15.9435 12.6173 20.4306 14.9446 22.7189C15.5305 23.295 16.4702 23.295 17.0561 22.7189C19.3833 20.4306 24.7495 15.9436 24.7495 10.96C24.7494 6.12821 20.832 2.21183 16.0003 2.21183Z"
        fill="url(#paint37_linear_1_2)"
      />
      <path
        d="M15.9999 16.7024C19.1711 16.7024 21.7423 14.1319 21.7423 10.9608C21.7423 7.78969 19.1711 5.2185 15.9999 5.2185C12.8288 5.2185 10.2576 7.78962 10.2576 10.9608C10.2576 14.132 12.8288 16.7024 15.9999 16.7024Z"
        fill="url(#paint38_linear_1_2)"
      />
      <path
        d="M21.6877 11.7459C21.6149 12.2855 21.4662 12.8012 21.2529 13.2825C20.5683 14.8279 19.2224 16.015 17.5735 16.4844L13.3759 12.2961L11.9277 10.8511C11.7282 10.6516 11.7282 10.3278 11.9277 10.1283L15.6378 6.41878C15.8373 6.21928 16.1611 6.21928 16.3606 6.41878L21.6877 11.7459Z"
        fill="url(#paint39_linear_1_2)"
      />
      <path
        d="M16.3043 8.3331L21.2542 13.2824C20.5696 14.8279 19.2238 16.015 17.5748 16.4843C17.0748 16.6267 16.5464 16.7027 16.0006 16.7027C15.7948 16.7027 15.5914 16.692 15.3913 16.6707L13.5931 14.8724C13.4644 14.7984 13.3772 14.6597 13.3772 14.5004V10.8304C13.3772 10.7162 13.4224 10.607 13.5033 10.5267L15.6969 8.33316C15.8644 8.16498 16.1368 8.16498 16.3043 8.3331Z"
        fill="url(#paint40_linear_1_2)"
      />
      <path
        d="M19.7103 11.0009C19.5796 11.0009 19.4488 10.951 19.3489 10.8512L16.0004 7.50265L12.6518 10.8512C12.4523 11.0507 12.1286 11.0507 11.9291 10.8512C11.7296 10.6517 11.7296 10.328 11.9291 10.1285L15.6391 6.41858C15.8386 6.21908 16.1623 6.21908 16.3618 6.41858L20.0717 10.1285C20.2713 10.328 20.2713 10.6517 20.0717 10.8512C19.9718 10.951 19.8411 11.0009 19.7103 11.0009Z"
        fill="url(#paint41_linear_1_2)"
      />
      <path
        d="M18.4972 10.5266L16.3037 8.33311C16.1358 8.16523 15.8637 8.16523 15.6959 8.33311L13.5024 10.5266C13.4218 10.6072 13.3765 10.7165 13.3765 10.8305V14.5004C13.3765 14.7377 13.569 14.9301 13.8063 14.9301H18.1933C18.4307 14.9301 18.6231 14.7377 18.6231 14.5004V10.8305C18.623 10.7165 18.5777 10.6072 18.4972 10.5266ZM16.7947 13.9164C16.7947 13.962 16.7577 13.9989 16.7121 13.9989H15.2874C15.2418 13.9989 15.2048 13.962 15.2048 13.9164V12.31C15.2048 12.2644 15.2418 12.2274 15.2874 12.2274H16.7121C16.7577 12.2274 16.7947 12.2644 16.7947 12.31V13.9164Z"
        fill="url(#paint42_linear_1_2)"
      />
      <path
        d="M9.17031 28.1096C9.17031 28.7024 9.05812 29.2681 8.85344 29.7881H0.316875C0.112188 29.2682 0 28.7024 0 28.1096C0 27.5323 0.106438 26.9805 0.301313 26.472C0.960437 24.7476 2.62994 23.5244 4.58519 23.5244C6.54044 23.5244 8.20994 24.7476 8.86906 26.472C9.06387 26.9805 9.17031 27.5323 9.17031 28.1096Z"
        fill="url(#paint43_linear_1_2)"
      />
      <path
        d="M12.1273 28.5705C12.1273 29.0005 12.046 29.4109 11.8975 29.7881H5.70501C5.55651 29.4109 5.4752 29.0005 5.4752 28.5705C5.4752 28.1517 5.55245 27.7514 5.69376 27.3826C6.17189 26.1317 7.38295 25.2444 8.80126 25.2444C10.2196 25.2444 11.4306 26.1317 11.9088 27.3826C12.0501 27.7514 12.1273 28.1517 12.1273 28.5705Z"
        fill="url(#paint44_linear_1_2)"
      />
      <path
        d="M32.0001 27.8315C32.0001 28.5225 31.8694 29.182 31.6308 29.7881H21.6798C21.4412 29.182 21.3105 28.5225 21.3105 27.8315C21.3105 27.1586 21.4345 26.5153 21.6617 25.9226C22.43 23.9126 24.3761 22.4866 26.6553 22.4866C28.9345 22.4866 30.8806 23.9126 31.649 25.9226C31.8761 26.5154 32.0001 27.1586 32.0001 27.8315Z"
        fill="url(#paint45_linear_1_2)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_2"
        x1="16.0001"
        y1="23.4857"
        x2="16.0001"
        y2="25.3034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_2"
        x1="16.0001"
        y1="27.0262"
        x2="16.0001"
        y2="28.8445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_2"
        x1="11.5497"
        y1="24.4682"
        x2="10.6669"
        y2="23.5854"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_2"
        x1="15.9059"
        y1="24.4682"
        x2="15.0231"
        y2="23.5854"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_2"
        x1="20.2613"
        y1="24.4682"
        x2="19.3785"
        y2="23.5854"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_2"
        x1="11.5496"
        y1="28.009"
        x2="10.6667"
        y2="27.1261"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1_2"
        x1="15.9058"
        y1="28.009"
        x2="15.023"
        y2="27.1262"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1_2"
        x1="20.2611"
        y1="28.009"
        x2="19.3783"
        y2="27.1261"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1_2"
        x1="8.00864"
        y1="25.2819"
        x2="10.9144"
        y2="25.2819"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#816965" />
        <stop offset="1" stopColor="#654E48" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1_2"
        x1="10.4745"
        y1="24.5114"
        x2="9.23217"
        y2="23.2697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1_2"
        x1="9.4624"
        y1="23.0741"
        x2="9.46303"
        y2="24.0849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1_2"
        x1="9.46797"
        y1="23.4996"
        x2="0.00621204"
        y2="23.0221"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1_2"
        x1="10.4745"
        y1="28.3588"
        x2="9.23217"
        y2="27.1171"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1_2"
        x1="9.4624"
        y1="26.9215"
        x2="9.46303"
        y2="27.9323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1_2"
        x1="9.46797"
        y1="27.347"
        x2="0.00621205"
        y2="26.8695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1_2"
        x1="12.3669"
        y1="25.2819"
        x2="15.2726"
        y2="25.2819"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#816965" />
        <stop offset="1" stopColor="#654E48" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1_2"
        x1="14.8323"
        y1="24.5115"
        x2="13.5906"
        y2="23.2698"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_1_2"
        x1="13.8199"
        y1="23.0741"
        x2="13.8211"
        y2="24.0849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_1_2"
        x1="13.8204"
        y1="23.6212"
        x2="13.8198"
        y2="23.1438"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_1_2"
        x1="14.8323"
        y1="28.3589"
        x2="13.5906"
        y2="27.1172"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_1_2"
        x1="13.8199"
        y1="26.9215"
        x2="13.8211"
        y2="27.9323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_1_2"
        x1="13.8204"
        y1="27.4686"
        x2="13.8198"
        y2="26.9911"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_1_2"
        x1="16.727"
        y1="25.2819"
        x2="19.6327"
        y2="25.2819"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#816965" />
        <stop offset="1" stopColor="#654E48" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_1_2"
        x1="19.191"
        y1="24.5114"
        x2="17.9487"
        y2="23.2697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_1_2"
        x1="21.7811"
        y1="24.6985"
        x2="18.2317"
        y2="21.149"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_1_2"
        x1="18.1779"
        y1="23.0741"
        x2="18.1786"
        y2="24.0849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_1_2"
        x1="9.48957"
        y1="23.0608"
        x2="0.0277976"
        y2="22.5834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_1_2"
        x1="19.191"
        y1="28.3588"
        x2="17.9487"
        y2="27.1171"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_1_2"
        x1="18.1779"
        y1="26.9215"
        x2="18.1786"
        y2="27.9323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_1_2"
        x1="9.48957"
        y1="26.9082"
        x2="0.027798"
        y2="26.4308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_1_2"
        x1="21.0843"
        y1="25.2819"
        x2="23.99"
        y2="25.2819"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#816965" />
        <stop offset="1" stopColor="#654E48" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_1_2"
        x1="23.5503"
        y1="24.5115"
        x2="22.308"
        y2="23.2698"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_1_2"
        x1="22.5372"
        y1="23.0741"
        x2="22.5385"
        y2="24.0849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_1_2"
        x1="22.5378"
        y1="23.6212"
        x2="22.5371"
        y2="23.1438"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_1_2"
        x1="23.5503"
        y1="28.3589"
        x2="22.308"
        y2="27.1172"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#654E48" stopOpacity="0" />
        <stop offset="1" stopColor="#503837" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_1_2"
        x1="22.5372"
        y1="26.9215"
        x2="22.5385"
        y2="27.9323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_1_2"
        x1="22.5378"
        y1="27.4686"
        x2="22.5371"
        y2="26.9911"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_1_2"
        x1="7.25104"
        y1="12.6818"
        x2="24.749"
        y2="12.6818"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_1_2"
        x1="21.7418"
        y1="10.9609"
        x2="10.2574"
        y2="10.9609"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_1_2"
        x1="17.1083"
        y1="14.3902"
        x2="16.073"
        y2="5.41784"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D54003" stopOpacity="0" />
        <stop offset="1" stopColor="#A40002" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_1_2"
        x1="19.3947"
        y1="16.0598"
        x2="14.1035"
        y2="10.7686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D54003" stopOpacity="0" />
        <stop offset="1" stopColor="#A40002" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_1_2"
        x1="11.7792"
        y1="8.63545"
        x2="20.2209"
        y2="8.63545"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#E2EDF2" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_1_2"
        x1="13.3763"
        y1="11.5691"
        x2="18.6227"
        y2="11.5691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#E2EDF2" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_1_2"
        x1="1.99934"
        y1="25.2552"
        x2="7.65719"
        y2="30.9128"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_1_2"
        x1="6.92539"
        y1="26.4999"
        x2="11.0295"
        y2="30.6039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_1_2"
        x1="23.6407"
        y1="24.5041"
        x2="30.2357"
        y2="31.0991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <clipPath id="clip0_1_2">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLocationsMap;
