import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import { Tooltip, Spin } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import classNames from "classnames";
import { IconHeart } from "components/Svgs";
import _, {
  get as lodashGet,
  find as lodashFind,
  map as lodashMap,
  isEmpty as lodashEmpty,
  isString as lodashString,
} from "lodash";
import { SimpleButton } from "components/Controls/Buttons";
import CustomTable from "components/DataDisplay/CustomTable";
import { DaysOnHiHome, HouseScore } from "components/DataDisplay";
import ImageWithPreview from "components/DataDisplay/ImageWithPreview";
import { HOUSES_PER_PAGE, HOUSE_VIEWS } from "constants/houses";
import { TOKEN_KEY } from "constants/localStorage";
import { HOUSE_DETAILS_LOCATION } from "constants/paths";
import { COLUMNS } from "containers/Houses/config";
import {
  getClassnameForStatus,
  getFullAddress,
  getHouseParameterValue,
  getMethodForScore,
  getPropertyTypeValue,
  getStatusValue
} from "utils/helpers/housesHelper/getter";
import { formatSearchParams, getActiveFilters } from "utils/helpers/searchHelper";
import { getCurrentPageNumber } from "utils/helpers/favoritesHelper";
import { COLUMNS_TITLE_IN_ALPHABET_ORDER } from "../../constants";
import "./styles.scss";

const SORT_ORDER = {
  asc: "ascend",
  desc: "descend",
};

const GET_HOUSES_METHODS = {
  [HOUSE_VIEWS.topMatches]: "getHousesRequest",
  [HOUSE_VIEWS.favorites]: "getHousesFavoritesRequest"
};

const ListView = observer((
  {
    criteriaStore,
    getHousesData,
    housesStore,
    view,
    viewedHouseIds,
    handleFavorites,
    handleOnClickHouse,
    handleNonAuthFavorites,
    markHouseAsRecentlyViewed,
    updateViewedHouseIds
  }
) => {
  const [pagination, setPagination] = useState({});
  const [columnsSorting, setColumnsSorting] = useState({});

  const housesData = housesStore.retrieveHousesData();

  useEffect(() => {
    const handleSuccess = responseData => {
      if (responseData.total_results === 0) {
        // checkResultsExistence(false);
      } else {
        updateViewedHouseIds(responseData);
        // checkResultsExistence(true);
        setPagination({
          current: lodashGet(responseData, "page"),
          pageSize: HOUSES_PER_PAGE,
          total: lodashGet(responseData, "total_results"),
        });
      }
    };
    getHousesData(handleSuccess);
  }, []);

  const compareDataIndexes = (index, value) => {
    if (lodashString(index)) {
      return index === value;
    }
    return index[0] === value;
  };

  const updateSorting = (field, order) => {
    const previousState = lodashGet(columnsSorting, field)
    let realOrder = order;

    if (previousState) {
      realOrder = previousState === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
    }

    setColumnsSorting({[field]: realOrder});
    return realOrder;
  };

  const isSorted = fieldName => {
    const value = lodashGet(columnsSorting, fieldName);
    return value && value === SORT_ORDER.asc;
  };

  const onSortByRecentlyAdded = () => {
    const recentlyAddedSort = lodashGet(columnsSorting, "external_created_at")
    const order = recentlyAddedSort || SORT_ORDER.asc;
    const sorter = { field: "external_created_at", order: updateSorting("external_created_at", order) };
    handleTableChange(pagination, _, sorter);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const criteriaData = criteriaStore.retrieveCriteriaData();
    const searchFilters = formatSearchParams(criteriaData);
    const currentlySortedField = Object.keys(columnsSorting)[0];

    let sorting = { field: currentlySortedField, order: columnsSorting[currentlySortedField] };
    if (!lodashEmpty(sorter)) {
      const sortingField = typeof(sorter.field) === "string" ? sorter.field : sorter.field[0];
      sorting = { field: sortingField, order: updateSorting(sortingField, sorter.order) }
    }

    const params = {
      filters: searchFilters,
      page: pagination.current,
      sorting: sorting,
    };
    setPagination(pagination);
    const getHousesMethodName = GET_HOUSES_METHODS[housesStore.view];
    housesStore[getHousesMethodName](params);
  };

  const onClickFavorite = propertyId => {
    const handleSuccessUpdate = (isLastItemOnPage, isRemovedFromFavorites) => {
      const newPagination = _.cloneDeep(pagination);
      newPagination.current = getCurrentPageNumber(newPagination.current, housesData, isLastItemOnPage);
      if (isRemovedFromFavorites) {
        newPagination.total -= 1;
      }
      if (newPagination.total === 0) {
        const houseData = housesStore.retrieveHousesData();
        houseData.total_results = 0;
      }
      handleTableChange(newPagination, _, {});
    }
    if (!localStorage.getItem(TOKEN_KEY)) {
      handleNonAuthFavorites();
    } else {
      handleFavorites(propertyId, housesStore, handleSuccessUpdate);
    }
  };

  const renderAddToFavoritesButton = record => (
    <div className="favorites" onClick={e => e.stopPropagation()}>
      <Tooltip
        placement="bottomRight"
        title={<FormattedMessage id={record.is_favorite ? "houses.removeFavourite" : "houses.addToFavourite"} />}
      >
        <SimpleButton
          className={classNames("favorites-button", {
            active: record.is_favorite
          })}
          icon={<IconHeart />}
          onClick={() => onClickFavorite(record.id)}
        />
      </Tooltip>
    </div>
  );

  const renderFirstColumn = record => {
    const statusClassname = getClassnameForStatus(record.listing_status);
    const searchFilters = formatSearchParams(criteriaStore.retrieveCriteriaData());
    const filters = getActiveFilters(searchFilters);
    return (
      <div className="hiscore-column">
        <div className="hiscore-column__score">
          <HouseScore score={record.total_score} />
        </div>
        <div className="hiscore-column__house">
          <div className="house-photo" onClick={e => e.stopPropagation()}>
            <ImageWithPreview
              data={record}
              height={80}
              key={`preview_image_${record.id}`}
              width={120}
              withRadius
            />
          </div>
          <div className="house-info">
            <a
              className={classNames("house-info__address", { isViewed: viewedHouseIds.includes(record.id) })}
              target="_blank"
              href={HOUSE_DETAILS_LOCATION(record.id, filters)}
              rel="noopener noreferrer"
              onClick={e => {
                e.stopPropagation();
                markHouseAsRecentlyViewed(record.id);
              }}
            >
              <span>{getFullAddress(record.address)}</span>
            </a>
            <div className="house-info__general">
              <div className={classNames("house-info__general__first-row", statusClassname)}>
                {getStatusValue(record)}
              </div>
              <div className="house-info__general__second-row">
                <span>{getPropertyTypeValue(record.prop_type)}</span>
                <DaysOnHiHome propertyItem={record} />
              </div>
            </div>
          </div>
        </div>
        {renderAddToFavoritesButton(record)}
      </div>
    );
  };

  const renderColoredRow = (value, criteria_values) => {
    const colorClassname = getMethodForScore(criteria_values[0], criteria_values[1], criteria_values[2]);
    return (
      <div className={`row-value ${colorClassname}`}>
        {getHouseParameterValue(value)}
      </div>
    );
  };

  const getSortedColumns = () => {
    let criteriaImportance = lodashGet(housesData, "criteria_importance", []);
    const criteriaData = criteriaStore.retrieveCriteriaData();
    // get the list with the info about criterion key, priority number, alphabet order number of the criterion
    let orderedCriteriaData = criteriaImportance.map(key => ({
      key,
      priority: lodashGet(criteriaData[key === "low_crime" ? "crime" : key], "priority", 0),
      alphabetOrderIndex: _.indexOf(COLUMNS_TITLE_IN_ALPHABET_ORDER, key)
    }));
    // sort criteria by: 1) priority (from the highest to the lowest) 2) alphabet (from A to Z if priorities are equal)
    orderedCriteriaData = orderedCriteriaData.sort((firstCriteria, secondCriteria) => {
      const isPriorityHigher = secondCriteria.priority - firstCriteria.priority;
      const isEarlierByAlphabet = firstCriteria.alphabetOrderIndex - secondCriteria.alphabetOrderIndex;
      return isPriorityHigher || isEarlierByAlphabet;
    });
    // get the list of the ordered criteria keys
    criteriaImportance = orderedCriteriaData.map(item => item.key);
    // get table columns according to the ordered criteriaImportance list
    const columns = COLUMNS(renderFirstColumn, renderColoredRow, isSorted);
    const result = lodashMap(criteriaImportance, (item) =>
      lodashFind(columns, (column) => compareDataIndexes(column.dataIndex, item))
    );
    result.unshift(columns[0]);
    return result;
  };

  const renderRecentlyAddedSortButton = () => (
    <div
      className="recently-added-button"
      onClick={onSortByRecentlyAdded}
    >
      <FormattedMessage id="houses.list.recentlyAddedMessage" />
      <div
        className={classNames(
          "recently-added-button__icon",
          { "is-active": isSorted("external_created_at") }
        )}
      >
        <CaretDownFilled />
      </div>
    </div>
  );

  const renderTable = () => (
    <CustomTable
      className={classNames("list-view__table", { isFavorites: view === HOUSE_VIEWS.favorites })}
      dataSource={housesData.data}
      columns={getSortedColumns()}
      pagination={{
        current: lodashGet(housesData, 'page'),
        pageSize: HOUSES_PER_PAGE,
        total: lodashGet(housesData, 'total_results'),
        showSizeChanger: false
      }}
      scroll={{ x: "max-content" }}
      onChange={handleTableChange}
      onRow={(record) => ({ onClick: () => handleOnClickHouse(record.id) })}
    />
  );

  const isShowResults = _.has(housesData, "data") && !_.isEmpty(housesData.data) && !housesStore.loading;

  // check this logic, maybe it will be better to optimize it
  return (
    <div className={classNames("list-view-wrapper", { isFavorites: housesStore.view === HOUSE_VIEWS.favorites })}>
      <Spin spinning={housesStore.loading}>
        {isShowResults && (
          <div className="houses-results-container">
            {renderRecentlyAddedSortButton()}
            {renderTable()}
          </div>
        )}
      </Spin>
    </div>
  );
});

export default inject("criteriaStore", "housesStore")(ListView);