import React from 'react';
import { InfoWindow } from '@react-google-maps/api'
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import {
  get as lodashGet,
  isEmpty as lodashIsEmpty,
} from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import { HouseScore, ImageWithPreview } from "components/DataDisplay";
import { getValueClassnameByScoreRangeType } from "utils/helpers/housesHelper/common";
import { nFormatter, priceFormatter, toLocalePrice } from "utils/helpers/housesHelper/formatter";
import { getFullAddress } from "utils/helpers/housesHelper/getter";
import "./styles.scss";

const MapInfoWindow = observer(({ housesStore, viewedHouseList }) => {
  const houseData = housesStore.retrieveHoveredItem();

  const getCriteriaClassName = key => getValueClassnameByScoreRangeType(
    lodashGet(houseData, `${key}.score`),
    lodashGet(houseData, `${key}.value`)
  );

  const getCriteriaPills = () => (
    <>
      <div className={`criteria__card-tag ${getCriteriaClassName("price")}`}>
        {toLocalePrice(priceFormatter(lodashGet(houseData, 'price.value')))}
      </div>
      <div className={`criteria__card-tag ${getCriteriaClassName("bedrooms")}`}>
        {lodashGet(houseData, "bedrooms.value")} <FormattedMessage id="houses.card.beds" />
      </div>
      <div className={`criteria__card-tag ${getCriteriaClassName("bathrooms")}`}>
        {lodashGet(houseData, "bathrooms.value")} <FormattedMessage id="houses.card.bath" />
      </div>
      {getSquareFootageValue()}
    </>
  );

  const getSquareFootageValue = () => {
    const buildingSizeValue = lodashGet(houseData, "building_size.value");
    return (
      <div className={`criteria__card-tag ${getCriteriaClassName("building_size")}`}>
        {_.isNil(buildingSizeValue) ? (
          <FormattedMessage id="general.noData" />
        ) : (
          <>
            <span>{nFormatter(buildingSizeValue, 1, false)}</span>
            &nbsp;
            <span style={{ textTransform: "capitalize" }}>
              {`${lodashGet(houseData, 'building_size.units')}`}
            </span>
          </>
        )}
      </div>
    );
  };

  if (lodashIsEmpty(houseData)) {
    return null;
  }

  const renderHouseCard = () => (
    <div className="map-info-window">
      <div className="map-info-window__score">
        <HouseScore score={houseData.total_score} />
      </div>
      <div className="map-info-window__photo">
        <ImageWithPreview data={houseData} height={140} width={240} />
      </div>
      <div className="map-info-window__bottom-content">
        <div className={classNames("address",{ isViewed: viewedHouseList.includes(houseData.id) })}>
          {getFullAddress(houseData.address)}
        </div>
        <div className="criteria">
          {getCriteriaPills()}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <InfoWindow
        position={{
          lat: lodashGet(houseData, "address.location.lat"),
          lng: lodashGet(houseData, "address.location.lon"),
        }}
      >
        {renderHouseCard()}
      </InfoWindow>
    </div>
  );
});

MapInfoWindow.propTypes = {
  viewedHouseList: PropTypes.array,
};

export default inject("housesStore")(MapInfoWindow);
