import React from "react";
import { SimpleButton } from "components/Controls/Buttons";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { DASHBOARD_PATH } from "constants/paths";
import { useHistory } from 'react-router-dom'
import { notFoundImage } from "images";
import "./styles.scss";


const NotFound = props => {
  const { customClassName, height, width } = props;
  const history = useHistory();
  return (
    <div className="list-view-wrapper">
      <div className="list-view">
        <div className="not-found-page">
          <div className="not-found-page__image">
            <img
              className={classNames(customClassName, "not-fount-page-img")}
              src={notFoundImage}
              alt="not-found-image"
              style={{ width, height, maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
          <SimpleButton
            customClass={classNames("button-redirect-dashboard")}
            onClick={() => history.push(DASHBOARD_PATH)}
          >
            <FormattedMessage id="sideMenu.criteriaDashboardMessage" />
          </SimpleButton>
        </div>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

NotFound.defaultProps = {
  height: "98vh",
};

export default NotFound;
