import React from "react";
import { Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import { getHouseDetailsValue } from "utils/helpers/housesHelper";
import {
  OTHER_INFO_FIRST_ROW_CRITERIA,
  OTHER_INFO_SECOND_ROW_CRITERIA,
} from "./constants";
import "./styles.scss";

const OtherInfoSection = ({ houseDetails }) => {
  const renderCriteria = (title, value) => (
    <div className="criteria-item">
      {title && <div className="criteria-item__title">{title}</div>}
      <div className="criteria-item__value">{value}</div>
    </div>
  );

  const renderCriteriaSection = (section) =>
    section.criteria.map((criteria) => {
      const title = <FormattedMessage id={criteria.title} />;
      const value = getHouseDetailsValue(houseDetails, criteria.key);
      return renderCriteria(title, value);
    });

  const renderListSection = (section) => {
    const value = getHouseDetailsValue(houseDetails, section.key);
    return _.isArray(value)
      ? value.map((criteria) => renderCriteria(null, criteria))
      : renderCriteria(null, value);
  };

  const renderSection = (section) => (
    <Col span={6} className="other-info-section">
      <div className="other-info-section__title">
        <FormattedMessage id={section.title} />
      </div>
      {_.has(section, "criteria")
        ? renderCriteriaSection(section)
        : renderListSection(section)}
    </Col>
  );

  const renderRow = (data) => (
    <Row gutter={64}>{data.map((section) => renderSection(section))}</Row>
  );

  return (
    <div className="other-info-container">
      <div className="other-info-title">
        <FormattedMessage id="houseDetails.otherInfo.title" />
      </div>
      <div className="other-info-content">
        {renderRow(OTHER_INFO_FIRST_ROW_CRITERIA)}
        {renderRow(OTHER_INFO_SECOND_ROW_CRITERIA)}
      </div>
    </div>
  );
};

export default OtherInfoSection;
