import React, { useEffect } from 'react';
import { BarChart, Bar, Cell, ResponsiveContainer } from "recharts";
import { inRange as lodashInRange } from "lodash";
import { HistogramColumn } from "components/Svgs";
import { inject, observer } from "mobx-react";
import { DATA_KIND } from "constants/common";
import "./styles.scss";

// min and max values that are located at the ends of the slider
// minValue and maxValue is the current values of the slider
// they are needed to calculate which columns should be filled
const RangeHistogram = observer((
  {
    dataKind,
    kind,
    criteriaStore,
    onboardingStore,
    steps, minValue,
    maxValue,
    min,
    max,
    invertHistogram,
    bottomValues = []
  }) => {
  useEffect(() => {
    const formatLocations = locations => (
      locations.map(location => ({
        city: location.city,
        state: location.state,
        postal_code: location.postal_code,
      }))
    );

    const formatHistogramParams = criteriaData => ({
      kind,
      steps,
      locations: formatLocations(criteriaData.locations)
    })

    const criteriaData = dataKind === DATA_KIND.onboarding ?
      onboardingStore.retrieveOnboardingData() :
      criteriaStore.retrieveCriteriaData();
    const params = formatHistogramParams(criteriaData);
    criteriaStore.getHistogramDataRequest(params);
  }, [])

  const data = criteriaStore.retrieveHistogramData();
  const oneColumnValue = max / data.length;

  const getColorColumn = index => {
    const startValue = invertHistogram ? min : minValue - oneColumnValue;
    return lodashInRange(index * oneColumnValue, startValue, maxValue)
      ? '#E0DCFB'
      : '#f4f7fc'
  }

  const getColorNumber = index => {
    const startValue = invertHistogram ? min : minValue - oneColumnValue;
    return lodashInRange(index * oneColumnValue, startValue, maxValue)
      ? '#583EF5'
      : '#1B235E'
  }

  const TriangleBar = (props) => {
    const { y, height } = props;
    const rx = data.length > 16 ? 6 : 12;
    return (
      <HistogramColumn
        {...props}
        rx={rx}
        height={height > 10 ? height : 10}
        y={height > 10 ? y : y - 10 + height}
      />
    )
  };

  return (
    <div className="range-histogram">
      <ResponsiveContainer width="99%" height="100%" >
        <BarChart  data={data} barCategoryGap="4%">
          <Bar dataKey="value" shape={<TriangleBar />} >
            {data.map((entry, index) => {
              const color = getColorColumn(index);
              return (
                <Cell key={`cell-${index}`} fill={color} />
              )
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="range-histogram__numbers">
        {bottomValues.map((item, index) => (
          <div style={{color: getColorNumber(index)}}>{item}</div>
        ))}
      </div>
    </div>
  );
})

export default inject("criteriaStore", "onboardingStore")(RangeHistogram);
