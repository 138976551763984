import React from "react";
import PropTypes from "prop-types";
import { getClassnameForScore } from "utils/helpers/housesHelper/common";
import "./styles.scss";

const HouseScore = props => {
  const { score } = props;
  const colorClassname = getClassnameForScore(score);
  return (
    <div className={`score-container ${colorClassname}`}>
      <span>{score}</span>
    </div>
  );
};

HouseScore.propTypes = {
  score: PropTypes.number,
};

export default HouseScore;
