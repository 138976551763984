import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import * as d3 from "d3";
import {
  find as lodashFind,
  forEach as lodashForEach,
  isEmpty as lodashIsEmpty,
  isEqual,
} from "lodash";
import { CRITERIA_ICON_MAP, PRIORITY_MESSAGES, CRITERIA_MESSAGE_MAP } from "containers/Dashboard/constants";

const ICON_SIZE = {
  1: 24,
  2: 32,
  3: 44,
  4: 55,
  5: 64,
}

class BubbleCluster extends React.Component {
  static defaultProps = {
    data: [],
    width: 800,
    height: 400
  };

  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.data.length > 0) {
      this.simulatePositions(this.props.data);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (!isEqual(prevProps.data, data) && data.length > 0) {
      const dataWithPosition = [];
      if (!lodashIsEmpty(data)) {
        lodashForEach(data, priorityData => {
          const simulatedData = lodashFind(this.state.data, item => item.key === priorityData.key);
          priorityData.value > 0 && dataWithPosition.push({ ...simulatedData, ...priorityData });
        });
        this.simulatePositions(dataWithPosition);
      } else {
        this.simulatePositions(data);
      }
    }
  }

  getMarginValue = () => {
    const windowWidth = window.innerWidth;
    if (1100 < windowWidth <= 1300) {
      return 150;
    }
    if (1300 < windowWidth <= 1500) {
      return 250;
    }
    if (1500 < windowWidth <= 1700) {
      return 350;
    }
    return 0;
  };

  radiusScale = (value) => (value + 1) * 10;  // Return 12 if bubbles look too small

  // x-axis pressure force
  getForceXStrength = (length) => {
    if (length <= 2) { return 0.9 }
    if (length <= 4) { return 0.85 }
    if (length <= 6) { return 0.6 }
    if (length <= 12) { return 0.2 }
    return 0.03
  }

  simulatePositions = (data) => {
    this.simulation = d3
      .forceSimulation()
      .nodes(data)
      .velocityDecay(0.8)
      .alphaMin(0.02)
      .force("x", d3.forceX().strength(this.getForceXStrength(data.length)))
      .force("y", d3.forceY().strength(0.9))
      .force(
        "collide",
        d3.forceCollide((item) => this.radiusScale(item.value) + 2).iterations(0.7).strength(1)
      )
      .on("tick", () => {
        if (this.mounted) {
          this.setState({ data });
        }
      });
  };

  renderIconInBubble = (key, size) => {
    const iconSize = ICON_SIZE[size];
    const icon = CRITERIA_ICON_MAP[key];
    if (icon) {
      return icon({ height: iconSize, width: iconSize, x: -iconSize / 2, y: -iconSize / 2 });
    }
  }

  // show tooltip with priority message when the bubble has hovered
  // tooltipKey is kind of criteria, tooltipValue is message by priority
  onMouseInBubble = (e, key, value) => {
    const { intl } = this.props;
    const message = PRIORITY_MESSAGES[value];
    let tooltip = document.getElementById("bubble-tooltip");
    let tooltipKey = document.getElementById("bubble-tooltip-key");
    let tooltipValue = document.getElementById("bubble-tooltip-value");

    tooltipKey.innerHTML = `${intl.formatMessage({ id: CRITERIA_MESSAGE_MAP[key] })}: `;
    tooltipValue.innerHTML = intl.formatMessage({ id: message });
    tooltip.style.visibility = 'visible';
    tooltip.style.top = `${e.pageY + 15}px`;
    tooltip.style.left = `${e.pageX - 110}px`;
  }

  onMouseOutFromBubble = () => {
    let tooltip = document.getElementById("bubble-tooltip");
    tooltip.style.visibility = 'hidden';
  }

  renderBubbles = (data) => data.map((item, index) => {
    const { width, height } = this.props;
    const marginValue = this.getMarginValue();
    return item.value > 0 && (
      <g
        key={index}
        transform={`translate(${ width / 2 + marginValue + item.x }, ${ height / 2 + item.y })`}
        onMouseMove={(e) => this.onMouseInBubble(e, item.key, item.value)}
        onMouseLeave={this.onMouseOutFromBubble}
      >
        <circle
          r={this.radiusScale(item.value)}
          fill="url(#paint_linear)"
        />
        {this.renderIconInBubble(item.key, item.value)}
        <linearGradient id="paint_linear" x1="60" y1="0" x2="60" y2="120" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DDF2FF" />
          <stop offset="1" stopColor="#C7C6FF" />
        </linearGradient>
      </g>
    );
  });

  render() {
    return (
      <>
        <div className="bubble-tooltip" id="bubble-tooltip">
          <span className="bubble-tooltip-key" id="bubble-tooltip-key" />
          <span className="bubble-tooltip-value" id="bubble-tooltip-value" />
        </div>
        <svg className="bubble-svg">
          {this.renderBubbles(this.state.data)}
        </svg>
      </>
    );
  }
}

BubbleCluster.propTypes = {
  data: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default injectIntl(BubbleCluster);
