import React from "react";

const IconCustomDistanceDetails = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6660_16725)">
    <path d="M19.2455 4.37623H15.2495V1.12874C15.2495 0.712032 14.9117 0.374268 14.495 0.374268H11.3778C10.9611 0.374268 10.6234 0.712071 10.6234 1.12874V4.37623H7.6934C3.45343 4.37623 -0.0248855 7.80677 0.000134136 12.0467C0.0247224 16.2147 3.41084 19.6009 7.57896 19.6255C11.8189 19.6506 15.2495 16.1723 15.2495 11.9323V9.00234H19.2455C19.6622 9.00234 20 8.66454 20 8.24787V5.13074C20 4.71403 19.6622 4.37623 19.2455 4.37623ZM10.6233 12.001C10.6233 13.6544 9.27814 14.9996 7.62469 14.9996C5.97124 14.9996 4.62606 13.6544 4.62606 12.001C4.62606 10.3476 5.97124 9.00234 7.62469 9.00234H10.6233V12.001Z" fill="url(#paint0_linear_6660_16725)"/>
    <path d="M9.76456 7.17383C10.0322 7.17383 10.2492 6.95686 10.2492 6.68921C10.2492 6.42157 10.0322 6.20459 9.76456 6.20459H8.22095C7.95331 6.20459 7.73633 6.42157 7.73633 6.68921C7.73633 6.95686 7.95327 7.17383 8.22095 7.17383H9.76456Z" fill="url(#paint1_linear_6660_16725)"/>
    <path d="M9.85842 16.2813C9.44324 16.4985 8.99963 16.6533 8.53991 16.7415C8.27705 16.7919 8.10485 17.0459 8.15524 17.3087C8.19971 17.5408 8.40281 17.7021 8.63065 17.7021C8.66089 17.7021 8.69163 17.6993 8.72246 17.6934C9.2752 17.5874 9.80853 17.4013 10.3076 17.1402C10.5448 17.0162 10.6365 16.7233 10.5125 16.4862C10.3884 16.249 10.0956 16.1573 9.85842 16.2813Z" fill="url(#paint2_linear_6660_16725)"/>
    <path d="M4.76261 8.11361C5.14045 7.8349 5.55512 7.61357 5.9952 7.45576C6.24712 7.36541 6.37814 7.08792 6.28779 6.836C6.19743 6.58404 5.91983 6.45322 5.66802 6.54341C5.13916 6.73306 4.64096 6.99894 4.18719 7.33365C3.97182 7.49251 3.92602 7.79592 4.08488 8.01133C4.1799 8.14016 4.3266 8.20831 4.47527 8.20831C4.57519 8.20827 4.67602 8.17749 4.76261 8.11361Z" fill="url(#paint3_linear_6660_16725)"/>
    <path d="M2.31606 11.4789C2.34669 11.4847 2.37708 11.4875 2.40712 11.4875C2.63524 11.4875 2.8385 11.3257 2.88269 11.0934C2.97014 10.6335 3.12426 10.1896 3.34077 9.77401C3.46442 9.53668 3.37226 9.24397 3.13489 9.12033C2.89756 8.99672 2.60485 9.08888 2.48116 9.32625C2.22093 9.82582 2.03563 10.3594 1.93049 10.9123C1.88049 11.1752 2.05312 11.4289 2.31606 11.4789Z" fill="url(#paint4_linear_6660_16725)"/>
    <path d="M2.96783 13.2773C2.89724 13.0191 2.63097 12.8671 2.37254 12.9377C2.11438 13.0083 1.96234 13.2748 2.03289 13.5329C2.18113 14.0752 2.40787 14.5925 2.70681 15.0705C2.79873 15.2176 2.95662 15.2982 3.11815 15.2982C3.20591 15.2982 3.29477 15.2744 3.37465 15.2244C3.60156 15.0825 3.6705 14.7835 3.52858 14.5566C3.27979 14.1588 3.09116 13.7284 2.96783 13.2773Z" fill="url(#paint5_linear_6660_16725)"/>
    <path d="M12.7914 13.3301C12.5392 13.2401 12.262 13.3715 12.1721 13.6236C12.0149 14.064 11.7942 14.479 11.5161 14.8572C11.3576 15.0729 11.4038 15.3762 11.6194 15.5348C11.7059 15.5983 11.8064 15.629 11.9061 15.629C12.055 15.629 12.202 15.5606 12.2969 15.4314C12.631 14.9772 12.8961 14.4785 13.0849 13.9493C13.1749 13.6972 13.0435 13.42 12.7914 13.3301Z" fill="url(#paint6_linear_6660_16725)"/>
    <path d="M6.35609 16.6597C5.90476 16.5371 5.47409 16.3491 5.07593 16.101C4.8488 15.9594 4.54993 16.0288 4.40837 16.256C4.2668 16.4831 4.33617 16.782 4.56335 16.9236C5.0417 17.2217 5.55942 17.4477 6.10201 17.595C6.14456 17.6066 6.18726 17.6121 6.22934 17.6121C6.44264 17.6121 6.63813 17.4701 6.69672 17.2544C6.76691 16.9961 6.61436 16.7299 6.35609 16.6597Z" fill="url(#paint7_linear_6660_16725)"/>
    <path d="M12.9368 9.36792C12.6691 9.36792 12.4521 9.5849 12.4521 9.85255V11.3961C12.4521 11.6638 12.6691 11.8807 12.9368 11.8807C13.2045 11.8807 13.4214 11.6638 13.4214 11.3961V9.85251C13.4214 9.5849 13.2044 9.36792 12.9368 9.36792Z" fill="url(#paint8_linear_6660_16725)"/>
    <path d="M12.4521 0.374268V1.11176C12.4521 1.37941 12.6691 1.59639 12.9368 1.59639C13.2045 1.59639 13.4214 1.37941 13.4214 1.11176V0.374268H12.4521Z" fill="url(#paint9_linear_6660_16725)"/>
    <path d="M12.9368 2.46289C12.6691 2.46289 12.4521 2.67987 12.4521 2.94752V4.11583C12.4521 4.38348 12.6691 4.60046 12.9368 4.60046C13.2045 4.60046 13.4214 4.38348 13.4214 4.11583V2.94752C13.4214 2.67987 13.2044 2.46289 12.9368 2.46289Z" fill="url(#paint10_linear_6660_16725)"/>
    <path d="M17.1422 6.20483H15.7932C15.5256 6.20483 15.3086 6.42181 15.3086 6.68946C15.3086 6.9571 15.5255 7.17408 15.7932 7.17408H17.1422C17.4099 7.17408 17.6268 6.9571 17.6268 6.68946C17.6268 6.42181 17.4099 6.20483 17.1422 6.20483Z" fill="url(#paint11_linear_6660_16725)"/>
    <path d="M19.262 6.20483C18.9943 6.20483 18.7773 6.42181 18.7773 6.68946C18.7773 6.9571 18.9943 7.17408 19.262 7.17408H19.9994V6.20483H19.262Z" fill="url(#paint12_linear_6660_16725)"/>
    <path d="M12.9368 5.771C12.6691 5.771 12.4521 5.98798 12.4521 6.25562V7.42394C12.4521 7.69158 12.6691 7.90856 12.9368 7.90856C13.2045 7.90856 13.4214 7.69158 13.4214 7.42394V6.25562C13.4214 5.98798 13.2044 5.771 12.9368 5.771Z" fill="url(#paint13_linear_6660_16725)"/>
    <path d="M19.9996 8.24783V5.13069C19.9996 4.71399 19.6618 4.37622 19.2451 4.37622H15.249V9.00234H19.2451C19.6618 9.0023 19.9996 8.6645 19.9996 8.24783Z" fill="url(#paint14_linear_6660_16725)"/>
    <path d="M6.12207 4.53685V9.40791C6.56434 9.15054 7.0776 9.00234 7.62513 9.00234H10.6237V4.37622H7.69379C7.15572 4.37622 6.62999 4.43171 6.12207 4.53685Z" fill="url(#paint15_linear_6660_16725)"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_6660_16725" x1="6.16818" y1="6.15438" x2="14.5408" y2="14.527" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8A7889"/>
    <stop offset="1" stopColor="#6E566E"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6660_16725" x1="9.13868" y1="6.80262" x2="15.2955" y2="11.5869" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6660_16725" x1="4.29772" y1="13.0325" x2="10.4546" y2="17.8168" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint3_linear_6660_16725" x1="7.3674" y1="9.08224" x2="13.5243" y2="13.8666" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint4_linear_6660_16725" x1="5.01595" y1="12.1081" x2="11.1728" y2="16.8925" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint5_linear_6660_16725" x1="3.21536" y1="14.4251" x2="9.37222" y2="19.2094" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint6_linear_6660_16725" x1="6.60378" y1="10.0643" x2="12.7607" y2="14.8486" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint7_linear_6660_16725" x1="2.92476" y1="14.7997" x2="9.08163" y2="19.584" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint8_linear_6660_16725" x1="8.71727" y1="7.34549" x2="14.8741" y2="12.1298" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint9_linear_6660_16725" x1="13.4474" y1="1.25839" x2="19.6042" y2="6.04273" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint10_linear_6660_16725" x1="12.1536" y1="2.92313" x2="18.3105" y2="7.70747" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint11_linear_6660_16725" x1="11.9527" y1="3.18094" x2="18.1095" y2="7.96531" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint12_linear_6660_16725" x1="13.1121" y1="1.68869" x2="19.2689" y2="6.4731" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint13_linear_6660_16725" x1="10.5509" y1="4.98578" x2="16.7078" y2="9.77012" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint14_linear_6660_16725" x1="17.5835" y1="6.68924" x2="14.8162" y2="6.68924" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint15_linear_6660_16725" x1="8.20101" y1="6.89206" x2="11.1034" y2="6.89206" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <clipPath id="clip0_6660_16725">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconCustomDistanceDetails;
