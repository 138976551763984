import React from "react";

const IconDoubleCheck = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5158 5.38721L5.48844 12.2544L2.94157 9.76612C1.90641 8.70753 0.300942 10.2778 1.33219 11.3364L4.74625 14.6724C4.95329 14.8755 5.23844 15.0005 5.55094 15.0005C5.89469 15.0005 6.20329 14.8482 6.41422 14.6099L13.2423 6.83252C14.1525 5.74268 12.4259 4.29737 11.5158 5.38721Z" fill="#4AB272"/>
    <path d="M11.4894 12.2544L17.5168 5.38721C18.4269 4.29737 20.1535 5.74268 19.2433 6.83252L12.4152 14.6099C12.2043 14.8482 11.8957 15.0005 11.5519 15.0005C11.2394 15.0005 10.9543 14.8755 10.7472 14.6724L9.21484 13.1724L10.72 11.5L11.4894 12.2544Z" fill="#4AB272"/>
  </svg>
);

export default IconDoubleCheck;
