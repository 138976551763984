import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { FormattedMessage, injectIntl}  from "react-intl";
import { SimpleButton } from "components/Controls/Buttons";
import { getPasswordIcon } from "utils/helpers/userHelper";
import { LOGIN_PATH } from "constants/paths";
import { withPassword } from "hocs/withPassword";
import {
  IconArrowRight,
  IconDoubleCheck,
} from "components/Svgs";
import"./styles.scss";

const SignUpForm = (
  {
    intl,
    isLoading,
    handleSignUp,
    passwordLength,
    passwordLetter,
    passwordsMatch,
    validatePassword,
    validateConfirmPassword,
    observeConfirmPasswordField,
    renderValidations,
  }) => {
  const [form] = Form.useForm();

  const fieldsData = [
    {
      name: "first_name",
      rules: [
        { required: true,message: intl.formatMessage({ id: "signUp.form.emptyFirstName" }) },
        { max: 50, message: intl.formatMessage({ id: "signUp.form.longFirstName" }) }
      ],
      placeholder:  "signUp.form.firstName"
    },
    {
      name: "last_name",
      rules: [
        { required: true,message: intl.formatMessage({ id: "signUp.form.emptyLastName" }) },
        { max: 50, message: intl.formatMessage({ id: "signUp.form.longLastName" }) }
      ],
      placeholder:  "signUp.form.lastName"
    },
  ];

  useEffect(() => {
    observeConfirmPasswordField("SignUpForm_password", "SignUpForm_confirm_password");
  }, [])

  const onFinish = formValues => {
    handleSignUp(formValues);
  };

  return (
    <Form
      name="SignUpForm"
      className="sign-up-form"
      form={form}
      onFinish={onFinish}
    >
      <div className="sign-up-form__user-name">
        {fieldsData.map(fieldData => (
          <Form.Item
            name={fieldData.name}
            rules={fieldData.rules}
          >
            <Input
              className="simple-input"
              placeholder={intl.formatMessage({ id: fieldData.placeholder })}
            />
          </Form.Item>
        ))}
      </div>
      <Form.Item
        name="email"
        rules={[
          { required: true, type: "email", message: intl.formatMessage({id: "signUp.form.invalidEmail"}) }
        ]}
      >
        <Input
          className="simple-input"
          placeholder={intl.formatMessage({ id: "signUp.form.emailAddress" })}
        />
      </Form.Item>
      <Form.Item
        name="password"
        className="form-item"
        dependencies={["confirm_password"]}
        rules={[
          {
            validator: (_, value) => {
              validatePassword(value);
              return Promise.resolve("");
            },
          },
        ]}
      >
        <Input.Password
          placeholder={intl.formatMessage({id: "signUp.form.password"})}
          iconRender={(visible) => getPasswordIcon(visible)}
        />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        className="form-item"
        dependencies={["password"]}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              validateConfirmPassword(getFieldValue("password"), value);
              return Promise.resolve("");
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={intl.formatMessage({id: "signUp.form.confirmPassword"})}
          iconRender={() =>
            passwordsMatch ? <IconDoubleCheck /> : null
          }
        />
      </Form.Item>
      {renderValidations()}
      <div className="sign-up-form__bottom">
        <p className="login__sign-up">
          <FormattedMessage id="signUp.haveAccount" />
          <a href={LOGIN_PATH}>
            <FormattedMessage id="signUp.signIn" />
          </a>
        </p>
        <SimpleButton
          customClass="login__continue"
          htmlType="submit"
          disabled={!passwordLength || !passwordLetter || !passwordsMatch || isLoading}
        >
          <FormattedMessage id="general.next" /> <IconArrowRight />
        </SimpleButton>
        <div className="bottom-message">
          <span><FormattedMessage id="signUp.creatingAccount" /></span>
          <a><FormattedMessage id="signUp.termsOfService" /></a>
          <span><FormattedMessage id="signUp.and" /></span>
          <a><FormattedMessage id="signUp.privacy" /></a>
        </div>
      </div>
    </Form>
  );
};

export default withPassword(injectIntl(SignUpForm));
