import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FiltersBarSearchLocationForm } from "components/Forms";
import "./styles.scss";

const LocationsSelectView = props => {
  const { criteriaData, isMap, onSubmit } = props;
  const initialData = criteriaData.locations || [];

  return (
    <div className={classNames("locations-select", { isMap })}>
      <FiltersBarSearchLocationForm
        initialData={initialData}
        onSubmitForm={onSubmit}
      />
    </div>
  );
};

LocationsSelectView.propTypes = {
 criteriaData: PropTypes.object,
 isMap: PropTypes.bool,
 onSubmit: PropTypes.func,
};

export default LocationsSelectView;
