import React from "react";

const IconCurbAppeal = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2_69)">
      <path
        d="M8.53384 19.461H1.00441V29.1238H8.53384V19.461Z"
        fill="url(#paint0_linear_2_69)"
      />
      <path
        d="M8.84725 21.5314H1.021C0.700311 21.5314 0.470748 21.2218 0.563811 20.9148L1.34044 18.3566C1.40156 18.1552 1.58719 18.0177 1.79762 18.0177H8.84731V21.5314H8.84725Z"
        fill="url(#paint1_linear_2_69)"
      />
      <path
        d="M30.9963 19.461H23.4669V29.1238H30.9963V19.461Z"
        fill="url(#paint2_linear_2_69)"
      />
      <path
        d="M30.9963 26.5921L25.9375 21.5332H23.4669V29.1235H30.9963V26.5921Z"
        fill="url(#paint3_linear_2_69)"
      />
      <path
        d="M23.1527 21.5314H30.9789C31.2996 21.5314 31.5292 21.2218 31.4361 20.9148L30.6595 18.3566C30.5984 18.1552 30.4127 18.0177 30.2023 18.0177H23.1527V21.5314Z"
        fill="url(#paint4_linear_2_69)"
      />
      <path
        d="M25.9411 13.8188V29.1235H6.06064V13.8188L16.0009 3.81838L25.9411 13.8188Z"
        fill="url(#paint5_linear_2_69)"
      />
      <path
        d="M25.9418 13.8188V29.1235H19.6654L6.06164 15.5198V13.8188L16.0017 3.81847L25.9418 13.8188Z"
        fill="url(#paint6_linear_2_69)"
      />
      <path
        d="M9.82184 10.0357C10.0366 11.5004 10.7594 12.8001 11.806 13.7494L25.942 27.8854V13.8188L16.0018 3.81838L9.82184 10.0357Z"
        fill="url(#paint7_linear_2_69)"
      />
      <path
        d="M22.8701 29.1235H13.1473L7.8027 23.7788C7.56739 23.6263 7.41083 23.3616 7.41083 23.0613V18.7598C7.41083 18.2885 7.79452 17.9048 8.26577 17.9048H11.367C11.6679 17.9048 11.9326 18.0615 12.0851 18.2967L12.0619 18.3152L22.8701 29.1235Z"
        fill="url(#paint8_linear_2_69)"
      />
      <path
        d="M11.3673 23.4151H8.26641C8.07085 23.4151 7.91235 23.2566 7.91235 23.061V18.7601C7.91235 18.5645 8.07091 18.406 8.26641 18.406H11.3673C11.5629 18.406 11.7214 18.5645 11.7214 18.7601V23.061C11.7215 23.2566 11.5629 23.4151 11.3673 23.4151Z"
        fill="#503837"
      />
      <path
        d="M11.366 23.9164H8.26508C7.79351 23.9164 7.40983 23.5327 7.40983 23.0611V18.7602C7.40983 18.2886 7.79351 17.9049 8.26508 17.9049H11.366C11.8376 17.9049 12.2213 18.2886 12.2213 18.7602V23.0611C12.2213 23.5327 11.8376 23.9164 11.366 23.9164ZM8.41226 22.9139H11.2189V18.9072H8.41226V22.9139Z"
        fill="white"
      />
      <path
        d="M25.9424 19.6791V29.1235H25.6374L20.2927 23.7788C20.0567 23.6263 19.9001 23.3616 19.9001 23.0613V18.7598C19.9001 18.2885 20.2838 17.9048 20.7557 17.9048H23.8562C24.1572 17.9048 24.4219 18.0615 24.5744 18.2967L24.5867 18.3063L24.5785 18.3153L25.9424 19.6791Z"
        fill="url(#paint9_linear_2_69)"
      />
      <path
        d="M23.8551 23.4151H20.7542C20.5586 23.4151 20.4001 23.2566 20.4001 23.061V18.7601C20.4001 18.5645 20.5586 18.406 20.7542 18.406H23.8551C24.0507 18.406 24.2092 18.5645 24.2092 18.7601V23.061C24.2093 23.2566 24.0507 23.4151 23.8551 23.4151Z"
        fill="#503837"
      />
      <path
        d="M23.8566 23.9164H20.7557C20.2841 23.9164 19.9005 23.5327 19.9005 23.0611V18.7602C19.9005 18.2886 20.2841 17.9049 20.7557 17.9049H23.8566C24.3282 17.9049 24.7119 18.2886 24.7119 18.7602V23.0611C24.7119 23.5327 24.3282 23.9164 23.8566 23.9164ZM20.9028 22.9139H23.7094V18.9072H20.9028V22.9139Z"
        fill="white"
      />
      <path
        d="M28.1891 15.2013L16.5992 3.60756C16.2688 3.27719 15.7332 3.27719 15.4028 3.60756L3.81003 15.2004C3.59391 15.4165 3.59391 15.7669 3.81003 15.983L4.30409 16.4771C4.52022 16.6931 4.87059 16.6931 5.08672 16.4771L15.8435 5.72037C15.9304 5.63344 16.0714 5.63344 16.1585 5.72037L26.9124 16.478C27.1285 16.6941 27.4789 16.6941 27.695 16.478L28.1891 15.9839C28.4052 15.7678 28.4052 15.4174 28.1891 15.2013Z"
        fill="url(#paint10_linear_2_69)"
      />
      <path
        d="M16.5992 3.60757C16.434 3.44238 16.2175 3.35982 16.001 3.35982V5.65513C16.058 5.65513 16.115 5.67688 16.1585 5.72038L26.9125 16.4781C27.1286 16.6941 27.479 16.6941 27.6951 16.4781L28.1892 15.984C28.4053 15.7679 28.4053 15.4175 28.1892 15.2014L16.5992 3.60757Z"
        fill="url(#paint11_linear_2_69)"
      />
      <path
        d="M13.9299 29.1233V18.2627C13.9299 17.7837 14.3182 17.3954 14.7972 17.3954H17.2057C17.6848 17.3954 18.0731 17.7837 18.0731 18.2627V29.1233H13.9299Z"
        fill="#816965"
      />
      <path
        d="M18.5851 29.1233H17.5591V18.5585C17.5591 18.201 17.2666 17.9085 16.9091 17.9085H15.092C14.733 17.9085 14.4419 18.1995 14.4419 18.5585V29.1233H13.416V17.88C13.416 17.3291 13.8626 16.8825 14.4135 16.8825H17.5875C18.1385 16.8825 18.5851 17.3291 18.5851 17.88V29.1233H18.5851Z"
        fill="#503837"
      />
      <path
        d="M15.2672 21.9399C15.5255 21.9399 15.7349 21.7304 15.7349 21.4721C15.7349 21.2138 15.5255 21.0044 15.2672 21.0044C15.0088 21.0044 14.7994 21.2138 14.7994 21.4721C14.7994 21.7304 15.0088 21.9399 15.2672 21.9399Z"
        fill="white"
      />
      <path
        d="M25.9422 26.3862H6.0608V29.1232H25.9422V26.3862Z"
        fill="#654E48"
      />
      <path
        d="M5.84288 28.0538C5.84288 28.4316 5.77144 28.792 5.64094 29.1233H0.201937C0.0714375 28.7921 0 28.4316 0 28.0538C0 27.686 0.0678125 27.3344 0.192 27.0104C0.611937 25.9117 1.67569 25.1324 2.9215 25.1324C4.16731 25.1324 5.231 25.9118 5.651 27.0104C5.77513 27.3344 5.84288 27.6861 5.84288 28.0538Z"
        fill="url(#paint12_linear_2_69)"
      />
      <path
        d="M7.72722 28.3474C7.72722 28.6214 7.67541 28.8829 7.58078 29.1232H3.63522C3.54059 28.8829 3.48878 28.6214 3.48878 28.3474C3.48878 28.0806 3.53797 27.8255 3.62809 27.5905C3.93272 26.7935 4.70434 26.2282 5.60809 26.2282C6.51184 26.2282 7.28347 26.7935 7.58809 27.5905C7.67803 27.8255 7.72722 28.0806 7.72722 28.3474Z"
        fill="url(#paint13_linear_2_69)"
      />
      <path
        d="M32.0005 27.8764C32.0005 28.3168 31.9172 28.7369 31.7651 29.1231H25.4249C25.2729 28.737 25.1895 28.3168 25.1895 27.8764C25.1895 27.4477 25.2686 27.0379 25.4133 26.6602C25.9029 25.3795 27.1428 24.4709 28.595 24.4709C30.0472 24.4709 31.2871 25.3795 31.7767 26.6602C31.9215 27.0379 32.0005 27.4478 32.0005 27.8764Z"
        fill="url(#paint14_linear_2_69)"
      />
      <path
        d="M15.9997 15.3675C19.4491 15.3675 22.2453 12.5712 22.2453 9.12186C22.2453 5.67249 19.4491 2.87623 15.9997 2.87623C12.5504 2.87623 9.75409 5.67249 9.75409 9.12186C9.75409 12.5712 12.5504 15.3675 15.9997 15.3675Z"
        fill="url(#paint15_linear_2_69)"
      />
      <path
        d="M22.2457 9.12196C22.2457 9.11108 22.2454 9.10033 22.2453 9.08946L19.929 6.77315C19.5491 6.35658 19.0236 6.08052 18.4087 6.05052C17.3757 6.00021 16.4842 6.63377 16.1458 7.53758L15.405 6.80071C14.9616 6.31227 14.311 6.01546 13.5918 6.05052C12.1619 6.12015 11.2128 7.51877 11.464 8.92815C11.641 9.92196 12.2572 10.7854 12.9802 11.4877L16.8081 15.3156C19.8757 14.9193 22.2457 12.2975 22.2457 9.12196Z"
        fill="url(#paint16_linear_2_69)"
      />
      <path
        d="M18.4079 6.05051C17.106 5.98708 16.0275 7.0097 16 8.29108C15.9999 8.29389 15.999 8.29389 15.9989 8.29108C15.9713 7.0097 14.8928 5.98708 13.591 6.05051C12.1611 6.12014 11.212 7.51877 11.4631 8.92814C11.8854 11.2985 14.8028 12.9306 15.7225 13.3909C15.897 13.4782 16.102 13.4782 16.2766 13.3909C17.1963 12.9306 20.1136 11.2986 20.5359 8.92814C20.7868 7.5187 19.8378 6.1202 18.4079 6.05051Z"
        fill="url(#paint17_linear_2_69)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2_69"
        x1="4.769"
        y1="20.6855"
        x2="4.769"
        y2="29.2663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_69"
        x1="4.69498"
        y1="18.4631"
        x2="4.69498"
        y2="21.5833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_69"
        x1="27.231"
        y1="20.6855"
        x2="27.231"
        y2="29.2663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2_69"
        x1="27.0553"
        y1="27.426"
        x2="19.2506"
        y2="20.4708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2_69"
        x1="27.3043"
        y1="18.4631"
        x2="27.3043"
        y2="21.5833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2_69"
        x1="16.0006"
        y1="16.6466"
        x2="16.0006"
        y2="34.56"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
        <stop offset="1" stopColor="#DB9E36" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2_69"
        x1="15.5452"
        y1="14.1833"
        x2="6.84639"
        y2="5.48456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2_69"
        x1="22.7412"
        y1="17.6273"
        x2="12.0955"
        y2="6.98157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2_69"
        x1="13.6892"
        y1="24.7839"
        x2="4.72065"
        y2="15.8147"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2_69"
        x1="24.7053"
        y1="23.3104"
        x2="18.963"
        y2="17.5681"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2_69"
        x1="15.9992"
        y1="11.2016"
        x2="15.9992"
        y2="21.2682"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2_69"
        x1="25.7653"
        y1="13.7145"
        x2="20.1836"
        y2="8.60407"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D54003" stopOpacity="0" />
        <stop offset="1" stopColor="#A40002" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2_69"
        x1="1.2739"
        y1="26.2352"
        x2="4.8789"
        y2="29.8399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_2_69"
        x1="4.41273"
        y1="27.028"
        x2="7.02789"
        y2="29.6431"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_2_69"
        x1="26.674"
        y1="25.7564"
        x2="30.8759"
        y2="29.9584"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_2_69"
        x1="13.166"
        y1="6.28876"
        x2="22.7168"
        y2="15.8402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7BAC" />
        <stop offset="1" stopColor="#E24786" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_2_69"
        x1="24.0933"
        y1="16.7689"
        x2="17.733"
        y2="10.4085"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7BAC" stopOpacity="0" />
        <stop offset="1" stopColor="#E24786" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_2_69"
        x1="14.6499"
        y1="7.32619"
        x2="20.8301"
        y2="13.5071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#E2EDF2" />
      </linearGradient>
      <clipPath id="clip0_2_69">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCurbAppeal;
