import React from "react";

const IconMailCheck = ({fillIcon, ...otherProps}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <circle cx="10" cy="10" r="10" fill="white" />
    <path
      d="M14.2658 5.38721L8.23844 12.2544L5.69157 9.76612C4.65641 8.70753 3.05094 10.2778 4.08219 11.3364L7.49625 14.6724C7.70329 14.8755 7.98844 15.0005 8.30094 15.0005C8.64469 15.0005 8.95329 14.8482 9.16422 14.6099L15.9923 6.83252C16.9025 5.74268 15.1759 4.29737 14.2658 5.38721Z"
      fill={fillIcon || "#3C9B6E"}
    />
  </svg>
);

export default IconMailCheck;
