import React from 'react';
import BaseLayout from "components/Layouts/BaseLayout";
import HouseDetails from 'containers/HouseDetails';
import _ from "lodash";
import queryString from 'query-string';

const HouseDetailsPage = (props) => {
  const { match } = props;
  const params = queryString.parse(props.location.search);
  const filters = _.has(params, 'filters') ? params.filters : null;
  const propertyId = match.params.id;

  return (
    <BaseLayout>
      <HouseDetails propertyId={propertyId} filters={filters} />
    </BaseLayout>
  )
}

export default HouseDetailsPage;
