import React from 'react';
import { Polygon } from '@react-google-maps/api'
import { inject, observer } from "mobx-react";


const POLYGON_OPTIONS = {
    commute: {
        fillColor: "#207d44",
        fillOpacity: 0.3,
        strokeColor: "#00FF00",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    },
    custom_distance: {
        fillColor: "#932d2e",
        fillOpacity: 0.3,
        strokeColor: "#FF0000",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1 
    }
}

const MapIsochrones = observer(({ housesStore }) => {
    // Don't forget filtering out locations without geometry
    

    // TODO: Optimize rendering
    // Avoid calling this function multiple times
    // For the same data
    // Maybe useMemo or add useEffect with state

    if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
        return null;
    }

    const getCoordinates = (rawCoordinates) => {
        const geometry = rawCoordinates &&
            rawCoordinates.map(coordinates => ({lat: coordinates[1], lng: coordinates[0]}))
        
        return geometry
    }

    const renderIsochrone = (rawCoordinates, global_index, label) => {
        try {
            if (!rawCoordinates) {
                return null;
            }
    
            const geometries = rawCoordinates.map(item => getCoordinates(item));
    
            return (
                <>
                    {geometries.map((item, index) => (
                        <Polygon
                            key={`${label}-${global_index}-${index}`}
                            options={POLYGON_OPTIONS[label]}
                            paths={item}
                        />
                    ))}
                </>
            );
        } catch {
            return null;
        }
    }

    const housesData = housesStore.retrieveHousesData()

    if (!housesData || !housesData.isochrones) {
        return null;
    }

    return (
        <>
            {housesData?.isochrones?.custom_distance?.map((isochrone, index) => renderIsochrone(isochrone, index, 'custom_distance'))}
            {housesData?.isochrones?.commute?.map((isochrone, index) => renderIsochrone(isochrone, index, 'commute'))}
        </>
    );
})

export default inject('housesStore')(MapIsochrones);
