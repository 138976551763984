import React from "react";

const IconEyeShow = (props) => (
  <svg
    width="20"
    height="14"
    {...props}
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.07431 9.3397C4.80478 6.33189 7.51181 2.40612 11.0899 3.42175C8.15243 5.18346 11.7735 8.88658 13.5782 5.90611C14.711 10.285 9.3165 12.2772 7.07431 9.3397Z"
      fill="#939DF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7935 7.6717C14.8364 15.0428 5.16457 15.1756 0.199731 7.67561C-0.0698005 7.26545 -0.0658943 6.71858 0.207543 6.31623C5.17239 -0.996269 14.7896 -1.21111 19.7974 6.30842C20.0669 6.71467 20.0669 7.26545 19.7935 7.6717ZM2.48879 6.99201C7.20754 1.6092 12.8911 1.53889 17.5083 6.99201C12.8911 12.3709 7.18411 12.3006 2.48879 6.99201Z"
      fill="#939DF4"
    />
  </svg>
);

export default IconEyeShow;
