import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { IconButton } from "components/Controls/Buttons";
import { checkIsButtonFilled, handleActiveCriteria } from "utils/helpers/onboardingHelper";
import "./styles.scss";

const CriteriaButtonView = props => {
  const { activeCriteriaKey, currentCriteria, stepData, changeActiveCriteria } = props;
  return (
    <IconButton
      customClassWrapper="criteria-button-wrapper"
      highlightMode="button"
      id={currentCriteria.key}
      isActive={activeCriteriaKey === currentCriteria.key}
      isFill={checkIsButtonFilled(currentCriteria, stepData)}
      leftIcon={currentCriteria.icon}
      message={<FormattedMessage id={currentCriteria.message}/>}
      onClick={() => handleActiveCriteria(activeCriteriaKey, currentCriteria.key, changeActiveCriteria)}
    />
  );
};

CriteriaButtonView.propTypes = {
  activeCriteriaKey: PropTypes.string,
  currentCriteria: PropTypes.object,
  stepData: PropTypes.object,
  changeActiveCriteria: PropTypes.func,
};

export default CriteriaButtonView;
