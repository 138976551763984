import React from "react";
import _, { get as lodashGet } from "lodash";
import { FormattedMessage } from "react-intl";
import {
  getBathsValue,
  getBedsValue,
  getGarageValue,
  getPricePerSqftValue,
  getPriceValue,
  getPropertyTypeValue,
  getYearBuiltValue
} from "./getter";

const checkItemPill = (item, criteria_map) => {
  const score = item === null ? null : item.score,
    criteria_value = item === null ? null : item.value;
  return [criteria_map, score,  criteria_value];
};

const getHouseDetailsValue = (houseDetails, key, valueKey) => {
  const value = lodashGet(houseDetails, valueKey || key, null);
  if (_.isNil(value)) {
    return <FormattedMessage id="general.noData" />;
  }
  const handleMethod = {
    year_built: getYearBuiltValue,
    bathrooms: getBathsValue,
    bedrooms: getBedsValue,
    garage: getGarageValue,
    price: getPriceValue,
    price_per_sqft: getPricePerSqftValue,
    prop_type: getPropertyTypeValue,
  }[key];
  return handleMethod ? handleMethod(value, houseDetails) : value;
};

export { checkItemPill, getHouseDetailsValue };
