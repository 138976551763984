import React from "react";

const IconLetter = () => (
  <svg width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M214.821 49.069L19.0664 58.2988L25.2882 190.246L221.043 181.016L214.821 49.069Z" fill="#939DF4"/>
  <path d="M25.2153 190.297V190.227L19 58.2284H19.0706L214.899 49V49.0706L221.115 181.069H221.044L25.2153 190.297ZM19.1177 58.3461L25.333 190.18L220.973 180.951L214.758 49.1177L19.1177 58.3461Z" fill="#263238"/>
  <path d="M215.518 50.6254C215.565 50.6725 214.953 51.3081 213.776 52.4616C212.458 53.7329 210.715 55.4044 208.549 57.4996C203.817 61.949 197.202 68.164 189.103 75.7915C172.411 91.2349 149.598 112.352 124.29 135.776L123.748 136.27L123.16 135.846C122.713 135.517 122.218 135.164 121.724 134.811C93.5431 114.282 68.0463 95.7078 49.4004 82.1242C40.3129 75.3913 32.897 69.9061 27.5998 65.9981C25.1514 64.1383 23.1973 62.6552 21.7377 61.5487C20.3958 60.5129 19.713 59.9479 19.7601 59.9008C19.8072 59.8537 20.537 60.3246 21.9496 61.2663C23.4798 62.3256 25.5045 63.7146 28.0236 65.4567C33.4149 69.2704 40.925 74.5909 50.1538 81.1355C68.8703 94.6013 94.4848 113.035 122.783 133.398C123.278 133.751 123.772 134.104 124.219 134.434L123.089 134.505C148.515 111.245 171.47 90.2697 188.256 74.9205C196.519 67.4577 203.276 61.4075 208.102 57.0523C210.362 55.0512 212.175 53.4739 213.54 52.2733C214.788 51.1198 215.471 50.5783 215.518 50.6254Z" fill="#263238"/>
  <path d="M25.9694 191.805C25.5692 191.498 39.4358 172.736 56.9517 149.853C74.4439 126.994 88.9463 108.702 89.3465 108.985C89.7467 109.291 75.88 128.053 58.3642 150.936C40.872 173.795 26.3696 192.087 25.9694 191.805Z" fill="#263238"/>
  <path d="M154.327 104.276C154.704 103.946 170.101 121.226 188.7 142.837C207.322 164.472 222.107 182.246 221.73 182.575C221.354 182.905 205.98 165.625 187.358 144.014C168.736 122.379 153.951 104.605 154.327 104.276Z" fill="#263238"/>
  </svg>
);

export default IconLetter;
