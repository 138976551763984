import React, { useState } from "react";
import PropTypes from "prop-types";
import CriteriaList from "./CriteriaList";
import CriterionFilter from "./CriterionFilter";
import "./styles.scss";

const FirstStep = props => {
  const { currentStepData, setCurrentStepData } = props;
  const [activeCriterion, setActiveCriterion] = useState(null);

  const onActiveCriterionChange = key => setActiveCriterion(activeCriterion === key ? null : key);

  return (
    <div className="first-step">
      <CriteriaList
        activeCriterion={activeCriterion}
        currentStepData={currentStepData}
        onActiveCriterionChange={onActiveCriterionChange}
        setCurrentStepData={setCurrentStepData}
      />
      {activeCriterion && (
        <CriterionFilter
          activeCriterion={activeCriterion}
          currentStepData={currentStepData}
          onActiveCriterionChange={onActiveCriterionChange}
          setCurrentStepData={setCurrentStepData}
        />
      )}
    </div>
  );
};

FirstStep.propTypes = {
  currentStepData: PropTypes.object,
  setCurrentStepData: PropTypes.func,
};

export default FirstStep;
