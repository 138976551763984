import React from "react";

const IconArrowRight = (props) => (
  <svg
    width="16"
    height="9"
    {...props}
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6705 8.28794L15.657 5.30149C16.1074 4.80514 16.1241 3.92923 15.657 3.39117L12.6705 0.404725C11.2691 -0.838236 9.54642 1.06374 10.7602 2.31505L11.4567 3.01161H1.32951C-0.443171 3.01161 -0.443171 5.68105 1.32951 5.68105H11.4567L10.7602 6.37761C9.51305 7.70399 11.3817 9.52673 12.6705 8.28794Z" />
  </svg>
);

export default IconArrowRight;
