import React from "react";
import _, {
  cloneDeep as lodashCloneDeep,
  filter as lodashFilter,
  get as lodashGet,
  map as lodashMap
} from "lodash";
import { FormattedMessage } from "react-intl";
import Moment from "moment";
import { DATETIME_BACKEND_FORMAT } from "constants/common";
import { RANGE_TYPE_CRITERIA, SYB_TYPE_CRITERIA } from "constants/criteria";
import { LISTING_STATUS_MESSAGES_MAPPINGS } from "constants/houses";
import { getBooleanValueByScore, getValueClassnameByScore, getValueClassnameByScoreRangeType } from "./common";
import {
  DAYS_ON_HIHOME_FORMAT,
  FIRST_ROW_CRITERIA,
  HOUSE_PROPERTY_TYPES,
  HOUSE_STATUS_CLASSNAMES,
  QUIETNESS_STATUSES,
  VALUE_KEY
} from "./constants";

const getAmountOfDaysOnHiHome = item => {
  const createdAt = lodashGet(item, "external_created_at", item.created_at);
  const created = Moment(
    createdAt,
    DATETIME_BACKEND_FORMAT
  ).startOf("day");
  const datesDifference = Moment().startOf("day").diff(created, DAYS_ON_HIHOME_FORMAT);
  return Moment.duration(datesDifference, DAYS_ON_HIHOME_FORMAT).asDays();
};

const getBathsValue = value => getDataTitle("houses.card.bathValue", { number: value });

const getBedsValue = value => getDataTitle("houses.card.bedsValue", { number: value });

const getBooleanValue = value =>
    value ? <FormattedMessage id="general.yesMessage" /> : <FormattedMessage id="general.noMessage" />;

const getClassnameForStatus = (status) =>
  HOUSE_STATUS_CLASSNAMES[status] || HOUSE_STATUS_CLASSNAMES.default;

const getClosestSchools = data => {
  const schools = lodashGet(data, 'schools.schools', []);
  return (
    <div>
      {lodashMap(schools, item => (
        <div style={{ paddingBottom: "10px" }}>
          <div>
            <FormattedMessage id="houseDetails.schoolName" values={{ value: lodashGet(item, 'name') }} />
          </div>
          <div>
            <FormattedMessage
              id="houseDetails.schoolRating"
              values={{ value: lodashGet(item, 'ratings.great_schools_rating') }}
            />
          </div>
          <div>
            <FormattedMessage
              id="houseDetails.schoolLevel"
              values={{ value: lodashGet(item, 'education_levels[0]') }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const getDataTitle = (messageId, values) => <FormattedMessage id={messageId} values={values} />;

const getDescription = (item, key) => {
  const valueKey = VALUE_KEY[key] || null;
  const description = _.get(item, `${key}.${valueKey}`, null);
  return getHouseParameterValue(description);
};

const getFormattedValueOrNull = (value, method) => (
  _.isNil(value) ? <FormattedMessage id="general.noData" /> : method(value)
);

const getFullAddress = address =>
    `${lodashGet(address, 'line')}, ${lodashGet(address, 'city')}, ${lodashGet(address, 'state_code')} 
    ${lodashGet(address, 'postal_code')}`;

const getGarageValue = (value, houseDetails) => {
  if (!_.isNumber(value)) {
    return getBooleanValue(houseDetails.garage.value);
  }
  return getDataTitle("houseDetails.carGarage", { value });
};

const getHighestCriteria = housesData => {
  const criteriaImportance = lodashGet(housesData, "criteria_importance", []);
  const highestCriteria = lodashCloneDeep(criteriaImportance);
  return lodashFilter(highestCriteria, item => !FIRST_ROW_CRITERIA.includes(item));
};

const getHouseParameterValue = value => _.isNil(value) ? <FormattedMessage id="general.noData" /> : value;

const getListValue = (value, separator = '\n') => value.join(separator);

const getLotSizeMessage = data => {
  const value = lodashGet(data, 'lot_size.value');
  const units = lodashGet(data, 'lot_size.units');
  return <FormattedMessage id="houseDetails.lotSizeMessage" values={{ value, units }} />;
};

const getMediumAddress = address => `${lodashGet(address, 'line')} ${lodashGet(address, 'city')}`;

const getMethodForScore = (kind, score, value) => {
  if (RANGE_TYPE_CRITERIA.includes(kind)) {
    return getValueClassnameByScoreRangeType(score, value);
  } else if (SYB_TYPE_CRITERIA.includes(kind)) {
    return getValueClassnameByScore(score, value);
  } else {
    return getBooleanValueByScore(score, value);
  }
};

const getPricePerSqft = data => {
  if (data.price_per_sqft) {
    return `$${data.price_per_sqft}/sqft`;
  }
  return <FormattedMessage id="general.noData" />;
};

const getPriceValue = value => (
  value.toLocaleString("en-US", { style: "currency", currency: "USD", maximumSignificantDigits: 10 })
);

const getPricePerSqftValue = value => {
  const currencyValue = getPriceValue(parseInt(value));
  return <FormattedMessage id="general.pricePerSqft" values={{ value: currencyValue }} />;
};

const getPricePerSqftAndPrice = data => {
  const price = lodashGet(data, "price.value");
  if (price) {
    const priceValue = getPriceValue(price);
    if (data.price_per_sqft) {
      return `${priceValue} (${getPricePerSqft(data)}) `;
    }
    return priceValue;
  }
  return `$${<FormattedMessage id="general.noData" />}`;
};

const getPropertyType = houseDetails => {  // remove code repeat
  const message = HOUSE_PROPERTY_TYPES[lodashGet(houseDetails, "prop_type")];
  if (message) {
    return <FormattedMessage id={message}/>;
  }
  return _.capitalize(houseDetails.prop_type);
};

const getPropertyTypeValue = value => {
  const message = HOUSE_PROPERTY_TYPES[value];
  if (message) {
    return <FormattedMessage id={message} />;
  }
  return _.capitalize(value);
};

const getStatusValue = record => {
  const message = LISTING_STATUS_MESSAGES_MAPPINGS[record.listing_status];
  if (message) {
    return <FormattedMessage id={message} />;
  }
  return _.capitalize(record.listing_status);
};

const getValueClassnameByValue = (value, score) => (
  score !== -1 ? (QUIETNESS_STATUSES[value] || QUIETNESS_STATUSES.default) : QUIETNESS_STATUSES.default
);

const getValueOrNull = item => _.isNull(item) ? null : item.value;

const getYearBuiltValue = value => getDataTitle("houseDetails.builtIn", { value });

const getBathsAndBeds = (item, message) => {
  if (item){
    switch (true){
      case item.value > 0:
        return <FormattedMessage id={`houses.list.${message}`} values={{number: item.value}}/>;
      case item.value === 0:
        return 0
      default:
        return null
  }
  }
  return null
}


export {
  getAmountOfDaysOnHiHome,
  getBathsValue,
  getBedsValue,
  getClassnameForStatus,
  getClosestSchools,
  getDescription,
  getGarageValue,
  getFormattedValueOrNull,
  getFullAddress,
  getHighestCriteria,
  getHouseParameterValue,
  getListValue,
  getLotSizeMessage,
  getMediumAddress,
  getMethodForScore,
  getPricePerSqft,
  getPricePerSqftValue,
  getPricePerSqftAndPrice,
  getPriceValue,
  getPropertyType,
  getPropertyTypeValue,
  getStatusValue,
  getValueClassnameByValue,
  getValueOrNull,
  getYearBuiltValue,
  getBathsAndBeds,
  getBooleanValue,
};

