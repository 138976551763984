import React from "react";

const IconFavorites = (props) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.98449 2.37687C5.46768 -2.64929 -2.05472 2.0359 0.525711 8.27019C1.66649 10.7033 8.55326 16.8702 9.33623 17.5606C9.70246 17.8511 10.2497 17.8931 10.6328 17.5648C11.4157 16.8702 18.3025 10.7075 19.4433 8.2744C22.1921 2.01064 14.0804 -2.64929 9.98449 2.37687Z"
    />
  </svg>
);

export default IconFavorites;
