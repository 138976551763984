import React from "react";

const IconCheckCriteria = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="16" height="16" rx="8" fill="url(#paint0_linear_2732_4568)"/>
  <svg width="15" height="15" viewBox="-3 -6 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.57156 0.271034L3.23176 5.07783L1.39796 3.33612C0.652632 2.59514 -0.503335 3.6943 0.239184 4.43528L2.69737 6.77032C2.84644 6.9125 3.05176 7 3.27676 7C3.52427 7 3.74646 6.89336 3.89834 6.72658L8.81472 1.2827C9.47005 0.51985 8.22689 -0.491819 7.57156 0.271034Z" fill="white"/>
  </svg>
  <defs>
  <linearGradient id="paint0_linear_2732_4568" x1="0" y1="16" x2="16" y2="16" gradientUnits="userSpaceOnUse">
  <stop stopColor="#9571F6"/>
  <stop offset="1" stopColor="#583EF5"/>
  </linearGradient>
  </defs>
</svg>
);

export default IconCheckCriteria;
