import React from "react";

const IconLetterSmall = () => (
<svg width="553" height="372" viewBox="0 0 553 372" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_3269_12162)">
<g clipPath="url(#clip1_3269_12162)">
<path d="M489.912 189.157L410.891 207.211L423.063 260.458L502.084 242.405L489.912 189.157Z" fill="white"/>
<path d="M490.291 189.743C490.309 189.761 490.113 190.063 489.714 190.622C489.269 191.235 488.683 192.044 487.954 193.057C486.354 195.215 484.115 198.227 481.369 201.923C475.708 209.421 467.959 219.691 459.366 231.062L459.188 231.302L458.912 231.169C458.708 231.071 458.477 230.964 458.255 230.849C445.28 224.55 433.541 218.856 424.956 214.689C420.762 212.619 417.349 210.922 414.905 209.714C413.777 209.137 412.87 208.675 412.186 208.328C411.573 208.008 411.253 207.822 411.262 207.804C411.271 207.786 411.617 207.919 412.248 208.195C412.95 208.515 413.875 208.932 415.021 209.456C417.492 210.611 420.94 212.237 425.178 214.218C433.79 218.331 445.556 223.964 458.566 230.183C458.788 230.289 459.019 230.396 459.223 230.502L458.77 230.609C467.426 219.291 475.237 209.066 480.942 201.603C483.768 197.969 486.07 195.002 487.714 192.879C488.487 191.902 489.109 191.12 489.58 190.534C490.033 190.001 490.265 189.725 490.291 189.743Z" fill="#E0E0E0"/>
<path d="M423.443 261.046C423.265 260.957 427.54 252.295 432.987 241.714C438.444 231.125 443.002 222.623 443.189 222.721C443.367 222.809 439.092 231.471 433.645 242.052C428.188 252.633 423.621 261.135 423.443 261.046Z" fill="#E0E0E0"/>
<path d="M469.303 216.048C469.427 215.888 476.963 221.796 486.116 229.232C495.278 236.677 502.592 242.833 502.468 242.993C502.343 243.153 494.816 237.245 485.654 229.809C476.492 222.365 469.169 216.208 469.303 216.048Z" fill="#E0E0E0"/>
<path d="M137.889 44.3985L130.641 75.7783L177.21 86.5283L184.458 55.1485L137.889 44.3985Z" fill="white"/>
<path d="M184.52 55.5779C184.529 55.5868 184.342 55.7023 183.987 55.8978C183.596 56.111 183.071 56.3864 182.431 56.7328C181.027 57.4613 179.063 58.483 176.655 59.7445C171.723 62.2675 164.969 65.7234 157.486 69.5435L157.327 69.6234L157.22 69.4813C157.14 69.3747 157.042 69.2592 156.953 69.1437C151.692 62.463 146.929 56.4219 143.446 52.0066C141.757 49.83 140.38 48.0532 139.393 46.7828C138.94 46.1876 138.585 45.7079 138.309 45.3525C138.069 45.0238 137.945 44.8461 137.954 44.8373C137.962 44.8284 138.114 44.9883 138.38 45.2992C138.673 45.6457 139.056 46.1077 139.527 46.6851C140.54 47.9378 141.953 49.679 143.685 51.82C147.196 56.2176 151.986 62.232 157.291 68.8772C157.38 68.9927 157.478 69.1081 157.558 69.2147L157.291 69.1526C164.791 65.3769 171.572 61.9566 176.522 59.4691C178.957 58.2698 180.938 57.2836 182.36 56.5818C183.027 56.262 183.551 56.0132 183.96 55.8178C184.315 55.649 184.511 55.5602 184.52 55.5779Z" fill="#E0E0E0"/>
<path d="M130.704 76.206C130.633 76.1083 135.13 72.6169 140.746 68.4059C146.362 64.1949 150.974 60.8546 151.046 60.9523C151.117 61.05 146.62 64.5414 141.004 68.7524C135.387 72.9633 130.775 76.2948 130.704 76.206Z" fill="#E0E0E0"/>
<path d="M166.613 64.1332C166.72 64.0799 169.199 69.1526 172.14 75.4513C175.082 81.7589 177.384 86.9027 177.277 86.956C177.17 87.0093 174.691 81.9366 171.749 75.6379C168.799 69.3303 166.506 64.1776 166.613 64.1332Z" fill="#E0E0E0"/>
<path d="M433.082 52.2559L399.059 66.8711L408.914 89.7997L442.938 75.1845L433.082 52.2559Z" fill="white"/>
<path d="M433.296 52.4779C433.304 52.4868 433.242 52.6378 433.109 52.9221C432.958 53.2331 432.762 53.6417 432.522 54.157C431.98 55.2586 431.225 56.7955 430.292 58.6789C428.363 62.5168 425.724 67.7672 422.809 73.5861L422.747 73.7105L422.614 73.675C422.516 73.6483 422.4 73.6128 422.294 73.5861C415.949 71.8271 410.199 70.228 405.996 69.0642C403.943 68.4779 402.263 67.9981 401.072 67.6606C400.521 67.4918 400.077 67.3585 399.739 67.2608C399.437 67.1631 399.277 67.1098 399.277 67.1009C399.277 67.092 399.446 67.1275 399.757 67.1986C400.095 67.2874 400.548 67.394 401.108 67.5362C402.317 67.856 403.996 68.3002 406.067 68.8421C410.27 69.9793 416.029 71.5428 422.383 73.2663C422.489 73.293 422.605 73.3285 422.703 73.3551L422.507 73.444C425.466 67.6428 428.132 62.4102 430.079 58.5812C431.047 56.7156 431.838 55.1964 432.407 54.1037C432.673 53.5973 432.887 53.1975 433.056 52.8955C433.198 52.6112 433.287 52.469 433.296 52.4779Z" fill="#E0E0E0"/>
<path d="M409.118 90.0216C409.029 89.995 410.238 85.7485 411.811 80.5514C413.392 75.3454 414.743 71.1522 414.832 71.1788C414.921 71.2055 413.712 75.4431 412.13 80.6491C410.558 85.8462 409.207 90.0483 409.118 90.0216Z" fill="#E0E0E0"/>
<path d="M426.024 66.0267C426.068 65.9467 429.934 67.9812 434.662 70.5664C439.389 73.1605 443.184 75.3193 443.139 75.3992C443.095 75.4792 439.229 73.4448 434.502 70.8595C429.783 68.2743 425.979 66.1066 426.024 66.0267Z" fill="#E0E0E0"/>
<path d="M421.536 357.756H105.721C98.5676 357.756 92.7734 351.964 92.7734 344.812V144.799C92.7734 137.648 98.5676 131.855 105.721 131.855H421.536C428.69 131.855 434.484 137.648 434.484 144.799V344.812C434.484 351.964 428.69 357.756 421.536 357.756Z" fill="#455A64"/>
<path d="M433.819 362.936H95.2363C86.5007 362.936 79.418 355.855 79.418 347.122V343.169H449.628V347.122C449.637 355.855 442.554 362.936 433.819 362.936Z" fill="#455A64"/>
<path d="M424.354 140.828H101.945V331.593H424.354V140.828Z" fill="#263238"/>
<path d="M229.938 341.045L234.532 348.356H299.182L303.448 341.045H229.938Z" fill="#263238"/>
<path d="M415.465 330.99H111.496C109.887 330.99 108.59 329.684 108.59 328.085V150.202C108.59 148.594 109.896 147.297 111.496 147.297H415.465C417.073 147.297 418.371 148.603 418.371 150.202V328.085C418.38 329.693 417.073 330.99 415.465 330.99Z" fill="#EBEBEB"/>
<path d="M418.04 331.257H108.25L108.588 161.023H418.04V331.257Z" fill="white"/>
<path d="M134 154.386C134 155.754 132.889 156.855 131.529 156.855C130.161 156.855 129.059 155.745 129.059 154.386C129.059 153.018 130.169 151.916 131.529 151.916C132.889 151.916 134 153.018 134 154.386Z" fill="#263238"/>
<path d="M118.996 154.386C118.996 155.754 117.885 156.855 116.525 156.855C115.157 156.855 114.055 155.745 114.055 154.386C114.055 153.018 115.166 151.916 116.525 151.916C117.894 151.916 118.996 153.018 118.996 154.386Z" fill="#263238"/>
<path d="M126.191 154.386C126.191 155.754 125.08 156.855 123.72 156.855C122.352 156.855 121.25 155.745 121.25 154.386C121.25 153.018 122.361 151.916 123.72 151.916C125.089 151.916 126.191 153.018 126.191 154.386Z" fill="#263238"/>
<path d="M431.543 341.045C431.543 341.17 355.705 341.267 262.163 341.267C168.596 341.267 92.7656 341.17 92.7656 341.045C92.7656 340.921 168.596 340.823 262.163 340.823C355.705 340.823 431.543 340.93 431.543 341.045Z" fill="#263238"/>
<path d="M207.774 227.518L333.814 226.346L337.502 311.996L206.992 311.063L207.774 227.518Z" fill="#939DF4"/>
<path d="M207.773 227.519L267.323 179.146C269.847 177.094 273.455 177.103 275.979 179.155L333.813 226.346" fill="#939DF4"/>
<g opacity="0.3">
<g opacity="0.3">
<g opacity="0.3">
<path opacity="0.3" d="M207.773 227.519L267.323 179.146C269.847 177.094 273.455 177.103 275.979 179.155L333.813 226.346" fill="black"/>
</g>
</g>
</g>
<path d="M206.99 311.063C206.83 310.912 216.899 299.878 229.473 286.419C242.048 272.951 252.374 262.157 252.525 262.308C252.685 262.459 242.617 273.484 230.042 286.952C217.476 300.411 207.15 311.205 206.99 311.063Z" fill="#263238"/>
<path d="M337.507 311.995C337.347 312.137 327.501 301.37 315.513 287.938C303.524 274.505 293.936 263.507 294.096 263.365C294.256 263.223 304.102 273.99 316.09 287.422C328.078 300.855 337.667 311.853 337.507 311.995Z" fill="#263238"/>
<g opacity="0.5">
<g opacity="0.5">
<g opacity="0.5">
<path opacity="0.5" d="M333.813 226.346L270.629 277.02L207.773 227.518" fill="black"/>
</g>
</g>
</g>
<path d="M214.14 232.191L213.855 192.836L324.219 191.308L325.543 232.707L270.624 276.362L214.14 232.191Z" fill="white"/>
<path d="M214.141 232.191C214.141 232.191 214.506 232.458 215.19 232.973C215.874 233.506 216.887 234.279 218.203 235.292C220.842 237.335 224.699 240.329 229.622 244.158C239.477 251.833 253.598 262.841 270.75 276.202H270.519C286.337 263.614 305.105 248.68 325.42 232.52L325.332 232.706C325.074 224.809 324.807 216.52 324.541 208.089C324.363 202.412 324.176 196.806 323.999 191.307L324.23 191.529C279.761 192.116 240.535 192.631 213.866 192.977L214.008 192.826C214.061 205.202 214.106 215.027 214.141 221.77C214.15 225.137 214.15 227.731 214.159 229.499C214.159 230.37 214.159 231.036 214.15 231.498C214.15 231.951 214.141 232.191 214.141 232.191C214.141 232.191 214.124 231.969 214.115 231.525C214.106 231.072 214.097 230.405 214.079 229.544C214.061 227.785 214.026 225.191 213.99 221.832C213.928 215.072 213.83 205.228 213.715 192.835V192.684H213.866C240.526 192.293 279.752 191.716 324.221 191.067H324.443L324.452 191.289C324.63 196.789 324.807 202.394 324.994 208.071C325.26 216.502 325.527 224.791 325.785 232.689V232.804L325.696 232.875C305.372 249.008 286.586 263.924 270.75 276.495L270.634 276.593L270.519 276.504C253.394 263.072 239.282 251.993 229.444 244.273C224.548 240.418 220.718 237.397 218.105 235.336C216.816 234.314 215.821 233.524 215.146 232.991C214.47 232.467 214.141 232.191 214.141 232.191Z" fill="#263238"/>
<path d="M306.819 211.154C306.819 211.278 289.943 211.527 269.131 211.696C248.309 211.865 231.434 211.9 231.434 211.776C231.434 211.651 248.309 211.403 269.131 211.234C289.935 211.056 306.819 211.021 306.819 211.154Z" fill="#263238"/>
<path d="M306.839 222.268C306.839 222.392 289.963 222.641 269.15 222.809C248.329 222.978 231.453 223.014 231.453 222.889C231.453 222.765 248.329 222.516 269.15 222.348C289.963 222.179 306.839 222.143 306.839 222.268Z" fill="#263238"/>
<path d="M306.862 235.079C306.862 235.203 289.986 235.452 269.174 235.621C248.352 235.79 231.477 235.825 231.477 235.701C231.477 235.577 248.352 235.328 269.174 235.159C289.986 234.99 306.862 234.955 306.862 235.079Z" fill="#263238"/>
<path d="M306.885 247.889C306.885 248.013 290.782 248.253 270.929 248.413C251.068 248.573 234.965 248.599 234.965 248.475C234.965 248.351 251.059 248.111 270.929 247.951C290.782 247.791 306.885 247.764 306.885 247.889Z" fill="#263238"/>
<path d="M292.801 259.163C292.801 259.18 292.65 259.198 292.357 259.216C292.011 259.234 291.593 259.251 291.086 259.269C289.913 259.296 288.34 259.34 286.421 259.385C282.422 259.456 277.01 259.553 271.038 259.66C265.031 259.776 259.583 259.811 255.646 259.802C253.727 259.793 252.154 259.776 250.981 259.776C250.474 259.767 250.057 259.749 249.71 259.74C249.417 259.731 249.266 259.713 249.266 259.696C249.266 259.678 249.417 259.66 249.71 259.642C250.057 259.625 250.474 259.607 250.981 259.589C252.154 259.562 253.727 259.518 255.646 259.473C259.645 259.402 265.057 259.305 271.029 259.198C277.036 259.083 282.475 259.047 286.412 259.056C288.331 259.065 289.904 259.083 291.077 259.083C291.584 259.092 292.002 259.109 292.348 259.118C292.65 259.136 292.801 259.154 292.801 259.163Z" fill="#263238"/>
<path d="M278.615 270.001C278.615 270.125 275.016 270.241 270.582 270.25C266.138 270.259 262.548 270.161 262.539 270.036C262.539 269.912 266.138 269.797 270.573 269.788C275.016 269.779 278.615 269.877 278.615 270.001Z" fill="#263238"/>
<path d="M293.487 190.96C293.611 190.978 293.664 191.476 293.611 192.089C293.549 192.702 293.398 193.181 293.274 193.173C293.149 193.164 293.096 192.657 293.149 192.044C293.203 191.431 293.354 190.943 293.487 190.96Z" fill="#263238"/>
<path d="M294.67 182.307C294.794 182.325 294.75 183.311 294.572 184.502C294.394 185.692 294.136 186.643 294.012 186.625C293.888 186.607 293.932 185.63 294.11 184.43C294.296 183.231 294.545 182.281 294.67 182.307Z" fill="#263238"/>
<path d="M296.413 173.743C296.537 173.769 296.43 174.747 296.164 175.928C295.906 177.101 295.586 178.034 295.462 178.007C295.337 177.98 295.444 177.003 295.711 175.822C295.977 174.649 296.288 173.716 296.413 173.743Z" fill="#263238"/>
<path d="M298.785 165.339C298.909 165.374 298.723 166.343 298.376 167.498C298.03 168.653 297.647 169.559 297.523 169.523C297.399 169.488 297.585 168.519 297.932 167.364C298.278 166.201 298.661 165.303 298.785 165.339Z" fill="#263238"/>
<path d="M301.832 157.158C301.948 157.211 301.681 158.153 301.237 159.272C300.793 160.391 300.331 161.262 300.215 161.218C300.099 161.173 300.357 160.223 300.81 159.103C301.255 157.975 301.717 157.104 301.832 157.158Z" fill="#263238"/>
<path d="M305.622 149.294C305.737 149.356 305.382 150.272 304.831 151.338C304.28 152.404 303.747 153.23 303.631 153.177C303.516 153.123 303.871 152.208 304.422 151.133C304.973 150.058 305.506 149.232 305.622 149.294Z" fill="#263238"/>
<path d="M310.187 141.85C310.293 141.921 309.831 142.792 309.165 143.787C308.525 144.809 307.912 145.573 307.805 145.511C307.698 145.448 308.134 144.56 308.783 143.538C309.449 142.534 310.08 141.779 310.187 141.85Z" fill="#263238"/>
<path d="M315.571 134.974C315.669 135.063 315.118 135.88 314.354 136.804C313.59 137.728 312.879 138.412 312.781 138.332C312.683 138.252 313.225 137.435 313.998 136.502C314.772 135.578 315.474 134.894 315.571 134.974Z" fill="#263238"/>
<path d="M321.724 128.782C321.813 128.88 321.173 129.626 320.293 130.452C319.422 131.278 318.64 131.873 318.551 131.785C318.462 131.696 319.102 130.95 319.982 130.114C320.862 129.288 321.644 128.684 321.724 128.782Z" fill="#263238"/>
<path d="M328.6 123.398C328.671 123.505 327.943 124.162 326.974 124.873C326.006 125.584 325.152 126.081 325.072 125.975C324.992 125.877 325.721 125.21 326.699 124.5C327.676 123.789 328.529 123.292 328.6 123.398Z" fill="#263238"/>
<path d="M336.133 118.983C336.186 119.098 335.377 119.658 334.32 120.227C333.262 120.795 332.356 121.177 332.285 121.062C332.222 120.955 333.031 120.395 334.097 119.818C335.164 119.241 336.07 118.867 336.133 118.983Z" fill="#263238"/>
<path d="M344.225 115.696C344.261 115.82 343.381 116.247 342.252 116.655C341.124 117.064 340.173 117.304 340.119 117.179C340.066 117.064 340.955 116.629 342.092 116.22C343.23 115.802 344.181 115.571 344.225 115.696Z" fill="#263238"/>
<path d="M352.721 113.715C352.739 113.839 351.797 114.097 350.615 114.301C349.442 114.55 348.465 114.648 348.438 114.523C348.411 114.399 349.345 114.097 350.535 113.848C351.726 113.644 352.704 113.591 352.721 113.715Z" fill="#263238"/>
<path d="M361.438 113.235C361.429 113.359 360.46 113.475 359.26 113.493C358.061 113.51 357.083 113.413 357.074 113.288C357.065 113.164 358.043 113.048 359.252 113.031C360.469 113.013 361.447 113.102 361.438 113.235Z" fill="#263238"/>
<path d="M370.104 114.257C370.077 114.382 369.091 114.328 367.909 114.142C366.727 113.946 365.776 113.689 365.785 113.564C365.803 113.44 366.781 113.493 367.98 113.68C369.18 113.875 370.131 114.133 370.104 114.257Z" fill="#263238"/>
<path d="M378.473 116.753C378.429 116.868 377.469 116.646 376.332 116.255C375.194 115.865 374.305 115.447 374.341 115.323C374.376 115.198 375.336 115.42 376.483 115.82C377.629 116.211 378.518 116.629 378.473 116.753Z" fill="#263238"/>
<path d="M386.246 120.716C386.175 120.822 385.278 120.432 384.238 119.836C383.198 119.241 382.398 118.664 382.452 118.557C382.505 118.442 383.412 118.841 384.46 119.437C385.518 120.032 386.317 120.609 386.246 120.716Z" fill="#263238"/>
<path d="M393.09 126.116C393.001 126.205 392.193 125.636 391.295 124.846C390.397 124.055 389.731 123.335 389.802 123.229C389.882 123.131 390.682 123.691 391.597 124.499C392.512 125.299 393.179 126.027 393.09 126.116Z" fill="#263238"/>
<path d="M397.41 131.091C397.303 131.162 396.921 130.834 396.548 130.354C396.175 129.874 395.953 129.412 396.05 129.332C396.148 129.252 396.539 129.581 396.912 130.07C397.295 130.558 397.517 131.011 397.41 131.091Z" fill="#263238"/>
<path d="M438.498 184.617C438.445 184.733 437.503 184.413 436.383 183.889C435.263 183.374 434.41 182.858 434.463 182.743C434.517 182.627 435.468 182.956 436.578 183.471C437.689 183.987 438.551 184.502 438.498 184.617Z" fill="#263238"/>
<path d="M446.886 187.531C446.851 187.655 445.873 187.46 444.691 187.104C443.509 186.74 442.594 186.358 442.638 186.234C442.683 186.109 443.66 186.305 444.833 186.66C445.997 187.025 446.922 187.407 446.886 187.531Z" fill="#263238"/>
<path d="M455.659 188.935C455.65 189.06 454.655 189.06 453.428 188.926C452.202 188.793 451.225 188.58 451.242 188.455C451.26 188.331 452.264 188.331 453.473 188.464C454.681 188.598 455.659 188.802 455.659 188.935Z" fill="#263238"/>
<path d="M464.527 188.659C464.545 188.783 463.559 188.961 462.332 189.059C461.106 189.148 460.102 189.121 460.102 188.997C460.102 188.872 461.079 188.695 462.297 188.597C463.514 188.499 464.509 188.526 464.527 188.659Z" fill="#263238"/>
<path d="M473.255 187.025C473.291 187.149 472.349 187.487 471.149 187.78C469.94 188.038 468.945 188.118 468.919 187.993C468.892 187.86 469.852 187.585 471.042 187.327C472.233 187.034 473.22 186.901 473.255 187.025Z" fill="#263238"/>
<path d="M481.501 183.747C481.563 183.863 480.701 184.369 479.582 184.884C478.462 185.399 477.511 185.719 477.466 185.604C477.422 185.488 478.284 184.973 479.386 184.467C480.497 183.96 481.439 183.64 481.501 183.747Z" fill="#263238"/>
<path d="M488.871 178.799C488.951 178.897 488.258 179.625 487.271 180.371C486.285 181.118 485.387 181.58 485.325 181.473C485.254 181.366 486.018 180.736 486.996 179.998C487.964 179.27 488.782 178.701 488.871 178.799Z" fill="#263238"/>
<path d="M494.914 172.313C495.021 172.384 494.523 173.264 493.732 174.214C492.942 175.165 492.177 175.822 492.088 175.734C491.991 175.645 492.604 174.854 493.377 173.921C494.15 172.988 494.808 172.242 494.914 172.313Z" fill="#263238"/>
<path d="M499.068 164.495C499.183 164.539 498.952 165.516 498.446 166.645C497.939 167.773 497.37 168.608 497.255 168.546C497.139 168.484 497.53 167.569 498.019 166.458C498.526 165.356 498.943 164.45 499.068 164.495Z" fill="#263238"/>
<path d="M500.714 155.807C500.838 155.807 500.945 156.811 500.812 158.045C500.687 159.28 500.376 160.249 500.261 160.222C500.136 160.195 500.234 159.209 500.358 158.001C500.474 156.793 500.581 155.807 500.714 155.807Z" fill="#263238"/>
<path d="M499.436 147.047C499.551 147.011 499.96 147.935 500.236 149.143C500.511 150.352 500.547 151.364 500.422 151.382C500.298 151.4 500.058 150.432 499.782 149.25C499.516 148.06 499.311 147.091 499.436 147.047Z" fill="#263238"/>
<path d="M495.569 139.087C495.667 139.016 496.343 139.771 496.965 140.846C497.587 141.921 497.924 142.871 497.809 142.924C497.693 142.978 497.178 142.134 496.565 141.077C495.952 140.019 495.463 139.158 495.569 139.087Z" fill="#263238"/>
<path d="M489.403 132.744C489.474 132.637 490.363 133.135 491.296 133.943C492.238 134.752 492.851 135.56 492.753 135.649C492.656 135.738 491.909 135.089 490.994 134.298C490.079 133.499 489.332 132.85 489.403 132.744Z" fill="#263238"/>
<path d="M481.344 129.138C481.371 129.013 482.384 129.111 483.566 129.502C484.748 129.893 485.619 130.417 485.565 130.524C485.512 130.639 484.57 130.319 483.424 129.937C482.268 129.555 481.318 129.271 481.344 129.138Z" fill="#263238"/>
<path d="M472.536 129.287C472.501 129.172 473.451 128.799 474.687 128.648C475.922 128.488 476.935 128.586 476.935 128.71C476.935 128.843 475.949 128.941 474.749 129.101C473.549 129.252 472.572 129.412 472.536 129.287Z" fill="#263238"/>
<path d="M464.876 133.57C464.787 133.49 465.391 132.664 466.395 131.909C467.391 131.154 468.342 130.78 468.404 130.887C468.466 131.003 467.64 131.544 466.68 132.273C465.711 133.001 464.974 133.659 464.876 133.57Z" fill="#263238"/>
<path d="M461.358 141.548C461.233 141.539 461.189 140.518 461.482 139.301C461.775 138.083 462.3 137.195 462.415 137.248C462.54 137.302 462.211 138.235 461.935 139.407C461.651 140.58 461.491 141.557 461.358 141.548Z" fill="#263238"/>
<path d="M463.088 150.157C462.981 150.219 462.412 149.366 462.004 148.184C461.595 147.003 461.515 145.99 461.63 145.963C461.764 145.937 462.039 146.887 462.439 148.033C462.839 149.179 463.212 150.094 463.088 150.157Z" fill="#263238"/>
<path d="M468.903 156.767C468.832 156.873 467.935 156.394 467.011 155.567C466.078 154.741 465.491 153.906 465.589 153.826C465.686 153.737 466.406 154.421 467.322 155.221C468.219 156.02 468.975 156.66 468.903 156.767Z" fill="#263238"/>
<path d="M476.97 160.382C476.944 160.506 475.939 160.373 474.766 159.973C473.593 159.573 472.722 159.058 472.776 158.942C472.829 158.827 473.771 159.138 474.917 159.529C476.064 159.92 476.997 160.248 476.97 160.382Z" fill="#263238"/>
<path d="M485.786 161.216C485.795 161.341 484.8 161.51 483.565 161.474C482.329 161.447 481.334 161.243 481.352 161.119C481.361 160.985 482.356 160.985 483.574 161.012C484.782 161.039 485.777 161.083 485.786 161.216Z" fill="#263238"/>
<path d="M494.493 159.6C494.537 159.716 493.613 160.133 492.414 160.444C491.214 160.755 490.21 160.826 490.192 160.702C490.165 160.577 491.125 160.302 492.307 160C493.48 159.698 494.449 159.485 494.493 159.6Z" fill="#263238"/>
<path d="M502.555 155.914C502.626 156.02 501.791 156.58 500.698 157.157C499.605 157.735 498.681 158.108 498.627 157.992C498.574 157.877 499.409 157.317 500.485 156.749C501.56 156.18 502.484 155.807 502.555 155.914Z" fill="#263238"/>
<path d="M509.671 150.619C509.76 150.717 509.058 151.427 508.098 152.209C507.147 152.991 506.311 153.542 506.232 153.435C506.16 153.328 506.862 152.618 507.804 151.845C508.755 151.081 509.591 150.53 509.671 150.619Z" fill="#263238"/>
<path d="M515.726 144.124C515.823 144.204 515.263 145.021 514.455 145.963C513.655 146.905 512.926 147.589 512.837 147.5C512.749 147.411 513.308 146.585 514.108 145.661C514.908 144.737 515.628 144.053 515.726 144.124Z" fill="#263238"/>
<path d="M520.613 136.715C520.728 136.777 520.302 137.684 519.671 138.741C519.031 139.798 518.435 140.598 518.329 140.526C518.222 140.455 518.649 139.549 519.271 138.501C519.902 137.453 520.506 136.653 520.613 136.715Z" fill="#263238"/>
<path d="M524.225 128.595C524.35 128.639 524.083 129.599 523.63 130.745C523.185 131.9 522.705 132.779 522.581 132.717C522.474 132.672 522.759 131.713 523.194 130.576C523.639 129.448 524.101 128.559 524.225 128.595Z" fill="#263238"/>
<path d="M526.698 120.076C526.823 120.102 526.672 121.089 526.361 122.288C526.05 123.478 525.703 124.42 525.579 124.376C525.454 124.34 525.605 123.354 525.916 122.163C526.227 120.982 526.574 120.049 526.698 120.076Z" fill="#263238"/>
<path d="M528.257 111.334C528.382 111.352 528.337 112.347 528.151 113.564C527.964 114.781 527.715 115.75 527.582 115.723C527.457 115.696 527.502 114.701 527.689 113.493C527.884 112.285 528.133 111.316 528.257 111.334Z" fill="#263238"/>
<path d="M529.073 102.485C529.198 102.494 529.233 103.489 529.136 104.715C529.047 105.941 528.869 106.927 528.736 106.91C528.611 106.901 528.576 105.897 528.673 104.68C528.771 103.463 528.949 102.477 529.073 102.485Z" fill="#263238"/>
<path d="M529.358 93.6104C529.482 93.6104 529.571 94.6054 529.544 95.8313C529.517 97.0573 529.393 98.0523 529.269 98.0434C529.145 98.0434 529.056 97.0395 529.083 95.8224C529.109 94.6053 529.234 93.6104 529.358 93.6104Z" fill="#263238"/>
<path d="M529.295 84.7266C529.419 84.7266 529.544 85.7127 529.561 86.9475C529.579 88.1735 529.49 89.1685 529.366 89.1685C529.242 89.1685 529.118 88.1735 529.1 86.9475C529.082 85.7216 529.171 84.7266 529.295 84.7266Z" fill="#263238"/>
<path d="M529.159 79.2627C529.283 79.2627 529.398 79.4848 529.407 79.7691C529.416 80.0534 529.319 80.2843 529.194 80.2843C529.07 80.2843 528.955 80.0622 528.946 79.778C528.937 79.4937 529.035 79.2627 529.159 79.2627Z" fill="#263238"/>
<path d="M548.664 52.8884L488.258 59.4482L492.681 100.157L553.087 93.5969L548.664 52.8884Z" fill="#939DF4"/>
<path d="M492.629 100.194V100.176L488.203 59.4518H488.221L548.65 52.8955V52.9133L553.076 93.6372H553.058L492.629 100.194ZM488.256 59.4874L492.673 100.149L553.031 93.5928L548.615 52.931L488.256 59.4874Z" fill="#263238"/>
<path d="M548.876 53.3753C548.894 53.3842 548.716 53.5974 548.37 53.9794C547.988 54.397 547.481 54.9478 546.85 55.6318C545.473 57.0977 543.544 59.141 541.172 61.6551C536.302 66.7456 529.637 73.7195 522.252 81.4396L522.092 81.5995L521.905 81.4752C521.763 81.3863 521.603 81.2797 521.443 81.182C512.334 75.363 504.088 70.0949 498.062 66.2481C495.121 64.3381 492.73 62.7834 491.015 61.6729C490.224 61.1488 489.593 60.7223 489.114 60.4025C488.678 60.1093 488.456 59.9405 488.474 59.9317C488.483 59.9139 488.722 60.0471 489.176 60.3137C489.664 60.6068 490.322 61.0066 491.131 61.4952C492.873 62.5702 495.299 64.0804 498.276 65.9283C504.327 69.7395 512.601 74.9633 521.745 80.7289C521.905 80.8266 522.065 80.9332 522.207 81.0221L521.861 81.0665C529.29 73.3908 536 66.4613 540.896 61.3975C543.313 58.9367 545.286 56.9289 546.699 55.4897C547.366 54.8323 547.89 54.3081 548.299 53.9084C548.663 53.5441 548.859 53.3664 548.876 53.3753Z" fill="#263238"/>
<path d="M492.897 100.637C492.773 100.548 496.71 94.4804 501.686 87.0712C506.672 79.662 510.813 73.7276 510.937 73.8164C511.062 73.9053 507.125 79.973 502.148 87.3822C497.163 94.7914 493.022 100.726 492.897 100.637Z" fill="#263238"/>
<path d="M530.959 71.1335C531.065 71.0269 536.157 76.0818 542.325 82.4161C548.492 88.7503 553.407 93.9741 553.3 94.0807C553.193 94.1873 548.101 89.1323 541.934 82.7981C535.766 76.4638 530.852 71.2401 530.959 71.1335Z" fill="#263238"/>
<path d="M272.795 176.318C272.919 176.3 273.106 176.771 273.212 177.375C273.319 177.979 273.292 178.486 273.168 178.512C273.043 178.53 272.857 178.059 272.75 177.455C272.652 176.842 272.67 176.336 272.795 176.318Z" fill="#263238"/>
<path d="M271.372 167.648C271.496 167.63 271.754 168.581 271.949 169.78C272.145 170.979 272.198 171.965 272.074 171.983C271.949 172.001 271.692 171.05 271.496 169.851C271.301 168.652 271.247 167.665 271.372 167.648Z" fill="#263238"/>
<path d="M270.098 158.959C270.223 158.941 270.463 159.901 270.631 161.1C270.8 162.3 270.836 163.286 270.703 163.303C270.569 163.321 270.338 162.362 270.169 161.162C270.009 159.963 269.974 158.977 270.098 158.959Z" fill="#263238"/>
<path d="M269.108 150.235C269.233 150.227 269.437 151.195 269.57 152.394C269.695 153.602 269.695 154.589 269.57 154.606C269.446 154.624 269.242 153.656 269.108 152.447C268.975 151.23 268.984 150.244 269.108 150.235Z" fill="#263238"/>
<path d="M268.494 141.467C268.618 141.458 268.778 142.435 268.849 143.643C268.92 144.852 268.866 145.838 268.742 145.847C268.618 145.856 268.458 144.878 268.387 143.67C268.316 142.462 268.37 141.476 268.494 141.467Z" fill="#263238"/>
<path d="M268.404 132.681C268.528 132.681 268.625 133.667 268.625 134.884C268.616 136.092 268.51 137.078 268.386 137.078C268.262 137.078 268.164 136.092 268.164 134.875C268.164 133.667 268.27 132.681 268.404 132.681Z" fill="#263238"/>
<path d="M268.956 123.921C269.08 123.939 269.107 124.925 269 126.124C268.902 127.332 268.716 128.301 268.591 128.292C268.467 128.283 268.44 127.297 268.547 126.089C268.645 124.88 268.822 123.912 268.956 123.921Z" fill="#263238"/>
<path d="M270.271 115.242C270.395 115.268 270.324 116.255 270.12 117.445C269.907 118.635 269.64 119.586 269.507 119.568C269.382 119.55 269.454 118.564 269.658 117.365C269.88 116.166 270.147 115.215 270.271 115.242Z" fill="#263238"/>
<path d="M272.518 106.757C272.642 106.802 272.456 107.77 272.118 108.925C271.772 110.08 271.389 110.995 271.274 110.959C271.15 110.924 271.327 109.956 271.683 108.792C272.012 107.628 272.394 106.713 272.518 106.757Z" fill="#263238"/>
<path d="M275.801 98.6107C275.916 98.664 275.614 99.6057 275.125 100.716C274.637 101.818 274.148 102.68 274.032 102.635C273.917 102.591 274.219 101.649 274.708 100.53C275.197 99.4192 275.685 98.5574 275.801 98.6107Z" fill="#263238"/>
<path d="M280.082 90.9527C280.189 91.0238 279.771 91.921 279.149 92.9516C278.527 93.9821 277.932 94.7728 277.816 94.7106C277.701 94.6484 278.118 93.7511 278.749 92.7117C279.38 91.6723 279.976 90.8816 280.082 90.9527Z" fill="#263238"/>
<path d="M285.354 83.9261C285.452 84.0061 284.91 84.8412 284.154 85.7829C283.399 86.7246 282.697 87.4264 282.599 87.3464C282.493 87.2665 283.035 86.4403 283.799 85.4897C284.563 84.548 285.265 83.8462 285.354 83.9261Z" fill="#263238"/>
<path d="M291.599 77.7518C291.679 77.8496 291.022 78.5869 290.133 79.4043C289.244 80.2216 288.445 80.8079 288.356 80.7191C288.267 80.6302 288.924 79.8929 289.822 79.0667C290.72 78.2405 291.519 77.6541 291.599 77.7518Z" fill="#263238"/>
<path d="M298.78 72.7053C298.842 72.8207 298.069 73.4337 297.056 74.0823C296.042 74.7308 295.154 75.175 295.083 75.0684C295.012 74.9618 295.785 74.3488 296.807 73.6914C297.829 73.0428 298.717 72.5987 298.78 72.7053Z" fill="#263238"/>
<path d="M306.775 69.1077C306.81 69.2321 305.939 69.6852 304.811 70.1205C303.691 70.5558 302.731 70.8224 302.678 70.7069C302.625 70.5914 303.505 70.1383 304.642 69.6941C305.78 69.2499 306.739 68.9834 306.775 69.1077Z" fill="#263238"/>
<path d="M313.194 67.5259C313.212 67.6502 312.75 67.8457 312.145 67.9612C311.55 68.0767 311.043 68.0678 311.017 67.9434C310.99 67.819 311.461 67.6236 312.065 67.5081C312.67 67.3926 313.176 67.4015 313.194 67.5259Z" fill="#263238"/>
<path d="M265.937 34.1711L249.875 75.6924L311.493 99.5141L327.555 57.9928L265.937 34.1711Z" fill="#939DF4"/>
<path d="M311.492 99.5439L311.474 99.5351L249.836 75.7084L249.845 75.6906L265.912 34.1494L265.93 34.1583L327.568 57.985L327.559 58.0027L311.492 99.5439ZM249.889 75.6817L311.465 99.4818L327.506 58.0027L265.93 34.2027L249.889 75.6817Z" fill="#263238"/>
<path d="M327.543 58.5711C327.551 58.5889 327.276 58.7044 326.752 58.8998C326.174 59.1131 325.41 59.3885 324.459 59.7349C322.397 60.4634 319.509 61.4762 315.972 62.7199C308.729 65.2074 298.803 68.61 287.819 72.3768L287.588 72.4567L287.464 72.2435C287.366 72.0836 287.268 71.9059 287.162 71.7282C281.27 61.565 275.938 52.3613 272.036 45.6362C270.152 42.3225 268.615 39.6217 267.513 37.6939C267.015 36.7878 266.616 36.0682 266.313 35.5174C266.047 35.0199 265.913 34.7533 265.931 34.7356C265.949 34.7267 266.118 34.9754 266.42 35.4552C266.749 35.9882 267.184 36.69 267.717 37.5696C268.855 39.4796 270.455 42.1448 272.41 45.4141C276.346 52.1214 281.732 61.2896 287.686 71.4173C287.792 71.595 287.89 71.7727 287.988 71.9326L287.633 71.7993C298.643 68.0947 308.578 64.7544 315.848 62.3113C319.411 61.1386 322.317 60.188 324.397 59.504C325.365 59.2019 326.139 58.9532 326.734 58.7666C327.258 58.6244 327.543 58.5445 327.543 58.5711Z" fill="#263238"/>
<path d="M249.86 76.2679C249.78 76.1258 256.614 72.2257 265.127 67.5528C273.641 62.8887 280.599 59.2197 280.679 59.3707C280.759 59.5129 273.925 63.4129 265.412 68.0858C256.907 72.7499 249.94 76.4101 249.86 76.2679Z" fill="#263238"/>
<path d="M301.372 66.7717C301.532 66.7273 303.922 74.1453 306.713 83.3402C309.503 92.5439 311.645 100.042 311.485 100.086C311.325 100.131 308.934 92.7127 306.135 83.5179C303.345 74.3141 301.212 66.8161 301.372 66.7717Z" fill="#263238"/>
<path d="M246.912 186.038C247.036 186.047 247.098 186.553 247.054 187.166C247.001 187.779 246.859 188.268 246.734 188.259C246.61 188.25 246.548 187.744 246.592 187.131C246.637 186.518 246.788 186.029 246.912 186.038Z" fill="#263238"/>
<path d="M247.135 177.004C247.259 177.004 247.365 178.017 247.356 179.26C247.356 180.513 247.25 181.526 247.117 181.517C246.993 181.517 246.886 180.504 246.895 179.26C246.904 178.026 247.01 177.013 247.135 177.004Z" fill="#263238"/>
<path d="M246.518 167.987C246.643 167.969 246.838 168.973 246.954 170.216C247.069 171.46 247.061 172.482 246.927 172.491C246.803 172.5 246.607 171.505 246.492 170.261C246.385 169.026 246.394 168.004 246.518 167.987Z" fill="#263238"/>
<path d="M245.017 159.076C245.141 159.049 245.435 160.017 245.675 161.252C245.915 162.478 246.012 163.491 245.879 163.518C245.755 163.535 245.452 162.567 245.221 161.341C244.982 160.124 244.884 159.111 245.017 159.076Z" fill="#263238"/>
<path d="M242.557 150.378C242.681 150.334 243.081 151.267 243.454 152.466C243.827 153.666 244.032 154.661 243.907 154.696C243.783 154.732 243.383 153.799 243.01 152.608C242.636 151.418 242.441 150.423 242.557 150.378Z" fill="#263238"/>
<path d="M239.098 142.036C239.213 141.983 239.72 142.863 240.235 144C240.751 145.146 241.071 146.105 240.955 146.159C240.84 146.203 240.324 145.324 239.818 144.195C239.302 143.058 238.982 142.09 239.098 142.036Z" fill="#263238"/>
<path d="M234.609 134.192C234.716 134.121 235.356 134.92 236.022 135.978C236.662 137.053 237.088 137.976 236.982 138.048C236.866 138.11 236.262 137.292 235.631 136.226C234.965 135.169 234.502 134.272 234.609 134.192Z" fill="#263238"/>
<path d="M229.073 127.058C229.171 126.969 229.9 127.68 230.7 128.648C231.499 129.608 232.068 130.452 231.97 130.532C231.873 130.612 231.144 129.901 230.344 128.942C229.544 127.982 228.976 127.138 229.073 127.058Z" fill="#263238"/>
<path d="M222.59 120.768C222.67 120.67 223.496 121.275 224.42 122.11C225.354 122.954 226.038 123.7 225.94 123.798C225.851 123.886 225.034 123.291 224.109 122.456C223.185 121.621 222.501 120.866 222.59 120.768Z" fill="#263238"/>
<path d="M215.254 115.492C215.317 115.385 216.223 115.856 217.263 116.549C218.303 117.242 219.093 117.89 219.013 117.997C218.942 118.103 218.036 117.624 217.005 116.94C215.974 116.247 215.183 115.598 215.254 115.492Z" fill="#263238"/>
<path d="M207.205 111.386C207.25 111.271 208.219 111.6 209.356 112.124C210.494 112.648 211.373 113.163 211.311 113.279C211.249 113.394 210.289 113.065 209.16 112.541C208.032 112.017 207.152 111.502 207.205 111.386Z" fill="#263238"/>
<path d="M198.583 108.659C198.609 108.535 199.613 108.695 200.822 109.024C202.031 109.352 202.981 109.716 202.946 109.832C202.91 109.956 201.906 109.788 200.706 109.468C199.507 109.148 198.556 108.784 198.583 108.659Z" fill="#263238"/>
<path d="M189.625 107.513C189.625 107.389 190.647 107.336 191.9 107.407C193.153 107.54 194.148 107.744 194.131 107.869C194.113 107.993 193.1 107.993 191.864 107.86C190.629 107.798 189.625 107.647 189.625 107.513Z" fill="#263238"/>
<path d="M180.614 108.136C180.596 108.011 181.582 107.789 182.835 107.638C184.079 107.487 185.101 107.478 185.11 107.603C185.119 107.727 184.124 107.949 182.889 108.1C181.653 108.242 180.64 108.26 180.614 108.136Z" fill="#263238"/>
<path d="M171.888 110.462C171.844 110.347 172.777 109.938 173.977 109.556C175.176 109.174 176.172 108.97 176.198 109.094C176.234 109.218 175.301 109.618 174.11 110C172.928 110.373 171.933 110.578 171.888 110.462Z" fill="#263238"/>
<path d="M163.746 114.38C163.675 114.274 164.52 113.696 165.621 113.101C166.723 112.506 167.665 112.115 167.719 112.23C167.772 112.346 166.937 112.923 165.844 113.509C164.759 114.096 163.817 114.487 163.746 114.38Z" fill="#263238"/>
<path d="M156.531 119.8C156.442 119.711 157.153 118.982 158.122 118.174C159.09 117.365 159.935 116.797 160.006 116.903C160.086 117.01 159.375 117.73 158.415 118.529C157.464 119.329 156.62 119.897 156.531 119.8Z" fill="#263238"/>
<path d="M150.62 126.623C150.513 126.552 151.002 125.645 151.766 124.65C152.54 123.655 153.286 122.962 153.384 123.042C153.482 123.131 152.895 123.949 152.131 124.935C151.375 125.912 150.727 126.694 150.62 126.623Z" fill="#263238"/>
<path d="M147.324 132.486C147.209 132.432 147.333 131.944 147.6 131.384C147.867 130.824 148.178 130.425 148.293 130.487C148.409 130.549 148.284 131.038 148.018 131.588C147.751 132.13 147.44 132.539 147.324 132.486Z" fill="#263238"/>
<path d="M90.8595 184.91C90.8506 184.786 91.3305 184.617 91.9348 184.546C92.5391 184.475 93.0456 184.519 93.0634 184.643C93.0812 184.768 92.6013 184.928 91.9881 185.008C91.3749 185.088 90.8684 185.043 90.8595 184.91Z" fill="#263238"/>
<path d="M82.0601 183.916C82.0957 183.792 83.0732 183.996 84.264 184.245C85.4548 184.494 86.4235 184.698 86.4146 184.831C86.4057 184.956 85.3837 184.956 84.1663 184.698C82.9488 184.441 82.0157 184.032 82.0601 183.916Z" fill="#263238"/>
<path d="M74.1022 179.98C74.1733 179.874 75.0442 180.362 76.0929 180.993C77.1415 181.624 77.9857 182.148 77.9235 182.264C77.8702 182.379 76.9193 182.033 75.8529 181.393C74.7954 180.753 74.0311 180.087 74.1022 179.98Z" fill="#263238"/>
<path d="M67.4833 174.046C67.581 173.957 68.3098 174.641 69.1718 175.512C70.0338 176.382 70.7003 177.12 70.6203 177.217C70.5403 177.315 69.7138 176.72 68.8429 175.84C67.972 174.961 67.3855 174.135 67.4833 174.046Z" fill="#263238"/>
<path d="M62.2655 166.849C62.3722 166.787 63.012 167.56 63.6785 168.582C64.345 169.603 64.8071 170.492 64.7094 170.572C64.6027 170.652 63.9718 169.87 63.2964 168.839C62.6121 167.809 62.1589 166.911 62.2655 166.849Z" fill="#263238"/>
<path d="M58.4236 158.828C58.5391 158.783 59.0279 159.663 59.5078 160.782C59.9876 161.911 60.2809 162.861 60.1654 162.923C60.0499 162.977 59.5611 162.106 59.0812 160.969C58.6013 159.832 58.3081 158.872 58.4236 158.828Z" fill="#263238"/>
<path d="M55.8717 150.299C55.9961 150.273 56.3516 151.206 56.6626 152.396C56.9736 153.578 57.1247 154.573 57.0003 154.608C56.8759 154.644 56.5204 153.711 56.2094 152.511C55.8984 151.321 55.7473 150.326 55.8717 150.299Z" fill="#263238"/>
<path d="M54.324 141.531C54.4485 141.513 54.7062 142.481 54.8839 143.698C55.0705 144.915 55.115 145.91 54.9906 145.937C54.8661 145.964 54.6173 144.986 54.4307 143.769C54.2441 142.552 54.1907 141.548 54.324 141.531Z" fill="#263238"/>
<path d="M53.4708 132.673C53.5952 132.664 53.7819 133.65 53.8796 134.876C53.9774 136.102 53.9596 137.106 53.8263 137.115C53.7019 137.124 53.5153 136.147 53.4175 134.912C53.3198 133.677 53.3375 132.682 53.4708 132.673Z" fill="#263238"/>
<path d="M53.0966 126.001C53.2208 125.992 53.3539 126.49 53.3805 127.103C53.4071 127.716 53.3272 128.213 53.2031 128.222C53.0789 128.231 52.9458 127.733 52.9192 127.12C52.8926 126.507 52.9724 126.01 53.0966 126.001Z" fill="#263238"/>
<path d="M86.5629 72.1308L12.6719 75.6201L15.0162 125.413L88.9072 121.924L86.5629 72.1308Z" fill="#939DF4"/>
<path d="M14.9945 125.433V125.406L12.6484 75.5938H12.6751L86.5945 72.1113V72.138L88.9406 121.95H88.9139L14.9945 125.433ZM12.6929 75.6382L15.039 125.388L88.8873 121.906L86.5412 72.1557L12.6929 75.6382Z" fill="#263238"/>
<path d="M86.8272 72.7247C86.845 72.7425 86.6139 72.9823 86.1696 73.4176C85.6719 73.8974 85.0143 74.5281 84.1967 75.3188C82.4105 76.9979 79.9134 79.3432 76.8563 82.2216C70.5557 88.0495 61.9445 96.0184 52.3913 104.858L52.1869 105.044L51.9647 104.885C51.7959 104.76 51.6093 104.627 51.4227 104.494C40.7853 96.7468 31.161 89.7374 24.1228 84.6114C20.6925 82.0706 17.8932 80.0006 15.8937 78.5259C14.9695 77.8241 14.2319 77.2644 13.6809 76.8468C13.1744 76.4559 12.9167 76.2427 12.9345 76.225C12.9522 76.2072 13.2277 76.3849 13.7609 76.7402C14.3386 77.14 15.1028 77.6642 16.0537 78.3216C18.0887 79.7608 20.9236 81.7685 24.4072 84.2383C31.4721 89.3199 41.1408 96.276 51.8226 103.961C52.0092 104.094 52.1958 104.227 52.3646 104.351L51.9381 104.378C61.5357 95.6008 70.2002 87.6852 76.5364 81.8929C79.6556 79.0767 82.2061 76.7935 84.0279 75.15C84.881 74.3949 85.5653 73.7997 86.0807 73.3466C86.5517 72.9113 86.8094 72.7069 86.8272 72.7247Z" fill="#263238"/>
<path d="M15.2805 126.001C15.1295 125.885 20.3637 118.805 26.9754 110.17C33.5782 101.544 39.0524 94.6407 39.2035 94.7473C39.3545 94.8628 34.1203 101.943 27.5086 110.578C20.9058 119.205 15.4316 126.108 15.2805 126.001Z" fill="#263238"/>
<path d="M63.7292 92.9705C63.8714 92.8461 69.6833 99.3669 76.7038 107.522C83.7331 115.687 89.314 122.394 89.1718 122.518C89.0296 122.643 83.2266 116.122 76.1972 107.967C69.1679 99.8023 63.587 93.0949 63.7292 92.9705Z" fill="#263238"/>
</g>
</g>
<defs>
<clipPath id="clip0_3269_12162">
<rect width="553" height="372" fill="white"/>
</clipPath>
<clipPath id="clip1_3269_12162">
<rect width="553" height="371" fill="white" transform="translate(0 -7)"/>
</clipPath>
</defs>
</svg>

);

export default IconLetterSmall;
