import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ImageList, ImageListItem } from "@mui/material";
import { SimpleButton } from "components/Controls/Buttons";
import { formatPhotoURL } from "utils/helpers/housesHelper/formatter";
import "./styles.scss";

const Photos = ({ houseDetails, onAllPhotosButtonClick }) => {
  const photosNumber = houseDetails.photos.length;

  const getPhotosData = () => {
    // Calculates photos placement on the photo collage according to the number of photos.
    // Example: if the photosNumber is 1, then the photo will be 100% of the collage width instead of 60%
    const photos = []
    if (_.has(houseDetails, "photos") && _.isArray(houseDetails.photos)) {
      houseDetails.photos.slice(0, 5).forEach((item, index) => {
        let photoData = { source: formatPhotoURL(item.href), title: `house photo ${index}` };
        if (index === 0) {
          photoData = { columns: photosNumber > 1 ? 3 : 5, rows: 2, ...photoData };
        }
        if (photosNumber < 4) {
          if (index === 1) {
            photoData = { columns: 2, rows: photosNumber === 2 ? 2 : 1, ...photoData };
          }
          if (index === 2) {
            photoData = { columns: 2, rows: 1, ...photoData };
          }
        }
        photos.push(photoData);
      });
    }
    return photos;
  };

  const renderHousePhotos = () => {
    const photosData = getPhotosData();
    return (
      <ImageList
        className="image-list"
        cols={5}
        gap={16}
        rowHeight={172}
        variant="quilted"
      >
        {photosData.map(item => (
          <ImageListItem
            className="image-list-item"
            key={item.img}
            cols={item.columns || 1}
            rows={item.rows || 1}
            onClick={onAllPhotosButtonClick}
          >
            <img
              className="house-detail-image"
              src={item.source}
              alt={item.title}
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  if (!_.has(houseDetails, "photos") ||
      (_.isArray(houseDetails.photos) && photosNumber === 0)) {
    return null;
  }

  return (
    <div className="photos-container">
      {renderHousePhotos()}
      <SimpleButton customClass="all-photos-btn" onClick={onAllPhotosButtonClick}>
        <FormattedMessage id="houseDetails.viewAllPhotos" />
      </SimpleButton>
    </div>
  );
};

Photos.propTypes = {
  houseDetails: PropTypes.object,
  onAllPhotosButtonClick: PropTypes.func,
};

export default Photos;
