import {FormattedMessage} from "react-intl";
import React from "react";

const HOUSES_PER_PAGE = 25;

const HOUSE_VIEWS = {
  topMatches: "topMatches",
  favorites: "favorites",
  recently: "recently",
};

const LISTING_STATUS_MESSAGES_MAPPINGS = {
  active: "houses.activeMessage",
  off_market: "houses.offMarketMessage",
  sold: "houses.soldMessage",
  under_contract: "houses.underContractMessage"
};

const SORT_OPTIONS = [
  { value: '0', label: <FormattedMessage id="houses.map.RecentlyAddedDesc" /> , name: 'external_created_at', order: 'desc', selectItem: 'Recently added'},
  { value: '1', label: <FormattedMessage id="houses.map.RecentlyAddedAsc" /> , name: 'external_created_at', order: 'asc', selectItem: 'Recently added'},

  { value: '2', label: <FormattedMessage id="houses.map.PriceDesc" /> , name: 'price', order: 'desc', selectItem: 'Price'},
  { value: '3', label: <FormattedMessage id="houses.map.PriceAsc" /> , name: 'price', order: 'asc', selectItem: 'Price'},

  { value: '4', label: <FormattedMessage id="houses.map.BedroomsDesc" />, name: 'bedrooms', order: 'desc', selectItem: 'Bedrooms'},
  { value: '5', label: <FormattedMessage id="houses.map.BedroomsAsc" /> , name: 'bedrooms', order: 'asc', selectItem: 'Bedrooms'},

  { value: '6', label: <FormattedMessage id="houses.map.BathroomsDesc" />, name: 'bathrooms', order: 'desc', selectItem: 'Bathrooms' },
  { value: '7', label: <FormattedMessage id="houses.map.BathroomsAsc" />, name: 'bathrooms', order: 'asc', selectItem: 'Bathrooms'},
];

export { HOUSE_VIEWS, HOUSES_PER_PAGE, LISTING_STATUS_MESSAGES_MAPPINGS, SORT_OPTIONS };
