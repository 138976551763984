import React from "react";

const IconWalkability = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1284_8246)">
      <path
        d="M13.2053 24.4726L15.2383 29.7909C15.3624 30.1156 15.6849 30.326 16.0318 30.3033C25.0037 29.7159 27.2988 25.6602 27.8464 23.1073C28.1228 21.8184 27.5305 20.47 26.3922 19.8052C21.0875 16.7068 14.9453 22.1129 13.3922 23.6181C13.1638 23.8394 13.0918 24.1755 13.2053 24.4726Z"
        fill="url(#paint0_linear_1284_8246)"
      />
      <path
        d="M13.7957 24.2447C13.7732 24.1842 13.7873 24.1172 13.8319 24.074C14.5374 23.3903 16.4294 21.679 18.7587 20.5573C21.5446 19.2155 24.0045 19.147 26.07 20.3534C26.55 20.6338 26.9233 21.0809 27.1212 21.6124C27.285 22.0521 27.3204 22.523 27.2236 22.9742C26.9923 24.0526 26.3667 25.6172 24.7003 26.946C22.7481 28.5028 19.817 29.4192 15.9881 29.6699C15.9211 29.6743 15.8541 29.6297 15.829 29.5642L13.7957 24.2447Z"
        fill="url(#paint1_linear_1284_8246)"
      />
      <path
        d="M10.7801 25.2928C10.699 25.0747 10.4796 24.9338 10.2478 24.9544C5.67421 25.3626 3.36633 27.5481 4.28496 30.0151C5.22965 32.5521 8.18158 32.2783 10.8636 31.2796C11.354 31.097 11.8171 30.9503 12.2477 30.8318C12.5398 30.7515 12.6957 30.437 12.59 30.1532L10.7801 25.2928Z"
        fill="url(#paint2_linear_1284_8246)"
      />
      <path
        d="M4.8817 29.7935C4.62195 29.0958 4.68058 28.4402 5.05602 27.8449C5.4127 27.2794 6.62733 25.9354 10.2171 25.5947L11.9526 30.2555C11.5151 30.3798 11.0755 30.5238 10.6439 30.6845C9.2197 31.2148 7.95626 31.4421 6.99026 31.3417C5.91783 31.2303 5.22808 30.7238 4.8817 29.7935Z"
        fill="url(#paint3_linear_1284_8246)"
      />
      <path
        d="M26.5338 24.8049L24.6484 19.7417C25.1407 19.8836 25.6145 20.0873 26.0692 20.3532C26.5494 20.6333 26.9227 21.0811 27.1205 21.612C27.2844 22.0523 27.3201 22.5233 27.223 22.9743C27.1106 23.4979 26.906 24.1355 26.5338 24.8049Z"
        fill="url(#paint4_linear_1284_8246)"
      />
      <path
        d="M25.3335 26.384L22.7695 19.4982C23.4235 19.4876 24.0507 19.569 24.6503 19.742L26.5357 24.8052C26.2473 25.3249 25.8581 25.8644 25.3335 26.384Z"
        fill="url(#paint5_linear_1284_8246)"
      />
      <path
        d="M23.9519 27.4808L21.0625 19.7212C21.6494 19.5805 22.2187 19.5066 22.7694 19.498L25.3334 26.3838C25.1412 26.5748 24.9308 26.7624 24.7011 26.946C24.4654 27.1338 24.216 27.3122 23.9519 27.4808Z"
        fill="url(#paint6_linear_1284_8246)"
      />
      <path
        d="M22.4558 28.2743L19.4648 20.2419C20.0102 20.0168 20.5424 19.8432 21.061 19.7212L23.9504 27.4808C23.4945 27.7733 22.996 28.0376 22.4558 28.2743Z"
        fill="url(#paint7_linear_1284_8246)"
      />
      <path
        d="M20.8796 28.8486L17.9492 20.9789C18.212 20.832 18.482 20.6908 18.7584 20.5574C18.996 20.4428 19.2318 20.3371 19.4648 20.2419L22.4558 28.2744C21.965 28.4893 21.4395 28.6808 20.8796 28.8486Z"
        fill="url(#paint8_linear_1284_8246)"
      />
      <path
        d="M20.8819 28.8486C20.3648 29.0039 19.8184 29.1396 19.2432 29.2548L16.5 21.8879C16.9489 21.5768 17.4355 21.2669 17.9516 20.979L20.8819 28.8486Z"
        fill="url(#paint9_linear_1284_8246)"
      />
      <path
        d="M19.2415 29.2546C18.7034 29.3627 18.1399 29.4523 17.5518 29.5239L15.1016 22.9438C15.5063 22.6118 15.9766 22.2488 16.4982 21.8877L19.2415 29.2546Z"
        fill="url(#paint10_linear_1284_8246)"
      />
      <path
        d="M17.5515 29.5241C17.048 29.5863 16.5267 29.6348 15.9878 29.6703C15.9207 29.6741 15.8542 29.6295 15.829 29.5643L13.7957 24.2446C13.7733 24.1843 13.7872 24.1173 13.8319 24.0736C14.0989 23.815 14.5355 23.4095 15.1013 22.9438L17.5515 29.5241Z"
        fill="url(#paint11_linear_1284_8246)"
      />
      <path
        d="M15.4785 12.9194L11.4875 8.85867C11.2439 8.61073 11.1866 8.22992 11.3515 7.92392C15.6171 0.00916529 20.26 -0.391272 22.8093 0.172541C24.0963 0.457228 25.0763 1.55673 25.2074 2.86829C25.8189 8.9811 18.3479 12.3185 16.3332 13.1048C16.037 13.2204 15.7014 13.1462 15.4785 12.9194Z"
        fill="url(#paint12_linear_1284_8246)"
      />
      <path
        d="M15.93 12.4755C15.9756 12.5211 16.0424 12.5361 16.1002 12.5135C17.0154 12.1563 19.3585 11.1474 21.3474 9.49559C23.7263 7.52015 24.8117 5.31165 24.5737 2.93146C24.5184 2.37828 24.267 1.85296 23.866 1.4519C23.5342 1.12009 23.1206 0.891962 22.6701 0.7924C21.5932 0.554212 19.9101 0.472337 18.0086 1.43515C15.781 2.5629 13.7285 4.8474 11.9081 8.22509C11.8763 8.28427 11.8889 8.36365 11.9381 8.41377L15.93 12.4755Z"
        fill="url(#paint13_linear_1284_8246)"
      />
      <path
        d="M13.7253 14.7818C13.8899 14.9463 13.927 15.2044 13.8117 15.4067C11.5383 19.3961 8.59085 20.5859 6.72941 18.7245C4.8151 16.8102 6.29191 14.2396 8.31553 12.2159C8.68553 11.8459 9.01147 11.4858 9.29835 11.1434C9.49291 10.9113 9.84372 10.9002 10.0579 11.1144L13.7253 14.7818Z"
        fill="url(#paint14_linear_1284_8246)"
      />
      <path
        d="M7.17519 18.2758C7.70157 18.8022 8.32226 19.0216 9.01976 18.9278C9.68244 18.8386 11.4099 18.293 13.2126 15.1701L9.69576 11.6533C9.40082 11.9996 9.08695 12.3394 8.76132 12.665C7.68676 13.7396 6.95457 14.7941 6.64413 15.7144C6.29951 16.736 6.47326 17.5739 7.17519 18.2758Z"
        fill="url(#paint15_linear_1284_8246)"
      />
      <path
        d="M20.7188 0.657825L24.5392 4.47827C24.6149 3.97158 24.6267 3.45595 24.5739 2.93189C24.519 2.37864 24.267 1.85295 23.8664 1.45233C23.5342 1.12014 23.1207 0.891825 22.6702 0.792512C22.1473 0.676887 21.4824 0.597762 20.7188 0.657825Z"
        fill="url(#paint16_linear_1284_8246)"
      />
      <path
        d="M18.7812 1.09421L23.9769 6.28984C24.2586 5.69959 24.4454 5.09528 24.5374 4.47815L20.7169 0.657715C20.1245 0.703715 19.472 0.83334 18.7812 1.09421Z"
        fill="url(#paint17_linear_1284_8246)"
      />
      <path
        d="M17.2109 1.89436L23.0658 7.74924C23.4378 7.27399 23.7419 6.78699 23.9787 6.28986L18.7831 1.09424C18.5294 1.18961 18.2714 1.30293 18.0088 1.43536C17.74 1.57174 17.4741 1.72436 17.2109 1.89436Z"
        fill="url(#paint18_linear_1284_8246)"
      />
      <path
        d="M15.8672 2.92335L21.928 8.98416C22.3594 8.58191 22.7388 8.17004 23.0653 7.74922L17.2104 1.89429C16.7549 2.18722 16.3071 2.53054 15.8672 2.92335Z"
        fill="url(#paint19_linear_1284_8246)"
      />
      <path
        d="M14.6914 4.11809L20.6294 10.056C20.8723 9.87815 21.113 9.69128 21.3492 9.49553C21.5524 9.32715 21.7465 9.15665 21.9299 8.9844L15.8691 2.92358C15.4695 3.2804 15.0768 3.67877 14.6914 4.11809Z"
        fill="url(#paint20_linear_1284_8246)"
      />
      <path
        d="M14.6916 4.11792C14.3353 4.52355 13.9846 4.96405 13.6406 5.4393L19.1993 10.9979C19.6689 10.7191 20.1531 10.4054 20.6295 10.0559L14.6916 4.11792Z"
        fill="url(#paint21_linear_1284_8246)"
      />
      <path
        d="M13.639 5.43921C13.317 5.88358 13.0011 6.35877 12.6914 6.86377L17.6564 11.8288C18.1266 11.5988 18.6523 11.3221 19.1977 10.9978L13.639 5.43921Z"
        fill="url(#paint22_linear_1284_8246)"
      />
      <path
        d="M12.691 6.86377C12.425 7.29577 12.1641 7.74971 11.9077 8.22496C11.8763 8.28446 11.8892 8.36352 11.938 8.41346L15.9302 12.4751C15.9756 12.5206 16.0423 12.5358 16.1007 12.5133C16.4469 12.3781 16.9973 12.1496 17.656 11.8288L12.691 6.86377Z"
        fill="url(#paint23_linear_1284_8246)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1284_8246"
        x1="23.0312"
        y1="18.2298"
        x2="17.2156"
        y2="30.9479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFB500" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1284_8246"
        x1="19.1592"
        y1="26.6903"
        x2="24.4648"
        y2="15.0877"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1284_8246"
        x1="10.3975"
        y1="24.9496"
        x2="7.05428"
        y2="32.2598"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFB500" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1284_8246"
        x1="7.04791"
        y1="32.2095"
        x2="11.797"
        y2="21.8235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1284_8246"
        x1="25.8817"
        y1="22.1651"
        x2="28.0746"
        y2="21.3481"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1284_8246"
        x1="24.3445"
        y1="22.832"
        x2="26.555"
        y2="22.0088"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1284_8246"
        x1="22.8003"
        y1="23.4919"
        x2="25.0107"
        y2="22.6687"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1284_8246"
        x1="21.2525"
        y1="24.1454"
        x2="23.463"
        y2="23.3222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1284_8246"
        x1="19.6849"
        y1="24.7437"
        x2="21.8954"
        y2="23.9205"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1284_8246"
        x1="18.1381"
        y1="25.39"
        x2="20.3485"
        y2="24.5668"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1284_8246"
        x1="16.5916"
        y1="26.0472"
        x2="18.8021"
        y2="25.224"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1284_8246"
        x1="15.0478"
        y1="26.7102"
        x2="17.2583"
        y2="25.887"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1284_8246"
        x1="16.2616"
        y1="8.35418"
        x2="2.27667"
        y2="21.0723"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFB500" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1284_8246"
        x1="21.4964"
        y1="8.35953"
        x2="2.33638"
        y2="-8.40244"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1284_8246"
        x1="9.74534"
        y1="15.6171"
        x2="1.70724"
        y2="28.3353"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF42" />
        <stop offset="1" stopColor="#FFB500" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1284_8246"
        x1="18.4231"
        y1="20.833"
        x2="-0.736935"
        y2="9.67219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1284_8246"
        x1="22.8482"
        y1="2.34899"
        x2="24.5027"
        y2="0.694502"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_1284_8246"
        x1="21.6001"
        y1="3.47114"
        x2="23.2678"
        y2="1.80303"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_1284_8246"
        x1="20.3597"
        y1="4.60067"
        x2="22.0274"
        y2="2.93288"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_1284_8246"
        x1="19.1215"
        y1="5.7352"
        x2="20.7892"
        y2="4.06748"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_1284_8246"
        x1="17.9274"
        y1="6.91187"
        x2="19.5957"
        y2="5.24413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_1284_8246"
        x1="16.6959"
        y1="8.05174"
        x2="18.3636"
        y2="6.384"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_1284_8246"
        x1="15.4537"
        y1="9.18338"
        x2="17.1214"
        y2="7.51565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_1284_8246"
        x1="14.2087"
        y1="10.3112"
        x2="15.8765"
        y2="8.64344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB500" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7000" />
      </linearGradient>
      <clipPath id="clip0_1284_8246">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconWalkability;
