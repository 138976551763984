import React from 'react';
import SideMenu from './SideMenu';
import './styles.scss';

const BaseLayout = props => (
    <div className="base-layout">
      <SideMenu {...props}>
        {props.children}
      </SideMenu>
    </div>
  )

export default BaseLayout;
