import React from "react";

const IconClose = (props) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.85215 7.00275L12.7643 2.09063C13.4991 1.35576 12.3541 0.29375 11.6607 0.987111L6.74863 5.89679L1.83407 0.987111C1.10164 0.252246 0.0371872 1.39727 0.730548 2.09063L5.64267 7.00275L0.728107 11.9149C0.0225387 12.6204 1.11385 13.7362 1.83162 13.0184L6.74619 8.10627L11.6607 13.0184C12.3663 13.724 13.4723 12.6204 12.7643 11.9149L7.85215 7.00275Z"
    />
  </svg>
);

export default IconClose;
