import React from "react";

const IconBasement = (props) => (
  <svg
    width="36"
    height="26"
    viewBox="0 0 36 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.6938 5.00434C21.9355 5.00434 22.9422 3.99787 22.9422 2.75632C22.9422 1.51477 21.9355 0.508301 20.6938 0.508301C19.452 0.508301 18.4453 1.51477 18.4453 2.75632C18.4453 3.99787 19.452 5.00434 20.6938 5.00434Z"
      fill="url(#paint0_linear_716_2566)"
    />
    <path
      d="M20.6929 4.63221C21.7293 4.63221 22.5694 3.79222 22.5694 2.75605C22.5694 1.71987 21.7293 0.879883 20.6929 0.879883C19.6566 0.879883 18.8164 1.71987 18.8164 2.75605C18.8164 3.79222 19.6566 4.63221 20.6929 4.63221Z"
      fill="url(#paint1_linear_716_2566)"
    />
    <path
      d="M21.6968 0.745117C21.8485 1.04773 21.9339 1.38924 21.9339 1.75072C21.9339 2.99229 20.9273 3.99874 19.6855 3.99874C19.3239 3.99874 18.9824 3.91326 18.6797 3.76164C19.0489 4.49829 19.8108 5.00406 20.691 5.00406C21.9328 5.00406 22.9395 3.99761 22.9395 2.75604C22.9395 1.87601 22.4336 1.11422 21.6968 0.745117Z"
      fill="url(#paint2_linear_716_2566)"
    />
    <path
      d="M18.1134 9.09591L18.7496 6.62879C18.9408 5.88733 19.6095 5.36914 20.3752 5.36914H20.6638C21.5737 5.36914 22.3113 6.10679 22.3113 7.01667V16.6525C22.3113 16.9263 22.0893 17.1483 21.8155 17.1483H19.5702C19.2964 17.1483 19.0744 16.9263 19.0744 16.6525V10.1942C19.0744 9.92455 18.8558 9.70593 18.5862 9.70593C18.267 9.706 18.0337 9.40488 18.1134 9.09591Z"
      fill="url(#paint3_linear_716_2566)"
    />
    <path
      d="M21.665 16.5002V7.22091C21.665 6.55576 21.1258 6.0166 20.4607 6.0166H20.3768C19.9064 6.0166 19.4959 6.33467 19.3785 6.79018L18.7891 9.07576C19.3197 9.17105 19.7238 9.63623 19.7238 10.1939V16.5002H21.665V16.5002Z"
      fill="url(#paint4_linear_716_2566)"
    />
    <path
      d="M22.3098 7.01645C22.3098 6.11631 21.5878 5.38523 20.6914 5.36963V17.1481H21.814C22.0878 17.1481 22.3098 16.9261 22.3098 16.6523V7.01645Z"
      fill="url(#paint5_linear_716_2566)"
    />
    <path
      d="M32.9584 25.4918C34.6355 25.4918 36 24.1273 36 22.4502C36 20.773 34.6355 19.4085 32.9584 19.4085H29.3257C28.876 19.4085 28.4531 19.2334 28.1351 18.9153L11.6188 2.39917C10.5049 1.28529 9.024 0.671875 7.44875 0.671875H3.04157C1.3644 0.671875 -7.24792e-05 2.03635 -7.24792e-05 3.71352C-7.24792e-05 5.3907 1.3644 6.7551 3.04157 6.7551H6.67426C7.12405 6.7551 7.54687 6.93023 7.86494 7.2483L24.3811 23.7645C25.495 24.8783 26.9759 25.4918 28.5512 25.4918H32.9584Z"
      fill="url(#paint6_linear_716_2566)"
    />
    <path
      d="M3.04252 1.34863H7.4497C8.83428 1.34863 10.1622 1.89866 11.1413 2.87771L27.6574 19.394C28.1001 19.8367 28.7006 20.0854 29.3266 20.0854H32.9593C34.2654 20.0854 35.3242 21.1442 35.3242 22.4503C35.3242 23.7564 34.2654 24.8152 32.9593 24.8152H28.5521C27.1675 24.8152 25.8396 24.2652 24.8606 23.2861L8.34433 6.76988C7.90167 6.32715 7.30125 6.07847 6.67513 6.07847H3.04245C1.73635 6.07847 0.677532 5.01964 0.677532 3.71355C0.677601 2.40746 1.73635 1.34863 3.04252 1.34863Z"
      fill="url(#paint7_linear_716_2566)"
    />
    <path
      d="M32.9602 24.1386C33.891 24.1386 34.6484 23.3812 34.6484 22.4504C34.6484 21.5195 33.8911 20.7621 32.9602 20.7621H29.3275C28.5162 20.7621 27.7535 20.4462 27.1798 19.8726L10.6636 3.35633C9.80533 2.49805 8.66427 2.02539 7.45051 2.02539H3.04333C2.11241 2.02539 1.35507 2.78273 1.35507 3.71365C1.35507 4.64457 2.11241 5.40191 3.04333 5.40191H6.67601C7.48721 5.40191 8.24999 5.71779 8.82366 6.29146L25.3399 22.8076C26.1981 23.6659 27.3392 24.1386 28.553 24.1386H32.9602Z"
      fill="url(#paint8_linear_716_2566)"
    />
    <path
      d="M10.8622 20.1591L7.37171 16.6691C7.17738 16.4749 7.35682 16.3393 7.55107 16.145L10.3511 13.3455C10.5454 13.1513 10.681 12.9719 10.8754 13.1662L14.3658 16.6561C14.6347 16.9251 15.0709 16.9251 15.3398 16.6561C15.7737 16.2223 16.5156 16.5296 16.5156 17.1431V21.6199C16.5156 22.0002 16.2073 22.3086 15.8269 22.3086H11.3492C10.7356 22.3086 10.4283 21.5668 10.8622 21.133C11.1311 20.864 11.1311 20.428 10.8622 20.1591Z"
      fill="url(#paint9_linear_716_2566)"
    />
    <path
      d="M10.7006 13.7861L13.9673 17.0524C14.4543 17.5392 15.2467 17.5392 15.7337 17.0524C15.774 17.0121 15.8211 17.0027 15.8738 17.0245C15.9264 17.0463 15.9531 17.0862 15.9531 17.1432V21.6201C15.9531 21.6641 15.9327 21.6938 15.9155 21.711C15.8983 21.7281 15.8686 21.7486 15.8247 21.7486H11.347C11.2899 21.7486 11.25 21.7219 11.2282 21.6693C11.2064 21.6166 11.2157 21.5695 11.2561 21.5292C11.7431 21.0424 11.7431 20.2501 11.2561 19.7633L7.98944 16.497L10.7006 13.7861Z"
      fill="url(#paint10_linear_716_2566)"
    />
    <path
      d="M7.54577 16.1453C7.35144 16.3396 7.17208 16.4752 7.36633 16.6694L10.8568 20.1594C11.1257 20.4283 11.1257 20.8643 10.8568 21.1332C10.4229 21.567 10.7302 22.3088 11.3438 22.3088H15.8215C16.0118 22.3088 16.1839 22.2317 16.3086 22.1071L8.94582 14.7456L7.54577 16.1453Z"
      fill="url(#paint11_linear_716_2566)"
    />
    <path
      d="M25.3242 23.7548V20.8564C25.3242 20.6329 25.143 20.4517 24.9195 20.4517H22.021L25.3242 23.7548Z"
      fill="url(#paint12_linear_716_2566)"
    />
    <path
      d="M22.0195 20.4516V17.5532C22.0195 17.3296 21.8383 17.1484 21.6148 17.1484H18.7164L22.0195 20.4516Z"
      fill="url(#paint13_linear_716_2566)"
    />
    <path
      d="M18.7148 17.1479V14.2495C18.7148 14.0259 18.5336 13.8447 18.3101 13.8447H15.4117L18.7148 17.1479Z"
      fill="url(#paint14_linear_716_2566)"
    />
    <path
      d="M15.4141 13.8452V10.9468C15.4141 10.7233 15.2328 10.542 15.0093 10.542H12.1109L15.4141 13.8452Z"
      fill="url(#paint15_linear_716_2566)"
    />
    <path
      d="M12.1094 10.5419V7.64352C12.1094 7.41997 11.9282 7.23877 11.7046 7.23877H8.80619L12.1094 10.5419Z"
      fill="url(#paint16_linear_716_2566)"
    />
    <path
      d="M21.469 17.1481H19.2237C18.9499 17.1481 18.7279 16.9261 18.7279 16.6522V9.50977L21.9648 12.7467V16.6522C21.9648 16.9261 21.7428 17.1481 21.469 17.1481Z"
      fill="url(#paint17_linear_716_2566)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_716_2566"
        x1="19.8526"
        y1="1.91512"
        x2="22.7644"
        y2="4.82696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_2566"
        x1="21.2331"
        y1="3.54551"
        x2="19.2587"
        y2="0.659855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#9E8C92" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_2566"
        x1="20.7691"
        y1="3.61764"
        x2="23.8592"
        y2="-2.12959"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.0471" stopColor="#6E566E" stopOpacity="0.047" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_2566"
        x1="22.3113"
        y1="11.2588"
        x2="18.0976"
        y2="11.2588"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_2566"
        x1="19.3517"
        y1="10.5193"
        x2="16.6429"
        y2="8.40679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#9E8C92" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_716_2566"
        x1="20.6618"
        y1="10.8898"
        x2="16.4085"
        y2="6.54649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.0471" stopColor="#6E566E" stopOpacity="0.047" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_716_2566"
        x1="20.9749"
        y1="10.1068"
        x2="15.0253"
        y2="16.0565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5F3FF" />
        <stop offset="1" stopColor="#B3CCFF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_716_2566"
        x1="16.2967"
        y1="14.7861"
        x2="20.8025"
        y2="10.2803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5F3FF" />
        <stop offset="1" stopColor="#B3CCFF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_716_2566"
        x1="19.4203"
        y1="11.6634"
        x2="16.1648"
        y2="14.9189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5F3FF" />
        <stop offset="1" stopColor="#B3CCFF" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_716_2566"
        x1="14.7186"
        y1="17.4377"
        x2="7.29716"
        y2="18.6379"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F92814" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_716_2566"
        x1="11.2506"
        y1="18.475"
        x2="18.4002"
        y2="15.8992"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" stopOpacity="0" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_716_2566"
        x1="12.0253"
        y1="20.309"
        x2="9.69157"
        y2="8.46058"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F92814" stopOpacity="0" />
        <stop offset="1" stopColor="#C1272D" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_716_2566"
        x1="23.3982"
        y1="23.3411"
        x2="24.7432"
        y2="17.2734"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#9E8C92" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_716_2566"
        x1="20.0935"
        y1="20.0379"
        x2="21.4386"
        y2="13.9703"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#9E8C92" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_716_2566"
        x1="16.7888"
        y1="16.7341"
        x2="18.1339"
        y2="10.6666"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#9E8C92" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_716_2566"
        x1="13.4881"
        y1="13.4314"
        x2="14.833"
        y2="7.36383"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#9E8C92" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_716_2566"
        x1="10.1834"
        y1="10.1282"
        x2="11.5284"
        y2="4.06061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#9E8C92" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_716_2566"
        x1="18.1977"
        y1="15.8047"
        x2="22.483"
        y2="10.5485"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#9E8C92" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconBasement;
