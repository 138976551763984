import React, { useState } from "react";
import { find as lodashFind, map as lodashMap } from "lodash";
import { normalizeMinMax, normalizeIdeal, SLIDER_DIFF } from "utils/helpers/criteriaHelper";
import { CustomSelect } from "components/Controls/Selects";
import { CustomSlider } from "components/Controls/Sliders";
import RangeHistogram from "components/DataDisplay/Histogram";
import "../styles.scss";


const SliderRangeSelectField = ({
  value = {},
  form,
  options,
  min,
  max,
  step,
  kind,
  histogramSteps,
  minMessage,
  idealMessage,
  maxMessage,
  dataKind
}) => {
  const [sliderValue, setSliderValue] = useState([
    value.min,
    value.ideal,
    value.max,
  ]);
  const [minValue, setMinValue] = useState(value.min);
  const [idealValue, setIdealValue] = useState(value.ideal);
  const [maxValue, setMaxValue] = useState(value.max);

  const getValue = inputValue => parseInt(inputValue) || 0;

  const onChangeMinInput = (inputValue) => {
    const value = getValue(inputValue);
    const [min, ideal, max] = normalizeMinMax(value, idealValue, maxValue, SLIDER_DIFF);
    overrideValues(min, ideal, max);
  };

  const onChangeIdealInput = (inputValue) => {
    const value = getValue(inputValue);
    const [min, ideal, max] = normalizeIdeal(minValue, value, maxValue, SLIDER_DIFF);
    overrideValues(min, ideal, max);
  };

  const onChangeMaxInput = (inputValue) => {
    const value = getValue(inputValue);
     const [min, ideal, max] = normalizeMinMax(minValue, idealValue, value, SLIDER_DIFF);
     overrideValues(min, ideal, max);
  };

  const overrideValues = (min, ideal, max) => {
    setSliderValue([min, ideal, max]);
    setMinValue(min);
    setIdealValue(ideal);
    setMaxValue(max);
    form.setFieldsValue({
      range: { min, ideal, max },
    });
  }

  const onChangeSlider = (_, value) => {
    if (value) {
      setSliderValue(value);
      setMinValue(value[0]);
      setIdealValue(value[1]);
      setMaxValue(value[2]);
      form.setFieldsValue({
        range: { min: value[0], ideal: value[1], max: value[2] },
      });
    }
  };

  const formatValue = value => {
    const option = lodashFind(options, item => item.value === value.toString());
    return option ? option.label : 0;
  }

  return (
    <div className="select-range">
      <div className="select-range__header">
        <div className="select-range__header-description">
          <RangeHistogram
            dataKind={dataKind}
            kind={kind}
            steps={histogramSteps}
            minValue={value.min}
            maxValue={value.max}
            min={min}
            max={max}
          />
        </div>
      </div>
      <div className="select-range-field">
        <div>
          <CustomSlider
            range
            marks={lodashMap(options, item => ({value: item.value}))}
            step={step}
            min={min}
            max={max}
            defaultValue={[value.min, value.ideal, value.max]}
            value={sliderValue}
            onChange={onChangeSlider}
            valueLabelDisplay="auto"
            valueLabelFormat={formatValue}
          />
        </div>
        <div className="select-range-field__selects">
          <CustomSelect
            addonBefore={minMessage}
            value={value.min.toString()}
            defaultValue={value.min}
            onChange={onChangeMinInput}
            bordered={false}
            options={options}
          />
          <span className="dash">&ndash;</span>
          <div className="input-star">
            <CustomSelect
              addonBefore={idealMessage}
              value={value.ideal.toString()}
              defaultValue={value.ideal}
              onChange={onChangeIdealInput}
              bordered={false}
              options={options}
            />
          </div>
          <span className="dash">&ndash;</span>
          <CustomSelect
            addonBefore={maxMessage}
            value={value.max.toString()}
            defaultValue={value.max}
            onChange={onChangeMaxInput}
            bordered={false}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default SliderRangeSelectField;
