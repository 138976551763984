import React from "react";
import { FormattedMessage } from "react-intl";

import "./styles.scss";

const AlmostThereStep = () => (
  <div className="almost-there-step-container">
    <div className="almost-there-step__title">
      <FormattedMessage id="onboarding.almostThereTitleFirst" />
    </div>
    <div className="almost-there-step__subtitle">
      <FormattedMessage id="onboarding.almostThereTitleSecond" />
    </div>
  </div>
);

export default AlmostThereStep;
