import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import CustomTooltip from "components/DataDisplay/CustomTooltip";
import { BathroomsForm, BedroomsForm, HomeTypeForm, PriceRangeForm } from "components/Forms";
import { CRITERIA_MAP } from "constants/criteria";
import {
  getBathsAndBeds,
  getBooleanValue,
  getFormattedValueOrNull,
  getPriceValue,
  getValueOrNull
} from "utils/helpers/housesHelper/getter";
import { checkItemPill } from "utils/helpers/housesHelper";
import { convertSqftToAcres, normalizeNumber } from "utils/helpers/criteriaHelper";

const BASIC_FILTERS_MODAL_DATA = {
  [CRITERIA_MAP.bathrooms]: {
    defaultValue: {},
    form: BathroomsForm,
    formName: "FiltersBarBathroomsForm",
    title: "houses.filtersBar.bathroomsModalTitle"
  },
  [CRITERIA_MAP.bedrooms]: {
    defaultValue: {},
    form: BedroomsForm,
    formName: "FiltersBarBedroomsForm",
    title: "houses.filtersBar.bedroomsModalTitle"
  },
  [CRITERIA_MAP.price]: {
    defaultValue: {},
    form: PriceRangeForm,
    formName: "FiltersBarPriceForm",
    title: "houses.filtersBar.priceModalTitle"
  },
  [CRITERIA_MAP.prop_type]: {
    defaultValue: {},
    form: HomeTypeForm,
    formName: "FiltersBarPropTypeForm",
    title: "houses.filtersBar.propTypeModalTitle"
  },
};

const COLUMNS = (renderFirstColumn, renderColoredRow, isSorted, address) => [
  {
    title: (
      <CustomTooltip title={<FormattedMessage id="houses.list.hiScoreTooltipMessage" />}>
        <span>
          <FormattedMessage id="houses.list.hiScoreMessage" />
        </span>
      </CustomTooltip>
    ),
    dataIndex: "total_score",
    className: classNames("hiscore-column__col", { "is-active": isSorted("total_score")}),
    sorter: true,
    showSorterTooltip: {},
    render: (_, record) => renderFirstColumn(record),
  },
  {
    title: <FormattedMessage id="houses.list.priceMessage" />,
    dataIndex: ["price", "value"],
    className: classNames("price-column", { "is-active": isSorted("price")}),
    align: 'center',
    sorter: true,
    width: 160,
    render: (_, record) => {
      const item = record.price;
      const value = getValueOrNull(item) ? getPriceValue(item.value) : '-';
      const pillData = checkItemPill(item, CRITERIA_MAP.price);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.bedroomsMessage" />,
    dataIndex: ["bedrooms", "value"],
    className: classNames({ "is-active": isSorted("bedrooms")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.bedrooms;
      const value = getBathsAndBeds(item, 'bedrooms');
      const pillData = checkItemPill(item, CRITERIA_MAP.bikeability);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.bathroomsMessage" />,
    dataIndex: ["bathrooms", "value"],
    className: classNames({ "is-active": isSorted("bathrooms")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.bathrooms;
      const value = getBathsAndBeds(item, 'bathrooms')
      const pillData = checkItemPill(item, CRITERIA_MAP.bathrooms);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.squareFeetMessage" />,
    dataIndex: ["building_size", "value"],
    className: classNames({ "is-active": isSorted("building_size")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.building_size;
      const value = getValueOrNull(item) && <FormattedMessage id="houses.card.buildingValue" values={{number: normalizeNumber(item.value)}}/>;
      const pillData = checkItemPill(item, CRITERIA_MAP.building_size);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.lotSizeMessage" />,
    dataIndex: ["lot_size", "value"],
    className: classNames({ "is-active": isSorted("lot_size")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.lot_size;
      const value = getValueOrNull(item) && <FormattedMessage id="dashboard.form.acreShort" values={{number: convertSqftToAcres(item.value)}} />;
      const pillData = checkItemPill(item, CRITERIA_MAP.lot_size);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.garageMessage" />,
    dataIndex: ["garage", "value"],
    className: classNames({ "is-active": isSorted("garage")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.garage;
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.garage);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.parkingMessage" />,
    dataIndex: ["parking", "value"],
    className: classNames({ "is-active": isSorted("parking")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.parking;
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.parking);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.centralACMessage" />,
    dataIndex: ["central_ac", "value"],
    className: classNames({ "is-active": isSorted("central_ac")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.central_ac;
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.central_ac);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.basementMessage" />,
    dataIndex: ["basement", "value"],
    className: classNames({ "is-active": isSorted("basement")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.basement;
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.basement);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.singleStoryMessage" />,
    dataIndex: ["single_story", "value"],
    className: classNames({ "is-active": isSorted("single_story")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.single_story;
      const value = item && getFormattedValueOrNull(item.value, getBooleanValue);
      const pillData = checkItemPill(item, CRITERIA_MAP.single_story);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.solarPowerPotentialMessage" />,
    dataIndex: "solar_power",
    className: classNames({ "is-active": isSorted("solar_power")}),
    align: 'center',
    sorter: true,
    width: 130,
    // temporary show N/A for this column
    render: () => renderColoredRow(null, -1, CRITERIA_MAP.solar_power, null)
    // render: (item) => item && renderColoredRow(item.value, item.score)

  },
  {
    title: <FormattedMessage id="houses.list.newlyBuiltMessage" />,
    dataIndex: ["newly_built", "value"],
    className: classNames({ "is-active": isSorted("newly_built")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.newly_built;
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.newly_built);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.commuteMessage" />,
    dataIndex: ["commute", "value"],
    className: classNames({ "is-active": isSorted("commute")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.commute;
      const value = getValueOrNull(item) && <FormattedMessage
            id="houses.list.proximity_criteria"
            values={{ number: item.value }}
          />;
      const pillData = checkItemPill(item, CRITERIA_MAP.commute);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.distanceToAddressMessage" values={{address: address}} />,
    dataIndex: ["custom_distance", "value"],
    className: classNames({ "is-active": isSorted("custom_distance")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.custom_distance;
      const value = getValueOrNull(item) && <FormattedMessage
            id="houses.list.proximity_criteria"
            values={{ number: item.value }}
          />;
      const pillData = checkItemPill(item, CRITERIA_MAP.custom_distance);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.proximityToGroceryMessage" />,
    dataIndex: ["grocery", "value"],
    className: classNames({ "is-active": isSorted("grocery")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.grocery;
      const value = getValueOrNull(item) && <FormattedMessage
            id="houses.list.proximity_criteria"
            values={{ number: ~~(item.value / 60) }}
          />;
      const pillData = checkItemPill(item, CRITERIA_MAP.grocery);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.parksMessage" />,
    dataIndex: ["parks", "value"],
    className: classNames({ "is-active": isSorted("parks")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.parks;
      const value = getValueOrNull(item) && <FormattedMessage
            id="houses.list.proximity_criteria"
            values={{ number: ~~(item.value / 60) }}
          />;
      const pillData = checkItemPill(item, CRITERIA_MAP.parks);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.schoolQualityMessage" />,
    dataIndex: ["school_quality", "value"],
    className: classNames({ "is-active": isSorted("school_quality")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.school_quality;
      const value = getValueOrNull(item);
      const pillData = checkItemPill(item, CRITERIA_MAP.school_quality);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.LowCrimeMessage" />,
    dataIndex: ["low_crime", "value"],
    className: classNames({ "is-active": isSorted("low_crime")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.low_crime;
      const value = getValueOrNull(item) && item.label;
      const pillData = checkItemPill(item, CRITERIA_MAP.crime);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.walkabilityMessage" />,
    dataIndex: ["walkability", "value"],
    className: classNames({ "is-active": isSorted("walkability")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.walkability;
      const value = getValueOrNull(item);
      const pillData = checkItemPill(item, CRITERIA_MAP.walkability);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.quietnessMessage" />,
    dataIndex: ["quietness", "value"],
    className: classNames({ "is-active": isSorted("quietness")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.quietness;
      const value = getValueOrNull(item) && item.value;
      const pillData = checkItemPill(item, CRITERIA_MAP.quietness);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.lowFloodRiskMessage" />,
    dataIndex: "flood_risk",
    className: classNames({ "is-active": isSorted("flood_risk")}),
    align: 'center',
    sorter: true,
    width: 130,
     // temporary show N/A for this column
    render: () => renderColoredRow(null, -1, CRITERIA_MAP.flood_risk, null)
  },
  {
    title: <FormattedMessage id="houses.list.publicTransitScoreMessage" />,
    dataIndex: ["transit", "value"],
    className: classNames({ "is-active": isSorted("transit")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.transit;
      const value = getValueOrNull(item);
      const pillData = checkItemPill(item, CRITERIA_MAP.transit);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.bikeabilityMessage" />,
    dataIndex: ["bikeability", "value"],
    className: classNames({ "is-active": isSorted("bikeability")}),
    align: 'center',
    sorter: true,
    width: 130,
    render: (_, record) => {
      const item = record.bikeability;
      const value = getValueOrNull(item);
      const pillData = checkItemPill(item, CRITERIA_MAP.bikeability);
      return renderColoredRow(value, pillData);
    }
  },
];

export { BASIC_FILTERS_MODAL_DATA, COLUMNS };
