import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
// import _ from "lodash";
import { GooglePlaces } from "components/Controls/Inputs";
import "../styles.scss";

const AddressField = ({ value = [], form, intl }) => {
  const handleSelect = location => {
    form.setFieldsValue({ location });
    // if (!_.isNil(onFieldChange)) {
    //   onFieldChange({ location });
    // }
  }

  return (
    <div className="location-search">
      <div>
        <div className="address-title">
          <FormattedMessage id="dashboard.form.addressTitle" />
        </div>
        <div className="location-search__input">
          <GooglePlaces
            handleSelect={handleSelect}
            value={value}
            placeholder={intl.formatMessage({id: "dashboard.form.addressPlaceholder"})}
            isSaveInput
            types={["address"]}
          />
        </div>
      </div>
    </div>
  );
};

AddressField.propTypes = {
  form: PropTypes.any,
  intl: PropTypes.any,
  value: PropTypes.bool,
  // onFieldChange: PropTypes.func,
};

export default injectIntl(AddressField);
