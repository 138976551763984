import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { SearchLocationForm } from "components/Forms";

import "./styles.scss";

const LocationsStep = ({ formName, queryParamGid, stepData, handleFormChange, onSubmitForm }) => {
  const initialData = stepData.locations || [];

  const renderTitle = () => (
      <div className="locations-step__title">
        <div className="locations-step__title__text">
          <FormattedMessage id="onboarding.locationsTitleFirst"/>
        </div>
        <div className="locations-step__title__text">
          <FormattedMessage id="onboarding.locationsTitleSecond" />
        </div>
        <div className="locations-step__subtitle__text">
          <FormattedMessage id="onboarding.locationsSubtitle" />
        </div>
      </div>
    );

  return (
    <div className="locations-step-container">
      {renderTitle()}
      <SearchLocationForm
        customClassName="locations-form"
        formName={formName}
        initialData={initialData}
        queryParamGid={queryParamGid}
        onValuesChange={handleFormChange}
        onSubmitForm={onSubmitForm}
      />
    </div>
  );
};

LocationsStep.propTypes = {
  formName: PropTypes.string,
  onboardingData: PropTypes.object,
  queryParamGid: PropTypes.string,
  stepData: PropTypes.object,
  user: PropTypes.object,
  handleFormChange: PropTypes.func,
  onSubmitForm: PropTypes.func,
};

export default LocationsStep;
