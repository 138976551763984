import { CRITERIA_MAP } from "constants/criteria";
import {
  IconBikeability,
  IconCommuteDetails,
  IconCustomDistanceDetails,
  IconLowCrime,
  IconProximityToGrocery,
  IconProximityToParks,
  IconPublicTransit,
  IconPublicTransitDetails,
  IconQuietness,
  IconRestaurants,
  IconSchoolBag,
  IconSchoolDetails,
  IconWalkability
} from "components/Svgs";

const CRITERIA_CARDS_DATA = [
  {
    key: CRITERIA_MAP.commute,
    icon: IconCommuteDetails,
    title: "houseDetails.commuteTime",
    valueKey: "commute.value",
  },
  {
    key: CRITERIA_MAP.school_quality,
    icon: IconSchoolDetails,
    title: "houseDetails.schoolQuality",
    valueKey: "schools.value",
  },
  {
    key: "low_crime",
    icon: IconLowCrime,
    title: "houseDetails.crimeRate",
    valueKey: "low_crime.value",
  },
  {
    key: CRITERIA_MAP.walkability,
    icon: IconWalkability,
    title: "houseDetails.walkability",
    valueKey: "walkability.value",
  },
  {
    key: CRITERIA_MAP.transit,
    icon: IconPublicTransitDetails,
    title: "houseDetails.publicTransitScore",
    valueKey: "transit.value",
  },
  {
    key: CRITERIA_MAP.bikeability,
    icon: IconBikeability,
    title: "houseDetails.bikeability",
    valueKey: "bikeability.value",
  },
  {
    key: CRITERIA_MAP.quietness,
    icon: IconQuietness,
    title: "houseDetails.quietness",
    valueKey: "quietness.value",
  },
  {
    key: CRITERIA_MAP.parks,
    icon: IconProximityToParks,
    title: "houseDetails.proximityToParks",
    valueKey: "parks.value",
  },
  {
    key: CRITERIA_MAP.grocery,
    icon: IconProximityToGrocery,
    title: "houseDetails.proximityToGrocery",
    valueKey: "grocery.value",
  },
  {
    key: CRITERIA_MAP.custom_distance,
    icon: IconCustomDistanceDetails,
    title: "houseDetails.yourCustomDistance",
    valueKey: "custom_distance.value",
  },
];

const DISTANCE_CRITERIA = [
  CRITERIA_MAP.commute,
  CRITERIA_MAP.custom_distance,
  CRITERIA_MAP.grocery,
  CRITERIA_MAP.parks,
  CRITERIA_MAP.transit
];

const GROCERY_AND_PARKS = [
  CRITERIA_MAP.grocery,
  CRITERIA_MAP.parks,
];

const PLACE_KIND_MESSAGES = {
  bus_station: "houseDetails.bus",
  cafe: "houseDetails.cafe",
  grocery: "houseDetails.grocery",
  light_rail_station: "houseDetails.rail",
  park: "houseDetails.park",
  restaurant: "houseDetails.restaurant",
  school: "houseDetails.schoolGrades",
  subway_station: "houseDetails.subway",
  train_station: "houseDetails.train",
  transit_station: "houseDetails.transitStop"
};

const PLACES_CARDS_DATA = [
  {
    key: "schools",
    kind: "school",
    icon: IconSchoolBag,
    title: "houseDetails.schools",
    valueKey: "schools.schools",
  },
  {
    key: "restaurants_and_cafes",
    icon: IconRestaurants,
    title: "houseDetails.restaurantsAndCafes",
  },
  {
    key: "transit_stations",
    icon: IconPublicTransit,
    title: "houseDetails.transitStops"
  },
  {
    key: "parks",
    kind: "park",
    icon: IconProximityToParks,
    title: "houseDetails.parks",
    valueKey: "parks.places"
  },
  {
    key: "grocery_stores",
    kind: "grocery",
    icon: IconProximityToGrocery,
    title: "houseDetails.groceryStores",
    valueKey: "grocery.places",
  },
];

const PLACES_MOCK_DATA = {
  "restaurants_and_cafes": [
    {
      name: "Item Name Lorem",
      kind: "restaurant",
      distance_in_miles: 1.9,
      rating: 5.0
    },
    {
      name: "Item Name Lorem",
      kind: "cafe",
      distance_in_miles: 3.9,
      rating: 4.8
    },
    {
      name: "Name 3",
      kind: "restaurant",
      distance_in_miles: 4.7,
      rating: 3.5
    },
  ],
  "grocery_stores": [
    {
      name: "Item Name Lorem",
      distance_in_miles: 0.2,
      rating: 4.9,
    },
    {
      name: "Item Name Lorem",
      distance_in_miles: 1.4,
      rating: 4.7,
    },
    {
      name: "Item Name Lorem",
      distance_in_miles: 2.5,
      rating: 3.8,
    },
  ],
  "schools": [
    {
      name: "Item Name Lorem",
      distance_in_miles: 0.1,
      rating: 4.9
    },
    {
      name: "Item Name Lorem",
      distance_in_miles: 1.1,
      rating: 4.5
    },
    {
      name: "Item Name Lorem",
      distance_in_miles: 2.1,
      rating: 3.7
    },
  ],
  "transit_stations": [
    {
      name: "Name",
      kind: "subway",
      distance_in_miles: 0.54,
      rating: 4.9,
    },
    {
      name: "Train Station Name",
      kind: "train",
      distance_in_miles: 1.87,
      rating: 3.4,
    },
    {
      name: "Bus Station Name",
      kind: "bus",
      distance_in_miles: 2.49,
      rating: 2.2,
    },
  ],
  "parks": [
    {
      name: "Item Name Lorem Park",
      distance_in_miles: 0.4,
      rating: 5,
    },
    {
      name: "Item Name Lorem",
      distance_in_miles: 0.4,
      rating: 4.1,
    },
    {
      name: "Item Name Lorem",
      distance_in_miles: 0.4,
      rating: 4.0,
    },
  ],
};

export {
  CRITERIA_CARDS_DATA,
  DISTANCE_CRITERIA,
  PLACE_KIND_MESSAGES,
  PLACES_CARDS_DATA,
  PLACES_MOCK_DATA,
  GROCERY_AND_PARKS,
};
