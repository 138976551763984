import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import { onboardingFirstStepPhoto } from "images";

import "./styles.scss";

const WelcomeStep = () => {

  const renderImage = () => (
    <div className="welcome-step__image">
      <img
        width={459} // 511
        height={310} // 346
        src={onboardingFirstStepPhoto}
        alt="Heidi photo"
      />
    </div>
  );

  const renderTitle = () => (
    <div className="welcome-step__title">
      <FormattedMessage id="onboarding.welcomeTitle" />
    </div>
  );

  return (
    <div className="welcome-step-container">
      {renderImage()}
      {renderTitle()}
    </div>
  );
};

WelcomeStep.propTypes = {
  formName: PropTypes.string,
  stepData: PropTypes.object,
  handleFormChange: PropTypes.func,
  onSubmitForm: PropTypes.func,
};

export default WelcomeStep;
