import React from "react";
import { Radio } from "antd";
import "../styles.scss";

const CustomRadio = props => {
  const { value, ...otherProps } = props;

  return (
    <div className="radio-wrapper">
      <Radio value={value} {...otherProps} />
    </div>
  );
};

export default CustomRadio;