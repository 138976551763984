import React from "react";

const IconEmailConfirm = () => (
<svg width="968" height="712" viewBox="0 0 968 712" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_3265_6471)">
<path d="M846.951 376.453L695.34 411.1L718.695 513.289L870.306 478.642L846.951 376.453Z" fill="white"/>
<path d="M847.683 377.577C847.717 377.611 847.342 378.191 846.574 379.265C845.722 380.442 844.597 381.993 843.198 383.937C840.129 388.08 835.833 393.859 830.564 400.952C819.703 415.342 804.835 435.051 788.348 456.874L788.007 457.335L787.478 457.079C787.086 456.891 786.643 456.687 786.217 456.465C761.323 444.377 738.8 433.448 722.329 425.452C714.282 421.48 707.734 418.223 703.046 415.904C700.88 414.796 699.141 413.91 697.828 413.245C696.652 412.631 696.038 412.273 696.055 412.239C696.072 412.205 696.737 412.46 697.948 412.989C699.295 413.603 701.068 414.404 703.267 415.41C708.007 417.626 714.623 420.746 722.756 424.549C739.277 432.442 761.852 443.252 786.813 455.186C787.24 455.391 787.683 455.596 788.075 455.8L787.205 456.005C803.812 434.284 818.8 414.66 829.746 400.338C835.168 393.365 839.584 387.671 842.738 383.596C844.221 381.72 845.415 380.22 846.319 379.095C847.188 378.072 847.631 377.543 847.683 377.577Z" fill="#E0E0E0"/>
<path d="M719.42 514.417C719.079 514.246 727.281 497.623 737.732 477.317C748.201 456.994 756.948 440.678 757.306 440.865C757.647 441.036 749.446 457.659 738.994 477.965C728.525 498.271 719.761 514.587 719.42 514.417Z" fill="#E0E0E0"/>
<path d="M807.414 428.061C807.652 427.754 822.111 439.092 839.673 453.362C857.251 467.65 871.284 479.465 871.045 479.772C870.806 480.079 856.365 468.741 838.786 454.47C821.207 440.183 807.158 428.368 807.414 428.061Z" fill="#E0E0E0"/>
<path d="M171.555 98.6411L157.648 158.863L246.997 179.494L260.904 119.272L171.555 98.6411Z" fill="white"/>
<path d="M261.024 120.096C261.041 120.113 260.683 120.335 260.001 120.71C259.25 121.119 258.244 121.648 257.017 122.313C254.323 123.711 250.555 125.672 245.934 128.093C236.471 132.935 223.513 139.567 209.157 146.898L208.85 147.052L208.645 146.779C208.492 146.574 208.304 146.353 208.134 146.131C198.04 133.31 188.901 121.716 182.218 113.243C178.978 109.065 176.335 105.655 174.443 103.217C173.573 102.075 172.891 101.154 172.363 100.472C171.902 99.8416 171.663 99.5006 171.681 99.4836C171.698 99.4665 171.987 99.7734 172.499 100.37C173.062 101.035 173.795 101.922 174.698 103.03C176.642 105.434 179.353 108.776 182.678 112.884C189.413 121.324 198.603 132.866 208.782 145.619C208.952 145.841 209.14 146.063 209.293 146.267L208.782 146.148C223.172 138.902 236.181 132.338 245.678 127.564C250.35 125.262 254.152 123.37 256.88 122.023C258.159 121.409 259.165 120.932 259.949 120.557C260.631 120.233 261.007 120.062 261.024 120.096Z" fill="#E0E0E0"/>
<path d="M157.771 159.685C157.635 159.497 166.262 152.797 177.038 144.715C187.814 136.634 196.663 130.223 196.799 130.411C196.935 130.598 188.308 137.299 177.532 145.38C166.757 153.462 157.908 159.855 157.771 159.685Z" fill="#E0E0E0"/>
<path d="M226.667 136.515C226.872 136.413 231.629 146.148 237.272 158.236C242.916 170.342 247.332 180.213 247.127 180.316C246.923 180.418 242.166 170.683 236.522 158.595C230.861 146.489 226.462 136.601 226.667 136.515Z" fill="#E0E0E0"/>
<path d="M737.92 113.72L672.641 141.769L691.549 185.772L756.828 157.723L737.92 113.72Z" fill="white"/>
<path d="M738.327 114.147C738.344 114.164 738.224 114.454 737.969 114.999C737.679 115.596 737.304 116.38 736.843 117.369C735.803 119.483 734.354 122.433 732.564 126.047C728.864 133.413 723.8 143.489 718.208 154.656L718.088 154.895L717.832 154.827C717.645 154.776 717.423 154.707 717.219 154.656C705.045 151.281 694.013 148.212 685.949 145.978C682.01 144.853 678.787 143.932 676.503 143.284C675.446 142.96 674.593 142.705 673.945 142.517C673.366 142.33 673.059 142.227 673.059 142.21C673.059 142.193 673.383 142.261 673.979 142.398C674.627 142.568 675.497 142.773 676.571 143.046C678.89 143.659 682.112 144.512 686.085 145.552C694.15 147.734 705.198 150.735 717.389 154.043C717.594 154.094 717.815 154.162 718.003 154.213L717.628 154.384C723.306 143.25 728.421 133.208 732.155 125.86C734.013 122.279 735.531 119.364 736.622 117.267C737.133 116.295 737.542 115.528 737.866 114.948C738.139 114.402 738.31 114.13 738.327 114.147Z" fill="#E0E0E0"/>
<path d="M691.942 186.198C691.772 186.147 694.091 177.997 697.109 168.023C700.144 158.032 702.735 149.985 702.906 150.036C703.076 150.087 700.757 158.22 697.722 168.211C694.705 178.185 692.113 186.249 691.942 186.198Z" fill="#E0E0E0"/>
<path d="M724.376 140.147C724.461 139.994 731.878 143.898 740.948 148.86C750.019 153.838 757.3 157.981 757.214 158.135C757.129 158.288 749.712 154.384 740.642 149.422C731.588 144.461 724.29 140.301 724.376 140.147Z" fill="#E0E0E0"/>
<path d="M715.765 700.016H109.834C96.1089 700.016 84.9922 688.9 84.9922 675.175V291.323C84.9922 277.599 96.1089 266.482 109.834 266.482H715.765C729.49 266.482 740.607 277.599 740.607 291.323V675.175C740.607 688.9 729.49 700.016 715.765 700.016Z" fill="#455A64"/>
<path d="M739.333 709.957H89.7205C72.9601 709.957 59.3711 696.368 59.3711 679.608V672.021H769.666V679.608C769.683 696.368 756.094 709.957 739.333 709.957Z" fill="#455A64"/>
<path d="M721.171 283.703H102.59V649.806H721.171V283.703Z" fill="#263238"/>
<path d="M348.16 667.946L356.975 681.978H481.015L489.199 667.946H348.16Z" fill="#263238"/>
<path d="M704.114 648.648H120.911C117.825 648.648 115.336 646.141 115.336 643.072V301.691C115.336 298.605 117.842 296.116 120.911 296.116H704.114C707.2 296.116 709.689 298.622 709.689 301.691V643.072C709.706 646.158 707.2 648.648 704.114 648.648Z" fill="#EBEBEB"/>
<path d="M709.058 649.16H114.688L115.335 322.458H709.058V649.16Z" fill="white"/>
<path d="M164.089 309.72C164.089 312.346 161.958 314.46 159.349 314.46C156.724 314.46 154.609 312.329 154.609 309.72C154.609 307.095 156.741 304.98 159.349 304.98C161.958 304.98 164.089 307.095 164.089 309.72Z" fill="#263238"/>
<path d="M135.304 309.72C135.304 312.346 133.173 314.46 130.564 314.46C127.938 314.46 125.824 312.329 125.824 309.72C125.824 307.095 127.955 304.98 130.564 304.98C133.19 304.98 135.304 307.095 135.304 309.72Z" fill="#263238"/>
<path d="M149.109 309.72C149.109 312.346 146.978 314.46 144.369 314.46C141.743 314.46 139.629 312.329 139.629 309.72C139.629 307.095 141.76 304.98 144.369 304.98C146.995 304.98 149.109 307.095 149.109 309.72Z" fill="#263238"/>
<path d="M734.964 667.946C734.964 668.185 589.458 668.372 409.988 668.372C230.466 668.372 84.9766 668.185 84.9766 667.946C84.9766 667.708 230.466 667.52 409.988 667.52C589.458 667.52 734.964 667.725 734.964 667.946Z" fill="#263238"/>
<path d="M305.637 450.072L547.46 447.822L554.536 612.196L304.137 610.405L305.637 450.072Z" fill="#939DF4"/>
<path d="M305.637 450.073L419.89 357.239C424.732 353.3 431.655 353.317 436.497 357.256L547.46 447.823" fill="#939DF4"/>
<g opacity="0.3">
<g opacity="0.3">
<g opacity="0.3">
<path opacity="0.3" d="M305.637 450.073L419.89 357.239C424.732 353.3 431.655 353.317 436.497 357.256L547.46 447.823" fill="black"/>
</g>
</g>
</g>
<path d="M304.133 610.406C303.826 610.117 323.144 588.941 347.27 563.111C371.396 537.264 391.208 516.549 391.498 516.839C391.805 517.129 372.487 538.287 348.361 564.134C324.252 589.964 304.439 610.679 304.133 610.406Z" fill="#263238"/>
<path d="M554.544 612.195C554.237 612.468 535.346 591.804 512.345 566.025C489.344 540.246 470.947 519.139 471.254 518.866C471.561 518.593 490.452 539.257 513.453 565.036C536.454 590.815 554.851 611.922 554.544 612.195Z" fill="#263238"/>
<g opacity="0.5">
<g opacity="0.5">
<g opacity="0.5">
<path opacity="0.5" d="M547.46 447.822L426.233 545.072L305.637 450.072" fill="black"/>
</g>
</g>
</g>
<path d="M317.85 459.04L317.305 383.511L529.051 380.579L531.592 460.029L426.221 543.81L317.85 459.04Z" fill="white"/>
<path d="M317.85 459.04C317.85 459.04 318.549 459.552 319.862 460.54C321.174 461.563 323.118 463.047 325.642 464.99C330.706 468.912 338.105 474.657 347.551 482.006C366.46 496.737 393.553 517.861 426.46 543.503H426.016C456.366 519.344 492.376 490.684 531.352 459.671L531.182 460.029C530.687 444.872 530.176 428.965 529.664 412.785C529.323 401.89 528.965 391.132 528.624 380.579L529.068 381.005C443.748 382.13 368.489 383.119 317.321 383.784L317.594 383.494C317.696 407.244 317.781 426.101 317.85 439.041C317.867 445.503 317.867 450.481 317.884 453.874C317.884 455.545 317.884 456.824 317.867 457.71C317.867 458.58 317.85 459.04 317.85 459.04C317.85 459.04 317.816 458.614 317.799 457.761C317.781 456.892 317.764 455.613 317.73 453.959C317.696 450.584 317.628 445.605 317.56 439.16C317.44 426.186 317.253 407.295 317.031 383.511V383.221H317.321C368.472 382.471 443.731 381.363 529.051 380.118H529.477L529.494 380.544C529.835 391.098 530.176 401.856 530.534 412.751C531.045 428.931 531.557 444.838 532.051 459.995V460.217L531.881 460.353C492.887 491.315 456.843 519.941 426.46 544.066L426.238 544.253L426.016 544.083C393.16 518.304 366.085 497.043 347.21 482.227C337.815 474.828 330.467 469.031 325.454 465.076C322.982 463.115 321.072 461.598 319.776 460.575C318.481 459.569 317.85 459.04 317.85 459.04Z" fill="#263238"/>
<path d="M495.668 418.666C495.668 418.905 463.29 419.382 423.358 419.706C383.41 420.03 351.031 420.098 351.031 419.859C351.031 419.621 383.41 419.143 423.358 418.819C463.273 418.478 495.668 418.41 495.668 418.666Z" fill="#263238"/>
<path d="M495.703 439.995C495.703 440.234 463.325 440.712 423.393 441.035C383.445 441.359 351.066 441.428 351.066 441.189C351.066 440.95 383.445 440.473 423.393 440.149C463.325 439.825 495.703 439.757 495.703 439.995Z" fill="#263238"/>
<path d="M495.75 464.581C495.75 464.82 463.372 465.297 423.44 465.621C383.492 465.945 351.113 466.014 351.113 465.775C351.113 465.536 383.492 465.059 423.44 464.735C463.372 464.411 495.75 464.343 495.75 464.581Z" fill="#263238"/>
<path d="M495.796 489.166C495.796 489.405 464.901 489.865 426.811 490.172C388.704 490.479 357.809 490.53 357.809 490.291C357.809 490.053 388.687 489.592 426.811 489.285C464.901 488.978 495.796 488.927 495.796 489.166Z" fill="#263238"/>
<path d="M468.775 510.802C468.775 510.836 468.485 510.87 467.923 510.905C467.258 510.939 466.456 510.973 465.484 511.007C463.234 511.058 460.216 511.143 456.533 511.229C448.86 511.365 438.477 511.552 427.019 511.757C415.493 511.979 405.041 512.047 397.488 512.03C393.805 512.013 390.787 511.979 388.537 511.979C387.565 511.962 386.764 511.928 386.099 511.91C385.536 511.893 385.246 511.859 385.246 511.825C385.246 511.791 385.536 511.757 386.099 511.723C386.764 511.689 387.565 511.655 388.537 511.621C390.787 511.569 393.805 511.484 397.488 511.399C405.161 511.263 415.544 511.075 427.002 510.87C438.528 510.649 448.963 510.581 456.516 510.598C460.199 510.615 463.217 510.649 465.467 510.649C466.439 510.666 467.241 510.7 467.905 510.717C468.485 510.751 468.775 510.785 468.775 510.802Z" fill="#263238"/>
<path d="M441.551 531.602C441.551 531.841 434.646 532.063 426.138 532.08C417.612 532.097 410.724 531.909 410.707 531.67C410.707 531.432 417.612 531.21 426.12 531.193C434.646 531.176 441.551 531.363 441.551 531.602Z" fill="#263238"/>
<path d="M470.09 379.913C470.329 379.947 470.431 380.902 470.328 382.078C470.209 383.255 469.919 384.175 469.681 384.158C469.442 384.141 469.34 383.169 469.442 381.993C469.544 380.817 469.834 379.879 470.09 379.913Z" fill="#263238"/>
<path d="M472.362 363.307C472.601 363.341 472.516 365.233 472.175 367.518C471.834 369.803 471.339 371.627 471.1 371.593C470.862 371.559 470.947 369.683 471.288 367.382C471.646 365.08 472.123 363.256 472.362 363.307Z" fill="#263238"/>
<path d="M475.701 346.871C475.94 346.922 475.735 348.797 475.224 351.065C474.729 353.315 474.115 355.106 473.877 355.054C473.638 355.003 473.842 353.128 474.354 350.86C474.866 348.61 475.462 346.82 475.701 346.871Z" fill="#263238"/>
<path d="M480.253 330.742C480.492 330.81 480.134 332.668 479.469 334.885C478.804 337.101 478.071 338.84 477.832 338.772C477.593 338.704 477.951 336.845 478.616 334.629C479.281 332.395 480.014 330.674 480.253 330.742Z" fill="#263238"/>
<path d="M486.101 315.04C486.323 315.142 485.811 316.95 484.959 319.098C484.106 321.246 483.22 322.917 482.998 322.832C482.776 322.747 483.271 320.922 484.14 318.774C484.993 316.609 485.88 314.938 486.101 315.04Z" fill="#263238"/>
<path d="M493.369 299.951C493.591 300.07 492.909 301.826 491.851 303.872C490.794 305.918 489.771 307.504 489.55 307.401C489.328 307.299 490.01 305.543 491.067 303.48C492.124 301.417 493.147 299.831 493.369 299.951Z" fill="#263238"/>
<path d="M502.132 285.664C502.336 285.8 501.45 287.471 500.171 289.38C498.943 291.341 497.767 292.807 497.562 292.688C497.357 292.569 498.193 290.864 499.438 288.903C500.716 286.976 501.927 285.527 502.132 285.664Z" fill="#263238"/>
<path d="M512.462 272.467C512.649 272.638 511.592 274.206 510.126 275.979C508.659 277.753 507.295 279.065 507.108 278.912C506.92 278.759 507.96 277.19 509.444 275.4C510.927 273.627 512.274 272.314 512.462 272.467Z" fill="#263238"/>
<path d="M524.263 260.583C524.433 260.77 523.206 262.203 521.518 263.788C519.847 265.374 518.346 266.516 518.176 266.346C518.005 266.175 519.233 264.743 520.921 263.14C522.609 261.555 524.109 260.395 524.263 260.583Z" fill="#263238"/>
<path d="M537.456 250.251C537.593 250.456 536.195 251.718 534.336 253.081C532.478 254.445 530.841 255.4 530.687 255.196C530.534 255.008 531.932 253.729 533.808 252.365C535.683 251.001 537.32 250.047 537.456 250.251Z" fill="#263238"/>
<path d="M551.913 241.778C552.015 241.999 550.463 243.073 548.434 244.164C546.405 245.256 544.666 245.989 544.53 245.767C544.411 245.563 545.962 244.488 548.008 243.38C550.054 242.272 551.793 241.556 551.913 241.778Z" fill="#263238"/>
<path d="M567.436 235.47C567.504 235.708 565.816 236.527 563.651 237.311C561.486 238.095 559.661 238.556 559.559 238.317C559.457 238.095 561.162 237.26 563.344 236.476C565.527 235.674 567.351 235.231 567.436 235.47Z" fill="#263238"/>
<path d="M583.735 231.668C583.769 231.906 581.962 232.401 579.694 232.793C577.443 233.27 575.568 233.458 575.517 233.219C575.466 232.98 577.256 232.401 579.541 231.923C581.825 231.531 583.701 231.429 583.735 231.668Z" fill="#263238"/>
<path d="M600.462 230.747C600.445 230.986 598.586 231.207 596.284 231.241C593.983 231.276 592.107 231.088 592.09 230.849C592.073 230.611 593.948 230.389 596.267 230.355C598.603 230.321 600.479 230.491 600.462 230.747Z" fill="#263238"/>
<path d="M617.091 232.709C617.04 232.947 615.148 232.845 612.88 232.487C610.612 232.112 608.788 231.617 608.805 231.379C608.839 231.14 610.714 231.242 613.016 231.6C615.318 231.975 617.142 232.47 617.091 232.709Z" fill="#263238"/>
<path d="M633.145 237.499C633.06 237.72 631.219 237.294 629.036 236.544C626.854 235.794 625.149 234.992 625.217 234.754C625.285 234.515 627.126 234.941 629.326 235.709C631.525 236.459 633.23 237.26 633.145 237.499Z" fill="#263238"/>
<path d="M648.059 245.103C647.922 245.308 646.2 244.557 644.205 243.415C642.211 242.273 640.676 241.165 640.778 240.96C640.881 240.738 642.62 241.506 644.632 242.648C646.661 243.79 648.195 244.898 648.059 245.103Z" fill="#263238"/>
<path d="M661.194 255.468C661.023 255.639 659.471 254.547 657.749 253.03C656.027 251.513 654.749 250.132 654.885 249.927C655.038 249.739 656.573 250.814 658.329 252.365C660.085 253.9 661.364 255.298 661.194 255.468Z" fill="#263238"/>
<path d="M669.477 265.016C669.273 265.152 668.539 264.522 667.823 263.601C667.107 262.68 666.681 261.794 666.868 261.64C667.056 261.487 667.806 262.118 668.522 263.055C669.255 263.993 669.682 264.863 669.477 265.016Z" fill="#263238"/>
<path d="M748.312 367.74C748.209 367.962 746.402 367.348 744.254 366.342C742.105 365.353 740.469 364.364 740.571 364.143C740.673 363.921 742.498 364.552 744.629 365.541C746.76 366.529 748.414 367.518 748.312 367.74Z" fill="#263238"/>
<path d="M764.403 373.332C764.335 373.571 762.459 373.195 760.192 372.513C757.924 371.814 756.168 371.081 756.253 370.843C756.338 370.604 758.214 370.979 760.464 371.661C762.698 372.36 764.471 373.093 764.403 373.332Z" fill="#263238"/>
<path d="M781.24 376.026C781.223 376.265 779.313 376.265 776.96 376.009C774.607 375.753 772.732 375.344 772.766 375.105C772.8 374.866 774.727 374.866 777.046 375.122C779.364 375.378 781.24 375.77 781.24 376.026Z" fill="#263238"/>
<path d="M798.249 375.497C798.283 375.735 796.39 376.076 794.037 376.264C791.685 376.434 789.758 376.383 789.758 376.145C789.758 375.906 791.633 375.565 793.969 375.377C796.305 375.19 798.215 375.241 798.249 375.497Z" fill="#263238"/>
<path d="M815.001 372.36C815.069 372.598 813.262 373.246 810.96 373.809C808.642 374.303 806.732 374.457 806.681 374.218C806.63 373.962 808.471 373.434 810.756 372.939C813.04 372.377 814.933 372.121 815.001 372.36Z" fill="#263238"/>
<path d="M830.818 366.069C830.938 366.291 829.284 367.263 827.135 368.251C824.987 369.24 823.163 369.854 823.077 369.632C822.992 369.411 824.646 368.422 826.76 367.45C828.892 366.478 830.699 365.865 830.818 366.069Z" fill="#263238"/>
<path d="M844.956 356.573C845.11 356.761 843.78 358.159 841.887 359.591C839.995 361.023 838.273 361.91 838.153 361.705C838.017 361.501 839.483 360.29 841.359 358.875C843.217 357.477 844.786 356.386 844.956 356.573Z" fill="#263238"/>
<path d="M856.551 344.127C856.756 344.263 855.801 345.951 854.283 347.775C852.766 349.6 851.3 350.861 851.129 350.691C850.942 350.52 852.118 349.003 853.601 347.213C855.085 345.422 856.346 343.99 856.551 344.127Z" fill="#263238"/>
<path d="M864.525 329.122C864.747 329.207 864.304 331.082 863.332 333.248C862.36 335.413 861.269 337.016 861.047 336.896C860.825 336.777 861.576 335.021 862.513 332.89C863.485 330.775 864.286 329.036 864.525 329.122Z" fill="#263238"/>
<path d="M867.679 312.448C867.918 312.448 868.123 314.375 867.867 316.745C867.628 319.115 867.031 320.973 866.81 320.922C866.571 320.871 866.759 318.978 866.997 316.659C867.219 314.341 867.424 312.448 867.679 312.448Z" fill="#263238"/>
<path d="M865.228 295.637C865.45 295.569 866.234 297.342 866.762 299.661C867.291 301.98 867.359 303.923 867.12 303.957C866.882 303.991 866.421 302.133 865.893 299.865C865.381 297.581 864.989 295.722 865.228 295.637Z" fill="#263238"/>
<path d="M857.809 280.361C857.997 280.225 859.293 281.674 860.486 283.737C861.68 285.8 862.328 287.624 862.106 287.726C861.884 287.829 860.896 286.209 859.719 284.18C858.543 282.151 857.605 280.497 857.809 280.361Z" fill="#263238"/>
<path d="M845.978 268.188C846.114 267.984 847.819 268.938 849.609 270.49C851.417 272.041 852.593 273.593 852.406 273.763C852.218 273.934 850.786 272.689 849.03 271.172C847.274 269.637 845.841 268.393 845.978 268.188Z" fill="#263238"/>
<path d="M830.517 261.266C830.568 261.027 832.512 261.215 834.779 261.965C837.047 262.715 838.718 263.721 838.615 263.926C838.513 264.147 836.706 263.534 834.506 262.8C832.29 262.067 830.466 261.522 830.517 261.266Z" fill="#263238"/>
<path d="M813.615 261.555C813.547 261.333 815.371 260.617 817.741 260.328C820.111 260.021 822.055 260.208 822.055 260.447C822.055 260.703 820.162 260.89 817.861 261.197C815.559 261.487 813.683 261.794 813.615 261.555Z" fill="#263238"/>
<path d="M798.919 269.773C798.748 269.62 799.908 268.034 801.835 266.585C803.744 265.136 805.569 264.42 805.688 264.624C805.807 264.846 804.222 265.886 802.38 267.284C800.522 268.682 799.106 269.944 798.919 269.773Z" fill="#263238"/>
<path d="M792.172 285.085C791.934 285.067 791.848 283.107 792.411 280.771C792.974 278.435 793.98 276.73 794.201 276.832C794.44 276.935 793.809 278.725 793.281 280.976C792.735 283.226 792.428 285.102 792.172 285.085Z" fill="#263238"/>
<path d="M795.49 301.606C795.286 301.725 794.195 300.088 793.41 297.821C792.626 295.553 792.472 293.609 792.694 293.558C792.95 293.507 793.478 295.331 794.246 297.531C795.013 299.73 795.729 301.486 795.49 301.606Z" fill="#263238"/>
<path d="M806.646 314.29C806.509 314.495 804.787 313.574 803.014 311.988C801.224 310.403 800.098 308.8 800.286 308.647C800.474 308.476 801.855 309.789 803.611 311.324C805.333 312.858 806.782 314.086 806.646 314.29Z" fill="#263238"/>
<path d="M822.122 321.229C822.071 321.467 820.145 321.212 817.894 320.444C815.643 319.677 813.972 318.688 814.075 318.467C814.177 318.245 815.984 318.842 818.184 319.592C820.383 320.342 822.173 320.973 822.122 321.229Z" fill="#263238"/>
<path d="M839.04 322.831C839.057 323.07 837.147 323.394 834.777 323.325C832.407 323.274 830.498 322.882 830.532 322.643C830.549 322.388 832.458 322.388 834.794 322.439C837.113 322.49 839.023 322.575 839.04 322.831Z" fill="#263238"/>
<path d="M855.746 319.728C855.831 319.95 854.058 320.751 851.756 321.348C849.454 321.945 847.527 322.081 847.493 321.842C847.442 321.604 849.283 321.075 851.551 320.495C853.802 319.916 855.66 319.507 855.746 319.728Z" fill="#263238"/>
<path d="M871.213 312.653C871.349 312.858 869.746 313.932 867.649 315.04C865.552 316.148 863.779 316.864 863.677 316.643C863.574 316.421 865.177 315.347 867.24 314.256C869.303 313.165 871.076 312.448 871.213 312.653Z" fill="#263238"/>
<path d="M884.866 302.491C885.036 302.679 883.689 304.043 881.848 305.543C880.024 307.043 878.421 308.1 878.267 307.896C878.131 307.691 879.478 306.327 881.285 304.844C883.11 303.378 884.712 302.321 884.866 302.491Z" fill="#263238"/>
<path d="M896.485 290.028C896.672 290.182 895.598 291.75 894.047 293.558C892.512 295.365 891.114 296.678 890.944 296.507C890.773 296.337 891.847 294.751 893.382 292.978C894.916 291.205 896.297 289.892 896.485 290.028Z" fill="#263238"/>
<path d="M905.859 275.809C906.08 275.928 905.262 277.667 904.051 279.696C902.824 281.725 901.681 283.259 901.477 283.123C901.272 282.987 902.09 281.248 903.284 279.236C904.495 277.224 905.654 275.689 905.859 275.809Z" fill="#263238"/>
<path d="M912.787 260.226C913.026 260.311 912.514 262.152 911.645 264.352C910.792 266.568 909.872 268.256 909.633 268.137C909.428 268.051 909.974 266.21 910.809 264.028C911.662 261.862 912.549 260.157 912.787 260.226Z" fill="#263238"/>
<path d="M917.537 243.875C917.775 243.926 917.485 245.819 916.889 248.12C916.292 250.405 915.627 252.212 915.388 252.127C915.15 252.059 915.439 250.166 916.036 247.882C916.633 245.614 917.298 243.824 917.537 243.875Z" fill="#263238"/>
<path d="M920.524 227.099C920.763 227.133 920.678 229.042 920.32 231.378C919.962 233.714 919.484 235.572 919.228 235.521C918.99 235.47 919.075 233.56 919.433 231.242C919.808 228.923 920.286 227.065 920.524 227.099Z" fill="#263238"/>
<path d="M922.09 210.118C922.329 210.135 922.397 212.045 922.209 214.398C922.039 216.751 921.698 218.643 921.442 218.609C921.203 218.592 921.135 216.665 921.323 214.33C921.51 211.994 921.851 210.101 922.09 210.118Z" fill="#263238"/>
<path d="M922.635 193.085C922.874 193.085 923.044 194.994 922.993 197.347C922.942 199.7 922.704 201.61 922.465 201.593C922.226 201.593 922.056 199.666 922.107 197.33C922.158 194.994 922.397 193.085 922.635 193.085Z" fill="#263238"/>
<path d="M922.516 176.036C922.755 176.036 922.994 177.928 923.028 180.298C923.062 182.651 922.891 184.56 922.653 184.56C922.414 184.56 922.175 182.651 922.141 180.298C922.107 177.945 922.277 176.036 922.516 176.036Z" fill="#263238"/>
<path d="M922.258 165.55C922.497 165.55 922.718 165.976 922.735 166.522C922.752 167.067 922.565 167.51 922.326 167.51C922.087 167.51 921.866 167.084 921.849 166.539C921.832 165.993 922.019 165.55 922.258 165.55Z" fill="#263238"/>
<path d="M959.677 114.935L843.781 127.524L852.268 205.65L968.164 193.06L959.677 114.935Z" fill="#939DF4"/>
<path d="M852.167 205.719V205.685L843.676 127.53H843.71L959.651 114.947V114.981L968.142 193.136H968.108L852.167 205.719ZM843.778 127.598L852.252 205.633L968.057 193.051L959.583 115.015L843.778 127.598Z" fill="#263238"/>
<path d="M960.088 115.868C960.122 115.885 959.781 116.295 959.116 117.028C958.382 117.829 957.411 118.886 956.2 120.199C953.557 123.012 949.857 126.933 945.305 131.758C935.961 141.528 923.174 154.912 909.005 169.728L908.698 170.034L908.34 169.796C908.067 169.625 907.76 169.421 907.454 169.233C889.977 158.066 874.154 147.955 862.594 140.573C856.951 136.907 852.364 133.924 849.074 131.792C847.556 130.787 846.346 129.968 845.425 129.354C844.589 128.792 844.163 128.468 844.197 128.451C844.214 128.417 844.675 128.672 845.544 129.184C846.482 129.747 847.744 130.514 849.295 131.451C852.637 133.514 857.292 136.413 863.004 139.959C874.615 147.273 890.489 157.298 908.033 168.364C908.34 168.551 908.647 168.756 908.92 168.926L908.255 169.011C922.509 154.281 935.382 140.982 944.776 131.264C949.414 126.541 953.199 122.688 955.91 119.926C957.189 118.664 958.195 117.658 958.979 116.891C959.678 116.192 960.053 115.851 960.088 115.868Z" fill="#263238"/>
<path d="M852.681 206.571C852.443 206.4 859.996 194.756 869.544 180.536C879.109 166.317 887.055 154.928 887.293 155.099C887.532 155.269 879.979 166.914 870.431 181.133C860.865 195.352 852.92 206.741 852.681 206.571Z" fill="#263238"/>
<path d="M925.706 149.949C925.911 149.745 935.681 159.446 947.514 171.602C959.347 183.759 968.775 193.784 968.571 193.988C968.366 194.193 958.596 184.492 946.763 172.335C934.931 160.179 925.502 150.154 925.706 149.949Z" fill="#263238"/>
<path d="M430.389 351.814C430.628 351.78 430.986 352.684 431.191 353.843C431.395 355.003 431.344 355.974 431.105 356.026C430.867 356.06 430.509 355.156 430.304 353.997C430.116 352.82 430.15 351.849 430.389 351.814Z" fill="#263238"/>
<path d="M427.66 335.175C427.898 335.141 428.393 336.965 428.768 339.267C429.143 341.569 429.245 343.461 429.007 343.495C428.768 343.53 428.274 341.705 427.898 339.404C427.523 337.102 427.421 335.209 427.66 335.175Z" fill="#263238"/>
<path d="M425.222 318.5C425.46 318.466 425.921 320.308 426.245 322.609C426.569 324.911 426.637 326.804 426.381 326.838C426.125 326.872 425.682 325.03 425.358 322.729C425.051 320.427 424.983 318.535 425.222 318.5Z" fill="#263238"/>
<path d="M423.318 301.758C423.557 301.741 423.949 303.599 424.205 305.901C424.444 308.22 424.444 310.112 424.205 310.146C423.966 310.18 423.574 308.322 423.318 306.003C423.063 303.667 423.08 301.775 423.318 301.758Z" fill="#263238"/>
<path d="M422.141 284.929C422.379 284.912 422.686 286.788 422.823 289.106C422.959 291.425 422.857 293.318 422.618 293.335C422.379 293.352 422.072 291.476 421.936 289.158C421.8 286.839 421.902 284.946 422.141 284.929Z" fill="#263238"/>
<path d="M421.968 268.068C422.207 268.068 422.394 269.96 422.394 272.296C422.377 274.615 422.173 276.507 421.934 276.507C421.695 276.507 421.508 274.615 421.508 272.279C421.508 269.96 421.712 268.068 421.968 268.068Z" fill="#263238"/>
<path d="M423.025 251.257C423.264 251.291 423.315 253.184 423.11 255.485C422.923 257.804 422.565 259.662 422.326 259.645C422.087 259.628 422.036 257.736 422.241 255.417C422.428 253.098 422.769 251.24 423.025 251.257Z" fill="#263238"/>
<path d="M425.553 234.6C425.792 234.651 425.655 236.543 425.263 238.828C424.854 241.113 424.343 242.937 424.087 242.903C423.848 242.869 423.985 240.976 424.377 238.675C424.803 236.373 425.314 234.549 425.553 234.6Z" fill="#263238"/>
<path d="M429.865 218.318C430.104 218.404 429.746 220.262 429.098 222.478C428.433 224.695 427.7 226.451 427.478 226.383C427.24 226.315 427.581 224.456 428.263 222.223C428.893 219.989 429.627 218.233 429.865 218.318Z" fill="#263238"/>
<path d="M436.159 202.684C436.381 202.786 435.801 204.593 434.863 206.724C433.925 208.839 432.988 210.492 432.766 210.407C432.544 210.322 433.124 208.515 434.062 206.366C435 204.235 435.937 202.581 436.159 202.684Z" fill="#263238"/>
<path d="M444.373 187.987C444.577 188.123 443.776 189.845 442.583 191.823C441.389 193.8 440.247 195.318 440.025 195.198C439.803 195.079 440.605 193.357 441.815 191.362C443.026 189.368 444.168 187.85 444.373 187.987Z" fill="#263238"/>
<path d="M454.487 174.501C454.675 174.654 453.635 176.257 452.185 178.064C450.736 179.871 449.389 181.218 449.201 181.065C448.997 180.911 450.037 179.326 451.503 177.501C452.97 175.694 454.317 174.347 454.487 174.501Z" fill="#263238"/>
<path d="M466.469 162.652C466.623 162.839 465.361 164.254 463.656 165.823C461.951 167.392 460.416 168.517 460.246 168.346C460.075 168.176 461.337 166.761 463.059 165.175C464.781 163.589 466.316 162.464 466.469 162.652Z" fill="#263238"/>
<path d="M480.25 152.968C480.369 153.189 478.886 154.366 476.942 155.61C474.999 156.855 473.294 157.708 473.157 157.503C473.021 157.298 474.504 156.122 476.465 154.86C478.426 153.616 480.131 152.763 480.25 152.968Z" fill="#263238"/>
<path d="M495.587 146.063C495.655 146.301 493.984 147.171 491.819 148.006C489.671 148.842 487.829 149.353 487.727 149.132C487.625 148.91 489.313 148.04 491.495 147.188C493.678 146.335 495.519 145.824 495.587 146.063Z" fill="#263238"/>
<path d="M507.902 143.027C507.936 143.266 507.049 143.641 505.89 143.863C504.748 144.084 503.776 144.067 503.725 143.828C503.674 143.59 504.577 143.215 505.737 142.993C506.896 142.771 507.868 142.788 507.902 143.027Z" fill="#263238"/>
<path d="M417.236 79.0141L386.418 158.699L504.639 204.416L535.457 124.731L417.236 79.0141Z" fill="#939DF4"/>
<path d="M504.638 204.473L504.604 204.456L386.344 158.73L386.361 158.695L417.188 78.9722L417.222 78.9892L535.482 124.716L535.465 124.75L504.638 204.473ZM386.446 158.678L504.587 204.354L535.363 124.75L417.222 79.0745L386.446 158.678Z" fill="#263238"/>
<path d="M535.435 125.841C535.452 125.875 534.923 126.097 533.917 126.472C532.809 126.881 531.343 127.41 529.518 128.075C525.563 129.473 520.021 131.417 513.235 133.803C499.339 138.577 480.294 145.107 459.22 152.336L458.777 152.49L458.538 152.08C458.351 151.774 458.163 151.433 457.959 151.092C446.654 131.587 436.424 113.924 428.939 101.017C425.325 94.6579 422.375 89.4748 420.261 85.7751C419.306 84.036 418.539 82.655 417.959 81.598C417.447 80.6432 417.192 80.1317 417.226 80.0976C417.26 80.0806 417.584 80.5579 418.163 81.4786C418.794 82.5016 419.63 83.8485 420.653 85.5364C422.835 89.202 425.904 94.3169 429.655 100.591C437.209 113.463 447.541 131.058 458.965 150.495C459.169 150.836 459.357 151.177 459.544 151.484L458.862 151.228C479.987 144.118 499.05 137.708 512.997 133.019C519.834 130.769 525.409 128.944 529.399 127.632C531.257 127.052 532.741 126.574 533.883 126.216C534.889 125.944 535.435 125.79 535.435 125.841Z" fill="#263238"/>
<path d="M386.392 159.804C386.239 159.531 399.35 152.046 415.684 143.078C432.018 134.127 445.369 127.086 445.522 127.376C445.676 127.649 432.564 135.133 416.23 144.101C399.913 153.052 386.545 160.077 386.392 159.804Z" fill="#263238"/>
<path d="M485.219 141.578C485.526 141.493 490.113 155.729 495.467 173.375C500.82 191.039 504.929 205.428 504.622 205.514C504.316 205.599 499.729 191.362 494.358 173.716C489.004 156.053 484.912 141.663 485.219 141.578Z" fill="#263238"/>
<path d="M380.731 370.468C380.97 370.485 381.089 371.457 381.004 372.633C380.902 373.81 380.629 374.747 380.39 374.73C380.151 374.713 380.032 373.742 380.117 372.565C380.203 371.389 380.492 370.451 380.731 370.468Z" fill="#263238"/>
<path d="M381.153 353.129C381.391 353.129 381.596 355.073 381.579 357.459C381.579 359.863 381.374 361.807 381.119 361.79C380.88 361.79 380.675 359.846 380.692 357.459C380.709 355.09 380.914 353.146 381.153 353.129Z" fill="#263238"/>
<path d="M379.97 335.824C380.209 335.79 380.584 337.716 380.806 340.103C381.027 342.49 381.01 344.451 380.754 344.468C380.516 344.485 380.141 342.575 379.919 340.188C379.714 337.818 379.731 335.858 379.97 335.824Z" fill="#263238"/>
<path d="M377.092 318.723C377.33 318.672 377.893 320.53 378.353 322.9C378.814 325.253 379.001 327.197 378.746 327.248C378.507 327.282 377.927 325.424 377.484 323.071C377.024 320.735 376.836 318.791 377.092 318.723Z" fill="#263238"/>
<path d="M372.368 302.031C372.607 301.946 373.374 303.736 374.09 306.038C374.806 308.34 375.199 310.249 374.96 310.317C374.721 310.385 373.954 308.595 373.238 306.311C372.522 304.026 372.147 302.116 372.368 302.031Z" fill="#263238"/>
<path d="M365.737 286.022C365.958 285.92 366.93 287.608 367.919 289.79C368.908 291.989 369.522 293.831 369.3 293.933C369.079 294.018 368.09 292.33 367.118 290.165C366.129 287.983 365.515 286.124 365.737 286.022Z" fill="#263238"/>
<path d="M357.123 270.966C357.328 270.83 358.555 272.365 359.834 274.393C361.062 276.456 361.88 278.23 361.676 278.366C361.454 278.485 360.294 276.917 359.084 274.871C357.805 272.842 356.919 271.12 357.123 270.966Z" fill="#263238"/>
<path d="M346.505 257.276C346.693 257.106 348.091 258.47 349.626 260.328C351.16 262.169 352.251 263.789 352.064 263.942C351.876 264.096 350.478 262.732 348.944 260.891C347.409 259.049 346.318 257.43 346.505 257.276Z" fill="#263238"/>
<path d="M334.062 245.205C334.215 245.017 335.801 246.177 337.574 247.779C339.364 249.399 340.677 250.831 340.49 251.019C340.319 251.189 338.751 250.047 336.977 248.444C335.204 246.842 333.891 245.393 334.062 245.205Z" fill="#263238"/>
<path d="M319.993 235.078C320.112 234.874 321.851 235.777 323.846 237.107C325.841 238.437 327.359 239.682 327.205 239.886C327.069 240.091 325.33 239.17 323.352 237.857C321.374 236.527 319.856 235.283 319.993 235.078Z" fill="#263238"/>
<path d="M304.543 227.201C304.629 226.979 306.487 227.61 308.67 228.616C310.852 229.622 312.54 230.611 312.421 230.833C312.301 231.054 310.46 230.423 308.295 229.418C306.129 228.412 304.441 227.423 304.543 227.201Z" fill="#263238"/>
<path d="M288.005 221.967C288.056 221.728 289.983 222.035 292.302 222.666C294.62 223.296 296.445 223.995 296.377 224.217C296.308 224.456 294.382 224.132 292.08 223.518C289.778 222.904 287.954 222.205 288.005 221.967Z" fill="#263238"/>
<path d="M270.816 219.768C270.816 219.529 272.777 219.427 275.181 219.563C277.585 219.819 279.495 220.211 279.461 220.45C279.427 220.688 277.483 220.688 275.113 220.433C272.743 220.313 270.816 220.023 270.816 219.768Z" fill="#263238"/>
<path d="M253.528 220.961C253.494 220.723 255.386 220.296 257.79 220.007C260.177 219.717 262.138 219.7 262.155 219.938C262.172 220.177 260.263 220.603 257.893 220.893C255.523 221.166 253.579 221.2 253.528 220.961Z" fill="#263238"/>
<path d="M236.784 225.427C236.699 225.206 238.489 224.421 240.791 223.688C243.093 222.955 245.002 222.563 245.054 222.802C245.122 223.04 243.331 223.808 241.047 224.541C238.779 225.257 236.869 225.649 236.784 225.427Z" fill="#263238"/>
<path d="M221.164 232.947C221.028 232.742 222.648 231.634 224.762 230.491C226.876 229.349 228.683 228.599 228.786 228.821C228.888 229.042 227.285 230.15 225.188 231.276C223.108 232.401 221.301 233.151 221.164 232.947Z" fill="#263238"/>
<path d="M207.323 243.347C207.152 243.176 208.517 241.778 210.375 240.227C212.233 238.675 213.853 237.584 213.99 237.789C214.143 237.993 212.779 239.374 210.938 240.909C209.113 242.443 207.493 243.534 207.323 243.347Z" fill="#263238"/>
<path d="M195.981 256.441C195.776 256.305 196.714 254.566 198.181 252.656C199.664 250.747 201.096 249.417 201.284 249.57C201.471 249.741 200.346 251.309 198.88 253.202C197.43 255.077 196.186 256.577 195.981 256.441Z" fill="#263238"/>
<path d="M189.657 267.694C189.435 267.591 189.674 266.654 190.186 265.579C190.697 264.505 191.294 263.738 191.516 263.857C191.737 263.977 191.499 264.915 190.987 265.972C190.476 267.012 189.879 267.796 189.657 267.694Z" fill="#263238"/>
<path d="M81.3284 368.302C81.3113 368.063 82.232 367.739 83.3914 367.603C84.5509 367.466 85.5227 367.552 85.5568 367.79C85.5909 368.029 84.6702 368.336 83.4937 368.489C82.3173 368.643 81.3454 368.557 81.3284 368.302Z" fill="#263238"/>
<path d="M64.4404 366.393C64.5086 366.155 66.3841 366.547 68.6689 367.024C70.9536 367.502 72.8121 367.894 72.795 368.149C72.778 368.388 70.8172 368.388 68.4813 367.894C66.1454 367.399 64.3552 366.615 64.4404 366.393Z" fill="#263238"/>
<path d="M49.1729 358.84C49.3093 358.636 50.9802 359.573 52.9921 360.784C55.004 361.994 56.6238 363 56.5045 363.222C56.4022 363.444 54.5778 362.779 52.5317 361.551C50.5028 360.323 49.0364 359.045 49.1729 358.84Z" fill="#263238"/>
<path d="M36.4774 347.451C36.665 347.28 38.0631 348.593 39.717 350.264C41.3708 351.935 42.6496 353.35 42.4961 353.538C42.3427 353.725 40.757 352.583 39.0861 350.895C37.4152 349.207 36.2899 347.621 36.4774 347.451Z" fill="#263238"/>
<path d="M26.4634 333.64C26.668 333.52 27.8956 335.003 29.1744 336.964C30.4531 338.925 31.3397 340.63 31.1522 340.783C30.9476 340.937 29.737 339.436 28.4412 337.459C27.1283 335.481 26.2588 333.759 26.4634 333.64Z" fill="#263238"/>
<path d="M19.0912 318.245C19.3129 318.16 20.2506 319.847 21.1714 321.996C22.0921 324.161 22.6547 325.985 22.4331 326.105C22.2114 326.207 21.2737 324.536 20.353 322.354C19.4322 320.171 18.8696 318.33 19.0912 318.245Z" fill="#263238"/>
<path d="M14.1969 301.878C14.4356 301.826 15.1176 303.617 15.7144 305.901C16.3111 308.169 16.601 310.078 16.3622 310.147C16.1235 310.215 15.4415 308.425 14.8448 306.123C14.248 303.838 13.9582 301.929 14.1969 301.878Z" fill="#263238"/>
<path d="M11.228 285.049C11.4667 285.015 11.9612 286.874 12.3022 289.209C12.6602 291.545 12.7455 293.455 12.5068 293.506C12.2681 293.557 11.7907 291.682 11.4326 289.346C11.0745 287.01 10.9723 285.083 11.228 285.049Z" fill="#263238"/>
<path d="M9.58851 268.051C9.82721 268.034 10.1853 269.927 10.3728 272.28C10.5604 274.632 10.5263 276.559 10.2705 276.576C10.0318 276.593 9.67377 274.718 9.48621 272.348C9.29866 269.978 9.33276 268.068 9.58851 268.051Z" fill="#263238"/>
<path d="M8.87428 255.247C9.11298 255.23 9.36875 256.185 9.4199 257.361C9.47105 258.537 9.31761 259.492 9.0789 259.509C8.84019 259.526 8.58443 258.571 8.53328 257.395C8.48212 256.219 8.63557 255.264 8.87428 255.247Z" fill="#263238"/>
<path d="M73.0823 151.875L-68.6875 158.56L-64.1815 254.119L77.5883 247.434L73.0823 151.875Z" fill="#939DF4"/>
<path d="M-64.2331 254.156V254.105L-68.7344 158.509H-68.6832L73.1404 151.825V151.876L77.6417 247.473H77.5905L-64.2331 254.156ZM-68.6491 158.594L-64.1479 254.071L77.5394 247.387L73.0381 151.91L-68.6491 158.594Z" fill="#263238"/>
<path d="M73.5867 153.002C73.6208 153.036 73.1774 153.496 72.3249 154.332C71.3701 155.253 70.1084 156.463 68.5398 157.981C65.1127 161.203 60.3216 165.704 54.4563 171.228C42.3677 182.412 25.8461 197.706 7.5171 214.67L7.12492 215.028L6.69867 214.721C6.37471 214.482 6.01665 214.227 5.6586 213.971C-14.7505 199.104 -33.2159 185.652 -46.7196 175.814C-53.301 170.938 -58.6718 166.966 -62.5081 164.135C-64.2813 162.788 -65.6965 161.714 -66.7536 160.913C-67.7255 160.163 -68.2199 159.754 -68.1858 159.72C-68.1517 159.685 -67.6232 160.026 -66.6002 160.708C-65.4919 161.476 -64.0256 162.482 -62.2012 163.743C-58.2967 166.505 -52.8577 170.358 -46.174 175.098C-32.6191 184.85 -14.0685 198.2 6.42588 212.948C6.78394 213.204 7.14197 213.459 7.46592 213.698L6.64752 213.749C25.0617 196.904 41.6857 181.713 53.8425 170.597C59.8271 165.192 64.7205 160.811 68.2158 157.657C69.8526 156.207 71.1655 155.065 72.1544 154.196C73.0581 153.36 73.5526 152.968 73.5867 153.002Z" fill="#263238"/>
<path d="M29.2707 191.857C29.5435 191.619 40.6944 204.133 54.164 219.784C67.6508 235.453 78.3583 248.325 78.0855 248.564C77.8127 248.803 66.6789 236.288 53.1922 220.637C39.7055 204.968 28.9979 192.096 29.2707 191.857Z" fill="#263238"/>
</g>
<defs>
<clipPath id="clip0_3265_6471">
<rect width="1061" height="712" fill="white" transform="translate(-93)"/>
</clipPath>
</defs>
</svg>

);

export default IconEmailConfirm;
