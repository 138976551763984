import React from "react";

const IconPlaceVector = props => (
  <svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 16.0784C0 7.19856 7.16344 0 16 0C24.8366 0 32 7.19856 32 16.0784C32 24.9583 24.8366 32.1569 16
    32.1569C7.16344 32.1569 0 24.9583 0 16.0784Z" fill="#D0D1E1"/>
    <path d="M16 41L11.2 31.3529H20.8L16 41Z" fill="#D0D1E1"/>
  </svg>
);

export default IconPlaceVector;
