import React from "react";

const IconEyeHidden = (props) => (
  <svg
    width="20"
    height="20"
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6863 8.38669C18.9636 8.06248 19.4988 7.43357 19.8191 6.89842C20.616 5.48436 18.5613 4.20311 17.6746 5.61326C14.6004 10.9179 4.4324 11.0742 2.41287 5.79295C1.65505 4.21873 -0.46604 5.25389 0.0925533 6.71873C0.365991 7.39842 0.975366 8.34373 1.19021 8.58982C0.448022 8.92576 -0.364478 9.58982 0.186303 10.6523C1.16287 11.9687 2.2449 10.8359 3.09255 10.3633C3.4324 10.6289 4.23708 11.0312 4.58865 11.1875C4.00271 12.0664 3.32693 13.1523 4.51052 13.9062C5.94802 14.5039 6.3738 13.1172 7.02224 11.9531C7.30349 12.0117 8.26052 12.1523 8.7488 12.1601C8.7488 13.7539 8.51052 14.8711 9.9988 15.0039C11.4636 14.9023 11.2488 13.7539 11.2488 12.0898C11.7644 12.0508 12.741 11.8125 12.9793 11.7539C13.7722 13 14.0652 14.4882 15.6238 13.832C16.8074 13 16.1316 12.2148 15.3621 10.8789C15.6472 10.7422 16.4832 10.2656 16.6863 10.1211C17.7488 10.7109 18.9168 11.8359 19.823 10.4922C20.2605 9.67576 19.8738 8.92185 18.6863 8.38669Z"
      fill="#939DF4"
    />
  </svg>
);

export default IconEyeHidden;
