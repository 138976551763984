import axios from 'axios';

import { BASE_API_URL } from 'constants/apiUrls';
import { TOKEN_KEY } from 'constants/localStorage';

const getDefaultHeaders = () => {
  const defaultHeaders = {};
  const token = localStorage.getItem(TOKEN_KEY);

  if (token) {
    defaultHeaders.Authorization = "Bearer " + token;
  }

  return defaultHeaders;
};

const getAxiosInstance = () => axios.create({
  baseURL: BASE_API_URL,
  headers: {
    ...getDefaultHeaders()
  }
});

const makeRequest = (method, url, params) => {
  const axiosInstance = getAxiosInstance();
  return axiosInstance.request({
    url,
    method,
    ...params
  });
};

export const get = (url, params) => makeRequest('get', url, params);

export const post = (url, data, params) => makeRequest('post', url, { data, ...params });

export const put = (url, data, params) => makeRequest('put', url, { data, ...params });

export const patch = (url, data, params) => makeRequest('patch', url, { data, ...params });

export const del = (url, data, params) => makeRequest('delete', url, { data, ...params });
