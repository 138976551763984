import React, {useEffect} from "react";
import { inject } from "mobx-react";
import { HOUSE_VIEWS } from "constants/houses";
import Houses from "containers/Houses";
import BaseLayout from "components/Layouts/BaseLayout";
import HelmetIntl from "components/Meta/HelmetIntl";
import { formatSearchParams } from "utils/helpers/searchHelper";


const HousesPage = ({ housesStore })  => {
  useEffect(() => {
    housesStore.setView(HOUSE_VIEWS.topMatches);
  }, [])

  const handleGetHousesData = (criteriaData, isMapView, handleSuccess, handleError) => {
    const searchParams = { filters: formatSearchParams(criteriaData) };
    isMapView
      ? housesStore.getHousesMapRequest(searchParams, handleSuccess)
      : housesStore.getHousesRequest(searchParams, handleSuccess, handleError);
  };

  return (
    <BaseLayout>
      <HelmetIntl id="helmet.topMatchesTitle" />
      <Houses handleGetHousesData={handleGetHousesData} view={HOUSE_VIEWS.topMatches} />
    </BaseLayout>
  )
}

export default inject('housesStore')(HousesPage);
