import React from "react";

const IconGoogle = (props) => (
  <svg
    width="24"
    height="24"
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.64298 6.69073C8.01574 5.3064 9.91682 4.44739 12.0068 4.44941C13.9337 4.45129 15.6917 5.17473 17.0251 6.36437C17.2161 6.53482 17.5228 6.52362 17.7038 6.34259L20.15 3.89633C20.3492 3.69713 20.3437 3.37162 20.1367 3.18056C17.9564 1.168 15.0285 -0.0464503 11.8164 0.00136143C8.56901 0.0496908 5.6315 1.39863 3.49757 3.54531C3.25964 3.78466 3.53795 6.68221 3.53795 6.68221C3.53795 6.68221 5.30355 8.0415 6.64298 6.69073Z"
      fill="url(#paint0_linear_1284_2574)"
    />
    <path
      d="M6.64427 6.69072C8.01702 5.30639 9.91811 4.44738 12.0081 4.4494C13.935 4.45129 15.693 5.17472 17.0263 6.36437C17.2174 6.53482 17.524 6.52362 17.7051 6.34258L19.1089 4.93877C18.9866 4.84427 18.8661 4.74508 18.7484 4.64089C16.9183 3.02004 14.5371 1.98865 11.8938 2.01642C9.15246 2.04522 6.6902 3.16333 4.90982 4.95618C4.71514 5.15223 4.63867 6.68531 4.63867 6.68531C4.63867 6.68531 4.8671 6.66465 6.64427 6.69072Z"
      fill="url(#paint1_linear_1284_2574)"
    />
    <path
      d="M4.44989 11.9318C4.43107 14.056 5.28942 15.9798 6.68425 17.3628L5.61366 19.6732L3.53837 20.5087C1.35672 18.3388 0.00471826 15.336 1.23737e-05 12.0174C-0.00469351 8.72188 1.33319 5.72282 3.49836 3.54541L6.64378 6.69082C5.30448 8.04141 4.46778 9.89223 4.44989 11.9318Z"
      fill="url(#paint2_linear_1284_2574)"
    />
    <path
      d="M4.45037 11.9318C4.43155 14.0561 5.2899 15.9798 6.68473 17.3629L4.96473 19.0834C3.17932 17.3102 2.06073 14.8664 2.0179 12.1615C1.9732 9.34785 3.08237 6.79679 4.91014 4.95679L6.64426 6.6909C5.30496 8.04149 4.46826 9.89232 4.45037 11.9318Z"
      fill="url(#paint3_linear_1284_2574)"
    />
    <path
      d="M17.3224 17.369C15.9465 18.7323 14.0469 19.5637 11.9516 19.5504C9.89775 19.5375 8.03892 18.7042 6.68523 17.3623L3.53906 20.5085C5.70052 22.658 8.67605 23.9899 11.9628 24C15.2562 24.0101 18.2515 22.6558 20.4305 20.4773L19.5313 18.5546L17.3224 17.369Z"
      fill="url(#paint4_linear_1284_2574)"
    />
    <path
      d="M17.3214 17.3688C15.9454 18.7322 14.046 19.5638 11.9507 19.5506C9.89695 19.5376 8.03813 18.7043 6.68443 17.3623L4.96387 19.0829C6.76871 20.8759 9.25474 21.984 11.9996 21.984C14.7446 21.984 17.2305 20.8758 19.0354 19.0829L17.3214 17.3688Z"
      fill="url(#paint5_linear_1284_2574)"
    />
    <path
      d="M24.0005 10.7677C24.0005 10.5312 23.8087 10.3394 23.5721 10.3394H12.4023C12.1904 10.3394 12.0186 10.5112 12.0186 10.7231V14.4163C12.0186 14.6282 12.1904 14.8 12.4023 14.8H17.8574C18.4193 14.8 18.7682 15.4132 18.4792 15.895C18.1566 16.4328 17.7686 16.9257 17.3269 17.364C17.3252 17.3657 17.3241 17.3673 17.3224 17.369L20.4311 20.4777C20.5779 20.331 20.7268 20.1841 20.8769 20.0369C22.8193 17.8828 24.0005 15.0249 24.0005 11.9072V10.7677Z"
      fill="url(#paint6_linear_1284_2574)"
    />
    <path
      d="M21.479 15.1426C21.9181 13.8155 20.9346 12.447 19.5369 12.447H12.0176V14.4162C12.0176 14.6281 12.1894 14.7999 12.4013 14.7999H17.8564C18.4183 14.7999 18.7672 15.4131 18.4782 15.8949C18.1566 16.4309 17.77 16.9222 17.3301 17.3595C17.3271 17.3627 17.3246 17.3657 17.3214 17.3689L19.0353 19.0828C19.9464 18.1788 20.6833 17.0997 21.193 15.9007C21.2979 15.6527 21.3934 15.4 21.479 15.1426Z"
      fill="url(#paint7_linear_1284_2574)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1284_2574"
        x1="3.40138"
        y1="3.64442"
        x2="20.2957"
        y2="3.64442"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF637B" />
        <stop offset="1" stopColor="#F90217" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1284_2574"
        x1="16.1969"
        y1="10.1353"
        x2="5.58263"
        y2="-0.479068"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF637B" />
        <stop offset="1" stopColor="#F90217" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1284_2574"
        x1="0.285377"
        y1="8.79412"
        x2="6.81093"
        y2="15.3197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD833" />
        <stop offset="1" stopColor="#F2B631" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1284_2574"
        x1="8.29423"
        y1="15.3769"
        x2="1.32176"
        y2="8.40446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD833" />
        <stop offset="1" stopColor="#F2B631" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1284_2574"
        x1="10.9668"
        y1="18.9631"
        x2="14.6078"
        y2="22.604"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28A265" />
        <stop offset="1" stopColor="#28895E" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1284_2574"
        x1="16.651"
        y1="23.7342"
        x2="6.119"
        y2="13.2023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28A265" />
        <stop offset="1" stopColor="#28895E" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1284_2574"
        x1="17.1292"
        y1="11.7024"
        x2="22.7139"
        y2="17.2871"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7FAEF4" />
        <stop offset="1" stopColor="#4C8DF1" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1284_2574"
        x1="21.8052"
        y1="19.1152"
        x2="14.5551"
        y2="11.865"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7FAEF4" />
        <stop offset="1" stopColor="#4C8DF1" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconGoogle;
