export const ERRORS_MESSAGES = {
  "auth/email-already-in-use": "signUp.errors.emailExist",
  "auth/too-many-requests": "signUp.errors.tooManyAttempts",
  "auth/invalid-action-code": "signUp.errors.invalidLink",
  "auth/expired-action-code": "signUp.errors.expiredLink",
  "auth/user-disabled": "signUp.errors.disabledUser",
  "auth/user-not-found": "signUp.errors.userNotFound",
  "auth/wrong-password": "signUp.errors.wrongPassword",
  "auth/user-token-expired": "signUp.errors.userTokenExpired",
  "emailNotConfirmed": "signUp.errors.emailNotConfirmed",
}

export const SUCCESS_MESSAGES = {
  "auth/email-already-in-use": "profile.passwordСhanged",
  "auth/too-many-requests": "signUp.errors.tooManyAttempts",
  "auth/invalid-action-code": "signUp.errors.invalidLink",
  "auth/expired-action-code": "signUp.errors.expiredLink",
  "auth/user-disabled": "signUp.errors.disabledUser",
  "auth/user-not-found": "signUp.errors.userNotFound",
  "emailNotConfirmed": "signUp.errors.emailNotConfirmed",
}