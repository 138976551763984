import React from "react";

const IconInteriorAesthetics = (props) => (
  <svg
    width="32"
    height="28"
    viewBox="0 0 32 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.7266 17.4714H6.27353V6.68541C6.27353 3.38056 8.95262 0.701408 12.2575 0.701408H19.7427C23.0476 0.701408 25.7267 3.3805 25.7267 6.68541V17.4714H25.7266Z"
      fill="url(#paint0_linear_2_112)"
    />
    <path
      d="M6.54571 4.89605C6.3689 5.46107 6.27353 6.06204 6.27353 6.68535V17.4713H21.0925C19.9846 10.4014 13.9065 4.98101 6.54571 4.89605V4.89605Z"
      fill="url(#paint1_linear_2_112)"
    />
    <path
      d="M10.9078 17.4713H25.7268V6.68535C25.7268 6.06204 25.6314 5.46107 25.4546 4.89605C18.0938 4.98101 12.0158 10.4014 10.9078 17.4713V17.4713Z"
      fill="url(#paint2_linear_2_112)"
    />
    <path
      d="M17.2401 7.40363C16.9492 7.07309 16.5233 6.86427 16.0484 6.86427C15.1718 6.86427 14.4611 7.57493 14.4611 8.45154C14.4611 8.92646 14.67 9.35231 15.0005 9.6432L19.8528 14.4955C19.9665 14.4121 20.0884 14.3379 20.2191 14.2764C21.0804 13.8713 21.7465 13.12 22.0384 12.2019L17.2401 7.40363Z"
      fill="url(#paint3_linear_2_112)"
    />
    <path
      d="M16.0002 9.98974C16.8769 9.98974 17.5875 9.2791 17.5875 8.40248C17.5875 7.52586 16.8769 6.81522 16.0002 6.81522C15.1236 6.81522 14.413 7.52586 14.413 8.40248C14.413 9.2791 15.1236 9.98974 16.0002 9.98974Z"
      fill="url(#paint4_linear_2_112)"
    />
    <path
      d="M6.82074 27.2987H5.9691C5.67062 27.2987 5.42868 27.0568 5.42868 26.7583V25.7593H7.36117V26.7583C7.36117 27.0568 7.11916 27.2987 6.82074 27.2987V27.2987Z"
      fill="url(#paint5_linear_2_112)"
    />
    <path
      d="M26.0301 27.2987H25.1785C24.8801 27.2987 24.6381 27.0568 24.6381 26.7583V25.7593H26.5706V26.7583C26.5705 27.0568 26.3286 27.2987 26.0301 27.2987Z"
      fill="url(#paint6_linear_2_112)"
    />
    <path
      d="M26.3851 25.5724H5.6156C5.01965 25.5724 4.53651 25.0893 4.53651 24.4933V20.2099C4.53651 19.614 5.01965 19.1308 5.6156 19.1308H26.385C26.981 19.1308 27.4641 19.614 27.4641 20.2099V24.4933C27.4642 25.0893 26.9811 25.5724 26.3851 25.5724V25.5724Z"
      fill="url(#paint7_linear_2_112)"
    />
    <path
      d="M27.7745 20.3538C26.0555 17.3133 22.7934 15.2606 19.0514 15.2606H12.9477C9.20574 15.2606 5.94362 17.3133 4.22459 20.3538H27.7745Z"
      fill="url(#paint8_linear_2_112)"
    />
    <path
      d="M20.2357 25.5726H7.76697V13.1039L13.7937 19.1306L20.2357 25.5726Z"
      fill="url(#paint9_linear_2_112)"
    />
    <path
      d="M8.73731 15.542C8.60743 13.1974 6.63077 11.3453 4.23749 11.4172C1.94164 11.4862 0.0707099 13.3572 0.00175304 15.653C-0.0525215 17.4599 0.989926 19.0293 2.513 19.7458C3.49778 20.209 4.12649 23.5329 4.12649 24.6212C4.12649 25.6168 4.93364 26.424 5.92928 26.424H6.94174C7.93738 26.424 8.74453 25.6168 8.74453 24.6212V15.542H8.73731Z"
      fill="url(#paint10_linear_2_112)"
    />
    <path
      d="M5.94232 14.5153C5.5732 14.0959 5.03277 13.831 4.43017 13.831C3.31776 13.831 2.41605 14.7327 2.41605 15.8451C2.41605 16.4477 2.68102 16.9881 3.10047 17.3572L8.63967 22.8964C8.70788 22.7063 8.74534 22.5013 8.74534 22.2876V17.3183L5.94232 14.5153Z"
      fill="url(#paint11_linear_2_112)"
    />
    <path
      d="M4.37235 17.8018C5.48472 17.8018 6.38647 16.9 6.38647 15.7876C6.38647 14.6753 5.48472 13.7735 4.37235 13.7735C3.25999 13.7735 2.35823 14.6753 2.35823 15.7876C2.35823 16.9 3.25999 17.8018 4.37235 17.8018Z"
      fill="url(#paint12_linear_2_112)"
    />
    <path
      d="M23.2619 15.542C23.3918 13.1974 25.3685 11.3453 27.7618 11.4172C30.0576 11.4862 31.9285 13.3572 31.9975 15.653C32.0518 17.4599 31.0093 19.0293 29.4863 19.7458C28.5015 20.209 27.8728 23.5329 27.8728 24.6212C27.8728 25.6168 27.0656 26.424 26.07 26.424H25.0575C24.0619 26.424 23.2547 25.6168 23.2547 24.6212V15.542H23.2619Z"
      fill="url(#paint13_linear_2_112)"
    />
    <path
      d="M29.1971 14.5153C28.8279 14.0959 28.2875 13.831 27.6849 13.831C26.5725 13.831 25.6708 14.7327 25.6708 15.8451C25.6708 16.4477 25.9358 16.9881 26.3551 17.3572L29.0218 20.0239C29.1661 19.918 29.3209 19.824 29.4868 19.7459C30.5797 19.2319 31.425 18.2786 31.7953 17.1135L29.1971 14.5153Z"
      fill="url(#paint14_linear_2_112)"
    />
    <path
      d="M27.6271 17.8018C28.7395 17.8018 29.6412 16.9 29.6412 15.7876C29.6412 14.6753 28.7395 13.7735 27.6271 13.7735C26.5147 13.7735 25.613 14.6753 25.613 15.7876C25.613 16.9 26.5147 17.8018 27.6271 17.8018Z"
      fill="url(#paint15_linear_2_112)"
    />
    <path
      d="M27.7619 11.4172C27.3884 11.406 27.0251 11.4417 26.6773 11.5187V24.546H27.8739C27.9007 23.3881 28.5232 20.1988 29.4864 19.7457C31.0094 19.0292 32.0518 17.4599 31.9976 15.653C31.9286 13.3572 30.0577 11.4862 27.7619 11.4172Z"
      fill="url(#paint16_linear_2_112)"
    />
    <path
      d="M15.9817 23.0104C13.1676 25.8245 10.4907 27.71 10.0025 27.2218C9.51436 26.7336 11.3998 24.0567 14.2139 21.2426C17.0279 18.4286 18.7957 20.1964 15.9817 23.0104Z"
      fill="url(#paint17_linear_2_112)"
    />
    <path
      d="M23.401 23.0104C26.215 25.8245 28.892 27.71 29.3801 27.2218C29.8683 26.7336 27.9828 24.0567 25.1688 21.2426C22.3547 18.4286 20.5869 20.1964 23.401 23.0104V23.0104Z"
      fill="url(#paint18_linear_2_112)"
    />
    <path
      d="M30.5199 23.297H10.2597C9.9734 23.297 9.74137 23.0649 9.74137 22.7786V21.5223H31.0382V22.7786C31.0382 23.0649 30.8062 23.297 30.5199 23.297Z"
      fill="url(#paint19_linear_2_112)"
    />
    <path
      d="M31.734 21.8418H8.93938C8.79287 21.8418 8.67409 21.723 8.67409 21.5765V20.6048C8.67409 20.4583 8.79287 20.3395 8.93938 20.3395H31.734C31.8806 20.3395 31.9993 20.4583 31.9993 20.6048V21.5765C31.9993 21.723 31.8806 21.8418 31.734 21.8418Z"
      fill="url(#paint20_linear_2_112)"
    />
    <path
      d="M27.1676 10.6049H13.5072C13.2079 10.6049 12.9654 10.8474 12.9654 11.1467V19.6088C12.9654 20.0123 13.2924 20.3394 13.696 20.3394H26.9788C27.3823 20.3394 27.7094 20.0123 27.7094 19.6088V11.1467C27.7094 10.8474 27.4668 10.6049 27.1676 10.6049V10.6049Z"
      fill="url(#paint21_linear_2_112)"
    />
    <path
      d="M20.3371 16.7756C21.057 16.7756 21.6405 16.192 21.6405 15.4722C21.6405 14.7523 21.057 14.1687 20.3371 14.1687C19.6172 14.1687 19.0336 14.7523 19.0336 15.4722C19.0336 16.192 19.6172 16.7756 20.3371 16.7756Z"
      fill="url(#paint22_linear_2_112)"
    />
    <path
      d="M12.9654 17.2785V19.6089C12.9654 20.0124 13.2924 20.3395 13.696 20.3395H26.9788C27.3823 20.3395 27.7094 20.0124 27.7094 19.6089V17.2785H12.9654Z"
      fill="url(#paint23_linear_2_112)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_112"
        x1="12.6834"
        y1="4.01868"
        x2="17.1051"
        y2="13.9103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE99A0" />
        <stop offset="1" stopColor="#FD4755" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_112"
        x1="14.4461"
        y1="10.5567"
        x2="20.2901"
        y2="4.71258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD4755" stopOpacity="0" />
        <stop offset="0.253" stopColor="#F5404E" stopOpacity="0.253" />
        <stop offset="0.6583" stopColor="#DD2D3A" stopOpacity="0.658" />
        <stop offset="1" stopColor="#C41926" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_112"
        x1="17.8216"
        y1="10.8241"
        x2="13.0492"
        y2="6.05169"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD4755" stopOpacity="0" />
        <stop offset="1" stopColor="#C41926" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2_112"
        x1="19.0434"
        y1="11.4466"
        x2="14.4401"
        y2="6.84327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD4755" stopOpacity="0" />
        <stop offset="1" stopColor="#C41926" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2_112"
        x1="14.6801"
        y1="7.08232"
        x2="17.08"
        y2="9.48219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE99A0" />
        <stop offset="1" stopColor="#FD4755" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2_112"
        x1="6.17039"
        y1="25.8143"
        x2="6.58927"
        y2="26.7514"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#537983" />
        <stop offset="1" stopColor="#3E5959" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2_112"
        x1="25.3798"
        y1="25.8143"
        x2="25.7986"
        y2="26.7514"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#537983" />
        <stop offset="1" stopColor="#3E5959" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2_112"
        x1="14.069"
        y1="18.0311"
        x2="17.0844"
        y2="24.7766"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE99A0" />
        <stop offset="1" stopColor="#FD4755" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2_112"
        x1="15.9995"
        y1="16.6593"
        x2="15.9995"
        y2="20.1245"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE99A0" />
        <stop offset="1" stopColor="#FD4755" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2_112"
        x1="16.4036"
        y1="23.9491"
        x2="5.44201"
        y2="18.097"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD4755" stopOpacity="0" />
        <stop offset="1" stopColor="#C41926" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2_112"
        x1="2.26246"
        y1="15.4993"
        x2="10.6147"
        y2="23.8516"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE99A0" />
        <stop offset="1" stopColor="#FD4755" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2_112"
        x1="7.96058"
        y1="19.3754"
        x2="3.68319"
        y2="15.098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD4755" stopOpacity="0" />
        <stop offset="1" stopColor="#C41926" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2_112"
        x1="2.69718"
        y1="14.1125"
        x2="5.74245"
        y2="17.1578"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE99A0" />
        <stop offset="1" stopColor="#FD4755" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_2_112"
        x1="21.6936"
        y1="13.7429"
        x2="31.3042"
        y2="23.3535"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE99A0" />
        <stop offset="1" stopColor="#FD4755" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_2_112"
        x1="30.3855"
        y1="18.5457"
        x2="27.1152"
        y2="15.2753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD4755" stopOpacity="0" />
        <stop offset="1" stopColor="#C41926" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_2_112"
        x1="25.9519"
        y1="14.1125"
        x2="28.9972"
        y2="17.1578"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE99A0" />
        <stop offset="1" stopColor="#FD4755" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_2_112"
        x1="30.8262"
        y1="17.9807"
        x2="27.2427"
        y2="17.9807"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD4755" stopOpacity="0" />
        <stop offset="1" stopColor="#C41926" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_2_112"
        x1="12.6889"
        y1="22.6959"
        x2="14.2925"
        y2="24.2995"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CA6E59" />
        <stop offset="1" stopColor="#883F2E" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_2_112"
        x1="26.6955"
        y1="22.6959"
        x2="25.0919"
        y2="24.2995"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CA6E59" />
        <stop offset="1" stopColor="#883F2E" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_2_112"
        x1="20.3898"
        y1="24.5446"
        x2="20.3898"
        y2="21.5204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#883F2E" />
        <stop offset="1" stopColor="#6D3326" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_2_112"
        x1="20.3367"
        y1="20.6317"
        x2="20.3367"
        y2="21.8623"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CA6E59" />
        <stop offset="1" stopColor="#883F2E" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_2_112"
        x1="18.7322"
        y1="11.8278"
        x2="22.4731"
        y2="20.1486"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EEF5F6" />
        <stop offset="1" stopColor="#CBE5E8" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_2_112"
        x1="21.834"
        y1="16.9691"
        x2="19.6615"
        y2="14.7966"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EEF5F6" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_2_112"
        x1="20.3374"
        y1="18.5988"
        x2="20.3374"
        y2="20.3932"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBE5E8" stopOpacity="0" />
        <stop offset="1" stopColor="#7CA1B1" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconInteriorAesthetics;
