import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.scss";

const IconView = props => {
  const { customIconClass, customWrapperClass, icon } = props;
  const Icon = icon;
  return (
    <div className={classNames("icon-wrapper", customWrapperClass)}>
      <Icon className={classNames("icon", customIconClass)} />
    </div>
  );
};

IconView.propTypes = {
  customIconClass: PropTypes.string,
  customWrapperClass: PropTypes.string,
  icon: PropTypes.any,
};

export default IconView;
