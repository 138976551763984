import React from "react";

const IconInputMain = (props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3227 2.72273C12.5815 1.94625 11.8428 1.20753 11.0663 1.46636L1.16162 4.76793C0.461149 5.00143 0.251281 5.89044 0.773382 6.41254L3.06632 8.70548L7.30257 6.48649L5.08358 10.7227L7.37653 13.0157C7.89863 13.5378 8.78765 13.3279 9.02114 12.6275L12.3227 2.72273Z"
      fill="#282C4D"
    />
    <circle cx="1.5" cy="12.2627" r="1.5" fill="#282C4D" />
  </svg>
);

export default IconInputMain;
