import React from "react";

const IconMap = (props) => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.9987 19.1667C6.20388 19.173 5.42406 18.9502 4.75248 18.5251C4.08091 18.0999 3.546 17.4903 3.2117 16.7692C1.30086 12.6884 0.332031 9.54228 0.332031 7.41828C0.353513 5.66431 1.06535 3.98947 2.31326 2.75675C3.56116 1.52403 5.2446 0.832764 6.9987 0.832764C8.7528 0.832764 10.4362 1.52403 11.6841 2.75675C12.932 3.98947 13.6439 5.66431 13.6654 7.41828C13.6654 9.54228 12.6965 12.6884 10.7857 16.7692C10.4514 17.4903 9.91648 18.0999 9.24491 18.5251C8.57333 18.9502 7.79352 19.173 6.9987 19.1667ZM6.9987 2.50003C5.68391 2.49072 4.41912 3.0033 3.48177 3.92533C2.54443 4.84735 2.01107 6.10352 1.9987 7.41828C1.9987 9.26961 2.94036 12.2591 4.72128 16.0629C4.92641 16.493 5.24918 16.8563 5.65223 17.1107C6.05527 17.365 6.52211 17.5 6.9987 17.5C7.47528 17.5 7.94212 17.365 8.34517 17.1107C8.74821 16.8563 9.07098 16.493 9.27611 16.0629C11.057 12.2591 11.9987 9.26961 11.9987 7.41828C11.9863 6.10352 11.453 4.84735 10.5156 3.92533C9.57827 3.0033 8.31348 2.49072 6.9987 2.50003ZM6.9987 10C6.50424 10 6.02089 9.85341 5.60977 9.5787C5.19865 9.304 4.87822 8.91355 4.689 8.45674C4.49978 7.99992 4.45027 7.49726 4.54673 7.0123C4.6432 6.52735 4.8813 6.08189 5.23093 5.73226C5.58056 5.38263 6.02602 5.14453 6.51097 5.04807C6.99592 4.9516 7.49859 5.00111 7.95541 5.19033C8.41222 5.37955 8.80267 5.69998 9.07737 6.1111C9.35207 6.52223 9.4987 7.00558 9.4987 7.50003C9.49797 8.16285 9.23434 8.79831 8.76566 9.26699C8.29698 9.73568 7.66151 9.9993 6.9987 10ZM6.9987 6.6667C6.83388 6.6667 6.67276 6.71557 6.53572 6.80714C6.39868 6.89871 6.29187 7.02885 6.2288 7.18113C6.16572 7.3334 6.14922 7.50095 6.18138 7.6626C6.21353 7.82425 6.2929 7.97274 6.40944 8.08928C6.52599 8.20583 6.67447 8.2852 6.83612 8.31735C6.99777 8.3495 7.16533 8.333 7.3176 8.26993C7.46987 8.20686 7.60002 8.10005 7.69159 7.96301C7.78316 7.82596 7.83203 7.66485 7.83203 7.50003C7.83174 7.2791 7.74385 7.06731 7.58764 6.91109C7.43142 6.75487 7.21962 6.66698 6.9987 6.6667Z"
    />
  </svg>
);

export default IconMap;
