import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { IconHiHomeSmall } from "components/Svgs";
import { getAmountOfDaysOnHiHome } from "utils/helpers/housesHelper/getter";
import "./styles.scss";

const DaysOnHiHome = props => {
  const { customClass, customIcon, customIconClass, propertyItem, tooltipPlacement } = props;
  const amountOfDaysOnHiHome = getAmountOfDaysOnHiHome(propertyItem);
  const Icon = customIcon || IconHiHomeSmall;
  return (
    <div className={classNames("days-number", customClass)}>
      <Tooltip
        placement={tooltipPlacement}
        title={
          <FormattedMessage
            id="houses.listedOnHiHome"
            values={{ number: amountOfDaysOnHiHome }}
          />
        }
      >
        <Icon className={classNames("days-number__icon", customIconClass)} />
        <FormattedMessage
          id="houses.daysMessage"
          values={{ number: amountOfDaysOnHiHome }}
        />
      </Tooltip>
    </div>
  );
};

DaysOnHiHome.defaultProps = {
  tooltipPlacement: "bottomRight",
};

DaysOnHiHome.propTypes = {
  customClass: PropTypes.string,
  customIcon: PropTypes.any,
  customIconClass: PropTypes.string,
  propertyItem: PropTypes.object,
  tooltipPlacement: PropTypes.string,
};

export default DaysOnHiHome;
