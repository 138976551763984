import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import {
  cloneDeep as lodashCloneDeep,
  findIndex as lodashFindIndex,
  remove as lodashRemove
} from "lodash";
import {
  getNoContentInfo,
  handleNewLocationSelect,
  handleQueryTextChange,
  MAX_LOCATIONS_NUMBER_FOR_SEARCH,
  MIN_SYMBOLS_FOR_GEO_SEARCH
} from "utils/helpers/locationSearchHelper";

const MultipleSearchLocationField = props => {
  const { form, maxTagCount, placeholder, value: locationsFormValue, onValuesChange } = props;
  const [options, setOptions] = useState([]); // list of possible options to select
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]); // list of selected options
  const [selectedValues, setSelectedValues] = useState([]); // list of selected values related to options

  useEffect(() => {
    let locationValues = lodashCloneDeep(selectedValues);
    let locationOptions = lodashCloneDeep(options);
    locationsFormValue.forEach(location => {
      locationOptions.push({
        disabled: false,
        label: location.address,
        value: location.address,
        ...location
      });
      locationValues.push(location.address);
    });
    setOptions(locationOptions);
    setSelectedOptions(locationOptions);
    setSelectedValues(locationValues);
  }, []);

  const onBlur = () => setOptions(selectedOptions);

  const onDeselect = removedValue => {
    const newSelectedValues = lodashCloneDeep(selectedValues);
    lodashRemove(newSelectedValues, value => value === removedValue);
    setSelectedValues(newSelectedValues);

    const newLocations = lodashCloneDeep(locationsFormValue) || [];
    lodashRemove(newLocations, item => item.address === removedValue);
    form.setFieldsValue({ locations: newLocations });
    onValuesChange({ locations: newLocations });

    const newSelectedOptions = lodashCloneDeep(selectedOptions);
    lodashRemove(newSelectedOptions, option => option.value === removedValue);
    setSelectedOptions(newSelectedOptions);

    // Enable options select when the number of selected options < 10
    if (selectedValues.length === MAX_LOCATIONS_NUMBER_FOR_SEARCH) {
      const enabledOptions = options.map(option => ({ ...option, disabled: false }));
      setOptions(enabledOptions);
    }
  };

  const onSelect = (chosenValue, location) => {
    handleNewLocationSelect(form, locationsFormValue, location, _, onValuesChange);

    // Update list of selected values with new value
    const updateValues = lodashCloneDeep(selectedValues);
    updateValues.push(chosenValue);
    setSelectedValues(updateValues);

    const newSelectedOptions = lodashCloneDeep(selectedOptions);
    const newSelectedOptionIndex = lodashFindIndex(options, { value: chosenValue });
    const newSelectedOption = options[newSelectedOptionIndex];
    if (!newSelectedOptions.includes(newSelectedOption)) {
      newSelectedOptions.push(newSelectedOption);
      setSelectedOptions(newSelectedOptions);
    }

    // Disable options select when number of selected options = 10
    if (newSelectedOptions.length === MAX_LOCATIONS_NUMBER_FOR_SEARCH) {
      const disabledOptions = options.map(option => ({ ...option, disabled: !updateValues.includes(option.value) }));
      setOptions(disabledOptions);
    }
  };

  const onSearch = queryValue => {
    if (queryValue && queryValue.length > MIN_SYMBOLS_FOR_GEO_SEARCH) {
      setIsLoading(true);
      handleQueryTextChange(queryValue, setOptions, setIsLoading, locationsFormValue);
    }
    setOptions(selectedOptions);
  };

  return (
    <Select
      dropdownClassName={props.dropdownClassName}
      maxTagCount={maxTagCount}
      mode="multiple"
      notFoundContent={getNoContentInfo(isLoading, null, true)}
      options={options}
      placeholder={placeholder}
      value={selectedValues}
      getPopupContainer={trigger => trigger.parentNode}
      onBlur={onBlur}
      onDeselect={onDeselect}
      onSearch={onSearch}
      onSelect={onSelect}
    />
  );
};

MultipleSearchLocationField.defaultProps = {
  maxTagCount: "responsive",
};

MultipleSearchLocationField.propTypes = {
  dropdownClassName: PropTypes.string,
  form: PropTypes.object,
  maxTagCount: PropTypes.any,
  placeholder: PropTypes.any,
  onValuesChange: PropTypes.func,
};

export default MultipleSearchLocationField;
