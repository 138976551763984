import React from "react";
import { FormattedMessage } from "react-intl";
import {
  AlmostThereStep,
  BasicCriteriaStep,
  CalculatingStep,
  LocationsStep,
  OtherCriteriaStep,
  PriorityStep,
  WelcomeStep
} from "containers/Onboarding/Steps";
import {
  getStepsNumber,
  isDisabledBasicCriteriaStep,
  isDisabledLocationsStep,
  isDisabledPriorityStep,
} from "utils/helpers/onboardingHelper";

const ONBOARDING_STEPS = [
  {
    content: WelcomeStep,
    layoutProps: {
      isShowBackButton: false,
      nextButtonCustomText: <FormattedMessage id="onboarding.letsGo" />,
      nextButtonDisabled: false,
    },
  },
  {
    checkNextButtonDisabled: formData => isDisabledLocationsStep(formData),
    content: LocationsStep,
    formName: "OnboardingLocationsForm",
    isShowProgressBar: true,
    progressBarProps: {
      currentStepNumber: 1,
      currentSubStepNumber: 0,
    },
  },
  {
    checkNextButtonDisabled: formData => isDisabledBasicCriteriaStep(formData),
    content: BasicCriteriaStep,
    isShowProgressBar: true,
    progressBarProps: {
      currentStepNumber: 1,
      currentSubStepNumber: 1,
      mode: "required",
    },
  },
  {
    content: OtherCriteriaStep,
    isShowProgressBar: true,
    progressBarProps: {
      currentStepNumber: 1,
      currentSubStepNumber: 5,
      mode: "additional",
      nextButtonDisabled: false,
    },
  },
  {
    content: AlmostThereStep,
    isShowProgressBar: true,
    layoutProps: {
      isShowBackButton: true,
      isShowProgressBar: true,
      nextButtonCustomText: <FormattedMessage id="onboarding.letsDoThis" />,
      nextButtonDisabled: false,
    },
    progressBarProps: {
      currentStepNumber: 2,
      currentSubStepNumber: 0,
    },
  },
  {
    checkNextButtonDisabled: formData => isDisabledPriorityStep(formData),
    content: PriorityStep,
    isShowProgressBar: true,
    layoutProps: {
      bottomHint: <FormattedMessage id="onboarding.priorityStepBottomHint" />,
      isExtendedContent: true,
    },
    progressBarProps: {
      currentStepNumber: 2,
      currentSubStepNumber: 0,
    },
  },
  {
    content: CalculatingStep,
    isShowProgressBar: true,
    layoutProps: {
      isHideFooter: true,
      isShowBackButton: false,
    },
    progressBarProps: {
      currentStepNumber: 2,
      currentSubStepNumber: 23,
      isLastStep: true,
    },
  }
];

const PROGRESS_BAR_STEPS = onboardingData => [
  { index: 0, title: null, subTitle: null, description: null, subStepsNumber: 1 },
  { index: 1, title: null, subTitle: null, description: null, subStepsNumber: 6 },
  { index: 2, title: null, subTitle: null, description: null, subStepsNumber: getStepsNumber(onboardingData, 2) }
];

export { ONBOARDING_STEPS, PROGRESS_BAR_STEPS };
