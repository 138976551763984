import React from "react";

const IconSingleStory = (props) => (
  <svg
    width="36"
    height="16"
    viewBox="0 0 36 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.8433 14.3145C30.0069 14.3145 31.7608 12.4357 31.7608 10.118C31.7608 7.80026 30.0069 5.92139 27.8433 5.92139C25.6797 5.92139 23.9258 7.80026 23.9258 10.118C23.9258 12.4357 25.6797 14.3145 27.8433 14.3145Z"
      fill="url(#paint0_linear_716_2496)"
    />
    <path
      d="M36.0001 14.0118C36.0001 14.6382 35.8894 15.2362 35.6875 15.7856H27.2657C27.0638 15.2362 26.9531 14.6382 26.9531 14.0118C26.9531 13.4017 27.0582 12.8186 27.2504 12.2812C27.9006 10.4589 29.5477 9.16602 31.4766 9.16602C33.4054 9.16602 35.0526 10.4587 35.7027 12.2812C35.8952 12.8186 36.0001 13.4017 36.0001 14.0118Z"
      fill="url(#paint1_linear_716_2496)"
    />
    <path
      d="M29.2824 0.213867H6.71875V15.7857H29.2824V0.213867Z"
      fill="url(#paint2_linear_716_2496)"
    />
    <path
      d="M29.2822 1.28564V8.70757H11.9512L6.71875 3.10252V1.28564H29.2822Z"
      fill="url(#paint3_linear_716_2496)"
    />
    <path
      d="M29.8667 0.72699V2.70268C29.8667 2.99628 29.6445 3.23436 29.3704 3.23436H6.63304C6.35897 3.23436 6.13672 2.99628 6.13672 2.70268V0.72699C6.13672 0.433392 6.35897 0.195313 6.63304 0.195313H29.3704C29.6445 0.195233 29.8667 0.433313 29.8667 0.72699Z"
      fill="url(#paint4_linear_716_2496)"
    />
    <path
      d="M21.7707 14.1117H12.0222L9.16444 11.0503C9.16369 11.0503 9.16369 11.0495 9.16287 11.0486C9.14815 11.0387 9.13343 11.0279 9.11952 11.0171C9.11796 11.0163 9.11648 11.0146 9.11491 11.0137C9.1025 11.0029 9.09015 10.9921 9.07855 10.9822C9.06383 10.9697 9.05075 10.9565 9.03751 10.9424C9.02509 10.9299 9.01357 10.9175 9.00189 10.9042C8.98948 10.8909 8.97869 10.8769 8.96702 10.8628C8.95535 10.8487 8.94457 10.8338 8.93445 10.818C8.92434 10.8031 8.9143 10.7874 8.90501 10.7716C8.88568 10.74 8.86865 10.7069 8.85311 10.6729C8.83839 10.6389 8.82441 10.6033 8.81362 10.5668C8.80745 10.5486 8.80277 10.5303 8.79816 10.5121C8.78968 10.4797 8.78344 10.4474 8.77957 10.4142C8.77726 10.4001 8.77496 10.386 8.77414 10.3711C8.77258 10.3587 8.77184 10.3454 8.77109 10.3321C8.77035 10.3147 8.76953 10.2973 8.76953 10.2799V5.65797C8.76953 5.1513 9.15425 4.73926 9.62715 4.73926H12.7358C12.7528 4.73926 12.7698 4.74006 12.7868 4.74093C12.7993 4.74173 12.8116 4.7426 12.8232 4.74428C12.8364 4.74507 12.8487 4.74675 12.8619 4.74921C12.8773 4.75168 12.8929 4.75415 12.9083 4.7583C12.9237 4.76164 12.9393 4.76491 12.9539 4.76992C12.9718 4.77486 12.9888 4.7798 13.0058 4.78649C13.0399 4.79812 13.0732 4.81302 13.1049 4.82879C13.1366 4.84536 13.1675 4.8636 13.197 4.88439C13.2124 4.89426 13.2264 4.9051 13.2411 4.91673C13.2542 4.9266 13.2674 4.93831 13.2806 4.94906C13.2968 4.96316 13.3123 4.97806 13.327 4.99383C13.3378 5.00466 13.3486 5.01621 13.3587 5.02784C13.3704 5.04035 13.3812 5.05357 13.392 5.06679C13.3936 5.06926 13.3951 5.07173 13.3974 5.0742C13.4183 5.10152 13.4377 5.1298 13.4547 5.15879C13.4547 5.15879 13.4563 5.15959 13.4563 5.16046L13.4331 5.18038L15.3062 7.18698L15.8202 7.73754L16.2916 8.24254L16.9527 8.95073L18.9459 11.0859L19.4606 11.6373L21.7707 14.1117Z"
      fill="url(#paint5_linear_716_2496)"
    />
    <path
      d="M12.7376 10.658H9.62849C9.43241 10.658 9.27344 10.4878 9.27344 10.2777V5.65817C9.27344 5.44813 9.43234 5.27783 9.62849 5.27783H12.7376C12.9337 5.27783 13.0927 5.44805 13.0927 5.65817V10.2777C13.0927 10.4877 12.9337 10.658 12.7376 10.658Z"
      fill="#503837"
    />
    <path
      d="M13.5105 5.26313C13.4948 5.22761 13.4766 5.19304 13.4566 5.16014L13.455 5.15926C13.4376 5.12995 13.4185 5.10152 13.3978 5.07404C13.3954 5.07229 13.3936 5.06958 13.392 5.06695C13.3812 5.05365 13.3704 5.04034 13.3588 5.02784C13.3489 5.01621 13.3381 5.00474 13.3273 4.99407C13.3124 4.97806 13.2966 4.96292 13.2809 4.94882C13.2676 4.93815 13.2545 4.9266 13.2411 4.9168C13.2262 4.90517 13.2129 4.89458 13.1972 4.88391C13.1681 4.86351 13.1367 4.84575 13.1051 4.82887C13.0736 4.81286 13.0397 4.79772 13.0057 4.78625C12.9891 4.78004 12.9717 4.77462 12.9542 4.77024C12.9393 4.7649 12.9235 4.7614 12.9086 4.75869C12.8929 4.75423 12.8771 4.7516 12.8622 4.74897C12.8489 4.74635 12.8366 4.74547 12.8233 4.74451C12.8117 4.74276 12.7992 4.74189 12.7868 4.74101C12.7702 4.74013 12.7528 4.73926 12.7353 4.73926H9.62671C9.15417 4.73926 8.76953 5.1513 8.76953 5.65837V10.2804C8.76953 10.2973 8.77035 10.3151 8.77117 10.332C8.77199 10.3453 8.7728 10.3586 8.77362 10.3711C8.77444 10.3862 8.77689 10.4004 8.77942 10.4146C8.78358 10.4475 8.78938 10.4803 8.79764 10.5123C8.80262 10.531 8.8076 10.5487 8.8134 10.5674C8.82418 10.6038 8.83824 10.6394 8.85318 10.6731C8.86813 10.7068 8.88553 10.7405 8.90464 10.7717C8.91371 10.7877 8.92456 10.8037 8.93453 10.8187C8.94449 10.8338 8.95527 10.8489 8.96687 10.8631C8.97847 10.8774 8.98925 10.8915 9.00167 10.9048C9.01327 10.9182 9.02487 10.9306 9.03729 10.9431C9.0506 10.9565 9.06376 10.9697 9.07789 10.9822C9.08948 10.9929 9.1019 11.0035 9.11439 11.0142C9.11603 11.0151 9.11767 11.0168 9.11938 11.0177C9.1335 11.0284 9.14756 11.0391 9.16243 11.0489C9.16325 11.0497 9.16325 11.0506 9.16406 11.0506C9.29753 11.1439 9.45584 11.1962 9.62663 11.1962H12.7352C13.2086 11.1962 13.5932 10.7842 13.5932 10.278V5.65853C13.5934 5.51706 13.5635 5.38309 13.5105 5.26313ZM12.5887 10.1196H9.77438V5.81632H12.5887V10.1196Z"
      fill="white"
    />
    <path
      d="M29.2819 7.30804V14.1111H25.8848L23.0274 11.0501C23.0266 11.0501 23.0266 11.0492 23.0258 11.0484C23.0108 11.0386 22.9959 11.0279 22.9827 11.0172C22.9811 11.0163 22.9795 11.0146 22.9778 11.0137C22.9653 11.003 22.9529 10.9924 22.9412 10.9817C22.9263 10.9692 22.914 10.956 22.9006 10.9426C22.8882 10.9302 22.8766 10.9177 22.865 10.9043C22.8525 10.891 22.8418 10.8769 22.8302 10.8626C22.8186 10.8484 22.8078 10.8333 22.7978 10.8182C22.787 10.8031 22.7771 10.7871 22.7679 10.7712C22.7489 10.74 22.7314 10.7063 22.7156 10.6726C22.7015 10.6388 22.6875 10.6033 22.6767 10.5669C22.6701 10.5482 22.6659 10.5305 22.6609 10.5118C22.6527 10.4798 22.646 10.447 22.6427 10.4141C22.6402 10.3998 22.6377 10.3857 22.6369 10.3706C22.6353 10.3582 22.6344 10.3449 22.6336 10.3315C22.6336 10.3146 22.6328 10.2968 22.6328 10.28V5.65788C22.6328 5.15081 23.0175 4.73877 23.49 4.73877H26.5986C26.616 4.73877 26.6326 4.73965 26.65 4.74052C26.6625 4.7414 26.6749 4.74227 26.6866 4.74402C26.6999 4.7449 26.7122 4.74578 26.7247 4.74849C26.7405 4.75111 26.7562 4.75382 26.7711 4.7582C26.7869 4.76083 26.8026 4.76442 26.8175 4.76975C26.8349 4.77413 26.8523 4.77947 26.8689 4.78576C26.9029 4.79731 26.9361 4.81237 26.9684 4.82838C26.9999 4.84526 27.0305 4.86303 27.0604 4.88342C27.0754 4.89409 27.0895 4.90476 27.1044 4.91631C27.1177 4.92603 27.1308 4.93766 27.1441 4.94833C27.1599 4.96259 27.1757 4.97757 27.1905 4.99358C27.2013 5.00425 27.2121 5.0158 27.2221 5.02735C27.2337 5.03978 27.2444 5.05308 27.2552 5.06646C27.2569 5.06909 27.2585 5.0718 27.261 5.07355C27.2818 5.10103 27.3008 5.12946 27.3182 5.15878L27.3198 5.15965L27.2966 5.18004L27.3738 5.26264L29.1692 7.18697L29.2819 7.30804Z"
      fill="url(#paint6_linear_716_2496)"
    />
    <path
      d="M26.597 10.658H23.4879C23.2918 10.658 23.1328 10.4878 23.1328 10.2777V5.65817C23.1328 5.44813 23.2917 5.27783 23.4879 5.27783H26.597C26.793 5.27783 26.952 5.44805 26.952 5.65817V10.2777C26.952 10.4877 26.7931 10.658 26.597 10.658Z"
      fill="#503837"
    />
    <path
      d="M27.3738 5.26313C27.3572 5.22761 27.3398 5.19304 27.3199 5.16014L27.3183 5.15926C27.3009 5.12995 27.2818 5.10152 27.2611 5.07404C27.2586 5.07229 27.257 5.06958 27.2553 5.06695C27.2444 5.05365 27.2337 5.04034 27.2221 5.02784C27.2122 5.01621 27.2014 5.00474 27.1906 4.99407C27.1757 4.97806 27.1599 4.96292 27.1442 4.94882C27.1309 4.93815 27.1177 4.9266 27.1044 4.9168C27.0895 4.90517 27.0754 4.89458 27.0605 4.88391C27.0306 4.86351 27 4.84575 26.9684 4.82887C26.9361 4.81286 26.903 4.79772 26.8689 4.78625C26.8524 4.78004 26.835 4.77462 26.8176 4.77024C26.8026 4.7649 26.7869 4.7614 26.7712 4.75869C26.7562 4.75423 26.7405 4.7516 26.7248 4.74897C26.7124 4.74635 26.6999 4.74547 26.6866 4.74451C26.675 4.74276 26.6626 4.74189 26.6501 4.74101C26.6327 4.74013 26.6161 4.73926 26.5987 4.73926H23.49C23.0175 4.73926 22.6328 5.1513 22.6328 5.65837V10.2804C22.6328 10.2973 22.6336 10.3151 22.6336 10.332C22.6344 10.3453 22.6353 10.3586 22.6369 10.3711C22.6377 10.3862 22.6402 10.4004 22.6427 10.4146C22.646 10.4475 22.6527 10.4803 22.6609 10.5123C22.6659 10.531 22.67 10.5487 22.6767 10.5674C22.6875 10.6038 22.7015 10.6394 22.7156 10.6731C22.7314 10.7068 22.7488 10.7405 22.7679 10.7717C22.777 10.7877 22.787 10.8037 22.7978 10.8187C22.8078 10.8339 22.8186 10.8489 22.8302 10.8631C22.8418 10.8774 22.8525 10.8915 22.865 10.9048C22.8766 10.9182 22.8882 10.9306 22.9006 10.9431C22.914 10.9565 22.9263 10.9697 22.9412 10.9822C22.9528 10.9929 22.9653 11.0035 22.9778 11.0142C22.9794 11.0151 22.981 11.0168 22.9827 11.0177C22.996 11.0284 23.0109 11.0391 23.0258 11.0489C23.0266 11.0497 23.0266 11.0506 23.0274 11.0506C23.1601 11.1439 23.3192 11.1962 23.49 11.1962H26.5986C27.0719 11.1962 27.4566 10.7842 27.4566 10.278V5.65853C27.4567 5.51706 27.4261 5.38309 27.3738 5.26313ZM26.452 10.1196H23.6377V5.81632H26.4521L26.452 10.1196Z"
      fill="white"
    />
    <path
      d="M15.418 15.7858V6.62469C15.418 5.98468 15.9022 5.46582 16.4998 5.46582H19.5036C20.101 5.46582 20.5854 5.9846 20.5854 6.62469V15.7858H15.418Z"
      fill="#FFBF5C"
    />
    <path
      d="M21.2244 15.7856H19.9449V7.01954C19.9449 6.54187 19.58 6.15101 19.1341 6.15101H16.8677C16.4199 6.15101 16.0569 6.53988 16.0569 7.01954V15.7856H14.7773V6.1131C14.7773 5.37703 15.3343 4.78027 16.0215 4.78027H19.9803C20.6674 4.78027 21.2244 5.37695 21.2244 6.1131V15.7856Z"
      fill="#FFE7A5"
    />
    <path
      d="M17.0835 11.5367C17.4057 11.5367 17.6669 11.2569 17.6669 10.9117C17.6669 10.5665 17.4057 10.2866 17.0835 10.2866C16.7612 10.2866 16.5 10.5665 16.5 10.9117C16.5 11.2569 16.7612 11.5367 17.0835 11.5367Z"
      fill="white"
    />
    <path
      d="M7.69895 14.276C7.69895 14.8091 7.60474 15.3179 7.43291 15.7855H0.266045C0.0942089 15.3179 0 14.8091 0 14.276C0 13.7569 0.0893757 13.2605 0.252959 12.8033C0.806315 11.2524 2.208 10.1523 3.84948 10.1523C5.49096 10.1523 6.89264 11.2525 7.44599 12.8033C7.60965 13.2605 7.69895 13.7569 7.69895 14.276Z"
      fill="url(#paint7_linear_716_2496)"
    />
    <path
      d="M10.1825 14.6905C10.1825 15.0772 10.1142 15.4463 9.98956 15.7855H4.79061C4.66591 15.4463 4.59766 15.0772 4.59766 14.6905C4.59766 14.314 4.66242 13.9539 4.78117 13.6222C5.18254 12.4973 6.19935 11.6992 7.39009 11.6992C8.58082 11.6992 9.59763 12.4973 9.999 13.6222C10.1176 13.9539 10.1825 14.3139 10.1825 14.6905Z"
      fill="url(#paint8_linear_716_2496)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_716_2496"
        x1="25.5385"
        y1="7.64945"
        x2="30.9273"
        y2="12.6806"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_2496"
        x1="28.9248"
        y1="10.9951"
        x2="34.8903"
        y2="16.5632"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_2496"
        x1="18.0002"
        y1="-13.3438"
        x2="18.0002"
        y2="50.0789"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF931E" />
        <stop offset="1" stopColor="#FF5D00" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_2496"
        x1="18.0001"
        y1="6.91616"
        x2="18.0001"
        y2="-0.296506"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_2496"
        x1="18.0013"
        y1="0.519226"
        x2="18.0013"
        y2="4.55016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_716_2496"
        x1="14.8635"
        y1="11.9116"
        x2="7.08312"
        y2="4.64938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_716_2496"
        x1="26.8895"
        y1="9.94413"
        x2="21.4353"
        y2="4.85264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_716_2496"
        x1="1.67842"
        y1="11.7089"
        x2="6.7552"
        y2="16.4473"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_716_2496"
        x1="5.81514"
        y1="12.8283"
        x2="9.49751"
        y2="16.2658"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconSingleStory;
