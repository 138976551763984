const INTERIOR_FEATURES = [
  {
    key: "basement_description",
    title: "houseDetails.otherInfo.basement",
  },
  {
    key: "fireplaces",
    title: "houseDetails.otherInfo.fireplaces",
  },
  {
    key: "cable",
    title: "houseDetails.otherInfo.cable",
  },
  {
    key: "internet",
    title: "houseDetails.otherInfo.internet",
  },
  {
    key: "flooring",
    title: "houseDetails.otherInfo.flooring",
  },
  {
    key: "green_energy",
    title: "houseDetails.otherInfo.greenEnergy",
  },
];

const UTILITIES = [
  {
    key: "utilities.Electric",
    title: "houseDetails.otherInfo.utilities",
  },
  {
    key: "utilities.Sewer",
    title: "houseDetails.otherInfo.sewer",
  },
  {
    key: "utilities.Utilities Connections",
    title: "houseDetails.otherInfo.utilitiesConnections",
  },
  {
    key: "utilities.Water Source",
    title: "houseDetails.otherInfo.waterSource",
  },
  {
    key: "utilities.Water Heater",
    title: "houseDetails.otherInfo.waterHeaters",
  },
];

const CONSTRUCTION = [
  {
    key: "architectural_style",
    title: "houseDetails.otherInfo.homeStyle",
  },
  {
    key: "building_exterior_type",
    title: "houseDetails.otherInfo.buildingExteriorType",
  },
  {
    key: "insulation",
    title: "houseDetails.otherInfo.insulation",
  },
  {
    key: "roof",
    title: "houseDetails.otherInfo.roof",
  },
];

const HOA = [
  {
    key: "association.Association",
    title: "houseDetails.otherInfo.association",
  },
  {
    key: "association.Association Fee",
    title: "houseDetails.otherInfo.associationFee",
  },
  {
    key: "association.Association Fee Frequency",
    title: "houseDetails.otherInfo.associationFeeFrequency",
  },
  {
    key: "association.Association_fee_includes",
    title: "houseDetails.otherInfo.associationFeeIncludes",
  },
  {
    key: "pets_allowed",
    title: "houseDetails.otherInfo.petsAllowed",
  },
];

const OTHER_INFO_FIRST_ROW_CRITERIA = [
  {
    title: "houseDetails.otherInfo.interiorTitle",
    criteria: INTERIOR_FEATURES,
  },
  {
    title: "houseDetails.otherInfo.utilitiesTitle",
    criteria: UTILITIES,
  },
  {
    title: "houseDetails.otherInfo.buildingAndConstructionTitle",
    criteria: CONSTRUCTION,
  },
  {
    title: "houseDetails.otherInfo.hoaTitle",
    criteria: HOA,
  },
];

const OTHER_INFO_SECOND_ROW_CRITERIA = [
  {
    key: "appliances",
    title: "houseDetails.otherInfo.appliances",
  },
  {
    key: "exterior_features",
    title: "houseDetails.otherInfo.exteriorFeatures",
  },
  {
    key: "lot_features",
    title: "houseDetails.otherInfo.lotFeatures",
  },
  {
    title: "houseDetails.otherInfo.otherFeature",
    criteria: [
      {
        key: "other_features.Parcel Number",
        title: "houseDetails.otherInfo.parcelNumber",
      },
      {
        key: "other_features.Zoning",
        title: "houseDetails.otherInfo.zoning",
      },
    ],
  },
];

export { OTHER_INFO_FIRST_ROW_CRITERIA, OTHER_INFO_SECOND_ROW_CRITERIA };
