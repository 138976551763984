import { CRITERIA_MAP } from "constants/criteria";
import { FirstStep, SecondStep } from "./Steps";

const ALL_CRITERIA_MODAL_STEPS = [
  {
    content: FirstStep,
    footerRightButtonTitle: "houses.filtersBar.allCriteriaModal.footerRightButtonTitleFirst",
    isShowBackButton: false,
    title: "houses.filtersBar.allCriteriaModal.firstStepTitle",
  },
  {
    content: SecondStep,
    footerRightButtonTitle: "houses.filtersBar.allCriteriaModal.footerRightButtonTitleSecond",
    isShowBackButton: true,
    title: "houses.filtersBar.allCriteriaModal.secondStepTitle",
  }
];

const PRIORITY_CRITERIA_LIST = [
  CRITERIA_MAP.basement,
  CRITERIA_MAP.bikeability,
  CRITERIA_MAP.central_ac,
  CRITERIA_MAP.crime,
  CRITERIA_MAP.garage,
  CRITERIA_MAP.newly_built,
  CRITERIA_MAP.parking,
  CRITERIA_MAP.quietness,
  CRITERIA_MAP.school_quality,
  CRITERIA_MAP.single_story,
  CRITERIA_MAP.solar_power,
  CRITERIA_MAP.transit,
  CRITERIA_MAP.walkability,
];

export { ALL_CRITERIA_MODAL_STEPS, PRIORITY_CRITERIA_LIST };
