import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Layout, Menu } from "antd";
import { FormattedMessage } from "react-intl";
import {
  LOGIN_PATH,
  DASHBOARD_PATH,
  TOP_MATCHES,
  // RECENTLY_ADDED,
  FAVORITES,
  PROFILE,
  SIGN_UP,
  // HELP,
} from "constants/paths";
import { withModal } from "hocs/withModal";
import { LogOutModal } from "components/Modals";
import {
  IconCriteria,
  IconFavorites,
  // IconHelp,
  IconLogout,
  // IconRecently,
  IconSettings,
  IconTop,
} from "components/Svgs";
import { NON_AUTHENTICATED_USER_DATA_KEY, TOKEN_KEY, USER_KEY } from "constants/localStorage";
import { localStorageStrToObjectOrNull } from "utils/helpers/common";
import { getUserAbbreviation } from "utils/helpers/userHelper";
import "../styles.scss";

const { Sider } = Layout;
const DEFAULT_FIRST_NAME = "HiHome";

const SideMenu = observer(({ authStore, children, withoutSidebar, modalVisible, handleModalVisible }) => {
  const [selectedKeys, setSelectedKeys] = useState();
  const [collapsed, setCollapsed] = useState(null);
  const history = useHistory();
  const user = (
    localStorageStrToObjectOrNull(USER_KEY) ||
    localStorageStrToObjectOrNull(NON_AUTHENTICATED_USER_DATA_KEY) ||
    {}
  );

  useEffect(() => {
    setSelectedKeys(history.location.pathname);
  }, []);

  useEffect(_, [authStore.loading]);

  if (withoutSidebar) {
    return children;
  }

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onClickMenu = ({ key }) => key === LOGIN_PATH ? handleModalVisible() : history.push(key);

  const renderSignUpOrLogOut = () => {
    const buttonConfigMap = {
      logOut: { key: LOGIN_PATH, className: null, messageId: "sideMenu.logoutMessage" },
      signUp: { key: SIGN_UP, className: "sign-up", messageId: "sideMenu.signUp" },
    };
    const data = localStorage.getItem(TOKEN_KEY) ? buttonConfigMap.logOut : buttonConfigMap.signUp;
    return (
      <Menu.Item key={data.key} icon={<IconLogout />} className={data.className}>
        <FormattedMessage id={data.messageId} />
      </Menu.Item>
    );
  };

  return (
    <Layout className="sidebar-layout">
      {modalVisible && <LogOutModal modalVisible={modalVisible} handleModalVisible={handleModalVisible} />}
      <Sider
        className="sidebar"
        collapsed={!collapsed}
        onCollapse={onCollapse}
        onMouseEnter={() => onCollapse(true)}
        onMouseLeave={() => onCollapse(false)}
        style={{
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0
        }}
      >
        <div className="sidebar__header">
          <div
            className="sidebar__header__button"
            onClick={() => onCollapse(!collapsed)}
          />
        </div>
        <>
          <Menu
            theme="light"
            onClick={onClickMenu}
            selectedKeys={selectedKeys}
            className="sidebar__menu"
          >
            <li className="sidebar-layout__name">
              <span className="initials">{getUserAbbreviation()}</span>
              <span className="name">
                {user.first_name || DEFAULT_FIRST_NAME }
                <br />
                {user.last_name}
              </span>
            </li>
            <Menu.Item key={DASHBOARD_PATH} icon={<IconCriteria />}>
              <FormattedMessage id="sideMenu.criteriaDashboardMessage" />
            </Menu.Item>
            <Menu.Item key={TOP_MATCHES} icon={<IconTop />}>
              <FormattedMessage id="sideMenu.topMatchesMessage" />
            </Menu.Item>
            {/* <Menu.Item key={RECENTLY_ADDED} icon={<IconRecently />}> */}
            {/*   <FormattedMessage id="sideMenu.recentlyAddedMessage" /> */}
            {/* </Menu.Item> */}
            <Menu.Item key={FAVORITES} icon={<IconFavorites />}>
              <FormattedMessage id="sideMenu.favoritesMessage" />
            </Menu.Item>
            <div className="sidebar__menu-deliver" />
            {localStorage.getItem(TOKEN_KEY) && (
              <Menu.Item key={PROFILE} icon={<IconSettings />}>
                <FormattedMessage id="sideMenu.settingsMessage" />
              </Menu.Item>
            )}
            {/* <Menu.Item key={HELP} icon={<IconHelp />}> */}
            {/*   <FormattedMessage id="sideMenu.helpMessage" /> */}
            {/* </Menu.Item> */}
            {renderSignUpOrLogOut()}
          </Menu>
        </>
      </Sider>
      <Layout className="sidebar-children">{children}</Layout>
    </Layout>
  );
});

export default inject("authStore")(withModal(SideMenu));
