import React from "react";

const IconProximityToGrocery = (props) => (
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.3827 23.7596H5.32031V9.12866H28.9258L27.582 21.3158C27.4274 22.7174 26.4895 23.7596 25.3827 23.7596Z"
      fill="url(#paint0_linear_1284_8049)"
    />
    <path
      d="M5.50278 10.7834L15.6488 23.7596H25.3827C26.4896 23.7596 27.4274 22.7174 27.582 21.3158L28.9258 9.12866H5.32031L5.50278 10.7834Z"
      fill="url(#paint1_linear_1284_8049)"
    />
    <path
      d="M5.32031 19.7751V23.7597H25.3827C26.4895 23.7597 27.4274 22.7175 27.582 21.3158L27.7519 19.7751H5.32031Z"
      fill="url(#paint2_linear_1284_8049)"
    />
    <path
      d="M28.6641 10.7419H5.58313C5.04748 10.7419 4.61328 10.3077 4.61328 9.77205V8.21497C4.61328 7.67931 5.04748 7.24512 5.58313 7.24512H28.6641C29.1998 7.24512 29.634 7.67931 29.634 8.21497V9.77205C29.634 10.3076 29.1997 10.7419 28.6641 10.7419Z"
      fill="url(#paint3_linear_1284_8049)"
    />
    <path
      d="M5.60702 29.4201H4.20084C3.683 29.4201 3.26318 29.0003 3.26318 28.4824V8.42013C3.26318 6.60102 2.69734 4.82697 1.6441 3.3438L0.583709 1.85059C0.208933 1.32291 0.33298 0.591298 0.860666 0.216522C1.38835 -0.158255 2.11996 -0.0342078 2.49474 0.493478L3.98794 2.59619C5.04118 4.07936 5.60702 5.85342 5.60702 7.67252V29.4201Z"
      fill="url(#paint4_linear_1284_8049)"
    />
    <path
      d="M28.573 28.8654V27.6308C28.573 27.3245 28.3246 27.0762 28.0183 27.0762H3.26172V28.4824C3.26172 29.0003 3.68155 29.4201 4.19938 29.4201H28.0184C28.3246 29.4201 28.573 29.1717 28.573 28.8654Z"
      fill="url(#paint5_linear_1284_8049)"
    />
    <path
      d="M7.5 29.4201H10.6098C9.80601 28.621 8.60758 27.3603 8.33809 27.0762H7.5V29.4201Z"
      fill="url(#paint6_linear_1284_8049)"
    />
    <path
      d="M25.8984 29.42H28.031C28.3921 29.42 28.5755 28.9854 28.3232 28.727C27.6431 28.0304 26.9359 27.2862 26.7366 27.0762H25.8985V29.42H25.8984Z"
      fill="url(#paint7_linear_1284_8049)"
    />
    <path
      d="M4.74012 32C6.8122 32 8.49196 30.3203 8.49196 28.2482C8.49196 26.1761 6.8122 24.4963 4.74012 24.4963C2.66804 24.4963 0.988281 26.1761 0.988281 28.2482C0.988281 30.3203 2.66804 32 4.74012 32Z"
      fill="url(#paint8_linear_1284_8049)"
    />
    <path
      d="M4.74113 30.8565C6.18166 30.8565 7.34944 29.6887 7.34944 28.2482C7.34944 26.8077 6.18166 25.6399 4.74113 25.6399C3.30059 25.6399 2.13281 26.8077 2.13281 28.2482C2.13281 29.6887 3.30059 30.8565 4.74113 30.8565Z"
      fill="url(#paint9_linear_1284_8049)"
    />
    <path
      d="M8.28654 29.4806L5.92463 27.1187C5.6079 26.787 5.15306 26.5882 4.65242 26.6147C3.82494 26.6585 3.15162 27.3318 3.10782 28.1593C3.08134 28.6599 3.28012 29.1147 3.61185 29.4315L5.96416 31.7838C7.04746 31.4125 7.90669 30.5599 8.28654 29.4806Z"
      fill="url(#paint10_linear_1284_8049)"
    />
    <path
      d="M4.7413 29.8839C5.64474 29.8839 6.37712 29.1516 6.37712 28.2481C6.37712 27.3447 5.64474 26.6123 4.7413 26.6123C3.83785 26.6123 3.10547 27.3447 3.10547 28.2481C3.10547 29.1516 3.83785 29.8839 4.7413 29.8839Z"
      fill="url(#paint11_linear_1284_8049)"
    />
    <path
      d="M23.1815 32C25.2536 32 26.9334 30.3203 26.9334 28.2482C26.9334 26.1761 25.2536 24.4963 23.1815 24.4963C21.1094 24.4963 19.4297 26.1761 19.4297 28.2482C19.4297 30.3203 21.1094 32 23.1815 32Z"
      fill="url(#paint12_linear_1284_8049)"
    />
    <path
      d="M23.1825 30.8565C24.6231 30.8565 25.7908 29.6887 25.7908 28.2482C25.7908 26.8077 24.6231 25.6399 23.1825 25.6399C21.742 25.6399 20.5742 26.8077 20.5742 28.2482C20.5742 29.6887 21.742 30.8565 23.1825 30.8565Z"
      fill="url(#paint13_linear_1284_8049)"
    />
    <path
      d="M26.7279 29.4806L24.366 27.1187C24.0492 26.787 23.5944 26.5882 23.0938 26.6147C22.2663 26.6585 21.593 27.3318 21.5492 28.1593C21.5228 28.6599 21.7215 29.1147 22.0533 29.4315L24.4056 31.7838C25.4888 31.4125 26.348 30.5599 26.7279 29.4806Z"
      fill="url(#paint14_linear_1284_8049)"
    />
    <path
      d="M23.1827 29.8839C24.0861 29.8839 24.8185 29.1516 24.8185 28.2481C24.8185 27.3447 24.0861 26.6123 23.1827 26.6123C22.2793 26.6123 21.5469 27.3447 21.5469 28.2481C21.5469 29.1516 22.2793 29.8839 23.1827 29.8839Z"
      fill="url(#paint15_linear_1284_8049)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1284_8049"
        x1="16.4867"
        y1="12.4273"
        x2="18.6511"
        y2="26.0897"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1284_8049"
        x1="17.8884"
        y1="15.6726"
        x2="16.3198"
        y2="2.37064"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFDCFB" stopOpacity="0" />
        <stop offset="0.2889" stopColor="#C7B6DF" stopOpacity="0.289" />
        <stop offset="0.5915" stopColor="#A495C7" stopOpacity="0.592" />
        <stop offset="0.8395" stopColor="#8E80B7" stopOpacity="0.84" />
        <stop offset="1" stopColor="#8679B2" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1284_8049"
        x1="16.5361"
        y1="21.919"
        x2="16.5361"
        y2="24.3329"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFDCFB" stopOpacity="0" />
        <stop offset="0.2889" stopColor="#C7B6DF" stopOpacity="0.289" />
        <stop offset="0.5915" stopColor="#A495C7" stopOpacity="0.592" />
        <stop offset="0.8395" stopColor="#8E80B7" stopOpacity="0.84" />
        <stop offset="1" stopColor="#8679B2" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1284_8049"
        x1="17.0929"
        y1="8.37898"
        x2="17.1942"
        y2="10.4044"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1284_8049"
        x1="3.43892"
        y1="14.7101"
        x2="7.94747"
        y2="14.7101"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1284_8049"
        x1="15.9173"
        y1="27.2015"
        x2="15.9173"
        y2="29.4845"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1284_8049"
        x1="9.32933"
        y1="28.7887"
        x2="8.24704"
        y2="28.3739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.8228" stopColor="#59465A" stopOpacity="0.866" />
        <stop offset="1" stopColor="#564357" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1284_8049"
        x1="27.7563"
        y1="28.893"
        x2="26.187"
        y2="28.1896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="1" stopColor="#564357" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1284_8049"
        x1="2.48174"
        y1="25.9898"
        x2="6.8239"
        y2="30.3319"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1284_8049"
        x1="6.31126"
        y1="29.8183"
        x2="3.29253"
        y2="26.7995"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1284_8049"
        x1="7.41382"
        y1="30.9207"
        x2="5.29574"
        y2="28.8026"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.8228" stopColor="#59465A" stopOpacity="0.866" />
        <stop offset="1" stopColor="#564357" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1284_8049"
        x1="4.13957"
        y1="27.3665"
        x2="5.30298"
        y2="29.071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1284_8049"
        x1="20.9231"
        y1="25.9898"
        x2="25.2653"
        y2="30.3319"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1284_8049"
        x1="24.7526"
        y1="29.8183"
        x2="21.7339"
        y2="26.7995"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1284_8049"
        x1="25.8552"
        y1="30.9207"
        x2="23.7371"
        y2="28.8026"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.8228" stopColor="#59465A" stopOpacity="0.866" />
        <stop offset="1" stopColor="#564357" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1284_8049"
        x1="22.581"
        y1="27.3665"
        x2="23.7444"
        y2="29.071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconProximityToGrocery;
