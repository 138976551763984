import React  from "react";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "antd";
import { get as lodashGet } from "lodash";
import { IconView, ScheduleTourButtonView } from "containers/HouseDetails/Views";
import { formatOpenHouseTimes } from "utils/helpers/housesHelper/formatter";
import { getHouseDetailsValue } from "utils/helpers/housesHelper";
import { COLUMN_SPAN, CRITERIA_COLUMNS } from "./constants";
import Photos  from "./Photos";
import "./styles.scss";


const PropertyInfoSection = observer(({ houseDetails, onAllPhotosButtonClick }) => {
  const renderCriteriaInfo = () => (
    <Row className="property-info__details__criteria-info">
      {CRITERIA_COLUMNS.map((columnCriteria,index) => (
        <Col span={COLUMN_SPAN[index]}>
          {columnCriteria.map(item => (
            <div className="criteria-info-wrapper">
              <IconView
                customIconClass="criteria-icon"
                customWrapperClass="criteria-icon-wrapper"
                icon={item.icon}
              />
              <div className="criteria-description">
                <div className="criteria-title">
                  <FormattedMessage id={item.criteriaTitle} />
                </div>
                <div className="criteria-value">
                  {getHouseDetailsValue(houseDetails, item.key, _.has(item, "valueKey") ? item.valueKey : null)}
                </div>
              </div>
            </div>
          ))}
        </Col>
      ))}
    </Row>
  );

  const renderDescription = () => (
    <Row className="property-info__details__description">
      <div className="description-title">
        <FormattedMessage id="houseDetails.description" />
      </div>
      <div className="description-text">
        {houseDetails.description}
      </div>
    </Row>
  );

  const renderOpenHouseTimes = () => {
    const openHouseTimes = lodashGet(houseDetails, "open_houses", []);
    const formattedOpenHouseTimes = formatOpenHouseTimes(openHouseTimes);
    return (
    <div className="property-info__details__open-house-times">
      {!_.isEmpty(formattedOpenHouseTimes) && (
        <>
          <div className="schedule-description">
            <div className="schedule-description__title">
              <FormattedMessage id="houseDetails.openHouseTimes"/>
            </div>
            <div className="schedule-description__content">
              {formattedOpenHouseTimes.map(item => <div className="schedule-description__content__text">{item}</div>)}
            </div>
          </div>
          <div className="open-houses-separator-line"/>
        </>
      )}
      <div className="schedule-button">
        <div className="schedule-button__title">
          <FormattedMessage id="houseDetails.wantToVisitHome"/>
        </div>
        <div className="tour-btn-description">
          <ScheduleTourButtonView houseDetails={houseDetails}/>
        </div>
      </div>
    </div>
    );
  };

  return (
    <div className="property-info-container">
      <div className="property-info__photos">
        <Photos houseDetails={houseDetails} onAllPhotosButtonClick={onAllPhotosButtonClick} />
      </div>
      <Row className="property-info__details">
        <Col span={15}>
          {renderDescription()}
          {renderCriteriaInfo()}
        </Col>
        <Col span={9}>
          {renderOpenHouseTimes()}
        </Col>
      </Row>
    </div>
  );
});

export default inject("housesStore", "criteriaStore")(PropertyInfoSection);
