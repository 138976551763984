const TIME_ZONES_MAP = {
  AST: { value: "America/Halifax", label: "AT" },
  CST: { value: "America/Mexico_City", label: "CT" },
  EST: { value: "America/New_York", label: "ET" },
  MST: { value: "America/Edmonton", label: "MT" },
  PST: { value: "America/Los_Angeles", label: "PT" },
};

const initializeDateFromTimestamp = timestamp => new Date(timestamp * 1000);

const formatDateValue = (date, options) => date.toLocaleDateString("en-US", options);

const formatTimeValue = (date, options) => date.toLocaleTimeString("en-US", options);

export {
  initializeDateFromTimestamp,
  formatDateValue,
  formatTimeValue,
  TIME_ZONES_MAP
};
