import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { houseMatchPhoto } from "images";

import "./styles.scss";

const CalculationStep = () => {
  const renderTitle = () => (
    <div className="calculating-step__title">
      <div className="calculating-step__loader"></div>
      <div className="calculating-step__titletext">
      <FormattedMessage id="onboarding.calculatingMatchesTitle"/>
      </div>
    </div>
  );

  const renderImage = () => (
    <div className="calculating-step__image">
      <img
        src={houseMatchPhoto}
        alt="90% Match Photo"
      />
    </div>
  );

  return (
    <div className="calculating-step-container">
      {renderTitle()}
      {renderImage()}
    </div>
  );

};

CalculationStep.propTypes = {
  stepData: PropTypes.object,
  handleFormChange: PropTypes.func,
  onSubmitForm: PropTypes.func,
};

export default CalculationStep;
