import { toJS, makeAutoObservable } from "mobx";
import _ from "lodash";
import globalErrorHandler from "utils/globalErrorHandler";
import { firebaseCheckAndUpdateIdToken } from "utils/firebase";
import {
  getHousesApi,
  getHousesMapApi,
  getHousesFavoritesMapApi,
  getHousesFavoritesApi,
  getHouseDetailsApi,
  postHousesFavoritesApi,
  delHousesFavoritesApi,
  postHousesRecentlyViewedApi,
} from "./requests";
import { trackEvent } from "utils/helpers/userHelper";

const INITIAL_DATA = {
  loading: false,
  housesData: {},
  hoveredItem: {},
  houseDetailsData: {},
  currentPage: 1,
  view: "",
  timeout: null,
};

class HousesStore {
  loading = INITIAL_DATA.loading;
  housesData = INITIAL_DATA.housesData;
  houseDetailsData = INITIAL_DATA.houseDetailsData;
  hoveredItem = INITIAL_DATA.hoveredItem;
  currentPage = INITIAL_DATA.currentPage;
  view = INITIAL_DATA.view;
  timeout = INITIAL_DATA.timeout;

  constructor () {
    makeAutoObservable(this);
  };

  handleTimeout = (mapRefCurrent, handleGetHouses, isLoaded, setIsLoaded) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    const fetch = () => {
      if (mapRefCurrent) {
        handleGetHouses();
      }
    }
    if (isLoaded) {
      this.timeout = setTimeout(fetch, 300);
    } else {
      setIsLoaded(true);
    }
  };

  removeHouseFromFavorites = houseId => {
    _.remove(this.housesData.data, item => item.id === houseId);
    this.housesData.total_results = this.housesData.total_results - 1;
  };

  retrieveHousesData = () => toJS(this.housesData) || {};

  retrieveHouseDetailsData = () => toJS(this.houseDetailsData);

  retrieveHoveredItem = () => toJS(this.hoveredItem);

  retrieveCurrentPage = () => this.currentPage;

  setHouses = data => this.housesData = data;

  setHousesData = data => this.housesData = { ...toJS(this.housesData), data } ;

  setHouseDetails = data => this.houseDetailsData = data;

  setHoveredItem = data => this.hoveredItem = data;

  setCurrentPage = page => this.currentPage = page;

  setView = view => this.view = view;

  getHousesRequest (requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.getHouses(requestBody, handleSuccess, handleError);
  };

  getHousesMapRequest (requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.getHousesMap(requestBody, handleSuccess, handleError);
  };

  getHousesFavoritesRequest (requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.getHousesFavorites(requestBody, handleSuccess, handleError);
  };

  getHousesFavoritesMapRequest (requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.getHousesFavoritesMap(requestBody, handleSuccess, handleError);
  };

  getHouseDetailsRequest (queryParams, handleSuccess, handleError) {
    this.loading = true;
    this.getHouseDetails(queryParams, handleSuccess, handleError);
  };

  markHousesFavoriteRequest (requestBody, handleSuccess, handleError) {
    this.markHousesFavorite(requestBody, handleSuccess, handleError);
  };

  markHousesRecentlyViewedRequest (requestBody, handleSuccess, handleError) {
    this.markHousesRecentlyViewed(requestBody, handleSuccess, handleError);
  };

  delHousesFavoritesRequest (requestBody, handleSuccess, handleError) {
    this.delHousesFavorites(requestBody, handleSuccess, handleError);
  };

  requestSuccess (handleSuccess, data) {
    this.loading = false;

    if (handleSuccess) {
      handleSuccess(data);
    }
  };

  requestFailure (handleError, errorResponse) {
    if (errorResponse && errorResponse.status !== 401) {
      this.loading = false;
    }
    globalErrorHandler(errorResponse, handleError)
  };

  *getHouses (requestBody, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const response = yield getHousesApi(requestBody);
      this.housesData = response.data;
      this.requestSuccess(handleSuccess, response.data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *getHousesMap (requestBody, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const id = new Date()
      this.id = id
      const initialSize = parseInt(process.env.REACT_APP_INITIAL_MAP_RESULTS_SIZE)
      const totalResults = parseInt(process.env.REACT_APP_MAP_MAX_RESULTS_SIZE)
      const batchSize = parseInt(process.env.REACT_APP_MAP_RESULTS_BATCH_SIZE)
      requestBody["size"] = initialSize;
      requestBody["start"] = 0;
      const response = yield getHousesMapApi(requestBody);
      if (this.id === id){
        this.housesData = response.data;
      } else{
        return
      }
      for (let i=initialSize; i<totalResults; i+=batchSize){
        if (this.id === id && response.data.total_results > initialSize){
          requestBody["size"] = batchSize;
          requestBody["start"] = i;
          const response = yield getHousesMapApi(requestBody);
          if (this.id === id){
            this.housesData.data = this.housesData.data.concat(response.data.data);
          } else{
            break;
          }
        } else {
          break;
        }
      }
      this.requestSuccess(handleSuccess, this.housesData);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *getHousesFavorites (requestBody, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const response = yield getHousesFavoritesApi(requestBody);
      this.housesData = response.data;
      this.requestSuccess(handleSuccess, response.data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *getHousesFavoritesMap (requestBody, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const initialSize = parseInt(process.env.REACT_APP_INITIAL_MAP_RESULTS_SIZE)
      const batchSize = parseInt(process.env.REACT_APP_MAP_RESULTS_BATCH_SIZE)
      requestBody["size"] = initialSize;
      requestBody["start"] = 0;
      const response = yield getHousesFavoritesMapApi(requestBody);
      this.housesData = response.data;
      const total_results = this.housesData.total_results;
      for (let i=initialSize; i<total_results; i+=batchSize){
        requestBody["size"] = batchSize;
        requestBody["start"] = i;
        const response = yield getHousesFavoritesMapApi(requestBody);
        this.housesData.data = this.housesData.data.concat(response.data.data);
      }
      this.requestSuccess(handleSuccess, response.data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *getHouseDetails (queryParams, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const response = yield getHouseDetailsApi(queryParams);
      this.houseDetailsData = response.data.data;
      this.requestSuccess(handleSuccess, response.data.data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *markHousesRecentlyViewed (requestBody, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const response = yield postHousesRecentlyViewedApi(requestBody);
      this.requestSuccess(handleSuccess, response.data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *markHousesFavorite (requestBody, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const response = yield postHousesFavoritesApi(requestBody);
      trackEvent("favorites", {
        ...requestBody, method: "add",
        house_url: window.location.origin + "/houses/" + requestBody.house_id
      });
      this.requestSuccess(handleSuccess, response.data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *delHousesFavorites (requestBody, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const response = yield delHousesFavoritesApi(requestBody);
      trackEvent("favorites", {
        ...requestBody, method: "delete",
        house_url: window.location.origin + "/houses/" + requestBody.house_id
      });
      this.requestSuccess(handleSuccess, response.data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };
}

export default HousesStore;
