import React from "react";

const IconLotSize = (props) => (
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.125 12.1008V28.2793H6.125V12.1008L16.625 1.5293L27.125 12.1008Z"
      fill="url(#paint0_linear_716_2466)"
    />
    <path
      d="M27.125 12.1008V28.2793H20.4947L6.125 13.899V12.1008L16.625 1.5293L27.125 12.1008Z"
      fill="url(#paint1_linear_716_2466)"
    />
    <path
      d="M27.125 27.2793V17.4166L18.8876 9.18751C18.3296 8.55467 17.5134 8.1543 16.6049 8.1543C14.9273 8.1543 13.5625 9.51776 13.5625 11.1937V13.4288C13.5625 13.7208 13.7195 13.9761 13.9531 14.117C13.9533 14.1171 27.1225 27.2754 27.125 27.2793Z"
      fill="url(#paint2_linear_716_2466)"
    />
    <path
      d="M23.875 28.2793H13.6157L7.97612 22.6219C7.72787 22.4605 7.5625 22.1803 7.5625 21.8625V17.3093C7.5625 16.8105 7.96734 16.4043 8.46466 16.4043H11.7369C12.0545 16.4043 12.3338 16.5701 12.4947 16.8191L12.4702 16.8387L23.875 28.2793Z"
      fill="url(#paint3_linear_716_2466)"
    />
    <path
      d="M11.7474 22.2168H8.4401C8.23148 22.2168 8.0625 22.0506 8.0625 21.8457V17.3379C8.0625 17.1328 8.23163 16.9668 8.4401 16.9668H11.7474C11.956 16.9668 12.125 17.133 12.125 17.3379V21.8457C12.125 22.0506 11.9559 22.2168 11.7474 22.2168Z"
      fill="#503837"
    />
    <path
      d="M11.7251 22.7793H8.46239C7.96619 22.7793 7.5625 22.3724 7.5625 21.8723V17.3113C7.5625 16.8112 7.96619 16.4043 8.46239 16.4043H11.7251C12.2213 16.4043 12.625 16.8112 12.625 17.3113V21.8723C12.625 22.3724 12.2212 22.7793 11.7251 22.7793ZM8.61712 21.7163H11.5702V17.4673H8.61712V21.7163Z"
      fill="white"
    />
    <path
      d="M27.125 18.2822V28.2793H26.8031L21.1642 22.622C20.9153 22.4605 20.75 22.1803 20.75 21.8625V17.3093C20.75 16.8105 21.1549 16.4043 21.6528 16.4043H24.9239C25.2415 16.4043 25.5207 16.5701 25.6816 16.8191L25.6947 16.8293L25.686 16.8387L27.125 18.2822Z"
      fill="url(#paint4_linear_716_2466)"
    />
    <path
      d="M24.9349 22.2168H21.6276C21.419 22.2168 21.25 22.0506 21.25 21.8457V17.3379C21.25 17.1328 21.4191 16.9668 21.6276 16.9668H24.9349C25.1435 16.9668 25.3125 17.133 25.3125 17.3379V21.8457C25.3125 22.0506 25.1434 22.2168 24.9349 22.2168Z"
      fill="#503837"
    />
    <path
      d="M24.9126 22.7793H21.6499C21.1537 22.7793 20.75 22.3724 20.75 21.8723V17.3113C20.75 16.8112 21.1537 16.4043 21.6499 16.4043H24.9126C25.4088 16.4043 25.8125 16.8112 25.8125 17.3113V21.8723C25.8125 22.3724 25.4088 22.7793 24.9126 22.7793ZM21.8047 21.7163H24.7578V17.4673H21.8047V21.7163Z"
      fill="white"
    />
    <path
      d="M29.5161 13.5682L17.2591 1.29166C16.9097 0.941841 16.3433 0.941841 15.9939 1.29166L3.73394 13.5673C3.50535 13.7962 3.50535 14.1671 3.73394 14.396L4.25642 14.9192C4.48501 15.148 4.8555 15.148 5.08409 14.9192L16.4599 3.52886C16.5519 3.43674 16.701 3.43674 16.7929 3.52886L28.1658 14.9201C28.3944 15.149 28.7649 15.149 28.9934 14.9201L29.5159 14.397C29.7447 14.1681 29.7447 13.7971 29.5161 13.5682Z"
      fill="url(#paint5_linear_716_2466)"
    />
    <path
      d="M17.2577 1.29166C17.083 1.11675 16.854 1.0293 16.625 1.0293V3.45977C16.6853 3.45977 16.7456 3.4828 16.7916 3.52886L28.1657 14.9201C28.3943 15.149 28.765 15.149 28.9935 14.9201L29.516 14.397C29.7447 14.1681 29.7447 13.7971 29.516 13.5683L17.2577 1.29166Z"
      fill="url(#paint6_linear_716_2466)"
    />
    <path
      d="M14.4375 28.2793V18.0902C14.4375 16.883 15.4169 15.9043 16.625 15.9043C17.8331 15.9043 18.8125 16.883 18.8125 18.0902V28.2793H14.4375Z"
      fill="#DB9E36"
    />
    <path
      d="M19.3749 28.2793H18.2833V18.0611C18.2833 17.1618 17.5426 16.4261 16.6374 16.4261H16.6126C15.7036 16.4261 14.9667 17.1581 14.9667 18.0611V28.2793H13.875V18.0735C13.875 16.5649 15.1063 15.3418 16.625 15.3418C18.1437 15.3418 19.375 16.5649 19.375 18.0735L19.3749 28.2793Z"
      fill="#9E6429"
    />
    <path
      d="M15.8438 20.6543C16.1026 20.6543 16.3125 20.4444 16.3125 20.1855C16.3125 19.9267 16.1026 19.7168 15.8438 19.7168C15.5849 19.7168 15.375 19.9267 15.375 20.1855C15.375 20.4444 15.5849 20.6543 15.8438 20.6543Z"
      fill="white"
    />
    <path d="M27.125 25.4043H6.125V28.2793H27.125V25.4043Z" fill="#654E48" />
    <path
      d="M9.75 27.1404C9.75 27.5426 9.67428 27.9265 9.53624 28.2793H3.77626C3.63814 27.9265 3.5625 27.5426 3.5625 27.1404C3.5625 26.7487 3.63431 26.3742 3.7658 26.0293C4.21048 24.8593 5.33702 24.0293 6.65625 24.0293C7.97548 24.0293 9.10202 24.8593 9.5467 26.0293C9.67819 26.3742 9.75 26.7487 9.75 27.1404Z"
      fill="url(#paint7_linear_716_2466)"
    />
    <path
      d="M11.75 27.4586C11.75 27.7485 11.6949 28.0251 11.5945 28.2793H7.40545C7.30501 28.0251 7.25 27.7484 7.25 27.4586C7.25 27.1764 7.30222 26.9065 7.39791 26.658C7.72134 25.8149 8.54057 25.2168 9.50008 25.2168C10.4596 25.2168 11.2788 25.8148 11.6022 26.658C11.6977 26.9065 11.75 27.1764 11.75 27.4586Z"
      fill="url(#paint8_linear_716_2466)"
    />
    <path
      d="M29.6875 26.9562C29.6875 27.4234 29.5995 27.8694 29.4392 28.2793H22.7483C22.5879 27.8695 22.5 27.4235 22.5 26.9562C22.5 26.5011 22.5834 26.0661 22.7361 25.6653C23.2528 24.3061 24.5613 23.3418 26.0938 23.3418C27.6263 23.3418 28.9348 24.3061 29.4515 25.6653C29.6041 26.0662 29.6875 26.5011 29.6875 26.9562Z"
      fill="url(#paint9_linear_716_2466)"
    />
    <path
      d="M18.8674 13.7168H14.3827C14.2059 13.7168 14.0625 13.5752 14.0625 13.4005V11.1855C14.0625 9.78755 15.2098 8.6543 16.625 8.6543C18.0402 8.6543 19.1875 9.78755 19.1875 11.1855V13.4005C19.1876 13.5751 19.0442 13.7168 18.8674 13.7168Z"
      fill="#654E48"
    />
    <path
      d="M16.625 8.1543C14.9363 8.1543 13.5625 9.51412 13.5625 11.1855V13.4147C13.5625 13.857 13.926 14.2168 14.3728 14.2168H18.8772C19.324 14.2168 19.6875 13.857 19.6875 13.4147V11.1855C19.6875 9.51412 18.3137 8.1543 16.625 8.1543ZM18.6933 10.9275H17.1138V9.17951C17.9461 9.37822 18.5849 10.0722 18.6933 10.9275ZM16.1363 9.17951V10.9275H14.5568C14.6651 10.0722 15.304 9.37822 16.1363 9.17951ZM14.54 11.895H16.1364V13.2494H14.54V11.895ZM17.1137 13.2493V11.8949H18.7101V13.2493H17.1137Z"
      fill="white"
    />
    <path
      d="M31.4613 27.8978C31.1637 27.8978 30.9225 28.1389 30.9225 28.4365V28.9819H2.49913C2.27125 28.9819 2.0865 28.7971 2.0865 28.5693V1.0775H2.62169C2.91925 1.0775 3.16044 0.836312 3.16044 0.53875C3.16044 0.241187 2.91925 0 2.62169 0H0.53875C0.241187 0.000125 0 0.241313 0 0.538813C0 0.836375 0.241187 1.07756 0.53875 1.07756H1.00912V28.9789C1.00912 29.5756 1.49281 30.0592 2.0895 30.0592H30.9226V30.5195C30.9226 30.8171 31.1638 31.0583 31.4613 31.0583C31.7589 31.0583 32.0001 30.8171 32.0001 30.5195V28.4365C32 28.1389 31.7588 27.8978 31.4613 27.8978Z"
      fill="#9FC7E2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_716_2466"
        x1="16.6246"
        y1="15.0903"
        x2="16.6246"
        y2="34.0257"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF931E" />
        <stop offset="1" stopColor="#FF5D00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_2466"
        x1="15.8174"
        y1="12.1607"
        x2="10.6235"
        y2="6.9706"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_2466"
        x1="20.65"
        y1="15.8909"
        x2="14.6092"
        y2="9.84412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_2466"
        x1="14.2301"
        y1="23.7283"
        x2="7.00968"
        y2="16.5305"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_2466"
        x1="26.6169"
        y1="22.9259"
        x2="21.7287"
        y2="18.0537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_716_2466"
        x1="16.6246"
        y1="9.33322"
        x2="16.6246"
        y2="19.9927"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE7A5" />
        <stop offset="0.966" stopColor="#FFBF5C" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_716_2466"
        x1="23.1299"
        y1="8.57244"
        x2="9.65215"
        y2="-4.88952"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_716_2466"
        x1="4.91147"
        y1="25.2036"
        x2="8.75026"
        y2="29.0209"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_716_2466"
        x1="8.23099"
        y1="26.0631"
        x2="10.9973"
        y2="28.8396"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_716_2466"
        x1="24.0665"
        y1="24.706"
        x2="28.5262"
        y2="29.1403"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconLotSize;
