import React from "react";

const IconBikeability = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1383_3541)">
      <path
        d="M6.08203 14.3921H6.94597V26.1496H6.08203V14.3921Z"
        fill="url(#paint0_linear_1383_3541)"
      />
      <path
        d="M0.636719 20.7028V19.8389H12.3941V20.7028H0.636719Z"
        fill="url(#paint1_linear_1383_3541)"
      />
      <path
        d="M2.66557 24.7333L2.05469 24.1223L10.3685 15.8086L10.9794 16.4195L2.66557 24.7333Z"
        fill="url(#paint2_linear_1383_3541)"
      />
      <path
        d="M2.05469 16.4195L2.66557 15.8086L10.9794 24.1223L10.3685 24.7333L2.05469 16.4195Z"
        fill="url(#paint3_linear_1383_3541)"
      />
      <path
        d="M6.51317 14.5312C3.34323 14.5312 0.773438 17.101 0.773438 20.271C0.773438 23.4409 3.34316 26.0107 6.51317 26.0107C9.68318 26.0107 12.2529 23.441 12.2529 20.271C12.2529 17.101 9.68312 14.5312 6.51317 14.5312ZM6.51317 25.0132C3.89406 25.0132 1.7709 22.8901 1.7709 20.271C1.7709 17.6519 3.89406 15.5287 6.51317 15.5287C9.13228 15.5287 11.2554 17.6519 11.2554 20.271C11.2554 22.8901 9.13228 25.0132 6.51317 25.0132V25.0132Z"
        fill="url(#paint4_linear_1383_3541)"
      />
      <path
        d="M6.51426 13.7568C2.91652 13.7568 0 16.6734 0 20.2711C0 23.8688 2.91652 26.7854 6.51426 26.7854C10.112 26.7854 13.0285 23.8688 13.0285 20.2711C13.0285 16.6734 10.112 13.7568 6.51426 13.7568ZM6.51426 25.6533C3.54177 25.6533 1.13205 23.2436 1.13205 20.2711C1.13205 17.2986 3.54177 14.8889 6.51426 14.8889C9.48675 14.8889 11.8965 17.2986 11.8965 20.2711C11.8965 23.2436 9.48681 25.6533 6.51426 25.6533Z"
        fill="url(#paint5_linear_1383_3541)"
      />
      <path
        d="M6.51543 14.3921C3.26869 14.3921 0.636719 17.0241 0.636719 20.2708C0.636719 23.5176 3.26869 26.1495 6.51543 26.1495C9.76218 26.1495 12.3941 23.5176 12.3941 20.2708C12.3941 17.0241 9.76218 14.3921 6.51543 14.3921V14.3921ZM6.51543 25.653C3.54295 25.653 1.13322 23.2433 1.13322 20.2708C1.13322 17.2983 3.54295 14.8886 6.51543 14.8886C9.48792 14.8886 11.8976 17.2983 11.8976 20.2708C11.8976 23.2433 9.48798 25.653 6.51543 25.653Z"
        fill="url(#paint6_linear_1383_3541)"
      />
      <path
        d="M25.0547 14.3921H25.9186V26.1496H25.0547V14.3921Z"
        fill="url(#paint7_linear_1383_3541)"
      />
      <path
        d="M19.6094 20.7028V19.8389H31.3668V20.7028H19.6094Z"
        fill="url(#paint8_linear_1383_3541)"
      />
      <path
        d="M21.6382 24.7333L21.0273 24.1223L29.3411 15.8086L29.952 16.4195L21.6382 24.7333Z"
        fill="url(#paint9_linear_1383_3541)"
      />
      <path
        d="M21.0273 16.4195L21.6382 15.8086L29.952 24.1223L29.3411 24.7333L21.0273 16.4195Z"
        fill="url(#paint10_linear_1383_3541)"
      />
      <path
        d="M25.4858 14.5312C22.3159 14.5312 19.7461 17.101 19.7461 20.271C19.7461 23.4409 22.3158 26.0107 25.4858 26.0107C28.6558 26.0107 31.2255 23.441 31.2255 20.271C31.2255 17.101 28.6558 14.5312 25.4858 14.5312ZM25.4858 25.0132C22.8667 25.0132 20.7436 22.8901 20.7436 20.271C20.7436 17.6519 22.8667 15.5287 25.4858 15.5287C28.1049 15.5287 30.2281 17.6519 30.2281 20.271C30.2281 22.8901 28.1049 25.0132 25.4858 25.0132Z"
        fill="url(#paint11_linear_1383_3541)"
      />
      <path
        d="M25.4869 13.7568C21.8892 13.7568 18.9727 16.6734 18.9727 20.2711C18.9727 23.8688 21.8892 26.7854 25.4869 26.7854C29.0847 26.7854 32.0012 23.8688 32.0012 20.2711C32.0012 16.6734 29.0847 13.7568 25.4869 13.7568ZM25.4869 25.6533C22.5144 25.6533 20.1047 23.2436 20.1047 20.2711C20.1047 17.2986 22.5144 14.8889 25.4869 14.8889C28.4594 14.8889 30.8691 17.2986 30.8691 20.2711C30.8691 23.2436 28.4594 25.6533 25.4869 25.6533Z"
        fill="url(#paint12_linear_1383_3541)"
      />
      <path
        d="M25.4881 14.3921C22.2413 14.3921 19.6094 17.0241 19.6094 20.2708C19.6094 23.5176 22.2413 26.1495 25.4881 26.1495C28.7348 26.1495 31.3668 23.5176 31.3668 20.2708C31.3668 17.0241 28.7348 14.3921 25.4881 14.3921ZM25.4881 25.653C22.5156 25.653 20.1059 23.2433 20.1059 20.2708C20.1059 17.2983 22.5156 14.8886 25.4881 14.8886C28.4606 14.8886 30.8703 17.2983 30.8703 20.2708C30.8703 23.2433 28.4606 25.653 25.4881 25.653Z"
        fill="url(#paint13_linear_1383_3541)"
      />
      <path
        d="M7.78934 9.80405H9.69817L7.31982 6.37402H7.09293C6.38241 6.37402 5.96453 7.17227 6.36936 7.75618L7.78934 9.80405Z"
        fill="url(#paint14_linear_1383_3541)"
      />
      <path
        d="M16.0005 21.0552H6.51482C6.28655 21.0552 6.06958 20.9557 5.92056 20.7828C5.77154 20.6098 5.70535 20.3805 5.7391 20.1547L7.42349 8.90359C7.48762 8.47517 7.88724 8.18027 8.31529 8.24401C8.74371 8.30814 9.03899 8.70745 8.97486 9.13581L7.42525 19.4866H16.0005C16.4337 19.4866 16.7849 19.8378 16.7849 20.2709C16.7849 20.704 16.4337 21.0552 16.0005 21.0552Z"
        fill="url(#paint15_linear_1383_3541)"
      />
      <path
        d="M15.9997 21.0552C15.7425 21.0552 15.5017 20.9291 15.3552 20.7177L7.55384 9.46654C7.38756 9.22679 7.36818 8.91444 7.50352 8.65593C7.63886 8.39736 7.90653 8.23535 8.19836 8.23535H23.801C24.0928 8.23535 24.3605 8.39736 24.4958 8.65593C24.6312 8.91444 24.6118 9.22679 24.4455 9.46654L16.6442 20.7177C16.4977 20.9291 16.2569 21.0552 15.9997 21.0552V21.0552ZM9.69658 9.80398L15.9997 18.8944L22.3028 9.80398H9.69658Z"
        fill="url(#paint16_linear_1383_3541)"
      />
      <path
        d="M25.4832 21.0554C25.1014 21.0554 24.7668 20.7761 24.7085 20.3871L22.7313 7.16992C22.6975 6.9441 22.7638 6.7149 22.9128 6.54191C23.0618 6.36898 23.2787 6.26953 23.507 6.26953H25.0366C25.4697 6.26953 25.8209 6.62072 25.8209 7.05385C25.8209 7.48697 25.4697 7.83816 25.0366 7.83816H24.4173L26.2598 20.155C26.3239 20.5834 26.0286 20.9826 25.6002 21.0467C25.5609 21.0525 25.5218 21.0554 25.4832 21.0554V21.0554Z"
        fill="url(#paint17_linear_1383_3541)"
      />
      <path
        d="M8.60896 8.23535H8.1986C7.90684 8.23535 7.63892 8.39786 7.50339 8.65637C7.36849 8.91488 7.38731 9.22672 7.55358 9.46704L15.3553 20.7178C15.5015 20.9293 15.7425 21.0554 15.9997 21.0554C16.257 21.0554 16.4979 20.9293 16.6441 20.7178L16.9572 20.2661L8.60896 8.23535Z"
        fill="url(#paint18_linear_1383_3541)"
      />
      <path
        d="M17.2402 24.3994L16.5863 24.5417L14.7578 16.1424L15.4118 16L17.2402 24.3994Z"
        fill="url(#paint19_linear_1383_3541)"
      />
      <path
        d="M25.4863 21.5078C26.1691 21.5078 26.7227 20.9543 26.7227 20.2715C26.7227 19.5887 26.1691 19.0352 25.4863 19.0352C24.8035 19.0352 24.25 19.5887 24.25 20.2715C24.25 20.9543 24.8035 21.5078 25.4863 21.5078Z"
        fill="url(#paint20_linear_1383_3541)"
      />
      <path
        d="M6.51686 22.4557H6.51279C5.30814 22.4557 4.32812 21.4757 4.32812 20.2711C4.32812 19.0664 5.30814 18.0864 6.51279 18.0864L6.52094 18.0866L16.0027 18.3946C17.0356 18.3968 17.8751 19.2377 17.8751 20.2711C17.8751 21.3044 17.0356 22.1453 16.0027 22.1475L6.51686 22.4557ZM6.83561 18.708C5.97537 18.71 5.27627 19.4104 5.27627 20.271C5.27627 21.1316 5.97544 21.832 6.83561 21.8339L15.6501 21.5477C16.3541 21.5477 16.9268 20.975 16.9268 20.271C16.9268 19.5669 16.3541 18.9942 15.6501 18.9942L15.6425 18.994L6.83561 18.708Z"
        fill="url(#paint21_linear_1383_3541)"
      />
      <path
        d="M8.28818 20.5998L8.28718 20.6005C8.15222 20.7052 8.09487 20.8816 8.14255 21.0456L8.14287 21.0466C8.21885 21.3078 8.02792 21.5704 7.75598 21.5787H7.75523C7.58431 21.5839 7.43391 21.6932 7.37618 21.8542C7.28426 22.1106 6.97512 22.211 6.75011 22.0577L6.74999 22.0576C6.60862 21.9612 6.42264 21.9612 6.28128 22.0576L6.28115 22.0577C6.05615 22.2111 5.74701 22.1106 5.65508 21.8543V21.8542C5.59736 21.6932 5.44702 21.584 5.2761 21.5787H5.27535C5.00348 21.5704 4.81248 21.3077 4.88853 21.0465L4.88878 21.0456C4.93646 20.8816 4.87911 20.7051 4.74415 20.6005L4.74321 20.5997C4.52831 20.4331 4.52831 20.1085 4.74321 19.942L4.74421 19.9412C4.87918 19.8366 4.93653 19.6601 4.88884 19.4961L4.88859 19.4952C4.8126 19.2339 5.00354 18.9713 5.27548 18.963H5.27623C5.44715 18.9578 5.59755 18.8485 5.65527 18.6875C5.74719 18.4312 6.05634 18.3307 6.28134 18.4841L6.28147 18.4841C6.42283 18.5805 6.60881 18.5805 6.75017 18.4841L6.7503 18.4841C6.9753 18.3307 7.28445 18.4312 7.37637 18.6875V18.6876C7.4341 18.8486 7.58443 18.9578 7.75535 18.9631H7.7561C8.02798 18.9714 8.21898 19.2341 8.14293 19.4953L8.14268 19.4962C8.09499 19.6602 8.15234 19.8367 8.28731 19.9413L8.28825 19.9421C8.50309 20.1086 8.50309 20.4332 8.28818 20.5998V20.5998Z"
        fill="url(#paint22_linear_1383_3541)"
      />
      <path
        d="M6.51368 21.5078C7.19648 21.5078 7.75001 20.9543 7.75001 20.2715C7.75001 19.5887 7.19648 19.0352 6.51368 19.0352C5.83087 19.0352 5.27734 19.5887 5.27734 20.2715C5.27734 20.9543 5.83087 21.5078 6.51368 21.5078Z"
        fill="url(#paint23_linear_1383_3541)"
      />
      <path
        d="M17.4906 20.5475L17.4897 20.5481C17.3763 20.6361 17.3281 20.7844 17.3681 20.9224L17.3684 20.9232C17.4323 21.1427 17.2718 21.3636 17.0432 21.3705H17.0426C16.8989 21.3749 16.7724 21.4668 16.7239 21.6021C16.6467 21.8176 16.3867 21.9021 16.1976 21.7731L16.1976 21.773C16.0787 21.692 15.9224 21.692 15.8035 21.773L15.8034 21.7731C15.6143 21.902 15.3543 21.8175 15.2771 21.6021V21.6021C15.2286 21.4667 15.1022 21.3749 14.9585 21.3705H14.9579C14.7293 21.3635 14.5688 21.1426 14.6326 20.9231L14.6329 20.9224C14.673 20.7845 14.6248 20.6361 14.5113 20.5481L14.5105 20.5475C14.3298 20.4075 14.3298 20.1346 14.5105 19.9945L14.5113 19.9939C14.6247 19.9059 14.673 19.7576 14.6329 19.6197L14.6326 19.6189C14.5688 19.3993 14.7293 19.1785 14.9579 19.1715H14.9585C15.1022 19.1671 15.2286 19.0753 15.2771 18.9399C15.3543 18.7245 15.6143 18.64 15.8034 18.7689L15.8035 18.769C15.9223 18.85 16.0786 18.85 16.1975 18.769L16.1976 18.7689C16.3867 18.6401 16.6467 18.7245 16.7239 18.9399V18.94C16.7724 19.0753 16.8989 19.1672 17.0426 19.1716H17.0432C17.2718 19.1785 17.4323 19.3994 17.3684 19.6189L17.3681 19.6197C17.3281 19.7576 17.3762 19.9059 17.4897 19.9939L17.4906 19.9945C17.6713 20.1346 17.6713 20.4075 17.4906 20.5475Z"
        fill="url(#paint24_linear_1383_3541)"
      />
      <path
        d="M16.0002 21.3106C16.5742 21.3106 17.0396 20.8452 17.0396 20.2712C17.0396 19.6972 16.5742 19.2319 16.0002 19.2319C15.4263 19.2319 14.9609 19.6972 14.9609 20.2712C14.9609 20.8452 15.4263 21.3106 16.0002 21.3106Z"
        fill="url(#paint25_linear_1383_3541)"
      />
      <path
        d="M29.7234 8.67939C29.6942 7.40761 28.6386 6.39893 27.3665 6.39893H25.2656V7.65157H27.3894C27.9896 7.65157 28.4771 8.14273 28.4713 8.74421C28.4668 9.20124 28.1748 9.58763 27.77 9.74267C27.5307 9.8344 27.3756 10.0679 27.3756 10.3242C27.3756 10.7631 27.8165 11.068 28.2254 10.9087C29.1145 10.5624 29.7467 9.69078 29.7234 8.67939V8.67939Z"
        fill="url(#paint26_linear_1383_3541)"
      />
      <path
        d="M25.2671 8.05779C25.0592 8.05779 24.8906 7.88925 24.8906 7.68132V6.46827C24.8906 6.26033 25.0592 6.0918 25.2671 6.0918C25.475 6.0918 25.6436 6.26033 25.6436 6.46827V7.68132C25.6436 7.88925 25.475 8.05779 25.2671 8.05779Z"
        fill="url(#paint27_linear_1383_3541)"
      />
      <path
        d="M10.2847 7.72196H5.64725C5.18406 7.72196 4.80859 7.34649 4.80859 6.88331C4.80859 5.83666 5.76107 5.04851 6.78921 5.24434L10.4526 5.94226C10.8761 6.02295 11.1824 6.39314 11.1824 6.82414C11.1825 7.32002 10.7806 7.72196 10.2847 7.72196V7.72196Z"
        fill="url(#paint28_linear_1383_3541)"
      />
      <path
        d="M15.8659 16.4684L15.19 16.3839C15.1352 16.3771 15.0798 16.3771 15.0249 16.3839L14.349 16.4684C13.9521 16.5181 13.6016 16.2086 13.6016 15.8086C13.6016 15.4086 13.9521 15.0992 14.349 15.1488L15.0249 15.2333C15.0797 15.2401 15.1352 15.2401 15.19 15.2333L15.8659 15.1488C16.2628 15.0992 16.6134 15.4086 16.6134 15.8086C16.6134 16.2086 16.2628 16.5181 15.8659 16.4684Z"
        fill="url(#paint29_linear_1383_3541)"
      />
      <path
        d="M17.5417 25.1745L16.8658 25.09C16.811 25.0832 16.7556 25.0832 16.7007 25.09L16.0248 25.1745C15.6279 25.2241 15.2773 24.9147 15.2773 24.5147C15.2773 24.1147 15.628 23.8052 16.0248 23.8548L16.7007 23.9394C16.7555 23.9462 16.811 23.9462 16.8658 23.9394L17.5417 23.8548C17.9386 23.8052 18.2892 24.1147 18.2892 24.5147C18.2892 24.9147 17.9386 25.2242 17.5417 25.1745Z"
        fill="url(#paint30_linear_1383_3541)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1383_3541"
        x1="6.14158"
        y1="20.2708"
        x2="6.98055"
        y2="20.2708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1383_3541"
        x1="6.51543"
        y1="19.8984"
        x2="6.51543"
        y2="20.7373"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1383_3541"
        x1="6.24927"
        y1="20.0178"
        x2="6.84237"
        y2="20.6109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1383_3541"
        x1="6.75069"
        y1="20.0213"
        x2="6.15758"
        y2="20.6144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1383_3541"
        x1="4.5768"
        y1="18.3346"
        x2="9.55837"
        y2="23.3162"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1383_3541"
        x1="2.40314"
        y1="16.16"
        x2="12.0351"
        y2="25.792"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1383_3541"
        x1="12.5481"
        y1="26.3034"
        x2="3.0042"
        y2="16.7596"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1383_3541"
        x1="25.1143"
        y1="20.2708"
        x2="25.9532"
        y2="20.2708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1383_3541"
        x1="25.4881"
        y1="19.8984"
        x2="25.4881"
        y2="20.7373"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1383_3541"
        x1="25.2191"
        y1="20.0178"
        x2="25.8122"
        y2="20.6109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1383_3541"
        x1="25.7205"
        y1="20.0212"
        x2="25.1274"
        y2="20.6144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1383_3541"
        x1="23.5494"
        y1="18.3346"
        x2="28.531"
        y2="23.3162"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1383_3541"
        x1="21.3758"
        y1="16.16"
        x2="31.0078"
        y2="25.792"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1383_3541"
        x1="31.5207"
        y1="26.3034"
        x2="21.9768"
        y2="16.7596"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1383_3541"
        x1="8.29187"
        y1="8.07862"
        x2="6.97422"
        y2="8.85246"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1383_3541"
        x1="6.74271"
        y1="16.8202"
        x2="13.08"
        y2="14.2476"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1383_3541"
        x1="15.3957"
        y1="7.70785"
        x2="19.4113"
        y2="16.4294"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_1383_3541"
        x1="22.7226"
        y1="13.6625"
        x2="26.2686"
        y2="13.6625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_1383_3541"
        x1="12.0708"
        y1="14.6665"
        x2="11.1924"
        y2="15.3148"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E87264" stopOpacity="0" />
        <stop offset="0.6519" stopColor="#F67150" stopOpacity="0.652" />
        <stop offset="1" stopColor="#FF7044" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_1383_3541"
        x1="15.6965"
        y1="20.3308"
        x2="16.2375"
        y2="20.2128"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_1383_3541"
        x1="25.0692"
        y1="19.8544"
        x2="26.1423"
        y2="20.9274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_1383_3541"
        x1="7.23122"
        y1="16.4644"
        x2="14.8445"
        y2="24.0776"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_1383_3541"
        x1="5.86967"
        y1="19.6248"
        x2="7.53166"
        y2="21.2868"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_1383_3541"
        x1="6.09661"
        y1="19.8544"
        x2="7.16961"
        y2="20.9274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_1383_3541"
        x1="15.4575"
        y1="19.728"
        x2="16.8546"
        y2="21.1251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_1383_3541"
        x1="15.6496"
        y1="19.9206"
        x2="16.5517"
        y2="20.8226"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_1383_3541"
        x1="25.5127"
        y1="9.05806"
        x2="29.7421"
        y2="7.67296"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_1383_3541"
        x1="24.6755"
        y1="7.26852"
        x2="25.8664"
        y2="6.87856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_1383_3541"
        x1="7.99555"
        y1="5.68619"
        x2="7.99555"
        y2="8.2157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_1383_3541"
        x1="15.1075"
        y1="15.2757"
        x2="15.1075"
        y2="16.3001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_1383_3541"
        x1="16.7833"
        y1="23.9818"
        x2="16.7833"
        y2="25.0062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <clipPath id="clip0_1383_3541">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconBikeability;
