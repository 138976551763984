import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import _ from "lodash";
import CustomTable from "components/DataDisplay/CustomTable";
import { IconAngleLeft, IconValuationChart } from "components/Svgs";
import { PROPERTY_HISTORY_COLUMNS, TAX_HISTORY_COLUMNS } from "./config";
import { HISTORY_COLUMNS_DATA } from "./constants";
import "./styles.scss";

const HistoryInfoSection = ({ houseDetails }) => {
  const [isFullPropHistoryOpened, setIsFullPropHistoryOpened] = useState(false);

  const isDataExist = key => _.has(houseDetails, key) && _.isArray(houseDetails[key]) && !_.isEmpty(houseDetails[key]);

  const renderChart = () => (
    <div className="property-history-info__chart">
      <IconValuationChart className="chart" />
      <div className="title">
        <FormattedMessage id="houseDetails.propertyHistory.valuationData" />
      </div>
    </div>
  );

  const renderPropertyHistoryTable = () => {
    const data = isFullPropHistoryOpened ? houseDetails.property_history : houseDetails.property_history.slice(0, 4);
    return (
      <CustomTable
        bordered={false}
        className="property-history-info__table"
        columns={PROPERTY_HISTORY_COLUMNS}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const renderPropertyHistory = () => {
    const buttonMessageId = (
      isFullPropHistoryOpened ? "houseDetails.propertyHistory.less" : "houseDetails.propertyHistory.more"
    );
    return (
      <div className="property-history-info">
        {renderChart()}
        <>
          {renderPropertyHistoryTable()}
          {houseDetails.property_history.length > 4 && (
            <div
              className="property-history-info__button"
              role="button"
              onClick={() => setIsFullPropHistoryOpened(!isFullPropHistoryOpened)}
            >
              <div className="property-history-info__button__text">
                <FormattedMessage id={buttonMessageId} />
              </div>
              <IconAngleLeft
                className={classNames("property-history-info__button__icon", { isOpened: isFullPropHistoryOpened })}
              />
            </div>
          )}
        </>
      </div>
    );
  };

  const renderPublicTaxHistory = () => (
    <div className="tax-history-info">
      <CustomTable
        bordered={false}
        className="tax-history-info__table"
        columns={TAX_HISTORY_COLUMNS(houseDetails)}
        dataSource={houseDetails.tax_history.slice(0, 10)}
        pagination={false}
      />
    </div>
  );

  return (
    <div className="history-info-container">
      <Row gutter={16}>
        {HISTORY_COLUMNS_DATA.map(columnData => (
          _.has(houseDetails, columnData.key) && !_.isEmpty(houseDetails[columnData.key]) && (
            <Col span={columnData.span} className="history-info-title">
              <FormattedMessage id={columnData.message} />
            </Col>
          )
        ))}
      </Row>
      <Row>
        {HISTORY_COLUMNS_DATA.map((columnData, index) => {
          const renderMethod = [renderPropertyHistory, renderPublicTaxHistory];
          return (
            <Col span={columnData.span}>
              {isDataExist(columnData.key) && renderMethod[index]()}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

HistoryInfoSection.propTypes = {
  houseDetails: PropTypes.object,
};

export default HistoryInfoSection;
