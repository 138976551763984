import React from "react";

const IconTransparentHeart = (props) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="white"
      d="M11.5056 0.0302734C12.1677 0.0302734 12.8103 0.174811 13.4119 0.460526C15.6433 1.51599 16.6052 4.19498 15.5525 6.437C14.6423 8.37985 9.14233 13.3009 8.51733 13.8555C8.38256 13.9765 8.21459 14.0404 8.03979 14.0504H7.99975C7.81127 14.0504 7.6267 13.9799 7.48217 13.8521C6.85717 13.3009 1.35815 8.37649 0.447013 6.43363C-0.604745 4.19498 0.356193 1.51599 2.58764 0.460526C3.19018 0.174811 3.83178 0.0302734 4.49389 0.0302734C5.86889 0.0302734 7.1599 0.67229 7.99975 1.72775C8.83666 0.67229 10.1277 0.0302734 11.5056 0.0302734ZM7.99975 12.2286C10.3464 10.1076 13.5964 6.9412 14.1443 5.77481C14.8298 4.30927 14.2048 2.558 12.7429 1.87229C12.3494 1.68742 11.9324 1.5933 11.5027 1.5933C10.3757 1.5933 9.34057 2.25212 8.8601 3.27397C8.83666 3.32775 8.79662 3.42859 8.75268 3.60674C8.66869 3.95632 8.35619 4.2017 7.99682 4.2017C7.63647 4.2017 7.32397 3.95632 7.23998 3.60674C7.19604 3.42859 7.156 3.32775 7.13256 3.27397C6.65502 2.25212 5.61694 1.5933 4.49096 1.5933C4.06029 1.5933 3.6433 1.68742 3.24975 1.87229C1.79467 2.56137 1.16967 4.31263 1.85522 5.77481C2.40307 6.9412 5.65307 10.1076 7.99975 12.2286Z"/>
  </svg>
);

export default IconTransparentHeart;
