import React from "react";

const IconHouseMarker = props => (
  <svg width="40" height="51" viewBox="0 0 40 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#583EF5"/>
    <path d="M20 51L14 39H26L20 51Z" fill="#583EF5"/>
    <g filter="url(#filter0_i_5924_15281)">
      <circle cx="20" cy="20" r="14" fill="white"/>
    </g>
    <path d="M26.7889 18.8907L20.3529 12.4595C20.3066 12.413 20.2515 12.3762 20.1909 12.3511C20.1304 12.3259 20.0654 12.313 19.9998 12.313C19.9342 12.313 19.8692 12.3259 19.8087 12.3511C19.7481 12.3762 19.693 12.413 19.6467 12.4595L13.2107 18.8907C13.0232 19.0782 12.917 19.3329 12.917 19.5985C12.917 20.1501 13.3654 20.5985 13.917 20.5985H14.5951V25.1876C14.5951 25.4641 14.8186 25.6876 15.0951 25.6876H18.9998V22.1876H20.7498V25.6876H24.9045C25.181 25.6876 25.4045 25.4641 25.4045 25.1876V20.5985H26.0826C26.3482 20.5985 26.6029 20.4938 26.7904 20.3048C27.1795 19.9141 27.1795 19.2813 26.7889 18.8907Z" fill="#583EF5"/>
    <defs>
      <filter id="filter0_i_5924_15281" x="6" y="6" width="28" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.576471 0 0 0 0 0.61451 0 0 0 0 0.956863 0 0 0 0.17 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5924_15281"/>
      </filter>
    </defs>
  </svg>
);

export default IconHouseMarker;
