import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { IconPlaceVector } from "components/Svgs";
import { IconView } from "../../index";
import "./styles.scss";

const PlaceIconView = (props) => {
  const { icon } = props;
  return (
    <div className="place-icon-container">
      <IconPlaceVector className="vector-icon" />
      {!_.isNil(icon) && <IconView icon={icon} customWrapperClass="circle-icon" customIconClass="place-icon" />}
    </div>
  );
}

PlaceIconView.propTypes = {
  icon: PropTypes.any,
};

export default PlaceIconView;
