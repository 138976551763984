import React from 'react';
import { Polygon } from '@react-google-maps/api'
import { inject, observer } from "mobx-react";


const POLYGON_OPTIONS = {
    fillColor: "#9571F6",
    fillOpacity: 0.3,
    strokeColor: "#9571F6",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

const MapRegions = observer(({ criteriaStore }) => {
    // Don't forget filtering out locations without geometry
    

    // TODO: Optimize rendering
    // Avoid calling this function multiple times
    // For the same data
    // Maybe useMemo or add useEffect with state
    const getCoordinates = (rawCoordinates) => {
        const geometry = rawCoordinates &&
            rawCoordinates.map(item => item.map(coordinates => ({lat: coordinates[1], lng: coordinates[0]})))
        
        return geometry
    }

    const renderRegion = (region) => {
        const rawGeometry = region?.geometry;
        const rawCoordinates = rawGeometry?.coordinates;

        if (!rawGeometry || !rawCoordinates) {
            return null;
        }

        const geometries = rawGeometry.type == 'MultiPolygon' ?
            rawCoordinates.map(item => getCoordinates(item)) : [getCoordinates(rawCoordinates)];

        return (
            <>
                {geometries.map((item, index) => (
                    <Polygon
                        key={`${region.gid}-${index}`}
                        options={POLYGON_OPTIONS}
                        paths={item}
                    />
                ))}
            </>
        );
    }

    const criteriaData = criteriaStore.retrieveCriteriaData()

    return (
        <>
            {criteriaData.locations.map(region => renderRegion(region))}
        </>
    );
})

export default inject('criteriaStore')(MapRegions);
