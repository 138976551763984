import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { SimpleButton } from "components/Controls/Buttons";
import { IconArrowLeft } from "components/Svgs";
import { DASHBOARD_PATH, ONBOARDING_PATH } from "constants/paths";

const HomePageButton = props => {
  const { kind } = props;
  const history = useHistory();
  return (
    <SimpleButton
      className="homepage-button"
      onClick={(e) => {
        e.preventDefault();
        if (kind === 'login') {
            history.push(ONBOARDING_PATH);
        } else {
          // history.goBack();  // uncomment when dashboard page becomes unnecessary
          history.push(DASHBOARD_PATH);
        }
      }}
    >
      <div className="homepage-button__icon">
        <IconArrowLeft />
      </div>
      <div>
        <FormattedMessage id="signUp.homepage"/>
      </div>
    </SimpleButton>
  );
};

HomePageButton.propTypes = {
  kind: PropTypes.string,
};

export default HomePageButton;
