import React from "react";

const IconProfileHouses = () => (
<svg width="457" height="315" viewBox="0 0 457 315" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M431.406 151.795C441.908 153.826 450.693 163.07 452.309 173.735C453.117 179.017 452.309 184.502 454.127 189.581C456.045 194.862 460.488 198.722 463.72 203.293C469.779 212.029 470.991 223.913 466.85 233.664C477.857 237.321 483.31 251.135 480.079 262.206C476.847 273.38 466.547 281.404 455.338 284.451C444.23 287.498 432.314 286.076 421.105 283.334" fill="#EBEBEB"/>
<path d="M442.112 313.806C442.011 313.806 441.809 296.132 441.809 274.395C441.809 252.658 441.91 234.984 442.112 234.984C442.213 234.984 442.415 252.658 442.415 274.395C442.314 296.234 442.213 313.806 442.112 313.806Z" fill="#E0E0E0"/>
<path d="M453.724 241.79C453.825 241.891 451.401 244.837 448.271 248.291C445.14 251.846 442.616 254.588 442.515 254.487C442.414 254.385 444.837 251.439 447.968 247.986C450.997 244.431 453.623 241.688 453.724 241.79Z" fill="#E0E0E0"/>
<path d="M275.387 39.2511H345.266C345.266 39.2511 339.106 27.9763 321.131 29.6015C303.965 31.1251 303.056 24.0149 294.371 23.2023C285.586 22.2881 278.315 28.9921 275.387 39.2511Z" fill="#F5F5F5"/>
<path d="M396.566 31.1251H466.446C466.446 31.1251 460.286 19.8503 442.311 21.4755C425.144 22.9991 424.235 15.8889 415.551 15.0763C406.766 14.1622 399.495 20.8661 396.566 31.1251Z" fill="#F5F5F5"/>
<path d="M25.4609 52.963H79.3852C79.3852 52.963 74.6391 44.2276 60.7036 45.5481C47.475 46.767 46.7681 41.2819 40.0023 40.5709C33.3375 39.8599 27.6825 45.0402 25.4609 52.963Z" fill="#EBEBEB"/>
<path d="M76.4531 19.1392H138.557C138.557 19.1392 133.104 9.08335 117.048 10.5054C101.8 11.9274 100.992 5.52824 93.2161 4.81722C85.5415 4.00462 79.0787 9.99752 76.4531 19.1392Z" fill="#F5F5F5"/>
<path d="M58.68 128.128C46.1582 126.605 33.2325 136.051 30.91 148.545C29.4962 155.96 31.3139 164.187 27.5775 170.891C25.5579 174.548 22.0235 177.189 19.1961 180.236C10.3097 189.479 7.17922 203.903 11.3195 216.092C5.26057 218.123 1.01933 224.218 0.211474 230.617C-0.697362 237.016 1.42326 243.517 4.65467 249.002C11.1175 259.87 22.7304 267.59 35.2522 269.215C47.7739 270.942 60.9015 266.676 70.0909 257.94" fill="#E0E0E0"/>
<path d="M38.4865 314.01C38.3856 314.01 38.1836 289.733 38.1836 259.871C38.1836 230.008 38.2846 205.731 38.4865 205.731C38.6885 205.731 38.7895 230.008 38.7895 259.871C38.7895 289.835 38.5875 314.01 38.4865 314.01Z" fill="#EBEBEB"/>
<path d="M38.4859 245.548C38.3849 245.65 35.8604 242.095 33.0329 237.727C30.1044 233.359 27.8828 229.703 27.9838 229.601C28.0848 229.5 30.6093 233.055 33.4368 237.422C36.2643 241.79 38.5869 245.447 38.4859 245.548Z" fill="#EBEBEB"/>
<path d="M468.36 313.807C468.36 313.908 364.954 314.111 237.414 314.111C109.874 314.111 6.46875 314.01 6.46875 313.807C6.46875 313.705 109.874 313.502 237.414 313.502C364.954 313.604 468.36 313.705 468.36 313.807Z" fill="#464657"/>
<path d="M187.735 11.9272H132.094V34.4768H187.735V11.9272Z" fill="#939DF4"/>
<g opacity="0.7">
<path opacity="0.7" d="M187.735 11.9272H132.094V34.4768H187.735V11.9272Z" fill="#1B235E"/>
</g>
<path d="M207.629 11.9272H151.988V34.4768H207.629V11.9272Z" fill="#939DF4"/>
<g opacity="0.4">
<path opacity="0.4" d="M207.629 11.9272H151.988V34.4768H207.629V11.9272Z" fill="#1B235E"/>
</g>
<path d="M189.551 33.4609H88.3672V314.111H189.551V33.4609Z" fill="#939DF4"/>
<g opacity="0.7">
<path opacity="0.7" d="M126.639 33.4609H88.3672V314.111H126.639V33.4609Z" fill="#1B235E"/>
</g>
<path d="M254.785 33.4609H153.602V314.111H254.785V33.4609Z" fill="#939DF4"/>
<g opacity="0.4">
<path opacity="0.4" d="M254.787 33.4609H126.742V314.111H254.787V33.4609Z" fill="#1B235E"/>
</g>
<path d="M212.175 33.461C212.175 33.5625 194.2 33.7657 171.984 33.7657C149.768 33.7657 131.793 33.6641 131.793 33.461C131.793 33.3594 149.768 33.1562 171.984 33.1562C194.2 33.2578 212.175 33.3594 212.175 33.461Z" fill="#464657"/>
<path d="M179.05 52.9634H145.02V132.699H179.05V52.9634Z" fill="#FAFAFA"/>
<path d="M179.15 132.699C179.15 132.699 179.15 132.598 179.15 132.293C179.15 131.988 179.15 131.684 179.15 131.176C179.15 130.16 179.15 128.636 179.15 126.706C179.15 122.847 179.15 117.158 179.049 110.15C179.049 96.031 178.948 76.1224 178.948 53.065L179.15 53.2681C168.547 53.2681 157.035 53.2681 145.119 53.2681L145.422 52.9634C145.422 84.0452 145.321 112.181 145.321 132.699L145.119 132.496C155.621 132.496 164.104 132.598 169.961 132.598C172.889 132.598 175.212 132.598 176.727 132.598C177.535 132.598 178.039 132.598 178.544 132.598C178.948 132.699 179.15 132.699 179.15 132.699C179.15 132.699 178.948 132.699 178.544 132.699C178.14 132.699 177.535 132.699 176.828 132.699C175.212 132.699 172.99 132.699 170.062 132.699C164.104 132.699 155.621 132.801 145.119 132.801H144.917V132.598C144.917 112.08 144.917 83.9436 144.816 52.8619V52.5571H145.119C157.035 52.5571 168.547 52.5571 179.15 52.5571H179.352V52.7603C179.352 75.9192 179.251 95.9294 179.251 110.048C179.251 117.057 179.15 122.745 179.15 126.605C179.15 128.535 179.15 129.957 179.15 130.973C179.15 131.48 179.15 131.785 179.15 132.09C179.15 132.598 179.15 132.699 179.15 132.699Z" fill="#464657"/>
<path d="M179.657 69.3169C179.657 69.4185 171.781 69.6217 162.086 69.6217C152.392 69.6217 144.516 69.5201 144.516 69.3169C144.516 69.2154 152.392 69.0122 162.086 69.0122C171.781 69.0122 179.657 69.1138 179.657 69.3169Z" fill="#464657"/>
<path d="M162.49 132.09C162.389 132.09 162.188 118.073 162.188 100.805C162.188 83.5373 162.288 69.52 162.49 69.52C162.591 69.52 162.793 83.5373 162.793 100.805C162.692 118.073 162.591 132.09 162.49 132.09Z" fill="#464657"/>
<path d="M240.547 52.9634H206.516V132.699H240.547V52.9634Z" fill="#FAFAFA"/>
<path d="M240.545 132.699C240.545 132.699 240.545 132.598 240.545 132.293C240.545 131.988 240.545 131.684 240.545 131.176C240.545 130.16 240.545 128.636 240.545 126.706C240.545 122.847 240.545 117.158 240.444 110.15C240.444 96.031 240.343 76.1224 240.343 53.065L240.545 53.2681C229.942 53.2681 218.43 53.2681 206.514 53.2681L206.817 52.9634C206.817 84.0452 206.716 112.181 206.716 132.699L206.514 132.496C217.016 132.496 225.498 132.598 231.355 132.598C234.284 132.598 236.606 132.598 238.121 132.598C238.929 132.598 239.434 132.598 239.939 132.598C240.343 132.699 240.545 132.699 240.545 132.699C240.545 132.699 240.343 132.699 239.939 132.699C239.535 132.699 238.929 132.699 238.222 132.699C236.606 132.699 234.385 132.699 231.456 132.699C225.498 132.699 217.016 132.801 206.514 132.801H206.312V132.598C206.312 112.08 206.312 83.9436 206.211 52.8619V52.5571H206.514C218.43 52.5571 229.942 52.5571 240.545 52.5571H240.747V52.7603C240.747 75.9192 240.646 95.9294 240.646 110.048C240.646 117.057 240.545 122.745 240.545 126.605C240.545 128.535 240.545 129.957 240.545 130.973C240.545 131.48 240.545 131.785 240.545 132.09C240.545 132.598 240.545 132.699 240.545 132.699Z" fill="#464657"/>
<path d="M241.153 69.3169C241.153 69.4185 233.277 69.6217 223.583 69.6217C213.888 69.6217 206.012 69.5201 206.012 69.3169C206.012 69.2154 213.888 69.0122 223.583 69.0122C233.277 69.0122 241.153 69.1138 241.153 69.3169Z" fill="#464657"/>
<path d="M223.885 132.09C223.784 132.09 223.582 118.073 223.582 100.805C223.582 83.5373 223.683 69.52 223.885 69.52C223.986 69.52 224.188 83.5373 224.188 100.805C224.188 118.073 224.087 132.09 223.885 132.09Z" fill="#464657"/>
<path d="M179.05 150.068H145.02V229.804H179.05V150.068Z" fill="#FAFAFA"/>
<path d="M179.15 229.703C179.15 229.703 179.15 229.601 179.15 229.296C179.15 228.992 179.15 228.687 179.15 228.179C179.15 227.163 179.15 225.64 179.15 223.71C179.15 219.85 179.15 214.162 179.049 207.153C179.049 193.034 178.948 173.126 178.948 150.068L179.15 150.272C168.547 150.272 157.035 150.272 145.119 150.272L145.422 149.967C145.422 181.049 145.321 209.185 145.321 229.703L145.119 229.5C155.621 229.5 164.104 229.601 169.961 229.601C172.889 229.601 175.212 229.601 176.727 229.601C177.535 229.601 178.039 229.601 178.544 229.601C178.948 229.703 179.15 229.703 179.15 229.703C179.15 229.703 178.948 229.703 178.544 229.703C178.14 229.703 177.535 229.703 176.828 229.703C175.212 229.703 172.99 229.703 170.062 229.703C164.104 229.703 155.621 229.804 145.119 229.804H144.917V229.601C144.917 209.083 144.917 180.947 144.816 149.865V149.561H145.119C157.035 149.561 168.547 149.561 179.15 149.561H179.352V149.764C179.352 172.923 179.251 192.933 179.251 207.052C179.251 214.06 179.15 219.748 179.15 223.608C179.15 225.538 179.15 226.96 179.15 227.976C179.15 228.484 179.15 228.789 179.15 229.093C179.15 229.601 179.15 229.703 179.15 229.703Z" fill="#464657"/>
<path d="M179.657 166.32C179.657 166.422 171.781 166.625 162.086 166.625C152.392 166.625 144.516 166.524 144.516 166.32C144.516 166.117 152.392 166.016 162.086 166.016C171.781 166.117 179.657 166.219 179.657 166.32Z" fill="#464657"/>
<path d="M162.49 229.093C162.389 229.093 162.188 215.076 162.188 197.808C162.188 180.541 162.288 166.523 162.49 166.523C162.591 166.523 162.793 180.541 162.793 197.808C162.692 215.076 162.591 229.093 162.49 229.093Z" fill="#464657"/>
<path d="M240.547 150.068H206.516V229.804H240.547V150.068Z" fill="#FAFAFA"/>
<path d="M240.545 229.703C240.545 229.703 240.545 229.601 240.545 229.296C240.545 228.992 240.545 228.687 240.545 228.179C240.545 227.163 240.545 225.64 240.545 223.71C240.545 219.85 240.545 214.162 240.444 207.153C240.444 193.034 240.343 173.126 240.343 150.068L240.545 150.272C229.942 150.272 218.43 150.272 206.514 150.272L206.817 149.967C206.817 181.049 206.716 209.185 206.716 229.703L206.514 229.5C217.016 229.5 225.498 229.601 231.355 229.601C234.284 229.601 236.606 229.601 238.121 229.601C238.929 229.601 239.434 229.601 239.939 229.601C240.343 229.703 240.545 229.703 240.545 229.703C240.545 229.703 240.343 229.703 239.939 229.703C239.535 229.703 238.929 229.703 238.222 229.703C236.606 229.703 234.385 229.703 231.456 229.703C225.498 229.703 217.016 229.804 206.514 229.804H206.312V229.601C206.312 209.083 206.312 180.947 206.211 149.865V149.561H206.514C218.43 149.561 229.942 149.561 240.545 149.561H240.747V149.764C240.747 172.923 240.646 192.933 240.646 207.052C240.646 214.06 240.545 219.748 240.545 223.608C240.545 225.538 240.545 226.96 240.545 227.976C240.545 228.484 240.545 228.789 240.545 229.093C240.545 229.601 240.545 229.703 240.545 229.703Z" fill="#464657"/>
<path d="M241.153 166.32C241.153 166.422 233.277 166.625 223.583 166.625C213.888 166.625 206.012 166.524 206.012 166.32C206.012 166.117 213.888 166.016 223.583 166.016C233.277 166.117 241.153 166.219 241.153 166.32Z" fill="#464657"/>
<path d="M223.885 229.093C223.784 229.093 223.582 215.076 223.582 197.808C223.582 180.541 223.683 166.523 223.885 166.523C223.986 166.523 224.188 180.541 224.188 197.808C224.188 215.076 224.087 229.093 223.885 229.093Z" fill="#464657"/>
<path d="M193.794 314.009C193.794 314.009 193.794 313.908 193.794 313.705C193.794 313.502 193.794 313.197 193.794 312.791C193.794 311.978 193.794 310.759 193.794 309.235C193.794 306.188 193.794 301.617 193.693 296.031C193.693 284.756 193.592 268.91 193.592 250.424L193.794 250.627C184.403 250.627 174.204 250.627 163.5 250.627C163.399 250.729 164.005 250.119 163.803 250.424V250.525V250.627V250.932V251.541V252.659C163.803 253.471 163.803 254.182 163.803 254.995C163.803 256.518 163.803 258.042 163.803 259.566C163.803 262.613 163.803 265.558 163.803 268.403C163.803 274.192 163.803 279.88 163.803 285.264C163.803 296.031 163.803 305.782 163.702 314.009L163.5 313.806C172.79 313.806 180.364 313.908 185.615 313.908C188.24 313.908 190.26 313.908 191.674 313.908C192.38 313.908 192.885 313.908 193.289 313.908C193.592 314.009 193.794 314.009 193.794 314.009C193.794 314.009 193.592 314.009 193.289 314.009C192.885 314.009 192.38 314.009 191.775 314.009C190.361 314.009 188.341 314.009 185.817 314.009C180.566 314.009 172.992 314.111 163.601 314.111H163.399V313.908C163.399 305.579 163.399 295.828 163.298 285.162C163.298 279.779 163.298 274.192 163.298 268.301C163.298 265.355 163.298 262.41 163.298 259.464C163.298 257.94 163.298 256.417 163.298 254.893C163.298 254.182 163.298 253.37 163.298 252.557V251.44V250.83V250.525V250.424V250.322C163.096 250.525 163.601 250.018 163.601 250.018C174.305 250.018 184.504 250.018 193.895 250.018H194.097V250.221C194.097 268.809 193.996 284.654 193.996 296.031C193.996 301.617 193.895 306.087 193.895 309.134C193.895 310.657 193.895 311.775 193.895 312.587C193.895 312.994 193.895 313.298 193.895 313.502C193.794 313.908 193.794 314.009 193.794 314.009Z" fill="#464657"/>
<path d="M223.99 314.009C223.99 314.009 223.99 313.908 223.99 313.705C223.99 313.502 223.99 313.197 223.99 312.791C223.99 311.978 223.99 310.759 223.99 309.235C223.99 306.188 223.99 301.617 223.889 296.031C223.889 284.756 223.788 268.91 223.788 250.424L223.99 250.627C214.598 250.627 204.399 250.627 193.695 250.627C193.594 250.729 194.2 250.119 193.998 250.424V250.525V250.627V250.932V251.541V252.659C193.998 253.471 193.998 254.182 193.998 254.995C193.998 256.518 193.998 258.042 193.998 259.566C193.998 262.613 193.998 265.558 193.998 268.403C193.998 274.192 193.998 279.88 193.998 285.264C193.998 296.031 193.998 305.782 193.897 314.009L193.695 313.806C202.985 313.806 210.559 313.908 215.81 313.908C218.436 313.908 220.455 313.908 221.869 313.908C222.576 313.908 223.081 313.908 223.485 313.908C223.788 314.009 223.99 314.009 223.99 314.009C223.99 314.009 223.788 314.009 223.485 314.009C223.081 314.009 222.576 314.009 221.97 314.009C220.556 314.009 218.537 314.009 216.012 314.009C210.761 314.009 203.187 314.111 193.796 314.111H193.594V313.908C193.594 305.579 193.594 295.828 193.493 285.162C193.493 279.779 193.493 274.192 193.493 268.301C193.493 265.355 193.493 262.41 193.493 259.464C193.493 257.94 193.493 256.417 193.493 254.893C193.493 254.182 193.493 253.37 193.493 252.557V251.44V250.83V250.525V250.424V250.322C193.291 250.525 193.796 250.018 193.796 250.018C204.5 250.018 214.699 250.018 224.091 250.018H224.292V250.221C224.292 268.809 224.192 284.654 224.192 296.031C224.192 301.617 224.091 306.087 224.091 309.134C224.091 310.657 224.091 311.775 224.091 312.587C224.091 312.994 224.091 313.298 224.091 313.502C223.99 313.908 223.99 314.009 223.99 314.009Z" fill="#464657"/>
<path d="M197.731 281.201C197.63 281.201 198.135 280.794 198.842 281.099C199.145 281.201 199.548 281.607 199.649 282.216C199.649 282.724 199.347 283.334 198.741 283.639C198.135 283.842 197.529 283.537 197.226 283.029C196.923 282.521 197.024 281.912 197.327 281.709C197.529 281.404 197.933 281.302 198.135 281.302C198.337 281.302 198.438 281.404 198.438 281.404C198.438 281.505 197.933 281.404 197.63 281.912C197.529 282.115 197.428 282.521 197.63 282.724C197.832 283.029 198.236 283.232 198.64 283.029C198.943 282.928 199.246 282.521 199.246 282.115C199.246 281.81 199.044 281.505 198.842 281.302C198.135 281.099 197.731 281.302 197.731 281.201Z" fill="#464657"/>
<path d="M187.028 281.201C186.927 281.201 187.432 280.794 188.138 281.099C188.441 281.201 188.845 281.607 188.946 282.216C188.946 282.724 188.643 283.334 188.037 283.639C187.432 283.842 186.826 283.537 186.523 283.029C186.22 282.521 186.321 281.912 186.624 281.709C186.826 281.404 187.23 281.302 187.432 281.302C187.634 281.302 187.735 281.404 187.735 281.404C187.735 281.505 187.23 281.404 186.927 281.912C186.826 282.115 186.725 282.521 186.927 282.724C187.129 283.029 187.533 283.232 187.936 283.029C188.239 282.928 188.542 282.521 188.542 282.115C188.542 281.81 188.34 281.505 188.138 281.302C187.432 281.099 187.028 281.302 187.028 281.201Z" fill="#464657"/>
<path d="M404.037 0.348145H330.32V314.01H404.037V0.348145Z" fill="#939DF4"/>
<path d="M366.473 22.2881H344.055V65.7619H366.473V22.2881Z" fill="#E0E0E0"/>
<path d="M355.869 65.7619C355.768 65.7619 355.566 56.0108 355.566 44.025C355.566 32.0392 355.667 22.2881 355.869 22.2881C355.97 22.2881 356.172 32.0392 356.172 44.025C356.172 56.0108 355.97 65.7619 355.869 65.7619Z" fill="#939DF4"/>
<path d="M366.473 37.4229C366.473 37.5245 361.424 37.7276 355.264 37.7276C349.104 37.7276 344.055 37.626 344.055 37.4229C344.055 37.3213 349.104 37.1182 355.264 37.1182C361.424 37.1182 366.473 37.2197 366.473 37.4229Z" fill="#939DF4"/>
<path d="M366.573 52.9634C366.573 53.065 361.524 53.2681 355.263 53.2681C349.002 53.2681 343.953 53.1666 343.953 52.9634C343.953 52.7603 349.002 52.6587 355.263 52.6587C361.524 52.6587 366.573 52.7603 366.573 52.9634Z" fill="#939DF4"/>
<path d="M435.343 0.348145H381.621V314.01H435.343V0.348145Z" fill="#939DF4"/>
<path opacity="0.1" d="M435.342 314.01L381.418 313.807V1.16064H435.241L435.342 314.01Z" fill="#1B235E"/>
<path d="M419.387 22.2881H396.969V65.7619H419.387V22.2881Z" fill="#E0E0E0"/>
<path d="M408.783 65.7619C408.682 65.7619 408.48 56.0108 408.48 44.025C408.48 32.0392 408.581 22.2881 408.783 22.2881C408.884 22.2881 409.086 32.0392 409.086 44.025C409.086 56.0108 408.985 65.7619 408.783 65.7619Z" fill="#939DF4"/>
<path d="M419.387 37.4229C419.387 37.5245 414.338 37.7276 408.178 37.7276C402.018 37.7276 396.969 37.626 396.969 37.4229C396.969 37.3213 402.018 37.1182 408.178 37.1182C414.338 37.1182 419.387 37.2197 419.387 37.4229Z" fill="#939DF4"/>
<path d="M419.487 52.9634C419.487 53.065 414.438 53.2681 408.177 53.2681C401.916 53.2681 396.867 53.1666 396.867 52.9634C396.867 52.7603 401.916 52.6587 408.177 52.6587C414.438 52.6587 419.487 52.7603 419.487 52.9634Z" fill="#939DF4"/>
<path d="M419.387 75.7158H396.969V119.19H419.387V75.7158Z" fill="#E0E0E0"/>
<path d="M408.783 119.19C408.682 119.19 408.48 109.439 408.48 97.4527C408.48 85.467 408.581 75.7158 408.783 75.7158C408.884 75.7158 409.086 85.467 409.086 97.4527C409.086 109.439 408.985 119.19 408.783 119.19Z" fill="#939DF4"/>
<path d="M419.387 90.8506C419.387 90.9522 414.338 91.1553 408.178 91.1553C402.018 91.1553 396.969 91.0537 396.969 90.8506C396.969 90.6475 402.018 90.5459 408.178 90.5459C414.338 90.5459 419.387 90.749 419.387 90.8506Z" fill="#939DF4"/>
<path d="M419.487 106.391C419.487 106.493 414.438 106.696 408.177 106.696C401.916 106.696 396.867 106.594 396.867 106.391C396.867 106.29 401.916 106.086 408.177 106.086C414.438 106.086 419.487 106.29 419.487 106.391Z" fill="#939DF4"/>
<path opacity="0.3" d="M381.316 0.348145H330.32V314.01H381.316V0.348145Z" fill="#1B235E"/>
<path d="M301.84 84.2485C302.244 84.9596 302.143 85.7722 301.941 86.4832C301.739 87.1942 301.538 88.0068 301.739 88.8194C301.84 89.3273 302.244 89.7336 302.345 90.2414C302.648 91.1556 302.143 92.2729 301.235 92.6792C299.922 92.0698 298.508 91.4603 297.195 90.8509C298.205 89.0225 298.912 86.9911 299.114 84.9596" fill="#939DF4"/>
<g opacity="0.5">
<g opacity="0.5">
<path opacity="0.5" d="M301.84 84.2485C302.244 84.9596 302.143 85.7722 301.941 86.4832C301.739 87.1942 301.538 88.0068 301.739 88.8194C301.84 89.3273 302.244 89.7336 302.345 90.2414C302.648 91.1556 302.143 92.2729 301.235 92.6792C299.922 92.0698 298.508 91.4603 297.195 90.8509C298.205 89.0225 298.912 86.9911 299.114 84.9596" fill="#1B235E"/>
</g>
</g>
<path d="M291.236 83.3347C292.448 83.6394 290.428 85.6709 290.529 86.8898C290.63 88.1087 290.832 89.4291 290.327 90.5465C287.904 90.5465 285.48 90.0386 283.359 88.9213C283.965 86.4835 284.47 84.0457 285.076 81.7095C284.975 81.7095 290.125 83.0299 291.236 83.3347Z" fill="#939DF4"/>
<path d="M355.866 63.2227H318.906V116.956H355.866V63.2227Z" fill="#464657"/>
<path d="M389.088 63.2227H352.129V116.956H389.088V63.2227Z" fill="#6C778B"/>
<path d="M380.302 106.087C380.302 106.087 380.302 105.884 380.302 105.478C380.302 105.071 380.302 104.462 380.302 103.751C380.302 102.227 380.302 100.094 380.201 97.3517C380.201 91.8667 380.1 84.2486 380.1 75.4116L380.302 75.6148C375.152 75.6148 369.497 75.6148 363.539 75.6148C363.438 75.7164 364.044 75.1069 363.842 75.3101V75.4116V75.5132V75.8179V76.3258V77.4431C363.842 78.1542 363.842 78.8652 363.842 79.5762C363.842 80.9982 363.842 82.4203 363.842 83.8423C363.842 86.5848 363.842 89.3273 363.842 91.8667C363.842 97.047 363.842 101.719 363.842 105.884L363.64 105.681C368.79 105.681 372.93 105.782 375.858 105.782C377.272 105.782 378.383 105.782 379.191 105.884C379.595 105.884 379.797 105.884 380.1 105.884C380.201 106.087 380.302 106.087 380.302 106.087C380.302 106.087 380.201 106.087 379.999 106.087C379.797 106.087 379.494 106.087 379.191 106.087C378.383 106.087 377.272 106.087 375.858 106.189C372.93 106.189 368.689 106.29 363.539 106.29H363.337V106.087C363.337 102.024 363.337 97.2502 363.337 92.0699C363.337 89.5305 363.337 86.788 363.337 84.0455C363.337 82.6234 363.337 81.2014 363.337 79.7793C363.337 79.0683 363.337 78.3573 363.337 77.6463V76.529V76.0211V75.7164V75.6148V75.5132C363.135 75.7164 363.64 75.2085 363.64 75.2085C369.598 75.2085 375.253 75.2085 380.403 75.2085H380.605V75.4116C380.605 84.3502 380.504 92.0698 380.504 97.5549C380.504 100.196 380.403 102.329 380.403 103.852C380.403 104.564 380.403 105.071 380.403 105.478C380.302 105.986 380.302 106.189 380.302 106.087Z" fill="#464657"/>
<path d="M376.972 92.4758C376.972 92.5773 376.77 92.6789 376.77 92.9836C376.669 93.2884 376.972 93.7962 377.376 93.5931C377.881 93.3899 377.78 92.9836 377.477 92.6789C377.174 92.4758 376.972 92.5773 376.972 92.4758C376.972 92.4758 377.275 92.171 377.679 92.4758C377.881 92.5773 378.184 92.8821 378.184 93.1868C378.184 93.5931 377.881 93.9994 377.477 94.101C377.073 94.2025 376.568 94.1009 376.367 93.7962C376.165 93.4915 376.165 93.0852 376.266 92.8821C376.568 92.3742 376.972 92.3742 376.972 92.4758Z" fill="#464657"/>
<path d="M427.259 107.509C427.259 107.509 427.259 107.306 427.259 106.798C427.259 106.29 427.259 105.579 427.259 104.767C427.259 102.938 427.259 100.196 427.158 96.6407L427.259 96.7422C411.607 96.8438 378.788 96.8438 340.818 96.8438C301.839 96.8438 268.313 96.7422 252.964 96.7422L253.065 96.6407C253.065 99.6879 253.065 102.024 252.964 103.649C252.964 104.462 252.964 105.071 252.964 105.478C252.964 105.884 252.964 106.087 252.964 106.087C252.964 106.087 252.964 105.884 252.964 105.478C252.964 105.071 252.964 104.462 252.964 103.649C252.964 102.024 252.964 99.6879 252.863 96.6407V96.5391H252.964C268.212 96.4375 301.839 96.4375 340.818 96.4375C378.687 96.4375 411.506 96.5391 427.259 96.5391H427.36V96.6407C427.36 100.196 427.36 102.837 427.259 104.767C427.259 105.681 427.259 106.392 427.259 106.798C427.259 107.204 427.259 107.509 427.259 107.509Z" fill="#464657"/>
<path d="M258.619 107.204C258.518 107.204 258.316 104.868 258.316 102.024C258.316 99.18 258.417 96.8438 258.619 96.8438C258.72 96.8438 258.922 99.18 258.922 102.024C258.821 104.868 258.72 107.204 258.619 107.204Z" fill="#464657"/>
<path d="M265.283 107.204C265.182 107.204 264.98 104.868 264.98 102.024C264.98 99.18 265.081 96.8438 265.283 96.8438C265.485 96.8438 265.586 99.18 265.586 102.024C265.485 104.868 265.384 107.204 265.283 107.204Z" fill="#464657"/>
<path d="M278.614 107.205C278.614 107.205 278.614 107.001 278.614 106.595C278.614 106.087 278.614 105.478 278.614 104.665C278.614 102.938 278.614 100.602 278.513 97.6566C278.513 91.6637 278.412 83.5377 278.412 74.2945L278.715 74.5992C277.705 74.5992 276.493 74.5992 275.383 74.5992C274.272 74.5992 273.06 74.5992 272.05 74.5992L272.353 74.2945C272.353 83.4362 272.252 91.6637 272.252 97.555C272.252 100.501 272.151 102.837 272.151 104.462C272.151 105.275 272.151 105.884 272.151 106.29C272.151 106.697 272.151 106.9 272.151 106.9C272.151 106.9 272.151 106.697 272.151 106.29C272.151 105.783 272.151 105.173 272.151 104.462C272.151 102.735 272.151 100.399 272.05 97.555C272.05 91.5621 271.949 83.4362 271.949 74.2945V73.9897H272.252C273.363 73.9897 274.474 73.9897 275.585 73.9897C276.695 73.9897 277.806 73.9897 278.917 73.9897H279.22V74.2945C279.22 83.4362 279.119 91.6637 279.119 97.6566C279.119 100.602 279.018 102.938 279.018 104.665C279.018 105.478 279.018 106.087 279.018 106.595C278.614 106.9 278.614 107.205 278.614 107.205Z" fill="#464657"/>
<path d="M285.279 107.204C285.179 107.204 284.977 104.868 284.977 102.024C284.977 99.18 285.078 96.8438 285.279 96.8438C285.481 96.8438 285.582 99.18 285.582 102.024C285.481 104.868 285.38 107.204 285.279 107.204Z" fill="#464657"/>
<path d="M291.944 107.204C291.843 107.204 291.641 104.868 291.641 102.024C291.641 99.18 291.742 96.8438 291.944 96.8438C292.045 96.8438 292.247 99.18 292.247 102.024C292.247 104.868 292.045 107.204 291.944 107.204Z" fill="#464657"/>
<path d="M298.608 107.204C298.507 107.204 298.305 104.868 298.305 102.024C298.305 99.18 298.406 96.8438 298.608 96.8438C298.709 96.8438 298.911 99.18 298.911 102.024C298.911 104.868 298.709 107.204 298.608 107.204Z" fill="#464657"/>
<path d="M305.272 107.204C305.171 107.204 304.969 104.868 304.969 102.024C304.969 99.18 305.07 96.8438 305.272 96.8438C305.474 96.8438 305.575 99.18 305.575 102.024C305.575 104.868 305.474 107.204 305.272 107.204Z" fill="#464657"/>
<path d="M311.936 107.204C311.835 107.204 311.633 104.868 311.633 102.024C311.633 99.18 311.734 96.8438 311.936 96.8438C312.037 96.8438 312.239 99.18 312.239 102.024C312.239 104.868 312.138 107.204 311.936 107.204Z" fill="#464657"/>
<path d="M318.604 107.204C318.503 107.204 318.301 104.868 318.301 102.024C318.301 99.18 318.402 96.8438 318.604 96.8438C318.806 96.8438 318.907 99.18 318.907 102.024C318.907 104.868 318.806 107.204 318.604 107.204Z" fill="#464657"/>
<path d="M325.268 107.204C325.167 107.204 324.965 104.868 324.965 102.024C324.965 99.18 325.066 96.8438 325.268 96.8438C325.369 96.8438 325.571 99.18 325.571 102.024C325.571 104.868 325.47 107.204 325.268 107.204Z" fill="#464657"/>
<path d="M331.932 107.204C331.831 107.204 331.629 104.868 331.629 102.024C331.629 99.18 331.73 96.8438 331.932 96.8438C332.134 96.8438 332.235 99.18 332.235 102.024C332.235 104.868 332.134 107.204 331.932 107.204Z" fill="#464657"/>
<path d="M338.596 107.204C338.495 107.204 338.293 104.868 338.293 102.024C338.293 99.18 338.394 96.8438 338.596 96.8438C338.697 96.8438 338.899 99.18 338.899 102.024C338.899 104.868 338.798 107.204 338.596 107.204Z" fill="#464657"/>
<path d="M345.26 107.204C345.159 107.204 344.957 104.868 344.957 102.024C344.957 99.18 345.058 96.8438 345.26 96.8438C345.462 96.8438 345.563 99.18 345.563 102.024C345.563 104.868 345.462 107.204 345.26 107.204Z" fill="#464657"/>
<path d="M352.026 107.204C351.925 107.204 351.723 104.868 351.723 102.024C351.723 99.18 351.824 96.8438 352.026 96.8438C352.127 96.8438 352.328 99.18 352.328 102.024C352.228 104.868 352.127 107.204 352.026 107.204Z" fill="#464657"/>
<path d="M358.694 107.204C358.593 107.204 358.391 104.868 358.391 102.024C358.391 99.18 358.492 96.8438 358.694 96.8438C358.795 96.8438 358.996 99.18 358.996 102.024C358.895 104.868 358.795 107.204 358.694 107.204Z" fill="#464657"/>
<path d="M365.358 107.204C365.257 107.204 365.055 104.868 365.055 102.024C365.055 99.18 365.156 96.8438 365.358 96.8438C365.56 96.8438 365.661 99.18 365.661 102.024C365.56 104.868 365.459 107.204 365.358 107.204Z" fill="#464657"/>
<path d="M372.022 107.204C371.921 107.204 371.719 104.868 371.719 102.024C371.719 99.18 371.82 96.8438 372.022 96.8438C372.123 96.8438 372.325 99.18 372.325 102.024C372.224 104.868 372.123 107.204 372.022 107.204Z" fill="#464657"/>
<path d="M378.686 107.204C378.585 107.204 378.383 104.868 378.383 102.024C378.383 99.18 378.484 96.8438 378.686 96.8438C378.787 96.8438 378.989 99.18 378.989 102.024C378.888 104.868 378.787 107.204 378.686 107.204Z" fill="#464657"/>
<path d="M385.35 107.204C385.249 107.204 385.047 104.868 385.047 102.024C385.047 99.18 385.148 96.8438 385.35 96.8438C385.552 96.8438 385.653 99.18 385.653 102.024C385.552 104.868 385.451 107.204 385.35 107.204Z" fill="#464657"/>
<path d="M392.018 107.204C391.917 107.204 391.715 104.868 391.715 102.024C391.715 99.18 391.816 96.8438 392.018 96.8438C392.119 96.8438 392.321 99.18 392.321 102.024C392.22 104.868 392.119 107.204 392.018 107.204Z" fill="#464657"/>
<path d="M398.682 107.204C398.581 107.204 398.379 104.868 398.379 102.024C398.379 99.18 398.48 96.8438 398.682 96.8438C398.884 96.8438 398.985 99.18 398.985 102.024C398.985 104.868 398.783 107.204 398.682 107.204Z" fill="#464657"/>
<path d="M405.346 107.204C405.245 107.204 405.043 104.868 405.043 102.024C405.043 99.18 405.144 96.8438 405.346 96.8438C405.447 96.8438 405.649 99.18 405.649 102.024C405.649 104.868 405.447 107.204 405.346 107.204Z" fill="#464657"/>
<path d="M412.01 107.204C411.909 107.204 411.707 104.868 411.707 102.024C411.707 99.18 411.808 96.8438 412.01 96.8438C412.111 96.8438 412.313 99.18 412.313 102.024C412.313 104.868 412.212 107.204 412.01 107.204Z" fill="#464657"/>
<path d="M418.674 107.204C418.573 107.204 418.371 104.868 418.371 102.024C418.371 99.18 418.472 96.8438 418.674 96.8438C418.775 96.8438 418.977 99.18 418.977 102.024C418.977 104.868 418.876 107.204 418.674 107.204Z" fill="#464657"/>
<path d="M425.342 107.204C425.241 107.204 425.039 104.868 425.039 102.024C425.039 99.18 425.14 96.8438 425.342 96.8438C425.544 96.8438 425.645 99.18 425.645 102.024C425.645 104.868 425.544 107.204 425.342 107.204Z" fill="#464657"/>
<path d="M427.261 106.087C427.261 106.189 406.66 106.392 381.314 106.392C355.967 106.392 335.367 106.291 335.367 106.087C335.367 105.986 355.967 105.783 381.314 105.783C406.66 105.884 427.261 105.986 427.261 106.087Z" fill="#464657"/>
<path d="M427.259 106.087H273.867V314.01H427.259V106.087Z" fill="#6C778B"/>
<path d="M335.265 106.087H252.965V314.01H335.265V106.087Z" fill="#464657"/>
<path d="M415.952 218.937H387.879V247.174H415.952V218.937Z" fill="#EBEBEB"/>
<path d="M403.026 250.018C402.925 250.018 402.723 242.705 402.723 233.766C402.723 224.726 402.824 217.514 403.026 217.514C403.127 217.514 403.329 224.828 403.329 233.766C403.228 242.705 403.127 250.018 403.026 250.018Z" fill="#6C778B"/>
<path d="M421.201 228.382C421.201 228.484 413.022 228.687 403.024 228.687C392.926 228.687 384.848 228.586 384.848 228.382C384.848 228.281 393.027 228.078 403.024 228.078C413.022 228.179 421.201 228.281 421.201 228.382Z" fill="#6C778B"/>
<path d="M375.964 218.937H347.891V247.174H375.964V218.937Z" fill="#EBEBEB"/>
<path d="M363.037 250.018C362.936 250.018 362.734 242.705 362.734 233.766C362.734 224.726 362.835 217.514 363.037 217.514C363.138 217.514 363.34 224.828 363.34 233.766C363.239 242.705 363.138 250.018 363.037 250.018Z" fill="#6C778B"/>
<path d="M381.213 228.382C381.213 228.484 373.033 228.687 363.036 228.687C352.938 228.687 344.859 228.586 344.859 228.382C344.859 228.281 353.039 228.078 363.036 228.078C373.033 228.179 381.213 228.281 381.213 228.382Z" fill="#6C778B"/>
<path d="M415.952 174.344H387.879V202.582H415.952V174.344Z" fill="#EBEBEB"/>
<path d="M403.026 205.426C402.925 205.426 402.723 198.113 402.723 189.174C402.723 180.134 402.824 172.922 403.026 172.922C403.127 172.922 403.329 180.236 403.329 189.174C403.228 198.113 403.127 205.426 403.026 205.426Z" fill="#6C778B"/>
<path d="M421.201 183.791C421.201 183.892 413.022 184.095 403.024 184.095C392.926 184.095 384.848 183.994 384.848 183.791C384.848 183.689 393.027 183.486 403.024 183.486C413.022 183.587 421.201 183.689 421.201 183.791Z" fill="#6C778B"/>
<path d="M375.964 174.344H347.891V202.582H375.964V174.344Z" fill="#EBEBEB"/>
<path d="M363.037 205.426C362.936 205.426 362.734 198.113 362.734 189.174C362.734 180.134 362.835 172.922 363.037 172.922C363.138 172.922 363.34 180.236 363.34 189.174C363.239 198.113 363.138 205.426 363.037 205.426Z" fill="#6C778B"/>
<path d="M381.213 183.791C381.213 183.892 373.033 184.095 363.036 184.095C352.938 184.095 344.859 183.994 344.859 183.791C344.859 183.689 353.039 183.486 363.036 183.486C373.033 183.587 381.213 183.689 381.213 183.791Z" fill="#6C778B"/>
<path d="M415.952 129.652H387.879V157.89H415.952V129.652Z" fill="#EBEBEB"/>
<path d="M403.026 160.734C402.925 160.734 402.723 153.42 402.723 144.482C402.723 135.442 402.824 128.23 403.026 128.23C403.127 128.23 403.329 135.543 403.329 144.482C403.228 153.42 403.127 160.734 403.026 160.734Z" fill="#6C778B"/>
<path d="M421.201 139.098C421.201 139.2 413.022 139.403 403.024 139.403C392.926 139.403 384.848 139.301 384.848 139.098C384.848 138.997 393.027 138.793 403.024 138.793C413.022 138.895 421.201 138.997 421.201 139.098Z" fill="#6C778B"/>
<path d="M375.964 129.652H347.891V157.89H375.964V129.652Z" fill="#EBEBEB"/>
<path d="M363.037 160.734C362.936 160.734 362.734 153.42 362.734 144.482C362.734 135.442 362.835 128.23 363.037 128.23C363.138 128.23 363.34 135.543 363.34 144.482C363.239 153.42 363.138 160.734 363.037 160.734Z" fill="#6C778B"/>
<path d="M381.213 139.098C381.213 139.2 373.033 139.403 363.036 139.403C352.938 139.403 344.859 139.301 344.859 139.098C344.859 138.997 353.039 138.793 363.036 138.793C373.033 138.895 381.213 138.997 381.213 139.098Z" fill="#6C778B"/>
<g opacity="0.7">
<path opacity="0.7" d="M307.092 129.652H279.02V157.89H307.092V129.652Z" fill="white"/>
</g>
<path d="M293.057 160.734C292.956 160.734 292.754 153.42 292.754 144.482C292.754 135.442 292.855 128.23 293.057 128.23C293.259 128.23 293.36 135.543 293.36 144.482C293.36 153.42 293.158 160.734 293.057 160.734Z" fill="#464657"/>
<path d="M312.342 139.098C312.342 139.2 304.162 139.403 294.165 139.403C284.067 139.403 275.988 139.301 275.988 139.098C275.988 138.997 284.168 138.793 294.165 138.793C304.162 138.895 312.342 138.997 312.342 139.098Z" fill="#464657"/>
<g opacity="0.7">
<path opacity="0.7" d="M307.092 174.852H279.02V203.09H307.092V174.852Z" fill="white"/>
</g>
<path d="M293.057 205.832C292.956 205.832 292.754 198.519 292.754 189.581C292.754 180.54 292.855 173.329 293.057 173.329C293.259 173.329 293.36 180.642 293.36 189.581C293.36 198.519 293.158 205.832 293.057 205.832Z" fill="#464657"/>
<path d="M312.342 184.299C312.342 184.4 304.162 184.604 294.165 184.604C284.067 184.604 275.988 184.502 275.988 184.299C275.988 184.197 284.168 183.994 294.165 183.994C304.162 183.994 312.342 184.096 312.342 184.299Z" fill="#464657"/>
<g opacity="0.7">
<path opacity="0.7" d="M307.092 219.952H279.02V248.189H307.092V219.952Z" fill="white"/>
</g>
<path d="M293.057 250.932C292.956 250.932 292.754 243.619 292.754 234.68C292.754 225.64 292.855 218.428 293.057 218.428C293.259 218.428 293.36 225.742 293.36 234.68C293.36 243.72 293.158 250.932 293.057 250.932Z" fill="#464657"/>
<path d="M312.342 229.398C312.342 229.5 304.162 229.703 294.165 229.703C284.067 229.703 275.988 229.602 275.988 229.398C275.988 229.195 284.168 229.094 294.165 229.094C304.162 229.094 312.342 229.195 312.342 229.398Z" fill="#464657"/>
<path d="M395.045 314.009C395.045 314.009 395.045 313.908 395.045 313.806C395.045 313.603 395.045 313.4 395.045 313.095C395.045 312.384 395.045 311.47 395.045 310.251C395.045 307.712 395.045 304.055 394.944 299.484C394.944 290.343 394.844 277.544 394.844 262.816L395.146 263.121C390.501 263.121 385.553 263.121 380.504 263.121C375.657 263.121 370.911 263.121 366.367 263.121L366.67 262.816C366.67 277.646 366.569 290.546 366.569 299.789C366.569 304.36 366.468 308.017 366.468 310.658C366.468 311.876 366.468 312.892 366.468 313.603C366.468 313.908 366.468 314.111 366.468 314.314C366.468 314.517 366.468 314.619 366.468 314.619C366.468 314.619 366.468 314.517 366.468 314.314C366.468 314.111 366.468 313.908 366.468 313.603C366.468 312.892 366.468 311.978 366.468 310.658C366.468 308.118 366.468 304.36 366.367 299.789C366.367 290.546 366.266 277.544 366.266 262.816V262.511H366.569C371.113 262.511 375.859 262.511 380.706 262.511C385.755 262.511 390.602 262.511 395.348 262.511H395.651V262.816C395.651 277.443 395.55 290.343 395.55 299.484C395.55 304.055 395.449 307.712 395.449 310.251C395.449 311.47 395.449 312.486 395.449 313.095C395.449 313.4 395.449 313.603 395.449 313.806C395.045 313.908 395.045 314.009 395.045 314.009Z" fill="#464657"/>
<path d="M379.701 265.762H369.906V289.327H379.701V265.762Z" fill="#E0E0E0"/>
<path d="M379.697 289.429H369.801V265.762H379.697V289.429ZM369.902 289.327H379.697V265.762H369.902V289.327Z" fill="#464657"/>
<path d="M391.416 265.762H381.621V289.327H391.416V265.762Z" fill="#E0E0E0"/>
<path d="M391.412 289.429H381.516V265.762H391.412V289.429ZM381.617 289.327H391.412V265.762H381.617V289.327Z" fill="#464657"/>
<path d="M391.613 291.765C391.613 291.765 391.714 291.663 391.915 291.663C392.117 291.663 392.42 291.867 392.622 292.171C392.824 292.476 392.925 292.984 392.723 293.492C392.521 294 391.915 294.406 391.209 294.304C390.502 294.203 390.199 293.593 390.098 293.085C389.997 292.476 390.401 292.07 390.704 291.867C391.411 291.46 391.915 291.765 391.814 291.765C391.814 291.867 391.31 291.765 390.906 292.171C390.704 292.374 390.502 292.679 390.603 292.984C390.603 293.289 390.906 293.695 391.31 293.796C391.713 293.898 392.117 293.593 392.218 293.289C392.42 292.984 392.319 292.578 392.218 292.374C392.117 291.867 391.613 291.867 391.613 291.765Z" fill="#464657"/>
<path d="M421.808 262.715C421.808 262.715 421.808 262.613 421.808 262.309C421.808 262.004 421.808 261.699 421.808 261.293C421.808 260.379 421.808 259.058 421.808 257.535L421.909 257.636C417.97 257.636 412.113 257.636 405.55 257.636C405.55 257.636 405.449 257.738 405.651 257.535V257.636V257.839V258.144V258.754C405.651 259.16 405.651 259.566 405.651 260.074C405.651 260.887 405.651 261.699 405.651 262.512L405.55 262.41C410.195 262.41 414.234 262.41 417.263 262.41C418.677 262.41 419.788 262.41 420.697 262.41C421.101 262.41 421.404 262.41 421.606 262.41C421.707 262.715 421.808 262.715 421.808 262.715C421.808 262.715 421.707 262.715 421.505 262.715C421.202 262.715 420.899 262.715 420.596 262.715C419.687 262.715 418.576 262.715 417.162 262.715C414.133 262.715 410.094 262.715 405.449 262.715H405.348V262.613C405.348 261.801 405.348 260.988 405.348 260.176C405.348 259.769 405.348 259.363 405.348 258.855V258.246V257.941V257.738V257.636C405.55 257.433 405.449 257.535 405.449 257.535C412.012 257.535 417.869 257.535 421.808 257.535H421.909V257.636C421.909 259.16 421.909 260.379 421.909 261.394C421.909 261.801 421.909 262.105 421.909 262.41C421.808 262.613 421.808 262.715 421.808 262.715Z" fill="#464657"/>
<path d="M358.085 252.253C358.085 252.253 358.085 252.151 358.085 251.846C358.085 251.542 358.085 251.237 358.085 250.831C358.085 249.916 358.085 248.596 358.085 247.072L358.186 247.174C354.248 247.174 348.391 247.174 341.827 247.174C341.827 247.174 341.726 247.275 341.928 247.072V247.174V247.377V247.682V248.291C341.928 248.697 341.928 249.104 341.928 249.612C341.928 250.424 341.928 251.237 341.928 252.049L341.827 251.948C346.472 251.948 350.511 251.948 353.541 251.948C354.955 251.948 356.065 251.948 356.974 251.948C357.378 251.948 357.681 251.948 357.883 251.948C357.984 252.253 358.085 252.253 358.085 252.253C358.085 252.253 357.984 252.253 357.782 252.253C357.479 252.253 357.176 252.253 356.873 252.253C355.964 252.253 354.854 252.253 353.44 252.253C350.41 252.253 346.371 252.253 341.726 252.253H341.625V252.151C341.625 251.338 341.625 250.526 341.625 249.713C341.625 249.307 341.625 248.901 341.625 248.393V247.783V247.479V247.377V247.275C341.827 247.072 341.726 247.174 341.726 247.174C348.29 247.174 354.147 247.174 358.085 247.174H358.186V247.275C358.186 248.799 358.186 250.018 358.186 251.034C358.186 251.44 358.186 251.745 358.186 252.049C358.085 252.151 358.085 252.253 358.085 252.253Z" fill="#464657"/>
<path d="M347.886 211.013C347.886 211.013 347.886 210.912 347.886 210.607C347.886 210.302 347.886 209.998 347.886 209.591C347.886 208.677 347.886 207.357 347.886 205.833L347.987 205.935C344.048 205.935 338.192 205.935 331.628 205.935C331.628 205.935 331.527 206.036 331.729 205.833V205.935V206.138V206.442V207.052C331.729 207.458 331.729 207.864 331.729 208.372C331.729 209.185 331.729 209.998 331.729 210.81L331.628 210.709C336.273 210.709 340.312 210.709 343.342 210.709C344.755 210.709 345.866 210.709 346.775 210.709C347.179 210.709 347.482 210.709 347.684 210.709C347.785 211.013 347.886 211.013 347.886 211.013C347.886 211.013 347.785 211.013 347.583 211.013C347.28 211.013 346.977 211.013 346.674 211.013C345.765 211.013 344.654 211.013 343.241 211.013C340.211 211.013 336.172 211.013 331.527 211.013H331.426V210.912C331.426 210.099 331.426 209.287 331.426 208.474C331.426 208.068 331.426 207.661 331.426 207.153V206.544V206.239V206.036V205.935C331.628 205.731 331.527 205.833 331.527 205.833C338.091 205.833 343.947 205.833 347.886 205.833H347.987V205.935C347.987 207.458 347.987 208.677 347.987 209.693C347.987 210.099 347.987 210.404 347.987 210.709C347.886 210.912 347.886 211.013 347.886 211.013Z" fill="#464657"/>
<path d="M406.761 166.524C406.761 166.524 406.761 166.422 406.761 166.118C406.761 165.813 406.761 165.508 406.761 165.102C406.761 164.188 406.761 162.867 406.761 161.344L406.862 161.445C402.924 161.445 397.067 161.445 390.503 161.445C390.503 161.445 390.402 161.547 390.604 161.344V161.445V161.648V161.953V162.563C390.604 162.969 390.604 163.375 390.604 163.883C390.604 164.696 390.604 165.508 390.604 166.321L390.503 166.219C395.148 166.219 399.187 166.219 402.217 166.219C403.63 166.219 404.741 166.219 405.65 166.219C406.054 166.219 406.357 166.219 406.559 166.219C406.66 166.524 406.761 166.524 406.761 166.524C406.761 166.524 406.66 166.524 406.458 166.524C406.155 166.524 405.852 166.524 405.549 166.524C404.64 166.524 403.529 166.524 402.116 166.524C399.086 166.524 395.047 166.524 390.402 166.524H390.301V166.422C390.301 165.61 390.301 164.797 390.301 163.985C390.301 163.578 390.301 163.172 390.301 162.664V162.055V161.75V161.547V161.445C390.503 161.242 390.402 161.344 390.402 161.344C396.966 161.344 402.823 161.344 406.761 161.344H406.862V161.445C406.862 162.969 406.862 164.188 406.862 165.204C406.862 165.61 406.862 165.915 406.862 166.219C406.761 166.422 406.761 166.524 406.761 166.524Z" fill="#464657"/>
<path d="M360.206 119.19C360.206 119.19 360.206 119.088 360.206 118.784C360.206 118.479 360.206 118.174 360.206 117.768C360.206 116.854 360.206 115.533 360.206 114.01L360.307 114.111C356.369 114.111 350.512 114.111 343.948 114.111C343.948 114.111 343.847 114.213 344.049 114.01V114.111V114.314V114.619V115.229C344.049 115.635 344.049 116.041 344.049 116.549C344.049 117.362 344.049 118.174 344.049 118.987L343.948 118.885C348.593 118.885 352.633 118.885 355.662 118.885C357.076 118.885 358.187 118.885 359.095 118.885C359.499 118.885 359.802 118.885 360.004 118.885C360.004 119.19 360.206 119.19 360.206 119.19C360.206 119.19 360.105 119.19 359.903 119.19C359.6 119.19 359.297 119.19 358.994 119.19C358.086 119.19 356.975 119.19 355.561 119.19C352.532 119.19 348.492 119.19 343.847 119.19H343.746V119.088C343.746 118.276 343.746 117.463 343.746 116.651C343.746 116.244 343.746 115.838 343.746 115.33V114.721V114.416V114.213V114.111C343.948 113.908 343.847 114.01 343.847 114.01C350.411 114.01 356.268 114.01 360.206 114.01H360.307V114.111C360.307 115.635 360.307 116.854 360.307 117.87C360.307 118.276 360.307 118.581 360.307 118.885C360.206 119.088 360.206 119.19 360.206 119.19Z" fill="#464657"/>
<path d="M416.655 171.501C416.655 171.501 416.655 171.399 416.655 171.095C416.655 170.79 416.655 170.485 416.655 170.079C416.655 169.165 416.655 167.844 416.655 166.321L416.756 166.422C412.818 166.422 406.961 166.422 400.397 166.422C400.397 166.422 400.296 166.524 400.498 166.321V166.422V166.626V166.93V167.54C400.498 167.946 400.498 168.352 400.498 168.86C400.498 169.673 400.498 170.485 400.498 171.298L400.397 171.196C405.042 171.196 409.082 171.196 412.111 171.196C413.525 171.196 414.636 171.196 415.545 171.196C415.949 171.196 416.252 171.196 416.453 171.196C416.554 171.501 416.655 171.501 416.655 171.501C416.655 171.501 416.554 171.501 416.352 171.501C416.05 171.501 415.747 171.501 415.444 171.501C414.535 171.501 413.424 171.501 412.01 171.501C408.981 171.501 404.941 171.501 400.296 171.501H400.195V171.4C400.195 170.587 400.195 169.774 400.195 168.962C400.195 168.555 400.195 168.149 400.195 167.641V167.032V166.727V166.524V166.422C400.397 166.219 400.296 166.321 400.296 166.321C406.86 166.321 412.717 166.321 416.655 166.321H416.756V166.422C416.756 167.946 416.756 169.165 416.756 170.181C416.756 170.587 416.756 170.892 416.756 171.196C416.655 171.399 416.655 171.501 416.655 171.501Z" fill="#464657"/>
<path d="M340.921 215.889C340.921 215.889 340.921 215.787 340.921 215.483C340.921 215.178 340.921 214.873 340.921 214.467C340.921 213.553 340.921 212.232 340.921 210.708L341.022 210.81C337.084 210.81 331.227 210.81 324.663 210.81C324.663 210.81 324.562 210.912 324.764 210.708V210.81V211.013V211.318V211.927C324.764 212.334 324.764 212.74 324.764 213.248C324.764 214.06 324.764 214.873 324.764 215.686L324.663 215.584C329.308 215.584 333.347 215.584 336.377 215.584C337.791 215.584 338.901 215.584 339.81 215.584C340.214 215.584 340.517 215.584 340.719 215.584C340.719 215.889 340.921 215.889 340.921 215.889C340.921 215.889 340.82 215.889 340.618 215.889C340.315 215.889 340.012 215.889 339.709 215.889C338.8 215.889 337.69 215.889 336.276 215.889C333.246 215.889 329.207 215.889 324.562 215.889H324.461V215.787C324.461 214.975 324.461 214.162 324.461 213.349C324.461 212.943 324.461 212.537 324.461 212.029V211.42V211.115V210.912V210.81C324.663 210.607 324.562 210.708 324.562 210.708C331.126 210.708 336.983 210.708 340.921 210.708H341.022V210.81C341.022 212.334 341.022 213.553 341.022 214.568C341.022 214.975 341.022 215.279 341.022 215.584C340.921 215.787 340.921 215.889 340.921 215.889Z" fill="#464657"/>
<path d="M347.886 124.269C347.886 124.269 347.886 124.167 347.886 123.862C347.886 123.558 347.886 123.253 347.886 122.847C347.886 121.932 347.886 120.612 347.886 119.088L347.987 119.19C344.048 119.19 338.192 119.19 331.628 119.19C331.628 119.19 331.527 119.292 331.729 119.088V119.19V119.393V119.698V120.307C331.729 120.714 331.729 121.12 331.729 121.628C331.729 122.44 331.729 123.253 331.729 124.066L331.628 123.964C336.273 123.964 340.312 123.964 343.342 123.964C344.755 123.964 345.866 123.964 346.775 123.964C347.179 123.964 347.482 123.964 347.684 123.964C347.785 124.269 347.886 124.269 347.886 124.269C347.886 124.269 347.785 124.269 347.583 124.269C347.28 124.269 346.977 124.269 346.674 124.269C345.765 124.269 344.654 124.269 343.241 124.269C340.211 124.269 336.172 124.269 331.527 124.269H331.426V124.167C331.426 123.355 331.426 122.542 331.426 121.729C331.426 121.323 331.426 120.917 331.426 120.409V119.799V119.495V119.292V119.19C331.628 118.987 331.527 119.088 331.527 119.088C338.091 119.088 343.947 119.088 347.886 119.088H347.987V119.19C347.987 120.714 347.987 121.932 347.987 122.948C347.987 123.355 347.987 123.659 347.987 123.964C347.886 124.167 347.886 124.269 347.886 124.269Z" fill="#464657"/>
<path d="M427.058 267.794C427.058 267.794 427.058 267.692 427.058 267.387C427.058 267.083 427.058 266.778 427.058 266.372C427.058 265.457 427.058 264.137 427.058 262.613L427.159 262.715C423.22 262.715 417.363 262.715 410.8 262.715C410.8 262.715 410.699 262.816 410.901 262.613V262.715V262.918V263.223V263.832C410.901 264.238 410.901 264.645 410.901 265.153C410.901 265.965 410.901 266.778 410.901 267.59L410.8 267.489C415.445 267.489 419.484 267.489 422.514 267.489C423.927 267.489 425.038 267.489 425.947 267.489C426.351 267.489 426.654 267.489 426.856 267.489C426.957 267.794 427.058 267.794 427.058 267.794C427.058 267.794 426.957 267.794 426.755 267.794C426.452 267.794 426.149 267.794 425.846 267.794C424.937 267.794 423.826 267.794 422.413 267.794C419.383 267.794 415.344 267.794 410.699 267.794H410.598V267.692C410.598 266.879 410.598 266.067 410.598 265.254C410.598 264.848 410.598 264.442 410.598 263.934V263.324V263.02V262.816V262.715C410.8 262.512 410.699 262.613 410.699 262.613C417.262 262.613 423.119 262.613 427.058 262.613H427.159V262.715C427.159 264.238 427.159 265.457 427.159 266.473C427.159 266.879 427.159 267.184 427.159 267.489C427.058 267.692 427.058 267.794 427.058 267.794Z" fill="#464657"/>
<path d="M331.73 75.7164C331.73 75.7164 331.528 75.818 331.226 76.1227C330.923 76.3258 330.418 76.7321 329.711 77.1384C328.398 78.0526 326.277 79.1699 323.652 80.3888C318.401 82.725 310.625 85.0613 301.739 84.9597C292.852 84.8581 285.077 82.3187 279.927 79.7794C277.301 78.5605 275.383 77.3416 273.969 76.4274C273.262 76.0211 272.757 75.6148 272.454 75.3101C272.151 75.107 271.949 74.9038 271.949 74.9038C271.949 74.9038 272.151 75.0054 272.555 75.2085C272.959 75.4117 273.464 75.818 274.07 76.2243C275.383 77.0369 277.402 78.2558 280.028 79.4747C285.178 81.9124 292.852 84.3502 301.638 84.4518C310.423 84.5534 318.199 82.4203 323.349 80.0841C325.975 78.9668 327.994 77.8495 329.408 77.0369C330.115 76.6306 330.62 76.3258 331.024 76.1227C331.529 75.7164 331.73 75.6148 331.73 75.7164Z" fill="#464657"/>
<path d="M329.609 74.0913C329.609 74.0913 329.508 74.1929 329.205 74.396C328.801 74.5992 328.397 74.9039 327.892 75.2086C327.589 75.4118 327.286 75.6149 326.882 75.8181C326.479 76.0212 326.075 76.2244 325.671 76.4275C324.762 76.8338 323.853 77.3417 322.641 77.8496C322.035 78.0527 321.429 78.3574 320.824 78.5606C320.218 78.7637 319.511 78.9669 318.804 79.2716C317.491 79.7795 315.976 80.0842 314.361 80.4905C311.23 81.2015 307.595 81.6078 303.859 81.7094C300.122 81.7094 296.588 81.3031 293.357 80.6936C291.741 80.2873 290.226 79.9826 288.913 79.5763C288.206 79.3732 287.601 79.17 286.894 78.9669C286.288 78.7637 285.682 78.459 285.076 78.2559C283.965 77.8496 282.955 77.3417 282.047 76.9354C281.643 76.7322 281.239 76.5291 280.835 76.326C280.431 76.1228 280.128 75.9197 279.825 75.7165C279.32 75.4118 278.815 75.1071 278.512 74.9039C278.209 74.7008 278.007 74.5992 278.108 74.5992C278.108 74.5992 278.31 74.7008 278.613 74.8023C279.017 75.0055 279.421 75.2086 280.027 75.5134C280.33 75.7165 280.633 75.8181 281.037 76.0212C281.441 76.2244 281.845 76.326 282.249 76.5291C283.157 76.9354 284.167 77.4433 285.278 77.748C285.884 77.9511 286.49 78.1543 287.096 78.3574C287.702 78.5606 288.408 78.7637 289.115 78.9669C290.428 79.3732 291.943 79.6779 293.558 80.0842C296.689 80.6936 300.223 81.0999 303.96 81.0999C307.696 81.0999 311.23 80.5921 314.361 79.9826C315.875 79.5763 317.39 79.2716 318.804 78.8653C319.511 78.6622 320.117 78.459 320.824 78.2559C321.429 78.0527 322.035 77.748 322.641 77.5448C323.752 77.1385 324.762 76.6307 325.671 76.2244C326.075 76.0212 326.479 75.8181 326.882 75.6149C327.286 75.4118 327.589 75.2086 327.892 75.1071C328.498 74.8023 328.902 74.5992 329.306 74.396C329.407 74.1929 329.609 74.0913 329.609 74.0913Z" fill="#464657"/>
<path d="M301.838 84.3501C300.424 85.3659 300.02 86.5848 299.919 88.3115C299.818 89.2257 299.919 90.343 299.516 91.1556C299.011 92.0698 298.001 92.4761 296.991 92.4761C295.981 92.4761 294.971 92.1714 294.062 91.8666C295.173 90.6477 295.88 89.1241 296.183 87.4989C296.284 86.7879 296.385 86.1785 296.587 85.4675C296.789 84.858 297.294 84.2486 297.9 84.0454L301.838 84.3501Z" fill="#939DF4"/>
<path d="M309.109 83.7409C308.2 84.0456 307.695 84.9598 307.493 85.8739C307.291 86.7881 307.392 87.7023 307.493 88.718C307.493 89.6322 307.493 90.6479 306.988 91.4605C309.109 91.8668 311.431 91.359 313.249 90.2416C312.542 87.8039 312.744 85.3661 313.855 83.1314C313.855 83.1314 313.956 83.0299 313.653 82.9283C313.249 82.8267 309.109 83.7409 309.109 83.7409Z" fill="#939DF4"/>
<path d="M196.82 214.974H153.902V314.009H196.82V214.974Z" fill="#939DF4"/>
<g opacity="0.3">
<path opacity="0.3" d="M196.82 214.974H153.902V314.009H196.82V214.974Z" fill="#1B235E"/>
</g>
<path d="M143.805 214.569H249.735L269.527 186.331L290.228 215.381L314.666 214.975L288.411 179.424L176.422 179.83L143.805 214.569Z" fill="#939DF4"/>
<g opacity="0.3">
<path opacity="0.3" d="M143.805 214.569H249.735L269.527 186.331L290.228 215.381L314.666 214.975L288.411 179.424L176.422 179.83L143.805 214.569Z" fill="#1B235E"/>
</g>
<path d="M196.82 214.975V314.01H302.548V214.975H290.43L269.527 186.331L249.634 214.568L196.82 214.975Z" fill="#939DF4"/>
<path d="M236.506 314.01C236.506 314.01 236.506 313.908 236.506 313.807C236.506 313.604 236.506 313.4 236.506 313.197C236.506 312.588 236.506 311.775 236.506 310.76C236.506 308.626 236.506 305.579 236.405 301.719C236.405 294 236.304 283.334 236.304 271.044L236.607 271.349C232.669 271.349 228.529 271.349 224.287 271.349C220.046 271.349 215.906 271.349 211.867 271.349L212.17 271.044C212.17 283.334 212.069 294.101 212.069 301.719C212.069 305.478 211.968 308.525 211.968 310.76C211.968 311.775 211.968 312.588 211.968 313.197C211.968 313.4 211.968 313.604 211.968 313.807C211.968 313.908 211.968 314.01 211.968 314.01C211.968 314.01 211.968 313.908 211.968 313.807C211.968 313.604 211.968 313.4 211.968 313.197C211.968 312.588 211.968 311.775 211.968 310.76C211.968 308.626 211.968 305.579 211.867 301.719C211.867 294 211.766 283.334 211.766 271.044V270.739H212.069C216.007 270.739 220.147 270.739 224.489 270.739C228.731 270.739 232.871 270.739 236.809 270.739H237.112V271.044C237.112 283.334 237.011 294.101 237.011 301.719C237.011 305.478 236.91 308.525 236.91 310.76C236.91 311.775 236.91 312.588 236.91 313.197C236.91 313.4 236.91 313.604 236.91 313.807C236.506 314.01 236.506 314.01 236.506 314.01Z" fill="#464657"/>
<path d="M290.327 221.272H251.348V245.549H290.327V221.272Z" fill="white"/>
<path d="M233.882 221.476H209.344V244.838H233.882V221.476Z" fill="white"/>
<path d="M262.557 245.853C262.456 245.853 262.254 240.368 262.254 233.563C262.254 226.757 262.355 221.272 262.557 221.272C262.658 221.272 262.86 226.757 262.86 233.563C262.86 240.368 262.658 245.853 262.557 245.853Z" fill="#939DF4"/>
<path d="M278.815 245.853C278.714 245.853 278.512 240.368 278.512 233.563C278.512 226.757 278.613 221.272 278.815 221.272C278.916 221.272 279.118 226.757 279.118 233.563C279.017 240.368 278.916 245.853 278.815 245.853Z" fill="#939DF4"/>
<path d="M278.712 234.274C278.712 234.376 275.076 234.579 270.633 234.579C266.19 234.579 262.555 234.477 262.555 234.274C262.555 234.071 266.19 233.969 270.633 233.969C275.177 234.071 278.712 234.172 278.712 234.274Z" fill="#939DF4"/>
<path d="M221.655 244.939C221.554 244.939 221.352 239.657 221.352 233.258C221.352 226.757 221.453 221.577 221.655 221.577C221.755 221.577 221.957 226.859 221.957 233.258C221.856 239.657 221.755 244.939 221.655 244.939Z" fill="#939DF4"/>
<path d="M233.878 233.766C233.878 233.868 228.425 234.071 221.558 234.071C214.792 234.071 209.238 233.969 209.238 233.766C209.238 233.563 214.691 233.461 221.558 233.461C228.425 233.563 233.878 233.665 233.878 233.766Z" fill="#939DF4"/>
<path d="M274.678 205.223C274.678 208.169 272.355 210.505 269.427 210.505C266.599 210.505 264.176 208.169 264.176 205.223C264.176 202.278 266.498 199.941 269.427 199.941C272.355 199.941 274.678 202.379 274.678 205.223Z" fill="white"/>
<path d="M232.263 198.825L238.928 190.597V170.993H225.094V198.926L232.263 198.825Z" fill="#939DF4"/>
<g opacity="0.3">
<path opacity="0.3" d="M232.266 198.825V170.993H238.93V190.597L232.266 198.825Z" fill="#1B235E"/>
</g>
<path d="M239.84 168.149H223.582V170.993H239.84V168.149Z" fill="#939DF4"/>
<path d="M143.805 214.568V217.412H249.634V214.568H143.805Z" fill="#939DF4"/>
<path d="M249.633 217.412L269.526 189.479V186.331L249.734 214.568L249.633 217.412Z" fill="#939DF4"/>
<path d="M314.16 214.974H290.43V216.802H314.16V214.974Z" fill="#939DF4"/>
<path d="M228.125 264.543H219.34V267.895H228.125V264.543Z" fill="#464657"/>
<path d="M234.486 292.781C234.385 292.781 234.385 292.273 233.881 291.765C233.578 291.562 233.174 291.46 232.77 291.663C232.366 291.867 232.063 292.273 232.063 292.781C232.063 293.289 232.366 293.695 232.77 293.898C233.174 294.101 233.578 294 233.881 293.796C234.385 293.289 234.385 292.781 234.486 292.781C234.486 292.781 234.688 293.39 234.083 294C233.78 294.304 233.174 294.507 232.568 294.304C231.962 294.101 231.457 293.492 231.457 292.781C231.457 292.07 231.962 291.46 232.568 291.257C233.174 291.054 233.78 291.257 234.083 291.562C234.688 292.171 234.486 292.781 234.486 292.781Z" fill="#464657"/>
<path d="M214.695 254.792C214.695 254.792 214.695 254.69 214.695 254.487C214.695 254.284 214.695 253.979 214.695 253.675C214.695 252.862 214.695 251.745 214.695 250.526L214.796 250.628C211.363 250.628 206.314 250.628 200.76 250.628C200.76 250.628 200.659 250.729 200.861 250.526V250.628V250.729V251.034V251.643C200.861 252.05 200.861 252.354 200.861 252.761C200.861 253.472 200.861 254.183 200.861 254.894L200.76 254.792C204.698 254.792 208.233 254.792 210.757 254.792C211.969 254.792 212.979 254.792 213.686 254.792C213.989 254.792 214.292 254.792 214.494 254.792C214.594 254.792 214.695 254.792 214.695 254.792C214.695 254.792 214.595 254.792 214.393 254.792C214.191 254.792 213.888 254.792 213.585 254.792C212.777 254.792 211.868 254.792 210.656 254.792C208.031 254.792 204.496 254.792 200.558 254.792H200.457V254.691C200.457 253.979 200.457 253.268 200.457 252.557C200.457 252.151 200.457 251.846 200.457 251.44V250.831V250.526V250.424V250.323C200.659 250.12 200.558 250.221 200.558 250.221C206.213 250.221 211.161 250.221 214.594 250.221H214.695V250.323C214.695 251.643 214.695 252.659 214.695 253.573C214.695 253.878 214.695 254.183 214.695 254.386C214.695 254.69 214.695 254.792 214.695 254.792Z" fill="#464657"/>
<path d="M167.938 241.181C167.938 241.181 167.938 241.08 167.938 240.876C167.938 240.673 167.938 240.369 167.938 240.064C167.938 239.251 167.938 238.134 167.938 236.915L168.039 237.017C164.605 237.017 159.556 237.017 154.002 237.017C154.002 237.017 153.901 237.118 154.103 236.915V237.017V237.118V237.423V238.032C154.103 238.439 154.103 238.743 154.103 239.15C154.103 239.861 154.103 240.572 154.103 241.283L154.002 241.181C157.94 241.181 161.475 241.181 163.999 241.181C165.211 241.181 166.221 241.181 166.928 241.181C167.231 241.181 167.534 241.181 167.736 241.181C167.837 241.181 167.938 241.181 167.938 241.181C167.938 241.181 167.837 241.181 167.635 241.181C167.433 241.181 167.13 241.181 166.827 241.181C166.019 241.181 165.11 241.181 163.898 241.181C161.273 241.181 157.739 241.181 153.8 241.181H153.699V241.08C153.699 240.369 153.699 239.658 153.699 238.947C153.699 238.54 153.699 238.236 153.699 237.829V237.22V236.915V236.814V236.712C153.901 236.509 153.8 236.61 153.8 236.61C159.455 236.61 164.403 236.61 167.837 236.61H167.938V236.712C167.938 238.032 167.938 239.048 167.938 239.962C167.938 240.267 167.938 240.572 167.938 240.775C167.938 241.08 167.938 241.181 167.938 241.181Z" fill="#464657"/>
<path d="M176.426 236.813C176.426 236.813 176.426 236.711 176.426 236.508C176.426 236.305 176.426 236 176.426 235.696C176.426 234.883 176.426 233.766 176.426 232.547L176.527 232.648C173.094 232.648 168.044 232.648 162.49 232.648C162.49 232.648 162.389 232.75 162.591 232.547V232.648V232.75V233.055V233.664C162.591 234.071 162.591 234.375 162.591 234.782C162.591 235.493 162.591 236.204 162.591 236.915L162.49 236.813C166.429 236.813 169.963 236.813 172.488 236.813C173.699 236.813 174.709 236.813 175.416 236.813C175.719 236.813 176.022 236.813 176.224 236.813C176.325 236.813 176.426 236.813 176.426 236.813C176.426 236.813 176.325 236.813 176.123 236.813C175.921 236.813 175.618 236.813 175.315 236.813C174.507 236.813 173.598 236.813 172.387 236.813C169.761 236.813 166.227 236.813 162.288 236.813H162.188V236.711C162.188 236 162.188 235.289 162.188 234.578C162.188 234.172 162.188 233.867 162.188 233.461V232.852V232.547V232.445V232.344C162.389 232.141 162.288 232.242 162.288 232.242C167.943 232.242 172.892 232.242 176.325 232.242H176.426V232.344C176.426 233.664 176.426 234.68 176.426 235.594C176.426 235.899 176.426 236.204 176.426 236.407C176.426 236.711 176.426 236.813 176.426 236.813Z" fill="#464657"/>
<path d="M219.238 259.159C219.238 259.159 219.238 259.058 219.238 258.854C219.238 258.651 219.238 258.347 219.238 258.042C219.238 257.229 219.238 256.112 219.238 254.893L219.339 254.995C215.906 254.995 210.857 254.995 205.303 254.995C205.303 254.995 205.202 255.096 205.404 254.893V254.995V255.096V255.401V256.01C205.404 256.417 205.404 256.721 205.404 257.128C205.404 257.839 205.404 258.55 205.404 259.261L205.303 259.159C209.241 259.159 212.776 259.159 215.3 259.159C216.512 259.159 217.522 259.159 218.229 259.159C218.532 259.159 218.835 259.159 219.036 259.159C219.137 259.159 219.238 259.159 219.238 259.159C219.238 259.159 219.137 259.159 218.936 259.159C218.734 259.159 218.431 259.159 218.128 259.159C217.32 259.159 216.411 259.159 215.199 259.159C212.574 259.159 209.039 259.159 205.101 259.159H205V259.058C205 258.347 205 257.636 205 256.925C205 256.518 205 256.214 205 255.807V255.198V254.893V254.792V254.69C205.202 254.487 205.101 254.588 205.101 254.588C210.756 254.588 215.704 254.588 219.137 254.588H219.238V254.69C219.238 256.01 219.238 257.026 219.238 257.94C219.238 258.245 219.238 258.55 219.238 258.753C219.238 259.058 219.238 259.159 219.238 259.159Z" fill="#464657"/>
<path d="M302.547 266.778C302.547 266.778 302.547 266.676 302.547 266.473C302.547 266.27 302.547 265.965 302.547 265.661C302.547 264.848 302.547 263.731 302.547 262.512L302.648 262.613C299.215 262.613 294.166 262.613 288.612 262.613C288.612 262.613 288.511 262.715 288.713 262.512V262.613V262.715V263.02V263.629C288.713 264.035 288.713 264.34 288.713 264.746C288.713 265.457 288.713 266.168 288.713 266.879L288.612 266.778C292.55 266.778 296.084 266.778 298.609 266.778C299.821 266.778 300.83 266.778 301.537 266.778C301.84 266.778 302.143 266.778 302.345 266.778C302.446 266.778 302.547 266.778 302.547 266.778C302.547 266.778 302.446 266.778 302.244 266.778C302.042 266.778 301.739 266.778 301.436 266.778C300.628 266.778 299.72 266.778 298.508 266.778C295.882 266.778 292.348 266.778 288.41 266.778H288.309V266.676C288.309 265.965 288.309 265.254 288.309 264.543C288.309 264.137 288.309 263.832 288.309 263.426V262.816V262.512V262.613V262.512C288.511 262.309 288.41 262.41 288.41 262.41C294.065 262.41 299.013 262.41 302.446 262.41H302.547V262.512C302.547 263.832 302.547 264.848 302.547 265.762C302.547 266.067 302.547 266.372 302.547 266.575C302.547 266.676 302.547 266.778 302.547 266.778Z" fill="#464657"/>
<path d="M302.548 271.146C302.548 271.146 302.548 271.044 302.548 270.841C302.548 270.536 302.548 270.231 302.548 269.927C302.548 269.012 302.548 267.997 302.447 266.778L302.548 266.879C300.933 266.879 299.216 266.879 297.297 266.879C297.297 266.879 297.499 266.676 297.398 266.778V266.879V266.981V267.286V267.895C297.398 268.301 297.398 268.606 297.398 269.012C297.398 269.723 297.398 270.434 297.398 271.044L297.297 270.942C298.711 270.942 300.024 270.942 301.034 270.942C301.437 270.942 301.74 270.942 302.144 270.942C302.447 271.044 302.548 271.146 302.548 271.146C302.548 271.146 302.447 271.146 302.144 271.146C301.841 271.146 301.438 271.146 301.135 271.146C300.024 271.146 298.812 271.146 297.297 271.146H297.196V271.044C297.196 270.434 297.196 269.723 297.196 269.012C297.196 268.606 297.196 268.301 297.196 267.895V267.286V266.981V266.879V266.778C297.095 266.879 297.297 266.676 297.297 266.676C299.216 266.676 300.933 266.676 302.548 266.676H302.649V266.778C302.649 267.997 302.649 269.012 302.649 269.927C302.649 270.231 302.649 270.536 302.649 270.841C302.548 271.044 302.548 271.146 302.548 271.146Z" fill="#464657"/>
<path d="M267.609 259.159C267.609 259.159 267.609 259.058 267.609 258.854C267.609 258.651 267.609 258.347 267.609 258.042C267.609 257.229 267.609 256.112 267.609 254.893L267.71 254.995C264.277 254.995 259.228 254.995 253.674 254.995C253.674 254.995 253.573 255.096 253.775 254.893V254.995V255.096V255.401V256.01C253.775 256.417 253.775 256.721 253.775 257.128C253.775 257.839 253.775 258.55 253.775 259.261L253.674 259.159C257.612 259.159 261.147 259.159 263.671 259.159C264.883 259.159 265.893 259.159 266.6 259.159C266.903 259.159 267.206 259.159 267.408 259.159C267.508 259.159 267.609 259.159 267.609 259.159C267.609 259.159 267.508 259.159 267.307 259.159C267.105 259.159 266.802 259.159 266.499 259.159C265.691 259.159 264.782 259.159 263.57 259.159C260.945 259.159 257.41 259.159 253.472 259.159H253.371V259.058C253.371 258.347 253.371 257.636 253.371 256.925C253.371 256.518 253.371 256.214 253.371 255.807V255.198V254.893V254.792V254.69C253.573 254.487 253.472 254.588 253.472 254.588C259.127 254.588 264.075 254.588 267.509 254.588H267.609V254.69C267.609 256.01 267.609 257.026 267.609 257.94C267.609 258.245 267.609 258.55 267.609 258.753C267.609 259.058 267.609 259.159 267.609 259.159Z" fill="#464657"/>
<g opacity="0.2">
<g opacity="0.2">
<path opacity="0.2" d="M274.576 250.424H260.539V254.792H274.576V250.424Z" fill="white"/>
</g>
</g>
<g opacity="0.2">
<g opacity="0.2">
<path opacity="0.2" d="M303.052 229.601H295.074V233.969H303.052V229.601Z" fill="white"/>
</g>
</g>
<path d="M199.848 285.061C199.848 285.061 199.848 284.959 199.848 284.756C199.848 284.553 199.848 284.248 199.848 283.944C199.848 283.131 199.848 282.014 199.848 280.795L199.949 280.897C196.515 280.897 191.466 280.897 185.912 280.897C185.912 280.897 185.811 280.998 186.013 280.795V280.897V280.998V281.303V281.912C186.013 282.319 186.013 282.623 186.013 283.03C186.013 283.741 186.013 284.452 186.013 285.163L185.912 285.061C189.851 285.061 193.385 285.061 195.91 285.061C197.121 285.061 198.131 285.061 198.838 285.061C199.141 285.061 199.444 285.061 199.646 285.061C199.747 284.959 199.848 285.061 199.848 285.061C199.848 285.061 199.747 285.061 199.545 285.061C199.343 285.061 199.04 285.061 198.737 285.061C197.929 285.061 197.02 285.061 195.809 285.061C193.183 285.061 189.649 285.061 185.71 285.061H185.609V284.959C185.609 284.248 185.609 283.537 185.609 282.826C185.609 282.42 185.609 282.115 185.609 281.709V281.1V280.795V280.693V280.592C185.811 280.389 185.71 280.49 185.71 280.49C191.365 280.49 196.313 280.49 199.747 280.49H199.848V280.592C199.848 281.912 199.848 282.928 199.848 283.842C199.848 284.147 199.848 284.452 199.848 284.655C199.848 284.959 199.848 285.061 199.848 285.061Z" fill="#464657"/>
<path d="M193.692 289.53C193.692 289.53 193.692 289.428 193.692 289.225C193.692 289.022 193.692 288.717 193.692 288.413C193.692 287.6 193.692 286.483 193.692 285.264L193.793 285.365C190.359 285.365 185.31 285.365 179.756 285.365C179.756 285.365 179.655 285.467 179.857 285.264V285.365V285.467V285.772V286.381C179.857 286.787 179.857 287.092 179.857 287.498C179.857 288.209 179.857 288.92 179.857 289.631L179.756 289.53C183.694 289.53 187.229 289.53 189.753 289.53C190.965 289.53 191.975 289.53 192.682 289.53C192.985 289.53 193.288 289.53 193.49 289.53C193.591 289.53 193.692 289.53 193.692 289.53C193.692 289.53 193.591 289.53 193.389 289.53C193.187 289.53 192.884 289.53 192.581 289.53C191.773 289.53 190.864 289.53 189.652 289.53C187.027 289.53 183.492 289.53 179.554 289.53H179.453V289.428C179.453 288.717 179.453 288.006 179.453 287.295C179.453 286.889 179.453 286.584 179.453 286.178V285.568V285.264V285.162V285.061C179.655 284.857 179.554 284.959 179.554 284.959C185.209 284.959 190.157 284.959 193.591 284.959H193.692V285.061C193.692 286.381 193.692 287.397 193.692 288.311C193.692 288.616 193.692 288.92 193.692 289.124C193.692 289.428 193.692 289.53 193.692 289.53Z" fill="#464657"/>
<g opacity="0.2">
<g opacity="0.2">
<path opacity="0.2" d="M211.563 302.024H204.797V306.392H211.563V302.024Z" fill="white"/>
</g>
</g>
<path d="M236.504 280.287C236.504 280.287 236.605 280.287 236.908 280.287C237.312 280.287 237.615 280.287 238.12 280.287C239.331 280.287 240.644 280.287 242.159 280.287L242.058 280.389C242.058 279.678 242.058 278.967 242.058 278.256C242.058 277.545 242.058 276.732 242.058 276.123L242.159 276.224C240.644 276.224 239.331 276.224 238.12 276.224C237.716 276.224 237.312 276.224 236.908 276.224C236.605 276.224 236.504 276.224 236.504 276.224C236.504 276.224 236.605 276.224 236.908 276.224C237.312 276.224 237.615 276.224 238.12 276.224C239.331 276.224 240.644 276.224 242.159 276.224H242.26V276.326C242.26 277.037 242.26 277.748 242.26 278.459C242.26 279.17 242.26 279.982 242.26 280.592V280.693H242.159C240.644 280.693 239.331 280.693 238.12 280.693C237.716 280.693 237.312 280.693 236.908 280.693C236.605 280.287 236.504 280.287 236.504 280.287Z" fill="#464657"/>
<path d="M233.676 233.461C233.676 233.461 233.777 233.461 234.08 233.461C234.484 233.461 234.787 233.461 235.292 233.461C236.503 233.461 237.816 233.461 239.331 233.461L239.23 233.563C239.23 232.852 239.23 232.141 239.23 231.43C239.23 230.719 239.23 229.906 239.23 229.297L239.331 229.398C237.816 229.398 236.503 229.398 235.292 229.398C234.888 229.398 234.484 229.398 234.08 229.398C233.777 229.398 233.676 229.398 233.676 229.398C233.676 229.398 233.777 229.398 234.08 229.398C234.484 229.398 234.787 229.398 235.292 229.398C236.503 229.398 237.816 229.398 239.331 229.398H239.432V229.5C239.432 230.211 239.432 230.922 239.432 231.633C239.432 232.344 239.432 233.157 239.432 233.766V233.868H239.331C237.816 233.868 236.503 233.868 235.292 233.868C234.888 233.868 234.484 233.868 234.08 233.868C233.777 233.461 233.676 233.461 233.676 233.461Z" fill="#464657"/>
<path d="M290.327 270.03H251.348V294.306H290.327V270.03Z" fill="white"/>
<path d="M262.553 294.508C262.452 294.508 262.25 289.023 262.25 282.217C262.25 275.412 262.351 269.927 262.553 269.927C262.654 269.927 262.856 275.412 262.856 282.217C262.856 289.023 262.654 294.508 262.553 294.508Z" fill="#939DF4"/>
<path d="M278.811 294.507C278.71 294.507 278.508 289.022 278.508 282.217C278.508 275.411 278.609 269.926 278.811 269.926C278.912 269.926 279.114 275.411 279.114 282.217C279.013 289.022 278.912 294.507 278.811 294.507Z" fill="#939DF4"/>
<path d="M278.712 283.029C278.712 283.131 275.076 283.334 270.633 283.334C266.19 283.334 262.555 283.233 262.555 283.029C262.555 282.826 266.19 282.725 270.633 282.725C275.177 282.725 278.712 282.826 278.712 283.029Z" fill="#939DF4"/>
<path d="M125.327 59.5659H109.372L52.418 115.127V314.01H89.6803L125.327 59.5659Z" fill="#464657"/>
<path d="M90.6872 221.171C90.6872 221.171 90.6872 221.069 90.6872 220.866C90.6872 220.663 90.6872 220.459 90.6872 220.155C90.6872 219.444 90.6872 218.53 90.6872 217.311C90.6872 214.771 90.6872 211.216 90.5862 206.645C90.5862 197.605 90.4852 185.01 90.4852 170.688L90.7882 170.993C86.446 170.993 82.0028 170.993 77.3576 170.993C77.8625 170.485 77.4586 170.891 77.6605 170.79V170.891V170.993V171.196V171.704V172.618C77.6605 173.227 77.6605 173.938 77.6605 174.548C77.6605 175.868 77.6605 177.087 77.6605 178.306C77.6605 180.845 77.6605 183.283 77.6605 185.619C77.6605 190.393 77.6605 194.964 77.6605 199.332C77.6605 207.966 77.5596 215.381 77.5596 221.171L77.3576 220.967C81.5988 220.967 84.9312 221.069 87.1528 221.069C88.2636 221.069 89.1725 221.069 89.7784 221.069C90.0813 221.069 90.2833 221.069 90.4852 221.069C90.5862 221.069 90.6872 221.069 90.6872 221.171C90.6872 221.171 90.5862 221.171 90.4852 221.171C90.2833 221.171 90.0813 221.171 89.7784 221.171C89.1725 221.171 88.2636 221.171 87.1528 221.171C84.8302 221.171 81.4979 221.272 77.2566 221.272H77.0546V221.069C77.0546 215.279 77.0546 207.864 76.9537 199.23C76.9537 194.964 76.9537 190.292 76.9537 185.518C76.9537 183.08 76.9537 180.642 76.9537 178.205C76.9537 176.986 76.9537 175.665 76.9537 174.446C76.9537 173.837 76.9537 173.126 76.9537 172.516V171.602V171.094V170.891V170.79V170.688C77.0546 170.586 76.6507 170.891 77.2566 170.383C81.9018 170.383 86.345 170.383 90.6872 170.383H90.9901V170.688C90.9901 185.112 90.8892 197.808 90.8892 206.849C90.8892 211.318 90.7882 214.873 90.7882 217.412C90.7882 218.631 90.7882 219.545 90.7882 220.256C90.7882 220.561 90.7882 220.764 90.7882 220.967C90.6872 221.069 90.6872 221.171 90.6872 221.171Z" fill="#6C778B"/>
<path d="M90.3855 175.462C90.3855 175.564 87.457 175.767 83.8216 175.767C80.1863 175.767 77.2578 175.665 77.2578 175.462C77.2578 175.259 80.1863 175.157 83.8216 175.157C87.457 175.157 90.3855 175.259 90.3855 175.462Z" fill="#6C778B"/>
<path d="M90.3855 179.626C90.3855 179.728 87.457 179.931 83.8216 179.931C80.1863 179.931 77.2578 179.83 77.2578 179.626C77.2578 179.423 80.1863 179.322 83.8216 179.322C87.457 179.322 90.3855 179.423 90.3855 179.626Z" fill="#6C778B"/>
<path d="M90.3855 183.689C90.3855 183.791 87.457 183.994 83.8216 183.994C80.1863 183.994 77.2578 183.893 77.2578 183.689C77.2578 183.588 80.1863 183.385 83.8216 183.385C87.457 183.486 90.3855 183.588 90.3855 183.689Z" fill="#6C778B"/>
<path d="M90.3855 187.854C90.3855 187.956 87.457 188.159 83.8216 188.159C80.1863 188.159 77.2578 188.057 77.2578 187.854C77.2578 187.752 80.1863 187.549 83.8216 187.549C87.457 187.651 90.3855 187.752 90.3855 187.854Z" fill="#6C778B"/>
<path d="M90.3855 192.019C90.3855 192.12 87.457 192.323 83.8216 192.323C80.1863 192.323 77.2578 192.222 77.2578 192.019C77.2578 191.917 80.1863 191.714 83.8216 191.714C87.457 191.714 90.3855 191.917 90.3855 192.019Z" fill="#6C778B"/>
<path d="M90.3855 196.183C90.3855 196.285 87.457 196.488 83.8216 196.488C80.1863 196.488 77.2578 196.386 77.2578 196.183C77.2578 196.082 80.1863 195.878 83.8216 195.878C87.457 195.878 90.3855 195.98 90.3855 196.183Z" fill="#6C778B"/>
<path d="M90.3855 200.348C90.3855 200.45 87.457 200.653 83.8216 200.653C80.1863 200.653 77.2578 200.551 77.2578 200.348C77.2578 200.247 80.1863 200.043 83.8216 200.043C87.457 200.043 90.3855 200.145 90.3855 200.348Z" fill="#6C778B"/>
<path d="M90.3855 204.512C90.3855 204.614 87.457 204.817 83.8216 204.817C80.1863 204.817 77.2578 204.715 77.2578 204.512C77.2578 204.411 80.1863 204.208 83.8216 204.208C87.457 204.208 90.3855 204.309 90.3855 204.512Z" fill="#6C778B"/>
<path d="M90.3855 208.575C90.3855 208.677 87.457 208.88 83.8216 208.88C80.1863 208.88 77.2578 208.778 77.2578 208.575C77.2578 208.474 80.1863 208.271 83.8216 208.271C87.457 208.372 90.3855 208.474 90.3855 208.575Z" fill="#6C778B"/>
<path d="M90.3855 212.74C90.3855 212.841 87.457 213.045 83.8216 213.045C80.1863 213.045 77.2578 212.943 77.2578 212.74C77.2578 212.537 80.1863 212.435 83.8216 212.435C87.457 212.537 90.3855 212.638 90.3855 212.74Z" fill="#6C778B"/>
<path d="M90.3855 216.904C90.3855 217.006 87.457 217.209 83.8216 217.209C80.1863 217.209 77.2578 217.107 77.2578 216.904C77.2578 216.701 80.1863 216.6 83.8216 216.6C87.457 216.6 90.3855 216.803 90.3855 216.904Z" fill="#6C778B"/>
<path d="M89.9801 273.177C89.9801 273.177 89.9801 273.076 89.9801 272.873C89.9801 272.669 89.9801 272.466 89.9801 272.162C89.9801 271.451 89.9801 270.536 89.9801 269.318C89.9801 266.778 89.9802 263.223 89.8792 258.652C89.8792 249.612 89.7782 237.017 89.7782 222.695L90.0811 223C85.7389 223 81.2957 223 76.6506 223C77.1555 222.492 76.7515 222.898 76.9535 222.796V222.898V223V223.203V223.711V224.625C76.9535 225.234 76.9535 225.945 76.9535 226.555C76.9535 227.875 76.9535 229.094 76.9535 230.313C76.9535 232.852 76.9535 235.29 76.9535 237.626C76.9535 242.4 76.9535 246.971 76.9535 251.339C76.9535 259.973 76.8525 267.388 76.8525 273.177L76.6506 272.974C80.8918 272.974 84.2242 273.076 86.4458 273.076C87.5566 273.076 88.4654 273.076 89.0713 273.076C89.3743 273.076 89.5762 273.076 89.7782 273.076C89.8792 273.177 89.9801 273.177 89.9801 273.177C89.9801 273.177 89.8792 273.177 89.7782 273.177C89.5762 273.177 89.3743 273.177 89.0713 273.177C88.4654 273.177 87.5566 273.177 86.4458 273.177C84.1232 273.177 80.7908 273.279 76.5496 273.279H76.3476V273.076C76.3476 267.286 76.3476 259.871 76.2466 251.237C76.2466 246.971 76.2466 242.299 76.2466 237.525C76.2466 235.087 76.2466 232.649 76.2466 230.211C76.2466 228.992 76.2466 227.672 76.2466 226.453C76.2466 225.844 76.2466 225.133 76.2466 224.523V223.609V223.101V222.898V222.796V222.695C76.3476 222.593 75.9437 222.898 76.5496 222.39C81.1947 222.39 85.6379 222.39 89.9801 222.39H90.2831V222.695C90.2831 237.118 90.1821 249.815 90.1821 258.855C90.1821 263.325 90.0811 266.88 90.0811 269.419C90.0811 270.638 90.0811 271.552 90.0811 272.263C90.0811 272.568 90.0811 272.771 90.0811 272.974C89.9801 273.076 89.9801 273.177 89.9801 273.177Z" fill="#6C778B"/>
<path d="M89.78 227.469C89.78 227.57 86.8515 227.774 83.2162 227.774C79.5808 227.774 76.6523 227.672 76.6523 227.469C76.6523 227.367 79.5808 227.164 83.2162 227.164C86.7505 227.164 89.78 227.266 89.78 227.469Z" fill="#6C778B"/>
<path d="M89.78 231.633C89.78 231.735 86.8515 231.938 83.2162 231.938C79.5808 231.938 76.6523 231.836 76.6523 231.633C76.6523 231.532 79.5808 231.329 83.2162 231.329C86.7505 231.329 89.78 231.43 89.78 231.633Z" fill="#6C778B"/>
<path d="M89.78 235.696C89.78 235.798 86.8515 236.001 83.2162 236.001C79.5808 236.001 76.6523 235.899 76.6523 235.696C76.6523 235.595 79.5808 235.392 83.2162 235.392C86.7505 235.493 89.78 235.595 89.78 235.696Z" fill="#6C778B"/>
<path d="M89.78 239.861C89.78 239.962 86.8515 240.166 83.2162 240.166C79.5808 240.166 76.6523 240.064 76.6523 239.861C76.6523 239.759 79.5808 239.556 83.2162 239.556C86.7505 239.658 89.78 239.759 89.78 239.861Z" fill="#6C778B"/>
<path d="M89.78 244.025C89.78 244.127 86.8515 244.33 83.2162 244.33C79.5808 244.33 76.6523 244.229 76.6523 244.025C76.6523 243.924 79.5808 243.721 83.2162 243.721C86.7505 243.822 89.78 243.924 89.78 244.025Z" fill="#6C778B"/>
<path d="M89.78 248.189C89.78 248.291 86.8515 248.494 83.2162 248.494C79.5808 248.494 76.6523 248.393 76.6523 248.189C76.6523 248.088 79.5808 247.885 83.2162 247.885C86.7505 247.885 89.78 248.088 89.78 248.189Z" fill="#6C778B"/>
<path d="M89.78 252.355C89.78 252.456 86.8515 252.659 83.2162 252.659C79.5808 252.659 76.6523 252.558 76.6523 252.355C76.6523 252.253 79.5808 252.05 83.2162 252.05C86.7505 252.05 89.78 252.151 89.78 252.355Z" fill="#6C778B"/>
<path d="M89.78 256.519C89.78 256.621 86.8515 256.824 83.2162 256.824C79.5808 256.824 76.6523 256.722 76.6523 256.519C76.6523 256.316 79.5808 256.214 83.2162 256.214C86.7505 256.214 89.78 256.316 89.78 256.519Z" fill="#6C778B"/>
<path d="M89.78 260.684C89.78 260.785 86.8515 260.988 83.2162 260.988C79.5808 260.988 76.6523 260.887 76.6523 260.684C76.6523 260.481 79.5808 260.379 83.2162 260.379C86.7505 260.379 89.78 260.481 89.78 260.684Z" fill="#6C778B"/>
<path d="M89.78 264.747C89.78 264.848 86.8515 265.051 83.2162 265.051C79.5808 265.051 76.6523 264.95 76.6523 264.747C76.6523 264.543 79.5808 264.442 83.2162 264.442C86.7505 264.543 89.78 264.645 89.78 264.747Z" fill="#6C778B"/>
<path d="M89.78 268.911C89.78 269.013 86.8515 269.216 83.2162 269.216C79.5808 269.216 76.6523 269.114 76.6523 268.911C76.6523 268.708 79.5808 268.606 83.2162 268.606C86.7505 268.708 89.78 268.81 89.78 268.911Z" fill="#6C778B"/>
<path d="M162.282 313.908V115.127L125.221 65.6602L89.6758 115.127V314.01H162.282" fill="#6C778B"/>
<path d="M143.503 313.4C143.503 313.4 143.503 313.299 143.503 313.095C143.503 312.892 143.503 312.689 143.503 312.384C143.503 311.673 143.503 310.759 143.503 309.439C143.503 306.899 143.503 303.141 143.402 298.57C143.402 289.327 143.301 276.427 143.301 261.597L143.604 261.902C138.959 261.902 134.213 261.902 129.265 261.902C124.317 261.902 119.469 261.902 114.925 261.902L115.228 261.597C115.228 276.427 115.127 289.327 115.127 298.57C115.127 303.141 115.026 306.798 115.026 309.439C115.026 310.658 115.026 311.673 115.026 312.384C115.026 312.689 115.026 312.892 115.026 313.095C115.026 313.299 115.026 313.4 115.026 313.4C115.026 313.4 115.026 313.299 115.026 313.095C115.026 312.892 115.026 312.689 115.026 312.384C115.026 311.673 115.026 310.759 115.026 309.439C115.026 306.899 115.026 303.141 114.925 298.57C114.925 289.327 114.824 276.427 114.824 261.597V261.292H115.127C119.772 261.292 124.518 261.292 129.467 261.292C134.415 261.292 139.262 261.292 143.806 261.292H144.109V261.597C144.109 276.427 144.008 289.327 144.008 298.57C144.008 303.141 143.907 306.798 143.907 309.439C143.907 310.658 143.907 311.673 143.907 312.384C143.907 312.689 143.907 312.892 143.907 313.095C143.503 313.299 143.503 313.4 143.503 313.4Z" fill="#464657"/>
<path d="M139.462 288.413C139.462 288.515 139.26 288.718 139.26 289.124C139.26 289.327 139.361 289.632 139.563 289.734C139.765 289.835 140.068 289.835 140.371 289.734C140.674 289.53 140.775 289.327 140.775 289.023C140.775 288.819 140.573 288.616 140.371 288.413C139.967 288.21 139.664 288.312 139.664 288.21C139.563 288.21 139.967 287.804 140.573 288.108C140.876 288.21 141.179 288.515 141.28 288.921C141.381 289.327 141.078 289.937 140.674 290.14C140.27 290.445 139.664 290.343 139.361 290.038C139.058 289.734 138.957 289.327 138.957 289.023C139.058 288.515 139.462 288.413 139.462 288.413Z" fill="#464657"/>
<path d="M135.526 253.675H123.004V257.535H135.526V253.675Z" fill="#464657"/>
<path d="M124.211 193.644H101.793V237.117H124.211V193.644Z" fill="#E0E0E0"/>
<path d="M113.612 241.993C113.511 241.993 113.309 230.617 113.309 216.701C113.309 202.684 113.41 191.409 113.612 191.409C113.713 191.409 113.914 202.786 113.914 216.701C113.914 230.617 113.713 241.993 113.612 241.993Z" fill="#6C778B"/>
<path d="M126.032 208.778C126.032 208.88 120.276 209.083 113.208 209.083C106.139 209.083 100.383 208.982 100.383 208.778C100.383 208.677 106.139 208.474 113.208 208.474C120.377 208.474 126.032 208.575 126.032 208.778Z" fill="#6C778B"/>
<path d="M126.032 224.319C126.032 224.421 120.276 224.624 113.208 224.624C106.139 224.624 100.383 224.523 100.383 224.319C100.383 224.116 106.24 224.015 113.308 224.015C120.377 224.015 126.032 224.116 126.032 224.319Z" fill="#6C778B"/>
<path d="M153.195 193.644H130.777V237.118H153.195V193.644Z" fill="#E0E0E0"/>
<path d="M142.592 241.994C142.491 241.994 142.289 230.617 142.289 216.702C142.289 202.684 142.39 191.41 142.592 191.41C142.693 191.41 142.895 202.786 142.895 216.702C142.895 230.617 142.794 241.994 142.592 241.994Z" fill="#6C778B"/>
<path d="M155.114 208.779C155.114 208.88 149.358 209.084 142.29 209.084C135.221 209.084 129.465 208.982 129.465 208.779C129.465 208.677 135.221 208.474 142.29 208.474C149.358 208.474 155.114 208.576 155.114 208.779Z" fill="#6C778B"/>
<path d="M155.114 224.319C155.114 224.421 149.358 224.624 142.29 224.624C135.221 224.624 129.465 224.523 129.465 224.319C129.465 224.116 135.221 224.015 142.29 224.015C149.358 224.015 155.114 224.116 155.114 224.319Z" fill="#6C778B"/>
<path d="M124.211 131.786H101.793V175.26H124.211V131.786Z" fill="#E0E0E0"/>
<path d="M113.612 180.135C113.511 180.135 113.309 168.758 113.309 154.843C113.309 140.826 113.41 129.551 113.612 129.551C113.713 129.551 113.914 140.927 113.914 154.843C113.914 168.758 113.713 180.135 113.612 180.135Z" fill="#6C778B"/>
<path d="M126.032 146.92C126.032 147.022 120.276 147.225 113.208 147.225C106.139 147.225 100.383 147.123 100.383 146.92C100.383 146.818 106.139 146.615 113.208 146.615C120.377 146.615 126.032 146.717 126.032 146.92Z" fill="#6C778B"/>
<path d="M126.032 162.461C126.032 162.563 120.276 162.766 113.208 162.766C106.139 162.766 100.383 162.664 100.383 162.461C100.383 162.359 106.139 162.156 113.208 162.156C120.377 162.156 126.032 162.258 126.032 162.461Z" fill="#6C778B"/>
<path d="M153.195 131.785H130.777V175.259H153.195V131.785Z" fill="#E0E0E0"/>
<path d="M142.592 180.135C142.491 180.135 142.289 168.758 142.289 154.843C142.289 140.826 142.39 129.551 142.592 129.551C142.693 129.551 142.895 140.927 142.895 154.843C142.895 168.758 142.794 180.135 142.592 180.135Z" fill="#6C778B"/>
<path d="M155.114 146.92C155.114 147.022 149.358 147.225 142.29 147.225C135.221 147.225 129.465 147.123 129.465 146.92C129.465 146.818 135.221 146.615 142.29 146.615C149.358 146.615 155.114 146.717 155.114 146.92Z" fill="#6C778B"/>
<path d="M155.114 162.461C155.114 162.563 149.358 162.766 142.29 162.766C135.221 162.766 129.465 162.664 129.465 162.461C129.465 162.359 135.221 162.156 142.29 162.156C149.358 162.156 155.114 162.258 155.114 162.461Z" fill="#6C778B"/>
<path d="M89.6796 220.155H72.0078V239.861H89.6796V220.155Z" fill="#6C778B"/>
<path d="M89.6796 156.874H72.0078V176.579H89.6796V156.874Z" fill="#6C778B"/>
<path d="M135.648 112.893C139.927 107.939 139.404 100.433 134.479 96.1284C129.554 91.8234 122.092 92.3496 117.812 97.3037C113.532 102.258 114.055 109.764 118.98 114.069C123.906 118.374 131.368 117.848 135.648 112.893Z" fill="#E0E0E0"/>
<path d="M138.656 105.071C138.656 105.173 133.001 105.376 126.033 105.376C119.065 105.376 113.41 105.274 113.41 105.071C113.41 104.868 119.065 104.767 126.033 104.767C133.001 104.868 138.656 104.97 138.656 105.071Z" fill="#6C778B"/>
<path d="M126.635 119.901C126.534 119.901 126.332 113.4 126.332 105.477C126.332 97.5545 126.433 91.0537 126.635 91.0537C126.837 91.0537 126.938 97.4529 126.938 105.477C126.938 113.4 126.736 119.901 126.635 119.901Z" fill="#6C778B"/>
<path d="M89.6778 115.127H83.9219L125.324 59.5659L166.727 115.127H162.284L125.223 65.6604L89.6778 115.127Z" fill="#464657"/>
<path d="M72.0077 156.874H44.1367V176.579H72.0077V156.874Z" fill="#464657"/>
<path d="M72.0077 220.155H44.1367V239.861H72.0077V220.155Z" fill="#464657"/>
<path d="M72.0077 115.127H44.1367L87.054 59.5659H125.326L72.0077 115.127Z" fill="#464657"/>
<path d="M73.4207 240.064C73.4207 240.165 66.7559 240.368 58.3744 240.368C50.0939 240.368 43.3281 240.267 43.3281 240.064C43.3281 239.86 49.9929 239.759 58.3744 239.759C66.7559 239.86 73.4207 239.962 73.4207 240.064Z" fill="#6C778B"/>
<path d="M73.4207 220.155C73.4207 220.257 66.7559 220.46 58.3744 220.46C50.0939 220.46 43.3281 220.358 43.3281 220.155C43.3281 220.054 49.9929 219.851 58.3744 219.851C66.7559 219.851 73.4207 219.952 73.4207 220.155Z" fill="#6C778B"/>
<path d="M73.4207 176.986C73.4207 177.087 66.7559 177.291 58.3744 177.291C50.0939 177.291 43.3281 177.189 43.3281 176.986C43.3281 176.783 49.9929 176.681 58.3744 176.681C66.7559 176.783 73.4207 176.884 73.4207 176.986Z" fill="#6C778B"/>
<path d="M73.4207 157.179C73.4207 157.28 66.7559 157.484 58.3744 157.484C50.0939 157.484 43.3281 157.382 43.3281 157.179C43.3281 156.976 49.9929 156.874 58.3744 156.874C66.7559 156.976 73.4207 157.077 73.4207 157.179Z" fill="#6C778B"/>
<path d="M111.39 255.299C111.39 255.299 111.39 255.198 111.39 254.893C111.39 254.588 111.39 254.284 111.39 253.877C111.39 252.963 111.39 251.643 111.39 250.119L111.491 250.221C107.552 250.221 101.695 250.221 95.1316 250.221C95.1316 250.221 95.0307 250.322 95.2326 250.119V250.221V250.424V250.729V251.338C95.2326 251.744 95.2326 252.151 95.2326 252.658C95.2326 253.471 95.2326 254.284 95.2326 255.096L95.1316 254.995C99.7768 254.995 103.816 254.995 106.846 254.995C108.259 254.995 109.37 254.995 110.279 254.995C110.683 254.995 110.986 254.995 111.188 254.995C111.188 255.299 111.39 255.299 111.39 255.299C111.39 255.299 111.289 255.299 111.087 255.299C110.784 255.299 110.481 255.299 110.178 255.299C109.269 255.299 108.158 255.299 106.745 255.299C103.715 255.299 99.6758 255.299 95.0307 255.299H94.9297V255.198C94.9297 254.385 94.9297 253.573 94.9297 252.76C94.9297 252.354 94.9297 251.947 94.9297 251.44V250.83V250.525V250.424V250.322C95.1317 250.119 95.0307 250.221 95.0307 250.221C101.594 250.221 107.451 250.221 111.39 250.221H111.491V250.322C111.491 251.846 111.491 253.065 111.491 254.081C111.491 254.487 111.491 254.792 111.491 255.096C111.39 255.198 111.39 255.299 111.39 255.299Z" fill="#464657"/>
<path d="M118.964 250.221C118.964 250.221 118.964 250.12 118.964 249.815C118.964 249.51 118.964 249.206 118.964 248.799C118.964 247.885 118.964 246.565 118.964 245.041L119.065 245.143C115.127 245.143 109.27 245.143 102.706 245.143C102.706 245.143 102.605 245.244 102.807 245.041V245.143V245.346V245.65V246.26C102.807 246.666 102.807 247.073 102.807 247.58C102.807 248.393 102.807 249.206 102.807 250.018L102.706 249.917C107.351 249.917 111.39 249.917 114.42 249.917C115.834 249.917 116.944 249.917 117.853 249.917C118.257 249.917 118.56 249.917 118.762 249.917C118.762 250.221 118.964 250.221 118.964 250.221C118.964 250.221 118.863 250.221 118.661 250.221C118.358 250.221 118.055 250.221 117.752 250.221C116.843 250.221 115.733 250.221 114.319 250.221C111.289 250.221 107.25 250.221 102.605 250.221H102.504V250.12C102.504 249.307 102.504 248.495 102.504 247.682C102.504 247.276 102.504 246.869 102.504 246.361V245.752V245.447V245.244V245.143C102.706 244.939 102.605 245.041 102.605 245.041C109.169 245.041 115.026 245.041 118.964 245.041H119.065V245.143C119.065 246.666 119.065 247.885 119.065 248.901C119.065 249.307 119.065 249.612 119.065 249.917C118.964 250.12 118.964 250.221 118.964 250.221Z" fill="#464657"/>
<path d="M113.007 193.644C113.007 193.644 113.007 193.543 113.007 193.238C113.007 192.933 113.007 192.628 113.007 192.222C113.007 191.308 113.007 189.987 113.007 188.464L113.108 188.565C109.17 188.565 103.313 188.565 96.7488 188.565C96.7488 188.565 96.6479 188.667 96.8498 188.464V188.565V188.769V189.073V189.683C96.8498 190.089 96.8498 190.495 96.8498 191.003C96.8498 191.816 96.8498 192.628 96.8498 193.441L96.7488 193.339C101.394 193.339 105.433 193.339 108.463 193.339C109.876 193.339 110.987 193.339 111.896 193.339C112.3 193.339 112.603 193.339 112.805 193.339C112.906 193.644 113.007 193.644 113.007 193.644C113.007 193.644 112.906 193.644 112.704 193.644C112.401 193.644 112.098 193.644 111.795 193.644C110.886 193.644 109.775 193.644 108.362 193.644C105.332 193.644 101.293 193.644 96.6479 193.644H96.5469V193.543C96.5469 192.73 96.5469 191.917 96.5469 191.105C96.5469 190.699 96.5469 190.292 96.5469 189.784V189.175V188.87V188.667V188.565C96.7488 188.362 96.6479 188.464 96.6479 188.464C103.212 188.464 109.069 188.464 113.007 188.464H113.108V188.565C113.108 190.089 113.108 191.308 113.108 192.324C113.108 192.73 113.108 193.035 113.108 193.339C113.007 193.543 113.007 193.644 113.007 193.644Z" fill="#464657"/>
<path d="M156.53 122.034C156.53 122.034 156.53 121.932 156.53 121.628C156.53 121.323 156.53 121.018 156.53 120.612C156.53 119.698 156.53 118.377 156.53 116.854L156.631 116.955C152.693 116.955 146.836 116.955 140.272 116.955C140.272 116.955 140.171 117.057 140.373 116.854V116.955V117.158V117.463V118.072C140.373 118.479 140.373 118.885 140.373 119.393C140.373 120.205 140.373 121.018 140.373 121.831L140.272 121.729C144.917 121.729 148.957 121.729 151.986 121.729C153.4 121.729 154.511 121.729 155.42 121.729C155.823 121.729 156.126 121.729 156.328 121.729C156.429 122.034 156.53 122.034 156.53 122.034C156.53 122.034 156.429 122.034 156.227 122.034C155.924 122.034 155.622 122.034 155.319 122.034C154.41 122.034 153.299 122.034 151.885 122.034C148.856 122.034 144.816 122.034 140.171 122.034H140.07V121.932C140.07 121.12 140.07 120.307 140.07 119.494C140.07 119.088 140.07 118.682 140.07 118.174V117.565V117.26V117.057V116.955C140.272 116.752 140.171 116.854 140.171 116.854C146.735 116.854 152.592 116.854 156.53 116.854H156.631V116.955C156.631 118.479 156.631 119.698 156.631 120.713C156.631 121.12 156.631 121.424 156.631 121.729C156.53 121.932 156.53 122.034 156.53 122.034Z" fill="#464657"/>
<path d="M116.233 93.4918C116.233 93.4918 116.233 93.3903 116.233 93.0856C116.233 92.7808 116.233 92.4761 116.233 92.0698C116.233 91.1556 116.233 89.8351 116.233 88.3115L116.334 88.4131C112.396 88.4131 106.539 88.4131 99.9754 88.4131C99.9754 88.4131 99.8744 88.5147 100.076 88.3115V88.4131V88.6163V88.921V89.5304C100.076 89.9367 100.076 90.343 100.076 90.8509C100.076 91.6635 100.076 92.4761 100.076 93.2887L99.9754 93.1871C104.621 93.1871 108.66 93.1871 111.689 93.1871C113.103 93.1871 114.214 93.1871 115.123 93.1871C115.527 93.1871 115.83 93.1871 116.032 93.1871C116.032 93.4918 116.233 93.4918 116.233 93.4918C116.233 93.4918 116.133 93.4918 115.931 93.4918C115.628 93.4918 115.325 93.4918 115.022 93.4918C114.113 93.4918 113.002 93.4918 111.588 93.4918C108.559 93.4918 104.52 93.4918 99.8744 93.4918H99.7734V93.3903C99.7734 92.5777 99.7734 91.7651 99.7734 90.9525C99.7734 90.5462 99.7734 90.1399 99.7734 89.632V89.0226V88.7178V88.5147V88.4131C99.9754 88.21 99.8744 88.3115 99.8744 88.3115C106.438 88.3115 112.295 88.3115 116.233 88.3115H116.334V88.4131C116.334 89.9367 116.334 91.1556 116.334 92.1714C116.334 92.5777 116.334 92.8824 116.334 93.1871C116.233 93.3903 116.233 93.4918 116.233 93.4918Z" fill="#464657"/>
<path d="M101.8 170.688C101.8 170.688 101.8 170.587 101.8 170.282C101.8 169.977 101.8 169.672 101.8 169.266C101.8 168.352 101.8 167.031 101.8 165.508L101.901 165.609C97.9626 165.609 92.1056 165.609 85.5418 165.609C85.5418 165.609 85.4408 165.711 85.6428 165.508V165.609V165.813V166.117V166.727C85.6428 167.133 85.6428 167.539 85.6428 168.047C85.6428 168.86 85.6428 169.672 85.6428 170.485L85.5418 170.383C90.187 170.383 94.2262 170.383 97.2557 170.383C98.6694 170.383 99.7802 170.383 100.689 170.383C101.093 170.383 101.396 170.383 101.598 170.383C101.699 170.688 101.8 170.688 101.8 170.688C101.8 170.688 101.699 170.688 101.497 170.688C101.194 170.688 100.891 170.688 100.588 170.688C99.6793 170.688 98.5685 170.688 97.1547 170.688C94.1253 170.688 90.086 170.688 85.4408 170.688H85.3398V170.587C85.3398 169.774 85.3398 168.961 85.3398 168.149C85.3398 167.742 85.3398 167.336 85.3398 166.828V166.219V165.914V165.711V165.609C85.5418 165.406 85.4408 165.508 85.4408 165.508C92.0046 165.508 97.8616 165.508 101.8 165.508H101.901V165.609C101.901 167.133 101.901 168.352 101.901 169.368C101.901 169.774 101.901 170.079 101.901 170.383C101.8 170.587 101.8 170.688 101.8 170.688Z" fill="#464657"/>
<path d="M95.2335 165.508C95.2335 165.508 95.2335 165.406 95.2335 165.102C95.2335 164.797 95.2335 164.492 95.2335 164.086C95.2335 163.172 95.2335 161.851 95.2335 160.328L95.3344 160.429C91.3962 160.429 85.5392 160.429 78.9754 160.429C78.9754 160.429 78.8744 160.531 79.0764 160.328V160.429V160.632V160.937V161.547C79.0764 161.953 79.0764 162.359 79.0764 162.867C79.0764 163.68 79.0764 164.492 79.0764 165.305L78.9754 165.203C83.6206 165.203 87.6598 165.203 90.6893 165.203C92.103 165.203 93.2138 165.203 94.1227 165.203C94.5266 165.203 94.8295 165.203 95.0315 165.203C95.1325 165.508 95.2335 165.508 95.2335 165.508C95.2335 165.508 95.1325 165.508 94.9305 165.508C94.6276 165.508 94.3246 165.508 94.0217 165.508C93.1128 165.508 92.002 165.508 90.5883 165.508C87.5588 165.508 83.5196 165.508 78.8744 165.508H78.7734V165.406C78.7734 164.594 78.7734 163.781 78.7734 162.969C78.7734 162.562 78.7734 162.156 78.7734 161.648V161.039V160.734V160.531V160.429C78.9754 160.226 78.8744 160.328 78.8744 160.328C85.4382 160.328 91.2952 160.328 95.2335 160.328H95.3344V160.429C95.3344 161.953 95.3344 163.172 95.3344 164.188C95.3344 164.594 95.3344 164.899 95.3344 165.203C95.2335 165.406 95.2335 165.508 95.2335 165.508Z" fill="#464657"/>
<path d="M162.081 268.504C162.081 268.504 162.081 268.403 162.081 268.098C162.081 267.793 162.081 267.489 162.081 267.082C162.081 266.168 162.081 264.848 162.081 263.324L162.182 263.426C158.244 263.426 152.387 263.426 145.823 263.426C145.823 263.426 145.722 263.527 145.924 263.324V263.426V263.629V263.934V264.543C145.924 264.949 145.924 265.356 145.924 265.864C145.924 266.676 145.924 267.489 145.924 268.301L145.823 268.2C150.468 268.2 154.508 268.2 157.537 268.2C158.951 268.2 160.061 268.2 160.97 268.2C161.374 268.2 161.677 268.2 161.879 268.2C161.98 268.504 162.081 268.504 162.081 268.504C162.081 268.504 161.98 268.504 161.778 268.504C161.475 268.504 161.172 268.504 160.869 268.504C159.961 268.504 158.85 268.504 157.436 268.504C154.407 268.504 150.367 268.504 145.722 268.504H145.621V268.403C145.621 267.59 145.621 266.778 145.621 265.965C145.621 265.559 145.621 265.153 145.621 264.645V264.035V263.731V263.629V263.527C145.823 263.324 145.722 263.426 145.722 263.426C152.286 263.426 158.143 263.426 162.081 263.426H162.182V263.527C162.182 265.051 162.182 266.27 162.182 267.286C162.182 267.692 162.182 267.997 162.182 268.301C162.081 268.403 162.081 268.504 162.081 268.504Z" fill="#464657"/>
<path d="M158.347 184.604C158.347 184.604 158.347 184.502 158.347 184.197C158.347 183.893 158.347 183.588 158.347 183.182C158.347 182.267 158.347 180.947 158.347 179.423L158.448 179.525C154.509 179.525 148.653 179.525 142.089 179.525C142.089 179.525 141.988 179.626 142.19 179.423V179.525V179.728V180.033V180.642C142.19 181.049 142.19 181.455 142.19 181.963C142.19 182.775 142.19 183.588 142.19 184.4L142.089 184.299C146.734 184.299 150.773 184.299 153.803 184.299C155.216 184.299 156.327 184.299 157.236 184.299C157.64 184.299 157.943 184.299 158.145 184.299C158.246 184.604 158.347 184.604 158.347 184.604C158.347 184.604 158.246 184.604 158.044 184.604C157.741 184.604 157.438 184.604 157.135 184.604C156.226 184.604 155.115 184.604 153.702 184.604C150.672 184.604 146.633 184.604 141.988 184.604H141.887V184.502C141.887 183.689 141.887 182.877 141.887 182.064C141.887 181.658 141.887 181.252 141.887 180.744V180.134V179.83V179.626V179.525C142.089 179.322 141.988 179.423 141.988 179.423C148.552 179.423 154.408 179.423 158.347 179.423H158.448V179.525C158.448 181.049 158.448 182.267 158.448 183.283C158.448 183.689 158.448 183.994 158.448 184.299C158.347 184.502 158.347 184.604 158.347 184.604Z" fill="#464657"/>
<path d="M162.085 273.583C162.085 273.583 162.085 273.482 162.085 273.177C162.085 272.872 162.085 272.567 162.085 272.161C162.085 271.145 162.085 269.926 162.085 268.504L162.186 268.606C160.267 268.606 158.147 268.606 155.824 268.606C155.824 268.606 156.026 268.403 155.925 268.504V268.606V268.809V269.114C155.925 269.317 155.925 269.52 155.925 269.825C155.925 270.231 155.925 270.739 155.925 271.145C155.925 271.958 155.925 272.771 155.925 273.583L155.824 273.482C157.541 273.482 159.156 273.482 160.368 273.482C160.873 273.482 161.277 273.482 161.681 273.482C161.883 273.482 162.085 273.583 162.085 273.583C162.085 273.583 161.984 273.583 161.681 273.583C161.277 273.583 160.873 273.583 160.368 273.583C159.055 273.583 157.541 273.583 155.723 273.583H155.622V273.482C155.622 272.669 155.622 271.958 155.622 271.044C155.622 270.637 155.622 270.231 155.622 269.723C155.622 269.52 155.622 269.317 155.622 269.012V268.708V268.504V268.403C155.521 268.504 155.723 268.301 155.723 268.301C158.046 268.301 160.166 268.301 162.085 268.301H162.186V268.403C162.186 269.825 162.186 271.044 162.186 272.161C162.186 272.567 162.186 272.872 162.186 273.177C162.085 273.482 162.085 273.583 162.085 273.583Z" fill="#464657"/>
<path d="M162.085 189.683C162.085 189.683 162.085 189.582 162.085 189.277C162.085 188.972 162.085 188.667 162.085 188.261C162.085 187.245 162.085 186.027 162.085 184.605L162.186 184.706C160.267 184.706 158.147 184.706 155.824 184.706C155.824 184.706 156.026 184.503 155.925 184.605V184.706V184.909V185.214C155.925 185.417 155.925 185.62 155.925 185.925C155.925 186.331 155.925 186.839 155.925 187.245C155.925 188.058 155.925 188.871 155.925 189.683L155.824 189.582C157.541 189.582 159.156 189.582 160.368 189.582C160.873 189.582 161.277 189.582 161.681 189.582C161.883 189.683 162.085 189.683 162.085 189.683C162.085 189.683 161.984 189.683 161.681 189.683C161.277 189.683 160.873 189.683 160.368 189.683C159.055 189.683 157.541 189.683 155.723 189.683H155.622V189.582C155.622 188.769 155.622 188.058 155.622 187.144C155.622 186.738 155.622 186.331 155.622 185.823C155.622 185.62 155.622 185.417 155.622 185.112V184.808V184.605V184.503C155.521 184.605 155.723 184.401 155.723 184.401C158.046 184.401 160.166 184.401 162.085 184.401H162.186V184.503C162.186 185.925 162.186 187.144 162.186 188.261C162.186 188.667 162.186 188.972 162.186 189.277C162.085 189.582 162.085 189.683 162.085 189.683Z" fill="#464657"/>
<path d="M191.873 299.383H162.285V314.01H191.873V299.383Z" fill="#6C778B"/>
<path d="M91.7009 115.127C91.7009 115.229 81.0978 115.33 68.0712 115.229C55.0445 115.127 44.4414 115.026 44.4414 114.822C44.4414 114.721 55.0445 114.619 68.0712 114.721C81.0978 114.721 91.7009 114.924 91.7009 115.127Z" fill="#6C778B"/>
<path d="M68.0721 313.807C61.5083 313.807 56.1562 308.424 56.1562 301.821C56.1562 295.219 61.5083 289.835 68.0721 289.835C74.6359 289.835 79.988 295.219 79.988 301.821C79.887 308.525 74.6359 313.807 68.0721 313.807ZM68.0721 291.054C62.1142 291.054 57.267 295.93 57.267 301.923C57.267 307.916 62.1142 312.791 68.0721 312.791C74.03 312.791 78.8772 307.916 78.8772 301.923C78.8772 295.93 74.03 291.054 68.0721 291.054Z" fill="#6C778B"/>
<path d="M50.5039 303.65L67.4689 301.821L67.6708 303.243L50.5039 304.97V303.65Z" fill="#939DF4"/>
<path d="M33.4354 313.807C26.8716 313.807 21.5195 308.423 21.5195 301.821C21.5195 295.218 26.8716 289.835 33.4354 289.835C39.9992 289.835 45.3512 295.218 45.3512 301.821C45.3512 308.525 39.9992 313.807 33.4354 313.807ZM33.4354 291.054C27.4775 291.054 22.6303 295.929 22.6303 301.922C22.6303 307.915 27.4775 312.791 33.4354 312.791C39.3933 312.791 44.2404 307.915 44.2404 301.922C44.2404 295.929 39.3933 291.054 33.4354 291.054Z" fill="#6C778B"/>
<path d="M59.9942 284.452L58.9844 281.608L59.6913 280.49L64.1345 279.271L64.3364 279.983L60.4991 281.1L59.9942 282.116L60.7011 284.147L59.9942 284.452Z" fill="#939DF4"/>
<path d="M67.1614 301.212L61.4055 288.007L49.8935 302.532L48.7827 301.516L60.9005 287.194L60.4966 286.382L43.3297 290.038L42.9258 288.718L60.0927 285.366L59.6888 284.249L60.9005 283.944L68.0703 300.602L67.1614 301.212Z" fill="#939DF4"/>
<path d="M34.0391 302.532L44.7431 304.259V303.04L34.342 301.516L34.0391 302.532Z" fill="#939DF4"/>
<path d="M33.4336 301.11L41.4112 290.242L42.9259 290.953L34.4434 301.821L33.4336 301.11Z" fill="#939DF4"/>
<path d="M47.4736 307.509C49.2583 307.509 50.705 306.054 50.705 304.259C50.705 302.464 49.2583 301.008 47.4736 301.008C45.6889 301.008 44.2422 302.464 44.2422 304.259C44.2422 306.054 45.6889 307.509 47.4736 307.509Z" fill="#939DF4"/>
<path d="M42.9275 288.718L42.1196 285.976H40.5039L41.4127 290.242L42.9275 290.953L43.3314 290.039L42.9275 288.718Z" fill="#939DF4"/>
<path d="M38.3849 285.874C38.4858 286.28 39.0917 287.905 39.8996 287.905C40.7074 288.007 46.2614 287.093 47.1703 286.89C47.6752 286.788 47.9781 285.874 46.7663 285.874C44.8477 285.874 38.0819 285.061 38.3849 285.874Z" fill="#939DF4"/>
<path d="M34.847 301.923C34.847 302.736 34.2411 303.345 33.4333 303.345C32.6254 303.345 32.0195 302.736 32.0195 301.923C32.0195 301.11 32.6254 300.501 33.4333 300.501C34.2411 300.501 34.847 301.11 34.847 301.923Z" fill="#939DF4"/>
<path d="M69.4877 301.923C69.4877 302.736 68.8818 303.345 68.0739 303.345C67.266 303.345 66.6602 302.736 66.6602 301.923C66.6602 301.11 67.266 300.501 68.0739 300.501C68.8818 300.501 69.4877 301.11 69.4877 301.923Z" fill="#939DF4"/>
<path opacity="0.5" d="M59.9942 284.452L58.9844 281.608L59.6913 280.49L64.1345 279.271L64.3364 279.983L60.4991 281.1L59.9942 282.116L60.7011 284.147L59.9942 284.452Z" fill="#1B235E"/>
<path opacity="0.5" d="M47.4736 307.509C49.2583 307.509 50.705 306.054 50.705 304.259C50.705 302.464 49.2583 301.008 47.4736 301.008C45.6889 301.008 44.2422 302.464 44.2422 304.259C44.2422 306.054 45.6889 307.509 47.4736 307.509Z" fill="#1B235E"/>
<path opacity="0.5" d="M38.3849 285.874C38.4858 286.28 39.0917 287.905 39.8996 287.905C40.7074 288.007 46.2614 287.093 47.1703 286.89C47.6752 286.788 47.9781 285.874 46.7663 285.874C44.8477 285.874 38.0819 285.061 38.3849 285.874Z" fill="#1B235E"/>
<path opacity="0.5" d="M34.847 301.923C34.847 302.736 34.2411 303.345 33.4333 303.345C32.6254 303.345 32.0195 302.736 32.0195 301.923C32.0195 301.11 32.6254 300.501 33.4333 300.501C34.2411 300.501 34.847 301.11 34.847 301.923Z" fill="#1B235E"/>
<path opacity="0.5" d="M69.4877 301.923C69.4877 302.736 68.8818 303.345 68.0739 303.345C67.266 303.345 66.6602 302.736 66.6602 301.923C66.6602 301.11 67.266 300.501 68.0739 300.501C68.8818 300.501 69.4877 301.11 69.4877 301.923Z" fill="#1B235E"/>
<path d="M47.0648 304.97V309.642H44.7422V310.759H48.4785V304.97H47.0648Z" fill="#939DF4"/>
<path d="M107.452 314.01H97.6564L95.6367 298.57H109.37L107.452 314.01Z" fill="#464657"/>
<path d="M104.626 293.898C105.636 293.187 106.848 292.983 108.06 292.983C108.666 292.983 109.373 293.085 109.877 293.491C110.382 293.898 110.584 294.71 110.079 295.218C109.776 295.523 109.373 295.624 109.07 295.624C108.161 295.726 107.151 295.726 106.444 296.335C106.04 296.742 105.838 297.249 105.434 297.453C104.929 297.656 104.424 297.453 104.021 297.046C103.718 296.64 103.617 296.132 103.718 295.523C103.718 295.726 103.718 294.609 104.626 293.898Z" fill="#939DF4"/>
<path d="M101.595 289.53C102.403 290.343 103.615 289.936 104.12 289.225C104.625 288.616 104.726 287.702 104.928 286.889C105.231 285.061 105.433 283.131 104.827 281.303C104.625 280.795 104.322 280.287 103.918 279.881C103.514 279.576 102.908 279.373 102.403 279.576C101.797 279.779 101.494 280.49 101.293 281.1C100.687 282.928 100.485 284.959 100.788 286.889C100.788 287.905 101.091 288.921 101.595 289.53Z" fill="#939DF4"/>
<path d="M100.081 291.155C100.686 290.241 100.686 289.022 100.182 288.006C99.7776 286.991 98.8687 286.178 98.0609 285.569C97.0511 284.858 95.8393 284.35 94.5265 284.045C94.0216 283.943 93.5167 283.842 93.0118 283.943C92.5069 284.045 92.103 284.35 91.901 284.858C91.5981 285.569 92.103 286.28 92.5069 286.889C93.3147 287.905 94.1226 288.921 95.0314 289.835C95.7383 290.546 96.6471 291.257 97.657 291.561C98.6668 291.765 99.6766 291.663 100.081 290.85" fill="#939DF4"/>
<path d="M103.413 303.446C103.413 303.446 103.413 303.243 103.413 303.04C103.413 302.735 103.413 302.329 103.413 301.922C103.413 301.414 103.413 300.805 103.413 300.196C103.514 299.586 103.615 298.875 103.716 298.164C103.918 297.453 104.12 296.742 104.524 296.234C104.827 295.726 105.231 295.218 105.635 295.015C106.038 294.71 106.442 294.609 106.644 294.507C106.947 294.406 107.048 294.406 107.048 294.406C107.048 294.406 106.947 294.406 106.644 294.406C106.341 294.507 105.937 294.609 105.534 294.812C105.13 295.117 104.726 295.523 104.322 296.031C104.019 296.64 103.716 297.25 103.514 297.961C103.312 298.672 103.211 299.383 103.211 300.094C103.211 300.703 103.211 301.313 103.211 301.821C103.211 302.329 103.312 302.735 103.312 302.938C103.413 303.344 103.413 303.446 103.413 303.446Z" fill="#464657"/>
<path d="M103.414 302.633C103.414 302.633 103.414 302.532 103.414 302.43C103.414 302.328 103.414 302.125 103.313 301.922C103.212 301.414 103.111 300.703 103.111 299.891C102.909 298.164 102.808 296.234 102.808 293.593C102.707 291.054 102.707 288.21 102.909 286.28C102.909 285.873 103.01 285.467 103.01 285.061C103.01 284.756 103.111 284.451 103.111 284.147C103.111 283.943 103.111 283.74 103.212 283.639C103.212 283.537 103.212 283.436 103.212 283.436C103.212 283.436 103.212 283.537 103.212 283.639C103.212 283.74 103.111 283.943 103.111 284.147C103.111 284.35 103.01 284.654 103.01 285.061C103.01 285.365 102.909 285.772 102.909 286.28C102.707 288.006 102.707 290.444 102.707 293.085C102.808 295.726 102.909 298.164 103.212 299.891C103.313 300.805 103.414 301.414 103.515 301.922C103.515 302.125 103.616 302.328 103.616 302.43C103.414 302.532 103.414 302.633 103.414 302.633Z" fill="#464657"/>
<path d="M102.807 297.961C102.807 297.961 102.807 297.758 102.706 297.351C102.605 296.945 102.504 296.437 102.302 295.828C102.1 295.218 101.797 294.406 101.494 293.593C101.292 293.187 100.989 292.273 100.181 291.054C99.1715 289.225 97.8587 287.905 96.8489 286.991C96.546 286.787 96.344 286.584 96.142 286.483C96.0411 286.381 95.9401 286.381 95.8391 286.28C95.7381 286.178 95.6371 286.178 95.5362 286.178C95.2332 285.975 95.0312 285.873 95.0312 285.975C95.0312 285.975 95.7381 286.381 96.8489 287.194C97.8587 288.006 99.1715 289.428 100.181 291.155C100.787 292.069 101.191 292.984 101.494 293.695C101.898 294.507 102.1 295.218 102.302 295.929C102.504 296.539 102.605 297.046 102.706 297.453C102.706 297.758 102.807 297.961 102.807 297.961Z" fill="#464657"/>
<path d="M109.673 298.57H94.9297V300.297H109.673V298.57Z" fill="#464657"/>
<path d="M109.373 300.195C109.373 300.297 106.343 300.297 102.708 300.297C99.0724 300.297 96.043 300.297 96.043 300.195C96.043 300.094 99.0724 300.094 102.708 300.094C106.343 300.094 109.373 300.094 109.373 300.195Z" fill="#E0E0E0"/>
<path d="M108.464 306.188C108.464 306.188 108.364 306.29 108.061 306.493C107.758 306.696 107.354 306.899 106.849 307.102C106.243 307.305 105.536 307.305 104.829 306.899C104.122 306.594 103.516 305.985 102.709 305.477C102.002 305.071 101.093 304.969 100.386 305.376C99.6791 305.68 99.1742 306.188 98.6692 306.493C98.1643 306.798 97.6594 306.899 97.3565 306.899C97.0535 306.899 96.8516 306.798 96.8516 306.798C96.8516 306.798 97.0535 306.899 97.3565 306.899C97.6594 306.899 98.1643 306.798 98.6692 306.493C99.1742 306.188 99.6791 305.68 100.386 305.376C100.689 305.172 101.194 305.071 101.598 305.071C102.002 305.071 102.507 305.274 102.91 305.477C103.718 305.883 104.324 306.493 105.031 306.899C105.738 307.305 106.445 307.305 106.95 307.102C107.859 306.594 108.464 306.087 108.464 306.188Z" fill="#E0E0E0"/>
<path d="M97.5563 304.665C97.5563 304.665 97.6573 304.563 97.8592 304.665C97.9602 304.766 98.0612 304.868 98.0612 305.071C98.0612 305.274 97.9602 305.376 97.7582 305.477C97.5563 305.579 97.3543 305.477 97.2533 305.376C97.1523 305.274 97.1523 305.071 97.1523 304.97C97.2533 304.665 97.4553 304.665 97.5563 304.665C97.4553 304.766 97.3543 304.766 97.3543 304.97C97.3543 305.173 97.4553 305.376 97.6573 305.274C97.8592 305.173 97.8592 304.97 97.7582 304.868C97.6573 304.665 97.5563 304.665 97.5563 304.665Z" fill="#E0E0E0"/>
<path d="M101.29 306.188C101.29 306.188 101.189 306.29 101.088 306.392C100.987 306.493 101.088 306.696 101.29 306.696C101.491 306.696 101.592 306.493 101.491 306.392C101.39 306.29 101.29 306.29 101.29 306.188C101.29 306.188 101.491 306.087 101.592 306.29C101.693 306.392 101.693 306.493 101.693 306.696C101.693 306.9 101.491 307.001 101.29 307.001C101.088 307.001 100.987 306.9 100.886 306.798C100.785 306.595 100.886 306.493 100.987 306.392C101.088 306.188 101.29 306.188 101.29 306.188Z" fill="#E0E0E0"/>
<path d="M105.941 305.173C105.941 305.173 105.84 305.274 105.84 305.376C105.739 305.579 105.941 305.782 106.143 305.782C106.446 305.782 106.446 305.477 106.345 305.376C106.244 305.274 106.143 305.173 106.143 305.173C106.143 305.173 106.345 305.071 106.446 305.274C106.547 305.376 106.547 305.477 106.547 305.68C106.547 305.884 106.345 305.985 106.143 305.985C105.941 305.985 105.739 305.985 105.638 305.782C105.537 305.579 105.537 305.477 105.638 305.376C105.739 305.071 105.941 305.071 105.941 305.173Z" fill="#E0E0E0"/>
<path d="M292.047 300.805H247.816V314.009H292.047V300.805Z" fill="#6C778B"/>
<path d="M293.764 299.992H246V302.532H293.764V299.992Z" fill="#6C778B"/>
<path d="M291.942 302.532C291.942 302.633 282.046 302.837 269.928 302.837C257.81 302.837 247.914 302.735 247.914 302.532C247.914 302.43 257.81 302.227 269.928 302.227C282.046 302.227 291.942 302.329 291.942 302.532Z" fill="#464657"/>
<path d="M292.349 305.985C292.349 306.087 282.352 306.189 270.032 306.189C257.712 306.189 247.715 306.087 247.715 305.985C247.715 305.884 257.712 305.782 270.032 305.782C282.352 305.782 292.349 305.884 292.349 305.985Z" fill="#FAFAFA"/>
<path d="M291.24 299.992C290.635 297.859 289.726 296.843 287.605 296.437C287.807 292.577 284.879 288.717 281.041 287.905C277.204 287.092 273.064 289.327 271.65 292.984C269.327 290.952 265.894 290.343 262.966 291.257C260.037 292.171 257.614 294.812 256.907 297.758C253.776 296.234 250.141 297.148 248.828 299.891H291.24V299.992Z" fill="#464657"/>
<path d="M418.981 264.136C418.981 264.136 417.163 263.629 416.457 267.895C415.75 272.161 415.75 274.497 415.75 274.497C415.75 274.497 415.548 275.411 414.841 275.106C414.134 274.802 413.023 273.583 412.215 274.091C411.407 274.7 411.407 275.818 412.013 277.24C412.619 278.662 414.033 282.014 414.134 282.318C414.235 282.623 414.235 283.131 413.73 282.928C413.225 282.725 409.994 280.896 409.186 282.42C408.378 284.045 410.903 287.803 411.105 288.21C411.407 288.616 411.306 288.819 411.206 289.022C411.004 289.225 409.994 288.514 409.085 288.006C408.176 287.499 406.964 287.092 406.56 288.006C406.156 288.921 406.964 292.171 411.508 296.336L413.73 298.164L416.457 297.148C422.011 294.609 423.727 291.866 423.727 290.749C423.626 289.733 422.415 289.733 421.405 289.936C420.395 290.139 419.183 290.546 419.082 290.241C418.981 290.038 418.981 289.835 419.385 289.428C419.789 289.124 423.323 286.28 423.02 284.553C422.717 282.826 419.082 283.639 418.577 283.639C418.072 283.639 418.173 283.131 418.375 282.928C418.577 282.725 420.799 279.982 421.91 278.763C423.02 277.544 423.323 276.529 422.717 275.716C422.112 274.903 420.597 275.716 419.89 275.818C419.183 275.919 419.183 275.005 419.183 275.005C419.183 275.005 419.89 272.77 420.496 268.504C420.9 264.238 418.88 264.136 418.981 264.136Z" fill="#939DF4"/>
<path d="M418.275 267.996C418.275 268.199 418.275 268.199 418.275 268.301C418.275 268.504 418.174 268.91 418.174 269.317C418.073 270.231 417.871 271.45 417.669 273.075C417.164 276.224 416.558 280.49 415.75 285.264C414.942 290.038 414.235 294.405 413.831 297.453C413.629 298.976 413.427 300.297 413.225 301.109C413.124 301.516 413.124 301.922 413.023 302.125C413.023 302.227 413.023 302.328 413.023 302.43C413.023 302.531 413.023 302.531 413.023 302.531C413.023 302.531 413.023 302.531 413.023 302.43C413.023 302.328 413.023 302.328 413.023 302.125C413.023 301.922 413.124 301.516 413.124 301.109C413.225 300.195 413.427 298.976 413.629 297.351C414.134 294.202 414.74 289.936 415.548 285.162C416.356 280.388 417.063 276.122 417.467 272.973C417.669 271.45 417.871 270.129 418.073 269.317C418.174 268.91 418.174 268.504 418.275 268.301C418.275 268.199 418.275 268.098 418.275 267.996Z" fill="#464657"/>
<path d="M413.629 275.817C413.629 275.817 413.73 275.919 413.73 276.021C413.831 276.122 413.932 276.325 414.033 276.528C414.336 276.935 414.639 277.443 415.043 278.154C415.447 278.763 415.75 279.372 415.952 279.779C416.052 279.982 416.153 280.083 416.254 280.287C416.355 280.388 416.355 280.49 416.355 280.49C416.355 280.49 416.254 280.388 416.254 280.287C416.153 280.185 416.052 279.982 415.952 279.779C415.649 279.372 415.346 278.865 414.942 278.154C414.538 277.544 414.235 277.036 414.033 276.528C413.932 276.325 413.831 276.224 413.73 276.021C413.629 275.919 413.629 275.817 413.629 275.817Z" fill="#464657"/>
<path d="M416.656 280.286C416.656 280.286 417.767 279.474 419.181 278.56C420.595 277.646 421.705 276.935 421.705 276.935C421.705 276.935 420.595 277.747 419.181 278.661C417.767 279.575 416.656 280.286 416.656 280.286Z" fill="#464657"/>
<path d="M415.145 287.498C415.145 287.498 415.246 287.498 415.347 287.397C415.447 287.397 415.649 287.295 415.851 287.194C416.356 287.092 416.962 286.889 417.669 286.584C418.376 286.381 418.982 286.076 419.386 285.873C419.588 285.771 419.79 285.67 419.891 285.67C419.992 285.67 420.093 285.568 420.093 285.568C420.093 285.568 419.992 285.568 419.891 285.67C419.79 285.772 419.588 285.771 419.386 285.873C418.982 286.076 418.376 286.279 417.669 286.584C416.962 286.787 416.356 286.99 415.851 287.194C415.649 287.295 415.447 287.295 415.347 287.295C415.246 287.498 415.145 287.498 415.145 287.498Z" fill="#464657"/>
<path d="M411.508 284.146C411.508 284.146 411.609 284.146 411.71 284.248C411.811 284.35 411.912 284.451 412.114 284.553C412.417 284.857 412.922 285.264 413.426 285.772C413.931 286.28 414.335 286.686 414.739 286.991C414.941 287.194 415.042 287.295 415.143 287.397C415.244 287.498 415.244 287.498 415.244 287.498C415.244 287.498 415.143 287.498 415.042 287.397C414.941 287.295 414.84 287.194 414.638 287.092C414.335 286.787 413.83 286.381 413.325 285.873C412.821 285.365 412.417 284.959 412.013 284.654C411.811 284.451 411.71 284.35 411.609 284.248C411.609 284.146 411.508 284.146 411.508 284.146Z" fill="#464657"/>
<path d="M409.387 290.342C409.387 290.342 409.488 290.342 409.589 290.545C409.69 290.647 409.892 290.85 410.094 290.952C410.498 291.358 411.103 291.866 411.81 292.475C412.416 293.085 413.022 293.593 413.426 293.999C413.628 294.202 413.83 294.304 413.931 294.507C414.032 294.608 414.133 294.71 414.133 294.71C414.133 294.71 414.032 294.71 413.931 294.507C413.83 294.405 413.628 294.202 413.426 294.101C413.022 293.694 412.416 293.186 411.709 292.577C411.103 291.968 410.498 291.46 410.094 291.053C409.892 290.85 409.69 290.749 409.589 290.545C409.488 290.444 409.387 290.342 409.387 290.342Z" fill="#464657"/>
<path d="M414.035 294.507C414.035 294.507 414.136 294.507 414.237 294.405C414.439 294.304 414.641 294.304 414.944 294.202C415.55 293.999 416.358 293.694 417.166 293.39C418.074 293.085 418.781 292.679 419.387 292.476C419.69 292.374 419.892 292.272 420.094 292.171C420.296 292.069 420.296 292.069 420.296 292.069C420.296 292.069 420.195 292.171 420.094 292.171C419.892 292.272 419.69 292.374 419.488 292.476C418.882 292.78 418.175 293.085 417.267 293.39C416.358 293.694 415.55 293.999 415.045 294.202C414.742 294.304 414.54 294.405 414.338 294.405C414.136 294.507 414.035 294.507 414.035 294.507Z" fill="#464657"/>
<path d="M419.182 314.01H409.387L407.367 298.57H421.101L419.182 314.01Z" fill="#464657"/>
<path d="M421.404 298.57H406.66V300.297H421.404V298.57Z" fill="#464657"/>
<path d="M421.103 300.195C421.103 300.297 418.074 300.297 414.438 300.297C410.803 300.297 407.773 300.297 407.773 300.195C407.773 300.094 410.803 300.094 414.438 300.094C418.074 300.094 421.103 300.094 421.103 300.195Z" fill="#E0E0E0"/>
<path d="M420.191 306.188C420.191 306.188 420.09 306.29 419.787 306.493C419.484 306.696 419.08 306.899 418.575 307.102C417.97 307.305 417.263 307.305 416.556 306.899C415.849 306.594 415.243 305.985 414.435 305.477C413.728 305.071 412.819 304.969 412.113 305.376C411.406 305.68 410.901 306.188 410.396 306.493C409.891 306.798 409.386 306.899 409.083 306.899C408.78 306.899 408.578 306.798 408.578 306.798C408.578 306.798 408.78 306.899 409.083 306.899C409.386 306.899 409.891 306.798 410.396 306.493C410.901 306.188 411.406 305.68 412.113 305.376C412.415 305.172 412.92 305.071 413.324 305.071C413.728 305.071 414.233 305.274 414.637 305.477C415.445 305.883 416.051 306.493 416.758 306.899C417.465 307.305 418.171 307.305 418.676 307.102C419.585 306.594 420.191 306.087 420.191 306.188Z" fill="#E0E0E0"/>
<path d="M409.287 304.664C409.287 304.664 409.388 304.563 409.59 304.664C409.691 304.766 409.792 304.867 409.792 305.071C409.792 305.274 409.691 305.375 409.489 305.477C409.287 305.578 409.085 305.477 408.984 305.375C408.883 305.274 408.883 305.071 408.883 304.969C408.984 304.664 409.186 304.664 409.287 304.664C409.186 304.766 409.085 304.766 409.085 304.969C409.085 305.172 409.186 305.375 409.388 305.274C409.59 305.172 409.59 304.969 409.489 304.867C409.388 304.664 409.287 304.664 409.287 304.664Z" fill="#E0E0E0"/>
<path d="M413.024 306.188C413.024 306.188 412.923 306.29 412.822 306.391C412.721 306.493 412.822 306.696 413.024 306.696C413.226 306.696 413.327 306.493 413.226 306.391C413.125 306.29 413.024 306.29 413.024 306.188C413.024 306.188 413.226 306.086 413.327 306.29C413.428 306.391 413.428 306.493 413.428 306.696C413.428 306.899 413.226 307.001 413.024 307.001C412.822 307.001 412.721 306.899 412.62 306.797C412.519 306.594 412.62 306.493 412.721 306.391C412.822 306.188 413.024 306.188 413.024 306.188Z" fill="#E0E0E0"/>
<path d="M417.668 305.173C417.668 305.173 417.567 305.274 417.567 305.376C417.466 305.579 417.668 305.782 417.87 305.782C418.173 305.782 418.173 305.477 418.072 305.376C417.971 305.274 417.87 305.173 417.87 305.173C417.87 305.173 418.072 305.071 418.173 305.274C418.274 305.376 418.274 305.477 418.274 305.68C418.274 305.884 418.072 305.985 417.87 305.985C417.668 305.985 417.466 305.985 417.365 305.782C417.264 305.579 417.264 305.477 417.365 305.376C417.466 305.071 417.668 305.071 417.668 305.173Z" fill="#E0E0E0"/>
</svg>

);

export default IconProfileHouses;
