import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { Form, Input } from "antd";

import "./styles.scss";

const InputField = ({ intl, maxLength, name, placeholderMessage, rules, titleMessage }) => (
  <div className="form-input">
    <div className="form-input__message">
      <FormattedMessage id={titleMessage} />
    </div>
    <Form.Item name={name} rules={rules}>
      <Input
        className="simple-input"
        maxLength={maxLength}
        placeholder={intl.formatMessage({ id: placeholderMessage })}
      />
    </Form.Item>
  </div>
);

InputField.props = {
  intl: PropTypes.object,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  placeholderMessage: PropTypes.string,
  rules: PropTypes.array,
  titleMessage: PropTypes.string,
};

export default injectIntl(InputField);
