import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import { SimpleButton } from "components/Controls/Buttons";
import { LocationsSelectView } from "containers/Houses/Views";
import { IconMoreCriteria } from "components/Svgs";
import { CRITERIA_MAP } from "constants/criteria";
import { priceFormatter } from "utils/helpers/housesHelper/formatter";
import { getPriceValue, getPropertyTypeValue } from "utils/helpers/housesHelper/getter";
import { BASIC_CRITERIA_FILTERS } from "./constants";
import "./styles.scss";

const FiltersBarView = observer((
  {
    criteriaData,
    criteriaStore,
    isMap,
    onMoreButtonClick,
    onSubmitCriteriaFilter
  }
) => {
  const activeFilter = criteriaStore.retrieveActiveFilter();

  const checkIsCriterionFilled = item => {
    if (_.has(criteriaData, item.key) && !_.isEmpty(criteriaData[item.key])) {
      return item.key === CRITERIA_MAP.prop_type ? !_.isEmpty(criteriaData[item.key].values) : true;
    }
    return false;
  };

  const getButtonText = key => {
    const methodMap = {
      bathrooms: getBathroomsTitle,
      bedrooms: getBedroomsTitle,
      price: getPriceTitle,
      prop_type: getPropTypeTitle,
    };
    return _.has(methodMap, key) ? methodMap[key]() : null;
  };

  const getBathroomsTitle = () => getButtonTitle(criteriaData.bathrooms.min, criteriaData.bathrooms.ideal, "ba");

  const getBedroomsTitle = () => getButtonTitle(criteriaData.bedrooms.min, criteriaData.bedrooms.ideal, "bd");

  const getButtonTitle = (firstValue, secondValue, units) => {
    const value = `${firstValue}-${secondValue}`;
    if (units) {
      return `${value} ${units}`;
    }
    return value;
  };

  const getFormattedPriceValue = value => {
    const formattedValue = priceFormatter(value);
    return getPriceValue(formattedValue);
  };

  const getPriceTitle = () => {
    const minValue = getFormattedPriceValue(criteriaData.price.min);
    const maxValue = getFormattedPriceValue(criteriaData.price.max);
    return getButtonTitle(minValue, maxValue);
  };

  const getPropTypeTitle = () =>
    criteriaData.prop_type.values.map((value, index) => {
      const formattedValue = getPropertyTypeValue(value);
      if (index === criteriaData.prop_type.values.length - 1) {
        return formattedValue;
      }
      return <>{formattedValue},&nbsp;</>;
    });

  const renderLocationsAutocomplete = () => (
    <LocationsSelectView
      criteriaData={criteriaData}
      isMap={isMap}
      onSubmit={onSubmitCriteriaFilter}
    />
  );

  const renderFilledCriteriaContent = key => {
    const value = getButtonText(key);
    return (
      <Tooltip title={value} placement="bottom">
        <div className="criteria-buttons__button__text">{value}</div>
      </Tooltip>
    );
  };

  const renderCriteriaButton = item => {
    const isFilled = checkIsCriterionFilled(item);
    return (
      <div
        className={classNames(
          "criteria-buttons__button",
          { isActive: item.key === activeFilter || isFilled, isFilled }
        )}
        onClick={() => criteriaStore.setActiveFilter(item.key)}
        role="button"
      >
        {isFilled ? (
          renderFilledCriteriaContent(item.key)
        ) : (
          <div className="criteria-buttons__button__icon">
            {item.icon}
          </div>
        )}
      </div>
    );
  };

  const renderCriteriaButtons = () => (
    <div className="criteria-buttons">
      {BASIC_CRITERIA_FILTERS.map(item => renderCriteriaButton(item))}
      <SimpleButton className="criteria-buttons__more-button" onClick={onMoreButtonClick}>
        <IconMoreCriteria />
        <FormattedMessage id="houses.more" />
      </SimpleButton>
    </div>
  );

  return (
    <div className="results-filters-bar-container">
      {renderLocationsAutocomplete()}
      {renderCriteriaButtons()}
    </div>
  );
});

FiltersBarView.propTypes = {
  criteriaData: PropTypes.object,
  isMap: PropTypes.bool,
  onMoreButtonClick: PropTypes.func,
  onSubmitCriteriaFilter: PropTypes.func,
};

export default inject("criteriaStore")(FiltersBarView);
