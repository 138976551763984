import React, { Component } from 'react';
import _ from "lodash";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import { IconMailCheck } from "components/Svgs";


export const withPassword = WrappedComponent => class Password extends Component {
    state = {
      passwordLength: undefined,
      passwordLetter: undefined,
      passwordsMatch: undefined,
    };

    validatePassword = (value) => {
      const validatedLength = /^(.{6,20})$/.test(value);
      const validatedLetter = /^(?=.*[a-z])(?=.*[A-Z])[^]/.test(value);
      this.setState({passwordLength: validatedLength, passwordLetter: validatedLetter});
      return validatedLength && validatedLetter
    }

    validateConfirmPassword = (password, value) => {
      this.setState({passwordsMatch: password === value});
    }

    observeConfirmPasswordField = (passwordId, confirmPasswordId) => {
      const password = document.getElementById(passwordId);
      const confirmPassword = document.getElementById(confirmPasswordId);

      if (password && confirmPassword) {
        const observer = new MutationObserver((mutationsList) => {
          _.forEach(mutationsList, mutation => {
            if (mutation && mutation.attributeName === "type") {
              confirmPassword.type = mutation.target.type
            }
          })
        });
        observer.observe(password, {attributes: true});
      }
    }

    renderValidations = () => {
      const { passwordLength, passwordLetter } = this.state;
      if (passwordLength === undefined || (passwordLength && passwordLetter)) {
        return null;
      }

      return (
        <>
          <div className={classNames("password-validation", { "validation-success": passwordLength })}>
            <div className="password-validation__icon">
              <IconMailCheck fillIcon={passwordLength ? "#3C9B6E" : "#FB5D5D"} />
            </div>
            <div className="password-validation__message">
              <FormattedMessage id="signUp.form.passwordLengthValidation" />
            </div>
          </div>
          <div className={classNames("password-validation", { "validation-success": passwordLetter })}>
            <div className="password-validation__icon">
              <IconMailCheck fillIcon={passwordLetter ? "#3C9B6E" : "#FB5D5D"} />
            </div>
            <div className="password-validation__message">
              <FormattedMessage id="signUp.form.passwordLetterValidation" />
            </div>
          </div>
        </>
      )
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          validatePassword={this.validatePassword}
          validateConfirmPassword={this.validateConfirmPassword}
          observeConfirmPasswordField={this.observeConfirmPasswordField}
          renderValidations={this.renderValidations}
        />
      );
    }
  };
