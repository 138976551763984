import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IconStarFilled } from "components/Svgs";
import { getClassnameForRating } from "utils/helpers/housesHelper/common";
import "./styles.scss";

const PlaceRatingView = props => {
  const { kind, isMap, item } = props;
  let content;
  const itemKind = kind || item.kind || null;

  if (itemKind === "school") {
    const schoolRating = item.ratings.great_schools_rating;
    const noRating = _.isNull(schoolRating);
    content = (
      <div className={classNames("circle", getClassnameForRating(schoolRating), { isMap, noRating })}>
        <div className="circle__text">
          {_.isNull(schoolRating) ? (
            <FormattedMessage id="general.noData" />
          ) : (
            <>
              <div className={classNames("actual-rating", { isMap })}>
                {schoolRating}
              </div>
              <div className={classNames("max-rating", { isMap })}>/10</div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    content = (
      <>
        <div className={classNames("number", { isMap })}>
          {item.rating ? item.rating.toFixed(1) : <FormattedMessage id="general.noData" />}
        </div>
        <IconStarFilled className={classNames("star-icon", { isMap })} />
      </>
    );
  }

  return (
    <div className={classNames("place-rating", { isMap })}>
      {content}
    </div>
  );
};

PlaceRatingView.propTypes = {
  kind: PropTypes.string,
  isMap: PropTypes.bool,
  item: PropTypes.object,
};

export default PlaceRatingView;
