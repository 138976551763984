import React from "react";

const IconQuietness = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1284_8330)">
      <path
        d="M16.9456 32C18.8076 32 20.3171 30.4905 20.3171 28.6285C20.3171 26.7665 18.8076 25.2571 16.9456 25.2571C15.0837 25.2571 13.5742 26.7665 13.5742 28.6285C13.5742 30.4905 15.0837 32 16.9456 32Z"
        fill="url(#paint0_linear_1284_8330)"
      />
      <path
        d="M16.9462 25.2568C15.1901 25.2568 13.7482 26.5997 13.5898 28.3144L17.2601 31.9847C18.9749 31.8263 20.3177 30.3844 20.3177 28.6283C20.3176 26.7663 18.8082 25.2568 16.9462 25.2568Z"
        fill="url(#paint1_linear_1284_8330)"
      />
      <path
        d="M27.9925 22.749H5.89649C4.38743 22.749 3.16406 23.9723 3.16406 25.4815C3.16406 26.9906 4.38737 28.2139 5.89649 28.2139H27.9925C29.5015 28.2139 30.7249 26.9906 30.7249 25.4815C30.7249 23.9723 29.5016 22.749 27.9925 22.749Z"
        fill="url(#paint2_linear_1284_8330)"
      />
      <path
        d="M26.4111 20.6873V15.2099C26.4111 10.7801 23.3686 7.06251 19.2595 6.0297C19.4938 5.63089 19.6295 5.16633 19.6295 4.67046C19.6295 3.18784 18.4274 1.9856 16.9447 1.9856C15.462 1.9856 14.2598 3.18778 14.2598 4.67046C14.2598 5.16633 14.3955 5.63089 14.6299 6.0297C10.5208 7.06251 7.47909 10.7801 7.47909 15.2099V20.6873C7.47909 21.8731 6.51784 22.8343 5.33203 22.8343H28.5573C27.3716 22.8343 26.4111 21.8731 26.4111 20.6873ZM16.9447 5.74346C16.3519 5.74346 15.8708 5.26327 15.8708 4.67034C15.8708 4.07659 16.3519 3.5964 16.9447 3.5964C17.5376 3.5964 18.0187 4.07659 18.0187 4.67034C18.0186 5.26321 17.5375 5.74346 16.9447 5.74346Z"
        fill="url(#paint3_linear_1284_8330)"
      />
      <path
        d="M3.19922 26.0242C3.40547 27.3289 4.53496 28.3266 5.89777 28.2033H27.9937C29.3565 28.3266 30.4859 27.3289 30.6923 26.0242H3.19922Z"
        fill="url(#paint4_linear_1284_8330)"
      />
      <path
        d="M30.7272 25.4812C30.7272 23.9722 29.5039 22.7488 27.9948 22.7488H27.9598C27.0661 22.4892 26.4131 21.6648 26.4131 20.6873V15.2099C26.4131 13.1235 25.7378 11.1953 24.5944 9.6311L6.01172 28.2137H13.6016C13.5849 28.3497 13.5753 28.4879 13.5753 28.6284C13.5753 30.4904 15.0847 31.9998 16.9467 31.9998C18.8087 31.9998 20.3182 30.4904 20.3182 28.6284C20.3182 28.4879 20.3085 28.3497 20.2919 28.2137H27.9947C29.5039 28.2137 30.7272 26.9903 30.7272 25.4812Z"
        fill="url(#paint5_linear_1284_8330)"
      />
      <path
        d="M4.78398 30.9638C4.36367 30.5435 4.36367 29.8618 4.78398 29.4415L26.64 7.5855C27.0603 7.16519 27.7419 7.16519 28.1622 7.5855C28.5825 8.00581 28.5825 8.68743 28.1622 9.10774L6.30623 30.9638C5.88592 31.3842 5.20436 31.3842 4.78398 30.9638Z"
        fill="url(#paint6_linear_1284_8330)"
      />
      <path
        d="M10.4099 3.40723C7.82157 4.86016 5.7622 7.1474 4.59833 9.90189C4.36971 10.4429 4.63208 11.0668 5.17558 11.2896L5.20646 11.3023C5.74802 11.5241 6.36045 11.2624 6.58882 10.7236C7.56669 8.41696 9.29362 6.50178 11.4625 5.28535C11.927 5.02472 12.127 4.46104 11.9287 3.96666L11.916 3.93535C11.6772 3.33985 10.9692 3.09323 10.4099 3.40723Z"
        fill="url(#paint7_linear_1284_8330)"
      />
      <path
        d="M9.10061 0.125817C5.64025 1.94568 2.88495 4.92992 1.35752 8.5541C1.12565 9.10428 1.39033 9.73828 1.94277 9.96478L1.94314 9.9649C2.48883 10.1887 3.11289 9.93134 3.34189 9.38784C4.67676 6.21929 7.08793 3.61124 10.1153 2.02368C10.6014 1.76875 10.8114 1.18369 10.6071 0.674252C10.3684 0.0793796 9.66792 -0.172557 9.10061 0.125817Z"
        fill="url(#paint8_linear_1284_8330)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1284_8330"
        x1="16.4114"
        y1="27.737"
        x2="22.2129"
        y2="37.4059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" />
        <stop offset="0.268" stopColor="#FFBB00" />
        <stop offset="0.659" stopColor="#FFA801" />
        <stop offset="1" stopColor="#FF9102" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1284_8330"
        x1="22.1682"
        y1="32.9869"
        x2="14.4554"
        y2="25.274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" stopOpacity="0" />
        <stop offset="0.203" stopColor="#FFBB00" stopOpacity="0.203" />
        <stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
        <stop offset="0.852" stopColor="#FF8800" stopOpacity="0.852" />
        <stop offset="1" stopColor="#FF7800" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1284_8330"
        x1="13.5641"
        y1="19.8463"
        x2="28.0452"
        y2="43.9816"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" />
        <stop offset="0.268" stopColor="#FFBB00" />
        <stop offset="0.659" stopColor="#FFA801" />
        <stop offset="1" stopColor="#FF9102" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1284_8330"
        x1="13.5189"
        y1="14.1494"
        x2="26.7305"
        y2="26.5938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" />
        <stop offset="0.268" stopColor="#FFBB00" />
        <stop offset="0.659" stopColor="#FFA801" />
        <stop offset="1" stopColor="#FF9102" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1284_8330"
        x1="22.2498"
        y1="26.2972"
        x2="0.0825382"
        y2="29.1153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" stopOpacity="0" />
        <stop offset="0.203" stopColor="#FFBB00" stopOpacity="0.203" />
        <stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
        <stop offset="0.852" stopColor="#FF8800" stopOpacity="0.852" />
        <stop offset="1" stopColor="#FF7800" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1284_8330"
        x1="20.5285"
        y1="24.1469"
        x2="16.1918"
        y2="19.8101"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" stopOpacity="0" />
        <stop offset="0.13" stopColor="#FDBB00" stopOpacity="0.13" />
        <stop offset="0.32" stopColor="#F7A701" stopOpacity="0.32" />
        <stop offset="0.547" stopColor="#EE8702" stopOpacity="0.547" />
        <stop offset="0.799" stopColor="#E25B04" stopOpacity="0.799" />
        <stop offset="1" stopColor="#D63305" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1284_8330"
        x1="15.3971"
        y1="18.198"
        x2="17.4658"
        y2="20.2661"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D63305" />
        <stop offset="0.366" stopColor="#CF3004" />
        <stop offset="0.899" stopColor="#BC2602" />
        <stop offset="1" stopColor="#B72401" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1284_8330"
        x1="6.71627"
        y1="5.77961"
        x2="9.11567"
        y2="8.179"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1ACEB8" />
        <stop offset="1" stopColor="#0BBFBA" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1284_8330"
        x1="2.5933"
        y1="1.63446"
        x2="7.20896"
        y2="6.25031"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1ACEB8" />
        <stop offset="1" stopColor="#0BBFBA" />
      </linearGradient>
      <clipPath id="clip0_1284_8330">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconQuietness;
