import { logOut } from "utils/helpers/userHelper";

const globalErrorHandler = (error, handleError) => {
  if (handleError) {
    handleError(error)
  }

  switch (error.status) {
    case 401: {
      logOut();
      break;
    }
    default: {
      break;
    }
  }
};

export default globalErrorHandler;
