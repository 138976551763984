import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SimpleButton from "components/Controls/Buttons/SimpleButton";
import { IconHeart } from "components/Svgs";
import { firebaseAuth } from "utils/firebase";
import { TOKEN_KEY } from "constants/localStorage";
import "./styles.scss";

const ICON_SIZE = {
  small: 16,
  medium: 20,
  large: 24,
};

const FavoritesButton = props => {
  const [isFavorite, setIsFavorite] = useState(props.isFavorite);
  const { houseId, housesStore, size, handleNonAuthUserClick} = props;
  const authToken = localStorage.getItem(TOKEN_KEY);

  const onFavoriteClick = () => {
    if (authToken){
    const data = {
      user_id: firebaseAuth.currentUser.uid,
      house_id: houseId,
    };
    const handleSuccess = () => setIsFavorite(!isFavorite);
    isFavorite
      ? housesStore.delHousesFavoritesRequest(data, handleSuccess)
      : housesStore.markHousesFavoriteRequest(data, handleSuccess);
  } else {
      handleNonAuthUserClick();
    }
  };

  return (
    <div className="favorites-button-container">
      <SimpleButton
        className={classNames("favorites-button", size , { active: isFavorite })}
        icon={<IconHeart width={ICON_SIZE[size]} height={ICON_SIZE[size]} />}
        onClick={onFavoriteClick}
      />
    </div>
  );
};

FavoritesButton.propTypes = {
  houseId: PropTypes.number,
  housesStore: PropTypes.any,
  isFavorite: PropTypes.bool,
  size: PropTypes.string,
  setFavoritesPopUpActive: PropTypes.func
};

FavoritesButton.defaultProps = {
  isFavorite: false,
  size: "small",  // Available values: small/medium/large
};

export default FavoritesButton;
