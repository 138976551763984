import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import _, { assign as lodashAssign, omit as lodashOmit } from 'lodash';
import { CRITERIA_DATA_KEY, TOKEN_KEY } from 'constants/localStorage';
import { LOGIN_PATH, ONBOARDING_PATH } from 'constants/paths';
import { inject, observer } from "mobx-react";
import { logOut } from "utils/helpers/userHelper";
// import { firebaseCheckAndUpdateIdToken } from "utils/firebase";

const ProtectedRoute = observer(({ onboardingStore, ...props }) => {
  // firebaseCheckAndUpdateIdToken();

  const [isFinished, setOnboardingIsFinished] = useState(null);
  const [isValidToken, setIsValidToken] = useState(!_.isNull(localStorage.getItem(TOKEN_KEY)));

  useEffect(() => {
    const handleSuccess = responseData => {
      setOnboardingIsFinished(responseData.is_finished);
    };
    const handleError = errors => {
      const errorStatus = errors.response.status;
      if (errorStatus === 401) {
        setIsValidToken(false);
      }
      if (errorStatus === 404) {
        setOnboardingIsFinished(false);
      }
    };
    if (isValidToken) {
      onboardingStore.getOnboardingDataRequest(handleSuccess, handleError);
    }
  }, []);

  const authToken = localStorage.getItem(TOKEN_KEY);
  let selfProps = props;
  selfProps = lodashOmit(selfProps, 'component');

  const navigateToComponent = selfProps => {
    const { component: ComponentToRender } = props;
    return <ComponentToRender {...lodashAssign(props, selfProps)} />;
  };

  const redirectToComponent = (authToken, selfProps) => {
    if (_.isNull(localStorage.getItem(TOKEN_KEY))) {
      if (localStorage.getItem(CRITERIA_DATA_KEY)) {
        return navigateToComponent(selfProps);
      }
      return redirectToOnboarding();
    }
    if (!isValidToken) {
      logOut();
      return redirectToLogin();
    }
    if (_.isNull(isFinished)) {
      return null;
    }
    const isOnboardingFinished = onboardingStore.retrieveIsOnboardingFinished();
    const isNavigateToComponent = isOnboardingFinished || selfProps.path === ONBOARDING_PATH;
    return isNavigateToComponent ? navigateToComponent(selfProps) : redirectToOnboarding();
  };

  const redirectToLogin = () => <Redirect to={LOGIN_PATH} />;

  const redirectToOnboarding = () => <Redirect to={ONBOARDING_PATH} />;

  return (
    <Route
      {...selfProps}
      render={() => redirectToComponent(authToken, selfProps)}
    />
  );
});

export default inject("onboardingStore")(ProtectedRoute);
