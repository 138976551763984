import React from 'react';
import BaseLayout from "components/Layouts/BaseLayout";
import HelmetIntl from 'components/Meta/HelmetIntl';
import Profile from 'containers/Profile';

const ProfilePage = () => (
  <BaseLayout>
    <HelmetIntl id="helmet.profileTitle" />
    <Profile />
  </BaseLayout>
);

export default ProfilePage;
