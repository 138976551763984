import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { TOKEN_KEY } from "constants/localStorage";
import { HOUSE_PATH, NOT_FOUND_PATH, ONBOARDING_PATH, TOP_MATCHES } from "constants/paths";

const AnonymousRoute = ({ component: Component, ...rest }) => {
  if (localStorage.getItem(TOKEN_KEY) && ![HOUSE_PATH, NOT_FOUND_PATH, ONBOARDING_PATH].includes(rest.path)) {
    return <Redirect to={TOP_MATCHES} />;
  }
  return <Route {...rest} render={props => <Component {...props} />}/>
};

AnonymousRoute.propTypes = {
  component: PropTypes.func,
};

export default AnonymousRoute;
