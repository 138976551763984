import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Tag, Tooltip, AutoComplete, Input } from "antd";
import PropTypes from "prop-types";
import _, {
  map as lodashMap,
  cloneDeep as lodashCloneDeep,
  remove as lodashRemove,
} from "lodash";
import {
  getLocationFromQuery,
  getNoContentInfo,
  handleNewLocationSelect,
  handleQueryTextChange,
  MIN_SYMBOLS_FOR_GEO_SEARCH,
  updateLocationsValue
} from "utils/helpers/locationSearchHelper";
import "../styles.scss";
import "./styles.scss";

const SearchLocationField = ({ value = [], form, maxItemsNumber, queryParamGid, onValuesChange }) => {
  const [locations, setLocations] = useState( []);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setLocations(value);
    if (queryParamGid && !_.isNil(form) && _.isEmpty(value)) {
      getLocationFromQuery(queryParamGid, form, setLocations, onValuesChange);
    }
  }, []);

  const closeTag = (e, address) => {
    e.preventDefault();
    const newLocations = lodashCloneDeep(locations) || [];
    lodashRemove(newLocations, (item) => item.address === address);
    updateLocationsValue(form, newLocations, setLocations, onValuesChange);
  };

  const handleSelect = (_, location) => {
    setQuery('');
    handleNewLocationSelect(form, locations, location, setLocations, onValuesChange);
  };

  const renderTags = () => (
    <div>
      {lodashMap(locations, (item) => (
        <Tooltip placement="bottomLeft" title={item.address} className="location-tag-tooltip">
          <Tag
            className="location-tag"
            closable
            onClose={(e) => closeTag(e, item.address)}
          >
            {item.address}
          </Tag>
        </Tooltip>
        ))}
    </div>
  );

  const handleChange = (value) => {
    setQuery(value);
  }

  const handleSearch = (query) => {
    if (query && query.length > MIN_SYMBOLS_FOR_GEO_SEARCH) {
      setIsLoading(true);
      handleQueryTextChange(query, setOptions, setIsLoading);
    }

    setOptions([]);
  }

  return (
    <div className="location-search location-search-custom">
      <div>
        <div className="location-search__input">
          <span className="location-search__label">
            <FormattedMessage id="dashboard.form.enterNeighborhoodMessage" />
          </span>
          <AutoComplete
            dropdownClassName="location-search-custom"
            disabled={locations.length >= maxItemsNumber}
            options={options}
            value={query}
            onChange={handleChange}
            onSelect={handleSelect}
            onSearch={handleSearch}
            notFoundContent={getNoContentInfo(isLoading, query.length)}
          >
            <Input />
          </AutoComplete>
          <div className="location-search__tags">{renderTags()}</div>
        </div>
      </div>
    </div>
  );
};

SearchLocationField.propTypes = {
  form: PropTypes.any,
  maxItemsNumber: PropTypes.number,
  value: PropTypes.array,
  queryParamGid: PropTypes.string,
  onValuesChange: PropTypes.func,
};

export default SearchLocationField;
