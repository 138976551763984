import React from "react";

const IconLowTaxes = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="30" height="30" fill="#E5E5E5" />
    <path
      d="M27.8677 30H6.29813C5.2938 30 4.47989 29.1859 4.47989 28.1818V1.81824C4.47989 0.814133 5.2938 0 6.29813 0H27.8677C28.8721 0 29.686 0.814133 29.686 1.81824V28.1818C29.686 29.1859 28.8721 30 27.8677 30Z"
      fill="url(#paint0_linear_0_1)"
    />
    <path
      d="M6.29813 30H27.8677C28.8721 30 29.686 29.1859 29.686 28.1818V26.293L4.5893 1.19613C4.51857 1.39023 4.47989 1.59965 4.47989 1.81823V28.1818C4.47989 29.1859 5.2938 30 6.29813 30V30Z"
      fill="url(#paint1_linear_0_1)"
    />
    <path
      d="M6.29813 30H27.8677C28.8721 30 29.686 29.1859 29.686 28.1818V26.302L26.2262 22.8424C25.8936 22.4764 25.4139 22.2469 24.8806 22.2469H4.47989V28.1818C4.47989 29.1859 5.2938 30 6.29813 30V30Z"
      fill="url(#paint2_linear_0_1)"
    />
    <path
      d="M3.04917 27.1044C2.84615 27.1044 2.65389 27.1497 2.48155 27.2305L2.87957 27.8531C2.95899 27.9769 3.13935 27.9769 3.21877 27.8531L3.61657 27.2305C3.44445 27.1497 3.25219 27.1044 3.04917 27.1044Z"
      fill="#A1B5D8"
    />
    <path
      d="M2.50117 27.3193C2.20866 26.7641 1.95597 26.1447 2.07545 25.5926H4.03101C4.13172 26.1779 3.88728 26.7744 3.60003 27.3187C3.36749 27.7595 2.73371 27.7604 2.50117 27.3193V27.3193Z"
      fill="url(#paint3_linear_0_1)"
    />
    <path
      d="M4.03044 25.5926C3.37858 25.5926 2.72673 25.5926 2.07487 25.5926C2.041 25.7494 2.03779 25.9119 2.05633 26.0767L3.46762 27.488C3.51866 27.4413 3.56398 27.3857 3.59945 27.3186C3.8867 26.7744 4.13115 26.1779 4.03044 25.5926Z"
      fill="url(#paint4_linear_0_1)"
    />
    <path
      d="M4.23521 0.998384C3.94064 -0.251997 2.15834 -0.251997 1.86354 0.998384C1.19909 3.81729 0.502833 8.16994 0.502833 14.0327C0.502833 19.3181 1.06863 22.9784 1.66693 25.3711C2.02719 26.8121 4.07156 26.8121 4.43182 25.3711C5.03012 22.9784 5.59591 19.3181 5.59591 14.0327C5.59591 8.16994 4.89965 3.81729 4.23521 0.998384Z"
      fill="url(#paint5_linear_0_1)"
    />
    <path
      d="M0.503322 13.9178L5.42337 18.8379C5.53163 17.4115 5.59617 15.8148 5.59617 14.0327C5.59617 13.8528 5.59548 13.6745 5.59411 13.4974H0.504924C0.503779 13.6363 0.503551 13.7771 0.503322 13.9178V13.9178Z"
      fill="url(#paint6_linear_0_1)"
    />
    <path
      d="M5.42656 13.9529H0.672455C0.462799 13.9529 0.292969 13.783 0.292969 13.5734V13.3044C0.292969 13.095 0.462799 12.925 0.672455 12.925H5.42656C5.63599 12.925 5.80582 13.095 5.80582 13.3044V13.5734C5.80582 13.783 5.63599 13.9529 5.42656 13.9529Z"
      fill="url(#paint7_linear_0_1)"
    />
    <path
      d="M4.71491 3.3252L3.3906 2.00089C3.30843 1.90407 3.18598 1.84296 3.04957 1.84296C2.80901 1.84296 2.61149 2.03316 2.6021 2.27372L2.36315 8.52425C2.35766 8.66822 2.42037 8.79822 2.52176 8.88428L5.56567 11.9282C5.46359 8.45124 5.11843 5.58197 4.71491 3.3252Z"
      fill="url(#paint8_linear_0_1)"
    />
    <path
      d="M1.58868 2.25426C1.53443 2.52068 1.48065 2.79648 1.42777 3.08327L5.22195 6.87744C5.02671 5.09239 4.77403 3.5511 4.51035 2.25426H1.58868Z"
      fill="url(#paint9_linear_0_1)"
    />
    <path
      d="M4.61494 3.0613H1.48384C1.36803 3.0613 1.27419 2.96745 1.27419 2.85164V2.46414C1.27419 2.34833 1.36803 2.25449 1.48384 2.25449H4.61494C4.73076 2.25449 4.82483 2.34833 4.82483 2.46414V2.85164C4.82483 2.96745 4.73076 3.0613 4.61494 3.0613Z"
      fill="url(#paint10_linear_0_1)"
    />
    <path
      d="M3.03639 3.0613H4.28448L3.4969 2.27371L3.44723 2.25426H3.03639V3.0613Z"
      fill="url(#paint11_linear_0_1)"
    />
    <path
      d="M3.28703 8.99094H2.81233C2.55735 8.99094 2.35365 8.77922 2.36326 8.52425L2.60221 2.27372C2.61137 2.03316 2.80912 1.84296 3.04968 1.84296C3.29023 1.84296 3.48798 2.03316 3.49714 2.27372L3.73609 8.52425C3.74593 8.77922 3.542 8.99094 3.28703 8.99094Z"
      fill="url(#paint12_linear_0_1)"
    />
    <path
      d="M3.04943 8.40271C2.84389 8.40271 2.67727 8.23608 2.67727 8.03055V6.36978C2.67727 6.16425 2.84389 5.99762 3.04943 5.99762C3.25496 5.99762 3.42159 6.16425 3.42159 6.36978V8.03055C3.42159 8.23608 3.25496 8.40271 3.04943 8.40271Z"
      fill="url(#paint13_linear_0_1)"
    />
    <path
      d="M24.8806 22.2469H3.31099C2.30666 22.2469 1.49252 23.0608 1.49252 24.0651V26.4617C1.49252 28.4159 3.07662 30 5.03081 30H28.0488C27.3033 30 26.6988 29.3955 26.6988 28.6501V24.0651C26.6988 23.0608 25.8847 22.2469 24.8806 22.2469Z"
      fill="url(#paint14_linear_0_1)"
    />
    <path
      d="M7.43982 14.0419H26.7263V15.0961H7.43982V14.0419Z"
      fill="#A1B5D8"
    />
    <path
      d="M7.43982 18.1938H22.7976V19.2483H7.43982V18.1938Z"
      fill="#A1B5D8"
    />
    <path
      d="M7.53024 3.50876C7.53024 3.34786 7.56778 3.20595 7.64331 3.08327C7.71861 2.96059 7.82756 2.89902 7.97015 2.89902H13.1864C13.3287 2.89902 13.4379 2.9583 13.5132 3.07686C13.5887 3.19542 13.6265 3.33504 13.6265 3.49594C13.6265 3.67378 13.5865 3.82416 13.507 3.94684C13.4272 4.06952 13.3205 4.13086 13.1864 4.13086H11.2507V11.6483C11.2507 11.7822 11.1837 11.8888 11.0498 11.9682C10.9156 12.0477 10.7605 12.0875 10.5844 12.0875C10.4002 12.0875 10.2429 12.0477 10.1132 11.9682C9.98317 11.8888 9.9184 11.7822 9.9184 11.6483V4.13086H7.97015C7.83603 4.13086 7.72914 4.06952 7.64949 3.94684C7.56984 3.82416 7.53024 3.6779 7.53024 3.50876Z"
      fill="url(#paint15_linear_0_1)"
    />
    <path
      d="M13.2658 11.5352C13.2658 11.4853 13.2699 11.4475 13.2784 11.4224L15.7743 3.27576C15.881 2.97432 16.179 2.82349 16.6679 2.82349C17.1657 2.82349 17.4681 2.96402 17.5749 3.24486L20.0638 11.3899C20.0805 11.4425 20.0888 11.4821 20.0888 11.5084C20.0888 11.6663 19.991 11.8025 19.7958 11.9163C19.6003 12.0305 19.3934 12.0873 19.1746 12.0873C18.91 12.0873 18.7459 11.9962 18.682 11.8137L18.1616 10.0134H15.0719L14.5576 11.8089C14.4992 11.9946 14.3445 12.0873 14.0936 12.0873C13.8929 12.0873 13.7048 12.033 13.5292 11.9243C13.3534 11.8156 13.2658 11.6858 13.2658 11.5352V11.5352ZM15.3742 8.95752H17.8693L16.6093 4.64356L15.3742 8.95752Z"
      fill="url(#paint16_linear_0_1)"
    />
    <path
      d="M20.3762 11.4713C20.3762 11.396 20.4012 11.3081 20.4515 11.2074L22.6261 7.48696L20.5145 3.79143C20.4472 3.67424 20.4138 3.56507 20.4138 3.46459C20.4138 3.29705 20.5019 3.15468 20.6779 3.03726C20.8539 2.91985 21.0464 2.86125 21.2561 2.86125C21.457 2.86125 21.6246 2.98691 21.7587 3.23845L23.4934 6.45607L25.253 3.23845C25.3702 2.98691 25.5378 2.86125 25.7559 2.86125C25.9568 2.86125 26.1454 2.91985 26.3214 3.03726C26.4975 3.15468 26.5853 3.29705 26.5853 3.46459C26.5853 3.56507 26.5519 3.67424 26.4849 3.79143L24.3732 7.48696L26.5478 11.2074C26.6062 11.3248 26.6357 11.413 26.6357 11.4713C26.6357 11.6473 26.5414 11.8005 26.3528 11.9302C26.1642 12.0602 25.9694 12.125 25.7685 12.125C25.5925 12.125 25.4542 12.0245 25.3535 11.8234L23.4934 8.47985L21.6582 11.8234C21.5406 12.0245 21.3982 12.125 21.2307 12.125C21.0379 12.125 20.8473 12.0602 20.6589 11.9302C20.4703 11.8005 20.3762 11.6473 20.3762 11.4713V11.4713Z"
      fill="url(#paint17_linear_0_1)"
    />
    <path
      d="M9.91856 6.52588V11.6483C9.91856 11.7824 9.98334 11.8886 10.1131 11.968C10.2429 12.0474 10.4004 12.0875 10.5848 12.0875C10.7604 12.0875 10.9153 12.0474 11.0495 11.968C11.1836 11.8886 11.2511 11.7824 11.2511 11.6483V7.8582L9.91856 6.52588Z"
      fill="url(#paint18_linear_0_1)"
    />
    <path
      d="M13.6386 10.2459L13.2784 11.4219C13.2699 11.4475 13.2655 11.4848 13.2655 11.5352C13.2655 11.6858 13.3532 11.8156 13.5294 11.9243C13.7048 12.033 13.8929 12.0875 14.0939 12.0875C14.3443 12.0875 14.4994 11.9948 14.5578 11.8092L14.7015 11.3088L13.6386 10.2459Z"
      fill="url(#paint19_linear_0_1)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="8.83787"
        y1="2.60581"
        x2="31.9421"
        y2="37.3367"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFFCF8" />
        <stop offset="0.1875" stopColor="#FAF8F6" />
        <stop offset="0.4041" stopColor="#ECEEF2" />
        <stop offset="0.6353" stopColor="#D5DCEA" />
        <stop offset="0.875" stopColor="#B5C4DF" />
        <stop offset="1" stopColor="#A1B5D8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_0_1"
        x1="14.1547"
        y1="15.5981"
        x2="-6.29877"
        y2="15.5981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A1B5D8" stopOpacity="0" />
        <stop offset="0.0343" stopColor="#9FB3D5" stopOpacity="0.035294" />
        <stop offset="0.4515" stopColor="#8898AF" stopOpacity="0.45098" />
        <stop offset="0.788" stopColor="#798898" stopOpacity="0.788235" />
        <stop offset="1" stopColor="#748290" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_0_1"
        x1="23.9545"
        y1="34.4885"
        x2="15.8349"
        y2="26.3689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A1B5D8" stopOpacity="0" />
        <stop offset="0.0343" stopColor="#9FB3D5" stopOpacity="0.035294" />
        <stop offset="0.4515" stopColor="#8898AF" stopOpacity="0.45098" />
        <stop offset="0.788" stopColor="#798898" stopOpacity="0.788235" />
        <stop offset="1" stopColor="#748290" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_0_1"
        x1="2.59848"
        y1="26.621"
        x2="4.33113"
        y2="26.621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE548" />
        <stop offset="1" stopColor="#FFB211" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_0_1"
        x1="4.25917"
        y1="27.0485"
        x2="2.34242"
        y2="25.1318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB211" stopOpacity="0" />
        <stop offset="0.2281" stopColor="#FF9F19" stopOpacity="0.227451" />
        <stop offset="0.6863" stopColor="#FF6F2E" stopOpacity="0.686275" />
        <stop offset="1" stopColor="#FF4B3E" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_0_1"
        x1="1.90287"
        y1="13.256"
        x2="6.29558"
        y2="13.256"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3B395F" />
        <stop offset="0.0633" stopColor="#37355A" />
        <stop offset="0.4033" stopColor="#232543" />
        <stop offset="0.7232" stopColor="#171B35" />
        <stop offset="1" stopColor="#131830" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_0_1"
        x1="4.33834"
        y1="17.0353"
        x2="0.450035"
        y2="6.98731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#131830" stopOpacity="0" />
        <stop offset="1" stopColor="#131830" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_0_1"
        x1="3.05127"
        y1="13.2869"
        x2="3.05127"
        y2="14.165"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC738" />
        <stop offset="0.4286" stopColor="#FFBB23" />
        <stop offset="1" stopColor="#FFA700" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_0_1"
        x1="6.05367"
        y1="7.08508"
        x2="0.511289"
        y2="6.44558"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#131830" stopOpacity="0" />
        <stop offset="1" stopColor="#131830" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_0_1"
        x1="4.0581"
        y1="5.47801"
        x2="0.821885"
        y2="-2.8849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#131830" stopOpacity="0" />
        <stop offset="1" stopColor="#131830" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_0_1"
        x1="2.98545"
        y1="2.15381"
        x2="3.11318"
        y2="3.16004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC738" />
        <stop offset="0.4286" stopColor="#FFBB23" />
        <stop offset="1" stopColor="#FFA700" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_0_1"
        x1="4.10089"
        y1="3.13697"
        x2="3.15866"
        y2="2.60497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB211" stopOpacity="0" />
        <stop offset="0.2281" stopColor="#FF9F19" stopOpacity="0.227451" />
        <stop offset="0.6863" stopColor="#FF6F2E" stopOpacity="0.686275" />
        <stop offset="1" stopColor="#FF4B3E" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_0_1"
        x1="2.20299"
        y1="5.41647"
        x2="3.79203"
        y2="5.41647"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC738" />
        <stop offset="0.4286" stopColor="#FFBB23" />
        <stop offset="1" stopColor="#FFA700" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_0_1"
        x1="3.64634"
        y1="7.19964"
        x2="1.96624"
        y2="7.19964"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC738" />
        <stop offset="0.4286" stopColor="#FFBB23" />
        <stop offset="1" stopColor="#FFA700" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_0_1"
        x1="14.7707"
        y1="26.3296"
        x2="14.7707"
        y2="31.6746"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFFCF8" />
        <stop offset="0.1875" stopColor="#FAF8F6" />
        <stop offset="0.4041" stopColor="#ECEEF2" />
        <stop offset="0.6353" stopColor="#D5DCEA" />
        <stop offset="0.875" stopColor="#B5C4DF" />
        <stop offset="1" stopColor="#A1B5D8" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_0_1"
        x1="10.5783"
        y1="5.23831"
        x2="10.5783"
        y2="14.1773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4974" />
        <stop offset="0.3262" stopColor="#FE4672" />
        <stop offset="0.5324" stopColor="#FB3E6C" />
        <stop offset="0.7054" stopColor="#F52F60" />
        <stop offset="0.8591" stopColor="#EE1A51" />
        <stop offset="1" stopColor="#E4003D" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_0_1"
        x1="16.6773"
        y1="5.23831"
        x2="16.6773"
        y2="14.1773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4974" />
        <stop offset="0.3262" stopColor="#FE4672" />
        <stop offset="0.5324" stopColor="#FB3E6C" />
        <stop offset="0.7054" stopColor="#F52F60" />
        <stop offset="0.8591" stopColor="#EE1A51" />
        <stop offset="1" stopColor="#E4003D" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_0_1"
        x1="23.506"
        y1="5.23831"
        x2="23.506"
        y2="14.1773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4974" />
        <stop offset="0.3262" stopColor="#FE4672" />
        <stop offset="0.5324" stopColor="#FB3E6C" />
        <stop offset="0.7054" stopColor="#F52F60" />
        <stop offset="0.8591" stopColor="#EE1A51" />
        <stop offset="1" stopColor="#E4003D" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_0_1"
        x1="16.7623"
        y1="16.0038"
        x2="4.82264"
        y2="4.06413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4003D" stopOpacity="0" />
        <stop offset="0.5838" stopColor="#BD294B" stopOpacity="0.584314" />
        <stop offset="1" stopColor="#A64153" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_0_1"
        x1="17.6247"
        y1="15.1414"
        x2="5.68507"
        y2="3.20175"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4003D" stopOpacity="0" />
        <stop offset="0.5838" stopColor="#BD294B" stopOpacity="0.584314" />
        <stop offset="1" stopColor="#A64153" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconLowTaxes;
