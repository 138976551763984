import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { SimpleButton } from "components/Controls/Buttons";

import "./styles.scss";

const RemoteSubmitButtonView = ({ formName }) => (
  <div className="remote-submit-button-wrapper">
    <div className="btn-default-position">
      <Form.Item>
        <SimpleButton htmlType="submit" customClass="btn-default" form={formName}>
          <FormattedMessage id="general.saveAndClose" />
        </SimpleButton>
      </Form.Item>
    </div>
  </div>
);

RemoteSubmitButtonView.propTypes = {
  formName: PropTypes.string,
};

export default RemoteSubmitButtonView;
