import React, { useEffect, useState } from "react";
import { Form, Input, Tooltip } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl }  from "react-intl";
import classNames from "classnames";
import { SimpleButton } from "components/Controls/Buttons";
import { MaskedInput } from "components/Controls/Inputs";
import Toggle from "components/Controls/Toggle";
import { PHONE_MASK, PHONE_MASK_PLACEHOLDER_CHAR } from "components/Forms/constants";
import { USER_KEY } from "constants/localStorage";
import { withPassword } from "hocs/withPassword";
import { firebaseAuth } from 'utils/firebase';
import { getPasswordIcon, normalizedPhone } from "utils/helpers/userHelper";
import { localStorageStrToObject } from "utils/helpers/common.js";
import {
  IconDoubleCheck,
  IconArrowRight
} from "components/Svgs";
import "../../styles.scss";

const GOOGLE_PROVIDER_ID = "google.com";

const ProfileForm = (
  {
    intl,
    isError,
    passwordLength,
    passwordLetter,
    passwordsMatch,
    handleProfileSubmit,
    handleReAuthenticate,
    observeConfirmPasswordField,
    renderValidations,
    validatePassword,
    validateConfirmPassword,
  }
) => {
  const [emailActive, setEmailActive] = useState(false);
  const [passwordActive, setPasswordActive] = useState(false);
  const [currentPasswordActive, setCurrentPasswordActive] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [validNumberEntered, setValidNumberEntered] = useState(true);
  const [profileNamesError, setProfileNamesError] = useState({ firstName: false, lastName: false });
  const [form] = Form.useForm();
  const userFirebase = firebaseAuth.currentUser;

  useEffect(() => {
    observeConfirmPasswordField("ProfileForm_password", "ProfileForm_confirm_password");
    const user = localStorageStrToObject(USER_KEY);

    if (_.isEmpty(user.phone)) {
      setValidNumberEntered(false);
    }
  }, [userFirebase, passwordActive]);

  const getFLErrors = () => profileNamesError.firstName || profileNamesError.lastName;

  const getInitialValues = () => {
    const user = localStorageStrToObject(USER_KEY);

    if (userFirebase) {
      return {
        firstName: user.first_name,
        lastName: user.last_name,
        phone: user.phone,
        notifyingAllowed: !validNumberEntered ? false : user.notifying_allowed,
        email: userFirebase.email,
      };
    }
  };

  const handleFormValuesChange = (value) => {
    const keyName = Object.keys(value)[0];
    if (["firstName", "lastName"].includes(keyName)) {
      const fieldValueLength = _.isString(value[keyName]) ? value[keyName].trim().length : 0;
      setProfileNamesError({...profileNamesError, [keyName]: fieldValueLength === 0 || fieldValueLength > 50  });
    }
  };

  const onClickCheckCurrentPassword = () => {
    if (currentPassword) {
      const handleSuccess = () => {
        setPasswordActive(true);
        setCurrentPasswordActive(true);
      };
      handleReAuthenticate(currentPassword, handleSuccess);
    }
  };

  const onFinish = formValues => {
    if (emailActive || passwordActive) {
      formValues.currentPassword = currentPassword;
    }
    formValues.phone = normalizedPhone(formValues.phone);
    handleProfileSubmit(formValues);
  };

  const validateSubmit = () => {
    const emailError = form.getFieldError("email");
    return _.isEmpty(emailError);
  };

  const passwordValidateSubmit = () => !(passwordActive && (!passwordLength || !passwordLetter || !passwordsMatch));

  const phoneNumberChanged = e => {
    // if only number entered in mask input (full phone number entered) then save it else disable notifications toggle
    if (!e.maskedValue.includes("X")) {
      setValidNumberEntered(true);
    } else {
      setValidNumberEntered(false);
      form.setFieldsValue({ notifyingAllowed: false });
    }
    return e;
  };

  const renderCurrentPassword = () => (
    <div className="form-input">
      <div className="form-input__message">
        <FormattedMessage id={currentPasswordActive ? "profile.form.oldPassword" : "profile.form.password"} />
      </div>
      <div className="input-with-button">
        <Form.Item
          name="currentPassword"
          rules={[
            {
              validator: (_, value) => {
                const isValidated = currentPasswordActive && validatePassword(value);
                isValidated && setCurrentPassword(value);
                return Promise.resolve("");
              },
            },
          ]}
        >
          <Input.Password
            disabled={!currentPasswordActive}
            className="simple-input"
            placeholder={intl.formatMessage({ id: "profile.form.currentPassword" })}
            iconRender={(visible) => getPasswordIcon(visible)}
          />
        </Form.Item>
          <div className="input-with-button__button">
            <SimpleButton
              customClass="btn-default"
              type="button"
              onClick={onClickCheckCurrentPassword}
            >
              <IconArrowRight />
            </SimpleButton>
          </div>
      </div>
    </div>
  );

  const renderPasswords = () => (
    <>
      <div className="form-input">
        <div className="form-input__message">
          <FormattedMessage id="profile.form.newPassword" />
        </div>
        <div className="input-with-button">
          <Form.Item
            name="password"
            dependencies={["confirm_password"]}
            rules={[
              {
                validator: (_, value) => {
                  passwordActive && validatePassword(value);
                  return Promise.resolve("");
                },
              },
            ]}
          >
            <Input.Password
              disabled={!passwordActive}
              className="simple-input"
              placeholder={intl.formatMessage({ id: "profile.form.password" })}
              iconRender={(visible) => getPasswordIcon(visible)}
            />
          </Form.Item>
          {!passwordActive && (
            <div className="input-with-button__button">
              <SimpleButton
                customClass="btn-default"
                type="button"
                onClick={() => {
                  setEmailActive(false);
                  setPasswordActive(true);
                }}
              >
                <FormattedMessage id="profile.form.change" />
              </SimpleButton>
            </div>
          )}
        </div>
      </div>

      <div className={classNames("form-input", {invisible: !passwordActive})}>
        <div className="form-input__message">
          <FormattedMessage id="profile.form.confirmPassword" />
        </div>
        <Form.Item
          name="confirm_password"
          className="form-item"
          dependencies={["password"]}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                passwordActive && validateConfirmPassword(getFieldValue("password"), value);
                return Promise.resolve("");
              },
            }),
          ]}
        >
          <Input.Password
            className="simple-input"
            placeholder={intl.formatMessage({ id: "profile.form.confirmPassword" })}
            iconRender={() =>
              passwordsMatch ? <IconDoubleCheck /> : null
            }
          />
        </Form.Item>
      </div>
      <SimpleButton
        customClass="save_password"
        htmlType="submit"
        disabled={!passwordValidateSubmit()}
      >
        <FormattedMessage id="profile.savePassword" />
      </SimpleButton>
    </>
  );


  const renderPasswordSection = () => {
    if (!passwordActive) {
      return renderCurrentPassword();
    }
    if (passwordActive && currentPassword) {
      return renderPasswords();
    }
  };

  const renderEmailCurrentPassword = () => (
    <div className="form-input">
      <div className="form-input__message">
        <FormattedMessage id="profile.form.password" />
      </div>
      <div className="input-with-button">
        <Form.Item
          name="emailCurrentPassword"
          rules={[
            {
              validator: (_, value) => {
                const isValidate = validatePassword(value);
                if (isValidate) {
                  setCurrentPassword(value);
                  return Promise.resolve("");
                } else {
                  return Promise.reject("");
                }
              },
            },
          ]}
        >
          <Input.Password
            className="simple-input"
            placeholder={intl.formatMessage({id: "profile.form.currentPassword"})}
            iconRender={(visible) => getPasswordIcon(visible)}
          />
        </Form.Item>
      </div>
    </div>
  );

  const renderEmailField = () => (
    <>
      <div className="form-input">
        <div className="form-input__message">
          <FormattedMessage id="profile.form.email" />
        </div>
        <div className="input-with-button">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: intl.formatMessage({id: "signUp.form.invalidEmail"}),
              },
            ]}
          >
            <Input
              disabled={!emailActive}
              className="simple-input"
              placeholder={intl.formatMessage({id: "profile.form.email"})}
            />
          </Form.Item>
          {!emailActive && (
            <div className="input-with-button__button">
              <SimpleButton
                customClass="btn-default"
                type="button"
                onClick={() => {
                  setEmailActive(true);
                  setPasswordActive(false);
                  setCurrentPasswordActive(false);
                }}
              >
                <FormattedMessage id="profile.form.change" />
              </SimpleButton>
            </div>
          )}
        </div>
      </div>
      {emailActive && renderEmailCurrentPassword()}
    </>
  );

  const renderNotifyingAllowed = () => {
    const isDisabled = !validNumberEntered;
    return (
      <div className={classNames("form-input", {"disabled": isDisabled})}>
        <div className="input-horizontal__control">
          <Tooltip
            overlayClassName={classNames({"hidden-tooltip": !isDisabled})}
            placement="bottomLeft"
            title={<FormattedMessage id="profile.form.enterPhone" />}
          >
            <Form.Item name="notifyingAllowed" valuePropName="checked">
              <Toggle disabled={isDisabled} />
            </Form.Item>
          </Tooltip>
        </div>
        <div className="input-horizontal__text">
          <div className="form-input__message">
            <FormattedMessage id="profile.form.notifyingAllowed" />
          </div>
        </div>
      </div>
    )
  };

  const renderBottomFields = () => {
    if (userFirebase) {
      const providerData = _.get(userFirebase, "providerData[0].providerId")
      if (providerData !== GOOGLE_PROVIDER_ID) {
        return (
          <>
            <div className="profile-form-columns__email">
              {renderEmailField()}
              {emailActive && !passwordActive && renderValidations()}
            </div>
            <div className={classNames("profile-form-columns__password", { is_confirm_opened: !passwordActive })}>
              <span className='password-message'>
              {!currentPasswordActive && (<FormattedMessage id="profile.form.password"/>)}
              </span>
              {!currentPasswordActive &&(
                <SimpleButton
                  customClass="btn-default change-password-btn"
                  type="button"
                  onClick={() => {
                    setCurrentPasswordActive(true);
                    setEmailActive(false);
                  }}
                >
                  <FormattedMessage id="profile.form.change.password" />
              </SimpleButton>)}
              {currentPasswordActive && renderPasswordSection()}
              {(passwordActive || currentPasswordActive) && renderValidations()}
            </div>
            <div className="profile-form-columns__phone">
              <div className="form-input">
                <div className="form-input__message">
                  <FormattedMessage id="profile.form.phoneNumber" />
                </div>
                <Form.Item name="phone">
                  <MaskedInput
                    onChange={phoneNumberChanged}
                    maskOptions={{
                     placeholderChar: PHONE_MASK_PLACEHOLDER_CHAR,
                     mask: PHONE_MASK
                   }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="profile-form-columns__notifying_allowed">
              {renderNotifyingAllowed()}
            </div>
          </>
        )
      }
    }
  };

  return (
    <Form
      form={form}
      initialValues={getInitialValues()}
      name="ProfileForm"
      onFinish={onFinish}
      onValuesChange={handleFormValuesChange}
    >
      <div className="profile-form-columns">
        <div className="form-input">
          <div className="form-input__message">
            <FormattedMessage id="profile.form.firstName" />
          </div>
          <Form.Item
            name="firstName"
            rules={[
              { max: 50, message: intl.formatMessage({ id: "profile.form.longFirstName" })}
            ]}
          >
            <Input
              className="simple-input"
              placeholder={intl.formatMessage({ id: "profile.form.firstName" })}
            />
          </Form.Item>
        </div>
        <div className="form-input">
          <div className="form-input__message">
            <FormattedMessage id="profile.form.lastName" />
          </div>
          <Form.Item
            name="lastName"
            rules={[
              { max: 50, message: intl.formatMessage({ id: "profile.form.longLastName" })}
            ]}
          >
            <Input
              className="simple-input"
              placeholder={intl.formatMessage({ id: "profile.form.lastName" })}
            />
          </Form.Item>
        </div>
        {renderBottomFields()}
      </div>
      <SimpleButton
        customClass={classNames("profile-button", { "with-padding": isError })}
        disabled={!validateSubmit() || currentPasswordActive || getFLErrors()}
        form="ProfileForm"
        htmlType="submit"
      >
        <FormattedMessage id="profile.saveProfile" />
      </SimpleButton>
    </Form>
  );
};

ProfileForm.propTypes = {
  intl: PropTypes.any,
  isError: PropTypes.bool,
  passwordLength: PropTypes.string,
  passwordLetter: PropTypes.string,
  passwordsMatch: PropTypes.bool,
  handleProfileSubmit: PropTypes.func,
  handleReAuthenticate: PropTypes.func,
  observeConfirmPasswordField: PropTypes.func,
  renderValidations: PropTypes.func,
  validateConfirmPassword: PropTypes.func,
  validatePassword: PropTypes.func,
};

export default withPassword(injectIntl(ProfileForm));
