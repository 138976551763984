import React from "react";

const HistogramColumn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
  >
    <filter id='inset-shadow'  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.584314 0 0 0 0 0.443137 0 0 0 0 0.964706 0 0 0 0.15 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_739_1313"/>
    </filter>
    <g filter="url(#inset-shadow)" >
      <rect
        width={props.width}
        height={props.height}
        x={props.x}
        y={props.y}
        rx={props.rx}
        fill={props.fill}
      />
    </g>
  </svg>
);

export default HistogramColumn;
