import React from "react";

const IconCentralAc = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_716_2647)">
      <path
        d="M31.029 8.53215V7.7799C31.029 6.70037 30.1538 5.8252 29.0743 5.8252H2.92735C1.84782 5.8252 0.972656 6.70037 0.972656 7.7799V8.53215H31.029Z"
        fill="url(#paint0_linear_716_2647)"
      />
      <path
        d="M29.9189 18.5082H2.08107C0.931702 18.5082 0 17.5765 0 16.4272V9.17091C0 8.02155 0.931702 7.08984 2.08107 7.08984H29.9189C31.0683 7.08984 32 8.02155 32 9.17091V16.4272C32 17.5765 31.0683 18.5082 29.9189 18.5082Z"
        fill="url(#paint1_linear_716_2647)"
      />
      <path
        d="M0 14.5381V16.4266C0 17.5759 0.931702 18.5076 2.08107 18.5076H29.9189C31.0683 18.5076 32 17.5759 32 16.4266V14.5381H0Z"
        fill="url(#paint2_linear_716_2647)"
      />
      <path
        d="M28.1766 18.5081H3.82422V17.6537C3.82422 17.315 4.09879 17.0405 4.43743 17.0405H27.5634C27.9021 17.0405 28.1766 17.3151 28.1766 17.6537V18.5081Z"
        fill="url(#paint3_linear_716_2647)"
      />
      <path
        d="M27.7637 19.2892H4.23714C4.03027 19.2892 3.881 19.0911 3.93803 18.8923L4.46911 17.0405H27.5317L28.0628 18.8923C28.1198 19.0911 27.9705 19.2892 27.7637 19.2892Z"
        fill="url(#paint4_linear_716_2647)"
      />
      <path
        d="M27.9712 13.1771C27.8873 13.4673 27.5841 13.6347 27.2938 13.5508C27.0035 13.4669 26.8362 13.1636 26.9201 12.8734C27.004 12.5831 27.3072 12.4158 27.5975 12.4997C27.8878 12.5836 28.0551 12.8868 27.9712 13.1771Z"
        fill="url(#paint5_linear_716_2647)"
      />
      <path
        d="M27.8836 13.1517C27.8136 13.394 27.5604 13.5336 27.3182 13.4637C27.076 13.3936 26.9364 13.1405 27.0063 12.8983C27.0764 12.6561 27.3295 12.5164 27.5717 12.5864C27.8139 12.6564 27.9535 12.9095 27.8836 13.1517Z"
        fill="url(#paint6_linear_716_2647)"
      />
      <path
        d="M27.8152 12.623C27.8302 12.7041 27.8272 12.7896 27.8028 12.8742C27.7189 13.1644 27.4156 13.3318 27.1254 13.2479C27.0408 13.2235 26.9668 13.1804 26.9062 13.1245C26.9428 13.3216 27.0867 13.4914 27.2924 13.5508C27.5827 13.6347 27.886 13.4673 27.9699 13.1771C28.0293 12.9713 27.9625 12.7591 27.8152 12.623Z"
        fill="url(#paint7_linear_716_2647)"
      />
      <path
        d="M26.7621 13.0251C26.7621 13.3272 26.5172 13.5722 26.215 13.5722C25.9129 13.5722 25.668 13.3272 25.668 13.0251C25.668 12.723 25.9129 12.478 26.215 12.478C26.5172 12.478 26.7621 12.723 26.7621 13.0251Z"
        fill="url(#paint8_linear_716_2647)"
      />
      <path
        d="M26.671 13.0254C26.671 13.2776 26.4666 13.482 26.2144 13.482C25.9622 13.482 25.7578 13.2776 25.7578 13.0254C25.7578 12.7733 25.9622 12.5688 26.2144 12.5688C26.4666 12.5689 26.671 12.7733 26.671 13.0254Z"
        fill="url(#paint9_linear_716_2647)"
      />
      <path
        d="M26.4607 12.5356C26.4976 12.6093 26.5183 12.6924 26.5183 12.7804C26.5183 13.0825 26.2734 13.3274 25.9713 13.3274C25.8833 13.3274 25.8002 13.3067 25.7266 13.2698C25.8164 13.449 26.0018 13.5721 26.2159 13.5721C26.518 13.5721 26.763 13.3272 26.763 13.0251C26.763 12.8108 26.6399 12.6255 26.4607 12.5356Z"
        fill="url(#paint10_linear_716_2647)"
      />
      <path
        d="M29.2191 13.0251C29.2191 13.3272 28.9742 13.5722 28.6721 13.5722C28.37 13.5722 28.125 13.3272 28.125 13.0251C28.125 12.723 28.37 12.478 28.6721 12.478C28.9742 12.478 29.2191 12.723 29.2191 13.0251Z"
        fill="url(#paint11_linear_716_2647)"
      />
      <path
        d="M29.1319 13.0254C29.1319 13.2776 28.9275 13.482 28.6753 13.482C28.4232 13.482 28.2188 13.2776 28.2188 13.0254C28.2188 12.7733 28.4232 12.5688 28.6753 12.5688C28.9275 12.5689 29.1319 12.7733 29.1319 13.0254Z"
        fill="url(#paint12_linear_716_2647)"
      />
      <path
        d="M28.9177 12.5356C28.9546 12.6093 28.9754 12.6924 28.9754 12.7804C28.9754 13.0825 28.7304 13.3274 28.4283 13.3274C28.3403 13.3274 28.2572 13.3067 28.1836 13.2698C28.2734 13.449 28.4588 13.5721 28.6729 13.5721C28.9751 13.5721 29.22 13.3272 29.22 13.0251C29.2201 12.8108 29.097 12.6255 28.9177 12.5356Z"
        fill="url(#paint13_linear_716_2647)"
      />
      <path
        d="M2.95216 7.9624C1.8028 7.9624 0.871094 8.8941 0.871094 10.0435V17.2997C0.871094 17.6497 0.95787 17.9793 1.11047 18.2687C1.39991 18.4213 1.72951 18.5081 2.07944 18.5081H29.9173C31.0667 18.5081 31.9984 17.5764 31.9984 16.427V9.17081C31.9984 8.82082 31.9116 8.49128 31.7589 8.20184C31.4695 8.04924 31.14 7.96247 30.79 7.96247H2.95216V7.9624Z"
        fill="url(#paint14_linear_716_2647)"
      />
      <path
        d="M19.2118 25.7612L19.2114 21.2925C19.2114 20.9649 18.9226 21.0017 18.5662 21.0017L13.4322 21.0013C13.076 21.0013 12.7872 20.9643 12.7872 21.2919L12.7876 25.7606C12.7876 26.2142 12.3879 26.5818 11.8947 26.5818C11.0991 26.5817 10.7008 27.4663 11.2634 27.9838L15.369 31.7594C15.7178 32.0802 16.2831 32.0802 16.6319 31.7595L20.7367 27.9846C21.2992 27.4673 20.9007 26.5827 20.1051 26.5826C19.6118 26.5826 19.2119 26.2148 19.2118 25.7612Z"
        fill="url(#paint15_linear_716_2647)"
      />
      <path
        d="M18.5667 21.0012C18.9229 21.0012 19.2117 20.9644 19.2118 21.292L19.2122 25.7609C19.2122 26.2144 19.612 26.5822 20.1052 26.5823C20.9007 26.5824 21.2992 27.467 20.7367 27.9844L16.6326 31.7595C16.4582 31.9199 16.2297 32 16.0012 32L16 21.0011L18.5667 21.0012Z"
        fill="url(#paint16_linear_716_2647)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_716_2647"
        x1="16.0009"
        y1="6.31404"
        x2="16.0009"
        y2="7.17196"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F7FC" />
        <stop offset="1" stopColor="#F0DDFC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_2647"
        x1="16"
        y1="7.73242"
        x2="16"
        y2="20.1242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F7FC" />
        <stop offset="1" stopColor="#F0DDFC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_2647"
        x1="16"
        y1="16.2685"
        x2="16"
        y2="11.556"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0DDFC" stopOpacity="0" />
        <stop offset="0.2889" stopColor="#C8B7E0" stopOpacity="0.289" />
        <stop offset="0.5915" stopColor="#A595C8" stopOpacity="0.592" />
        <stop offset="0.8395" stopColor="#8F81B8" stopOpacity="0.84" />
        <stop offset="1" stopColor="#8779B3" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_2647"
        x1="16.0004"
        y1="18.6895"
        x2="16.0004"
        y2="16.9753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E8595" />
        <stop offset="1" stopColor="#555A66" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_2647"
        x1="16.0004"
        y1="18.2932"
        x2="16.0004"
        y2="20.0603"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEC3D2" />
        <stop offset="0.97" stopColor="#808797" />
        <stop offset="1" stopColor="#7E8595" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_716_2647"
        x1="27.351"
        y1="12.8049"
        x2="27.835"
        y2="13.6828"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_716_2647"
        x1="27.5273"
        y1="13.166"
        x2="27.3359"
        y2="12.6122"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#FCEE21" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_716_2647"
        x1="27.4495"
        y1="13.2653"
        x2="28.5609"
        y2="12.1303"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#B53759" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_716_2647"
        x1="26.0104"
        y1="12.8204"
        x2="26.7189"
        y2="13.5289"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F92814" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_716_2647"
        x1="26.2801"
        y1="13.1185"
        x2="25.9425"
        y2="12.64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" stopOpacity="0" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_716_2647"
        x1="26.2349"
        y1="13.2347"
        x2="26.9869"
        y2="11.8362"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F92814" stopOpacity="0" />
        <stop offset="1" stopColor="#C1272D" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_716_2647"
        x1="28.4674"
        y1="12.8204"
        x2="29.1759"
        y2="13.5289"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_716_2647"
        x1="28.741"
        y1="13.1185"
        x2="28.4035"
        y2="12.64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEE83" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_716_2647"
        x1="28.692"
        y1="13.2347"
        x2="29.444"
        y2="11.8361"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" stopOpacity="0" />
        <stop offset="1" stopColor="#009245" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_716_2647"
        x1="15.0948"
        y1="10.0232"
        x2="10.7251"
        y2="-0.451207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_716_2647"
        x1="13.4493"
        y1="27.2871"
        x2="20.3155"
        y2="21.8993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_716_2647"
        x1="18.1945"
        y1="27.3949"
        x2="11.3154"
        y2="16.2472"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <clipPath id="clip0_716_2647">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCentralAc;
