import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { SimpleButton } from "components/Controls/Buttons";
import { IconArrowRight } from "components/Svgs";
import { SIGN_UP } from "constants/paths";
import "./styles.scss";

const AccountButtonView = props => {
  const { maxSize } = props;
  const history = useHistory();
  return (
    <SimpleButton
      customClass={classNames("create-account-button", { maxSize })}
      onClick={() => history.push(SIGN_UP)}
    >
      <FormattedMessage id="houses.createAnAccount" />
      <div className="icon-arrow">
        <IconArrowRight />
      </div>
    </SimpleButton>
  );
};

AccountButtonView.propTypes = {
  maxSize: PropTypes.bool,
};

export default AccountButtonView;
