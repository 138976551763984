import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { SimpleButton } from "components/Controls/Buttons";
import { trackScheduleTour } from "utils/helpers/userHelper";
import "./styles.scss";

const ScheduleTourButtonView = props => {
  const { houseDetails } = props;
  return (
    <div className="schedule-tour-button-wrapper">
      <SimpleButton
        onClick={() => trackScheduleTour(houseDetails)}
        customClass="schedule-tour-button"
      >
        <FormattedMessage id="houseDetails.scheduleATour" />
      </SimpleButton>
    </div>
  );
};

ScheduleTourButtonView.propTypes = {
  houseDetails: PropTypes.object,
};

export default ScheduleTourButtonView;
