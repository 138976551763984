import React from "react";

const IconFoot = ({fill}) => (
  <svg width="12" height="12" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6071 3.20072C9.72512 3.10089 9.09312 2.30448 9.19357 1.42387C9.29465 0.542296 10.0905 -0.0904953 10.9706 0.010611C11.852 0.111717 12.484 0.90781 12.3842 1.78874C12.2828 2.66968 11.4872 3.30247 10.6071 3.20072Z" fill={fill} />
    <path d="M5.36962 7.12356C5.39847 7.02422 5.43732 6.92349 5.49005 6.82192C5.87021 6.08129 6.26759 5.34871 6.67384 4.62112C6.24344 4.65442 5.81305 4.68771 5.38238 4.72129C5.08768 5.30375 4.79326 5.88594 4.49828 6.46867C4.02349 7.4066 2.61826 6.58161 3.09139 5.64646C3.471 4.89473 3.92109 3.20479 4.90287 3.12875C5.9468 3.04745 6.99101 2.9667 8.03523 2.88567C8.0602 2.88372 8.08184 2.88733 8.10599 2.88705C8.89879 2.67422 9.82867 3.23725 9.99045 4.04809C10.5241 5.19969 11.2916 6.09739 12.5748 6.47256C13.5815 6.76698 13.1528 8.34009 12.1416 8.04401C10.8704 7.67217 9.95743 6.94374 9.2648 5.98472C8.84551 6.73202 8.43621 7.48513 8.04383 8.24713C8.90656 8.84208 9.66051 9.56218 10.3209 10.3919C10.5052 10.6239 10.5366 10.8617 10.4747 11.0715C10.4769 11.1486 10.4722 11.2296 10.4497 11.3184C10.1955 12.3235 9.94161 13.3292 9.68715 14.3342C9.41743 15.4057 7.72193 15.0999 7.99416 14.0221C8.23613 13.065 8.47838 12.1074 8.72036 11.1489C8.11348 10.4338 7.3981 9.81109 6.58088 9.34712C6.3883 9.23723 6.26731 9.08683 6.19988 8.92061C5.0813 10.6344 3.96272 13.0045 2.84525 14.4416C2.16372 15.3169 0.799283 14.2671 1.48331 13.3888C3.1455 10.9061 5.048 7.67217 5.36962 7.12356Z" fill={fill} />
  </svg>
);

export default IconFoot;
