import React from "react";

const IconPublicTransit = (props) => (
  <svg
    width="32"
    height="26"
    viewBox="0 0 32 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.2571 2.57035H2.44531V1.76162C2.44531 1.13405 2.95411 0.625244 3.58169 0.625244H17.1207C17.7483 0.625244 18.2571 1.13405 18.2571 1.76162V2.57035Z"
      fill="url(#paint0_linear_1284_8278)"
    />
    <path
      d="M31.121 21.3614H0.277344V3.11462C0.277344 2.54446 0.739524 2.08228 1.30969 2.08228H27.0745C29.3093 2.08228 31.121 3.89398 31.121 6.12883V21.3614Z"
      fill="url(#paint1_linear_1284_8278)"
    />
    <path
      d="M28.9258 21.3615H31.1218V12.5608H30.3532C29.5649 12.5608 28.9258 13.1999 28.9258 13.9882V21.3615Z"
      fill="url(#paint2_linear_1284_8278)"
    />
    <path
      d="M2.27302 3.46265H15.4915C15.9853 3.46265 16.3856 3.86296 16.3856 4.35676V10.2862C16.3856 10.78 15.9853 11.1803 15.4915 11.1803H2.27302C1.77922 11.1803 1.37891 10.78 1.37891 10.2862V4.35676C1.37891 3.86296 1.77922 3.46265 2.27302 3.46265Z"
      fill="url(#paint3_linear_1284_8278)"
    />
    <path
      d="M30.6816 6.36461V10.2077C30.6816 10.7448 30.2461 11.1803 29.709 11.1803H24.1457C23.7762 11.1803 23.4766 10.8807 23.4766 10.5111V4.13182C23.4766 3.76225 23.7762 3.46265 24.1457 3.46265H27.7796C29.3823 3.46265 30.6816 4.76191 30.6816 6.36461Z"
      fill="url(#paint4_linear_1284_8278)"
    />
    <path
      d="M0.277344 12.6235H17.2543V14.9451H0.277344V12.6235Z"
      fill="url(#paint5_linear_1284_8278)"
    />
    <path
      d="M22.8378 21.5505V4.83255C22.8378 4.56111 22.6177 4.34106 22.3463 4.34106H17.5149C17.2435 4.34106 17.0234 4.56111 17.0234 4.83255V21.5505H22.8378Z"
      fill="url(#paint6_linear_1284_8278)"
    />
    <path
      d="M22.3447 4.34106H19.7617V21.5505H22.8362V4.83255C22.8362 4.56111 22.6162 4.34106 22.3447 4.34106Z"
      fill="url(#paint7_linear_1284_8278)"
    />
    <path
      d="M13.191 21.5506H2.59766C2.66661 18.4763 5.01121 16.0081 7.89466 16.0081C10.7774 16.0081 13.122 18.4763 13.191 21.5506Z"
      fill="url(#paint8_linear_1284_8278)"
    />
    <path
      d="M29.6994 21.5506H19.1055C19.1744 18.4763 21.5197 16.0081 24.4025 16.0081C27.2852 16.0081 29.6305 18.4763 29.6994 21.5506Z"
      fill="url(#paint9_linear_1284_8278)"
    />
    <path
      d="M31.397 19.82H30.0795C29.8975 19.82 29.75 19.6725 29.75 19.4905V17.4828C29.75 17.3008 29.8975 17.1533 30.0795 17.1533H31.397C31.579 17.1533 31.7265 17.3008 31.7265 17.4828V19.4905C31.7265 19.6725 31.579 19.82 31.397 19.82Z"
      fill="url(#paint10_linear_1284_8278)"
    />
    <path
      d="M1.91653 19.82H0.599006C0.417045 19.82 0.269531 19.6725 0.269531 19.4905V17.4828C0.269531 17.3008 0.417045 17.1533 0.599006 17.1533H1.91653C2.09849 17.1533 2.246 17.3008 2.246 17.4828V19.4905C2.246 19.6725 2.09849 19.82 1.91653 19.82Z"
      fill="url(#paint11_linear_1284_8278)"
    />
    <path
      d="M5.1205 21.9253H0.550902C0.246651 21.9253 0 21.6786 0 21.3744V19.8553C0 19.5511 0.246651 19.3044 0.550902 19.3044H5.1205C5.42475 19.3044 5.6714 19.5511 5.6714 19.8553V21.3744C5.6714 21.6786 5.42475 21.9253 5.1205 21.9253Z"
      fill="url(#paint12_linear_1284_8278)"
    />
    <path
      d="M31.4486 21.9253H26.879C26.5748 21.9253 26.3281 21.6786 26.3281 21.3744V19.8553C26.3281 19.5511 26.5748 19.3044 26.879 19.3044H31.4486C31.7529 19.3044 31.9995 19.5511 31.9995 19.8553V21.3744C31.9995 21.6786 31.7529 21.9253 31.4486 21.9253Z"
      fill="url(#paint13_linear_1284_8278)"
    />
    <path
      d="M11.5875 21.6812C11.5875 22.2451 11.4609 22.779 11.2352 23.257C10.8696 24.0307 10.2428 24.6575 9.46824 25.0222C8.99113 25.2489 8.45642 25.3745 7.8934 25.3745C5.85287 25.3745 4.19922 23.7209 4.19922 21.6812C4.19922 19.6407 5.85287 17.9871 7.8934 17.9871C9.93394 17.9871 11.5875 19.6407 11.5875 21.6812Z"
      fill="url(#paint14_linear_1284_8278)"
    />
    <path
      d="M7.8932 23.5623C8.93231 23.5623 9.77467 22.7199 9.77467 21.6808C9.77467 20.6417 8.93231 19.7993 7.8932 19.7993C6.85408 19.7993 6.01172 20.6417 6.01172 21.6808C6.01172 22.7199 6.85408 23.5623 7.8932 23.5623Z"
      fill="url(#paint15_linear_1284_8278)"
    />
    <path
      d="M11.236 23.257C10.8703 24.0307 10.2436 24.6574 9.46899 25.0222L7.07972 22.6329C6.81148 22.4028 6.64062 22.0619 6.64062 21.6813C6.64062 20.989 7.20188 20.4277 7.89415 20.4277C8.2757 20.4277 8.61653 20.5986 8.84668 20.8677L11.236 23.257Z"
      fill="url(#paint16_linear_1284_8278)"
    />
    <path
      d="M7.89421 22.9343C8.58655 22.9343 9.14779 22.3731 9.14779 21.6809C9.14779 20.9887 8.58655 20.4275 7.89421 20.4275C7.20187 20.4275 6.64062 20.9887 6.64062 21.6809C6.64062 22.3731 7.20187 22.9343 7.89421 22.9343Z"
      fill="url(#paint17_linear_1284_8278)"
    />
    <path
      d="M8.45563 20.5598C8.54021 20.7285 8.58784 20.9189 8.58784 21.1205C8.58784 21.8127 8.02658 22.3738 7.33419 22.3738C7.13259 22.3738 6.94216 22.3262 6.77344 22.2416C6.97924 22.6524 7.40409 22.9343 7.89482 22.9343C8.58715 22.9343 9.1484 22.3732 9.1484 21.6809C9.14846 21.1903 8.86643 20.7656 8.45563 20.5598Z"
      fill="url(#paint18_linear_1284_8278)"
    />
    <path
      d="M28.0914 21.6812C28.0914 22.2451 27.9648 22.779 27.7391 23.257C27.3735 24.0307 26.7467 24.6575 25.9721 25.0222C25.495 25.2489 24.9603 25.3745 24.3973 25.3745C22.3568 25.3745 20.7031 23.7209 20.7031 21.6812C20.7031 19.6407 22.3568 17.9871 24.3973 17.9871C26.4378 17.9871 28.0914 19.6407 28.0914 21.6812Z"
      fill="url(#paint19_linear_1284_8278)"
    />
    <path
      d="M24.3971 23.5623C25.4362 23.5623 26.2786 22.7199 26.2786 21.6808C26.2786 20.6417 25.4362 19.7993 24.3971 19.7993C23.358 19.7993 22.5156 20.6417 22.5156 21.6808C22.5156 22.7199 23.358 23.5623 24.3971 23.5623Z"
      fill="url(#paint20_linear_1284_8278)"
    />
    <path
      d="M27.7399 23.257C27.3742 24.0307 26.7475 24.6574 25.9729 25.0222L23.5836 22.6329C23.3154 22.4028 23.1445 22.0619 23.1445 21.6813C23.1445 20.989 23.7058 20.4277 24.3981 20.4277C24.7796 20.4277 25.1204 20.5986 25.3506 20.8677L27.7399 23.257Z"
      fill="url(#paint21_linear_1284_8278)"
    />
    <path
      d="M24.3981 22.9343C25.0904 22.9343 25.6517 22.3731 25.6517 21.6809C25.6517 20.9887 25.0904 20.4275 24.3981 20.4275C23.7058 20.4275 23.1445 20.9887 23.1445 21.6809C23.1445 22.3731 23.7058 22.9343 24.3981 22.9343Z"
      fill="url(#paint22_linear_1284_8278)"
    />
    <path
      d="M24.9595 20.5598C25.0441 20.7285 25.0917 20.9189 25.0917 21.1205C25.0917 21.8127 24.5305 22.3738 23.8381 22.3738C23.6365 22.3738 23.4461 22.3262 23.2773 22.2416C23.4831 22.6524 23.908 22.9343 24.3987 22.9343C25.0911 22.9343 25.6523 22.3732 25.6523 21.6809C25.6524 21.1903 25.3703 20.7656 24.9595 20.5598Z"
      fill="url(#paint23_linear_1284_8278)"
    />
    <path
      d="M19.5379 21.9254H12.7223C12.3579 21.9254 12.0625 21.63 12.0625 21.2655V19.9643C12.0625 19.5998 12.3579 19.3044 12.7223 19.3044H19.5379C19.9023 19.3044 20.1977 19.5998 20.1977 19.9643V21.2655C20.1977 21.63 19.9023 21.9254 19.5379 21.9254Z"
      fill="url(#paint24_linear_1284_8278)"
    />
    <path
      d="M7.84138 10.1764H2.88453C2.64196 10.1764 2.44531 9.97971 2.44531 9.73714V4.78028C2.44531 4.53771 2.64196 4.34106 2.88453 4.34106H7.84138C8.08396 4.34106 8.2806 4.53771 8.2806 4.78028V9.73714C8.2806 9.97971 8.08396 10.1764 7.84138 10.1764Z"
      fill="url(#paint25_linear_1284_8278)"
    />
    <path
      d="M14.9312 10.1764H9.97437C9.7318 10.1764 9.53516 9.97971 9.53516 9.73714V4.78028C9.53516 4.53771 9.7318 4.34106 9.97437 4.34106H14.9312C15.1738 4.34106 15.3704 4.53771 15.3704 4.78028V9.73714C15.3704 9.97971 15.1738 10.1764 14.9312 10.1764Z"
      fill="url(#paint26_linear_1284_8278)"
    />
    <path
      d="M29.6148 10.1764H24.658C24.4154 10.1764 24.2188 9.97971 24.2188 9.73714V4.78028C24.2188 4.53771 24.4154 4.34106 24.658 4.34106H27.9521C29.113 4.34106 30.054 5.28211 30.054 6.44302V9.73714C30.054 9.97971 29.8574 10.1764 29.6148 10.1764Z"
      fill="url(#paint27_linear_1284_8278)"
    />
    <path
      d="M31.1207 8.03254L30.5444 7.45628C30.4369 7.32803 30.2756 7.24634 30.0951 7.24634H29.4501C29.1972 7.24634 28.9922 7.45133 28.9922 7.70425V11.099C28.9922 11.244 29.0598 11.373 29.1651 11.457L31.1207 13.4125V8.03254Z"
      fill="url(#paint28_linear_1284_8278)"
    />
    <path
      d="M31.9662 4.82241V8.25205C31.9662 8.84123 31.6192 9.35009 31.1192 9.58601C30.9291 9.67636 30.7157 9.72656 30.4917 9.72656V8.78539C30.786 8.78539 31.025 8.54633 31.025 8.25205V4.82241C31.025 4.60155 30.8908 4.41206 30.6988 4.33111C30.6988 4.33111 30.6988 4.33049 30.6981 4.33111C30.5193 3.96908 30.2865 3.63778 30.0117 3.3479H30.4917C31.3049 3.3479 31.9662 4.00923 31.9662 4.82241Z"
      fill="url(#paint29_linear_1284_8278)"
    />
    <path
      d="M30.095 7.24634H29.4501C29.1972 7.24634 28.9922 7.45133 28.9922 7.70425V11.0989C28.9922 11.3518 29.1972 11.5569 29.4501 11.5569H30.095C30.4189 11.5569 30.6814 11.2943 30.6814 10.9704V7.83275C30.6814 7.50886 30.4189 7.24634 30.095 7.24634Z"
      fill="url(#paint30_linear_1284_8278)"
    />
    <path
      d="M31.1216 13.7529H30.2745C30.1186 13.7529 29.9922 13.6265 29.9922 13.4706C29.9922 13.3147 30.1186 13.1882 30.2745 13.1882H31.1216V13.7529Z"
      fill="url(#paint31_linear_1284_8278)"
    />
    <path
      d="M31.1216 14.7568H30.2745C30.1186 14.7568 29.9922 14.6304 29.9922 14.4745C29.9922 14.3186 30.1186 14.1921 30.2745 14.1921H31.1216V14.7568Z"
      fill="url(#paint32_linear_1284_8278)"
    />
    <path
      d="M31.1216 15.7607H30.2745C30.1186 15.7607 29.9922 15.6343 29.9922 15.4784C29.9922 15.3225 30.1186 15.196 30.2745 15.196H31.1216V15.7607Z"
      fill="url(#paint33_linear_1284_8278)"
    />
    <path
      d="M31.1216 16.7646H30.2745C30.1186 16.7646 29.9922 16.6382 29.9922 16.4823C29.9922 16.3264 30.1186 16.2 30.2745 16.2H31.1216V16.7646Z"
      fill="url(#paint34_linear_1284_8278)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1284_8278"
        x1="10.3512"
        y1="4.04718"
        x2="10.3512"
        y2="1.3807"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1284_8278"
        x1="10.1485"
        y1="0.875185"
        x2="18.3487"
        y2="19.2497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1284_8278"
        x1="31.4572"
        y1="17.4896"
        x2="25.8415"
        y2="15.419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A97F6" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1284_8278"
        x1="8.88227"
        y1="10.8352"
        x2="8.88227"
        y2="2.96313"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1284_8278"
        x1="27.0791"
        y1="10.8352"
        x2="27.0791"
        y2="2.96313"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1284_8278"
        x1="7.24368"
        y1="10.3735"
        x2="9.72718"
        y2="15.9384"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1284_8278"
        x1="14.7517"
        y1="7.91082"
        x2="24.6967"
        y2="17.8559"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1284_8278"
        x1="20.5529"
        y1="12.7925"
        x2="9.38433"
        y2="9.59253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFDCFB" stopOpacity="0" />
        <stop offset="0.4856" stopColor="#BAAAD6" stopOpacity="0.486" />
        <stop offset="1" stopColor="#8679B2" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1284_8278"
        x1="7.89428"
        y1="15.1786"
        x2="7.89428"
        y2="20.943"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1284_8278"
        x1="24.4024"
        y1="15.1786"
        x2="24.4024"
        y2="20.943"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1284_8278"
        x1="30.2337"
        y1="17.65"
        x2="31.3055"
        y2="19.4275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1284_8278"
        x1="0.753296"
        y1="17.65"
        x2="1.82505"
        y2="19.4275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1284_8278"
        x1="2.8357"
        y1="20.1037"
        x2="2.8357"
        y2="22.2475"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1284_8278"
        x1="29.1638"
        y1="20.1037"
        x2="29.1638"
        y2="22.2475"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1284_8278"
        x1="6.87442"
        y1="20.6619"
        x2="11.1475"
        y2="24.935"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1284_8278"
        x1="9.19528"
        y1="22.9828"
        x2="6.0207"
        y2="19.8082"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEC3D2" />
        <stop offset="0.2341" stopColor="#B6B7C6" />
        <stop offset="0.6581" stopColor="#A097A7" />
        <stop offset="1" stopColor="#8B788A" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1284_8278"
        x1="9.79877"
        y1="23.5857"
        x2="7.70297"
        y2="21.4899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_1284_8278"
        x1="7.42519"
        y1="21.2119"
        x2="9.04872"
        y2="22.8353"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_1284_8278"
        x1="7.93836"
        y1="22.1614"
        x2="9.66122"
        y2="18.9571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.0471" stopColor="#6E566E" stopOpacity="0.047" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_1284_8278"
        x1="23.3783"
        y1="20.6619"
        x2="27.6513"
        y2="24.9349"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_1284_8278"
        x1="25.6991"
        y1="22.9828"
        x2="22.5245"
        y2="19.8082"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEC3D2" />
        <stop offset="0.2341" stopColor="#B6B7C6" />
        <stop offset="0.6581" stopColor="#A097A7" />
        <stop offset="1" stopColor="#8B788A" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_1284_8278"
        x1="26.3027"
        y1="23.5857"
        x2="24.2069"
        y2="21.4899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_1284_8278"
        x1="23.9291"
        y1="21.2119"
        x2="25.5526"
        y2="22.8353"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_1284_8278"
        x1="24.4423"
        y1="22.1614"
        x2="26.1652"
        y2="18.9571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.0471" stopColor="#6E566E" stopOpacity="0.047" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_1284_8278"
        x1="16.1301"
        y1="20.1038"
        x2="16.1301"
        y2="22.2476"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_1284_8278"
        x1="2.82178"
        y1="4.71753"
        x2="7.70165"
        y2="9.59734"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_1284_8278"
        x1="9.91163"
        y1="4.71753"
        x2="14.7914"
        y2="9.59734"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_1284_8278"
        x1="24.3517"
        y1="4.96105"
        x2="29.2315"
        y2="9.84085"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_1284_8278"
        x1="31.7877"
        y1="10.7411"
        x2="28.9224"
        y2="9.69528"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A97F6" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BB" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_1284_8278"
        x1="28.7511"
        y1="4.84355"
        x2="32.5754"
        y2="8.06689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_1284_8278"
        x1="28.9608"
        y1="9.0096"
        x2="30.5922"
        y2="9.78349"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_1284_8278"
        x1="30.5569"
        y1="14.1817"
        x2="30.5569"
        y2="13.4076"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_1284_8278"
        x1="30.5569"
        y1="15.1856"
        x2="30.5569"
        y2="14.4115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_1284_8278"
        x1="30.5569"
        y1="16.1895"
        x2="30.5569"
        y2="15.4154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_1284_8278"
        x1="30.5569"
        y1="17.1934"
        x2="30.5569"
        y2="16.4193"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconPublicTransit;
