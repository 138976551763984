import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import _ from "lodash";
import { FavoritesForNonAuthModal } from "components/Modals";
import { DATA_KIND } from "constants/common";
import { USER_KEY } from "constants/localStorage";
import { HOUSE_VIEWS } from "constants/houses";
import { HOUSE_DETAILS_LOCATION } from "constants/paths";
import { handleFavoritesListMapView } from "utils/helpers/favoritesHelper";
import { getModalTitle, localStorageStrToObjectOrNull } from "utils/helpers/common";
import { formatCriteriaData } from "utils/helpers/criteriaHelper";
import { formatSearchParams, getActiveFilters } from "utils/helpers/searchHelper";
import { AllCriteriaModalView, NoResultsView } from "containers/Houses/Views";
import { NON_PRIORITY_CRITERIA } from "constants/criteria";
import { ListView, MapView } from "containers/Houses/Content";
import Header from "./Header";
import { CriteriaModalView } from "../Onboarding/Views";
import { BASIC_FILTERS_MODAL_DATA } from "./config";
import { SWITCHER_VIEW } from "./constants";
import "./styles.scss";

const HOUSES_COMPONENTS_MAP = {
  [SWITCHER_VIEW.list]: ListView,
  [SWITCHER_VIEW.map]: MapView,
  // [SWITCHER_VIEW.card]: CardView,
};

const Houses = observer(({ criteriaStore, housesStore, view, handleGetHousesData }) => {
  const [activeView, setActiveView] = useState(SWITCHER_VIEW.list);
  const [resultsExist, checkResultsExistence] = useState(true);
  const activeFilter = criteriaStore.retrieveActiveFilter();
  const criteriaData = criteriaStore.retrieveCriteriaData();
  const housesData = housesStore.retrieveHousesData();
  const isNonAuthUser = _.isNull(localStorage.getItem(USER_KEY));
  const [favoritesPopUpActive, setFavoritesPopUpActive] = useState(false);
  const [isShowAllCriteriaModal, setIsShowAllCriteriaModal] = useState(false);
  const [viewedHouseIds, setViewedHouseIds] = useState([]);

  useEffect(() => () => housesStore.setHouses([]), []);

  const handleNonAuthFavorites = () => setFavoritesPopUpActive(true);

  const isShowEmptyResults = ((isNonAuthUser && _.isEmpty(housesData) && view === HOUSE_VIEWS.favorites) || _.get(housesData, "total_results") === 0) &&
      activeView !== "map";

  const getHousesData = (handleSuccess, isMapView) => {
    if (_.isEmpty(criteriaData)) {
      criteriaStore.getCriteriaRequest(null, responseData => handleGetHousesData(responseData, isMapView, handleSuccess));
    } else if (!(isNonAuthUser && view === HOUSE_VIEWS.favorites)) {
      handleGetHousesData(criteriaData, isMapView, handleSuccess);
    }
  };

  const markHouseAsRecentlyViewed = (houseId) => {
    if (!viewedHouseIds.includes(houseId) && !isNonAuthUser) {
      const data = {
        user_id: localStorageStrToObjectOrNull(USER_KEY).username,
        house_id: houseId,
      };
      const handleSuccess = () => {
        const newViewedHouseList = _.cloneDeep(viewedHouseIds);
        newViewedHouseList.push(houseId);
        setViewedHouseIds(newViewedHouseList);
      };
      housesStore.markHousesRecentlyViewedRequest(data, handleSuccess);
    }
  };

  const handleOnClickHouse = (id) => {
    markHouseAsRecentlyViewed(id);
    const searchFilters = formatSearchParams(criteriaData);
    const activeFilters = getActiveFilters(searchFilters);
    window.open(HOUSE_DETAILS_LOCATION(id, activeFilters), "_blank");
  };

  const handleActiveView = currentSwitcherValue => {
    housesStore.setHouses({});
    setActiveView(currentSwitcherValue);
  };

  const onChangeAllCriteriaModalState = () => setIsShowAllCriteriaModal(!isShowAllCriteriaModal);

  const onSubmitCriteriaFilter = updatedValues => {
    const handleSuccess = () => {
      criteriaStore.setActiveFilter(null);
      const updatedCriteriaData = criteriaStore.retrieveCriteriaData();
      handleGetHousesData(updatedCriteriaData, activeView === SWITCHER_VIEW.map);
      if (activeView === SWITCHER_VIEW.map) {
        housesStore.setCurrentPage(1);
      }
    };
    const criteriaData = criteriaStore.retrieveCriteriaData();
    if (!NON_PRIORITY_CRITERIA.includes(updatedValues.kind)) {
      const priority = criteriaData[updatedValues.kind]?.priority;
      updatedValues.priority = priority || 0;
    }
    const data = formatCriteriaData(updatedValues, criteriaData);
    criteriaStore.postSearchCriteriaRequest({ data }, handleSuccess);
  };

  const updateViewedHouseIds = housesData => {
    const houseIds = housesData.data.filter(houseItem => houseItem.is_viewed).map(houseItem => houseItem.id);
    setViewedHouseIds(houseIds);
  };

  const renderContent = () => {
    const HousesComponent = HOUSES_COMPONENTS_MAP[activeView];
    const isShowNoResults = (isShowEmptyResults || !resultsExist) && activeView !== SWITCHER_VIEW.map;
    if (isShowNoResults) {
      return (
        <NoResultsView
          activeTab={activeView}
          isLoading={housesStore.loading}
          view={view}
        />
      );
    }

    return (
      <HousesComponent
        activeTab={activeView}
        view={view}
        checkResultsExistence={checkResultsExistence}
        criteriaStore={criteriaStore}
        getHousesData={getHousesData}
        housesStore={housesStore}
        viewedHouseIds={viewedHouseIds}
        handleFavorites={handleFavoritesListMapView}
        handleOnClickHouse={handleOnClickHouse}
        handleNonAuthFavorites={handleNonAuthFavorites}
        markHouseAsRecentlyViewed={markHouseAsRecentlyViewed}
        setViewedHouseIds={setViewedHouseIds}
        updateViewedHouseIds={updateViewedHouseIds}
      />
    );
  };

  const renderCriteriaModalBody = filterData => {
    const Form = filterData.form;
    const criteriaData = criteriaStore.retrieveCriteriaData();
    const initialData = criteriaData[activeFilter] || filterData.defaultValue;
    return (
      <Form
        dataKind={DATA_KIND.topMatches}
        formName={filterData.formName}
        initialData={initialData}
        kind={activeFilter}
        onSubmitForm={onSubmitCriteriaFilter}
      />
    );
  };

  const renderCriteriaModal = () => {
    const modalData = BASIC_FILTERS_MODAL_DATA[activeFilter];
    const title = getModalTitle(modalData, "title");
    return (
      <CriteriaModalView
        bodyContent={() => renderCriteriaModalBody(modalData)}
        customClass="filters-bar-criteria-modal"
        formName={modalData.formName}
        isVisible={!_.isNull(activeFilter)}
        title={title}
        onClose={() => criteriaStore.setActiveFilter(null)}
      />
    );
  };

  return (
    <div className="houses-main-wrapper">
      <div className={classNames("main houses", { "list-content": activeView === SWITCHER_VIEW.list })}>
        <Header
          activeTab={activeView}
          criteriaData={criteriaStore.retrieveCriteriaData()}
          housesData={housesStore.retrieveHousesData()}
          isLoading={housesStore.loading}
          isMap={activeView === "map"}
          view={view}
          handleActiveTab={handleActiveView}
          onChangeAllCriteriaModalState={onChangeAllCriteriaModalState}
          onSubmitCriteriaFilter={onSubmitCriteriaFilter}
        />
        <div className="main-content house-content">
          {renderContent()}
        </div>
        {criteriaStore.retrieveActiveFilter() && renderCriteriaModal()}
        <FavoritesForNonAuthModal
          isVisible={favoritesPopUpActive}
          onClose={() => setFavoritesPopUpActive(false)}
        />
        {isShowAllCriteriaModal && (
          <AllCriteriaModalView
            activeTab={activeView}
            criteriaData={criteriaData}
            criteriaStore={criteriaStore}
            housesStore={housesStore}
            getHouseResults={handleGetHousesData}
            onChangeIsVisible={onChangeAllCriteriaModalState}
          />
        )}
      </div>
    </div>
  );
});

export default inject("criteriaStore", "housesStore")(Houses);
