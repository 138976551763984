import React from "react";
import { Button } from "antd";
import classNames from "classnames";
import "../styles.scss";

const SimpleButton = props => {
  const { customClass, ...otherProps } = props;

  return (
    <div className={classNames("button-wrapper", customClass)}>
      <Button {...otherProps}>
        <span className="button__text">{props.children}</span>
      </Button>
    </div>
  );
};

export default SimpleButton;