import { initializeApp } from "firebase/app";
import {
  applyActionCode,
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  checkActionCode,
  EmailAuthProvider,
  getIdToken,
  reauthenticateWithCredential,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { TOKEN_KEY } from "constants/localStorage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth();
export const firebaseLogin = (requestBody) => signInWithEmailAndPassword(firebaseAuth, requestBody.email, requestBody.password);
export const firebaseSignUp = (requestBody) => createUserWithEmailAndPassword(firebaseAuth, requestBody.email, requestBody.password);
export const firebaseSendEmailVerification = (requestBody) => sendEmailVerification(requestBody);
export const firebaseApplyVerification = (requestBody) => applyActionCode(firebaseAuth, requestBody);
export const firebaseCheckActionCode = (requestBody) => checkActionCode(firebaseAuth, requestBody);
export const firebaseUpdateEmail = (requestBody) => updateEmail(firebaseAuth.currentUser, requestBody)
export const firebaseUpdatePassword = (requestBody) => updatePassword(firebaseAuth.currentUser, requestBody);
export const firebaseUpdateProfile = (requestBody) => updateProfile(firebaseAuth.currentUser, requestBody);
export const firebaseReauthenticateWithCredential = (requestBody) => reauthenticateWithCredential(firebaseAuth.currentUser, requestBody);
export const firebaseSignInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  return signInWithPopup(firebaseAuth, provider);
};

export const firebaseCheckAndUpdateIdToken = async () => {
  let user = firebaseAuth.currentUser;
  if (!user) {
    user = await new Promise((resolve) => {
      firebaseAuth.onAuthStateChanged(user => resolve(user))
    })
  }
  if (user && localStorage.getItem(TOKEN_KEY)) {
    const token = await getIdToken(user);
    localStorage.setItem(TOKEN_KEY, token);
  } else {
    localStorage.removeItem(TOKEN_KEY);
  }
};

export const checkIsEmailVerified = () => firebaseAuth.currentUser && firebaseAuth.currentUser.emailVerified;
export const getEmailCredentials = (email, password) => EmailAuthProvider.credential(email, password);
