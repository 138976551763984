import React from 'react';
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { SwitchButton } from "components/Controls/Buttons";
import { SWITCH_SETTINGS } from "containers/Houses/constants";
import "./styles.scss";

const SwitcherView = ({ handleActiveView, activeView }) => (
  <div className="switcher">
    {SWITCH_SETTINGS.map(item => (
      <SwitchButton
        key={item.key}
        icon={item.icon}
        isActive={item.key === activeView}
        onClick={() => handleActiveView(item.key)}
      >
        <FormattedMessage id={item.message} />
      </SwitchButton>
    ))}
  </div>
);

SwitcherView.propTypes = {
  activeView: PropTypes.string,
  handleActiveView: PropTypes.func,
};

export default SwitcherView;
