import _ from "lodash";
import { DATE_OPTIONS, IMAGE_EXTENSION, lookup, rx } from "./constants";
import {
  formatDateValue,
  formatTimeValue,
  initializeDateFromTimestamp,
  TIME_ZONES_MAP
} from "../dateHelper";

const formatOpenHouseTimes = timesList => {
  // Gets timestamp value from dates list and returns open house times list with strings in appropriate format
  const formattedTimesList = [];
  timesList.forEach(item => {
    // convert timestamp value into date object
    const startDate = initializeDateFromTimestamp(item.start_date);
    const endDate = initializeDateFromTimestamp(item.end_date);
    // get start and end day and month values from date object
    const startDay = formatDateValue(startDate, DATE_OPTIONS.dayAndMonth);
    const endDay = formatDateValue(endDate, DATE_OPTIONS.dayAndMonth);

    const startWeekDay = formatDateValue(startDate, DATE_OPTIONS.weekDay);
    // get start and end time from date object
    const timeOptions = { timeZone: TIME_ZONES_MAP[item.time_zone].value , ...DATE_OPTIONS.time };
    const startTime = formatTimeValue(startDate, timeOptions);
    const endTime = formatTimeValue(endDate, timeOptions);

    let formattedDate;
    const timeZone = TIME_ZONES_MAP[item.time_zone].label;
    if (startDay === endDay) {
      formattedDate = `${startWeekDay} ${startDay} — ${startTime} - ${endTime} ${timeZone}`;
    } else {
      const endWeekDay = formatDateValue(endDate, DATE_OPTIONS.weekDay);
      formattedDate = `${startWeekDay} ${startDay} ${startTime} - ${endWeekDay} ${endDate} ${endTime} ${timeZone}`;
    }
    formattedTimesList.push(formattedDate);
  });
  return formattedTimesList;
};

const formatPhotoURL = url => {
  const cutPath = url.includes(IMAGE_EXTENSION) ? url.split(IMAGE_EXTENSION)[0] : null;
  if (!_.isNull(cutPath)) {
    let newPath = "";
    const lastSymbolIndex = cutPath.length - 1;
    if (cutPath[lastSymbolIndex] === 'x') {
      newPath = cutPath.slice(0, lastSymbolIndex) + "od" + IMAGE_EXTENSION;
      return newPath;
    }
  }
  return  url;
};

const formatTaxDifference = (currentTax, prevTax) => {
  let taxDifference = ((currentTax - prevTax) / prevTax * 100).toFixed(1);
  const isNegative = taxDifference < 0;
  taxDifference = taxDifference > 0 ? `+${taxDifference}` : taxDifference.toString();
  taxDifference = taxDifference.replace(/\./, ',');
  return { value: taxDifference, isNegative };
};

const fullNumerFormatter = (num, digits, upper) => {
  if (num >= 1000000) {
    const nFormat = nFormatter(num, digits, upper);
    const kFormat = kFormatter(num % 1000000);
    return `${nFormat} ${kFormat}`;
  } else {
    return kFormatter(num);
  }
};

const kFormatter = num => Math.abs(num) > 999 ?
  Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K" : Math.sign(num) * Math.abs(num);

const nFormatter = (num, digits, upper=true) => {
  const lookup = [
    { value: 1e6, symbol: upper ? "M" : "m" },
    { value: 1e3, symbol: upper ? "K" : "k" },
    { value: 1, symbol: "" },
  ];
  let item = lookup.find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

const toLocalePrice = value => (
  `$${value.toLocaleString('en-US')}`
)

const priceFormatter = (num) => {
  let item = lookup.find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(item.digits).replace(rx, "$1") + item.symbol : "0";
};

export {
  formatOpenHouseTimes,
  formatPhotoURL,
  formatTaxDifference,
  fullNumerFormatter,
  kFormatter,
  nFormatter,
  priceFormatter,
  toLocalePrice
};
