import React from "react";
import HelmetIntl from "components/Meta/HelmetIntl";
import Onboarding from "containers/Onboarding";
import _ from "lodash";
import queryString from 'query-string';

const OnboardingPage = (props) => {
  const params = queryString.parse(props.location.search);
  const queryParamGid = _.has(params, 'location') ? params.location : null;

  return (
    <>
      <HelmetIntl id="helmet.onboardingTitle"/>
      <Onboarding queryParamGid={queryParamGid} />
    </>
  );
}

export default OnboardingPage;
