import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Table } from "antd";
import CustomRadio from "components/Controls/Radio/CustomRadio";
import { ALL_CRITERIA_DATA } from "containers/Onboarding/config/buttonsConfig";
import { PRIORITY_COLUMNS } from "containers/Onboarding/config/tablesConfig";
import { getPriorityCriteria } from "utils/helpers/onboardingHelper";

import "./styles.scss";

const PriorityStep = ({ stepData, handleFormChange }) => {
  const [selectedPriority, setSelectedPriority] = useState(stepData);

  const renderTitle = () => (
    <div className="priority-step__title">
      <div className="priority-step__title__text">
        <FormattedMessage id="onboarding.priorityTitle" />
      </div>
      <div className="priority_step-subtitle">
        <div className="priority_step-subtitle__text">
          <FormattedMessage id="onboarding.prioritySubTitleFirst" />
        </div>
      </div>
    </div>
  );

  const onRadioChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    stepData[name].priority = value;
    setSelectedPriority(stepData);
    handleFormChange(stepData);
  };

  const checkSelectedPriority = (criteria, priority) => selectedPriority[criteria.kind].priority === priority;

  const renderFirstColumn = record => {
    const criteria = ALL_CRITERIA_DATA.filter(item => item.key === record.kind)[0];
    return (
      <div className="priority-step__criteria_column">
        <div className="priority-step__criteria_column_icon">
        {criteria.icon}
        </div>
        <FormattedMessage id={criteria.message}/>
      </div>
    );
  };

  const renderRadioButton = (item, priority) => (
    <CustomRadio
      checked={checkSelectedPriority(item, priority)}
      onChange={onRadioChange}
      name={item.kind}
      value={priority}
    />
  );

  const renderPriority = () => {
    let data = getPriorityCriteria(stepData);
    data = Object.values(data);
    return (
      <div className="priority-step__content">
        <Table
          bordered={false}
          columns={PRIORITY_COLUMNS(renderFirstColumn, renderRadioButton)}
          dataSource={data}
          pagination={false}
          size="middle"
        />
      </div>
    );
  };

  return (
    <div className="priority-criteria-step-container">
      {renderTitle()}
      {renderPriority()}
    </div>
  );
};

PriorityStep.propTypes = {
  stepData: PropTypes.object,
  handleFormChange: PropTypes.func,
};

export default PriorityStep;
