import React from "react";

const IconConfetti = () => (
<svg width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1617_6575)">
<path d="M92.1941 140.001L64.4106 153.431C54.3993 158.27 42.7734 150.977 42.7734 139.857V127.877C42.7734 123.1 45.5061 118.744 49.8068 116.665L77.5904 103.236C87.6017 98.3966 99.2276 105.69 99.2276 116.809V128.79C99.2276 133.566 96.4948 137.923 92.1941 140.001Z" fill="url(#paint0_linear_1617_6575)"/>
<path d="M94.0275 207.329L58.676 184.646C48.7659 178.287 42.7734 167.322 42.7734 155.547V127.877C42.7734 132.017 44.8803 135.872 48.364 138.108L83.325 160.54C93.2351 166.899 99.2276 177.864 99.2276 189.639V204.487C99.2276 207.157 96.2751 208.771 94.0275 207.329Z" fill="url(#paint1_linear_1617_6575)"/>
<path d="M47.9734 48.4254L83.325 71.1087C93.2351 77.4678 99.2276 88.433 99.2276 100.208V127.877C99.2276 123.738 97.1207 119.883 93.637 117.647L58.676 95.2146C48.7659 88.8556 42.7734 77.8904 42.7734 66.1158V51.2673C42.7734 48.5972 45.7264 46.9836 47.9734 48.4254Z" fill="url(#paint2_linear_1617_6575)"/>
<path d="M197.623 110.136L177.478 119.874C170.219 123.382 161.789 118.094 161.789 110.032V101.345C161.789 97.8813 163.77 94.7227 166.889 93.2154L187.034 83.4775C194.293 79.9688 202.723 85.2573 202.723 93.3194V102.006C202.724 105.47 200.742 108.629 197.623 110.136Z" fill="url(#paint3_linear_1617_6575)"/>
<path d="M185.151 51.4646L177.478 55.1738C170.219 58.6825 161.789 53.394 161.789 45.3319V36.6448C161.789 33.1813 163.77 30.0227 166.889 28.5154L199.085 12.953C200.768 12.1394 202.724 13.3658 202.724 15.2359V23.4538C202.724 35.3879 195.896 46.2707 185.151 51.4646Z" fill="url(#paint4_linear_1617_6575)"/>
<path d="M198.953 158.955L173.32 142.508C166.134 137.897 161.789 129.946 161.789 121.408V101.345C161.789 104.347 163.317 107.142 165.843 108.763L191.192 125.029C198.378 129.64 202.723 137.59 202.723 146.128V156.894C202.723 158.83 200.582 160 198.953 158.955Z" fill="url(#paint5_linear_1617_6575)"/>
<path d="M191.192 60.3283L165.843 44.0624C163.317 42.4417 161.789 39.6464 161.789 36.645V45.9777V56.7081V56.7443C161.789 65.2822 166.134 73.2328 173.32 77.8436L198.67 94.1095C201.196 95.7307 202.723 98.5255 202.723 101.527V92.1947V81.4638V81.4276C202.723 72.8897 198.378 64.9391 191.192 60.3283Z" fill="url(#paint6_linear_1617_6575)"/>
<path d="M41.5725 45.3962C34.3805 50.0828 28.244 56.2564 23.5969 63.4781C22.5518 65.1021 20.14 65.0974 19.1009 63.4701C14.4802 56.2315 8.36683 50.0357 1.19177 45.3228C-0.400702 44.2767 -0.396466 41.8978 1.19977 40.8578C8.39177 36.1713 14.5278 29.9976 19.1748 22.776C20.2195 21.152 22.6313 21.1562 23.6708 22.784C28.2915 30.0226 34.4054 36.2183 41.58 40.9313C43.1734 41.9769 43.1692 44.3557 41.5725 45.3962Z" fill="url(#paint7_linear_1617_6575)"/>
<path d="M238.799 173.832C231.607 178.519 225.471 184.692 220.824 191.914C219.778 193.538 217.367 193.533 216.328 191.906C211.707 184.667 205.593 178.472 198.418 173.758C196.826 172.712 196.83 170.333 198.426 169.293C205.618 164.606 211.754 158.433 216.401 151.212C217.446 149.588 219.858 149.592 220.897 151.22C225.518 158.458 231.632 164.654 238.806 169.367C240.4 170.413 240.395 172.792 238.799 173.832Z" fill="url(#paint8_linear_1617_6575)"/>
<path d="M70.2429 28.4442C65.4914 31.5407 61.4373 35.6193 58.3672 40.3906C57.6768 41.4635 56.0834 41.4607 55.3968 40.3849C52.3441 35.6024 48.3051 31.5092 43.5648 28.3958C42.5126 27.7045 42.5154 26.1332 43.57 25.4456C48.3215 22.3492 52.3756 18.2706 55.4458 13.4993C56.1361 12.4264 57.7295 12.4292 58.4161 13.5045C61.4688 18.2871 65.5084 22.3802 70.2486 25.4941C71.3004 26.1854 71.2975 27.7572 70.2429 28.4442Z" fill="url(#paint9_linear_1617_6575)"/>
<path d="M70.2429 214.554C65.4914 217.651 61.4373 221.729 58.3672 226.5C57.6768 227.573 56.0834 227.571 55.3968 226.495C52.3441 221.712 48.3051 217.619 43.5648 214.506C42.5126 213.814 42.5154 212.243 43.57 211.556C48.3215 208.459 52.3756 204.38 55.4458 199.609C56.1361 198.536 57.7295 198.539 58.4161 199.614C61.4688 204.397 65.5084 208.49 70.2486 211.604C71.3004 212.295 71.2975 213.867 70.2429 214.554Z" fill="url(#paint10_linear_1617_6575)"/>
<path d="M48.8523 103.214C44.1008 106.31 40.0467 110.389 36.9765 115.16C36.2862 116.233 34.6928 116.23 34.0062 115.154C30.9535 110.372 26.9144 106.279 22.1742 103.165C21.122 102.474 21.1248 100.903 22.1794 100.215C26.9309 97.1187 30.985 93.0401 34.0551 88.2688C34.7455 87.1959 36.3389 87.1987 37.0255 88.274C40.0782 93.0566 44.1177 97.1498 48.858 100.264C49.9097 100.955 49.9069 102.527 48.8523 103.214Z" fill="url(#paint11_linear_1617_6575)"/>
<path d="M228.805 125.836C224.054 128.932 220 133.011 216.93 137.782C216.239 138.855 214.646 138.852 213.959 137.777C210.907 132.994 206.868 128.901 202.127 125.787C201.075 125.096 201.078 123.525 202.132 122.837C206.884 119.741 210.938 115.662 214.008 110.891C214.699 109.818 216.292 109.821 216.979 110.896C220.031 115.679 224.071 119.772 228.811 122.886C229.863 123.577 229.86 125.148 228.805 125.836Z" fill="url(#paint12_linear_1617_6575)"/>
<path d="M73.2101 54.7039H85.0007C87.1075 54.7039 88.9706 53.3877 89.7301 51.4225C91.9597 45.655 96.5197 41.0395 102.25 38.7345C104.176 37.9595 105.455 36.1119 105.455 34.0348V22.1976C105.455 18.8861 102.315 16.4432 99.1188 17.3115C84.0896 21.3948 72.2576 33.3228 68.3131 48.4079C67.4797 51.5952 69.916 54.7039 73.2101 54.7039Z" fill="url(#paint13_linear_1617_6575)"/>
<path d="M97.2539 151.181V161.855C97.2539 163.763 98.4459 165.449 100.225 166.137C105.446 168.156 109.625 172.284 111.712 177.472C112.413 179.216 114.086 180.374 115.966 180.374H126.683C129.681 180.374 131.893 177.531 131.107 174.638C127.41 161.031 116.611 150.319 102.953 146.748C100.068 145.993 97.2539 148.199 97.2539 151.181Z" fill="url(#paint14_linear_1617_6575)"/>
<path d="M205.988 46.1469V56.8213C205.988 58.7286 207.18 60.4152 208.959 61.1032C214.181 63.122 218.359 67.25 220.446 72.4378C221.148 74.1822 222.821 75.3394 224.701 75.3394H235.417C238.416 75.3394 240.627 72.4966 239.841 69.6034C236.145 55.9964 225.345 45.2848 211.688 41.7135C208.803 40.9592 205.988 43.1644 205.988 46.1469Z" fill="url(#paint15_linear_1617_6575)"/>
<path d="M5.80336 212.977H19.321C21.7365 212.977 23.8725 211.468 24.7431 209.215C27.2998 202.602 32.5271 197.311 39.097 194.667C41.306 193.778 42.7718 191.66 42.7718 189.279V175.707C42.7718 171.911 39.1714 169.11 35.5078 170.105C18.2763 174.787 4.71159 188.463 0.188769 205.758C-0.766525 209.413 2.02642 212.977 5.80336 212.977Z" fill="url(#paint16_linear_1617_6575)"/>
<path d="M171.329 227.303C167.586 227.304 163.827 226.635 160.228 225.274C150.846 221.727 143.747 213.928 141.077 204.3L123.003 216.641C121.615 217.589 119.723 217.232 118.775 215.845L109.288 201.95C108.341 200.562 108.697 198.67 110.085 197.722L138.992 177.984C140.116 167.427 143.574 157.143 149.219 147.977C149.858 146.94 151.233 146.639 152.25 147.311L167.773 157.559C168.743 158.199 169.023 159.487 168.425 160.485C167.792 161.539 167.202 162.615 166.654 163.708C176.687 162.639 187.018 166.306 194.105 174.277L194.789 175.045C200.753 181.752 203.564 190.709 202.504 199.618C201.444 208.528 196.609 216.574 189.24 221.693C183.907 225.397 177.644 227.303 171.329 227.303ZM161.715 190.208C161.937 192.522 162.326 194.828 162.886 197.108L163.088 197.931C164.049 201.844 167.073 203.371 168.328 203.845C169.585 204.32 172.862 205.175 176.17 202.877C178.897 200.983 179.587 198.326 179.755 196.911C179.924 195.495 179.877 192.75 177.67 190.269L176.987 189.501C173.92 186.052 168.735 185.414 164.926 188.015L161.715 190.208Z" fill="url(#paint17_linear_1617_6575)"/>
<path d="M194.787 175.045L194.104 174.277C187.016 166.306 176.686 162.638 166.653 163.708C159.59 173.847 161.713 190.208 161.713 190.208L164.925 188.015C168.734 185.414 173.92 186.053 176.986 189.502L177.615 190.208H202.189C201.158 184.63 198.632 179.369 194.787 175.045Z" fill="url(#paint18_linear_1617_6575)"/>
<path d="M110.085 197.722C108.697 198.669 108.341 200.562 109.288 201.949L118.775 215.844C119.723 217.231 121.615 217.588 123.003 216.641L141.078 204.299C137.455 190.632 138.992 177.984 138.992 177.984L110.085 197.722Z" fill="url(#paint19_linear_1617_6575)"/>
<path d="M145.533 61.7368C143.398 54.0168 137.703 47.7646 130.187 44.9227C127.301 43.8356 124.288 43.2949 121.286 43.2949C116.226 43.2949 111.204 44.8234 106.933 47.7923C101.023 51.8977 97.1494 58.3486 96.3 65.4893C95.8972 68.8888 96.1951 72.2987 97.1386 75.5161C97.2158 75.7919 97.3042 76.0625 97.3922 76.333C97.4807 76.598 97.5744 76.8681 97.668 77.133C98.0544 78.198 98.5179 79.2356 99.0586 80.245C99.3179 80.7472 99.605 81.2441 99.9085 81.7293C100.058 81.9721 100.212 82.2093 100.366 82.4465L100.372 82.4521C100.526 82.6785 100.681 82.8992 100.836 83.1199C100.902 83.2083 100.963 83.2963 101.028 83.3848C101.316 83.7768 101.613 84.1519 101.923 84.5269C102.105 84.7476 102.292 84.9683 102.485 85.1834L103.032 85.8013C108.71 92.1914 116.993 95.1269 125.033 94.2719C124.597 95.149 124.123 96.0102 123.615 96.8545C123.135 97.6545 123.361 98.6865 124.139 99.1999L136.577 107.416C137.394 107.952 138.498 107.714 139.011 106.881C143.536 99.5363 146.306 91.2921 147.205 82.8272L156.59 76.421C157.779 75.6093 158.491 74.2625 158.491 72.8225V56.4356C158.491 54.9312 156.805 54.0408 155.563 54.8893L145.533 61.7368ZM116.75 73.5956L116.248 73.0328L116.204 72.9834C114.438 70.9914 114.399 68.7952 114.532 67.6582C114.67 66.5269 115.222 64.397 117.407 62.8737C120.061 61.0361 122.682 61.7203 123.692 62.101C124.696 62.4817 127.124 63.701 127.892 66.8413L128.052 67.4982C128.504 69.3302 128.813 71.1787 128.995 73.0328L126.418 74.7876C123.366 76.8737 119.211 76.3603 116.75 73.5956Z" fill="url(#paint20_linear_1617_6575)"/>
<path d="M102.484 85.1851L103.031 85.8006C108.712 92.1894 116.993 95.1296 125.035 94.2722C130.696 86.1446 128.994 73.0308 128.994 73.0308L126.419 74.7884C123.366 76.8731 119.21 76.3621 116.752 73.5974L116.248 73.0308H96.5508C97.3776 77.5023 99.4021 81.7192 102.484 85.1851Z" fill="url(#paint21_linear_1617_6575)"/>
<path d="M158.488 56.4359V72.8227C158.488 74.2627 157.777 75.6095 156.588 76.4213L147.203 82.8274C147.203 82.8274 148.434 72.6904 145.531 61.7366L155.56 54.8895C156.803 54.0415 158.488 54.9314 158.488 56.4359Z" fill="url(#paint22_linear_1617_6575)"/>
</g>
<defs>
<linearGradient id="paint0_linear_1617_6575" x1="82.5113" y1="115.83" x2="63.1937" y2="136.814" gradientUnits="userSpaceOnUse">
<stop stopColor="#9B62CD"/>
<stop offset="0.3003" stopColor="#985DCB"/>
<stop offset="0.6716" stopColor="#8F4EC7"/>
<stop offset="1" stopColor="#833AC1"/>
</linearGradient>
<linearGradient id="paint1_linear_1617_6575" x1="73.988" y1="161.036" x2="59.0003" y2="189.013" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF819C"/>
<stop offset="0.2594" stopColor="#FF7E9A"/>
<stop offset="0.4757" stopColor="#FE7396"/>
<stop offset="0.6765" stopColor="#FC628E"/>
<stop offset="0.8668" stopColor="#FA4A83"/>
<stop offset="1" stopColor="#F83479"/>
</linearGradient>
<linearGradient id="paint2_linear_1617_6575" x1="81.3217" y1="74.4109" x2="63.3363" y2="99.8899" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF819C"/>
<stop offset="0.2594" stopColor="#FF7E9A"/>
<stop offset="0.4757" stopColor="#FE7396"/>
<stop offset="0.6765" stopColor="#FC628E"/>
<stop offset="0.8668" stopColor="#FA4A83"/>
<stop offset="1" stopColor="#F83479"/>
</linearGradient>
<linearGradient id="paint3_linear_1617_6575" x1="190.603" y1="92.6098" x2="176.596" y2="107.824" gradientUnits="userSpaceOnUse">
<stop stopColor="#9B62CD"/>
<stop offset="0.3003" stopColor="#985DCB"/>
<stop offset="0.6716" stopColor="#8F4EC7"/>
<stop offset="1" stopColor="#833AC1"/>
</linearGradient>
<linearGradient id="paint4_linear_1617_6575" x1="183.11" y1="32.3738" x2="172.452" y2="47.3615" gradientUnits="userSpaceOnUse">
<stop stopColor="#9B62CD"/>
<stop offset="0.3003" stopColor="#985DCB"/>
<stop offset="0.6716" stopColor="#8F4EC7"/>
<stop offset="1" stopColor="#833AC1"/>
</linearGradient>
<linearGradient id="paint5_linear_1617_6575" x1="184.422" y1="125.388" x2="173.555" y2="145.674" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD945"/>
<stop offset="0.3043" stopColor="#FFCD3E"/>
<stop offset="0.8558" stopColor="#FFAD2B"/>
<stop offset="1" stopColor="#FFA325"/>
</linearGradient>
<linearGradient id="paint6_linear_1617_6575" x1="188.964" y1="59.5829" x2="174.765" y2="79.6977" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD945"/>
<stop offset="0.3043" stopColor="#FFCD3E"/>
<stop offset="0.8558" stopColor="#FFAD2B"/>
<stop offset="1" stopColor="#FFA325"/>
</linearGradient>
<linearGradient id="paint7_linear_1617_6575" x1="14.4212" y1="33.788" x2="37.0492" y2="64.0847" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD945"/>
<stop offset="0.3043" stopColor="#FFCD3E"/>
<stop offset="0.8558" stopColor="#FFAD2B"/>
<stop offset="1" stopColor="#FFA325"/>
</linearGradient>
<linearGradient id="paint8_linear_1617_6575" x1="211.622" y1="162.264" x2="234.25" y2="192.56" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD945"/>
<stop offset="0.3043" stopColor="#FFCD3E"/>
<stop offset="0.8558" stopColor="#FFAD2B"/>
<stop offset="1" stopColor="#FFA325"/>
</linearGradient>
<linearGradient id="paint9_linear_1617_6575" x1="52.3094" y1="20.7805" x2="67.2595" y2="40.7968" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF7044"/>
<stop offset="1" stopColor="#F82814"/>
</linearGradient>
<linearGradient id="paint10_linear_1617_6575" x1="52.2722" y1="206.891" x2="67.2223" y2="226.907" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD945"/>
<stop offset="0.3043" stopColor="#FFCD3E"/>
<stop offset="0.8558" stopColor="#FFAD2B"/>
<stop offset="1" stopColor="#FFA325"/>
</linearGradient>
<linearGradient id="paint11_linear_1617_6575" x1="30.9042" y1="95.5461" x2="45.8538" y2="115.562" gradientUnits="userSpaceOnUse">
<stop stopColor="#00ADFB"/>
<stop offset="1" stopColor="#3F3CED"/>
</linearGradient>
<linearGradient id="paint12_linear_1617_6575" x1="210.853" y1="118.204" x2="225.803" y2="138.22" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF7044"/>
<stop offset="1" stopColor="#F82814"/>
</linearGradient>
<linearGradient id="paint13_linear_1617_6575" x1="92.5254" y1="42.1811" x2="73.3226" y2="21.1726" gradientUnits="userSpaceOnUse">
<stop stopColor="#92F37F"/>
<stop offset="1" stopColor="#4AB272"/>
</linearGradient>
<linearGradient id="paint14_linear_1617_6575" x1="108.592" y1="168.668" x2="127.612" y2="151.283" gradientUnits="userSpaceOnUse">
<stop stopColor="#92F37F"/>
<stop offset="1" stopColor="#4AB272"/>
</linearGradient>
<linearGradient id="paint15_linear_1617_6575" x1="217.326" y1="63.634" x2="236.346" y2="46.2486" gradientUnits="userSpaceOnUse">
<stop stopColor="#92F37F"/>
<stop offset="1" stopColor="#4AB272"/>
</linearGradient>
<linearGradient id="paint16_linear_1617_6575" x1="27.9478" y1="198.619" x2="5.93182" y2="174.534" gradientUnits="userSpaceOnUse">
<stop stopColor="#92F37F"/>
<stop offset="1" stopColor="#4AB272"/>
</linearGradient>
<linearGradient id="paint17_linear_1617_6575" x1="141.527" y1="154.008" x2="169.018" y2="234.698" gradientUnits="userSpaceOnUse">
<stop stopColor="#00ADFB"/>
<stop offset="1" stopColor="#3F3CED"/>
</linearGradient>
<linearGradient id="paint18_linear_1617_6575" x1="175.856" y1="178.055" x2="151.759" y2="174.831" gradientUnits="userSpaceOnUse">
<stop stopColor="#3F3CED" stopOpacity="0"/>
<stop offset="1" stopColor="#2F43BB"/>
</linearGradient>
<linearGradient id="paint19_linear_1617_6575" x1="126.502" y1="198.451" x2="153.144" y2="192.342" gradientUnits="userSpaceOnUse">
<stop stopColor="#3F3CED" stopOpacity="0"/>
<stop offset="1" stopColor="#2F43BB"/>
</linearGradient>
<linearGradient id="paint20_linear_1617_6575" x1="120.778" y1="45.1999" x2="142.813" y2="109.879" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF7044"/>
<stop offset="1" stopColor="#F82814"/>
</linearGradient>
<linearGradient id="paint21_linear_1617_6575" x1="117.983" y1="82.8519" x2="130.951" y2="84.5075" gradientUnits="userSpaceOnUse">
<stop stopColor="#F82814" stopOpacity="0"/>
<stop offset="1" stopColor="#C0272D"/>
</linearGradient>
<linearGradient id="paint22_linear_1617_6575" x1="155.65" y1="66.7352" x2="146.361" y2="71.3337" gradientUnits="userSpaceOnUse">
<stop sstopColor="#F82814" stopOpacity="0"/>
<stop offset="1" stopColor="#C0272D"/>
</linearGradient>
<clipPath id="clip0_1617_6575">
<rect width="240" height="240" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default IconConfetti;
