import React from "react";
import { Button } from "antd";
import classNames from "classnames";
import "../styles.scss";

const SwitchButton = props => {
  const { icon, isActive, ...otherProps } = props;

  return (
    <Button
        className={classNames("switch-button", {"active": isActive })}
        icon={icon}
        {...otherProps}
    >
      <span className="button__text">{props.children}</span>
    </Button>
  );
};

export default SwitchButton;