const DATE_OPTIONS = {
  dayAndMonth: { month: "numeric", day: "numeric" },
  time: { hour: "numeric", minute: "2-digit" },
  timeZone: { timeZoneName: "short" },
  weekDay: { weekday: "long" },
};

const DAYS_ON_HIHOME_FORMAT = "days";

const FIRST_ROW_CRITERIA = ["price", "bedrooms", "bathrooms", "lot_size"];

const HOUSE_PROPERTY_TYPES = {
  apartment: "dashboard.form.apartmentsMessage",
  condo: "dashboard.form.condosMessage",
  farm: "dashboard.form.farmsMessage",
  land: "dashboard.form.landMessage",
  mobile: "dashboard.form.mobileHomesMessage",
  multi_family: "dashboard.form.multifamilyMessage",
  single_family: "dashboard.form.housesMessage",
  townhouse: "dashboard.form.townhomesMessage",
};

const HOUSE_STATUS_CLASSNAMES = {
  active: "status-green",
  under_contract: "status-orange",
  default: "status-grey",
};

const IMAGE_EXTENSION = ".jpg";

const VALUE_KEY = {
  "bikeability": "value",
  "commute": "value",
  "distance": "value",
  "low_crime": "label",
  "quietness": "label",
  "transit": "value",
  "walkability": "value",
};

const PLACE_DESCRIPTION_COLOR_MAP = {
  grocery: "salmon-value",
  park: "green-light-value",
  restaurant: "orange-light-value",
  school: "blue-light-value",
  transit_station: "purple-light-value",
  default: "grey2-value",
};

const QUIETNESS_STATUSES = {
  "Calm": "green-value",
  "Active": "yellow-value",
  "Busy": "red-value",
  default: "grey-value"
};

const lookup = [
  { value: 1e6, symbol: "M", digits: 2 },
  { value: 1e3, symbol: "k", digits: 0 },
  { value: 1, symbol: "", digits: 0 },
];

const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

export {
  DATE_OPTIONS,
  DAYS_ON_HIHOME_FORMAT,
  FIRST_ROW_CRITERIA,
  HOUSE_PROPERTY_TYPES,
  HOUSE_STATUS_CLASSNAMES,
  IMAGE_EXTENSION,
  PLACE_DESCRIPTION_COLOR_MAP,
  QUIETNESS_STATUSES,
  VALUE_KEY,
  lookup,
  rx
};
