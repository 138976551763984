import React from "react";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";
import { PLACE_KIND_MESSAGES } from "containers/HouseDetails/Sections/NeighborhoodInfoSection/constants";
import { getHouseParameterValue } from "utils/helpers/housesHelper/getter";
import { PLACE_DESCRIPTION_COLOR_MAP } from "utils/helpers/housesHelper/constants";
import "./styles.scss";

const PlaceDescriptionView = props => {
  const { kind, isDataExist, isMap, item } = props;
  const itemKind = kind || item.kind || null;
  const kindClassname = isMap ? PLACE_DESCRIPTION_COLOR_MAP[itemKind] : PLACE_DESCRIPTION_COLOR_MAP.default;

  const getTransitStations = (item, defaultKindMessageId) => {
    const transitTypesExist =
      _.has(item, "transit_type") &&
      _.isArray(item.transit_type) &&
      !_.isEmpty(item.transit_type);
    if (transitTypesExist) {
      return (
        <div className="transit-kinds">
          {item.transit_type.map((transitType, index) => {
            const formattedTransitType =  <FormattedMessage id={PLACE_KIND_MESSAGES[transitType]} />;
            if (index === item.transit_type.length - 1) {
              return formattedTransitType;
            }
            return <div>{formattedTransitType},&nbsp;</div>;
          })}
        </div>
      );
    }
    return <FormattedMessage id={defaultKindMessageId} />;
  };

  const getPlaceKind = item => {
    if (itemKind) {
      const messageId = PLACE_KIND_MESSAGES[itemKind];
      if (messageId) {
        if (itemKind === "school") {
          let value = _.has(item, "grades") && (item.grades.low || item.grades.high) ? (
            `${item.grades.low || ""}-${item.grades.high || ""}`
          ) : null;
          value = getHouseParameterValue(value);
          return <FormattedMessage id={messageId} values={{ value }} />;
        } else if (itemKind === "transit_station") {
          return getTransitStations(item, messageId);
        }
        return <FormattedMessage id={messageId} />;
      }
      return _.capitalize(itemKind);
    }
    return null;
  };

  return (
    <div className={classNames("place-description", { isMap })}>
      <div className={classNames("place-name", { isMap })}>
        {isDataExist && !isMap ? (
          <Tooltip placement="bottomLeft" title={item.name}>
            {item.name}
          </Tooltip>
        ) : (
          item.name
        )}
      </div>
      <div className="place-common">
        <div className={`place-common__kind ${kindClassname}`}>
          {getPlaceKind(item)}
        </div>
        <div className="place-common__distance">
          <FormattedMessage id="houseDetails.distanceInMiles" values={{ value: item.distance_in_miles.toFixed(2) }} />
        </div>
      </div>
    </div>
  );
};

PlaceDescriptionView.propTypes = {
  kind: PropTypes.string,
  isDataExist: PropTypes.bool,
  isMap: PropTypes.bool,
  item: PropTypes.object,
};

export default PlaceDescriptionView;