import React from "react";
import { Switch } from "antd";
import './styles.scss';

const Toggle = (props) => (
  <div className="toggle-wrapper">
    <Switch
      {...props}
    />
  </div>
);

export default Toggle;