import React from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

const getModalTitle = (data, key) => _.has(data, key) ? <FormattedMessage id={data[key]} /> : null;

const localStorageStrToObjectOrNull = key => {
  const data = localStorage.getItem(key);
  if (_.isNull(data)) {
    return null;
  }
  return JSON.parse(data);
};

const localStorageStrToObject = key => {
  const data = localStorage.getItem(key);
  if (_.isNull(data)) {
    return {};
  }
  return JSON.parse(data);
};

const localStorageStrToNumberOrNull = key => {
  const data = localStorage.getItem(key);
  if (_.isNull(data)) {
    return null;
  }
  const stepNumber = parseInt(data);
  return _.isNumber(stepNumber) ? stepNumber : null;
};

const setObjectToLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export {
  getModalTitle,
  localStorageStrToObjectOrNull,
  localStorageStrToNumberOrNull,
  localStorageStrToObject,
  setObjectToLocalStorage,
};
