import React from 'react';
import HelmetIntl from 'components/Meta/HelmetIntl';
import SignUp from 'containers/SignUp';

const SignUpPage = () => (
  <>
    <HelmetIntl id="helmet.signUpTitle" />
    <SignUp />
  </>
);

export default SignUpPage;
