import _ from "lodash";

const formatLocationsFilter = criteriaValues => {
   const values = [];
   criteriaValues.forEach(item => {
     const location_criteria = {
       city: item.city,
       state: item.state,
       postal_code: item.postal_code,
       lat: item.latitude,
       lon: item.longitude,
       gid: item.gid,
     };
     values.push(location_criteria);
   });

   return values;
};

const formatRangeFilter = criteriaValues => {
  const filterValues = { ideal: criteriaValues.ideal, priority: criteriaValues.priority };
  ['min', 'max'].forEach(parameter => {
    if (_.isObject(criteriaValues) &&
        _.keys(criteriaValues).includes(parameter) &&
        !_.isNull(criteriaValues[parameter])) {
      _.assign(filterValues, {[parameter]: criteriaValues[parameter]});
    }});
  return filterValues;
};

const formatDistanceFilter = criteriaValues => (
  {
    "kind": criteriaValues.kind,
    "max": criteriaValues.max,
    "ideal": criteriaValues.ideal,
    "priority": criteriaValues.priority,
    "transport_type": criteriaValues.transport_type,
    "location": {
      "lat": criteriaValues.latitude,
      "lon": criteriaValues.longitude,
      "address": criteriaValues.address,
      "city": criteriaValues.city,
      "state": criteriaValues.state,
      "postal_code": criteriaValues.postal_code,
    }
  }
)

const formatProximityFilter = criteriaValues => {
  ['min', 'ideal', 'max'].forEach(parameter => {
    criteriaValues[parameter] = criteriaValues[parameter] * 60;
  });
  return criteriaValues;
}

const FORMAT_MAPPINGS = {
  prop_type: (criteria_item) => criteria_item.values,
  locations: formatLocationsFilter,
  commute: formatDistanceFilter,
  custom_distance: formatDistanceFilter,
  grocery: formatProximityFilter,
  parks: formatProximityFilter,
  default: formatRangeFilter,
};

export const formatSearchParams = criteria => {
  const searchParams = {};
  _.entries(criteria).forEach(([criteria_key, criteria_item]) => {
    const criteriaKey = criteria_key === 'crime' ? 'low_crime' : criteria_key;
    searchParams[criteriaKey] = criteria_item;
    const isItemExist = _.isArray(criteria_item) ? !_.isEmpty(criteria_item) : !_.isNull(criteria_item);
    if (isItemExist) {
      const formatMethod = FORMAT_MAPPINGS[criteriaKey] || FORMAT_MAPPINGS.default;
      searchParams[criteriaKey] = formatMethod(criteria_item);
    }
  });
  return searchParams;
}

export const getActiveFilters = filters => {
  const activeFilters = {}
  Object.keys(filters).forEach(criteria => {
  if (criteria !== "locations" && filters[criteria].priority > 0) {
    activeFilters[criteria] = filters[criteria];
  }
  })
  return btoa(JSON.stringify(activeFilters))
  }
