import React from "react";

const IconInputPassword = (props) => (
  <svg
    width="10"
    height="16"
    {...props}
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.81453 15.7609C7.41 15.7609 9.51382 13.6566 9.51382 11.0611C9.51382 10.0265 9.17947 9.07037 8.61287 8.29387V3.79835C8.61287 1.70373 6.90914 0 4.81453 0C2.71991 0 1.01618 1.70373 1.01618 3.79835V8.29387C0.449584 9.07037 0.115234 10.0265 0.115234 11.0611C0.115234 13.6566 2.21905 15.7609 4.81453 15.7609ZM5.47183 12.1601V13.0387C5.47183 13.402 5.17736 13.696 4.81453 13.696C4.45169 13.696 4.15722 13.402 4.15722 13.0387V12.1601C3.42192 11.8911 2.89563 11.1847 2.89563 10.3574C2.89563 9.29955 3.7567 8.43848 4.81453 8.43848C5.87235 8.43848 6.73342 9.29955 6.73342 10.3574C6.73342 11.1847 6.20714 11.8911 5.47183 12.1601ZM2.33079 3.79835C2.33079 2.42896 3.44514 1.31461 4.81453 1.31461C6.18391 1.31461 7.29826 2.42896 7.29826 3.79835V7.07129C6.57742 6.62169 5.72643 6.36183 4.81453 6.36183C3.90263 6.36183 3.05163 6.62169 2.33079 7.07129V3.79835Z"
      fill="#282C4D"
    />
  </svg>
);

export default IconInputPassword;
