import React from "react";

const IconExclamationPoint = (props) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.05095 10C7.4947 10 7.04459 9.5531 7.04459 9V4C7.04459 3.4469 7.4947 3 8.05095 3C8.60721 3 9.05732 3.4469 9.05732 4V9C9.05732 9.5531 8.60721 10 8.05095 10Z"
      fill="#FFFFFF"
    />
    <path
      d="M9.05732 12C9.05732 11.4478 8.60721 11 8.05095 11C7.4947 11 7.04459 11.4478 7.04459 12C7.04459 12.5522 7.4947 13 8.05095 13C8.60721 13 9.05732 12.5522 9.05732 12Z"
      fill="#FFFFFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05095 16C10.2023 16 12.2238 15.1687 13.7432 13.6562C15.2656 12.1469 16.1019 10.1375 16.1019 8C16.1019 5.86255 15.2656 3.8562 13.7432 2.34375C12.2238 0.831299 10.2023 0 8.05095 0C5.89964 0 3.88101 0.831299 2.35868 2.34375C0.836348 3.85315 0 5.86255 0 8C0 10.1375 0.836348 12.1469 2.35868 13.6562C3.87806 15.1687 5.89964 16 8.05095 16ZM2.01274 8C2.01274 4.69067 4.7203 2 8.05095 2C11.3816 2 14.0892 4.69067 14.0892 8C14.0892 11.3093 11.3816 14 8.05095 14C4.7203 14 2.01274 11.3093 2.01274 8Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default IconExclamationPoint;
