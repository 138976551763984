import React from 'react';
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Form } from "antd";
import _ from "lodash";
import { SimpleButton } from "components/Controls/Buttons";
import {
  BathroomsForm,
  BedroomsForm,
  DistanceForm,
  GroceryForm,
  HomeTypeForm,
  LotSizeForm,
  ParksForm,
  PriceRangeForm,
  SearchLocationForm,
  SquareFootageForm,
} from "components/Forms";
import { IconClose } from "components/Svgs";
import { DATA_KIND } from "constants/common";
import {CRITERIA_MAP, NON_PRIORITY_CRITERIA} from "constants/criteria";
import { PRIORITY_CRITERIA_LIST } from "containers/Houses/Views/AllCriteriaModalView/config";
import { formatCriteriaData } from "utils/helpers/criteriaHelper";
import { CRITERIA_MESSAGE_MAP, CRITERIA_ICON_MAP } from "../config";

const FORMS = {
  [CRITERIA_MAP.bathrooms]: {
    form: BathroomsForm,
    formName: "BathroomsForm",
    key: CRITERIA_MAP.bathrooms,
    defaultValue: {}
  },
  [CRITERIA_MAP.bedrooms]: {
    form: BedroomsForm,
    formName: "BedroomsForm",
    key: CRITERIA_MAP.bedrooms,
    defaultValue: {}
  },
  [CRITERIA_MAP.prop_type]: {
    form: HomeTypeForm,
    formName: "HomeTypeForm",
    key: CRITERIA_MAP.prop_type,
    defaultValue: {}
  },
  [CRITERIA_MAP.location]: {
    form: SearchLocationForm,
    formName: "LocationsForm",
    key: CRITERIA_MAP.location,
    defaultValue: []
  },
  [CRITERIA_MAP.custom_distance]: {
    form: DistanceForm,
    formName: "CustomDistanceForm",
    key: CRITERIA_MAP.custom_distance,
    defaultValue: {}
  },
  [CRITERIA_MAP.commute]: {
    form: DistanceForm,
    formName: "CommuteDistanceForm",
    key: CRITERIA_MAP.commute,
    defaultValue: {}
  },
  [CRITERIA_MAP.grocery]: {
    form: GroceryForm,
    formName: "GroceryForm",
    key: CRITERIA_MAP.grocery,
    defaultValue: {}
  },
  [CRITERIA_MAP.price]: {
    form: PriceRangeForm,
    formName: "PriceRangeForm",
    key: CRITERIA_MAP.price,
    defaultValue: {}
  },
  [CRITERIA_MAP.parks]: {
    form: ParksForm,
    formName: "ParksForm",
    key: CRITERIA_MAP.parks,
    defaultValue: {}
  },
  [CRITERIA_MAP.building_size]: {
    form: SquareFootageForm,
    formName: "SquareFootageForm",
    key: CRITERIA_MAP.building_size,
    defaultValue: {}
  },
  [CRITERIA_MAP.lot_size]: {
    form: LotSizeForm,
    formName: "LotSizeForm",
    key: CRITERIA_MAP.lot_size,
    defaultValue: {}
  },
};

const CriterionFilter = ({ activeCriterion, currentStepData, onActiveCriterionChange, setCurrentStepData }) => {
  const getInitialData = formData => {
    if (formData) {
      const initialData = currentStepData[formData.key];
      return initialData || formData.defaultValue || null;
    }
    return null;
  };

  const onSave = formData => {
    const dataToSave = _.cloneDeep(formData);
    if (!NON_PRIORITY_CRITERIA.includes(formData.kind)) {
      const priority = currentStepData[formData.kind]?.priority;
      dataToSave.priority = priority || 0;
    }
    const newCurrentStepData = formatCriteriaData(dataToSave, currentStepData);
    setCurrentStepData(newCurrentStepData);
    onActiveCriterionChange(activeCriterion);
  };

  const onUnselectCriteriaClick = () => {
    let newCurrentStepData = _.cloneDeep(currentStepData);
    if (_.has(newCurrentStepData, activeCriterion)) {
      newCurrentStepData = _.omit(newCurrentStepData, activeCriterion);
      setCurrentStepData(newCurrentStepData);
      onActiveCriterionChange(activeCriterion);
    }
  };

  const renderHeader = () => {
    const Icon = CRITERIA_ICON_MAP[activeCriterion];
    return (
      <div>
        <div className="filters-title fs-16">
          <div className="filters-icon">
            <Icon />
          </div>
          <FormattedMessage id={CRITERIA_MESSAGE_MAP[activeCriterion]} />
          <SimpleButton
            customClass="filters-close"
            onClick={() => onActiveCriterionChange(activeCriterion)}
          >
            <IconClose />
          </SimpleButton>
        </div>
        {activeCriterion === CRITERIA_MAP.location && (
          <div className="location-search__sub-title">
            <FormattedMessage id="dashboard.form.locationMA" />
          </div>
        )}
      </div>
    );
  };

  const renderForm = () => {
    const formData = FORMS[activeCriterion];
    const CriteriaForm = formData.form || formData;
    return (
      <CriteriaForm
        dataKind={DATA_KIND.topMatches}
        formName={_.has(formData, "formName") ? formData.formName : null}
        initialData={getInitialData(formData)}
        kind={activeCriterion}
        onSubmitForm={onSave}
      />
    );
  };

  const renderPriorityContent = () => {
    const Icon = CRITERIA_ICON_MAP[activeCriterion];
    return (
      <div className="priority-criterion-content">
        <div className="priority-criterion-content__icon">
          <Icon />
        </div>
        <span className="priority-criterion-content__text">
          <FormattedMessage
            id="houses.filtersBar.allCriteriaModal.priorityCriteriaText"
            values={{ value: <FormattedMessage id={CRITERIA_MESSAGE_MAP[activeCriterion]} /> }}
          />
        </span>
      </div>
    );
  };

  const renderRemoteSubmitButton = () => {
    const formData = FORMS[activeCriterion];
    return !_.isNil(formData.formName) && (
      <div className="remote-submit-button-wrapper">
        <div className="btn-default-position">
          <Form.Item>
            <SimpleButton htmlType="submit" customClass="btn-default" form={formData.formName}>
              <FormattedMessage id="general.saveAndClose" />
            </SimpleButton>
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderFooter = () => (
    <div className="footer">
      <SimpleButton
        customClass="unselect-criteria-button"
        disabled={!_.has(currentStepData, activeCriterion)}
        htmlType="button"
        onClick={onUnselectCriteriaClick}
      >
        <FormattedMessage id="houses.filtersBar.allCriteriaModal.unselectCriteria" />
      </SimpleButton>
      {!PRIORITY_CRITERIA_LIST.includes(activeCriterion) && renderRemoteSubmitButton()}
    </div>
  );

  return (
    <div className="criterion-filter-container" key={activeCriterion}>
      {renderHeader()}
      {PRIORITY_CRITERIA_LIST.includes(activeCriterion) ? renderPriorityContent() : renderForm()}
      {renderFooter()}
    </div>
  );
};

CriterionFilter.propTypes = {
  activeCriterion: PropTypes.string,
  currentStepData: PropTypes.object,
  onActiveCriterionChange: PropTypes.func,
  setCurrentStepData: PropTypes.func,
};

export default CriterionFilter;
