import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { SearchLocationField } from "components/Fields";

const MAX_ITEMS = 10;

const SearchLocationForm = (
  {
    customClassName,
    formName,
    initialData,
    queryParamGid,
    onValuesChange,
    onSubmitForm
  }
) => {
  const [form] = Form.useForm();
  const initialValues = { locations: initialData };

  const onFinish = values => onSubmitForm(values.locations);

  return (
    <Form
      className={customClassName}
      form={form}
      initialValues={initialValues}
      name={formName}
      onFinish={onFinish}
    >
      <Form.Item name="locations">
        <SearchLocationField
          form={form}
          maxItemsNumber={MAX_ITEMS}
          title={<FormattedMessage id="dashboard.form.locationTitle" />}
          queryParamGid={queryParamGid}
          onValuesChange={onValuesChange}
        />
      </Form.Item>
    </Form>
  );
};

SearchLocationForm.propTypes = {
  customClassName: PropTypes.string,
  formName: PropTypes.string,
  initialData: PropTypes.array,
  queryParamGid: PropTypes.string,
  onValuesChange: PropTypes.func,
  onSubmitForm: PropTypes.func,
};

export default SearchLocationForm;
