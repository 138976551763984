import React from "react";
import { Image } from 'antd';
import classNames from "classnames";
import PropTypes from "prop-types";
import "./styles.scss";

const CustomImage = (props) => {
  const { customClassName, height, image, width, withRadius, ...otherProps } = props;
  return (
    <div className={classNames(customClassName, "custom-image", {"with-radius": withRadius })}>
      <Image
        width={width}
        height={height}
        src={image}
        {...otherProps}
      />
    </div>
  );
}

CustomImage.propTypes = {
  customClassName: PropTypes.string,
  height: PropTypes.number,
  image: PropTypes.string,
  width: PropTypes.number,
  withRadius: PropTypes.bool,
};

export default CustomImage;
