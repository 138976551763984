import {
  SEARCH_HOUSE_PROPERTIES_PATH,
  SEARCH_HOUSE_PROPERTIES_MAP_PATH,
  SEARCH_HOUSE_DETAILS_PATH,
  SEARCH_HOUSE_FAVORITES_PATH,
  SEARCH_HOUSE_FAVORITES_MAP_PATH,
  SEARCH_FAVORITES_PATH,
  USE_MOCKS,
  SEARCH_RECENTLY_VIEWED_PATH
} from 'constants/apiUrls';
import { post, del } from 'utils/requests';
import {HOUSES_MOCK} from "../constants";

export const getHousesApi = params => {
  if (USE_MOCKS) {
    return new Promise(resolve => {
        setTimeout(() => {
          resolve({ data: HOUSES_MOCK });
        }, 300);
      })
  }

  return post(SEARCH_HOUSE_PROPERTIES_PATH, params);
}

export const getHousesMapApi = params => {
  if (USE_MOCKS) {
    return new Promise(resolve => {
        setTimeout(() => {
          resolve({ data: HOUSES_MOCK });
        }, 300);
      })
  }
  return post(SEARCH_HOUSE_PROPERTIES_MAP_PATH, params);
}

export const getHouseDetailsApi = params => {
  if (USE_MOCKS) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ data: HOUSES_MOCK.data[0] });
      }, 300);
    })
  }
  return post(`${SEARCH_HOUSE_DETAILS_PATH}/${params.id}/?filters=${params.filters}/`);
}

export const getHousesFavoritesApi = (params) => post(SEARCH_HOUSE_FAVORITES_PATH, params);
export const getHousesFavoritesMapApi = (params) => post(SEARCH_HOUSE_FAVORITES_MAP_PATH, params);

export const postHousesFavoritesApi = params => post(SEARCH_FAVORITES_PATH, params);
export const delHousesFavoritesApi = params => del(SEARCH_FAVORITES_PATH, params);

export const postHousesRecentlyViewedApi = params => post(SEARCH_RECENTLY_VIEWED_PATH, params);
