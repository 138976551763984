import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";
import { CustomModal } from "components/Modals";
import { SimpleButton } from "components/Controls/Buttons";
import { IconArrowLeft, IconClose } from "components/Svgs";
import { CRITERIA_MAP } from "constants/criteria";
import { SWITCHER_VIEW } from "containers/Houses/constants";
import { ALL_CRITERIA_MODAL_STEPS } from "./config";
import "./styles.scss";

const AllCriteriaModalView = (
  {
    activeTab,
    criteriaData,
    criteriaStore,
    housesStore,
    getHouseResults,
    onChangeIsVisible
  }
) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepData, setCurrentStepData] = useState({});
  const currentItem = ALL_CRITERIA_MODAL_STEPS[currentStep];
  const Step = currentItem.content;

  useEffect(() => {
    setCurrentStepData(criteriaData);
  }, []);

  const checkIsRightButtonDisabled = () => {
    const criteriaWithPriority = _.omit(currentStepData, [CRITERIA_MAP.location, CRITERIA_MAP.prop_type]);
    if (currentStep === 0) {
      return _.isEmpty(criteriaWithPriority);
    }
    return Object.values(criteriaWithPriority).some(item => item.priority === 0);
  };

  const onClose = () => {
    onChangeIsVisible();
    setCurrentStep(0);
    setCurrentStepData({});
  };

  const onFooterRightButtonClick = () => {
    if (currentStep === 0) {
      setCurrentStep(1);
    } else {
      onSaveAndClose();
    }
  };

  const onGetResultsSuccess = () => {
    if (activeTab === SWITCHER_VIEW.map) {
      housesStore.setCurrentPage(1);
    }
  };

  const onSaveAndClose = () => {
    if (_.isEqual(criteriaData, currentStepData)) {
      onClose();
    } else {
      const handleSuccess = () => {
        getHouseResults(currentStepData, activeTab === SWITCHER_VIEW.map, onGetResultsSuccess);
        onClose();
      };
      criteriaStore.postSearchCriteriaRequest({ data: currentStepData }, handleSuccess);
    }
  };

  const renderFooter = () => (
    <div className="all-criteria-modal__footer">
      {currentItem.isShowBackButton && (
        <SimpleButton
          customClass="back-button"
          htmlType="button"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          <div className="back-button-text">
            <div className="back-button-icon">
              <IconArrowLeft />
            </div>
            <FormattedMessage id="general.back" />
          </div>
        </SimpleButton>
      )}
      <SimpleButton
        customClass="right-button"
        disabled={checkIsRightButtonDisabled()}
        onClick={onFooterRightButtonClick}
      >
        <FormattedMessage id={currentItem.footerRightButtonTitle} />
      </SimpleButton>
    </div>
  );

  const renderTitle = () => (
    <div className="all-criteria-modal__title">
      <FormattedMessage id={currentItem.title} />
    </div>
  );

  return (
    <CustomModal
      customClassName="all-criteria-modal"
      isVisible
      width="94%"
    >
      <SimpleButton customClass="modal-close" onClick={onSaveAndClose}>
        <IconClose />
      </SimpleButton>
      {renderTitle()}
      <div className="all-criteria-modal__body">
        <Step
          currentStepData={currentStepData}
          setCurrentStepData={setCurrentStepData}
        />
      </div>
      {renderFooter()}
    </CustomModal>
  );
};

AllCriteriaModalView.propTpyes = {
  activeTab: PropTypes.string,
  criteriaData: PropTypes.object,
  criteriaStore: PropTypes.any,
  housesStore: PropTypes.any,
  isVisible: PropTypes.bool,
  getHouseResults: PropTypes.func,
  onChangeIsVisible: PropTypes.func,
};

export default AllCriteriaModalView;
