import React from "react";
import { Form, Checkbox } from "antd";
import PropTypes from "prop-types";
import { get as lodashGet } from "lodash";
import { FormattedMessage } from "react-intl";
import CustomCheckbox from "components/Controls/Checkboxes/CustomCheckbox";
import "../../styles.scss";


const HOUSE_PROPERTY_TYPES = [
  { key: "single_family", value: "single_family", message_id: "dashboard.form.housesMessage" },
  { key: "townhouse", value: "townhouse", message_id: "dashboard.form.townhomesMessage" },
  { key: "condo", value: "condo", message_id: "dashboard.form.condosMessage" },
  { key: "multi_family", value: "multi_family", message_id: "dashboard.form.multifamilyMessage" },
  { key: "apartment", value: "apartment", message_id: "dashboard.form.apartmentsMessage" },
  { key: "mobile", value:  "mobile", message_id: "dashboard.form.mobileHomesMessage" },
  { key: "farm", value: "farm", message_id: "dashboard.form.farmsMessage"  },
  { key: "land", value: "land", message_id: "dashboard.form.landMessage" },
];

const HomeTypeForm = ({ formName, initialData, kind, onSubmitForm, onValuesChange }) => {
  const [form] = Form.useForm();
  const initialValues = { values: lodashGet(initialData, "values") };

  const onChange = checkboxValues => (
    onValuesChange && onValuesChange({ [kind]: { values: checkboxValues.values, kind } })
  );

  const onFinish = formValues => onSubmitForm({ values: formValues.values, kind });

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name={formName}
      onFinish={onFinish}
      onValuesChange={onChange}
    >
      <Form.Item name="values" className="form-checkbox">
        <Checkbox.Group>
          {HOUSE_PROPERTY_TYPES.map(property_type => (
            <CustomCheckbox key={property_type.key} value={property_type.value}>
              <FormattedMessage id={property_type.message_id} />
            </CustomCheckbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

HomeTypeForm.propTypes = {
  formName: PropTypes.string,
  initialData: PropTypes.object,
  onSubmitForm: PropTypes.func,
  onValuesChange: PropTypes.func,
};

export default HomeTypeForm;
