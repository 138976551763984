import React from "react";
import { Pagination } from "antd";

const CustomPagination = props => (
  <div className="pagination-wrapper">
    <Pagination
      defaultCurrent={1}
      {...props}
    />
  </div>
);

export default CustomPagination;