import React from "react";

const IconBedrooms = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_716_1943)">
      <path
        d="M28.5729 14.0708V6.02071C28.5729 4.384 27.1027 3.14121 25.4884 3.41152C24.983 3.49616 24.4081 3.54818 23.7584 3.54818C20.2776 3.54818 19.5862 1.68188 16.0013 1.68188C12.4164 1.68188 11.725 3.54818 8.24418 3.54818C7.59446 3.54818 7.01959 3.49616 6.51417 3.41152C4.89993 3.14127 3.42969 4.384 3.42969 6.02071V14.0708H28.5729Z"
        fill="url(#paint0_linear_716_1943)"
      />
      <path
        d="M23.7584 5.14925C24.4093 5.14925 25.0359 5.09962 25.6207 5.00167C25.7239 4.98442 25.8285 4.97563 25.9316 4.97563C26.9465 4.97563 27.7722 5.80349 27.7722 6.82109V14.0704H4.23047V6.82109C4.23047 5.80349 5.05619 4.97563 6.0711 4.97563C6.17412 4.97563 6.27872 4.98442 6.38194 5.00167C6.96678 5.09962 7.59329 5.14925 8.24421 5.14925C10.1408 5.14925 11.2811 4.64541 12.3839 4.15813C13.4026 3.70806 14.3647 3.28296 16.0013 3.28296C17.6379 3.28296 18.6 3.70806 19.6186 4.15813"
        fill="url(#paint1_linear_716_1943)"
      />
      <path
        d="M27.4635 13.2699V6.46145C27.4635 5.85327 26.9759 5.3584 26.3767 5.3584C26.3152 5.3584 26.2524 5.36373 26.19 5.37427C25.5334 5.48539 24.8321 5.54167 24.1058 5.54167C21.947 5.54167 20.6158 4.94748 19.4414 4.42312C18.4173 3.96608 17.533 3.57129 15.9993 3.57129C14.4656 3.57129 13.5814 3.96608 12.5574 4.42312C11.3828 4.94748 10.0516 5.54174 7.8929 5.54174C7.16656 5.54174 6.46526 5.48539 5.80857 5.37433C5.74626 5.36379 5.68339 5.35846 5.6219 5.35846C5.02269 5.35846 4.53516 5.85333 4.53516 6.46152V13.2699H27.4635V13.2699Z"
        fill="url(#paint2_linear_716_1943)"
      />
      <path
        d="M25.4884 3.41152C24.983 3.49616 24.4081 3.54818 23.7584 3.54818C20.2776 3.54818 19.5862 1.68188 16.0013 1.68188C12.4164 1.68188 11.725 3.54818 8.24418 3.54818C7.59446 3.54818 7.01959 3.49616 6.51417 3.41152C4.89993 3.14127 3.42969 4.384 3.42969 6.02071V6.4945C3.42969 4.85779 4.89993 3.615 6.51417 3.8853C7.01952 3.96995 7.59446 4.02196 8.24418 4.02196C11.725 4.02196 12.4164 2.15567 16.0013 2.15567C19.5862 2.15567 20.2776 4.02196 23.7584 4.02196C24.4081 4.02196 24.983 3.96995 25.4884 3.8853C27.1026 3.61506 28.5728 4.85779 28.5728 6.4945V6.02071C28.5728 4.384 27.1026 3.14127 25.4884 3.41152Z"
        fill="url(#paint3_linear_716_1943)"
      />
      <path
        d="M4.53906 9.01412V12.5322H27.462V8.70874C26.9992 8.71972 26.5628 8.75122 26.1642 8.80938C22.1216 9.39618 20.8306 10.1302 17.3892 9.39618C16.4894 9.2038 14.9369 9.09218 13.1345 9.03125C10.3724 8.9409 7.02471 8.96894 4.53906 9.01412Z"
        fill="url(#paint4_linear_716_1943)"
      />
      <path
        d="M4.53906 11.1751V12.5322H27.462V10.7458C24.192 10.5322 20.4835 10.4165 19.2971 10.8773C17.0279 11.7585 15.9451 11.4161 12.3864 10.9266C9.82408 10.574 7.17687 10.906 4.53906 11.1751Z"
        fill="url(#paint5_linear_716_1943)"
      />
      <path
        d="M14.7695 9.83276L17.6929 12.7562H14.7695V9.83276Z"
        fill="url(#paint6_linear_716_1943)"
      />
      <path
        d="M25.6484 9.83276L28.5719 12.7562H25.6484V9.83276Z"
        fill="url(#paint7_linear_716_1943)"
      />
      <path
        d="M13.5581 13.8739H7.39311C6.51154 13.8739 5.79688 13.1592 5.79688 12.2777V10.8707C5.79688 9.98908 6.51154 9.27441 7.39311 9.27441H13.5581C14.4396 9.27441 15.1543 9.98908 15.1543 10.8707V12.2777C15.1543 13.1592 14.4396 13.8739 13.5581 13.8739Z"
        fill="url(#paint8_linear_716_1943)"
      />
      <path
        d="M8.27592 10.1604C7.39435 10.1604 6.67969 10.8751 6.67969 11.7566V13.1636C6.67969 13.3739 6.72141 13.5743 6.79533 13.7582C6.97923 13.8321 7.17958 13.8738 7.3899 13.8738H13.5549C14.4364 13.8738 15.1511 13.1592 15.1511 12.2776V10.8706C15.1511 10.6603 15.1094 10.4599 15.0355 10.276C14.8515 10.2021 14.6512 10.1604 14.4409 10.1604H8.27592Z"
        fill="url(#paint9_linear_716_1943)"
      />
      <path
        d="M24.6088 13.8739H18.4439C17.5623 13.8739 16.8477 13.1592 16.8477 12.2777V10.8707C16.8477 9.98908 17.5623 9.27441 18.4439 9.27441H24.6088C25.4904 9.27441 26.2051 9.98908 26.2051 10.8707V12.2777C26.2051 13.1592 25.4904 13.8739 24.6088 13.8739Z"
        fill="url(#paint10_linear_716_1943)"
      />
      <path
        d="M19.3306 10.1604C18.449 10.1604 17.7344 10.8751 17.7344 11.7566V13.1636C17.7344 13.3739 17.7761 13.5743 17.85 13.7582C18.0339 13.8321 18.2343 13.8738 18.4446 13.8738H24.6095C25.4911 13.8738 26.2058 13.1592 26.2058 12.2776V10.8706C26.2058 10.6603 26.164 10.4599 26.0901 10.276C25.9062 10.2021 25.7059 10.1604 25.4956 10.1604H19.3306Z"
        fill="url(#paint11_linear_716_1943)"
      />
      <path
        d="M25.8669 12.5536C19.3111 11.8187 12.6898 11.8187 6.13392 12.5536C4.47399 12.7396 3.0616 13.639 2.20494 14.9137C1.95641 15.2835 2.0041 15.7706 2.32585 16.0827C2.65878 16.4056 3.18408 16.4449 3.56475 16.1761C4.29906 15.6576 5.17511 15.3117 6.13398 15.2042C12.6898 14.4693 19.3111 14.4693 25.867 15.2042C26.8259 15.3117 27.7019 15.6576 28.4362 16.1761C28.8169 16.4449 29.3422 16.4056 29.6751 16.0827C29.9969 15.7705 30.0445 15.2835 29.796 14.9137C28.9393 13.639 27.5268 12.7396 25.8669 12.5536Z"
        fill="url(#paint12_radial_716_1943)"
      />
      <path
        d="M29.954 24.5628H2.04688V18.8775C2.04688 16.2536 4.01375 14.0463 6.62037 13.7452C12.853 13.025 19.1479 13.025 25.3805 13.7452C27.9871 14.0463 29.954 16.2536 29.954 18.8775V24.5628Z"
        fill="url(#paint13_linear_716_1943)"
      />
      <path
        d="M1.34766 25.9487V17.9047C1.34766 17.9047 2.90198 19.6447 6.95851 19.6447C11.015 19.6447 11.8208 17.4697 15.9987 17.4697C20.1766 17.4697 20.9824 19.6447 25.0389 19.6447C29.0954 19.6447 30.6497 17.9047 30.6497 17.9047V25.9486H1.34766V25.9487Z"
        fill="url(#paint14_linear_716_1943)"
      />
      <path
        d="M30.6496 21.9466V25.1704H1.34766V21.6667C1.93934 21.6768 2.49715 21.7056 3.00664 21.759C8.17432 22.2967 9.82452 22.9693 14.2236 22.2967C15.3737 22.1204 17.3583 22.0181 19.6623 21.9623C23.193 21.8795 27.4722 21.9052 30.6496 21.9466Z"
        fill="url(#paint15_linear_716_1943)"
      />
      <path
        d="M30.6496 23.927V25.1706H1.34766V23.5336C5.52774 23.3379 10.2681 23.2318 11.7847 23.6541C14.6854 24.4616 16.0695 24.1479 20.6186 23.6993C23.8939 23.3761 27.2777 23.6804 30.6496 23.927Z"
        fill="url(#paint16_linear_716_1943)"
      />
      <path
        d="M1.34766 24.4814H30.6497V25.9487H1.34766V24.4814Z"
        fill="url(#paint17_linear_716_1943)"
      />
      <path
        d="M6.95851 20.0361C11.015 20.0361 11.8208 17.8611 15.9987 17.8611C20.1766 17.8611 20.9824 20.0361 25.0389 20.0361C29.0954 20.0361 30.6497 18.2961 30.6497 18.2961V17.9047C30.6497 17.9047 29.0954 19.6447 25.0389 19.6447C20.9824 19.6447 20.1766 17.4697 15.9987 17.4697C11.8208 17.4697 11.015 19.6447 6.95851 19.6447C2.90198 19.6447 1.34766 17.9047 1.34766 17.9047V18.2961C1.34766 18.2961 2.90198 20.0361 6.95851 20.0361Z"
        fill="url(#paint18_linear_716_1943)"
      />
      <path
        d="M4.3566 19.3696C2.21831 18.8792 1.34766 17.9048 1.34766 17.9048V25.9486H10.9356L4.3566 19.3696Z"
        fill="url(#paint19_linear_716_1943)"
      />
      <path
        d="M1.34766 18.7534H3.37131V28.0408H1.34766V18.7534Z"
        fill="url(#paint20_linear_716_1943)"
      />
      <path
        d="M3.37137 18.7534H1.34766V20.3679L3.37137 22.3916V18.7534Z"
        fill="url(#paint21_linear_716_1943)"
      />
      <path
        d="M28.6289 18.7534H30.6526V28.0408H28.6289V18.7534Z"
        fill="url(#paint22_linear_716_1943)"
      />
      <path
        d="M30.6526 18.7534H28.6289V20.3679L30.6526 22.3916V18.7534Z"
        fill="url(#paint23_linear_716_1943)"
      />
      <path
        d="M3.53316 30.08H1.19108C0.928051 30.08 0.714844 29.8668 0.714844 29.6038V28.162C0.714844 27.899 0.928051 27.6858 1.19108 27.6858H3.5331C3.79613 27.6858 4.00933 27.899 4.00933 28.162V29.6038C4.0094 29.8668 3.79613 30.08 3.53316 30.08Z"
        fill="url(#paint24_linear_716_1943)"
      />
      <path
        d="M4.2454 30.3184H0.476235C0.213208 30.3184 0 30.1052 0 29.8422V29.5727C0 29.0146 0.452392 28.5623 1.01045 28.5623H3.71118C4.26924 28.5623 4.72163 29.0146 4.72163 29.5727V29.8422C4.72163 30.1052 4.50842 30.3184 4.2454 30.3184Z"
        fill="url(#paint25_linear_716_1943)"
      />
      <path
        d="M3.7096 28.5623H2.65993C2.76453 28.764 2.82401 28.9931 2.82401 29.2361V29.6272C2.82401 30.0089 2.51455 30.3184 2.13281 30.3184H4.24381C4.50684 30.3184 4.72004 30.1051 4.72004 29.8421V29.5726C4.72004 29.0146 4.26765 28.5623 3.7096 28.5623Z"
        fill="url(#paint26_linear_716_1943)"
      />
      <path
        d="M4.72197 29.8419V29.5724C4.72197 29.1177 4.42155 28.7332 4.00843 28.6064V28.162C4.00843 27.899 3.79523 27.6858 3.5322 27.6858H1.80696C1.54393 27.6858 1.33072 27.899 1.33072 28.162V28.6064C0.917548 28.7332 0.617188 29.1177 0.617188 29.5724V29.8419C0.617188 30.1049 0.830395 30.3181 1.09342 30.3181H4.24574C4.50876 30.3181 4.72197 30.1049 4.72197 29.8419Z"
        fill="url(#paint27_linear_716_1943)"
      />
      <path
        d="M30.8105 30.08H28.4684C28.2054 30.08 27.9922 29.8668 27.9922 29.6038V28.162C27.9922 27.899 28.2054 27.6858 28.4684 27.6858H30.8105C31.0735 27.6858 31.2867 27.899 31.2867 28.162V29.6038C31.2867 29.8668 31.0734 30.08 30.8105 30.08Z"
        fill="url(#paint28_linear_716_1943)"
      />
      <path
        d="M31.5227 30.3184H27.7536C27.4906 30.3184 27.2773 30.1052 27.2773 29.8422V29.5727C27.2773 29.0146 27.7297 28.5623 28.2878 28.5623H30.9885C31.5466 28.5623 31.9989 29.0146 31.9989 29.5727V29.8422C31.9989 30.1052 31.7857 30.3184 31.5227 30.3184Z"
        fill="url(#paint29_linear_716_1943)"
      />
      <path
        d="M30.9909 28.5623H29.9412C30.0458 28.764 30.1053 28.9931 30.1053 29.2361V29.6272C30.1053 30.0089 29.7958 30.3184 29.4141 30.3184H31.5251C31.7881 30.3184 32.0013 30.1051 32.0013 29.8421V29.5726C32.0013 29.0146 31.5489 28.5623 30.9909 28.5623Z"
        fill="url(#paint30_linear_716_1943)"
      />
      <path
        d="M31.9993 29.8419V29.5724C31.9993 29.1177 31.6989 28.7332 31.2858 28.6064V28.162C31.2858 27.899 31.0726 27.6858 30.8095 27.6858H29.0843C28.8213 27.6858 28.6081 27.899 28.6081 28.162V28.6064C28.1949 28.7332 27.8945 29.1177 27.8945 29.5724V29.8419C27.8945 30.1049 28.1077 30.3181 28.3708 30.3181H31.5231C31.7861 30.3181 31.9993 30.1049 31.9993 29.8419Z"
        fill="url(#paint31_linear_716_1943)"
      />
      <path
        d="M2.36033 20.6103C3.59055 20.6103 4.58784 19.613 4.58784 18.3828C4.58784 17.1526 3.59055 16.1553 2.36033 16.1553C1.1301 16.1553 0.132812 17.1526 0.132812 18.3828C0.132812 19.613 1.1301 20.6103 2.36033 20.6103Z"
        fill="url(#paint32_linear_716_1943)"
      />
      <path
        d="M2.35901 20.2419C3.38572 20.2419 4.21803 19.4096 4.21803 18.3829C4.21803 17.3562 3.38572 16.5239 2.35901 16.5239C1.33231 16.5239 0.5 17.3562 0.5 18.3829C0.5 19.4096 1.33231 20.2419 2.35901 20.2419Z"
        fill="url(#paint33_linear_716_1943)"
      />
      <path
        d="M3.35624 16.3904C3.50645 16.6902 3.59116 17.0286 3.59116 17.3868C3.59116 18.617 2.59389 19.6144 1.36364 19.6144C1.00543 19.6144 0.667046 19.5296 0.367188 19.3794C0.732929 20.1093 1.48775 20.6105 2.35978 20.6105C3.59003 20.6105 4.5873 19.6132 4.5873 18.383C4.5873 17.5109 4.08615 16.7561 3.35624 16.3904Z"
        fill="url(#paint34_linear_716_1943)"
      />
      <path
        d="M29.6377 20.6103C30.8679 20.6103 31.8652 19.613 31.8652 18.3828C31.8652 17.1526 30.8679 16.1553 29.6377 16.1553C28.4074 16.1553 27.4102 17.1526 27.4102 18.3828C27.4102 19.613 28.4074 20.6103 29.6377 20.6103Z"
        fill="url(#paint35_linear_716_1943)"
      />
      <path
        d="M29.6403 20.2419C30.667 20.2419 31.4993 19.4096 31.4993 18.3829C31.4993 17.3562 30.667 16.5239 29.6403 16.5239C28.6136 16.5239 27.7812 17.3562 27.7812 18.3829C27.7812 19.4096 28.6136 20.2419 29.6403 20.2419Z"
        fill="url(#paint36_linear_716_1943)"
      />
      <path
        d="M30.6375 16.3904C30.7877 16.6902 30.8724 17.0286 30.8724 17.3868C30.8724 18.617 29.8751 19.6144 28.6449 19.6144C28.2867 19.6144 27.9483 19.5296 27.6484 19.3794C28.0142 20.1093 28.769 20.6105 29.641 20.6105C30.8712 20.6105 31.8685 19.6132 31.8685 18.383C31.8685 17.5109 31.3674 16.7561 30.6375 16.3904Z"
        fill="url(#paint37_linear_716_1943)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_716_1943"
        x1="14.8653"
        y1="2.02943"
        x2="17.3674"
        y2="19.4731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_1943"
        x1="16.0013"
        y1="14.1972"
        x2="16.0013"
        y2="-12.3444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_1943"
        x1="15.9993"
        y1="2.74732"
        x2="15.9993"
        y2="20.1113"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_1943"
        x1="14.5933"
        y1="0.460488"
        x2="16.3763"
        y2="7.59254"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_1943"
        x1="16.0005"
        y1="10.099"
        x2="16.0005"
        y2="6.96091"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_716_1943"
        x1="16.0005"
        y1="11.8922"
        x2="16.0005"
        y2="9.97676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_716_1943"
        x1="16.2905"
        y1="12.8262"
        x2="13.9102"
        y2="10.0132"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_716_1943"
        x1="27.1694"
        y1="12.8262"
        x2="24.7892"
        y2="10.0131"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_716_1943"
        x1="10.203"
        y1="10.458"
        x2="10.7406"
        y2="12.6593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB5B0" />
        <stop offset="1" stopColor="#FF70AC" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_716_1943"
        x1="10.4332"
        y1="11.2675"
        x2="7.47719"
        y2="6.67196"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9EDF5" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_716_1943"
        x1="21.2538"
        y1="10.458"
        x2="21.7913"
        y2="12.6593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB5B0" />
        <stop offset="1" stopColor="#FF70AC" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_716_1943"
        x1="21.2881"
        y1="10.9762"
        x2="18.9282"
        y2="7.37427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9EDF5" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_716_1943"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.0596 16.6024) rotate(1.20304) scale(15.4076 4.76079)"
      >
        <stop stopColor="#F0DDFC" />
        <stop offset="0.5895" stopColor="#F1DFFC" />
        <stop offset="0.8016" stopColor="#F3E6FC" />
        <stop offset="0.9525" stopColor="#F7F1FC" />
        <stop offset="1" stopColor="#F9F7FC" />
      </radialGradient>
      <linearGradient
        id="paint13_linear_716_1943"
        x1="14.9719"
        y1="14.9711"
        x2="15.5863"
        y2="18.8815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB5B0" />
        <stop offset="1" stopColor="#FF70AC" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_716_1943"
        x1="15.9987"
        y1="16.8413"
        x2="15.9987"
        y2="29.3082"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_716_1943"
        x1="15.955"
        y1="24.3744"
        x2="16.3211"
        y2="16.3635"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_716_1943"
        x1="15.9986"
        y1="24.5703"
        x2="15.9986"
        y2="20.6426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_716_1943"
        x1="15.9987"
        y1="24.5929"
        x2="15.9987"
        y2="26.3492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_716_1943"
        x1="15.1452"
        y1="21.7277"
        x2="17.4066"
        y2="12.1167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_716_1943"
        x1="6.93329"
        y1="25.077"
        x2="-0.245755"
        y2="20.8239"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_716_1943"
        x1="1.46737"
        y1="23.3971"
        x2="3.5896"
        y2="23.3971"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_716_1943"
        x1="4.87217"
        y1="22.0733"
        x2="1.30135"
        y2="18.5025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_716_1943"
        x1="28.7486"
        y1="23.3971"
        x2="30.8709"
        y2="23.3971"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_716_1943"
        x1="32.1534"
        y1="22.0733"
        x2="28.5826"
        y2="18.5025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_716_1943"
        x1="2.36215"
        y1="28.0064"
        x2="2.36215"
        y2="29.0149"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_716_1943"
        x1="2.36085"
        y1="29.0766"
        x2="2.36085"
        y2="30.795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_716_1943"
        x1="3.42643"
        y1="29.0766"
        x2="3.42643"
        y2="30.795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_716_1943"
        x1="2.44138"
        y1="29.0514"
        x2="-1.38978"
        y2="27.5779"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDAAA" stopOpacity="0" />
        <stop offset="0.2054" stopColor="#FFDFB5" stopOpacity="0.205" />
        <stop offset="0.5703" stopColor="#FFECD4" stopOpacity="0.57" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_716_1943"
        x1="29.6394"
        y1="28.0064"
        x2="29.6394"
        y2="29.0149"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_716_1943"
        x1="29.6382"
        y1="29.0766"
        x2="29.6382"
        y2="30.795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_716_1943"
        x1="30.7077"
        y1="29.0766"
        x2="30.7077"
        y2="30.795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_716_1943"
        x1="29.7187"
        y1="29.0514"
        x2="25.8876"
        y2="27.5779"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDAAA" stopOpacity="0" />
        <stop offset="0.2054" stopColor="#FFDFB5" stopOpacity="0.205" />
        <stop offset="0.5703" stopColor="#FFECD4" stopOpacity="0.57" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_716_1943"
        x1="1.52688"
        y1="17.5494"
        x2="4.41184"
        y2="20.4344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_716_1943"
        x1="3.12036"
        y1="19.0477"
        x2="0.00595568"
        y2="16.3285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDAAA" stopOpacity="0" />
        <stop offset="0.2054" stopColor="#FFDFB5" stopOpacity="0.205" />
        <stop offset="0.5703" stopColor="#FFECD4" stopOpacity="0.57" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_716_1943"
        x1="2.43721"
        y1="19.2366"
        x2="5.49898"
        y2="13.5421"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_716_1943"
        x1="28.8043"
        y1="17.5494"
        x2="31.6892"
        y2="20.4344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_716_1943"
        x1="30.4017"
        y1="19.0477"
        x2="27.2873"
        y2="16.3285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDAAA" stopOpacity="0" />
        <stop offset="0.2054" stopColor="#FFDFB5" stopOpacity="0.205" />
        <stop offset="0.5703" stopColor="#FFECD4" stopOpacity="0.57" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_716_1943"
        x1="29.7184"
        y1="19.2366"
        x2="32.7802"
        y2="13.5421"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <clipPath id="clip0_716_1943">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconBedrooms;
