import React from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MAX_MINUTES } from "constants/criteria";
import { PriorityField, SliderIdealMaxInputField } from "components/Fields";
import { AddressField, MovementTypeField } from "components/Fields";
import { IconBedrooms } from "components/Svgs";
import { getInitialDistanceValues, onCriteriaRangeFormFinish } from "utils/helpers/criteriaHelper";
import "../../styles.scss";

const DEFAULT_VALUES = {
  min: 10,
  max: 100,
  ideal: 20,
  step: 10,
  transportType: "driving"
}

const DistanceForm = ({ formName, initialData, kind, withPriority, onSubmitForm }) => {
  const [form] = Form.useForm();

  const initialValues = getInitialDistanceValues(DEFAULT_VALUES, initialData, withPriority);

  const onFinish = values => onCriteriaRangeFormFinish(kind, values, withPriority, onSubmitForm);

  // const onFieldChange = () => {
  //   const fieldsValue = form.getFieldsValue();
  //   onValuesChange({ [kind]: fieldsValue});
  // };

  return (
    <Form
      className="distance-form"
      form={form}
      initialValues={initialValues}
      name={formName}
      onFinish={onFinish}
    >
      <div className="distance-fields">
        {/* <Form.Item name="location"><AddressField form={form} onFieldChange={onFieldChange} /></Form.Item> */}
        <Form.Item name="location"><AddressField form={form} /></Form.Item>
        <Form.Item name="transport_type"><MovementTypeField form={form} /></Form.Item>
      </div>
      <Form.Item name="range">
        <SliderIdealMaxInputField
          kind={kind}
          form={form}
          min={DEFAULT_VALUES.min}
          max={DEFAULT_VALUES.max}
          step={DEFAULT_VALUES.step}
          maxInputValue={MAX_MINUTES}
          withoutHistogram
          invertTrack
          icon={<IconBedrooms />}
          idealMessage={<FormattedMessage id="dashboard.form.targetMinutesAway" />}
          maxMessage={<FormattedMessage id="dashboard.form.maximumMinutesAway" />}
          roundFunction={value => Math.round(value / DEFAULT_VALUES.step) * DEFAULT_VALUES.step}
        />
      </Form.Item>
      {withPriority && (
        <Form.Item name="priority">
          <PriorityField />
        </Form.Item>
      )}
    </Form>
  );
};

DistanceForm.propTypes = {
  formName: PropTypes.string,
  initialData: PropTypes.object,
  kind: PropTypes.string,
  withPriority: PropTypes.bool,
  onSubmitForm: PropTypes.func,
  // onValuesChange: PropTypes.func,
};

export default DistanceForm;
