import React, { Component } from 'react';

export const withModal = WrappedComponent => class Modal extends Component {
    state = {
      visible: false
    };

    handleModalVisible = () => {
      this.setState(prevState => ({
        visible: !prevState.visible,
      }));
    };

    render() {
      const { visible } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          modalVisible={visible}
          handleModalVisible={this.handleModalVisible}
        />
      );
    }
  };
