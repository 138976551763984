import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { MAX_SQUARE_FOOTAGE } from "constants/criteria";
import PriorityField from "components/Fields/PriorityField";
import SliderRangeInputField from "components/Fields/SliderRangeInputField";
import { createHistogramSteps, getInitialRangePriorityValues, onCriteriaRangeFormFinish, SQUARE_FOOTAGE_SLIDER_DIFF } from "utils/helpers/criteriaHelper";
import "../../styles.scss";

const DEFAULT_VALUES = {
  min: 500,
  max: 7500,
  ideal: 3000,
  step: 500,
}

const HISTOGRAM_STEPS = createHistogramSteps(DEFAULT_VALUES.min, DEFAULT_VALUES.max, DEFAULT_VALUES.step);

const SquareFootageForm = ({ dataKind, formName, initialData, kind, withPriority, onSubmitForm }) => {
  const [form] = Form.useForm();

  const initialValues =
    getInitialRangePriorityValues(DEFAULT_VALUES, initialData, true, true, withPriority);

  const onFinish = values => onCriteriaRangeFormFinish(kind, values, withPriority, onSubmitForm);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name={formName}
      onFinish={onFinish}
    >
      <Form.Item name="range">
        <SliderRangeInputField
          dataKind={dataKind}
          form={form}
          kind={kind}
          min={DEFAULT_VALUES.min}
          max={DEFAULT_VALUES.max}
          step={DEFAULT_VALUES.step}
          histogramSteps={HISTOGRAM_STEPS}
          ideal={DEFAULT_VALUES.ideal}
          maxInputValue={MAX_SQUARE_FOOTAGE}
          minMessage={<FormattedMessage id="dashboard.form.minSquareFootage" />}
          idealMessage={<FormattedMessage id="dashboard.form.idealSquareFootage" />}
          maxMessage={<FormattedMessage id="dashboard.form.maxSquareFootage" />}
          sliderDiff={SQUARE_FOOTAGE_SLIDER_DIFF}
        />
      </Form.Item>
      {withPriority && (
        <Form.Item name="priority">
          <PriorityField />
        </Form.Item>
      )}
    </Form>
  );
};

SquareFootageForm.propTypes = {
  dataKind: PropTypes.string,
  formName: PropTypes.string,
  initialData: PropTypes.object,
  kind: PropTypes.string,
  withPriority: PropTypes.bool,
  onSubmitForm: PropTypes.func,
};

export default SquareFootageForm;
