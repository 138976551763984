export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const SEARCH_API_URL = process.env.REACT_APP_SEARCH_API_URL;
export const GEO_SEARCH_API_URL = process.env.REACT_APP_GEO_SEARCH_API_URL;
export const USE_MOCKS = process.env.REACT_APP_BASE_USE_MOCK;

// [Auth]
export const LOG_IN_URL = `${BASE_API_URL}/sign_in/`;
export const USER = `${BASE_API_URL}/user/`;

// [Criteria]
export const CRITERIA = `${BASE_API_URL}/criteria/`;
export const CRITERIA_HISTOGRAM = `${SEARCH_API_URL}/criteria_histogram/`;

// [Onboarding]
export const GET_ONBOARDING_DATA_PATH = `${BASE_API_URL}/onboarding/`;
export const SAVE_ONBOARDING_DATA_PATH = `${BASE_API_URL}/onboarding/save_step_data/`;

// [Search]
export const SEARCH_HOUSE_DETAILS_PATH = `${SEARCH_API_URL}/property`;
export const SEARCH_HOUSE_PROPERTIES_PATH = `${SEARCH_API_URL}/search_results/`;
export const SEARCH_HOUSE_PROPERTIES_MAP_PATH = `${SEARCH_API_URL}/search_results/map/`;
export const SEARCH_HOUSE_FAVORITES_PATH = `${SEARCH_API_URL}/search_results/favorites/`;
export const SEARCH_HOUSE_FAVORITES_MAP_PATH = `${SEARCH_API_URL}/search_results/favorites/map/`;
export const SEARCH_FAVORITES_PATH = `${SEARCH_API_URL}/favorites/`;
export const SEARCH_RECENTLY_VIEWED_PATH = `${SEARCH_API_URL}/recently_viewed/`;
