import React from "react";
import { Select } from "antd";

const { Option } = Select;

const CustomSelect = ({ options, addonBefore, ...otherProps }) => (
  <div className="custom-select-wrapper">
    <div className="custom-select-addon">
      {addonBefore}
    </div>
    <Select
      {...otherProps}
      style={{ width: '100%' }}
    >
      {options && options.map(item => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  </div>
)

export default CustomSelect;