import React from "react";
import { IconBathrooms, IconBedrooms, IconHomeType, IconPriceRange } from "components/Svgs";
import { CRITERIA_MAP } from "constants/criteria";

const BASIC_CRITERIA_FILTERS = [
  {
    key: CRITERIA_MAP.price,
    icon: <IconPriceRange />,
  },
  {
    key: CRITERIA_MAP.bedrooms,
    icon: <IconBedrooms />,
  },
  {
    key: CRITERIA_MAP.bathrooms,
    icon: <IconBathrooms />,
  },
  {
    key: CRITERIA_MAP.prop_type,
    icon: <IconHomeType />,
  },
];

export { BASIC_CRITERIA_FILTERS };
