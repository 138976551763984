import React from 'react';
import { FormattedMessage } from "react-intl";
import { DaysOnHiHome } from "components/DataDisplay";
import classNames from "classnames";
import { get as lodashGet } from "lodash";
import PropTypes from "prop-types";
import ImageWithPreview from "components/DataDisplay/ImageWithPreview";
import { IconTransparentHeart, IconHiHome } from "components/Svgs";
import { getClassnameForScore, getValueClassnameByScoreRangeType } from "utils/helpers/housesHelper/common";
import {nFormatter, toLocalePrice} from "utils/helpers/housesHelper/formatter";
import {
  getClassnameForStatus,
  getFullAddress,
  getPropertyTypeValue,
  getStatusValue,
} from "utils/helpers/housesHelper/getter";
import "./styles.scss";

const HouseCard = (props) => {
  const {
    activeFavorite,
    isActive,
    houseData,
    viewedHouseIds,
    handleFavorites,
    handleOnClickHouse,
    setIsActive,
  } = props;

  const getFirstRowCriteria = () => (
    <>
      <div className={`card-tag ${getValueClassnameByScoreRangeType(lodashGet(houseData, 'bedrooms.score'), lodashGet(houseData, 'bedrooms.value'))}`}>
        {lodashGet(houseData, 'bedrooms.value')} <FormattedMessage id="houses.card.beds" />
      </div>
      <div className={`card-tag ${getValueClassnameByScoreRangeType(lodashGet(houseData, 'bathrooms.score'),lodashGet(houseData, 'bathrooms.value'))}`}>
        {lodashGet(houseData, 'bathrooms.value')} <FormattedMessage id="houses.card.bath" />
      </div>
      {getSquareFootageValue()}
    </>
  );

  const getSquareFootageValue = () => {
    const buildingSizeValue = lodashGet(houseData, 'building_size.value');
    return (
      <div className={`card-tag ${getValueClassnameByScoreRangeType(lodashGet(houseData, 'building_size.score'),buildingSizeValue)}`}>
        {_.isNil(buildingSizeValue) ? (
          <FormattedMessage id="general.noData" />
        ) : (
          <>
            {nFormatter(buildingSizeValue, 1, false)}
            &nbsp;
            <span style={{ textTransform: "capitalize" }}>
              {`${lodashGet(houseData, 'building_size.units')}`}
            </span>
          </>
        )}
      </div>
    );
  };

  const onClickAccordion = e => {
    e.stopPropagation();
    setIsActive(!isActive);
  };

  return (
    <div className="card-view-wrapper">
      <div className="card-view" onClick={() => handleOnClickHouse(houseData.id)}>
        <div className="card-view__score-inner">
          <div className="card-view__score">
            <div className={`score-container ${getClassnameForScore(houseData.total_score)}`}>
              <span>{houseData.total_score} </span>
            </div>
          </div>
        </div>
        <div className="card-view__photo" onClick={(e) => e.stopPropagation()}>
          <ImageWithPreview data={houseData} height={132} />
            <div className="card-view__price">
              <div className={classNames("card-tag", "price-tag",
                getValueClassnameByScoreRangeType(
                  lodashGet(houseData, 'price.score'),
                  lodashGet(houseData, 'price.value'))
              )}>
                {`${toLocalePrice(lodashGet(houseData, 'price.value'))}`}
              </div>
            </div>
            <div className="favorites-heart" onClick={(e) => e.stopPropagation()}>
              <IconTransparentHeart className={classNames({ active: activeFavorite })} onClick={handleFavorites} />
            </div>
          {/*NEW marker for cards currently disabled */}
          {/*{!houseData.is_viewed && (*/}
          {/*  <div className="new-home">*/}
          {/*    <FormattedMessage id="houses.card.new" />*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        <div className="card-view__bottom-content">
          <div className="criteria">
            <div className={isActive ? "accordion is-active" : "accordion"}>
              <div className="accordion__top" onClick={onClickAccordion}>
                <div className="accordion__text">
                  {getFirstRowCriteria()}
                </div>
              </div>
            </div>
          </div>
          <div className="address">
            <div className={classNames("address-content",{ isViewed: viewedHouseIds.includes(houseData.id) })}>
              {getFullAddress(houseData.address)}
            </div>
          </div>
          <div className="status">
            <div className={`status-item ${getClassnameForStatus(houseData.listing_status)}`}>
              {getStatusValue(houseData)}
            </div>
            <div className="property__type">
              <div className="message">
                {getPropertyTypeValue(houseData.prop_type)}
              </div>
            </div>
            <DaysOnHiHome
              customClass="status-days"
              customIcon={IconHiHome}
              propertyItem={houseData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

HouseCard.propTypes = {
  activeFavorite: PropTypes.bool,
  isActive: PropTypes.bool,
  houseData: PropTypes.object,
  viewedHouseIds: PropTypes.array,
  handleFavorites: PropTypes.func,
  handleOnClickHouse: PropTypes.func,
  setIsActive: PropTypes.func,
};

export default HouseCard;
