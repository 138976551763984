import React from "react";

const IconBathrooms = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_716_1872)">
      <path
        d="M8.53475 14.8915C9.13644 14.8915 9.6242 14.4038 9.6242 13.8021C9.6242 13.2004 9.13644 12.7126 8.53475 12.7126C7.93307 12.7126 7.44531 13.2004 7.44531 13.8021C7.44531 14.4038 7.93307 14.8915 8.53475 14.8915Z"
        fill="url(#paint0_linear_716_1872)"
      />
      <path
        d="M8.53424 14.7111C9.0364 14.7111 9.44348 14.304 9.44348 13.8018C9.44348 13.2997 9.0364 12.8926 8.53424 12.8926C8.03208 12.8926 7.625 13.2997 7.625 13.8018C7.625 14.304 8.03208 14.7111 8.53424 14.7111Z"
        fill="url(#paint1_linear_716_1872)"
      />
      <path
        d="M9.02049 12.8274C9.09396 12.974 9.13538 13.1395 9.13538 13.3147C9.13538 13.9164 8.64766 14.4042 8.04593 14.4042C7.87075 14.4042 7.70523 14.3628 7.55859 14.2893C7.73748 14.6463 8.10667 14.8914 8.53315 14.8914C9.13481 14.8914 9.62259 14.4036 9.62259 13.802C9.62259 13.3755 9.37745 13.0063 9.02049 12.8274Z"
        fill="url(#paint2_linear_716_1872)"
      />
      <path
        d="M18.4762 17.2285C19.0779 17.2285 19.5656 16.7407 19.5656 16.139C19.5656 15.5373 19.0779 15.0496 18.4762 15.0496C17.8745 15.0496 17.3867 15.5373 17.3867 16.139C17.3867 16.7407 17.8745 17.2285 18.4762 17.2285Z"
        fill="url(#paint3_linear_716_1872)"
      />
      <path
        d="M18.4756 17.0485C18.9778 17.0485 19.3849 16.6414 19.3849 16.1392C19.3849 15.6371 18.9778 15.23 18.4756 15.23C17.9735 15.23 17.5664 15.6371 17.5664 16.1392C17.5664 16.6414 17.9735 17.0485 18.4756 17.0485Z"
        fill="url(#paint4_linear_716_1872)"
      />
      <path
        d="M18.9619 15.1646C19.0354 15.3112 19.0768 15.4767 19.0768 15.6519C19.0768 16.2536 18.5891 16.7413 17.9873 16.7413C17.8122 16.7413 17.6466 16.6999 17.5 16.6265C17.6789 16.9834 18.048 17.2286 18.4746 17.2286C19.0762 17.2286 19.564 16.7408 19.564 16.1391C19.564 15.7126 19.3189 15.3434 18.9619 15.1646Z"
        fill="url(#paint5_linear_716_1872)"
      />
      <path
        d="M10.5658 16.8766C11.5297 16.8766 12.3112 16.0952 12.3112 15.1312C12.3112 14.1672 11.5297 13.3857 10.5658 13.3857C9.60177 13.3857 8.82031 14.1672 8.82031 15.1312C8.82031 16.0952 9.60177 16.8766 10.5658 16.8766Z"
        fill="url(#paint6_linear_716_1872)"
      />
      <path
        d="M10.5661 16.5879C11.3706 16.5879 12.0228 15.9358 12.0228 15.1313C12.0228 14.3267 11.3706 13.6746 10.5661 13.6746C9.76156 13.6746 9.10938 14.3267 9.10938 15.1313C9.10938 15.9358 9.76156 16.5879 10.5661 16.5879Z"
        fill="url(#paint7_linear_716_1872)"
      />
      <path
        d="M11.346 13.5698C11.4637 13.8047 11.5301 14.0699 11.5301 14.3506C11.5301 15.3146 10.7487 16.0961 9.78464 16.0961C9.50398 16.0961 9.23882 16.0297 9.00391 15.912C9.29046 16.4839 9.88196 16.8766 10.5653 16.8766C11.5292 16.8766 12.3107 16.0952 12.3107 15.1312C12.3106 14.4479 11.918 13.8564 11.346 13.5698Z"
        fill="url(#paint8_linear_716_1872)"
      />
      <path
        d="M20.1868 18.7937C21.1507 18.7937 21.9322 18.0123 21.9322 17.0484C21.9322 16.0844 21.1507 15.303 20.1868 15.303C19.2228 15.303 18.4414 16.0844 18.4414 17.0484C18.4414 18.0123 19.2228 18.7937 20.1868 18.7937Z"
        fill="url(#paint9_linear_716_1872)"
      />
      <path
        d="M20.1872 18.5049C20.9917 18.5049 21.6438 17.8527 21.6438 17.0482C21.6438 16.2437 20.9917 15.5916 20.1872 15.5916C19.3826 15.5916 18.7305 16.2437 18.7305 17.0482C18.7305 17.8527 19.3826 18.5049 20.1872 18.5049Z"
        fill="url(#paint10_linear_716_1872)"
      />
      <path
        d="M20.9672 15.4871C21.0849 15.722 21.1513 15.9871 21.1513 16.2679C21.1513 17.2318 20.3698 18.0132 19.4058 18.0132C19.1251 18.0132 18.86 17.9469 18.625 17.8292C18.9116 18.4011 19.5031 18.7938 20.1864 18.7938C21.1503 18.7938 21.9317 18.0124 21.9317 17.0483C21.9318 16.3651 21.5391 15.7736 20.9672 15.4871Z"
        fill="url(#paint11_linear_716_1872)"
      />
      <path
        d="M16.7298 18.6221C17.6937 18.6221 18.4751 17.8407 18.4751 16.8767C18.4751 15.9128 17.6937 15.1313 16.7298 15.1313C15.7658 15.1313 14.9844 15.9128 14.9844 16.8767C14.9844 17.8407 15.7658 18.6221 16.7298 18.6221Z"
        fill="url(#paint12_linear_716_1872)"
      />
      <path
        d="M16.7301 18.3333C17.5346 18.3333 18.1868 17.6811 18.1868 16.8766C18.1868 16.0721 17.5346 15.4199 16.7301 15.4199C15.9256 15.4199 15.2734 16.0721 15.2734 16.8766C15.2734 17.6811 15.9256 18.3333 16.7301 18.3333Z"
        fill="url(#paint13_linear_716_1872)"
      />
      <path
        d="M17.5101 15.3152C17.6278 15.5501 17.6942 15.8153 17.6942 16.096C17.6942 17.0599 16.9127 17.8414 15.9487 17.8414C15.668 17.8414 15.4029 17.7751 15.168 17.6573C15.4545 18.2293 16.046 18.622 16.7293 18.622C17.6933 18.622 18.4748 17.8406 18.4748 16.8765C18.4747 16.1932 18.082 15.6017 17.5101 15.3152Z"
        fill="url(#paint14_linear_716_1872)"
      />
      <path
        d="M5.93133 15.7577C7.04328 15.7577 7.9447 14.8563 7.9447 13.7443C7.9447 12.6324 7.04328 11.731 5.93133 11.731C4.81938 11.731 3.91797 12.6324 3.91797 13.7443C3.91797 14.8563 4.81938 15.7577 5.93133 15.7577Z"
        fill="url(#paint15_linear_716_1872)"
      />
      <path
        d="M5.93032 15.4248C6.85833 15.4248 7.61063 14.6725 7.61063 13.7445C7.61063 12.8165 6.85833 12.0642 5.93032 12.0642C5.0023 12.0642 4.25 12.8165 4.25 13.7445C4.25 14.6725 5.0023 15.4248 5.93032 15.4248Z"
        fill="url(#paint16_linear_716_1872)"
      />
      <path
        d="M6.83058 11.9434C6.96637 12.2144 7.04291 12.5202 7.04291 12.844C7.04291 13.956 6.14152 14.8574 5.02955 14.8574C4.70578 14.8574 4.3999 14.7808 4.12891 14.645C4.45951 15.3048 5.14174 15.7578 5.92994 15.7578C7.04191 15.7578 7.94331 14.8564 7.94331 13.7444C7.94331 12.9562 7.49035 12.274 6.83058 11.9434Z"
        fill="url(#paint17_linear_716_1872)"
      />
      <path
        d="M11.3891 20.5189C12.8768 20.5189 14.0829 19.3129 14.0829 17.8251C14.0829 16.3374 12.8768 15.1313 11.3891 15.1313C9.90136 15.1313 8.69531 16.3374 8.69531 17.8251C8.69531 19.3129 9.90136 20.5189 11.3891 20.5189Z"
        fill="url(#paint18_linear_716_1872)"
      />
      <path
        d="M11.3888 20.0732C12.6304 20.0732 13.6369 19.0667 13.6369 17.8251C13.6369 16.5834 12.6304 15.5769 11.3888 15.5769C10.1472 15.5769 9.14062 16.5834 9.14062 17.8251C9.14062 19.0667 10.1472 20.0732 11.3888 20.0732Z"
        fill="url(#paint19_linear_716_1872)"
      />
      <path
        d="M12.5912 15.4153C12.773 15.7779 12.8754 16.1871 12.8754 16.6203C12.8754 18.1081 11.6693 19.3141 10.1816 19.3141C9.74839 19.3141 9.33917 19.2117 8.97656 19.03C9.41885 19.9127 10.3317 20.5187 11.3862 20.5187C12.874 20.5187 14.08 19.3127 14.08 17.8249C14.0801 16.7705 13.474 15.8576 12.5912 15.4153Z"
        fill="url(#paint20_linear_716_1872)"
      />
      <path
        d="M7.88788 20.5187C9.70502 20.5187 11.1781 19.0456 11.1781 17.2285C11.1781 15.4113 9.70502 13.9382 7.88788 13.9382C6.07074 13.9382 4.59766 15.4113 4.59766 17.2285C4.59766 19.0456 6.07074 20.5187 7.88788 20.5187Z"
        fill="url(#paint21_linear_716_1872)"
      />
      <path
        d="M7.88661 19.9744C9.40317 19.9744 10.6326 18.745 10.6326 17.2284C10.6326 15.7118 9.40317 14.4824 7.88661 14.4824C6.37004 14.4824 5.14062 15.7118 5.14062 17.2284C5.14062 18.745 6.37004 19.9744 7.88661 19.9744Z"
        fill="url(#paint22_linear_716_1872)"
      />
      <path
        d="M9.36037 14.2852C9.5823 14.7281 9.70735 15.2278 9.70735 15.757C9.70735 17.5741 8.23429 19.0472 6.41712 19.0472C5.888 19.0472 5.38823 18.9221 4.94531 18.7002C5.48555 19.7784 6.60053 20.5186 7.88856 20.5186C9.70572 20.5186 11.1788 19.0456 11.1788 17.2284C11.1789 15.9403 10.4386 14.8253 9.36037 14.2852Z"
        fill="url(#paint23_linear_716_1872)"
      />
      <path
        d="M4.23657 20.5188C5.98309 20.5188 7.39893 19.103 7.39893 17.3564C7.39893 15.6099 5.98309 14.1941 4.23657 14.1941C2.49005 14.1941 1.07422 15.6099 1.07422 17.3564C1.07422 19.103 2.49005 20.5188 4.23657 20.5188Z"
        fill="url(#paint24_linear_716_1872)"
      />
      <path
        d="M4.2369 19.9955C5.69452 19.9955 6.87615 18.8139 6.87615 17.3563C6.87615 15.8987 5.69452 14.717 4.2369 14.717C2.77929 14.717 1.59766 15.8987 1.59766 17.3563C1.59766 18.8139 2.77929 19.9955 4.2369 19.9955Z"
        fill="url(#paint25_linear_716_1872)"
      />
      <path
        d="M6.68035 28.8248C6.38463 28.6383 6.2833 28.2254 6.45402 27.9025L6.76317 27.3179L7.8341 27.993L7.52496 28.5777C7.35423 28.9006 6.97606 29.0112 6.68035 28.8248Z"
        fill="url(#paint26_linear_716_1872)"
      />
      <path
        d="M22.5405 28.8248C22.8362 28.6383 22.9375 28.2254 22.7668 27.9025L22.4577 27.3179L21.3867 27.993L21.6959 28.5777C21.8666 28.9006 22.2447 29.0112 22.5405 28.8248Z"
        fill="url(#paint27_linear_716_1872)"
      />
      <path
        d="M19.2346 28.3815H9.98311C5.53223 28.3815 1.73069 24.8753 0.986406 20.0838L0.5625 17.355H28.6552L28.2313 20.0838C27.487 24.8754 23.6854 28.3815 19.2346 28.3815Z"
        fill="url(#paint28_linear_716_1872)"
      />
      <path
        d="M28.2309 20.0838L28.6548 17.355H2.85938L3.28328 20.0838C4.02756 24.8754 7.8291 28.3815 12.28 28.3815H19.2342C23.6851 28.3815 27.4866 24.8754 28.2309 20.0838Z"
        fill="url(#paint29_linear_716_1872)"
      />
      <path
        d="M25.8425 17.355L25.4186 20.0838C24.6743 24.8754 20.8728 28.3815 16.4219 28.3815H19.2382C23.6891 28.3815 27.4906 24.8753 28.2349 20.0838L28.6588 17.355H25.8425Z"
        fill="url(#paint30_linear_716_1872)"
      />
      <path
        d="M28.3269 18.2653H0.893992C0.400251 18.2653 0 17.8651 0 17.3713V17.291C0 16.7972 0.400251 16.397 0.893992 16.397H28.3269C28.8206 16.397 29.2209 16.7972 29.2209 17.291V17.3713C29.2209 17.8651 28.8206 18.2653 28.3269 18.2653Z"
        fill="url(#paint31_linear_716_1872)"
      />
      <path
        d="M1.23834 16.8145C0.765809 16.8145 0.382812 17.2327 0.382812 17.7486C0.382812 17.8988 0.416067 18.0403 0.473793 18.1661C0.588993 18.2291 0.718499 18.2654 0.856099 18.2654H28.3659C28.8384 18.2654 29.2214 17.8471 29.2214 17.3312C29.2214 17.181 29.1881 17.0395 29.1304 16.9138C29.0153 16.8508 28.8857 16.8145 28.7481 16.8145H1.23834Z"
        fill="url(#paint32_linear_716_1872)"
      />
      <path
        d="M23.4762 6.35006L22.8594 6.18517L23.4293 4.05347L24.046 4.21836L23.4762 6.35006Z"
        fill="url(#paint33_linear_716_1872)"
      />
      <path
        d="M30.1728 28.6416H29.2393V9.21802C29.2393 7.28817 27.9483 5.59675 26.0868 5.08757L22.1172 4.00183C21.8685 3.93381 21.7221 3.67712 21.7901 3.42846C21.8581 3.1798 22.1148 3.03336 22.3635 3.10137L26.333 4.18711C28.6004 4.80729 30.1727 6.86746 30.1727 9.21808V28.6416H30.1728Z"
        fill="url(#paint34_linear_716_1872)"
      />
      <path
        d="M24.3904 7.67777L21.4713 6.87934C21.0569 6.76596 20.8128 6.33816 20.9262 5.92373C21.0395 5.5093 21.4674 5.26522 21.8818 5.3786L24.8009 6.17703C25.2153 6.29041 25.4594 6.71821 25.346 7.13264C25.2326 7.54707 24.8048 7.79109 24.3904 7.67777Z"
        fill="url(#paint35_linear_716_1872)"
      />
      <path
        d="M24.9727 6.42475L22.0536 5.62638C21.6392 5.51306 21.2114 5.75708 21.098 6.17151C21.0489 6.35109 21.0671 6.53317 21.137 6.6911C21.2275 6.77782 21.3403 6.84389 21.4697 6.87927L24.3888 7.67771C24.8032 7.79102 25.2311 7.54701 25.3444 7.13258C25.3935 6.95294 25.3753 6.77091 25.3054 6.61298C25.2149 6.52621 25.1021 6.4602 24.9727 6.42475Z"
        fill="url(#paint36_linear_716_1872)"
      />
      <path
        d="M30.992 28.7135H28.4155C28.1926 28.7135 28.0117 28.5327 28.0117 28.3096V27.0872C28.0117 26.8642 28.1925 26.6833 28.4155 26.6833H30.992C31.215 26.6833 31.3959 26.8641 31.3959 27.0872V28.3096C31.3959 28.5326 31.215 28.7135 30.992 28.7135Z"
        fill="url(#paint37_linear_716_1872)"
      />
      <path
        d="M31.5966 28.9151H27.8101C27.5871 28.9151 27.4062 28.7343 27.4062 28.5113V28.2827C27.4062 27.8096 27.7898 27.426 28.263 27.426H31.1436C31.6168 27.426 32.0003 27.8096 32.0003 28.2827V28.5113C32.0004 28.7343 31.8196 28.9151 31.5966 28.9151Z"
        fill="url(#paint38_linear_716_1872)"
      />
      <path
        d="M24.5474 9.57796L24.7746 8.7634C24.8349 8.54737 24.7086 8.32331 24.4925 8.26307C24.2765 8.20283 24.0525 8.32914 23.9922 8.54517L23.765 9.35973C23.7048 9.57577 23.8311 9.79977 24.0471 9.86006C24.2632 9.9203 24.4872 9.79399 24.5474 9.57796Z"
        fill="url(#paint39_linear_716_1872)"
      />
      <path
        d="M22.9967 15.1322L23.2239 14.3176C23.2841 14.1016 23.1579 13.8776 22.9418 13.8173C22.7257 13.757 22.5017 13.8833 22.4414 14.0994L22.2142 14.9139C22.154 15.13 22.2803 15.354 22.4963 15.4143C22.7124 15.4745 22.9364 15.3482 22.9967 15.1322Z"
        fill="url(#paint40_linear_716_1872)"
      />
      <path
        d="M23.6568 12.7685L24.1145 11.1274C24.1747 10.9114 24.0484 10.6873 23.8324 10.6271C23.6164 10.5668 23.3923 10.6932 23.3321 10.9092L22.8744 12.5502C22.8142 12.7663 22.9404 12.9903 23.1565 13.0506C23.3725 13.1109 23.5965 12.9845 23.6568 12.7685Z"
        fill="url(#paint41_linear_716_1872)"
      />
      <path
        d="M22.1178 11.8936L22.345 11.0791C22.4052 10.863 22.2789 10.639 22.0629 10.5787C21.8468 10.5185 21.6228 10.6448 21.5625 10.8608L21.3353 11.6754C21.2751 11.8914 21.4014 12.1155 21.6174 12.1757C21.8335 12.236 22.0575 12.1097 22.1178 11.8936Z"
        fill="url(#paint42_linear_716_1872)"
      />
      <path
        d="M21.731 13.2823L21.8388 12.896C21.8991 12.68 21.7728 12.4559 21.5567 12.3957C21.3406 12.3354 21.1166 12.4617 21.0564 12.6778L20.9486 13.064C20.8884 13.2801 21.0147 13.5041 21.2307 13.5644C21.4468 13.6246 21.6708 13.4983 21.731 13.2823Z"
        fill="url(#paint43_linear_716_1872)"
      />
      <path
        d="M21.3443 14.6707L21.4521 14.2844C21.5123 14.0684 21.3861 13.8443 21.17 13.7841C20.9539 13.7238 20.7299 13.8501 20.6696 14.0662L20.5619 14.4524C20.5017 14.6685 20.628 14.8925 20.844 14.9528C21.0601 15.013 21.2841 14.8867 21.3443 14.6707Z"
        fill="url(#paint44_linear_716_1872)"
      />
      <path
        d="M22.6607 9.94301L23.1184 8.30198C23.1786 8.08595 23.0524 7.86195 22.8363 7.80165C22.6202 7.74141 22.3962 7.86766 22.336 8.08375L21.8783 9.72478C21.8181 9.94081 21.9444 10.1649 22.1604 10.2251C22.3765 10.2854 22.6005 10.1591 22.6607 9.94301Z"
        fill="url(#paint45_linear_716_1872)"
      />
      <path
        d="M21.2389 8.65486L21.4661 7.8403C21.5264 7.62427 21.4001 7.40027 21.184 7.33997C20.968 7.27974 20.7439 7.40604 20.6837 7.62208L20.4564 8.43664C20.3962 8.65267 20.5225 8.87673 20.7385 8.93697C20.9546 8.9972 21.1786 8.8709 21.2389 8.65486Z"
        fill="url(#paint46_linear_716_1872)"
      />
      <path
        d="M19.6881 14.2091L19.9153 13.3945C19.9755 13.1785 19.8492 12.9544 19.6332 12.8942C19.4171 12.8339 19.1931 12.9602 19.1328 13.1763L18.9056 13.9908C18.8454 14.2069 18.9717 14.4309 19.1877 14.4912C19.4038 14.5514 19.6278 14.4251 19.6881 14.2091Z"
        fill="url(#paint47_linear_716_1872)"
      />
      <path
        d="M20.3482 11.8454L20.8059 10.2043C20.8661 9.98829 20.7398 9.76422 20.5238 9.70399C20.3077 9.64375 20.0837 9.77006 20.0235 9.98609L19.5658 11.6271C19.5056 11.8432 19.6318 12.0672 19.8479 12.1275C20.0639 12.1878 20.2879 12.0615 20.3482 11.8454Z"
        fill="url(#paint48_linear_716_1872)"
      />
      <path
        d="M9.72893 16.4269C9.72893 16.4167 9.72736 16.4068 9.72591 16.397H4.67098C4.66979 16.3982 4.66916 16.3988 4.66797 16.4V22.4251C4.66797 22.5397 4.76089 22.6326 4.87547 22.6326H4.88049V22.6376L4.95503 22.7134V22.8335C4.95503 22.8776 4.99123 22.9132 5.03534 22.9124L10.5274 28.3815H19.2349C19.9784 28.3815 20.7035 28.2829 21.3996 28.0976L9.72893 16.4269Z"
        fill="url(#paint49_linear_716_1872)"
      />
      <path
        d="M5.13115 22.9138H5.02837C4.98897 22.9138 4.95703 22.8819 4.95703 22.8425V22.6023H5.20249V22.8425C5.20255 22.8819 5.17055 22.9138 5.13115 22.9138Z"
        fill="url(#paint50_linear_716_1872)"
      />
      <path
        d="M5.55302 22.9138H5.45025C5.41084 22.9138 5.37891 22.8819 5.37891 22.8425V22.6023H5.62436V22.8425C5.62436 22.8819 5.59243 22.9138 5.55302 22.9138Z"
        fill="url(#paint51_linear_716_1872)"
      />
      <path
        d="M5.97881 22.9138H5.87603C5.83662 22.9138 5.80469 22.8819 5.80469 22.8425V22.6023H6.05015V22.8425C6.05021 22.8819 6.01827 22.9138 5.97881 22.9138Z"
        fill="url(#paint52_linear_716_1872)"
      />
      <path
        d="M6.40459 22.9138H6.30181C6.26241 22.9138 6.23047 22.8819 6.23047 22.8425V22.6023H6.47593V22.8425C6.47593 22.8819 6.44399 22.9138 6.40459 22.9138Z"
        fill="url(#paint53_linear_716_1872)"
      />
      <path
        d="M6.82646 22.9138H6.72368C6.68428 22.9138 6.65234 22.8819 6.65234 22.8425V22.6023H6.8978V22.8425C6.8978 22.8819 6.86586 22.9138 6.82646 22.9138Z"
        fill="url(#paint54_linear_716_1872)"
      />
      <path
        d="M7.25224 22.9138H7.14947C7.11006 22.9138 7.07812 22.8819 7.07812 22.8425V22.6023H7.32358V22.8425C7.32365 22.8819 7.29165 22.9138 7.25224 22.9138Z"
        fill="url(#paint55_linear_716_1872)"
      />
      <path
        d="M7.67412 22.9138H7.57134C7.53194 22.9138 7.5 22.8819 7.5 22.8425V22.6023H7.74546V22.8425C7.74546 22.8819 7.71352 22.9138 7.67412 22.9138Z"
        fill="url(#paint56_linear_716_1872)"
      />
      <path
        d="M8.0999 22.9138H7.99712C7.95772 22.9138 7.92578 22.8819 7.92578 22.8425V22.6023H8.17124V22.8425C8.17124 22.8819 8.1393 22.9138 8.0999 22.9138Z"
        fill="url(#paint57_linear_716_1872)"
      />
      <path
        d="M8.52178 22.9138H8.419C8.37959 22.9138 8.34766 22.8819 8.34766 22.8425V22.6023H8.59312V22.8425C8.59312 22.8819 8.56118 22.9138 8.52178 22.9138Z"
        fill="url(#paint58_linear_716_1872)"
      />
      <path
        d="M8.94756 22.9138H8.84478C8.80538 22.9138 8.77344 22.8819 8.77344 22.8425V22.6023H9.0189V22.8425C9.0189 22.8819 8.98696 22.9138 8.94756 22.9138Z"
        fill="url(#paint59_linear_716_1872)"
      />
      <path
        d="M9.37334 22.9138H9.27056C9.23116 22.9138 9.19922 22.8819 9.19922 22.8425V22.6023H9.44468V22.8425C9.44468 22.8819 9.41275 22.9138 9.37334 22.9138Z"
        fill="url(#paint60_linear_716_1872)"
      />
      <path
        d="M9.52143 22.6326H4.87547C4.76089 22.6326 4.66797 22.5397 4.66797 22.4251V16.427C4.66797 16.3123 4.76089 16.2195 4.87547 16.2195H9.52143C9.63606 16.2195 9.72893 16.3124 9.72893 16.427V22.4251C9.72893 22.5397 9.63606 22.6326 9.52143 22.6326Z"
        fill="url(#paint61_linear_716_1872)"
      />
      <path
        d="M8.97902 16.2195V21.7451C8.97902 21.8384 8.90335 21.9141 8.80999 21.9141H5.59029C5.49693 21.9141 5.42132 21.8384 5.42132 21.7451V16.2195H5.02734V22.0841C5.02734 22.1902 5.11343 22.2764 5.21966 22.2764H9.18081C9.28698 22.2764 9.37313 22.1903 9.37313 22.0841V16.2195H8.97902Z"
        fill="url(#paint62_linear_716_1872)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_716_1872"
        x1="8.12716"
        y1="13.3944"
        x2="9.53811"
        y2="14.8054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_1872"
        x1="8.64279"
        y1="13.9104"
        x2="7.65688"
        y2="12.9245"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_1872"
        x1="8.57098"
        y1="14.2195"
        x2="10.0685"
        y2="11.4344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_1872"
        x1="18.0686"
        y1="15.7314"
        x2="19.4796"
        y2="17.1424"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_1872"
        x1="18.5842"
        y1="16.2477"
        x2="17.5984"
        y2="15.2618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_716_1872"
        x1="18.5124"
        y1="16.5566"
        x2="20.0099"
        y2="13.7715"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_716_1872"
        x1="9.9127"
        y1="14.4781"
        x2="12.1733"
        y2="16.7387"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_716_1872"
        x1="10.7399"
        y1="15.3051"
        x2="9.16039"
        y2="13.7256"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_716_1872"
        x1="10.6259"
        y1="15.8001"
        x2="13.025"
        y2="11.338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_716_1872"
        x1="19.5337"
        y1="16.3953"
        x2="21.7943"
        y2="18.6559"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_716_1872"
        x1="20.361"
        y1="17.2221"
        x2="18.7815"
        y2="15.6426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_716_1872"
        x1="20.247"
        y1="17.7173"
        x2="22.6462"
        y2="13.2552"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_716_1872"
        x1="16.0767"
        y1="16.2237"
        x2="18.3373"
        y2="18.4842"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_716_1872"
        x1="16.904"
        y1="17.0505"
        x2="15.3244"
        y2="15.4709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_716_1872"
        x1="16.7899"
        y1="17.5455"
        x2="19.1891"
        y2="13.0834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_716_1872"
        x1="5.17808"
        y1="12.991"
        x2="7.7857"
        y2="15.5986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_716_1872"
        x1="6.13091"
        y1="13.9451"
        x2="4.30885"
        y2="12.1231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_716_1872"
        x1="5.9999"
        y1="14.516"
        x2="8.76734"
        y2="9.36893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_716_1872"
        x1="10.3812"
        y1="16.8172"
        x2="13.8701"
        y2="20.3061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_716_1872"
        x1="11.6571"
        y1="18.0934"
        x2="9.21931"
        y2="15.6556"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_716_1872"
        x1="11.4798"
        y1="18.8574"
        x2="15.1826"
        y2="11.9708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_716_1872"
        x1="6.65689"
        y1="15.9975"
        x2="10.9182"
        y2="20.2588"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_716_1872"
        x1="8.21439"
        y1="17.5562"
        x2="5.23688"
        y2="14.5787"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_716_1872"
        x1="8.00282"
        y1="18.4893"
        x2="12.5254"
        y2="10.078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0469BC" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BC" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_716_1872"
        x1="4.02926"
        y1="16.8613"
        x2="6.04237"
        y2="21.6682"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_716_1872"
        x1="4.55195"
        y1="17.6713"
        x2="1.69014"
        y2="14.8095"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" stopOpacity="0" />
        <stop offset="1" stopColor="#65E1DC" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_716_1872"
        x1="6.58359"
        y1="28.1166"
        x2="7.48386"
        y2="28.1166"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F7FC" />
        <stop offset="1" stopColor="#F0DDFC" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_716_1872"
        x1="22.6372"
        y1="28.1166"
        x2="21.737"
        y2="28.1166"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F7FC" />
        <stop offset="1" stopColor="#F0DDFC" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_716_1872"
        x1="13.2709"
        y1="18.5936"
        x2="22.4445"
        y2="27.7672"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F7FC" />
        <stop offset="1" stopColor="#F0DDFC" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_716_1872"
        x1="12.3419"
        y1="19.8604"
        x2="1.96701"
        y2="15.5802"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_716_1872"
        x1="21.6755"
        y1="25.305"
        x2="40.3732"
        y2="7.57362"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0DDFC" stopOpacity="0" />
        <stop offset="0.5373" stopColor="#B9A9D6" stopOpacity="0.537" />
        <stop offset="1" stopColor="#8779B3" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_716_1872"
        x1="14.6104"
        y1="16.3401"
        x2="14.6104"
        y2="18.6368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F7FC" />
        <stop offset="1" stopColor="#F0DDFC" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_716_1872"
        x1="13.4548"
        y1="15.0495"
        x2="9.54837"
        y2="7.82861"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_716_1872"
        x1="23.1348"
        y1="5.11813"
        x2="23.7853"
        y2="5.2893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_716_1872"
        x1="21.7735"
        y1="15.8632"
        x2="30.1728"
        y2="15.8632"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_716_1872"
        x1="23.2987"
        y1="5.80169"
        x2="22.9024"
        y2="7.57185"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_716_1872"
        x1="23.5025"
        y1="7.40564"
        x2="22.2535"
        y2="4.05989"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#FCEE21" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_716_1872"
        x1="29.7038"
        y1="26.9552"
        x2="29.7038"
        y2="27.8102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_716_1872"
        x1="29.7033"
        y1="27.8622"
        x2="29.7033"
        y2="29.3192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_716_1872"
        x1="24.2398"
        y1="8.97702"
        x2="25.0447"
        y2="9.20184"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_716_1872"
        x1="22.6867"
        y1="14.5301"
        x2="23.4916"
        y2="14.7549"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_716_1872"
        x1="23.4633"
        y1="11.7537"
        x2="24.2682"
        y2="11.9785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_716_1872"
        x1="21.8093"
        y1="11.2914"
        x2="22.6142"
        y2="11.5162"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_716_1872"
        x1="21.3623"
        y1="12.8938"
        x2="22.1671"
        y2="13.1186"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_716_1872"
        x1="20.975"
        y1="14.282"
        x2="21.7798"
        y2="14.5068"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_716_1872"
        x1="22.4686"
        y1="8.92807"
        x2="23.2735"
        y2="9.15291"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_716_1872"
        x1="20.932"
        y1="8.05254"
        x2="21.7368"
        y2="8.27736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_716_1872"
        x1="19.3788"
        y1="13.6056"
        x2="20.1837"
        y2="13.8304"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_716_1872"
        x1="20.1554"
        y1="10.8292"
        x2="20.9603"
        y2="11.0541"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CFFC" />
        <stop offset="1" stopColor="#4A98F7" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_716_1872"
        x1="12.3474"
        y1="23.657"
        x2="-0.812063"
        y2="16.1446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0DDFC" stopOpacity="0" />
        <stop offset="0.5373" stopColor="#B9A9D6" stopOpacity="0.537" />
        <stop offset="1" stopColor="#8779B3" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_716_1872"
        x1="4.95703"
        y1="22.758"
        x2="5.20255"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_716_1872"
        x1="5.37891"
        y1="22.758"
        x2="5.62436"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_716_1872"
        x1="5.80475"
        y1="22.758"
        x2="6.05021"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_716_1872"
        x1="6.23047"
        y1="22.758"
        x2="6.47593"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_716_1872"
        x1="6.65234"
        y1="22.758"
        x2="6.8978"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_716_1872"
        x1="7.07819"
        y1="22.758"
        x2="7.32365"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_716_1872"
        x1="7.5"
        y1="22.758"
        x2="7.74546"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint57_linear_716_1872"
        x1="7.92578"
        y1="22.758"
        x2="8.17124"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint58_linear_716_1872"
        x1="8.34766"
        y1="22.758"
        x2="8.59318"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint59_linear_716_1872"
        x1="8.77344"
        y1="22.758"
        x2="9.0189"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_716_1872"
        x1="9.19922"
        y1="22.758"
        x2="9.44468"
        y2="22.758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint61_linear_716_1872"
        x1="4.66797"
        y1="19.426"
        x2="9.72893"
        y2="19.426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="0.2552" stopColor="#FF6C42" />
        <stop offset="0.4819" stopColor="#FE623A" />
        <stop offset="0.6978" stopColor="#FC4F2E" />
        <stop offset="0.9059" stopColor="#FA361D" />
        <stop offset="1" stopColor="#F92814" />
      </linearGradient>
      <linearGradient
        id="paint62_linear_716_1872"
        x1="5.02734"
        y1="19.2479"
        x2="9.37306"
        y2="19.2479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <clipPath id="clip0_716_1872">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconBathrooms;
