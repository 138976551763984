import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { SimpleButton } from "components/Controls/Buttons";
import { CustomModal } from "components/Modals";
import { IconClose } from "components/Svgs";
import "./styles.scss";

const CriteriaModalView = props => {
  const { bodyContent, customClass, formName, isVisible, isSaveDisabled, subTitle, title, onClose, onSave } = props;

  const renderFooter = () => (
    <div className="modal-footer">
      <SimpleButton customClass="cancel-button" onClick={onClose}>
        <FormattedMessage id="general.cancelMessage" />
      </SimpleButton>
      <SimpleButton
        customClass="save-button"
        disabled={isSaveDisabled}
        form={formName}
        htmlType="submit"
        onClick={onSave}
      >
        <FormattedMessage id="general.saveAndClose" />
      </SimpleButton>
    </div>
  );

  const renderTitle = () => (
    <div className="modal-title-container">
      <div className="modal-title-container__title">
        {title}
      </div>
      {subTitle && (
        <div className="modal-title-container__subtitle">
          {subTitle}
        </div>
      )}
    </div>
  );

  return (
    <CustomModal customClassName={classNames("criteria-modal", customClass)} width="45%" isVisible={isVisible}>
      <SimpleButton customClass="modal-close" onClick={onClose}>
        <IconClose />
      </SimpleButton>
      {renderTitle()}
      <div className="criteria-modal-body">
        {bodyContent()}
      </div>
      {renderFooter()}
    </CustomModal>
  );
};

CriteriaModalView.propTypes = {
  bodyContent: PropTypes.any,
  customClass: PropTypes.string,
  formName: PropTypes.string,
  isSaveDisabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  subTitle: PropTypes.any,
  title: PropTypes.any,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

CriteriaModalView.defaultProps = {
  isSaveDisabled: false,
};

export default CriteriaModalView;
