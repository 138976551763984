import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { BASIC_CRITERIA_FIRST_ROW, BASIC_CRITERIA_SECOND_ROW } from "containers/Onboarding/config/buttonsConfig";
import { BASIC_CRITERIA_MODAL_DATA } from "containers/Onboarding/config/modalsConfig";
import { CRITERIA_MAP } from "constants/criteria";
import { CriteriaButtonView, CriteriaModalView } from "containers/Onboarding/Views";
import { getModalTitle } from "utils/helpers/common";
import "./styles.scss";

const BasicCriteriaStep = ({ dataKind, stepData, handleFormChange }) => {
  const [activeCriteria, setActiveCriteria] = useState(null);

  const checkIsSaveDisabled = activeCriteria => {
    if (activeCriteria === CRITERIA_MAP.prop_type) {
      const isPropTypeDataExist = _.has(stepData, CRITERIA_MAP.prop_type) && !_.isEmpty(stepData.prop_type);
      return isPropTypeDataExist ? _.isEmpty(stepData.prop_type.values) : true;
    }
    return false;
  };

  const onClose = () => setActiveCriteria(null);

  const onSave = formValues => {
    handleFormChange({ [activeCriteria]: formValues });
    onClose();
  };

  const onValuesChange = formValues => handleFormChange(formValues);

  const renderBasicCriteria = () => (
    <div className="basics-step__content">
      {[BASIC_CRITERIA_FIRST_ROW, BASIC_CRITERIA_SECOND_ROW].map(row => (
        <div className="criteria-row">
          {row.map(item => (
            <div className="criteria-column">
              <CriteriaButtonView
                activeCriteriaKey={activeCriteria}
                currentCriteria={item}
                stepData={stepData}
                changeActiveCriteria={setActiveCriteria}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const renderCriteriaModal = () => {
    const modalData = BASIC_CRITERIA_MODAL_DATA[activeCriteria];
    const title = getModalTitle(modalData, "title");
    const subTitle = getModalTitle(modalData, "subTitle");
    return (
      <CriteriaModalView
        bodyContent={() => renderCriteriaModalBody(modalData)}
        formName={modalData.formName}
        isSaveDisabled={checkIsSaveDisabled(activeCriteria)}
        isVisible={!_.isNull(activeCriteria)}
        title={title}
        subTitle={subTitle}
        onClose={onClose}
      />
    );
  };

  const renderCriteriaModalBody = criteriaData => {
    const Form = criteriaData.form;
    const initialData = stepData[activeCriteria] || criteriaData.defaultValue;
    return (
      <Form
        dataKind={dataKind}
        formName={criteriaData.formName}
        initialData={initialData}
        kind={activeCriteria}
        withPriority={false}
        onSubmitForm={onSave}
        onValuesChange={onValuesChange}
      />
    );
  };

  const renderTitle = () => (
    <div className="basics-step__title">
      <div className="basics-step__title__text">
        <FormattedMessage id="onboarding.basicsTitle" />
      </div>
      <div className="basics_step-subtitle">
        <div className="basics_step-subtitle__text">
          <FormattedMessage id="onboarding.basicsSubTitleFirst" />
        </div>
        <div className="basics_step-subtitle__text">
          <FormattedMessage id="onboarding.basicsSubTitleSecond" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="basic-criteria-step-container">
      {renderTitle()}
      {renderBasicCriteria()}
      {activeCriteria && renderCriteriaModal()}
    </div>
  );
};

BasicCriteriaStep.propTypes = {
  dataKind: PropTypes.string,
  stepData: PropTypes.object,
  handleFormChange: PropTypes.func,
};

export default BasicCriteriaStep;
