import React from "react";
import { inject } from "mobx-react";
import { Form } from "antd";
import { get as lodashGet } from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { SimpleButton } from "components/Controls/Buttons";
import PriorityField from "components/Fields/PriorityField";
import "../styles.scss";

const PriorityForm = ({ criteriaStore, kind, onSubmitForm }) => {
  const [form] = Form.useForm();
  const criteriaData = criteriaStore.retrieveCriteriaData();
  const initialValues = { priority: lodashGet(criteriaData, `${kind}.priority`) };

  const onFinish = values => onSubmitForm({ kind, priority: values.priority });

  return (
    <Form
      name="PriorityForm"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item name="priority">
        <PriorityField />
      </Form.Item>
      <div className="btn-default-position">
        <Form.Item>
          <SimpleButton htmlType="submit" customClass="btn-default">
            <FormattedMessage id="general.saveAndClose" />
          </SimpleButton>
        </Form.Item>
      </div>
    </Form>
  );
};

PriorityForm.propTypes = {
  kind: PropTypes.string,
  onSubmitForm: PropTypes.func,
};

export default inject("criteriaStore")(PriorityForm);
