import { toJS, makeAutoObservable } from "mobx";
import _ from "lodash";
import { CRITERIA_DATA_KEY, USER_KEY } from "constants/localStorage";
import { localStorageStrToObjectOrNull, setObjectToLocalStorage } from "utils/helpers/common";
import globalErrorHandler from "utils/globalErrorHandler";
import { firebaseCheckAndUpdateIdToken } from "utils/firebase";
import {
  getCriteriaApi,
  postCriteriaHistogramApi,
  postSearchCriteriaApi,
} from "./requests";

const INITIAL_DATA = {
  activeFilter: null,
  loading: false,
  criteriaData: {},
  histogramData: [],
}

class CriteriaStore {
  activeFilter = INITIAL_DATA.activeFilter;
  loading = INITIAL_DATA.loading;
  criteriaData = INITIAL_DATA.criteriaData;
  histogramData = INITIAL_DATA.histogramData;

  constructor () {
    makeAutoObservable(this);
  }

  retrieveActiveFilter = () => this.activeFilter;

  retrieveCriteriaData = () => toJS(this.criteriaData) || {};

  retrieveHistogramData = () => toJS(this.histogramData);

  setActiveFilter = key => {
    this.activeFilter = key;
  };

  setCriteria = data => {
    this.criteriaData = data;
  };

  getCriteriaRequest (requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.getCriteria(requestBody, handleSuccess, handleError);
  };

  getHistogramDataRequest (requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.getHistogramData(requestBody, handleSuccess, handleError);
  };

  postSearchCriteriaRequest (requestBody, handleSuccess, handleError) {
    this.loading = true;
    this.postSearchCriteria(requestBody, handleSuccess, handleError);
  };

  requestSuccess (handleSuccess, data) {
    this.loading = false;

    if (handleSuccess) {
      handleSuccess(data);
    }
  };

  requestFailure (handleError, errorResponse) {
    if (errorResponse && errorResponse.status !== 401) {
      this.loading = false;
    }
    globalErrorHandler(errorResponse, handleError)
  };

  *getCriteria (requestBody, handleSuccess, handleError) {
    try {
      let data;
      const user = localStorage.getItem(USER_KEY);
      if (!user) {
        data = localStorageStrToObjectOrNull(CRITERIA_DATA_KEY);
        this.criteriaData = data;
      } else {
        yield firebaseCheckAndUpdateIdToken();
        const response = yield getCriteriaApi(requestBody);
        data = _.isArray(response.data) ? response.data[response.data.length - 1] : {};
        this.criteriaData = data;
      }
      this.requestSuccess(handleSuccess, data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *getHistogramData (requestBody, handleSuccess, handleError) {
    try {
      yield firebaseCheckAndUpdateIdToken();
      const response = yield postCriteriaHistogramApi(requestBody);
      this.histogramData = response.data;
      this.requestSuccess(handleSuccess, response.data);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };

  *postSearchCriteria (requestBody, handleSuccess, handleError) {
    try {
      const user = localStorage.getItem(USER_KEY);
      if (user) {
        yield firebaseCheckAndUpdateIdToken();
        const response = yield postSearchCriteriaApi(requestBody);
        this.criteriaData = response.data[response.data.length - 1];
      } else {
        setObjectToLocalStorage(CRITERIA_DATA_KEY, requestBody.data);
        this.criteriaData = requestBody.data;
      }
      this.requestSuccess(handleSuccess);
    } catch (error) {
      this.requestFailure(handleError, error.response);
    }
  };
}

export default CriteriaStore;
