import {
  CRITERIA,
  CRITERIA_HISTOGRAM,
  USE_MOCKS,
} from 'constants/apiUrls';
import { get, post } from 'utils/requests';
import {HISTOGRAM_DATA_MOCK} from "../constants";

export const getCriteriaApi = () => get(`${CRITERIA}`);
export const postCriteriaHistogramApi = (params) => {
  if (USE_MOCKS) {
    const data = params.kind !== 'price' ? HISTOGRAM_DATA_MOCK.slice(0, 8) : HISTOGRAM_DATA_MOCK
    return new Promise(resolve => {
        setTimeout(() => {
          resolve({ data });
        }, 300);
      })
  }
  return post(CRITERIA_HISTOGRAM, params);
}
export const postSearchCriteriaApi = ({data, params}) => post(CRITERIA, data, {params});
