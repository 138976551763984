import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { IconButton } from "components/Controls/Buttons";
import { PRIORITY_CRITERIA_LIST } from "containers/Houses/Views/AllCriteriaModalView/config";
import {
  ADDITIONAL_CRITERIA,
  BASIC_CRITERIA,
  COMING_SOON,
  LOCATION_CRITERIA
} from "../config";
import "../styles.scss";

const CRITERIA_DATA = [
  {
    criteriaList: BASIC_CRITERIA,
    key: "basicCriteria",
    title: "dashboard.criteria.basicCriteriaTitle"
  },
  {
    criteriaList: LOCATION_CRITERIA,
    key: "locationCriteria",
    title: "dashboard.criteria.locationCriteriaTitle"
  },
  {
    criteriaList: ADDITIONAL_CRITERIA,
    key: "additionalCriteria",
    title: "dashboard.criteria.additionalHomeCriteriaTitle"
  },
];

const CriteriaList = ({ activeCriterion, currentStepData, onActiveCriterionChange, setCurrentStepData }) => {

  // checking if the icon should be marked as checked
  const checkIsFill = (criterionKey, criteriaData) => _.has(criteriaData, criterionKey);

  const onCriterionButtonClick = key => {
    onActiveCriterionChange(key);
    if (activeCriterion !== key && PRIORITY_CRITERIA_LIST.includes(key) && !_.has(currentStepData, key)) {
      const newCurrentStepData = _.cloneDeep(currentStepData);
      newCurrentStepData[key] = { kind: key, priority: 0 };
      setCurrentStepData(newCurrentStepData);
    }
  };

  const renderIconButton = criterionData => (
    <IconButton
      customClassName="criterion-button"
      id={criterionData.key}
      leftIcon={criterionData.icon}
      isActive={activeCriterion === criterionData.key}
      isFill={checkIsFill(criterionData.key, currentStepData)}
      message={!activeCriterion && <FormattedMessage id={criterionData.message} />}
      onClick={() => onCriterionButtonClick(criterionData.key)}
    />
  );

  const renderComingSoon = () => (
    <>
      <div className="coming-soon__title">
        <FormattedMessage id="dashboard.criteria.comingSoonTitle" />
      </div>
      <div className="coming-soon__content">
        {COMING_SOON.map(criterion => (
          <IconButton
            customClassName="criterion-button"
            leftIcon={criterion.icon}
            message={!activeCriterion && <FormattedMessage id={criterion.message} />}
            disabled="disabled"
          />
        ))}
      </div>
    </>
  );

  return (
    <div className="criteria-list-container">
      <div className="criteria">
        {CRITERIA_DATA.map(item => (
          <div className="criteria-content" key={item.key}>
            <div className="criteria-title">
              <FormattedMessage id={item.title} />
            </div>
            <div className="criteria-content__content">
              {item.criteriaList.map(criterion => renderIconButton(criterion))}
            </div>
          </div>
        ))}
      </div>
      <div className="coming-soon">{renderComingSoon()}</div>
    </div>
  );
};

CriteriaList.propTypes = {
  activeCriterion: PropTypes.string,
  currentStepData: PropTypes.object,
  onActiveCriterionChange: PropTypes.func,
  setCurrentStepData: PropTypes.func,
};

export default CriteriaList;
