import React from 'react';
import BaseLayout from 'components/Layouts/BaseLayout';
import HelmetIntl from 'components/Meta/HelmetIntl';
import Dashboard from 'containers/Dashboard';

const DashboardPage = () => (
  <BaseLayout>
    <HelmetIntl id="helmet.dashboardTitle" />
    <Dashboard />
  </BaseLayout>
);

export default DashboardPage;
