import React, { useEffect, useRef, useState } from "react";
import {injectIntl } from "react-intl";
import _ from "lodash";
import classNames from "classnames";
import { CustomSlider } from "components/Controls/Sliders";
import { NumberInput } from "components/Controls/Inputs";
import RangeHistogram from "components/DataDisplay/Histogram";
import { CRITERIA_MAP } from "constants/criteria";
import { normalizeMinMax, normalizeIdeal, parseNumberToInt, DISTANCE_SLIDER_DIFF, absValue } from "utils/helpers/criteriaHelper";
import "../styles.scss";

const TOOLTIP_MAX_MESSAGES = {
  [CRITERIA_MAP.bathrooms]: "dashboard.form.bathroomsTooltipMinMessage",
  [CRITERIA_MAP.bedrooms]: "dashboard.form.bedroomsTooltipMinMessage",
};
const TOOLTIP_IDEAL_MESSAGES = {
  [CRITERIA_MAP.bathrooms]: "dashboard.form.bathroomsTooltipIdealMessage",
  [CRITERIA_MAP.bedrooms]: "dashboard.form.bedroomsTooltipIdealMessage",
};

const SliderIdealMaxInputField = props => {
  const {
    dataKind,
    form,
    value,
    min,
    max,
    step,
    histogramSteps,
    kind,
    maxMessage,
    idealMessage,
    maxInputValue,
    withoutHistogram,
    invertTrack,
    isFloat,
    roundFunction,
  } = props;
  const [sliderValue, setSliderValue] = useState([value.ideal, value.max]);
  const [maxValue, setMaxValue] = useState(value.max);
  const [idealValue, setIdealValue] = useState(value.ideal);
  const sliderTrackRef = useRef(null);

  useEffect(() => {
    const sliderTrack = sliderTrackRef.current.children[0].children[0].children[1];
    if (invertTrack) {
      // add a color stripe to the left before the slider
      const rightTrack = sliderTrackRef.current.children[0].children[0].children[3];
      sliderTrack.style.left = `0%`;
      sliderTrack.style.width = rightTrack.style.left;
    } else {
      // add a colored stripe on the right to the end of the track
      sliderTrack.style.width = `${100 - parseFloat(sliderTrack.style.left)}%`;
    }
  }, [sliderValue]);

  const getValue = inputValue => {
    inputValue = absValue(inputValue)
    if (roundFunction) {
      return roundFunction(inputValue);
    }
    if (isFloat && !_.isInteger(inputValue)) {
      const floatValue = parseFloat(inputValue);
      const fixedValue = parseFloat(floatValue.toFixed(0));
      return floatValue > fixedValue ? fixedValue + DISTANCE_SLIDER_DIFF : fixedValue - DISTANCE_SLIDER_DIFF;
    }
    return parseInt(inputValue) || 0;
  };

  const onChangeMaxInput = inputValue => {
    const value = getValue(inputValue);
    let [, ideal, max] = [idealValue - DISTANCE_SLIDER_DIFF, idealValue, value];

    [, ideal, max] = normalizeMinMax(idealValue - DISTANCE_SLIDER_DIFF, idealValue, value, DISTANCE_SLIDER_DIFF);
    setSliderValue([ideal, max]);
  };

  const onBlurMaxInput = inputValue => {
    const value = getValue(inputValue);
    const [, ideal, max] = normalizeMinMax(idealValue - DISTANCE_SLIDER_DIFF, idealValue, value, DISTANCE_SLIDER_DIFF);
    changeFormValue(ideal, max);
  };

  const onChangeIdealInput = inputValue => {
    const value = getValue(inputValue);
    let [, ideal, max] = [value - DISTANCE_SLIDER_DIFF, value, maxValue];

    [, ideal, max] = normalizeIdeal(value - DISTANCE_SLIDER_DIFF, value, maxValue, DISTANCE_SLIDER_DIFF);
    setSliderValue([ideal, max]);
  };

  const onBlurIdealInput = inputValue => {
    const value = getValue(inputValue);
    const [, ideal, max] = normalizeIdeal(value - DISTANCE_SLIDER_DIFF, value, maxValue, DISTANCE_SLIDER_DIFF);
    changeFormValue(ideal, max);
  };

  const changeFormValue = (ideal, max) => {
    setSliderValue([ideal, max]);
    form.setFieldsValue({
      range: { ideal, max },
    });
    setMaxValue(max);
    setIdealValue(ideal);
  };

  const onChangeSlider = (_, value) => {
    setIdealValue(value[0]);
    setMaxValue(value[1]);
    setSliderValue(value);
    form.setFieldsValue({
      range: {ideal: value[0],  max: value[1]},
    });
  };

  const renderHistogram = () => (
    <div className="select-range__histogram">
      <RangeHistogram
        dataKind={dataKind}
        kind={kind}
        steps={histogramSteps}
        minValue={min}
        maxValue={value.max}
        min={min}
        max={max}
        invertHistogram={invertTrack}
      />
    </div>
  );


  const renderCustomInput = (message, currentValue, defaultValue, onBlur, onChange, tooltipMessageId, isStar) => (
    <>
      <div className="input-container__title">
        {message}
      </div>
      <div className={classNames("input-container__field", { "input-star": isStar })}>
        <NumberInput
          value={currentValue}
          defaultValue={defaultValue}
          onChange={(value) => onChange(value)}
          onBlur={event => onBlur(parseNumberToInt(event.target.value))}
          maxInputValue={maxInputValue}
          isFloat={isFloat}
        />
      </div>
    </>
  );

  return (
    <div className="select-range">
      {!withoutHistogram && renderHistogram()}
      <div className="select-range-field">
        <div ref={sliderTrackRef}>
          <CustomSlider
            track
            step={step}
            min={min}
            max={max}
            defaultValue={[value.ideal, value.max]}
            value={sliderValue}
            onChange={onChangeSlider}
            withPadding
            invertedSlider={invertTrack}
          />
        </div>
        <div className="select-range-field__inputs">
          <div className="input-container">
            {renderCustomInput(idealMessage, idealValue, value.ideal, onBlurIdealInput, onChangeIdealInput, TOOLTIP_IDEAL_MESSAGES[kind], true)}
          </div>
          <span className="dash">&ndash;</span>
          <div className="input-container">
          {renderCustomInput(maxMessage, maxValue, value.max, onBlurMaxInput, onChangeMaxInput, TOOLTIP_MAX_MESSAGES[kind], false)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SliderIdealMaxInputField);
