import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { formatTaxDifference } from "utils/helpers/housesHelper/formatter";
import {
  getFormattedValueOrNull,
  getHouseParameterValue,
  getPricePerSqftValue,
  getPriceValue
} from "utils/helpers/housesHelper/getter";

const PROPERTY_HISTORY_COLUMNS = [
  {
    dataIndex: "date",
    title: <FormattedMessage id="houseDetails.propertyHistory.date" />,
    render: value => {
      if (value) {
        const date = new Date(value * 1000);
        return date.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
      }
      return getHouseParameterValue(value);
    },
    className: "table-cell",
    width: 104,
  },
  {
    dataIndex: "event_name",
    title: <FormattedMessage id="houseDetails.propertyHistory.event" />,
    render: value => getHouseParameterValue(value),
    className: "table-cell",
  },
  {
    dataIndex: "price",
    title: <FormattedMessage id="houseDetails.propertyHistory.price" />,
    render: value => getFormattedValueOrNull(value, getPriceValue),
    className: "table-cell",
  },
  {
    dataIndex: "price_per_sqft",
    title: <FormattedMessage id="houseDetails.propertyHistory.squareFootage" />,
    render: value => {
      if (value) {
        const formattedValue = value.toFixed(0);
        return getPricePerSqftValue(formattedValue);
      }
      return getHouseParameterValue(value);
    },
    className: "table-cell",
  }
];

const TAX_HISTORY_COLUMNS = houseDetails => [
  {
    dataIndex: "year",
    title: <FormattedMessage id="houseDetails.taxHistory.year" />,
    render: value => getHouseParameterValue(value),
    className: "table-cell",
  },
  {
    dataIndex: "tax",
    title: <FormattedMessage id="houseDetails.taxHistory.propertyTaxes" />,
    render: (currentTax, taxHistory, index) => {
      if (_.isNull(currentTax)) {
        return getHouseParameterValue(currentTax);
      }
      const prevTax = index < houseDetails.tax_history.length - 1 ? houseDetails.tax_history[index + 1].tax : null;
      const taxValue = getPriceValue(currentTax);
      if (!_.isNull(prevTax)) {
        const taxDifference = formatTaxDifference(currentTax, prevTax);
        return (
          <div className="property-tax">
            {taxValue}
            &nbsp;
            <div className={classNames("tax-difference", { isNegative: taxDifference.isNegative })}>
              <FormattedMessage id="houseDetails.taxHistory.taxDifference" values={{ value: taxDifference.value }} />
            </div>
          </div>
        );
      }
      return taxValue;
    },
    className: "table-cell",
  },
  {
    dataIndex: "assessment",
    title: <FormattedMessage id="houseDetails.taxHistory.taxAssessment" />,
    render: value => getFormattedValueOrNull(value, getPriceValue),
    className: "table-cell",
  },
];

export { PROPERTY_HISTORY_COLUMNS, TAX_HISTORY_COLUMNS };
