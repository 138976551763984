import React from "react";
import { IconList, IconMap } from "components/Svgs";
import { CRITERIA_MAP } from "constants/criteria";

const COLUMNS_TITLE_IN_ALPHABET_ORDER = [
  CRITERIA_MAP.basement,
  CRITERIA_MAP.bathrooms,
  CRITERIA_MAP.bedrooms,
  CRITERIA_MAP.bikeability,
  CRITERIA_MAP.central_ac,
  CRITERIA_MAP.commute,
  CRITERIA_MAP.custom_distance,
  CRITERIA_MAP.garage,
  CRITERIA_MAP.lot_size,
  "low_crime",
  CRITERIA_MAP.flood_risk,
  CRITERIA_MAP.newly_built,
  CRITERIA_MAP.parking,
  CRITERIA_MAP.parks,
  CRITERIA_MAP.price,
  CRITERIA_MAP.grocery,
  CRITERIA_MAP.transit,
  CRITERIA_MAP.quietness,
  CRITERIA_MAP.school_quality,
  CRITERIA_MAP.single_story,
  CRITERIA_MAP.solar_power,
  CRITERIA_MAP.building_size,
  CRITERIA_MAP.walkability,
];

const SWITCHER_VIEW = {
  list: "list",
  map: "map",
  card: "card",
};

const SWITCH_SETTINGS = [
  { key: "list", icon: <IconList />, message: "houses.switcher.listViewMessage" },
  { key: "map", icon : <IconMap />, message: "houses.switcher.mapViewMessage" },
  // { key: "card", icon: <IconCards />, message: "houses.switcher.cardViewMessage" },
];

export {
  COLUMNS_TITLE_IN_ALPHABET_ORDER,
  SWITCH_SETTINGS,
  SWITCHER_VIEW,
};
