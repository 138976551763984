import React from "react";

const IconNewlyBuilt = (props) => (
  <svg
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.1344 8.82211C26.1385 8.82211 26.9525 8.00815 26.9525 7.00407C26.9525 6 26.1385 5.18604 25.1344 5.18604C24.1304 5.18604 23.3164 6 23.3164 7.00407C23.3164 8.00815 24.1304 8.82211 25.1344 8.82211Z"
      fill="url(#paint0_radial_716_2358)"
    />
    <path
      d="M26.7574 7.00419C26.7574 7.90044 26.0308 8.62703 25.1346 8.62703C24.2383 8.62703 23.5117 7.90044 23.5117 7.00419C23.5117 6.10794 24.2383 5.38135 25.1346 5.38135C26.0308 5.38135 26.7574 6.10794 26.7574 7.00419Z"
      fill="url(#paint1_linear_716_2358)"
    />
    <path
      d="M25.9473 5.37793C26.07 5.62264 26.1391 5.89884 26.1391 6.19117C26.1391 7.19521 25.3252 8.00921 24.3211 8.00921C24.0287 8.00921 23.7525 7.94006 23.5078 7.81746C23.8063 8.41322 24.4224 8.82226 25.1341 8.82226C26.1382 8.82226 26.9521 8.00826 26.9521 7.00422C26.9521 6.2925 26.5431 5.67641 25.9473 5.37793Z"
      fill="url(#paint2_linear_716_2358)"
    />
    <path
      d="M25.133 4.96654C24.9172 4.96654 24.7422 4.79154 24.7422 4.5757V3.71408C24.7422 3.49824 24.9172 3.32324 25.133 3.32324C25.3489 3.32324 25.5239 3.49824 25.5239 3.71408V4.57576C25.5238 4.7916 25.3489 4.96654 25.133 4.96654Z"
      fill="url(#paint3_linear_716_2358)"
    />
    <path
      d="M26.5169 1.77068C26.024 2.09187 25.6035 2.5149 25.285 3.00984C25.2134 3.12114 25.0482 3.12083 24.9769 3.00927C24.6603 2.51321 24.2413 2.08861 23.7496 1.76566C23.6405 1.69394 23.6408 1.53093 23.7502 1.45965C24.2431 1.13846 24.6636 0.715435 24.982 0.220502C25.0536 0.109192 25.2189 0.109505 25.2901 0.221066C25.6068 0.717129 26.0257 1.14173 26.5175 1.46467C26.6266 1.53639 26.6263 1.6994 26.5169 1.77068Z"
      fill="url(#paint4_linear_716_2358)"
    />
    <path
      d="M25.133 9.0415C25.3489 9.0415 25.5239 9.2165 25.5239 9.43234V10.294C25.5239 10.5099 25.3489 10.6849 25.133 10.6849C24.9172 10.6849 24.7422 10.5099 24.7422 10.294V9.43234C24.7422 9.21644 24.9172 9.0415 25.133 9.0415Z"
      fill="url(#paint5_linear_716_2358)"
    />
    <path
      d="M23.7502 12.2375C24.2431 11.9163 24.6636 11.4933 24.9821 10.9983C25.0537 10.887 25.219 10.8873 25.2902 10.9989C25.6069 11.495 26.0258 11.9196 26.5175 12.2425C26.6266 12.3142 26.6263 12.4772 26.5169 12.5485C26.024 12.8697 25.6035 13.2927 25.2851 13.7877C25.2135 13.899 25.0482 13.8987 24.977 13.7871C24.6603 13.291 24.2414 12.8664 23.7497 12.5435C23.6405 12.4718 23.6408 12.3088 23.7502 12.2375Z"
      fill="url(#paint6_linear_716_2358)"
    />
    <path
      d="M27.1719 7.00412C27.1719 6.78828 27.3469 6.61328 27.5627 6.61328H28.4244C28.6402 6.61328 28.8152 6.78828 28.8152 7.00412C28.8152 7.21997 28.6402 7.39496 28.4244 7.39496H27.5627C27.3468 7.39496 27.1719 7.21997 27.1719 7.00412Z"
      fill="url(#paint7_linear_716_2358)"
    />
    <path
      d="M30.3693 8.3875C30.0481 7.89463 29.6251 7.47411 29.1302 7.15562C29.0189 7.08403 29.0192 6.91876 29.1307 6.84754C29.6268 6.53093 30.0514 6.11192 30.3743 5.62025C30.4461 5.51113 30.6091 5.51138 30.6804 5.62081C31.0015 6.11367 31.4246 6.53419 31.9195 6.85262C32.0308 6.92422 32.0305 7.08949 31.9189 7.1607C31.4229 7.47738 30.9983 7.89633 30.6753 8.38806C30.6036 8.49717 30.4406 8.49692 30.3693 8.3875Z"
      fill="url(#paint8_linear_716_2358)"
    />
    <path
      d="M27.1392 9.00741C27.3509 8.79571 27.6942 8.79571 27.9059 9.00741L29.0147 10.1162C29.2264 10.3279 29.2264 10.6712 29.0147 10.8829C28.803 11.0946 28.4597 11.0946 28.248 10.8829L27.1392 9.7741C26.9275 9.56239 26.9275 9.21911 27.1392 9.00741Z"
      fill="url(#paint9_linear_716_2358)"
    />
    <path
      d="M23.1319 5.00102C22.9202 5.21273 22.5769 5.21273 22.3652 5.00102L21.2564 3.89226C21.0447 3.68055 21.0447 3.33728 21.2564 3.12557C21.4681 2.91387 21.8114 2.91387 22.0231 3.12557L23.1319 4.23434C23.3436 4.44611 23.3436 4.78932 23.1319 5.00102Z"
      fill="url(#paint10_linear_716_2358)"
    />
    <path
      d="M23.1319 9.00741C23.3436 9.21911 23.3436 9.56239 23.1319 9.7741L22.0231 10.8829C21.8114 11.0946 21.4681 11.0946 21.2564 10.8829C21.0447 10.6712 21.0447 10.3279 21.2564 10.1162L22.3652 9.00741C22.5769 8.79571 22.9202 8.79571 23.1319 9.00741Z"
      fill="url(#paint11_linear_716_2358)"
    />
    <path
      d="M27.1392 5.00102C26.9275 4.78932 26.9275 4.44604 27.1392 4.23434L28.248 3.12557C28.4597 2.91387 28.803 2.91387 29.0147 3.12557C29.2264 3.33728 29.2264 3.68055 29.0147 3.89226L27.9059 5.00102C27.6942 5.21273 27.3509 5.21273 27.1392 5.00102Z"
      fill="url(#paint12_linear_716_2358)"
    />
    <path
      d="M30.1271 9.73498C30.1248 9.72909 30.1225 9.72325 30.1201 9.71742C29.9675 9.33774 30.1512 8.91365 30.5326 8.76532C30.5395 8.76262 30.5463 8.75993 30.5531 8.75723C30.9259 8.60915 31.3435 8.79011 31.4904 9.16325C31.4931 9.17009 31.4959 9.17699 31.4986 9.18383C31.6512 9.5635 31.4675 9.9876 31.0861 10.1359C31.0802 10.1382 31.0744 10.1405 31.0685 10.1428C30.694 10.2912 30.2751 10.1097 30.1271 9.73498Z"
      fill="url(#paint13_linear_716_2358)"
    />
    <path
      d="M20.14 4.27303C20.1423 4.27893 20.1446 4.28476 20.147 4.2906C20.2996 4.67027 20.1159 5.09436 19.7345 5.24269C19.7276 5.24539 19.7208 5.24809 19.714 5.25078C19.3412 5.39886 18.9236 5.21791 18.7767 4.84476C18.774 4.83792 18.7712 4.83102 18.7685 4.82418C18.6159 4.44451 18.7996 4.02042 19.1809 3.87209C19.1868 3.86983 19.1927 3.86751 19.1986 3.86518C19.573 3.71686 19.992 3.89831 20.14 4.27303Z"
      fill="url(#paint14_linear_716_2358)"
    />
    <path
      d="M22.4049 11.9977C22.4108 11.9954 22.4166 11.9931 22.4224 11.9907C22.8021 11.8381 23.2262 12.0218 23.3745 12.4032C23.3772 12.4101 23.3799 12.4169 23.3826 12.4238C23.5307 12.7965 23.3497 13.2141 22.9766 13.361C22.9698 13.3637 22.9629 13.3665 22.956 13.3692C22.5763 13.5218 22.1523 13.3381 22.0039 12.9567C22.0017 12.9508 21.9993 12.945 21.997 12.9391C21.8487 12.5647 22.0302 12.1457 22.4049 11.9977Z"
      fill="url(#paint15_linear_716_2358)"
    />
    <path
      d="M27.8654 2.01061C27.8595 2.01293 27.8536 2.01525 27.8478 2.01763C27.4681 2.17023 27.044 1.98651 26.8957 1.60515C26.893 1.59824 26.8903 1.59141 26.8876 1.58457C26.7395 1.2118 26.9205 0.794228 27.2936 0.647279C27.3005 0.644581 27.3074 0.64182 27.3142 0.639122C27.6939 0.486526 28.118 0.670244 28.2663 1.05161C28.2686 1.05751 28.2709 1.06334 28.2732 1.06924C28.4215 1.4437 28.2401 1.86265 27.8654 2.01061Z"
      fill="url(#paint16_linear_716_2358)"
    />
    <path
      d="M30.1271 4.27303C30.1248 4.27893 30.1225 4.28476 30.1201 4.2906C29.9675 4.67027 30.1512 5.09436 30.5326 5.24269C30.5395 5.24539 30.5463 5.24809 30.5531 5.25078C30.9259 5.39886 31.3435 5.21791 31.4904 4.84476C31.4931 4.83792 31.4959 4.83102 31.4986 4.82418C31.6512 4.44451 31.4675 4.02042 31.0861 3.87209C31.0802 3.86983 31.0744 3.86751 31.0685 3.86518C30.694 3.71686 30.2751 3.89831 30.1271 4.27303Z"
      fill="url(#paint17_linear_716_2358)"
    />
    <path
      d="M20.14 9.73498C20.1423 9.72909 20.1446 9.72325 20.147 9.71742C20.2996 9.33774 20.1159 8.91365 19.7345 8.76532C19.7276 8.76262 19.7208 8.75993 19.714 8.75723C19.3412 8.60915 18.9236 8.79011 18.7767 9.16325C18.774 9.17009 18.7712 9.17699 18.7685 9.18383C18.6159 9.5635 18.7996 9.9876 19.1809 10.1359C19.1868 10.1382 19.1927 10.1405 19.1986 10.1428C19.573 10.2912 19.992 10.1097 20.14 9.73498Z"
      fill="url(#paint18_linear_716_2358)"
    />
    <path
      d="M22.4049 2.01061C22.4108 2.01293 22.4166 2.01525 22.4224 2.01763C22.8021 2.17023 23.2262 1.98651 23.3745 1.60515C23.3772 1.59824 23.3799 1.59141 23.3826 1.58457C23.5307 1.2118 23.3497 0.794228 22.9766 0.647279C22.9698 0.644581 22.9629 0.64182 22.956 0.639122C22.5763 0.486526 22.1523 0.670244 22.0039 1.05161C22.0017 1.05751 21.9993 1.06334 21.997 1.06924C21.8487 1.4437 22.0302 1.86265 22.4049 2.01061Z"
      fill="url(#paint19_linear_716_2358)"
    />
    <path
      d="M27.8654 11.9977C27.8595 11.9954 27.8536 11.9931 27.8478 11.9907C27.4681 11.8381 27.044 12.0218 26.8957 12.4032C26.893 12.4101 26.8903 12.4169 26.8876 12.4238C26.7395 12.7965 26.9205 13.2141 27.2936 13.361C27.3005 13.3637 27.3074 13.3665 27.3142 13.3692C27.6939 13.5218 28.118 13.3381 28.2663 12.9567C28.2686 12.9508 28.2709 12.945 28.2732 12.9391C28.4215 12.5647 28.2401 12.1457 27.8654 11.9977Z"
      fill="url(#paint20_linear_716_2358)"
    />
    <path
      d="M29.7795 2.88326C29.9943 2.88326 30.1684 2.70914 30.1684 2.49436C30.1684 2.27958 29.9943 2.10547 29.7795 2.10547C29.5647 2.10547 29.3906 2.27958 29.3906 2.49436C29.3906 2.70914 29.5647 2.88326 29.7795 2.88326Z"
      fill="url(#paint21_linear_716_2358)"
    />
    <path
      d="M20.6467 2.70552C20.8615 2.70552 21.0356 2.53141 21.0356 2.31663C21.0356 2.10185 20.8615 1.92773 20.6467 1.92773C20.4319 1.92773 20.2578 2.10185 20.2578 2.31663C20.2578 2.53141 20.4319 2.70552 20.6467 2.70552Z"
      fill="url(#paint22_linear_716_2358)"
    />
    <path
      d="M29.6115 12.0678C29.8263 12.0678 30.0004 11.8937 30.0004 11.6789C30.0004 11.4642 29.8263 11.29 29.6115 11.29C29.3968 11.29 29.2227 11.4642 29.2227 11.6789C29.2227 11.8937 29.3968 12.0678 29.6115 12.0678Z"
      fill="url(#paint23_linear_716_2358)"
    />
    <path
      d="M20.5334 11.7876C20.7482 11.7876 20.9223 11.6134 20.9223 11.3987C20.9223 11.1839 20.7482 11.0098 20.5334 11.0098C20.3186 11.0098 20.1445 11.1839 20.1445 11.3987C20.1445 11.6134 20.3186 11.7876 20.5334 11.7876Z"
      fill="url(#paint24_linear_716_2358)"
    />
    <path
      d="M11.9 17.6859C11.5043 17.6859 11.1836 17.3651 11.1836 16.9694C11.1836 11.0439 16.0044 6.22314 21.9298 6.22314C22.3256 6.22314 22.6463 6.5439 22.6463 6.93957C22.6463 7.33524 22.3255 7.65599 21.9298 7.65599C16.7944 7.65599 12.6165 11.8339 12.6165 16.9694C12.6164 17.3651 12.2958 17.6859 11.9 17.6859Z"
      fill="url(#paint25_linear_716_2358)"
    />
    <path
      d="M2.22266 9.25342H6.01572V15.0134H2.22266V9.25342Z"
      fill="url(#paint26_linear_716_2358)"
    />
    <path
      d="M6.01572 13.6585V9.25342H2.22266V9.86543L6.01572 13.6585Z"
      fill="url(#paint27_linear_716_2358)"
    />
    <path
      d="M0.708077 29.8635C0.313975 29.8635 -0.00508373 29.6944 6.13644e-05 29.4874C0.0881555 25.9268 0.993944 23.1274 2.09845 23.1274C3.24154 23.1274 4.17186 26.1258 4.20304 29.8635H0.708077Z"
      fill="url(#paint28_linear_716_2358)"
    />
    <path
      d="M20.5359 15.3845L20.5332 29.1779C20.5331 29.5564 20.2262 29.8632 19.8477 29.8632L2.90418 29.8628C2.52557 29.8628 2.21869 29.5559 2.21875 29.1773L2.22151 15.3812L10.0168 7.38208L11.3802 5.67334L12.7436 7.38258L20.5359 15.3845Z"
      fill="url(#paint29_linear_716_2358)"
    />
    <path
      d="M20.5376 14.8882L20.535 26.1745L11.9551 26.189L2.22266 16.452L2.22291 14.8849L11.3819 5.67383L20.5376 14.8882Z"
      fill="url(#paint30_linear_716_2358)"
    />
    <path
      d="M18.6154 26.1898L20.5342 26.1745L20.5335 19.5142L13.3656 12.3438C12.8801 11.7924 12.1698 11.4435 11.3792 11.4434C9.91918 11.4431 8.7311 12.6307 8.73085 14.0908L8.73047 16.0381C8.73041 16.2924 8.86706 16.5149 9.0703 16.6376C9.07036 16.6376 18.6133 26.1864 18.6154 26.1898Z"
      fill="url(#paint31_linear_716_2358)"
    />
    <path
      d="M14.8939 26.1747H11.6348L5.93838 26.1742L3.82588 24.0611C3.60928 23.9202 3.46484 23.6768 3.46484 23.3997L3.46541 19.4372C3.46541 19.0029 3.81885 18.6494 4.25324 18.6494L7.10989 18.65C7.38767 18.65 7.63106 18.7944 7.77192 19.0116L7.7504 19.0285L9.16241 20.441L14.8939 26.1747Z"
      fill="url(#paint32_linear_716_2358)"
    />
    <path
      d="M7.10859 23.7264L4.25193 23.7259C4.07179 23.7258 3.92578 23.5798 3.92578 23.3996L3.92647 19.4375C3.92653 19.2573 4.07254 19.1113 4.25275 19.1113L7.1094 19.1118C7.28954 19.1119 7.43555 19.2579 7.43555 19.4381L7.43486 23.4003C7.4348 23.5804 7.28873 23.7264 7.10859 23.7264Z"
      fill="url(#paint33_linear_716_2358)"
    />
    <path
      d="M7.10927 24.1883L4.25261 24.1878C3.81816 24.1878 3.46478 23.8343 3.46484 23.3998L3.46553 19.4377C3.4656 19.0032 3.8191 18.6498 4.25355 18.6499L7.11021 18.6504C7.54466 18.6505 7.89804 19.004 7.89797 19.4384L7.89728 23.4006C7.89722 23.8351 7.54372 24.1884 7.10927 24.1883ZM4.38826 23.2644L6.97386 23.2649L6.97455 19.5738L4.38895 19.5733L4.38826 23.2644Z"
      fill="url(#paint34_linear_716_2358)"
    />
    <path
      d="M20.3636 25.9463V26.1746H11.637L9.5251 24.0615C9.30794 23.9212 9.16406 23.6772 9.16406 23.4006L9.16463 20.4409V19.4382C9.16463 19.0038 9.51807 18.6504 9.95245 18.6504L12.8091 18.651C13.0863 18.651 13.3303 18.7953 13.4706 19.012L13.4496 19.0294L14.9734 20.5538L20.3636 25.9463Z"
      fill="url(#paint35_linear_716_2358)"
    />
    <path
      d="M12.8039 23.7279L9.94724 23.7274C9.7671 23.7273 9.62109 23.5813 9.62109 23.4011L9.62178 19.4389C9.62185 19.2588 9.76785 19.1128 9.94806 19.1128L12.8047 19.1133C12.9849 19.1134 13.1309 19.2594 13.1309 19.4396L13.1302 23.4017C13.1302 23.5819 12.9841 23.7279 12.8039 23.7279Z"
      fill="url(#paint36_linear_716_2358)"
    />
    <path
      d="M12.8046 24.1893L9.94792 24.1888C9.51347 24.1888 9.16009 23.8353 9.16016 23.4008L9.16085 19.4386C9.16091 19.0042 9.51441 18.6508 9.94886 18.6509L12.8055 18.6514C13.24 18.6514 13.5933 19.0049 13.5933 19.4394L13.5926 23.4016C13.5925 23.836 13.239 24.1894 12.8046 24.1893ZM10.0836 23.2654L12.6692 23.2658L12.6699 19.5747L10.0843 19.5743L10.0836 23.2654Z"
      fill="url(#paint37_linear_716_2358)"
    />
    <path
      d="M20.5386 20.2869L20.5369 26.1749H17.445L15.3343 24.063C15.1165 23.9226 14.9727 23.6787 14.9727 23.4021L14.9732 20.5542V19.4391C14.9732 19.0053 15.3267 18.6519 15.7616 18.6519L18.6177 18.6524C18.8948 18.6524 19.1389 18.7969 19.2792 19.0135L19.2908 19.0222L19.2832 19.0303L20.5386 20.2869Z"
      fill="url(#paint38_linear_716_2358)"
    />
    <path
      d="M18.6164 23.7289L15.7597 23.7283C15.5796 23.7283 15.4336 23.5823 15.4336 23.4021L15.4343 19.4399C15.4343 19.2598 15.5804 19.1138 15.7606 19.1138L18.6172 19.1143C18.7973 19.1143 18.9434 19.2603 18.9434 19.4405L18.9427 23.4027C18.9426 23.5828 18.7965 23.7289 18.6164 23.7289Z"
      fill="url(#paint39_linear_716_2358)"
    />
    <path
      d="M18.6171 24.1913L15.7604 24.1908C15.326 24.1907 14.9726 23.8372 14.9727 23.4028L14.9733 19.4406C14.9734 19.0061 15.3269 18.6528 15.7614 18.6528L18.618 18.6533C19.0525 18.6534 19.4058 19.0069 19.4058 19.4413L19.4051 23.4035C19.405 23.838 19.0515 24.1914 18.6171 24.1913ZM15.8961 23.2674L18.4817 23.2678L18.4824 19.5767L15.8968 19.5763L15.8961 23.2674Z"
      fill="url(#paint40_linear_716_2358)"
    />
    <path
      d="M22.6082 16.1622L11.9331 5.47982C11.6288 5.17544 11.1354 5.17532 10.8309 5.47963L0.149404 16.1574C-0.049749 16.3564 -0.0498118 16.6792 0.149278 16.8784L0.604369 17.3336C0.803459 17.5327 1.12622 17.5328 1.32537 17.3337L11.2367 7.42599C11.3168 7.34586 11.4467 7.34592 11.5268 7.42605L21.4319 17.3382C21.6309 17.5374 21.9537 17.5374 22.1529 17.3383L22.6081 16.8833C22.8072 16.6842 22.8072 16.3614 22.6082 16.1622Z"
      fill="url(#paint41_linear_716_2358)"
    />
    <path
      d="M11.9303 5.47979C11.7782 5.32757 11.5787 5.25146 11.3793 5.25146L11.3789 7.36598C11.4314 7.36598 11.4839 7.38605 11.524 7.42608L21.4291 17.3382C21.6282 17.5373 21.951 17.5374 22.1501 17.3383L22.6053 16.8832C22.8045 16.6841 22.8045 16.3614 22.6054 16.1622L11.9303 5.47979Z"
      fill="url(#paint42_linear_716_2358)"
    />
    <path
      d="M13.3249 16.3167L9.4303 16.316C9.27677 16.316 9.15228 16.1915 9.15234 16.038L9.15272 14.0907C9.15297 12.8617 10.1494 11.8655 11.3785 11.8657C12.6075 11.866 13.6036 12.8624 13.6034 14.0915L13.603 16.0388C13.6029 16.1922 13.4784 16.3167 13.3249 16.3167Z"
      fill="url(#paint43_linear_716_2358)"
    />
    <path
      d="M11.3792 11.4434C9.91918 11.4431 8.7311 12.6307 8.73085 14.0908L8.73047 16.0381C8.73041 16.4244 9.04457 16.7387 9.43089 16.7388L13.3255 16.7395C13.7118 16.7396 14.0262 16.4254 14.0262 16.039L14.0266 14.0917C14.0269 12.6317 12.8392 11.4437 11.3792 11.4434ZM13.167 13.8662L11.8013 13.866L11.8015 12.339C12.5212 12.5127 13.0734 13.119 13.167 13.8662ZM10.9565 12.3389L10.9562 13.8658L9.59052 13.8656C9.68432 13.1184 10.2368 12.5123 10.9565 12.3389ZM9.57577 14.7106L10.956 14.7109L10.9559 15.8939L9.57558 15.8937L9.57577 14.7106ZM11.8009 15.8941L11.8011 14.7111L13.1814 14.7113L13.1812 15.8944L11.8009 15.8941Z"
      fill="url(#paint44_linear_716_2358)"
    />
    <path
      d="M19.687 27.9393L3.06631 27.9363C2.32843 27.9362 1.73034 27.3379 1.73047 26.6C1.73059 25.8621 2.32887 25.264 3.06675 25.2642L19.6875 25.2672C20.4254 25.2673 21.0235 25.8656 21.0233 26.6035C21.0232 27.3413 20.4249 27.9394 19.687 27.9393Z"
      fill="url(#paint45_linear_716_2358)"
    />
    <path
      d="M6.21227 9.86336H2.02805C1.92841 9.86336 1.84766 9.78261 1.84766 9.68297V8.65012C1.84766 8.55048 1.92841 8.46973 2.02805 8.46973H6.21227C6.31191 8.46973 6.39266 8.55048 6.39266 8.65012V9.68297C6.39266 9.78261 6.31184 9.86336 6.21227 9.86336Z"
      fill="url(#paint46_linear_716_2358)"
    />
    <path
      d="M29.3817 29.8634C29.7758 29.8634 30.0948 29.5414 30.0897 29.1474C30.0016 22.3682 29.0958 17.0386 27.9913 17.0386C26.8482 17.0386 25.9179 22.7473 25.8867 29.8635H29.3817V29.8634Z"
      fill="url(#paint47_linear_716_2358)"
    />
    <path
      d="M25.8867 29.8636H29.3817C29.7758 29.8636 30.0948 29.5416 30.0897 29.1476C30.0804 28.4275 30.0617 27.724 30.0346 27.0413L26.2082 23.2148C26.0138 25.1502 25.8974 27.4242 25.8867 29.8636Z"
      fill="url(#paint48_linear_716_2358)"
    />
    <path
      d="M27.5478 29.8634C27.8387 29.8634 28.0664 29.616 28.0459 29.3258C27.7233 24.7551 25.6598 21.23 23.1623 21.23C20.6647 21.23 18.6012 24.7551 18.2786 29.3258C18.2582 29.616 18.4859 29.8634 18.7767 29.8634H27.5478Z"
      fill="url(#paint49_linear_716_2358)"
    />
    <path
      d="M23.4148 27.0289C22.4309 25.1522 21.5082 23.6963 21.354 23.7771C21.1999 23.8579 21.8725 25.4449 22.8565 27.3217C23.0552 27.7008 23.6136 27.4081 23.4148 27.0289Z"
      fill="url(#paint50_linear_716_2358)"
    />
    <path
      d="M23.3934 28.973C21.9383 27.4325 20.6562 26.2806 20.5296 26.4001C20.4031 26.5197 21.48 27.8654 22.9351 29.4059C23.229 29.7171 23.6874 29.2842 23.3934 28.973Z"
      fill="url(#paint51_linear_716_2358)"
    />
    <path
      d="M22.9054 27.0289C23.8894 25.1522 24.8121 23.6963 24.9662 23.7771C25.1204 23.8579 24.4478 25.4449 23.4638 27.3217C23.265 27.7008 22.7066 27.4081 22.9054 27.0289Z"
      fill="url(#paint52_linear_716_2358)"
    />
    <path
      d="M22.9266 28.973C24.3817 27.4325 25.6638 26.2806 25.7904 26.4001C25.917 26.5197 24.84 27.8654 23.3849 29.4059C23.091 29.7171 22.6327 29.2842 22.9266 28.973Z"
      fill="url(#paint53_linear_716_2358)"
    />
    <path
      d="M23.4781 28.1985C23.4781 25.3156 23.337 22.9785 23.1629 22.9785C22.9888 22.9785 22.8477 25.3156 22.8477 28.1985C22.8477 28.7809 22.8535 29.3404 22.8642 29.8635H23.4617C23.4723 29.3403 23.4781 28.7809 23.4781 28.1985Z"
      fill="url(#paint54_linear_716_2358)"
    />
    <path
      d="M27.5496 29.8634C27.8405 29.8634 28.0682 29.616 28.0477 29.3258C27.7251 24.7551 25.6616 21.23 23.1641 21.23V29.8634H27.5496Z"
      fill="url(#paint55_linear_716_2358)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_716_2358"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.6108 6.30591) scale(2.26528)"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.4169" stopColor="#FFD744" />
        <stop offset="0.6278" stopColor="#FFCF3F" />
        <stop offset="0.7926" stopColor="#FFC237" />
        <stop offset="0.9326" stopColor="#FFAF2C" />
        <stop offset="1" stopColor="#FFA325" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_716_2358"
        x1="25.4504"
        y1="7.32005"
        x2="23.2755"
        y2="5.14517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" stopOpacity="0" />
        <stop offset="1" stopColor="#FBED21" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_2358"
        x1="25.9064"
        y1="7.07255"
        x2="29.2932"
        y2="4.20591"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E87264" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7044" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_2358"
        x1="24.7422"
        y1="4.14489"
        x2="25.5238"
        y2="4.14489"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_2358"
        x1="24.6516"
        y1="0.96018"
        x2="26.2023"
        y2="3.03639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_716_2358"
        x1="25.5238"
        y1="9.86315"
        x2="24.7422"
        y2="9.86315"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_716_2358"
        x1="25.6204"
        y1="13.025"
        x2="24.0697"
        y2="10.9487"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_716_2358"
        x1="27.9935"
        y1="6.61328"
        x2="27.9935"
        y2="7.39496"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_716_2358"
        x1="31.1699"
        y1="6.50909"
        x2="29.0937"
        y2="8.05983"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_716_2358"
        x1="28.4579"
        y1="9.5348"
        x2="27.6913"
        y2="10.3014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_716_2358"
        x1="21.8294"
        y1="4.43288"
        x2="22.5959"
        y2="3.66632"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_716_2358"
        x1="22.6121"
        y1="10.3229"
        x2="21.8456"
        y2="9.55633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_716_2358"
        x1="27.7151"
        y1="3.69528"
        x2="28.4816"
        y2="4.46184"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_716_2358"
        x1="31.0783"
        y1="8.85732"
        x2="30.484"
        y2="10.2282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADFB" />
        <stop offset="1" stopColor="#3F3CED" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_716_2358"
        x1="19.0355"
        y1="5.24986"
        x2="19.6298"
        y2="3.87898"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADFB" />
        <stop offset="1" stopColor="#3F3CED" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_716_2358"
        x1="23.2639"
        y1="13.0762"
        x2="21.893"
        y2="12.4819"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADFB" />
        <stop offset="1" stopColor="#3F3CED" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_716_2358"
        x1="26.8699"
        y1="1.03333"
        x2="28.2408"
        y2="1.62768"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADFB" />
        <stop offset="1" stopColor="#3F3CED" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_716_2358"
        x1="31.1573"
        y1="5.29573"
        x2="30.5629"
        y2="3.92485"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADFB" />
        <stop offset="1" stopColor="#3F3CED" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_716_2358"
        x1="19.1144"
        y1="8.9032"
        x2="19.7087"
        y2="10.2741"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADFB" />
        <stop offset="1" stopColor="#3F3CED" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_716_2358"
        x1="23.3429"
        y1="1.07714"
        x2="21.9719"
        y2="1.67151"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADFB" />
        <stop offset="1" stopColor="#3F3CED" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_716_2358"
        x1="26.9488"
        y1="13.12"
        x2="28.3197"
        y2="12.5257"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADFB" />
        <stop offset="1" stopColor="#3F3CED" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_716_2358"
        x1="29.6159"
        y1="2.15847"
        x2="29.8928"
        y2="2.88576"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_716_2358"
        x1="20.5229"
        y1="2.64443"
        x2="20.8254"
        y2="1.92803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_716_2358"
        x1="29.4868"
        y1="12.0037"
        x2="29.7894"
        y2="11.2873"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_716_2358"
        x1="20.222"
        y1="11.2746"
        x2="20.9384"
        y2="11.5772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_716_2358"
        x1="14.4463"
        y1="9.48589"
        x2="17.105"
        y2="12.1446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_716_2358"
        x1="2.70975"
        y1="10.1199"
        x2="4.77997"
        y2="13.0773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_716_2358"
        x1="6.43317"
        y1="11.8734"
        x2="3.92499"
        y2="9.36523"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F82814" stopOpacity="0" />
        <stop offset="1" stopColor="#C0272D" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_716_2358"
        x1="0.854587"
        y1="26.4954"
        x2="2.25179"
        y2="26.4954"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_716_2358"
        x1="11.3815"
        y1="17.9344"
        x2="11.3815"
        y2="35.0593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9B62CD" />
        <stop offset="0.3003" stopColor="#985DCB" />
        <stop offset="0.6716" stopColor="#8F4EC7" />
        <stop offset="1" stopColor="#833AC1" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_716_2358"
        x1="9.18215"
        y1="13.4399"
        x2="5.04794"
        y2="9.30564"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#833AC1" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_716_2358"
        x1="14.1289"
        y1="17.4076"
        x2="9.24348"
        y2="12.5222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#833AC1" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_716_2358"
        x1="7.35146"
        y1="23.0887"
        x2="2.53264"
        y2="18.2699"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#833AC1" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_716_2358"
        x1="3.93024"
        y1="21.4178"
        x2="7.43925"
        y2="21.4178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_716_2358"
        x1="3.46484"
        y1="21.4192"
        x2="7.89804"
        y2="21.4192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_716_2358"
        x1="13.0016"
        y1="23.0407"
        x2="8.2429"
        y2="18.282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#833AC1" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_716_2358"
        x1="9.62555"
        y1="21.4183"
        x2="13.1346"
        y2="21.4183"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_716_2358"
        x1="9.16016"
        y1="21.4201"
        x2="13.5933"
        y2="21.4201"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_716_2358"
        x1="18.6702"
        y1="22.902"
        x2="15.1914"
        y2="19.4233"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#833AC1" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_716_2358"
        x1="15.438"
        y1="21.4182"
        x2="18.9471"
        y2="21.4182"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_716_2358"
        x1="14.9727"
        y1="21.4221"
        x2="19.4058"
        y2="21.4221"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_716_2358"
        x1="11.3819"
        y1="12.4734"
        x2="11.3819"
        y2="21.7473"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_716_2358"
        x1="14.5889"
        y1="9.70448"
        x2="5.47647"
        y2="2.27954"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E87264" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7044" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_716_2358"
        x1="9.15542"
        y1="14.089"
        x2="13.6061"
        y2="14.089"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_716_2358"
        x1="8.73047"
        y1="14.0915"
        x2="14.0266"
        y2="14.0915"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_716_2358"
        x1="11.3819"
        y1="25.9065"
        x2="11.3819"
        y2="27.9134"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_716_2358"
        x1="3.38284"
        y1="8.11321"
        x2="4.46583"
        y2="9.66032"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_716_2358"
        x1="26.8721"
        y1="23.451"
        x2="29.4041"
        y2="23.451"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" />
        <stop offset="0.2193" stopColor="#44AD71" />
        <stop offset="0.4983" stopColor="#359F6E" />
        <stop offset="0.8081" stopColor="#1B8769" />
        <stop offset="1" stopColor="#077565" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_716_2358"
        x1="30.5957"
        y1="29.4576"
        x2="24.0033"
        y2="25.2276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005A01" stopOpacity="0" />
        <stop offset="0.2523" stopColor="#005C08" stopOpacity="0.252" />
        <stop offset="0.6256" stopColor="#00611C" stopOpacity="0.626" />
        <stop offset="1" stopColor="#006837" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_716_2358"
        x1="20.9575"
        y1="25.0272"
        x2="27.4763"
        y2="31.5461"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_716_2358"
        x1="21.7819"
        y1="25.9213"
        x2="22.8069"
        y2="25.3844"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_716_2358"
        x1="21.4839"
        y1="28.3491"
        x2="22.3248"
        y2="27.554"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_716_2358"
        x1="24.518"
        y1="25.9785"
        x2="23.493"
        y2="25.4416"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_716_2358"
        x1="24.7848"
        y1="28.2431"
        x2="23.944"
        y2="27.448"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_716_2358"
        x1="22.5329"
        y1="26.421"
        x2="23.6899"
        y2="26.421"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_716_2358"
        x1="26.2944"
        y1="28.3209"
        x2="19.6569"
        y2="20.8059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" stopOpacity="0" />
        <stop offset="1" stopColor="#009245" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconNewlyBuilt;
