import React, { useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, OverlayView } from '@react-google-maps/api';
import _, { get as lodashGet } from 'lodash';
import { IconHouseMarker } from "components/Svgs";
import { HOUSE_PLACES_DATA } from "containers/HouseDetails/constants";
import PlaceRatingView from "../Places/PlaceRatingView";
import PlaceDescriptionView from "../Places/PlaceDescriptionView";
import HousePlacesModalView from "../HousePlacesModalView";
import "./styles.scss";

const MapView = ({ houseDetails }) => {
  const mapRef = useRef(null);
  const initialCenter = new window.google.maps.LatLng(
    houseDetails.address.location.lat,
    houseDetails.address.location.lon
  );
  const [center, setCenter] = useState(initialCenter);
  const [hoveredPlace, setHoveredPlace] = useState(null);
  const [curCheckBoxPlaces, setCurCheckBoxPlaces] = useState([])

  const getCoordinates = (item, valueKey) => ({
    lat: lodashGet(item, `${valueKey}.lat`),
    lng: lodashGet(item, `${valueKey}.lon`) || lodashGet(item, `${valueKey}.lng`),
  });

  const handleLoad = map => {
    // uncomment it if it needs to fit all places on the map load
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    if (mapRef.current) {
      setCenter(mapRef.center || center);
    }
    mapRef.current = map;
  };

  const updateHoveredPlace = (item, kind) => {
    if (_.isNull(item) || _.has(item, "kind")) {
      setHoveredPlace(item);
    } else {
      const hoveredItem = _.cloneDeep(item);
      hoveredItem.kind = kind;
      setHoveredPlace(hoveredItem);
    }
  };

  if (_.isEmpty(houseDetails)) {
    return null;
  }

  const renderHoveredPlace = () => {
    if (_.isNull(hoveredPlace)) {
      return null;
    }
    return (
      <InfoWindow position={getCoordinates(hoveredPlace, "coordinates")}>
        <div className="place-info-card">
          <PlaceRatingView isMap item={hoveredPlace} />
          <PlaceDescriptionView
            isDataExist
            isMap
            item={hoveredPlace}
          />
        </div>
      </InfoWindow>
    );
  };

  const renderMarkers = () => (
    HOUSE_PLACES_DATA.map(data => (
      curCheckBoxPlaces.includes(data.key) &&
      lodashGet(houseDetails, data.valueKey || data.key, []).slice(0, 3).map(item => (
        <Marker
          id={`${data.key}_${item.name}`}
          icon={data.icon}
          position={getCoordinates(item, "coordinates")}
          onMouseOver={() => updateHoveredPlace(item, data.kind)}
          onMouseOut={() => updateHoveredPlace(null)}
        />
      ))
    ))
  );

  const renderOverlay = () => (
    <OverlayView
      position={getCoordinates(houseDetails, "address.location")}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div id={`marker${houseDetails.id}`} className="map-marker-container">
        <IconHouseMarker />
      </div>
    </OverlayView>
  );

  return (
    <div className="details-map">
      <div className="map-view-wrapper">
        <div className="map-view" >
          <div className="google-container-wrapper">
            <HousePlacesModalView setCurCheckBoxPlaces={setCurCheckBoxPlaces} />
            <GoogleMap
              options={{ mapId: process.env.REACT_APP_GOOGLE_MAP_ID }}
              mapContainerClassName="google-container"
              onLoad={handleLoad}
              zoom={17}
              center={center}
            >
              {renderOverlay()}
              {renderMarkers()}
              {renderHoveredPlace()}
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapView;
