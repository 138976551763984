import React from "react";

const IconRestaurants = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="url(#paint0_linear_6689_15533)"/>
    <path d="M8.0004 15.5755C12.1843 15.5755 15.576 12.1838 15.576 7.99992C15.576 3.81603 12.1843 0.424316 8.0004 0.424316C3.81652 0.424316 0.424805 3.81603 0.424805 7.99992C0.424805 12.1838 3.81652 15.5755 8.0004 15.5755Z" fill="url(#paint1_linear_6689_15533)"/>
    <path d="M7.99991 12.9227C10.7187 12.9227 12.9227 10.7187 12.9227 7.99991C12.9227 5.28114 10.7187 3.07715 7.99991 3.07715C5.28114 3.07715 3.07715 5.28114 3.07715 7.99991C3.07715 10.7187 5.28114 12.9227 7.99991 12.9227Z" fill="url(#paint2_linear_6689_15533)"/>
    <path d="M15.2792 11.3229L6.2637 2.30742C6.21513 2.15871 6.07342 2.05242 5.90749 2.05703C5.71336 2.06327 5.55515 2.24128 5.55515 2.43542V3.02682L4.80556 2.30704C4.7549 2.16912 4.62711 2.06186 4.47725 2.05703C4.27166 2.05129 4.10304 2.21578 4.10304 2.42032V3.02707C4.08603 3.01791 3.35342 2.3072 3.35342 2.3072C3.30266 2.16922 3.1745 2.06186 3.0246 2.05703C2.81902 2.05129 2.65039 2.21578 2.65039 2.42032V4.65468C2.65039 5.16251 2.88189 5.62883 3.25823 5.93713C3.32754 6.02174 3.40528 6.09954 3.49042 6.16932C3.55973 6.25393 3.63747 6.33173 3.72261 6.4015C3.79191 6.48612 3.86966 6.56392 3.9548 6.63369C3.97162 6.65421 3.98893 6.67432 4.00672 6.69402V7.02801C4.00672 8.40411 3.86671 9.77713 3.58881 11.1256L3.543 11.3495C3.42278 11.9334 3.36189 12.5283 3.36189 13.1248V13.131C3.36189 13.4146 3.50736 13.664 3.72766 13.8092L5.52873 15.6102C6.30715 15.8628 7.13758 15.9999 8.00017 15.9999C11.2328 15.9999 14.0174 14.0824 15.2792 11.3229Z" fill="url(#paint3_linear_6689_15533)"/>
    <path d="M16.0003 8.00005C16.0003 7.05564 15.8362 6.14966 15.5357 5.30853L12.8574 2.6302C12.5337 2.28576 12.0773 2.06737 11.5695 2.05771C10.552 2.03791 9.71875 2.89046 9.71875 3.9085V4.6153C9.71875 5.13941 9.94118 5.61144 10.2964 5.94273L11.0666 6.71453C11.072 6.74866 11.0751 6.78336 11.0751 6.81846V7.02822C11.0751 8.40431 10.9351 9.77733 10.6571 11.1259L10.6113 11.3497C10.4911 11.9336 10.4302 12.5285 10.4302 13.125V13.1312C10.4302 13.3783 10.5407 13.5994 10.7148 13.7482L11.9339 14.9673C14.3615 13.5936 16.0003 10.9884 16.0003 8.00005Z" fill="url(#paint4_linear_6689_15533)"/>
    <path d="M5.90746 2.05703C5.71333 2.06327 5.55512 2.24128 5.55512 2.43542V3.8464C5.55512 4.04053 5.39741 4.21904 5.20327 4.22478C4.99769 4.23102 4.82906 4.06604 4.82906 3.86202V2.43542C4.82906 2.24128 4.67135 2.06327 4.47722 2.05703C4.27163 2.05129 4.10301 2.21578 4.10301 2.42033V3.8464C4.10301 4.04053 3.94479 4.21904 3.75116 4.22478C3.54558 4.23102 3.37695 4.06604 3.37695 3.86202V2.43542C3.37695 2.24128 3.21874 2.06327 3.0246 2.05703C2.81902 2.05129 2.65039 2.21578 2.65039 2.42033V4.65468C2.65039 5.3802 3.12245 6.02143 3.81519 6.23743L3.81726 6.23796C3.9297 6.27281 4.00672 6.37744 4.00672 6.49559V7.02801C4.00672 8.40411 3.86671 9.77713 3.58881 11.1256L3.543 11.3495C3.42278 11.9334 3.36189 12.5283 3.36189 13.1248V13.131C3.36189 13.5796 3.72569 13.9429 4.17435 13.9429H4.75778C5.20644 13.9429 5.57024 13.5796 5.57024 13.131V13.1248C5.57024 12.5283 5.50935 11.9334 5.38913 11.3495L5.34332 11.1256C5.06539 9.77713 4.92538 8.40414 4.92538 7.02801V6.49192C4.92538 6.37584 5.00083 6.27332 5.1117 6.23896L5.11691 6.23739C5.46354 6.12913 5.75449 5.91523 5.95901 5.6373C6.16356 5.35884 6.28171 5.01741 6.28171 4.65465V2.42029C6.28171 2.21578 6.11305 2.05129 5.90746 2.05703Z" fill="url(#paint5_linear_6689_15533)"/>
    <path d="M5.91848 2.05713C5.869 2.05713 5.82188 2.06701 5.77899 2.08493C5.88033 2.13205 5.95054 2.23479 5.95054 2.35404V4.18118C5.95054 4.47762 5.85416 4.75708 5.68685 4.98447C5.51954 5.21186 5.28155 5.38695 4.99832 5.47507C4.82511 5.52904 4.64556 5.55589 4.46602 5.55589C4.28647 5.55589 4.1069 5.52904 3.93372 5.47507C3.36726 5.29882 2.9815 4.77427 2.9815 4.18118V2.35404C2.9815 2.27203 3.01472 2.1978 3.06843 2.14409C3.09271 2.11981 3.12123 2.09954 3.15304 2.08496C3.11016 2.06704 3.06303 2.05716 3.01353 2.05716C2.91314 2.05716 2.82244 2.09769 2.75668 2.16342C2.69118 2.22918 2.65039 2.32013 2.65039 2.42027V4.65459C2.65039 5.38036 3.12236 6.02152 3.81516 6.23736C4.02724 6.30334 4.2466 6.33635 4.46599 6.33635C4.68538 6.33635 4.90473 6.30334 5.11681 6.23736C5.4632 6.12944 5.75443 5.91523 5.95898 5.63721C6.16353 5.35915 6.28155 5.01747 6.28155 4.65459V2.42023C6.28161 2.21973 6.11901 2.05713 5.91848 2.05713Z" fill="url(#paint6_linear_6689_15533)"/>
    <path d="M11.5695 2.05771C10.552 2.03791 9.71875 2.89046 9.71875 3.9085V4.6153C9.71875 5.3273 10.1289 5.94352 10.7253 6.24071C10.9424 6.34897 11.0751 6.57589 11.0751 6.81843V7.02819C11.0751 8.40428 10.9351 9.7773 10.6571 11.1258L10.6113 11.3496C10.4911 11.9336 10.4302 12.5285 10.4302 13.1249V13.1312C10.4302 13.5798 10.794 13.9431 11.2427 13.9431H11.8261C12.2748 13.9431 12.6386 13.5798 12.6386 13.1312V13.1249C12.6386 12.5285 12.5777 11.9336 12.4574 11.3496L12.4116 11.1258C12.1337 9.7773 11.9937 8.40432 11.9937 7.02819V6.81843C11.9937 6.57589 12.1264 6.34897 12.3435 6.24071C12.9399 5.94352 13.3501 5.3273 13.3501 4.6153V3.87312C13.3501 2.88212 12.5563 2.07644 11.5695 2.05771Z" fill="url(#paint7_linear_6689_15533)"/>
    <path d="M11.5343 6.43065C10.5316 6.43065 9.71875 5.61779 9.71875 4.61506V3.87272C9.71875 2.86999 10.5316 2.05713 11.5343 2.05713C12.5371 2.05713 13.3499 2.86999 13.3499 3.87272V4.61506C13.3499 5.61779 12.5371 6.43065 11.5343 6.43065Z" fill="url(#paint8_linear_6689_15533)"/>
    <path d="M11.5339 5.97958C10.7412 5.97958 10.0986 5.33698 10.0986 4.54429V3.94335C10.0986 3.15066 10.7412 2.50806 11.5339 2.50806C12.3266 2.50806 12.9692 3.15066 12.9692 3.94335V4.54429C12.9693 5.33698 12.3266 5.97958 11.5339 5.97958Z" fill="url(#paint9_linear_6689_15533)"/>
    <defs>
    <linearGradient id="paint0_linear_6689_15533" x1="6.22083" y1="5.39053" x2="11.9546" y2="13.8001" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F8F6FB"/>
    <stop offset="1" stopColor="#EFDCFB"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6689_15533" x1="0.424805" y1="7.99991" x2="15.576" y2="7.99991" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F8F6FB"/>
    <stop offset="1" stopColor="#EFDCFB"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6689_15533" x1="9.88737" y1="9.87112" x2="5.85635" y2="5.87482" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F8F6FB"/>
    <stop offset="1" stopColor="#EFDCFB"/>
    </linearGradient>
    <linearGradient id="paint3_linear_6689_15533" x1="9.72569" y1="9.61107" x2="2.46285" y2="8.25581" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EFDCFB" stopOpacity="0"/>
    <stop offset="0.4856" stopColor="#BAAAD6" stopOpacity="0.486"/>
    <stop offset="1" stopColor="#8679B2"/>
    </linearGradient>
    <linearGradient id="paint4_linear_6689_15533" x1="15.2038" y1="9.28372" x2="10.7557" y2="8.26436" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EFDCFB" stopOpacity="0"/>
    <stop offset="0.4856" stopColor="#BAAAD6" stopOpacity="0.486"/>
    <stop offset="1" stopColor="#8679B2"/>
    </linearGradient>
    <linearGradient id="paint5_linear_6689_15533" x1="2.83797" y1="7.84696" x2="6.08714" y2="8.07282" gradientUnits="userSpaceOnUse">
    <stop stopColor="#BDC2D1"/>
    <stop offset="0.2367" stopColor="#B5B6C5"/>
    <stop offset="0.6651" stopColor="#9E96A6"/>
    <stop offset="1" stopColor="#8A7889"/>
    </linearGradient>
    <linearGradient id="paint6_linear_6689_15533" x1="4.73338" y1="4.08631" x2="7.39179" y2="7.12697" gradientUnits="userSpaceOnUse">
    <stop stopColor="#6E566E" stopOpacity="0"/>
    <stop offset="0.2944" stopColor="#674D66" stopOpacity="0.294"/>
    <stop offset="1" stopColor="#593B56"/>
    </linearGradient>
    <linearGradient id="paint7_linear_6689_15533" x1="10.451" y1="8.00021" x2="13.0569" y2="8.00021" gradientUnits="userSpaceOnUse">
    <stop stopColor="#BDC2D1"/>
    <stop offset="0.2367" stopColor="#B5B6C5"/>
    <stop offset="0.6651" stopColor="#9E96A6"/>
    <stop offset="1" stopColor="#8A7889"/>
    </linearGradient>
    <linearGradient id="paint8_linear_6689_15533" x1="11.9911" y1="4.46616" x2="19.0367" y2="7.89469" gradientUnits="userSpaceOnUse">
    <stop stopColor="#BDC2D1"/>
    <stop offset="0.2367" stopColor="#B5B6C5"/>
    <stop offset="0.6651" stopColor="#9E96A6"/>
    <stop offset="1" stopColor="#8A7889"/>
    </linearGradient>
    <linearGradient id="paint9_linear_6689_15533" x1="12.7145" y1="5.42435" x2="9.73434" y2="2.44421" gradientUnits="userSpaceOnUse">
    <stop stopColor="#BDC2D1"/>
    <stop offset="0.2367" stopColor="#B5B6C5"/>
    <stop offset="0.6651" stopColor="#9E96A6"/>
    <stop offset="1" stopColor="#8A7889"/>
    </linearGradient>
    </defs>
  </svg>
);

export default IconRestaurants;
