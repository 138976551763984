import React from "react";

const IconProximityToParks = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1284_8185)">
      <path
        d="M22.9815 31.0639C22.9815 31.3965 22.9144 31.713 22.7934 31.9999H18.586C18.4655 31.713 18.3984 31.3965 18.3984 31.0639C18.3984 29.7622 19.4242 28.7063 20.69 28.7063C21.2472 28.7063 21.7586 28.9112 22.1559 29.2521C22.6602 29.6848 22.9815 30.336 22.9815 31.0639Z"
        fill="url(#paint0_linear_1284_8185)"
      />
      <path
        d="M23.4392 30.9312C23.4392 31.1755 23.4066 31.4123 23.3453 31.6373C23.287 31.8515 23.0869 31.9999 22.8594 31.9999H18.2676C18.1258 31.6723 18.0469 31.3109 18.0469 30.9312C18.0469 29.4448 19.2538 28.2393 20.743 28.2393C21.3987 28.2393 22.0004 28.4732 22.4679 28.8624C23.0613 29.3565 23.4392 30.1 23.4392 30.9312Z"
        fill="url(#paint1_linear_1284_8185)"
      />
      <path
        d="M18.0282 23.9112L17.4023 23.2854L20.906 19.7817L21.5318 20.4076L18.0282 23.9112Z"
        fill="url(#paint2_linear_1284_8185)"
      />
      <path
        d="M12.3254 28.6485C13.6916 28.6485 14.7991 27.5409 14.7991 26.1747C14.7991 24.8085 13.6916 23.7009 12.3254 23.7009C10.9591 23.7009 9.85156 24.8085 9.85156 26.1747C9.85156 27.5409 10.9591 28.6485 12.3254 28.6485Z"
        fill="url(#paint3_linear_1284_8185)"
      />
      <path
        d="M12.3224 28.2392C13.4626 28.2392 14.3869 27.3149 14.3869 26.1747C14.3869 25.0344 13.4626 24.1101 12.3224 24.1101C11.1822 24.1101 10.2578 25.0344 10.2578 26.1747C10.2578 27.3149 11.1822 28.2392 12.3224 28.2392Z"
        fill="url(#paint4_linear_1284_8185)"
      />
      <path
        d="M13.4288 23.9619C13.5957 24.2949 13.6897 24.6707 13.6897 25.0685C13.6897 26.4347 12.5822 27.5423 11.2159 27.5423C10.8181 27.5423 10.4424 27.4482 10.1094 27.2814C10.5155 28.092 11.3539 28.6486 12.3223 28.6486C13.6885 28.6486 14.7961 27.541 14.7961 26.1748C14.7961 25.2064 14.2395 24.3681 13.4288 23.9619Z"
        fill="url(#paint5_linear_1284_8185)"
      />
      <path
        d="M22.9815 31.0639C22.9815 31.3965 22.9144 31.713 22.7934 31.9999H18.586C18.4655 31.713 18.3984 31.3965 18.3984 31.0639C18.3984 29.7622 19.4242 28.7063 20.69 28.7063C21.2472 28.7063 21.7586 28.9112 22.1559 29.2521C22.6602 29.6848 22.9815 30.336 22.9815 31.0639Z"
        fill="url(#paint6_linear_1284_8185)"
      />
      <path
        d="M18.5586 32H22.4451L19.1675 28.7224C18.4769 29.2012 18.0031 29.9931 17.9536 30.9022C17.9426 31.104 17.9524 31.3019 17.9807 31.4934C18.0241 31.7857 18.2712 32 18.5586 32Z"
        fill="url(#paint7_linear_1284_8185)"
      />
      <path
        d="M18.6651 24.7261V15.9954H13.3356V24.7261C13.3356 27.2287 12.8212 29.7046 11.8242 32H13.3356H18.6651H20.1765C19.1795 29.7045 18.6651 27.2287 18.6651 24.7261Z"
        fill="url(#paint8_linear_1284_8185)"
      />
      <path
        d="M18.6472 24.7261V15.9954H14.8769V24.7261C14.8769 27.2287 14.3564 29.7046 13.3477 32H14.8769H18.6472H20.1765C19.1678 29.7045 18.6472 27.2287 18.6472 24.7261Z"
        fill="url(#paint9_linear_1284_8185)"
      />
      <path
        d="M13.3359 15.9954V20.6143L18.7103 25.9887C18.6812 25.569 18.6653 25.1481 18.6653 24.7262V15.9954H13.3359Z"
        fill="url(#paint10_linear_1284_8185)"
      />
      <path
        d="M18.881 6.10746C20.3211 6.10746 21.4886 4.94001 21.4886 3.49989C21.4886 2.05978 20.3211 0.892334 18.881 0.892334C17.4409 0.892334 16.2734 2.05978 16.2734 3.49989C16.2734 4.94001 17.4409 6.10746 18.881 6.10746Z"
        fill="url(#paint11_linear_1284_8185)"
      />
      <path
        d="M18.8794 5.67623C20.0813 5.67623 21.0556 4.70189 21.0556 3.49998C21.0556 2.29807 20.0813 1.32373 18.8794 1.32373C17.6775 1.32373 16.7031 2.29807 16.7031 3.49998C16.7031 4.70189 17.6775 5.67623 18.8794 5.67623Z"
        fill="url(#paint12_linear_1284_8185)"
      />
      <path
        d="M20.0459 1.16748C20.2218 1.51848 20.3209 1.91459 20.3209 2.33391C20.3209 3.77404 19.1535 4.94154 17.7133 4.94154C17.294 4.94154 16.8979 4.8424 16.5469 4.66652C16.975 5.52099 17.8587 6.10765 18.8795 6.10765C20.3196 6.10765 21.4871 4.94022 21.4871 3.50003C21.487 2.47929 20.9004 1.59559 20.0459 1.16748Z"
        fill="url(#paint13_linear_1284_8185)"
      />
      <path
        d="M13.5808 9.63815C16.2423 9.63815 18.3999 7.48058 18.3999 4.81907C18.3999 2.15757 16.2423 0 13.5808 0C10.9193 0 8.76172 2.15757 8.76172 4.81907C8.76172 7.48058 10.9193 9.63815 13.5808 9.63815Z"
        fill="url(#paint14_linear_1284_8185)"
      />
      <path
        d="M13.5805 8.84104C15.8018 8.84104 17.6025 7.04035 17.6025 4.81908C17.6025 2.59781 15.8018 0.797119 13.5805 0.797119C11.3593 0.797119 9.55859 2.59781 9.55859 4.81908C9.55859 7.04035 11.3593 8.84104 13.5805 8.84104Z"
        fill="url(#paint15_linear_1284_8185)"
      />
      <path
        d="M15.7361 0.508057C16.0611 1.15672 16.2443 1.88883 16.2443 2.66379C16.2443 5.32531 14.0867 7.48286 11.4253 7.48286C10.6503 7.48286 9.91825 7.29965 9.26953 6.97463C10.0607 8.5538 11.6938 9.63797 13.5804 9.63797C16.2419 9.63797 18.3994 7.48036 18.3994 4.8189C18.3995 2.93234 17.3153 1.29927 15.7361 0.508057Z"
        fill="url(#paint16_linear_1284_8185)"
      />
      <path
        d="M19.7827 21.034C24.7196 21.034 28.7217 17.0318 28.7217 12.095C28.7217 7.15812 24.7196 3.15601 19.7827 3.15601C14.8459 3.15601 10.8438 7.15812 10.8438 12.095C10.8438 17.0318 14.8459 21.034 19.7827 21.034Z"
        fill="url(#paint17_linear_1284_8185)"
      />
      <path
        d="M19.7845 19.5552C23.9048 19.5552 27.2449 16.2151 27.2449 12.0949C27.2449 7.97462 23.9048 4.63452 19.7845 4.63452C15.6643 4.63452 12.3242 7.97462 12.3242 12.0949C12.3242 16.2151 15.6643 19.5552 19.7845 19.5552Z"
        fill="url(#paint18_linear_1284_8185)"
      />
      <path
        d="M23.784 4.09863C24.3869 5.3019 24.7267 6.65982 24.7267 8.09732C24.7267 13.0342 20.7246 17.0363 15.7877 17.0363C14.3503 17.0363 12.9923 16.6965 11.7891 16.0936C13.2567 19.0227 16.2859 21.0338 19.7853 21.0338C24.7221 21.0338 28.7243 17.0317 28.7243 12.0949C28.7243 8.59545 26.7132 5.56624 23.784 4.09863Z"
        fill="url(#paint19_linear_1284_8185)"
      />
      <path
        d="M10.8467 21.0336C15.0271 21.0336 18.416 17.6447 18.416 13.4643C18.416 9.28392 15.0271 5.89502 10.8467 5.89502C6.66624 5.89502 3.27734 9.28392 3.27734 13.4643C3.27734 17.6447 6.66624 21.0336 10.8467 21.0336Z"
        fill="url(#paint20_linear_1284_8185)"
      />
      <path
        d="M10.8446 19.7817C14.3335 19.7817 17.1618 16.9534 17.1618 13.4645C17.1618 9.97554 14.3335 7.14722 10.8446 7.14722C7.35567 7.14722 4.52734 9.97554 4.52734 13.4645C4.52734 16.9534 7.35567 19.7817 10.8446 19.7817Z"
        fill="url(#paint21_linear_1284_8185)"
      />
      <path
        d="M13.3355 32.0001H17.3634L13.4529 28.0896L13.2383 32.0001H13.3355Z"
        fill="url(#paint22_linear_1284_8185)"
      />
      <path
        d="M23.3782 31.501C23.3155 31.85 23.1262 32 22.8052 32H21.1641C21.7884 31.4827 22.1872 30.6894 22.1872 29.8006C22.1872 29.6133 22.1691 29.4297 22.1353 29.2522C22.0869 29 21.8935 28.4966 21.8935 28.4966C21.8935 28.4966 22.7947 28.9556 23.1852 29.7914C23.548 30.5679 23.4253 31.2849 23.3782 31.501Z"
        fill="url(#paint23_linear_1284_8185)"
      />
      <path
        d="M14.3916 30.4488C14.3916 30.8034 14.343 31.1471 14.2518 31.4738C14.1651 31.7846 13.867 32.0001 13.5284 32.0001H7.3214C6.91218 32.0001 6.55666 31.7183 6.46349 31.3199C6.39798 31.0398 6.36328 30.7482 6.36328 30.4488C6.36328 28.2913 8.16018 26.5413 10.3775 26.5413C11.3536 26.5413 12.2494 26.8809 12.9455 27.4459C13.8289 28.163 14.3916 29.2422 14.3916 30.4488Z"
        fill="url(#paint24_linear_1284_8185)"
      />
      <path
        d="M13.7141 30.6416C13.7141 31.1243 13.6142 31.5838 13.434 32.0002H7.16997C6.99052 31.5837 6.89062 31.1243 6.89062 30.6416C6.89062 28.7519 8.41784 27.2192 10.3023 27.2192C11.1319 27.2192 11.8934 27.5167 12.4849 28.0115C13.2358 28.6396 13.7141 29.5849 13.7141 30.6416Z"
        fill="url(#paint25_linear_1284_8185)"
      />
      <path
        d="M14.3004 31.2756C14.207 31.7822 13.9253 32 13.4473 32H11.0039C11.9334 31.2492 12.5273 30.0976 12.5273 28.8074C12.5273 28.5356 12.5003 28.269 12.4499 28.0114C12.3778 27.6453 12.09 26.9146 12.09 26.9146C12.1292 26.9132 13.421 27.4798 14.0352 28.8477C14.5472 29.988 14.3704 30.962 14.3004 31.2756Z"
        fill="url(#paint26_linear_1284_8185)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1284_8185"
        x1="20.9567"
        y1="30.673"
        x2="18.5311"
        y2="33.0986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEE83" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1284_8185"
        x1="19.5572"
        y1="29.7254"
        x2="21.9729"
        y2="31.8274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1284_8185"
        x1="19.2184"
        y1="22.691"
        x2="20.3138"
        y2="18.9083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1284_8185"
        x1="11.3998"
        y1="25.2492"
        x2="14.6037"
        y2="28.4531"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="1" stopColor="#FFA425" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1284_8185"
        x1="12.5688"
        y1="26.4211"
        x2="10.3301"
        y2="24.1824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#FCEE21" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1284_8185"
        x1="12.4082"
        y1="27.1229"
        x2="15.8085"
        y2="20.7987"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#B53759" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1284_8185"
        x1="20.9567"
        y1="31.2069"
        x2="18.5311"
        y2="28.7813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEE83" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1284_8185"
        x1="23.9125"
        y1="32.7956"
        x2="14.6933"
        y2="28.4072"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" stopOpacity="0" />
        <stop offset="1" stopColor="#009245" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1284_8185"
        x1="11.8634"
        y1="23.9977"
        x2="22.4837"
        y2="23.9977"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB37E" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1284_8185"
        x1="17.1308"
        y1="24.0452"
        x2="2.25212"
        y2="25.8306"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDAAA" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1284_8185"
        x1="18.1516"
        y1="22.2093"
        x2="13.628"
        y2="13.9981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1284_8185"
        x1="17.9054"
        y1="2.52427"
        x2="21.2826"
        y2="5.90152"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1284_8185"
        x1="19.1391"
        y1="3.75975"
        x2="16.7794"
        y2="1.39997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEE83" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1284_8185"
        x1="18.97"
        y1="4.49943"
        x2="22.5543"
        y2="-2.16679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" stopOpacity="0" />
        <stop offset="1" stopColor="#009245" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1284_8185"
        x1="11.7778"
        y1="3.01603"
        x2="18.0193"
        y2="9.25755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1284_8185"
        x1="14.0606"
        y1="5.29914"
        x2="9.69951"
        y2="0.938053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEE83" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1284_8185"
        x1="13.7477"
        y1="6.6658"
        x2="20.3718"
        y2="-5.65402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" stopOpacity="0" />
        <stop offset="1" stopColor="#009245" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_1284_8185"
        x1="16.4382"
        y1="8.75049"
        x2="28.0156"
        y2="20.3278"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_1284_8185"
        x1="20.675"
        y1="12.9853"
        x2="12.5856"
        y2="4.89592"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEE83" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_1284_8185"
        x1="20.0958"
        y1="15.5207"
        x2="32.3828"
        y2="-7.33145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" stopOpacity="0" />
        <stop offset="1" stopColor="#009245" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_1284_8185"
        x1="10.3503"
        y1="12.2792"
        x2="15.1689"
        y2="23.7848"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_1284_8185"
        x1="11.5987"
        y1="14.2185"
        x2="4.74871"
        y2="7.36852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEE83" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_1284_8185"
        x1="16.6462"
        y1="33.3455"
        x2="10.9698"
        y2="27.6691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C6239" stopOpacity="0" />
        <stop offset="1" stopColor="#8C6239" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_1284_8185"
        x1="22.0424"
        y1="31.1497"
        x2="24.4002"
        y2="26.7644"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" stopOpacity="0" />
        <stop offset="1" stopColor="#009245" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_1284_8185"
        x1="8.69043"
        y1="28.6092"
        x2="12.2568"
        y2="31.7124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_1284_8185"
        x1="10.6955"
        y1="30.8642"
        x2="7.12145"
        y2="27.2903"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEE83" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_1284_8185"
        x1="12.3246"
        y1="30.7798"
        x2="15.7661"
        y2="24.379"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB272" stopOpacity="0" />
        <stop offset="1" stopColor="#009245" />
      </linearGradient>
      <clipPath id="clip0_1284_8185">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconProximityToParks;
