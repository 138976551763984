import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { injectIntl } from "react-intl";
import { Spin } from "antd";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { HistoryInfoSection, NeighborhoodInfoSection, OtherInfoSection, PropertyInfoSection } from "./Sections";
import { FavoritesForNonAuthModal, PhoneInputModal } from "components/Modals";
import NotFound from "containers/NotFound";
import { formatSearchParams, getActiveFilters } from "utils/helpers/searchHelper";
import { getFullAddress } from "utils/helpers/housesHelper/getter";
import { localStorageStrToObject } from "utils/helpers/common";
import MapView from "./Views/MapView";
import { HouseAllPhotosView, TopBarView } from "./Views";
import { USER_KEY, HOME_DETAIL_PAGE_VISITS } from "constants/localStorage";
import "./styles.scss";


const PAGE_TOP_MARGIN_IN_PIXELS = 30;

  const HouseDetails = observer(({ housesStore, criteriaStore, authStore, filters,propertyId }) => {
  const [isPhotoCollageOpened, setIsPhotoCollageOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTopBarFixed, setIsTopBarFixed] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [screenSize, setScreenSize] = useState({ height: window.innerHeight, width: window.innerWidth });
  const houseDetails = housesStore.retrieveHouseDetailsData();
  const [favoritesPopUpActive, setFavoritesPopUpActive] = useState(false)
  const handleNonAuthFavorites = () => setFavoritesPopUpActive(true);

  const getFiltersQueryParams = (filters, searchFilters) => filters || getActiveFilters(searchFilters);


  useEffect(() => {
    checkIfShouldNotify();
    const handleSuccess = () => {
      setIsLoading(false);
    }
    const handleError = () => {
      setIsLoading(false);
    }
    const criteriaData = criteriaStore.retrieveCriteriaData();
    const searchFilters = formatSearchParams(criteriaData);

    if (!_.isEmpty(searchFilters)) {
      const queryParams = getFiltersQueryParams(filters, searchFilters);
      housesStore.getHouseDetailsRequest({
        filters: queryParams,
        id: propertyId,
      }, handleSuccess, handleError);
    } else {
      const handleCriteriaSuccess = data => {
        const searchFilters = formatSearchParams(data);
        const queryParams = getFiltersQueryParams(filters, searchFilters);
        housesStore.getHouseDetailsRequest({
          filters: queryParams,
          id: propertyId,
        }, handleSuccess, handleError);
      }
      criteriaStore.getCriteriaRequest(null, handleCriteriaSuccess);
    }

    return () => housesStore.setHouseDetails({});
  }, []);

  const checkIfShouldNotify = () => {
    // update amount of visit id details page and if it's enough to show notification then notify
    const user = localStorageStrToObject(USER_KEY);
    const visits = JSON.parse(localStorage.getItem(HOME_DETAIL_PAGE_VISITS)) || 0;
    localStorage.setItem(HOME_DETAIL_PAGE_VISITS, visits + 1);
    if (!_.isEmpty(user) && _.isEmpty(user.phone) && visits + 1 === 2) {
      setModalVisible(true);
    }
  };

  const handleResize = () => setScreenSize({ height: window.innerHeight, width: window.innerWidth });

  const handleScroll = () => setIsTopBarFixed(window.scrollY > PAGE_TOP_MARGIN_IN_PIXELS);

  const handleModalVisible = () => setModalVisible(!modalVisible);

  const handleSubmit = data => {
    authStore.updateUserDetailsRequest(data)
  }

  useEffect(() => {
    // tracks scroll event to fix main house info at the top of the page
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    }
  }, [handleResize, handleScroll]);

  const getPropertyInfoSection = () => (
    <PropertyInfoSection
      houseDetails={houseDetails}
      onAllPhotosButtonClick={() => setIsPhotoCollageOpened(true)}
    />
  );

  const getHistoryInfoSection = () => <HistoryInfoSection houseDetails={houseDetails} />;

  const getMapAndNeighborhoodInfoSection = () => (
    <>
      <MapView houseDetails={houseDetails} />
      <NeighborhoodInfoSection houseDetails={houseDetails} />
    </>
  );

  const getOtherInfoSection = () => <OtherInfoSection houseDetails={houseDetails} />;
  // const getOtherInfoSection = () => renderDetails(getOtherDetailsRow, OTHER_DETAILS, "houseDetails.otherDetailsTitle");

  const getInfoBlock = index => {
    const orderedInfoBlockMethods = [
      getPropertyInfoSection,
      getMapAndNeighborhoodInfoSection,
      getOtherInfoSection,
      getHistoryInfoSection
    ];
    return index < orderedInfoBlockMethods.length ? orderedInfoBlockMethods[index]() : null;
  };

  // const renderDetails = (method, data, messageId) => (
  //   <div className="details-collapse">
  //     <Collapse bordered={false} expandIconPosition="right">
  //       <Panel header={intl.formatMessage({ id: messageId })} key="1">
  //         <div className="other-details-wrapper">
  //           {_.map(data, (item, key) => method(houseDetails, item, key))}
  //         </div>
  //       </Panel>
  //     </Collapse>
  //   </div>
  // );

  const renderHouse = () => (
    <div className="house-details-container">
      <TopBarView
        isFixed={isTopBarFixed}
        isPhotoCollageOpened={isPhotoCollageOpened}
        houseDetails={houseDetails}
        housesStore={housesStore}
        handleNonAuthFavorites={handleNonAuthFavorites}
        onReturnButtonClick={() => setIsPhotoCollageOpened(false)}
      />
      {isPhotoCollageOpened ? renderHousePhotos() : renderHouseDetails()}
    </div>
  );

  const renderHouseDetails = () => {
    const houseInfoBlocks = _.range(4);
    return (
    <>
      {houseInfoBlocks.map(index => (
        <div className="house-info-block">
          {getInfoBlock(index)}
          {index !== houseInfoBlocks.length - 1 && <div className="separator-line" />}
        </div>
      ))}
    </>
    );
  };

  const renderHousePhotos = () => (
    <div><HouseAllPhotosView houseDetails={houseDetails} screenSize={screenSize} /></div>
  );

  if (_.isEmpty(houseDetails) && !isLoading) {
    return <NotFound customClassName="not-found-page" width="85vw" height="90vh" />;
  }

  return (
    <>
      <PhoneInputModal
        modalVisible={modalVisible}
        handleModalVisible={handleModalVisible}
        handleSubmit={handleSubmit}
      />
      <Helmet title={!_.isEmpty(houseDetails) && !isLoading && getFullAddress(houseDetails.address)} />
      <div className="house-details-wrapper">
        <Spin spinning={isLoading}>
          {!_.isEmpty(houseDetails) && !isLoading && renderHouse()}
        </Spin>
        <FavoritesForNonAuthModal
          isVisible={favoritesPopUpActive}
          onClose={() => setFavoritesPopUpActive(false)}
        />
      </div>
    </>
  );
});

export default inject("housesStore", "criteriaStore", "authStore")(injectIntl(HouseDetails));
