import React from "react";

const IconGarage = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_808_1496)">
      <path
        d="M25.1405 29.1121C26.9591 29.1121 28.4334 27.6378 28.4334 25.8192C28.4334 24.0006 26.9591 22.5264 25.1405 22.5264C23.3219 22.5264 21.8477 24.0006 21.8477 25.8192C21.8477 27.6378 23.3219 29.1121 25.1405 29.1121Z"
        fill="url(#paint0_linear_808_1496)"
      />
      <path
        d="M31.999 28.875C31.999 29.3666 31.906 29.8358 31.7362 30.2669H24.6573C24.4875 29.8358 24.3945 29.3666 24.3945 28.875C24.3945 28.3963 24.4828 27.9387 24.6444 27.5171C25.191 26.0872 26.5754 25.0728 28.1967 25.0728C29.818 25.0728 31.2025 26.0871 31.749 27.5171C31.9107 27.9388 31.999 28.3963 31.999 28.875Z"
        fill="url(#paint1_linear_808_1496)"
      />
      <path
        d="M27.0111 13.3163V30.2672H4.99219V13.3163L16.0016 2.24023L27.0111 13.3163Z"
        fill="url(#paint2_linear_808_1496)"
      />
      <path
        d="M27.0111 13.3163V30.2672H20.0591L4.99219 15.2004V13.3163L16.0016 2.24023L27.0111 13.3163Z"
        fill="url(#paint3_linear_808_1496)"
      />
      <path
        d="M29.5011 14.8476L16.6646 2.00683C16.2987 1.64095 15.7055 1.64095 15.3396 2.00683L2.49984 14.8466C2.26047 15.086 2.26047 15.474 2.49984 15.7134L3.04703 16.2606C3.28641 16.5 3.67447 16.5 3.91384 16.2606L15.8276 4.34683C15.9239 4.25052 16.0801 4.25052 16.1764 4.34683L28.087 16.2616C28.3264 16.501 28.7145 16.501 28.9538 16.2616L29.501 15.7144C29.7405 15.475 29.7405 15.087 29.5011 14.8476Z"
        fill="url(#paint4_linear_808_1496)"
      />
      <path
        d="M16.6664 2.0068C16.4835 1.82386 16.2437 1.73242 16.0039 1.73242V4.27461C16.067 4.27461 16.1302 4.29874 16.1783 4.34686L28.0889 16.2616C28.3283 16.501 28.7163 16.501 28.9557 16.2616L29.5029 15.7144C29.7423 15.4751 29.7423 15.087 29.5029 14.8476L16.6664 2.0068Z"
        fill="url(#paint5_linear_808_1496)"
      />
      <path
        d="M27.0115 28.5418V17.9431L18.4511 9.38277C17.8522 8.70277 16.9759 8.27246 16.0006 8.27246C14.1995 8.27246 12.7344 9.73771 12.7344 11.5386V13.9406C12.7344 14.2544 12.903 14.5288 13.1537 14.6801L27.0031 28.5296C27.0057 28.5339 27.0089 28.5376 27.0115 28.5418Z"
        fill="url(#paint6_linear_808_1496)"
      />
      <path
        d="M27.011 30.2671V20.7204C27.011 20.7204 23.0873 16.6823 23.0189 16.6197C22.9425 16.5499 22.8409 16.5073 22.7293 16.5073H9.27388C9.03631 16.5073 8.84375 16.6999 8.84375 16.9375V18.8006V21.0938V23.3871V25.6803V27.9736V30.2668H9.19125H27.0111V30.2671H27.011Z"
        fill="url(#paint7_linear_808_1496)"
      />
      <path
        d="M23.1597 27.9741H8.84375V30.2674H23.1597V27.9741Z"
        fill="url(#paint8_linear_808_1496)"
      />
      <path
        d="M23.1597 25.6807H8.84375V27.9739H23.1597V25.6807Z"
        fill="url(#paint9_linear_808_1496)"
      />
      <path
        d="M23.1597 23.3877H8.84375V25.6809H23.1597V23.3877Z"
        fill="url(#paint10_linear_808_1496)"
      />
      <path
        d="M23.1597 21.0942H8.84375V23.3875H23.1597V21.0942Z"
        fill="url(#paint11_linear_808_1496)"
      />
      <path
        d="M23.1597 18.8008H8.84375V21.094H23.1597V18.8008Z"
        fill="url(#paint12_linear_808_1496)"
      />
      <path
        d="M23.1595 18.8006H8.84375V16.9374C8.84375 16.6999 9.03631 16.5073 9.27387 16.5073H22.7294C22.9669 16.5073 23.1595 16.6999 23.1595 16.9374V18.8006Z"
        fill="url(#paint13_linear_808_1496)"
      />
      <path
        d="M18.4048 14.2835H13.6007C13.4113 14.2835 13.2578 14.13 13.2578 13.9406V11.5385C13.2578 10.0225 14.4868 8.79346 16.0029 8.79346C17.5189 8.79346 18.7479 10.0225 18.7479 11.5385V13.9406C18.7478 14.13 18.5942 14.2835 18.4048 14.2835Z"
        fill="#654E48"
      />
      <path
        d="M16.0006 8.27246C14.1995 8.27246 12.7344 9.73771 12.7344 11.5386V13.9406C12.7344 14.4172 13.122 14.8048 13.5986 14.8048H18.4026C18.8792 14.8048 19.2668 14.4172 19.2668 13.9406V11.5386C19.2668 9.73765 17.8016 8.27246 16.0006 8.27246ZM18.2064 11.2606H16.5218V9.37709C17.4094 9.59121 18.0909 10.339 18.2064 11.2606ZM15.4793 9.37715V11.2607H13.7948C13.9103 10.339 14.5916 9.59121 15.4793 9.37715ZM13.7768 12.3031H15.4793V13.7625H13.7768V12.3031ZM16.5218 13.7625V12.3031H18.2244V13.7625H16.5218Z"
        fill="white"
      />
      <path
        d="M6.47137 29.0823C6.47137 29.5006 6.39219 29.8999 6.24775 30.2668H0.223625C0.0791875 29.8999 0 29.5007 0 29.0823C0 28.675 0.075125 28.2855 0.212625 27.9267C0.67775 26.7099 1.85594 25.8467 3.23569 25.8467C4.61544 25.8467 5.79362 26.7099 6.25875 27.9267C6.39625 28.2856 6.47137 28.675 6.47137 29.0823Z"
        fill="url(#paint14_linear_808_1496)"
      />
      <path
        d="M8.55759 29.4077C8.55759 29.7111 8.50016 30.0007 8.39541 30.2669H4.02547C3.92066 30.0007 3.86328 29.7111 3.86328 29.4077C3.86328 29.1122 3.91772 28.8297 4.01753 28.5694C4.35491 27.6867 5.20959 27.0605 6.21047 27.0605C7.21134 27.0605 8.06603 27.6867 8.40341 28.5694C8.50309 28.8297 8.55759 29.1122 8.55759 29.4077Z"
        fill="url(#paint15_linear_808_1496)"
      />
      <path
        d="M19.2301 24.0793H18.4795C18.1424 24.0793 17.852 23.8421 17.7847 23.5119L16.9831 22.2237C16.3885 21.2683 15.3429 20.6875 14.2176 20.6875H10.3797C9.44544 20.6875 8.65886 21.3863 8.54876 22.314L8.27372 23.4424C8.23063 23.8057 7.92264 24.0793 7.55683 24.0793C6.90735 24.0793 6.36138 24.567 6.28847 25.2124L5.9801 27.7057C5.94333 28.0311 6.19788 28.316 6.52534 28.316H21.1022C21.4547 28.316 21.7158 27.9883 21.637 27.6446L21.1085 25.5757C20.9077 24.7001 20.1285 24.0793 19.2301 24.0793Z"
        fill="url(#paint16_linear_808_1496)"
      />
      <path
        d="M16.5954 23.175C16.3909 22.1718 15.5085 21.4512 14.4847 21.4512H10.2654C9.84165 21.4512 9.48491 21.7681 9.43499 22.1889C9.43499 22.1889 9.26094 23.1952 9.16106 23.7399C9.12862 23.9168 9.26451 24.0795 9.44432 24.0795H16.4304C16.6132 24.0795 16.7498 23.9114 16.7122 23.7324L16.5954 23.175Z"
        fill="url(#paint17_linear_808_1496)"
      />
      <path
        d="M12.3966 28.3158H7.05859C7.09334 26.7667 8.27479 25.5229 9.72777 25.5229C11.1804 25.5229 12.3619 26.7667 12.3966 28.3158Z"
        fill="url(#paint18_linear_808_1496)"
      />
      <path
        d="M20.7133 28.3158H15.375C15.4097 26.7667 16.5915 25.5229 18.0442 25.5229C19.4968 25.5229 20.6786 26.7667 20.7133 28.3158Z"
        fill="url(#paint19_linear_808_1496)"
      />
      <path
        d="M21.3243 28.2688L17.1579 24.3378C17.0982 24.3184 17.0547 24.2625 17.0547 24.1963V23.0931C17.0547 23.0106 17.1216 22.9434 17.2044 22.9434C17.4041 22.9434 17.5847 23.0268 17.712 23.1613L17.7834 23.512C17.851 23.8423 18.1413 24.0793 18.4784 24.0793H19.2289C20.1272 24.0793 20.9066 24.7003 21.1073 25.5758L21.3993 26.6129L21.6357 27.6446C21.6967 27.91 21.5545 28.1662 21.3243 28.2688Z"
        fill="url(#paint20_linear_808_1496)"
      />
      <path
        d="M20.543 25.9741V28.316H21.1978C21.5503 28.316 21.8114 27.9883 21.7326 27.6446L21.3184 25.8383C21.3014 25.7643 21.2356 25.7119 21.1597 25.7119H20.8052C20.6604 25.7119 20.543 25.8293 20.543 25.9741Z"
        fill="url(#paint21_linear_808_1496)"
      />
      <path
        d="M8.33023 28.5047H6.0276C5.87429 28.5047 5.75 28.3805 5.75 28.2271V27.4617C5.75 27.3084 5.87429 27.1841 6.0276 27.1841H8.33023C8.48355 27.1841 8.60783 27.3084 8.60783 27.4617V28.2271C8.60783 28.3805 8.48355 28.5047 8.33023 28.5047Z"
        fill="url(#paint22_linear_808_1496)"
      />
      <path
        d="M21.5959 28.5047H19.2932C19.1399 28.5047 19.0156 28.3805 19.0156 28.2271V27.4617C19.0156 27.3084 19.1399 27.1841 19.2932 27.1841H21.5959C21.7492 27.1841 21.8735 27.3084 21.8735 27.4617V28.2271C21.8735 28.3805 21.7492 28.5047 21.5959 28.5047Z"
        fill="url(#paint23_linear_808_1496)"
      />
      <path
        d="M11.5902 28.3825C11.5902 28.6667 11.5264 28.9357 11.4126 29.1765C11.2284 29.5664 10.9126 29.8822 10.5223 30.066C10.2818 30.1802 10.0124 30.2436 9.7287 30.2436C8.70047 30.2436 7.86719 29.4103 7.86719 28.3825C7.86719 27.3543 8.70047 26.521 9.7287 26.521C10.7569 26.521 11.5902 27.3543 11.5902 28.3825Z"
        fill="url(#paint24_linear_808_1496)"
      />
      <path
        d="M9.72933 29.3302C10.2529 29.3302 10.6774 28.9058 10.6774 28.3822C10.6774 27.8586 10.2529 27.4341 9.72933 27.4341C9.20572 27.4341 8.78125 27.8586 8.78125 28.3822C8.78125 28.9058 9.20572 29.3302 9.72933 29.3302Z"
        fill="url(#paint25_linear_808_1496)"
      />
      <path
        d="M11.4133 29.1762C11.229 29.566 10.9132 29.8819 10.5229 30.0657L9.31892 28.8617C9.18375 28.7457 9.09766 28.574 9.09766 28.3821C9.09766 28.0333 9.38048 27.7505 9.72931 27.7505C9.92158 27.7505 10.0933 27.8366 10.2093 27.9722L11.4133 29.1762Z"
        fill="url(#paint26_linear_808_1496)"
      />
      <path
        d="M9.72934 29.0137C10.0782 29.0137 10.361 28.7309 10.361 28.3821C10.361 28.0333 10.0782 27.7505 9.72934 27.7505C9.38047 27.7505 9.09766 28.0333 9.09766 28.3821C9.09766 28.7309 9.38047 29.0137 9.72934 29.0137Z"
        fill="url(#paint27_linear_808_1496)"
      />
      <path
        d="M10.0117 27.8169C10.0543 27.9019 10.0783 27.9978 10.0783 28.0994C10.0783 28.4482 9.79553 28.731 9.44663 28.731C9.34504 28.731 9.24908 28.707 9.16406 28.6644C9.26777 28.8713 9.48185 29.0134 9.72913 29.0134C10.078 29.0134 10.3608 28.7307 10.3608 28.3818C10.3608 28.1346 10.2187 27.9206 10.0117 27.8169Z"
        fill="url(#paint28_linear_808_1496)"
      />
      <path
        d="M19.9066 28.382C19.9066 28.6662 19.8428 28.9352 19.729 29.1761C19.5448 29.5659 19.229 29.8818 18.8387 30.0656C18.5983 30.1798 18.3288 30.2431 18.0451 30.2431C17.0169 30.2431 16.1836 29.4098 16.1836 28.382C16.1836 27.3538 17.0169 26.5205 18.0451 26.5205C19.0733 26.5205 19.9066 27.3538 19.9066 28.382Z"
        fill="url(#paint29_linear_808_1496)"
      />
      <path
        d="M18.0457 29.3298C18.5693 29.3298 18.9938 28.9053 18.9938 28.3817C18.9938 27.8581 18.5693 27.4336 18.0457 27.4336C17.5221 27.4336 17.0977 27.8581 17.0977 28.3817C17.0977 28.9053 17.5221 29.3298 18.0457 29.3298Z"
        fill="url(#paint30_linear_808_1496)"
      />
      <path
        d="M19.7297 29.1762C19.5454 29.566 19.2296 29.8819 18.8393 30.0657L17.6353 28.8617C17.5002 28.7457 17.4141 28.574 17.4141 28.3821C17.4141 28.0333 17.6969 27.7505 18.0457 27.7505C18.238 27.7505 18.4097 27.8366 18.5257 27.9722L19.7297 29.1762Z"
        fill="url(#paint31_linear_808_1496)"
      />
      <path
        d="M18.0458 29.0137C18.3946 29.0137 18.6774 28.7309 18.6774 28.3821C18.6774 28.0333 18.3946 27.7505 18.0458 27.7505C17.6969 27.7505 17.4141 28.0333 17.4141 28.3821C17.4141 28.7309 17.6969 29.0137 18.0458 29.0137Z"
        fill="url(#paint32_linear_808_1496)"
      />
      <path
        d="M18.3281 27.8169C18.3707 27.9019 18.3947 27.9978 18.3947 28.0994C18.3947 28.4482 18.1119 28.731 17.763 28.731C17.6614 28.731 17.5655 28.707 17.4805 28.6644C17.5842 28.8713 17.7983 29.0134 18.0455 29.0134C18.3944 29.0134 18.6772 28.7307 18.6772 28.3818C18.6772 28.1346 18.5351 27.9206 18.3281 27.8169Z"
        fill="url(#paint33_linear_808_1496)"
      />
      <path
        d="M15.595 28.5048H12.1606C11.977 28.5048 11.8281 28.3559 11.8281 28.1723V27.5166C11.8281 27.3329 11.977 27.1841 12.1606 27.1841H15.595C15.7786 27.1841 15.9275 27.3329 15.9275 27.5166V28.1723C15.9275 28.3559 15.7786 28.5048 15.595 28.5048Z"
        fill="url(#paint34_linear_808_1496)"
      />
      <path
        d="M21.1075 25.5758C20.9067 24.7001 20.1275 24.0793 19.2291 24.0793H18.4784C18.1414 24.0793 17.851 23.8421 17.7837 23.5119L16.982 22.2237C16.3875 21.2683 15.3419 20.6875 14.2166 20.6875H12.1055V28.316H21.1011C21.4537 28.316 21.7148 27.9883 21.6359 27.6447L21.1075 25.5758Z"
        fill="url(#paint35_linear_808_1496)"
      />
      <path
        d="M11.7542 22.0088C11.7542 21.8875 11.6558 21.7891 11.5344 21.7891H10.576C10.2 21.7891 9.88355 22.0498 9.83925 22.3961C9.83925 22.3961 9.65891 22.7992 9.5762 23.4598C9.55748 23.6092 9.67402 23.741 9.82462 23.741H11.5344C11.6558 23.741 11.7542 23.6426 11.7542 23.5212V22.0088Z"
        fill="url(#paint36_linear_808_1496)"
      />
      <path
        d="M16.2593 23.4458C16.1743 22.9532 15.5753 21.7891 14.2573 21.7891H12.6442C12.5301 21.7891 12.4375 21.8875 12.4375 22.0088V23.5213C12.4375 23.6426 12.5301 23.741 12.6442 23.741H16.0276C16.1747 23.741 16.2858 23.5995 16.2593 23.4458Z"
        fill="url(#paint37_linear_808_1496)"
      />
      <path
        d="M6.13507 26.8705H6.81766C6.89658 26.8705 6.96054 26.8065 6.96054 26.7276V25.9525C6.96054 25.8735 6.89658 25.8096 6.81766 25.8096H6.22268C6.15 25.8096 6.08888 25.8642 6.08072 25.9364L5.99311 26.7116C5.98353 26.7963 6.04983 26.8705 6.13507 26.8705Z"
        fill="url(#paint38_linear_808_1496)"
      />
      <path
        d="M21.054 25.395C20.7938 24.6159 20.0619 24.0792 19.2266 24.0792H18.476C18.139 24.0792 17.8486 23.8419 17.7812 23.5117V24.6615C17.7812 25.0667 18.1097 25.395 18.5147 25.395H21.054Z"
        fill="url(#paint39_linear_808_1496)"
      />
      <path
        d="M17.2045 24.346C17.1218 24.346 17.0547 24.2789 17.0547 24.1962V23.0932C17.0547 23.0105 17.1218 22.9434 17.2045 22.9434C17.5919 22.9434 17.9059 23.2573 17.9059 23.6447C17.9059 24.032 17.5919 24.346 17.2045 24.346Z"
        fill="url(#paint40_linear_808_1496)"
      />
      <path
        d="M14.3813 25.0802H13.4944C13.3508 25.0802 13.2344 24.9638 13.2344 24.8202V24.74C13.2344 24.5964 13.3508 24.48 13.4944 24.48H14.3813C14.5249 24.48 14.6413 24.5964 14.6413 24.74V24.8202C14.6413 24.9638 14.5249 25.0802 14.3813 25.0802Z"
        fill="url(#paint41_linear_808_1496)"
      />
      <path
        d="M8.91184 24.673H8.1546C8.08432 24.673 8.02734 24.616 8.02734 24.5457C8.02734 24.4754 8.08432 24.4185 8.1546 24.4185H8.91184C8.98213 24.4185 9.03911 24.4754 9.03911 24.5457C9.03911 24.616 8.98213 24.673 8.91184 24.673Z"
        fill="url(#paint42_linear_808_1496)"
      />
      <path
        d="M8.91184 25.0524H8.1546C8.08432 25.0524 8.02734 24.9954 8.02734 24.9251C8.02734 24.8548 8.08432 24.7979 8.1546 24.7979H8.91184C8.98213 24.7979 9.03911 24.8548 9.03911 24.9251C9.03911 24.9954 8.98213 25.0524 8.91184 25.0524Z"
        fill="url(#paint43_linear_808_1496)"
      />
      <path
        d="M8.91184 25.4318H8.1546C8.08432 25.4318 8.02734 25.3748 8.02734 25.3045C8.02734 25.2342 8.08432 25.1772 8.1546 25.1772H8.91184C8.98213 25.1772 9.03911 25.2342 9.03911 25.3045C9.03911 25.3748 8.98213 25.4318 8.91184 25.4318Z"
        fill="url(#paint44_linear_808_1496)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_808_1496"
        x1="23.2028"
        y1="23.8822"
        x2="27.4418"
        y2="28.1212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_808_1496"
        x1="26.052"
        y1="26.508"
        x2="30.7433"
        y2="31.1993"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_808_1496"
        x1="16.0013"
        y1="16.4482"
        x2="16.0013"
        y2="36.2884"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF931E" />
        <stop offset="1" stopColor="#FF5D00" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_808_1496"
        x1="15.496"
        y1="13.7201"
        x2="5.86166"
        y2="4.08575"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_808_1496"
        x1="16.0001"
        y1="10.4178"
        x2="16.0001"
        y2="21.5674"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2EDF2" />
        <stop offset="1" stopColor="#A5B0B8" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_808_1496"
        x1="39.9325"
        y1="19.5544"
        x2="6.33507"
        y2="-0.39813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2EDF2" stopOpacity="0" />
        <stop offset="1" stopColor="#A5B0B8" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_808_1496"
        x1="19.6507"
        y1="15.8942"
        x2="9.81127"
        y2="6.05406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_808_1496"
        x1="21.5401"
        y1="29.0525"
        x2="11.8757"
        y2="19.3881"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5D00" stopOpacity="0" />
        <stop offset="1" stopColor="#D54003" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_808_1496"
        x1="16.0014"
        y1="28.2177"
        x2="16.0014"
        y2="31.2601"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2EDF2" />
        <stop offset="1" stopColor="#A5B0B8" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_808_1496"
        x1="16.0014"
        y1="25.9249"
        x2="16.0014"
        y2="28.9667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2EDF2" />
        <stop offset="1" stopColor="#A5B0B8" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_808_1496"
        x1="16.0014"
        y1="23.632"
        x2="16.0014"
        y2="26.6738"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2EDF2" />
        <stop offset="1" stopColor="#A5B0B8" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_808_1496"
        x1="16.0014"
        y1="21.3386"
        x2="16.0014"
        y2="24.3804"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2EDF2" />
        <stop offset="1" stopColor="#A5B0B8" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_808_1496"
        x1="16.0014"
        y1="19.0451"
        x2="16.0014"
        y2="22.0869"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2EDF2" />
        <stop offset="1" stopColor="#A5B0B8" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_808_1496"
        x1="16.0013"
        y1="16.7517"
        x2="16.0013"
        y2="19.7935"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2EDF2" />
        <stop offset="1" stopColor="#A5B0B8" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_808_1496"
        x1="1.41097"
        y1="27.0681"
        x2="5.40354"
        y2="31.0605"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_808_1496"
        x1="4.88669"
        y1="27.9465"
        x2="7.78313"
        y2="30.8428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EB644" />
        <stop offset="1" stopColor="#738611" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_808_1496"
        x1="11.7946"
        y1="21.6294"
        x2="13.7913"
        y2="27.4225"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_808_1496"
        x1="14.4056"
        y1="25.3134"
        x2="10.7669"
        y2="20.6429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_808_1496"
        x1="9.72758"
        y1="25.105"
        x2="9.72758"
        y2="28.0097"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset="0.8122" stopColor="#27D2F5" />
        <stop offset="1" stopColor="#18CEFB" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_808_1496"
        x1="18.0441"
        y1="25.105"
        x2="18.0441"
        y2="28.0097"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65E0DB" />
        <stop offset="0.8122" stopColor="#27D2F5" />
        <stop offset="1" stopColor="#18CEFB" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_808_1496"
        x1="18.9892"
        y1="24.6328"
        x2="17.1375"
        y2="22.5437"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A97F6" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BB" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_808_1496"
        x1="20.4824"
        y1="26.6306"
        x2="21.0778"
        y2="27.199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_808_1496"
        x1="7.17892"
        y1="27.5868"
        x2="7.17892"
        y2="28.6671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_808_1496"
        x1="20.4445"
        y1="27.5868"
        x2="20.4445"
        y2="28.6671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_808_1496"
        x1="9.21523"
        y1="27.8689"
        x2="11.3684"
        y2="30.0221"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_808_1496"
        x1="10.3855"
        y1="29.0383"
        x2="8.78578"
        y2="27.4386"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEC3D2" />
        <stop offset="0.2341" stopColor="#B6B7C6" />
        <stop offset="0.6581" stopColor="#A097A7" />
        <stop offset="1" stopColor="#8B788A" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_808_1496"
        x1="10.6891"
        y1="29.3418"
        x2="9.63297"
        y2="28.2857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_808_1496"
        x1="9.493"
        y1="28.1457"
        x2="10.3111"
        y2="28.9638"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_808_1496"
        x1="9.75107"
        y1="28.6239"
        x2="10.6192"
        y2="27.0093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.0471" stopColor="#6E566E" stopOpacity="0.047" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_808_1496"
        x1="17.5316"
        y1="27.8684"
        x2="19.6848"
        y2="30.0216"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_808_1496"
        x1="18.7018"
        y1="29.0378"
        x2="17.1021"
        y2="27.4381"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BEC3D2" />
        <stop offset="0.2341" stopColor="#B6B7C6" />
        <stop offset="0.6581" stopColor="#A097A7" />
        <stop offset="1" stopColor="#8B788A" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_808_1496"
        x1="19.0055"
        y1="29.3418"
        x2="17.9494"
        y2="28.2857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B788A" stopOpacity="0" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_808_1496"
        x1="17.8094"
        y1="28.1457"
        x2="18.6275"
        y2="28.9638"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_808_1496"
        x1="18.0675"
        y1="28.6239"
        x2="18.9357"
        y2="27.0093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E566E" stopOpacity="0" />
        <stop offset="0.0471" stopColor="#6E566E" stopOpacity="0.047" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_808_1496"
        x1="13.8778"
        y1="27.5869"
        x2="13.8778"
        y2="28.6671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_808_1496"
        x1="13.6665"
        y1="24.0629"
        x2="9.21899"
        y2="20.0581"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A97F6" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BB" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_808_1496"
        x1="10.3511"
        y1="22.1846"
        x2="11.156"
        y2="23.6476"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_808_1496"
        x1="13.4887"
        y1="21.8636"
        x2="14.6774"
        y2="24.0242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_808_1496"
        x1="6.27118"
        y1="25.9956"
        x2="6.72585"
        y2="26.7588"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_808_1496"
        x1="19.3617"
        y1="24.4959"
        x2="16.991"
        y2="26.2982"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A97F6" stopOpacity="0" />
        <stop offset="1" stopColor="#0469BB" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_808_1496"
        x1="17.0206"
        y1="23.4048"
        x2="17.6413"
        y2="23.9279"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_808_1496"
        x1="13.9378"
        y1="25.2225"
        x2="13.9378"
        y2="24.4946"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_808_1496"
        x1="8.53322"
        y1="24.7333"
        x2="8.53322"
        y2="24.4247"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_808_1496"
        x1="8.53322"
        y1="25.1127"
        x2="8.53322"
        y2="24.8041"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_808_1496"
        x1="8.53322"
        y1="25.4921"
        x2="8.53322"
        y2="25.1835"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18CEFB" />
        <stop offset="1" stopColor="#4A97F6" />
      </linearGradient>
      <clipPath id="clip0_808_1496">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconGarage;
