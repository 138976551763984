import { configure } from "mobx";
import AuthStore from "./AuthStore";
import CriteriaStore from "./CriteriaStore";
import HousesStore from "./HousesStore";
import OnboardingStore from "./OnboardingStore";

setTimeout(() => {
    configure({
        reactionScheduler: (f) => {
            setTimeout(f, 1);
        },
        enforceActions: "never",
    });
}, 1);

export const initStores = () =>
    // do some stuff
     ({
        authStore: new AuthStore(),
        criteriaStore: new CriteriaStore(),
        housesStore: new HousesStore(),
        onboardingStore: new OnboardingStore(),
    });
