import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Spin } from "antd";
import { useHistory, Link } from "react-router-dom";
import { LOGIN_PATH } from "constants/paths";
import { LAST_SAVED_ONBOARDING_STEP_KEY, ONBOARDING_DATA_KEY } from "constants/localStorage";
import { IconArrowLeft } from "components/Svgs";
import { SimpleButton } from "components/Controls/Buttons";
import { hihomeLogoBeta } from "images";
import "./styles.scss";

const DEFAULT_BUTTON_MESSAGE = {
    back: "general.back",
    next: "general.next",
};

const OnboardingLayout = (
  {
    backButtonCustomText,
    backButtonDisabled,
    bottomHint,
    children,
    formName,
    isExtendedContent,
    isHideFooter,
    isLoading,
    isShowAuthButtons,
    isShowBackButton,
    nextButtonCustomText,
    nextButtonDisabled,
    onBackButtonClick,
    onNextButtonClick
  }
) => {
  const history = useHistory();

  const renderBackButton = () => {
    const buttonText = backButtonCustomText || <FormattedMessage id={DEFAULT_BUTTON_MESSAGE["back"]} />;
    return (
      <SimpleButton
        customClass="back-button"
        disabled={backButtonDisabled}
        htmlType="button"
        onClick={onBackButtonClick}
      >
        <div className="back-button-text">
          <div className="back-button-icon">
            <IconArrowLeft />
          </div>
          {buttonText}
        </div>
      </SimpleButton>
    );
  };

  const renderNextButton = () => {
    const buttonText = nextButtonCustomText || <FormattedMessage id={DEFAULT_BUTTON_MESSAGE["next"]} />;
    return (
      <SimpleButton
        customClass="next-button"
        disabled={nextButtonDisabled}
        form={formName}
        htmlType={ formName ? "submit" : "button" }
        onClick={() => onNextButtonClick(formName)}
      >
        {buttonText}
      </SimpleButton>
    );
  };

  const renderContent = () => (
    <div className={classNames("children-container", { 'extended': isExtendedContent })}>
      {children}
    </div>
  );

  const renderFooter = () => (
    <div className="layout-footer">
      <div className={classNames("layout-buttons", { 'extended': isExtendedContent })}>
        {renderNextButton()}
        {isShowBackButton && renderBackButton()}
      </div>
      {isShowAuthButtons && renderUserAuthButtons()}
      {bottomHint && <div className="bottom-hint">{bottomHint}</div>}
    </div>
  );

  const renderHeader = () => (
    <div className="logo">
      <Link to="/"><img src={hihomeLogoBeta} alt="hihome-logo-beta" width={186} height={57} /></Link>
      {/* <IconLogo /> */}
    </div>
  );

  const renderUserAuthButtons = () => (
    <div className="user_auth_buttons">
      {/* <a className="user_auth_buttons__link" onClick={() => history.push(SIGN_UP)}> */}
      {/*   <FormattedMessage id="general.signUp" /> */}
      {/* </a> */}
      {/* <div className="user_auth_buttons__divider" /> */}
      <div className='have_account'>
        <FormattedMessage id="onboarding.haveAccount"/>
      </div>
      <a className="user_auth_buttons__link" onClick={() => {
        localStorage.removeItem(LAST_SAVED_ONBOARDING_STEP_KEY);
        localStorage.removeItem(ONBOARDING_DATA_KEY);
        history.push(LOGIN_PATH);
      }}>
        <FormattedMessage id="general.signIn" />
      </a>
    </div>
  );

  return (
    <Spin spinning={isLoading} style={{ minHeight: '100%' }}>
      <div className="onboarding-layout-container">
        {isLoading ? null : (
          <>
            {renderHeader()}
            {renderContent()}
            {!isHideFooter && renderFooter()}
          </>
        )}
      </div>
    </Spin>
  );
};

OnboardingLayout.propTypes = {
  backButtonCustomText: PropTypes.any,
  backButtonDisabled: PropTypes.bool,
  bottomHint: PropTypes.any,
  children: PropTypes.any,
  formName: PropTypes.string,
  isExtendedContent: PropTypes.bool,
  isHideFooter: PropTypes.bool,
  isLoading: PropTypes.bool,
  isShowAuthButtons: PropTypes.bool,
  isShowBackButton: PropTypes.bool,
  nextButtonCustomText: PropTypes.any,
  nextButtonDisabled: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
};

OnboardingLayout.defaultProps = {
  backButtonDisabled: false,
  bottomHint: null,
  formName: null,
  isExtendedContent: false,
  isHideFooter: false,
  isShowBackButton: true,
  isShowAuthButtons: false,
  nextButtonDisabled: true,
  onBackButtonClick: null,
  onNextButtonClick: null,
};

export default OnboardingLayout;
