import React from "react";
import { Checkbox } from "antd";
import "../styles.scss";

const CustomCheckbox = props => {
  const { children, value, ...otherProps } = props;

  return (
    <div className="checkbox-wrapper">
      <Checkbox value={value} {...otherProps} >
        <span className="checkbox__text">{children}</span>
      </Checkbox>
    </div>
  );
};

export default CustomCheckbox;