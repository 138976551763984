import React from "react";
import { InputNumber } from "antd";
import { normalizeNumber, parseNumberToInt } from "utils/helpers/criteriaHelper";
import "../styles.scss";


const NumberInput = ({maxInputValue, isFloat, ...otherProps}) => (
  <div className="custom-input-wrapper input-number">
    <InputNumber
      formatter={value => normalizeNumber(value)}
      parser={value => parseNumberToInt(value, maxInputValue, isFloat)}
      controls={false}
      {...otherProps}
    />
  </div>
);

export default NumberInput;