import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import _ from "lodash";
import { getMethodForScore } from "utils/helpers/housesHelper/getter";
import { SimpleButton } from "components/Controls/Buttons";
import { trackScheduleTour } from "utils/helpers/userHelper";
import {
  groceryMarker,
  parkMarker,
  restaurantMarker,
  schoolMarker,
  transitStopMarker
} from "images/HouseDetailsMarkers";

const formatBooleanValue = value =>
    value ? <FormattedMessage id="general.yesMessage" /> : <FormattedMessage id="general.noMessage" />;

const getItemValue = (houseDetails, item, key) => {
    let itemValue = _.get(houseDetails, key);
    if (['score', 'list', 'need_processing'].includes(item.valueType)) {
        itemValue = {
          score: () => _.get(houseDetails, `${key}.value`),
          list: () => _.get(houseDetails, key, []).join('\n'),
          need_processing:  () => _.has(item, 'method') ? item.method(houseDetails, key) : null,
        }[item.valueType]();
    }
    return _.isBoolean(itemValue) ? formatBooleanValue(itemValue) : itemValue;
};

export const getDetailsRow = (houseDetails, item, key) => {
  const value = getItemValue(houseDetails, item, key);

  if (!item.titleMessage && !value) {
    return null;
  }

  const colorClassName = _.has(houseDetails, `${key}.score`)
    ? getMethodForScore(key, _.get(houseDetails, `${key}.score`),_.get(houseDetails, `${key}.value`))
    : null

  return (
    <div className="other-detail">
      <div className="other-detail__title">
        {item.titleMessage && <FormattedMessage id={item.titleMessage} />}
      </div>
      <div className="other-detail__values">
        <div className={`value-message details-tag ${colorClassName}`}>
          {value}
        </div>
      </div>
    </div>
  )
}

export const getOtherDetailsRow = (houseDetails, item, key) => {
  let value = '';

  if (key === 'broker') {
    const data = _.get(houseDetails, key);
    const agent = _.get(houseDetails, "agent")
    return (
      <>
        <div className="house-details-other-details__tour">
          <div className="house-details-other-details__tour__text">
            <FormattedMessage id="houseDetails.otherDetails.tourText"/>
          </div>
          <SimpleButton
            onClick={() => trackScheduleTour(houseDetails)}
            customClass={classNames("button-schedule-a-tour")}
          >
            <FormattedMessage id="houseDetails.scheduleATour" />
          </SimpleButton>
        </div>
        <div className="broker-card">
          <div className="broker-card__subtitle">
            <FormattedMessage id="houseDetails.otherDetails.subtitle"/>
          </div>
          <div className="broker-card__name">
            {_.get(data, 'name')}
          </div>
          {_.get(agent, "name") &&
          <div className="broker-card__type">
            {_.get(agent, "name")}
          </div>}
          <div className="broker-card__phone">
            <div  className="broker-card__phone-number">
              {_.get(data, 'phone1.number') && _.get(data, 'phone1.number').replace(/[^+\d]+/g, "")}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="other-detail">
      <div className="other-detail__title">
        {item.titleMessage && <FormattedMessage id={item.titleMessage} />}
      </div>
      <div className="other-detail__values">
        <div className={classNames(`value-message details-tag ${getMethodForScore(key, _.get(houseDetails, `${key}.score`),_.get(houseDetails, `${key}.value`))}`)}>
          {value}
        </div>
      </div>
    </div>
  )
}

const HOUSE_PLACES_DATA = [
  {
    key: "grocery_stores",
    kind: "grocery",
    icon: groceryMarker,
    valueKey: "grocery.places",
  },
  {
    key: "parks",
    kind: "park",
    icon: parkMarker,
    valueKey: "parks.places",
  },
  {
    key: "restaurants_and_cafes",
    icon: restaurantMarker,
  },
  {
    key: "schools",
    kind: "school",
    icon: schoolMarker,
    valueKey: "schools.schools",
  },
  {
    key: "transit_stations",
    icon: transitStopMarker,
  },
];

export { HOUSE_PLACES_DATA };
