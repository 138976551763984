import React from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "antd";
import CustomCheckbox from "components/Controls/Checkboxes/CustomCheckbox";
import "./styles.scss";

const HOUSE_DETAIL_PLACES = [
  { key: "schools", value: "schools", message_id: "houseDetails.schools" },
  { key: "restaurants_and_cafes", value: "restaurants_and_cafes", message_id: "houseDetails.restaurantsAndCafes" },
  { key: "transit_stations", value: "transit_stations", message_id: "houseDetails.transitStops" },
  { key: "parks", value: "parks", message_id: "houseDetails.parks" },
  { key: "grocery_stores", value: "grocery_stores", message_id: "houseDetails.groceryStores" },
];
const HousePlacesModalView = ({setCurCheckBoxPlaces}) => {

  const onHouseTypeChange = checkedValues => setCurCheckBoxPlaces(checkedValues);

  return (
    <div className="neighborhood-table-list">
      <Checkbox.Group onChange={onHouseTypeChange}>
        {HOUSE_DETAIL_PLACES.map(property_type => (
          <CustomCheckbox key={property_type.key} value={property_type.value}>
            <FormattedMessage id={property_type.message_id} />
          </CustomCheckbox>
        ))}
      </Checkbox.Group>
    </div>
  );
};

export default HousePlacesModalView;
