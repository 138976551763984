import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Spin } from "antd";
import classNames from "classnames";
import { HOUSE_VIEWS } from "constants/houses";
import HouseList from "./HouseList";
import MapMarkers from "./MapMarkers";
import MapRegions from './MapRegions';
import { inject, observer } from "mobx-react";
import { formatSearchParams } from "utils/helpers/searchHelper";
import _ from 'lodash';
import MapInfoWindow from "./MapInfoWindow";
import MapIsochrones from "./MapIsochrones";
import UnitsInfoWindow from "./UnitsInfoWindow";
import "./styles.scss";

const GET_HOUSES_METHODS = {
  [HOUSE_VIEWS.topMatches]: 'getHousesMapRequest',
  [HOUSE_VIEWS.favorites]: 'getHousesFavoritesMapRequest'
};

// const DEFAULT_CENTER = new window.google.maps.LatLng(42.407211, -71.382439); // center of MA USA
const DEFAULT_ZOOM = 8;

const MapView = observer((
  {
    activeTab,
    criteriaStore,
    housesStore,
    view,
    viewedHouseIds,
    getHousesData,
    handleFavorites,
    handleOnClickHouse,
    handleNonAuthFavorites,
    setViewedHouseIds,
    updateViewedHouseIds
  }
) => {
  const [activeUnitsData, setActiveUnitsData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const DEFAULT_CENTER = new window.google.maps.LatLng(42.407211, -71.382439); // center of MA USA
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [isDragAndZoomBlocked, setIsDragAndZoomBlocked] = useState(false);

  useEffect(() => {
    const handleSuccess = housesData => {
      updateViewedHouseIds(housesData);
    };
    getHousesData(handleSuccess, true);
  }, []);

  const mapRef = useRef(null);
  const housesData = housesStore.retrieveHousesData();

  const getMapPosition = () => ({
    north_east: {
      lat: mapRef.current.getBounds().getNorthEast().lat(),
      lng: mapRef.current.getBounds().getNorthEast().lng(),
    },
    south_west: {
      lat: mapRef.current.getBounds().getSouthWest().lat(),
      lng: mapRef.current.getBounds().getSouthWest().lng(),
    }
  });

  const handleGetHouses = () => {
    if (mapRef.current && !housesStore.loading) {
      const criteriaData = criteriaStore.retrieveCriteriaData();
      const searchParams = { filters: formatSearchParams(criteriaData) };
      searchParams["filters"]["position"] = getMapPosition();
      const getHousesMethodName = GET_HOUSES_METHODS[housesStore.view]
      housesStore[getHousesMethodName](searchParams);
      housesStore.setCurrentPage(1);
    }
  };

  const handleLoad = (map) => {
    setIsDragAndZoomBlocked(true);
    const bounds = new window.google.maps.LatLngBounds();
    if (!_.isEmpty(housesData)) {
      housesData.data.forEach(({address}) =>
          bounds.extend({lat: address.location.lat, lng: address.location.lon})
      );
      map.fitBounds(bounds);
    }
    map.setOptions(
      {
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      }
    ) 
    mapRef.current = map;
  };

  const onDragAndZoom = () => {
    if (!isDragAndZoomBlocked) {
      housesStore.handleTimeout(mapRef.current, handleGetHouses, isLoaded, setIsLoaded);
      if (mapRef.current) {
        setActiveUnitsData(null);
        setCenter(mapRef.center || center);
        setZoom(mapRef.current.zoom || zoom);
      }
    } else {
      setIsDragAndZoomBlocked(false);
    }
  };

  const onUnitsMarkerClick = data => {
    const markerData = activeUnitsData ? null : data;
    setActiveUnitsData(markerData);
  };

  const renderMap = () => (
    <div className="map-wrapper">
      <GoogleMap
        mapContainerClassName={classNames("map-container", { isFavorites: view === HOUSE_VIEWS.favorites })}
        onLoad={handleLoad}
        onDragEnd={onDragAndZoom}
        onZoomChanged={onDragAndZoom}
        options={{ mapId: process.env.REACT_APP_GOOGLE_MAP_ID }}
        zoom={zoom}
        center={center}
      >
        <MapMarkers
          viewedHouseList={viewedHouseIds}
          zoom={zoom}
          handleOnClickHouse={handleOnClickHouse}
          onUnitsMarkerClick={onUnitsMarkerClick}
          setViewedHouseList={setViewedHouseIds}
        />
        <MapInfoWindow viewedHouseList={viewedHouseIds} />
        <MapRegions />
        <MapIsochrones />
        {!_.isNull(activeUnitsData) && (
          <UnitsInfoWindow
            activeUnitsData={activeUnitsData}
            viewedHouseList={viewedHouseIds}
            handleOnClickHouse={handleOnClickHouse}
            setViewedHouseList={setViewedHouseIds}
          />
        )}
      </GoogleMap>
    </div>
  );

  if (_.isEmpty(housesData) && housesStore.loading) {
    return null;
  }

  return (
    <div className="map-view-wrapper">
      <Spin spinning={criteriaStore.loading || housesStore.loading}>
        <div className="map-view-container">
          <HouseList
            activeTab={activeTab}
            criteriaStore={criteriaStore}
            view={view}
            viewedHouseIds={viewedHouseIds}
            handleFavorites={handleFavorites}
            handleNonAuthFavorites={handleNonAuthFavorites}
            handleOnClickHouse={handleOnClickHouse}
          />
          {renderMap()}
        </div>
      </Spin>
    </div>
  );
});

export default inject("criteriaStore", "housesStore")(MapView);
