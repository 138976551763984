import React from "react";
import HelmetIntl from "components/Meta/HelmetIntl";
import EmailConfirm from "containers/SignUp/EmailConfirm";

const EmailConfirmPage = () => (
  <>
    <HelmetIntl id="helmet.confirmEmailTitle" />
    <EmailConfirm />
  </>
);
export default EmailConfirmPage;
