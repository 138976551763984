import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import {
  get as lodashGet,
  findKey as lodashFindKey
} from "lodash";
import PropTypes from "prop-types";
import PriorityField from "components/Fields/PriorityField";
import SliderRangeSelectField from "components/Fields/SliderRangeSelectField";
import { LOT_SIZE_OPTIONS, LOT_SIZE_VALUES } from "containers/Dashboard/constants";
import { addPriorityValues } from "utils/helpers/criteriaHelper";
import "../../styles.scss";


const DEFAULT_VALUES = {
  min: 0,
  max: 15,
  ideal: 3,
  step: 1,
}

const LotSizeForm = ({ dataKind, formName, initialData, kind, withPriority, onSubmitForm }) => {
  const [form] = Form.useForm();

  const getCriteriaValueByKey  = valueKey => (
    lodashFindKey(LOT_SIZE_VALUES, item => item === lodashGet(initialData, valueKey))
  );

  const getInitialValues = () => {
    let initialValues = {
      range: {
        ideal: getCriteriaValueByKey("ideal") || DEFAULT_VALUES.ideal,
        max: getCriteriaValueByKey("max") || DEFAULT_VALUES.max,
        min: getCriteriaValueByKey("min") || DEFAULT_VALUES.min,
      },
    };
    if (withPriority) {
      initialValues = addPriorityValues(initialValues, lodashGet(initialData, "priority"));
    }
    return initialValues;
  };

  const onFinish = values => {
    let updatedValues = {
      ideal: LOT_SIZE_VALUES[values.range.ideal],
      max: LOT_SIZE_VALUES[values.range.max],
      min: LOT_SIZE_VALUES[values.range.min],
      kind
    };
    if (withPriority) {
      updatedValues = addPriorityValues(updatedValues, values.priority);
    }
    onSubmitForm(updatedValues);
  };

  return (
    <Form
      className="lot-size-form"
      form={form}
      initialValues={getInitialValues()}
      name={formName}
      onFinish={onFinish}
    >
      <Form.Item name="range">
        <SliderRangeSelectField
          dataKind={dataKind}
          form={form}
          options={LOT_SIZE_OPTIONS}
          kind={kind}
          histogramSteps={Object.values(LOT_SIZE_VALUES)}
          min={DEFAULT_VALUES.min}
          max={DEFAULT_VALUES.max}
          step={DEFAULT_VALUES.step}
          ideal={DEFAULT_VALUES.ideal}
          minMessage={<FormattedMessage id="dashboard.form.minimumSize" />}
          idealMessage={<FormattedMessage id="dashboard.form.targetSize" />}
          maxMessage={<FormattedMessage id="dashboard.form.maximumSize" />}
        />
      </Form.Item>
      {withPriority && (
        <Form.Item name="priority">
          <PriorityField />
        </Form.Item>
      )}
    </Form>
  );
};

LotSizeForm.propTypes = {
  dataKind: PropTypes.string,
  formName: PropTypes.string,
  initialData: PropTypes.object,
  kind: PropTypes.string,
  withPriority: PropTypes.bool,
  onSubmitForm: PropTypes.func,
};

export default LotSizeForm;
