/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import HelmetIntl from 'components/Meta/HelmetIntl';
import NotFound from "containers/NotFound";

const NotFoundPage = () => (
  <div>
    <HelmetIntl id="helmet.notFoundTitle" />
    <NotFound />
  </div>
);
export default NotFoundPage;
