import React from "react";

const IconMoreCriteria = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 0H10C9.44727 0 9 0.446875 9 1V6C9 6.55312 9.44727 7 10 7H15C15.5527 7 16 6.55312 16 6V1C16 0.446875 15.5527 0 15 0ZM14 5H11V2H14V5Z" fill="#1B235E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 9H15C15.5527 9 16 9.44687 16 10V15C16 15.5531 15.5527 16 15 16H10C9.44727 16 9 15.5531 9 15V10C9 9.44687 9.44727 9 10 9ZM14 14H11V11H14V14Z" fill="#1B235E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 9H6C6.55273 9 7 9.44687 7 10V15C7 15.5531 6.55273 16 6 16H1C0.447266 16 0 15.5531 0 15V10C0 9.44687 0.447266 9 1 9ZM5 14H2V11H5V14Z" fill="#1B235E"/>
    <path d="M3 1H4V3H6V4H4V6.00313H3V4H1V3H3V1Z" fill="#1B235E"/>
  </svg>
);

export default IconMoreCriteria;
