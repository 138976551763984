import React from "react";

const IconCommuteDetails = (props) => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.1568 4.92075H13.1883V3.45091C13.1883 2.95157 12.7821 2.54538 12.2827 2.54538H7.71583C7.2165 2.54538 6.81031 2.95157 6.81031 3.45091V4.92075H4.8418V3.45091C4.8418 1.8662 6.13109 0.576904 7.71579 0.576904H12.2827C13.8674 0.576904 15.1567 1.8662 15.1567 3.45091V4.92075H15.1568Z" fill="url(#paint0_linear_6489_18699)"/>
    <path d="M12.0038 1.36279H7.99528C6.6043 1.36279 5.47266 2.65209 5.47266 4.2368V4.9206H6.81062V3.45076C6.81062 2.95142 7.21681 2.54523 7.71614 2.54523H12.2831C12.7824 2.54523 13.1886 2.95142 13.1886 3.45076V4.9206H14.5266V4.2368C14.5265 2.65209 13.3948 1.36279 12.0038 1.36279Z" fill="url(#paint1_linear_6489_18699)"/>
    <path d="M18.301 17.1938H1.69893C0.972068 17.1938 0.382812 16.6045 0.382812 15.8777V5.40035C0.382812 4.67348 0.972068 4.08423 1.69893 4.08423H18.3009C19.0278 4.08423 19.6171 4.67348 19.6171 5.40035V15.8777C19.6171 16.6045 19.0278 17.1938 18.301 17.1938Z" fill="url(#paint2_linear_6489_18699)"/>
    <path d="M19.6175 5.39995V15.8771C19.6175 16.6047 19.0277 17.1937 18.3009 17.1937H6.81352L0.382812 10.7357V5.39995C0.382812 4.67317 0.972577 4.08423 1.69936 4.08423H18.3009C19.0277 4.08423 19.6175 4.67317 19.6175 5.39995Z" fill="url(#paint3_linear_6489_18699)"/>
    <path d="M19.6172 10.2246V15.877C19.6172 16.6045 19.0272 17.1936 18.3006 17.1936H8.46915L3.21021 11.9347C3.06915 11.8373 2.97656 11.6748 2.97656 11.491V10.763C2.97656 10.4657 3.21762 10.2246 3.51499 10.2246H19.6172Z" fill="url(#paint4_linear_6489_18699)"/>
    <path d="M11.1051 17.1936H18.2997C19.0264 17.1936 19.6163 16.6045 19.6163 15.877V10.2246H6.7832V12.8717L11.1051 17.1936Z" fill="url(#paint5_linear_6489_18699)"/>
    <path d="M14.3887 10.763V11.491C14.3887 11.6748 14.4813 11.8373 14.6223 11.9347L19.3543 16.6667C19.5195 16.4467 19.6174 16.1734 19.6174 15.877V10.2246H14.9271C14.6297 10.2246 14.3887 10.4657 14.3887 10.763Z" fill="url(#paint6_linear_6489_18699)"/>
    <path d="M0.382812 12.926V15.8776C0.382812 16.6045 0.972068 17.1937 1.69893 17.1937H18.3009C19.0278 17.1937 19.6171 16.6045 19.6171 15.8776V12.926H0.382812Z" fill="url(#paint7_linear_6489_18699)"/>
    <path d="M7.34975 4.24178H4.29297V3.84876C4.29297 3.58347 4.50803 3.36841 4.77332 3.36841H6.8694C7.13469 3.36841 7.34975 3.58347 7.34975 3.84876V4.24178Z" fill="url(#paint8_linear_6489_18699)"/>
    <path d="M15.7052 4.24178H12.6484V3.84876C12.6484 3.58347 12.8635 3.36841 13.1288 3.36841H15.2249C15.4902 3.36841 15.7052 3.58347 15.7052 3.84876V4.24178Z" fill="url(#paint9_linear_6489_18699)"/>
    <path d="M18.6315 11.1516H1.36851C0.612706 11.1516 0 10.5389 0 9.78306V5.31455C0 4.55875 0.612706 3.94604 1.36851 3.94604H18.6314C19.3872 3.94604 20 4.55875 20 5.31455V9.78306C20 10.5389 19.3873 11.1516 18.6315 11.1516Z" fill="url(#paint10_linear_6489_18699)"/>
    <path d="M11 5.43115L12.2288 6.6599H11V5.43115Z" fill="url(#paint11_linear_6489_18699)"/>
    <path d="M13.373 7.68604V11.1516H16.8386L13.373 7.68604Z" fill="url(#paint12_linear_6489_18699)"/>
    <path d="M2.11164 16.2982C1.70654 16.1692 1.38677 15.8494 1.25772 15.4443C1.22489 15.3412 1.12646 15.2732 1.0183 15.2732H0.385911C0.246107 15.2732 0.132812 15.3865 0.132812 15.5263V16.1069C0.132812 16.8338 0.722068 17.423 1.44893 17.423H2.02956C2.16936 17.423 2.28266 17.3097 2.28266 17.17V16.5376C2.2827 16.4295 2.2147 16.331 2.11164 16.2982Z" fill="url(#paint13_linear_6489_18699)"/>
    <path d="M2.11217 16.2982C1.70707 16.1691 1.38731 15.8493 1.25825 15.4443C1.22543 15.3412 1.12699 15.2732 1.01884 15.2732H0.646484V15.5938C0.646484 16.3207 1.23574 16.9099 1.9626 16.9099H2.28323V16.5376C2.28323 16.4294 2.21523 16.331 2.11217 16.2982Z" fill="url(#paint14_linear_6489_18699)"/>
    <path d="M17.8879 16.2982C18.293 16.1692 18.6127 15.8494 18.7418 15.4443C18.7746 15.3412 18.873 15.2732 18.9812 15.2732H19.6135C19.7533 15.2732 19.8666 15.3865 19.8666 15.5263V16.1069C19.8666 16.8338 19.2774 17.423 18.5505 17.423H17.9699C17.8301 17.423 17.7168 17.3097 17.7168 17.17V16.5376C17.7168 16.4295 17.7848 16.331 17.8879 16.2982Z" fill="url(#paint15_linear_6489_18699)"/>
    <path d="M17.8879 16.2982C18.293 16.1691 18.6127 15.8493 18.7418 15.4443C18.7746 15.3412 18.873 15.2732 18.9812 15.2732H19.3535V15.5938C19.3535 16.3207 18.7643 16.9099 18.0374 16.9099H17.7168V16.5376C17.7168 16.4294 17.7848 16.331 17.8879 16.2982Z" fill="url(#paint16_linear_6489_18699)"/>
    <path d="M5.13159 11.9856H3.47218C3.17473 11.9856 2.93359 11.7445 2.93359 11.4471V10.7193C2.93359 10.4218 3.17473 10.1807 3.47218 10.1807H5.13155C5.429 10.1807 5.67014 10.4218 5.67014 10.7193V11.4471C5.67014 11.7445 5.429 11.9856 5.13159 11.9856Z" fill="url(#paint17_linear_6489_18699)"/>
    <path d="M3.62096 11.7092H3.45354C3.38319 11.7092 3.32617 11.6522 3.32617 11.5818V11.2106C3.32617 11.1403 3.38319 11.0833 3.45354 11.0833H3.62096C3.69131 11.0833 3.74833 11.1403 3.74833 11.2106V11.5818C3.74833 11.6522 3.69131 11.7092 3.62096 11.7092Z" fill="url(#paint18_linear_6489_18699)"/>
    <path d="M4.38463 11.7092H4.21722C4.14686 11.7092 4.08984 11.6522 4.08984 11.5818V11.2106C4.08984 11.1403 4.14686 11.0833 4.21722 11.0833H4.38463C4.45498 11.0833 4.512 11.1403 4.512 11.2106V11.5818C4.512 11.6522 4.45494 11.7092 4.38463 11.7092Z" fill="url(#paint19_linear_6489_18699)"/>
    <path d="M5.15025 11.7092H4.98284C4.91249 11.7092 4.85547 11.6522 4.85547 11.5818V11.2106C4.85547 11.1403 4.91249 11.0833 4.98284 11.0833H5.15025C5.22061 11.0833 5.27763 11.1403 5.27763 11.2106V11.5818C5.27759 11.6522 5.22057 11.7092 5.15025 11.7092Z" fill="url(#paint20_linear_6489_18699)"/>
    <path d="M16.528 11.9856H14.8687C14.5712 11.9856 14.3301 11.7445 14.3301 11.4471V10.7193C14.3301 10.4218 14.5712 10.1807 14.8687 10.1807H16.528C16.8255 10.1807 17.0666 10.4218 17.0666 10.7193V11.4471C17.0666 11.7445 16.8255 11.9856 16.528 11.9856Z" fill="url(#paint21_linear_6489_18699)"/>
    <path d="M15.0174 11.7092H14.85C14.7797 11.7092 14.7227 11.6522 14.7227 11.5818V11.2106C14.7227 11.1403 14.7797 11.0833 14.85 11.0833H15.0174C15.0878 11.0833 15.1448 11.1403 15.1448 11.2106V11.5818C15.1448 11.6522 15.0878 11.7092 15.0174 11.7092Z" fill="url(#paint22_linear_6489_18699)"/>
    <path d="M15.7811 11.7092H15.6137C15.5433 11.7092 15.4863 11.6522 15.4863 11.5818V11.2106C15.4863 11.1403 15.5433 11.0833 15.6137 11.0833H15.7811C15.8515 11.0833 15.9085 11.1403 15.9085 11.2106V11.5818C15.9085 11.6522 15.8515 11.7092 15.7811 11.7092Z" fill="url(#paint23_linear_6489_18699)"/>
    <path d="M16.5467 11.7092H16.3793C16.309 11.7092 16.252 11.6522 16.252 11.5818V11.2106C16.252 11.1403 16.309 11.0833 16.3793 11.0833H16.5467C16.6171 11.0833 16.6741 11.1403 16.6741 11.2106V11.5818C16.6741 11.6522 16.6171 11.7092 16.5467 11.7092Z" fill="url(#paint24_linear_6489_18699)"/>
    <path d="M10.0021 13.3803C11.7679 13.3803 13.1994 11.9488 13.1994 10.183C13.1994 8.41711 11.7679 6.9856 10.0021 6.9856C8.2362 6.9856 6.80469 8.41711 6.80469 10.183C6.80469 11.9488 8.2362 13.3803 10.0021 13.3803Z" fill="url(#paint25_linear_6489_18699)"/>
    <path d="M10.4333 9.76977C10.4333 9.94373 10.3083 10.3109 10.1541 10.3109C9.99997 10.3109 9.875 9.94373 9.875 9.76977C9.875 9.59581 9.99997 8.32983 10.1541 8.32983C10.3083 8.32983 10.4333 9.59581 10.4333 9.76977Z" fill="url(#paint26_linear_6489_18699)"/>
    <path d="M10.4334 9.76977C10.4334 9.59581 10.3085 8.32983 10.1543 8.32983V10.3109C10.3085 10.3109 10.4334 9.94373 10.4334 9.76977Z" fill="url(#paint27_linear_6489_18699)"/>
    <path d="M9.56904 8.3113L10.4278 10.1646C10.4426 10.1965 10.415 10.2049 10.3831 10.2197L9.92258 10.433C9.89061 10.4478 9.86639 10.4634 9.85157 10.4315L8.99284 8.57819C8.97234 8.53396 8.91986 8.51472 8.87563 8.5352C8.80426 8.56825 8.72855 8.49855 8.75562 8.42472L8.95319 7.88601C8.96997 7.84024 9.02068 7.81676 9.06647 7.83355L9.60529 8.03116C9.67912 8.05825 9.68337 8.16106 9.61201 8.19412C9.56778 8.21461 9.54854 8.26707 9.56904 8.3113Z" fill="url(#paint28_linear_6489_18699)"/>
    <path d="M10.3822 10.2197C10.4141 10.2049 10.4417 10.1965 10.4269 10.1646L9.56813 8.31131C9.54763 8.26708 9.56687 8.21462 9.6111 8.19412C9.68247 8.16107 9.67821 8.05824 9.60438 8.03115L9.06556 7.83354C9.04267 7.82516 9.01855 7.82683 8.99805 7.83632L10.1519 10.3264L10.3822 10.2197Z" fill="url(#paint29_linear_6489_18699)"/>
    <path d="M10.1539 10.7441C10.3931 10.7441 10.5871 10.5501 10.5871 10.3109C10.5871 10.0716 10.3931 9.87769 10.1539 9.87769C9.91465 9.87769 9.7207 10.0716 9.7207 10.3109C9.7207 10.5501 9.91465 10.7441 10.1539 10.7441Z" fill="url(#paint30_linear_6489_18699)"/>
    <path d="M11.1002 6.12309V5.65517C11.1002 5.39898 10.8152 5.2456 10.6014 5.38674L9.68754 5.98988C7.51116 6.15039 5.79492 7.96568 5.79492 10.183C5.79492 12.5059 7.67806 14.3886 10.001 14.3886C12.3239 14.3886 14.207 12.5059 14.207 10.183C14.207 8.24058 12.8898 6.6065 11.1002 6.12309ZM10.001 13.1587C8.35743 13.1587 7.02476 11.8266 7.02476 10.183C7.02476 8.71027 8.09515 7.48792 9.50017 7.24964L10.6014 7.97642C10.8152 8.11756 11.1002 7.96419 11.1002 7.70799V7.41741C12.1995 7.85486 12.9771 8.92787 12.9771 10.183C12.9771 11.8266 11.6445 13.1587 10.001 13.1587Z" fill="url(#paint31_linear_6489_18699)"/>
    <path d="M11.0989 6.71201V7.41739C12.1983 7.85468 12.9759 8.92743 12.9759 10.1826C12.9759 11.8261 11.6432 13.1588 9.99969 13.1588C8.35616 13.1588 7.02349 11.8261 7.02349 10.1826C7.02349 8.70982 8.09388 7.48786 9.4989 7.24966L9.04494 6.95005C8.958 6.89268 8.91071 6.80358 8.90224 6.71143C7.42816 7.17688 6.35938 8.55456 6.35938 10.1826C6.35938 12.1932 7.98914 13.8229 9.99969 13.8229C12.0102 13.8229 13.64 12.1932 13.64 10.1826C13.64 8.55523 12.5721 7.17797 11.0989 6.71201Z" fill="url(#paint32_linear_6489_18699)"/>
    <path d="M7.89933 8.07586C8.32564 7.65065 8.88043 7.35465 9.50015 7.24955L9.04615 6.9499C8.85352 6.82276 8.85352 6.54018 9.04615 6.41304L9.68752 5.98975C8.26501 6.09465 7.03984 6.90641 6.36133 8.07582H7.89933V8.07586Z" fill="url(#paint33_linear_6489_18699)"/>
    <defs>
    <linearGradient id="paint0_linear_6489_18699" x1="9.99928" y1="0.878748" x2="9.99928" y2="3.47016" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6489_18699" x1="9.99955" y1="3.48487" x2="9.99955" y2="0.835028" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6489_18699" x1="8.52348" y1="6.01705" x2="12.0121" y2="16.9381" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D78878"/>
    <stop offset="1" stopColor="#CA6E59"/>
    </linearGradient>
    <linearGradient id="paint3_linear_6489_18699" x1="10.0001" y1="15.9443" x2="10.0001" y2="8.3147" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CA6E59" stopOpacity="0"/>
    <stop offset="1" stopColor="#883F2E"/>
    </linearGradient>
    <linearGradient id="paint4_linear_6489_18699" x1="12.5288" y1="14.0259" x2="10.1124" y2="7.9123" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CA6E59" stopOpacity="0"/>
    <stop offset="1" stopColor="#883F2E"/>
    </linearGradient>
    <linearGradient id="paint5_linear_6489_18699" x1="16.191" y1="15.0657" x2="11.6224" y2="9.69316" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CA6E59" stopOpacity="0"/>
    <stop offset="1" stopColor="#883F2E"/>
    </linearGradient>
    <linearGradient id="paint6_linear_6489_18699" x1="18.8703" y1="14.4408" x2="16.1337" y2="9.28794" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CA6E59" stopOpacity="0"/>
    <stop offset="1" stopColor="#883F2E"/>
    </linearGradient>
    <linearGradient id="paint7_linear_6489_18699" x1="9.99995" y1="13.7921" x2="9.99995" y2="16.8721" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CA6E59" stopOpacity="0"/>
    <stop offset="1" stopColor="#883F2E"/>
    </linearGradient>
    <linearGradient id="paint8_linear_6489_18699" x1="5.8214" y1="3.48488" x2="5.8214" y2="3.95158" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EEF5F6"/>
    <stop offset="1" stopColor="#CBE5E8"/>
    </linearGradient>
    <linearGradient id="paint9_linear_6489_18699" x1="14.1768" y1="3.48488" x2="14.1768" y2="3.95158" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EEF5F6"/>
    <stop offset="1" stopColor="#CBE5E8"/>
    </linearGradient>
    <linearGradient id="paint10_linear_6489_18699" x1="9.99999" y1="4.60569" x2="9.99999" y2="10.9749" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D78878"/>
    <stop offset="1" stopColor="#CA6E59"/>
    </linearGradient>
    <linearGradient id="paint11_linear_6489_18699" x1="11.9673" y1="7.01284" x2="10.7516" y2="5.7971" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CA6E59" stopOpacity="0"/>
    <stop offset="1" stopColor="#883F2E"/>
    </linearGradient>
    <linearGradient id="paint12_linear_6489_18699" x1="16.5289" y1="10.9806" x2="12.6858" y2="9.07866" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CA6E59" stopOpacity="0"/>
    <stop offset="1" stopColor="#883F2E"/>
    </linearGradient>
    <linearGradient id="paint13_linear_6489_18699" x1="1.20775" y1="15.654" x2="1.20775" y2="17.271" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EEF5F6"/>
    <stop offset="1" stopColor="#CBE5E8"/>
    </linearGradient>
    <linearGradient id="paint14_linear_6489_18699" x1="1.56335" y1="15.993" x2="1.06907" y2="16.4873" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EEF5F6"/>
    <stop offset="1" stopColor="#CBE5E8"/>
    </linearGradient>
    <linearGradient id="paint15_linear_6489_18699" x1="18.7917" y1="15.654" x2="18.7917" y2="17.271" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EEF5F6"/>
    <stop offset="1" stopColor="#CBE5E8"/>
    </linearGradient>
    <linearGradient id="paint16_linear_6489_18699" x1="18.4367" y1="15.993" x2="18.931" y2="16.4873" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EEF5F6"/>
    <stop offset="1" stopColor="#CBE5E8"/>
    </linearGradient>
    <linearGradient id="paint17_linear_6489_18699" x1="4.30187" y1="10.1807" x2="4.30187" y2="12.0584" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint18_linear_6489_18699" x1="3.83566" y1="11.3962" x2="3.25178" y2="11.3962" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint19_linear_6489_18699" x1="4.59934" y1="11.3962" x2="4.01545" y2="11.3962" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint20_linear_6489_18699" x1="5.36496" y1="11.3962" x2="4.78108" y2="11.3962" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint21_linear_6489_18699" x1="15.6984" y1="10.1807" x2="15.6984" y2="12.0584" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint22_linear_6489_18699" x1="15.2322" y1="11.3962" x2="14.6483" y2="11.3962" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint23_linear_6489_18699" x1="15.9958" y1="11.3962" x2="15.4119" y2="11.3962" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint24_linear_6489_18699" x1="16.7615" y1="11.3962" x2="16.1776" y2="11.3962" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE177"/>
    <stop offset="1" stopColor="#FE9738"/>
    </linearGradient>
    <linearGradient id="paint25_linear_6489_18699" x1="8.72041" y1="8.90132" x2="11.1992" y2="11.3801" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EEF5F6"/>
    <stop offset="1" stopColor="#CBE5E8"/>
    </linearGradient>
    <linearGradient id="paint26_linear_6489_18699" x1="9.875" y1="9.32036" x2="10.4333" y2="9.32036" gradientUnits="userSpaceOnUse">
    <stop stopColor="#92F37F"/>
    <stop offset="1" stopColor="#4AB272"/>
    </linearGradient>
    <linearGradient id="paint27_linear_6489_18699" x1="10.3412" y1="9.22609" x2="9.38607" y2="9.74483" gradientUnits="userSpaceOnUse">
    <stop stopColor="#005A01" stopOpacity="0"/>
    <stop offset="1" stopColor="#005A01"/>
    </linearGradient>
    <linearGradient id="paint28_linear_6489_18699" x1="9.06561" y1="9.08227" x2="10.6862" y2="9.39631" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD945"/>
    <stop offset="0.3043" stopColor="#FFCD3E"/>
    <stop offset="0.8558" stopColor="#FFAD2B"/>
    <stop offset="1" stopColor="#FFA325"/>
    </linearGradient>
    <linearGradient id="paint29_linear_6489_18699" x1="9.7879" y1="8.67765" x2="9.29298" y2="11.799" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F35227" stopOpacity="0"/>
    <stop offset="1" stopColor="#F35227"/>
    </linearGradient>
    <linearGradient id="paint30_linear_6489_18699" x1="10.0678" y1="10.2154" x2="10.4145" y2="10.5999" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FF819C"/>
    <stop offset="0.2594" stopColor="#FF7E9A"/>
    <stop offset="0.4757" stopColor="#FE7396"/>
    <stop offset="0.6765" stopColor="#FC628E"/>
    <stop offset="0.8668" stopColor="#FA4A83"/>
    <stop offset="1" stopColor="#F83479"/>
    </linearGradient>
    <linearGradient id="paint31_linear_6489_18699" x1="6.92214" y1="7.1045" x2="12.3055" y2="12.4879" gradientUnits="userSpaceOnUse">
    <stop stopColor="#60B7FF"/>
    <stop offset="1" stopColor="#0268B4"/>
    </linearGradient>
    <linearGradient id="paint32_linear_6489_18699" x1="11.865" y1="12.0482" x2="7.39619" y2="7.57939" gradientUnits="userSpaceOnUse">
    <stop stopColor="#60B7FF"/>
    <stop offset="1" stopColor="#0268B4"/>
    </linearGradient>
    <linearGradient id="paint33_linear_6489_18699" x1="7.63215" y1="7.60818" x2="9.18995" y2="6.55868" gradientUnits="userSpaceOnUse">
    <stop stopColor="#0268B4" stopOpacity="0"/>
    <stop offset="1" stopColor="#0268B4"/>
    </linearGradient>
    </defs>
  </svg>
);

export default IconCommuteDetails;
