import React from "react";

const IconAngleLeft = props => (
  <svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.440703 5.67199L4.55086 9.72201C5.39429 10.5153 6.53985 9.43693 5.7153 8.65915L1.99224 4.99957L5.73733
    1.2997C6.50523 0.577704 5.32191 -0.482057 4.55086 0.243042L0.431262 4.30236C-0.100603 4.79195 -0.188723 5.08943
    0.440703 5.67199Z"
    />
  </svg>
);

export default IconAngleLeft;
