import React from "react";
import { get as lodashGet } from "lodash";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { HOUSE_VIEWS } from "constants/houses";
import { FiltersBarView, SwitcherView } from "../Views";
import "./styles.scss";

const HEADER_TITLE_MAP = {
  [HOUSE_VIEWS.topMatches]: "houses.topHomeMatchesTitle",
  [HOUSE_VIEWS.favorites]: "houses.favoritesTitle",
};

const Header = props => {
  const {
    activeTab,
    criteriaData,
    housesData,
    isLoading,
    isMap,
    view,
    handleActiveTab,
    onChangeAllCriteriaModalState,
    onSubmitCriteriaFilter
  } = props;
  const resultsNumber = lodashGet(housesData, "total_results", 0);
  const isShowFiltersBar = view === HOUSE_VIEWS.topMatches && !isLoading;

  const renderResultsNumber = () => {
    if (!resultsNumber) {
      return null;
    }
    return (
      <div className="results-header-title__results">
        <FormattedMessage id="houses.resultsMessage" values={{ number: resultsNumber }} />
      </div>
    );
  };

  const renderButtons = () => {
    const isHideSwitcher = view === HOUSE_VIEWS.favorites && !isLoading && housesData && resultsNumber === 0;
    return (
      <div className="results-header-buttons">
        {/*{isNonAuthUser && (*/}
        {/*  <div className="results-header-buttons__left">*/}
        {/*    <AccountButtonView />*/}
        {/*  </div>*/}
        {/*)}*/}
        {!isHideSwitcher && (
          <div className="results-header-buttons__right">
            <SwitcherView
              handleActiveView={handleActiveTab}
              activeView={activeTab}
            />
          </div>
        )}
      </div>
    );
  };

  const renderTitle = () => (
    <div className="results-header-title">
      <div className="results-header-title__text">
        <FormattedMessage id={HEADER_TITLE_MAP[view]} />
      </div>
      {view === HOUSE_VIEWS.topMatches && renderResultsNumber()}
    </div>
  );

  return (
    <div className="results-header-wrapper">
      <div className="results-header">
        {renderTitle()}
        {renderButtons()}
      </div>
      {isShowFiltersBar && (
        <FiltersBarView
          criteriaData={criteriaData}
          isMap={isMap}
          onMoreButtonClick={onChangeAllCriteriaModalState}
          onSubmitCriteriaFilter={onSubmitCriteriaFilter}
        />
      )}
    </div>
  );
};

Header.propTypes = {
  activeTab: PropTypes.string,
  criteriaData: PropTypes.object,
  housesData: PropTypes.object,
  isLoading: PropTypes.bool,
  isMap: PropTypes.bool,
  isNonAuthUser: PropTypes.bool,
  view: PropTypes.string,
  handleActiveTab: PropTypes.func,
  onChangeAllCriteriaModalState: PropTypes.func,
  onSubmitCriteriaFilter: PropTypes.func,
};

export default Header;
