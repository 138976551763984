import React from "react";

const IconCustomDistanceTo = (props) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1284_8219)">
      <path
        d="M26.7621 13.0564H16.7482C16.335 13.0564 16 12.7154 16 12.2948V8.16101C16 7.74039 16.335 7.39941 16.7482 7.39941H26.7621C27.2231 7.39941 27.6651 7.5866 27.99 7.91947L29.4691 9.43481C29.8973 9.87363 29.8973 10.5821 29.4691 11.0209L27.99 12.5363C27.6651 12.8692 27.2231 13.0564 26.7621 13.0564Z"
        fill="url(#paint0_linear_1284_8219)"
      />
      <path
        d="M16 12.2949C16 12.7155 16.335 13.0565 16.7482 13.0565H26.7621C27.2231 13.0565 27.6651 12.8693 27.99 12.5364L29.4691 11.0211C29.6832 10.8017 29.7903 10.5148 29.7903 10.228H16V12.2949Z"
        fill="url(#paint1_linear_1284_8219)"
      />
      <path
        d="M21.5822 21.2091H16.3881C16.1738 21.2091 16 20.9082 16 20.5371V16.8898C16 16.5186 16.1737 16.2178 16.3881 16.2178H21.5822C21.8214 16.2178 22.0506 16.3829 22.2191 16.6766L22.9863 18.0137C23.2085 18.4009 23.2085 19.026 22.9863 19.4132L22.2191 20.7502C22.0506 21.0439 21.8214 21.2091 21.5822 21.2091Z"
        fill="url(#paint2_linear_1284_8219)"
      />
      <path
        d="M16 20.537C16 20.9081 16.1737 21.209 16.3881 21.209H21.5822C21.8214 21.209 22.0506 21.0438 22.2191 20.7501L22.9863 19.4131C23.0973 19.2195 23.1529 18.9664 23.1529 18.7134H16V20.537Z"
        fill="url(#paint3_linear_1284_8219)"
      />
      <path
        d="M18.2812 7.39941H16.7482C16.335 7.39941 16 7.74039 16 8.16101V12.2948C16 12.7154 16.335 13.0564 16.7482 13.0564H23.8389L18.2812 7.39941Z"
        fill="url(#paint4_linear_1284_8219)"
      />
      <path
        d="M16.3882 21.2091H21.5823C21.8214 21.2091 22.0506 21.0439 22.2192 20.7502L22.3793 20.4712L18.2675 16.2859C18.2675 16.2859 18.2642 16.262 18.2581 16.2178H16.3881C16.1738 16.2178 16 16.5186 16 16.8898V20.5371C16.0001 20.9082 16.1738 21.2091 16.3882 21.2091Z"
        fill="url(#paint5_linear_1284_8219)"
      />
      <path
        d="M13.7305 4.18628H18.2653V29.4806H13.7305V4.18628Z"
        fill="url(#paint6_linear_1284_8219)"
      />
      <path
        d="M18.2653 4.18628H13.7305V6.16764L18.2653 10.7835V4.18628Z"
        fill="url(#paint7_linear_1284_8219)"
      />
      <path
        d="M15.9972 5.3937C17.4605 5.3937 18.6467 4.18628 18.6467 2.69685C18.6467 1.20742 17.4605 0 15.9972 0C14.5339 0 13.3477 1.20742 13.3477 2.69685C13.3477 4.18628 14.5339 5.3937 15.9972 5.3937Z"
        fill="url(#paint8_linear_1284_8219)"
      />
      <path
        d="M17.8768 6.25875H14.1208C13.6291 6.25875 13.2305 5.85301 13.2305 5.35256V4.86444C13.2305 4.36399 13.6291 3.95825 14.1208 3.95825H17.8768C18.3685 3.95825 18.7671 4.36399 18.7671 4.86444V5.35256C18.7671 5.85301 18.3685 6.25875 17.8768 6.25875Z"
        fill="url(#paint9_linear_1284_8219)"
      />
      <path
        d="M23.4999 32.5714H8.50068C7.96064 32.5714 7.55819 32.0645 7.67157 31.527C7.94683 30.222 9.08013 29.2898 10.3915 29.2898H21.6091C22.9204 29.2898 24.0538 30.222 24.329 31.527C24.4424 32.0645 24.0399 32.5714 23.4999 32.5714Z"
        fill="url(#paint10_linear_1284_8219)"
      />
      <path
        d="M18.2693 15.8188L15.8412 13.3474C15.7042 13.1704 15.4922 13.1201 15.2536 13.1201H13.7344V18.7132L18.2692 23.329V15.8188H18.2693Z"
        fill="url(#paint11_linear_1284_8219)"
      />
      <path
        d="M5.23911 18.7133H15.253C15.6662 18.7133 16.0012 18.3724 16.0012 17.9517V13.818C16.0012 13.3974 15.6662 13.0564 15.253 13.0564H5.23911C4.77812 13.0564 4.33615 13.2436 4.01125 13.5765L2.53216 15.0918C2.10386 15.5306 2.10386 16.2391 2.53216 16.6779L4.01125 18.1932C4.33615 18.5262 4.77812 18.7133 5.23911 18.7133Z"
        fill="url(#paint12_linear_1284_8219)"
      />
      <path
        d="M16.0012 17.9516C16.0012 18.3723 15.6662 18.7132 15.253 18.7132H5.23914C4.77815 18.7132 4.33618 18.526 4.01128 18.1932L2.53219 16.6778C2.31804 16.4584 2.21094 16.1716 2.21094 15.8848H16.0012V17.9516Z"
        fill="url(#paint13_linear_1284_8219)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1284_8219"
        x1="20.025"
        y1="6.56316"
        x2="23.9503"
        y2="12.6955"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="0.3043" stopColor="#FFCD3E" />
        <stop offset="0.8558" stopColor="#FFAD2B" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1284_8219"
        x1="23.1553"
        y1="9.80996"
        x2="28.9662"
        y2="-1.98838"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F82814" stopOpacity="0" />
        <stop offset="1" stopColor="#C0272D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1284_8219"
        x1="17.6152"
        y1="16.3333"
        x2="20.2678"
        y2="20.4773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CDEC7A" />
        <stop offset="0.2157" stopColor="#B0E995" />
        <stop offset="0.5613" stopColor="#87E4BB" />
        <stop offset="0.8347" stopColor="#6EE1D2" />
        <stop offset="1" stopColor="#65E0DB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1284_8219"
        x1="18.4243"
        y1="21.3151"
        x2="22.0678"
        y2="13.9173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CDEC7A" stopOpacity="0" />
        <stop offset="0.2354" stopColor="#9AD57D" stopOpacity="0.235" />
        <stop offset="0.6035" stopColor="#51B482" stopOpacity="0.604" />
        <stop offset="0.8679" stopColor="#239F85" stopOpacity="0.868" />
        <stop offset="1" stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1284_8219"
        x1="21.8923"
        y1="11.5163"
        x2="13.305"
        y2="9.10585"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F82814" stopOpacity="0" />
        <stop offset="1" stopColor="#C0272D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1284_8219"
        x1="22.3395"
        y1="21.167"
        x2="12.298"
        y2="16.4516"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CDEC7A" stopOpacity="0" />
        <stop offset="0.2354" stopColor="#9AD57D" stopOpacity="0.235" />
        <stop offset="0.6035" stopColor="#51B482" stopOpacity="0.604" />
        <stop offset="0.8679" stopColor="#239F85" stopOpacity="0.868" />
        <stop offset="1" stopColor="#119786" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1284_8219"
        x1="14.4384"
        y1="16.8334"
        x2="17.9256"
        y2="16.8334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1284_8219"
        x1="16.7922"
        y1="8.46591"
        x2="14.8785"
        y2="0.817753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFDCFB" stopOpacity="0" />
        <stop offset="0.0287" stopColor="#E9D6F7" stopOpacity="0.029" />
        <stop offset="0.2203" stopColor="#C5B5DE" stopOpacity="0.22" />
        <stop offset="0.4138" stopColor="#AA9BCB" stopOpacity="0.414" />
        <stop offset="0.6078" stopColor="#9688BD" stopOpacity="0.608" />
        <stop offset="0.8028" stopColor="#8A7DB5" stopOpacity="0.803" />
        <stop offset="1" stopColor="#8679B2" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1284_8219"
        x1="15.1683"
        y1="1.36794"
        x2="16.884"
        y2="4.02281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1284_8219"
        x1="15.6243"
        y1="4.08879"
        x2="16.4872"
        y2="6.3566"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F8F6FB" />
        <stop offset="1" stopColor="#EFDCFB" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1284_8219"
        x1="16.0003"
        y1="29.4118"
        x2="16.0003"
        y2="33.0738"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A7889" />
        <stop offset="0.3344" stopColor="#867385" />
        <stop offset="0.7039" stopColor="#7B667B" />
        <stop offset="1" stopColor="#6E566E" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1284_8219"
        x1="18.5596"
        y1="19.7411"
        x2="10.3053"
        y2="12.2866"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFDCFB" stopOpacity="0" />
        <stop offset="0.0287" stopColor="#E9D6F7" stopOpacity="0.029" />
        <stop offset="0.2203" stopColor="#C5B5DE" stopOpacity="0.22" />
        <stop offset="0.4138" stopColor="#AA9BCB" stopOpacity="0.414" />
        <stop offset="0.6078" stopColor="#9688BD" stopOpacity="0.608" />
        <stop offset="0.8028" stopColor="#8A7DB5" stopOpacity="0.803" />
        <stop offset="1" stopColor="#8679B2" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1284_8219"
        x1="7.63221"
        y1="11.2324"
        x2="11.1891"
        y2="19.7461"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA936" />
        <stop offset="0.4112" stopColor="#FF8548" />
        <stop offset="0.7781" stopColor="#FF6C54" />
        <stop offset="1" stopColor="#FF6359" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1284_8219"
        x1="9.2776"
        y1="16.4976"
        x2="5.6505"
        y2="5.87074"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F82814" stopOpacity="0" />
        <stop offset="1" stopColor="#C0272D" />
      </linearGradient>
      <clipPath id="clip0_1284_8219">
        <rect width="32" height="32.5714" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCustomDistanceTo;
