import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import { notification } from 'antd';
import { FormattedMessage, injectIntl } from "react-intl";
import { IconArrowRight } from "components/Svgs";
import {
  map as lodashMap,
  isEmpty as lodashIsEmpty,
} from "lodash";
import { SimpleButton } from "components/Controls/Buttons";
import { TOP_MATCHES } from "constants/paths";
import Filters from "./Filters";
import Criteria from "./Criteria";
import BubbleCluster from "./BubbleCluster";
import emptyBubbleCluster from "images/bubbles.png";
import { USER_KEY } from "constants/localStorage";
import "./styles.scss";
import { localStorageStrToObject } from "utils/helpers/common";

const Dashboard = observer(({ criteriaStore, intl }) => {
  const [activeFilter, setActiveFilter] = useState();
  const history = useHistory();
  const user = localStorageStrToObject(USER_KEY);

  useEffect(() => {
    criteriaStore.getCriteriaRequest();
  }, []);

  const handleActiveFilter = (value) => {
    setActiveFilter(activeFilter === value ? null : value);
  };

  const openNotification = () => {
    notification.open({
      description: intl.formatMessage({id: "dashboard.header.seeMatchesNotificationMessage"}),
    });
  }

  const onSeeMatches = () => checkBasicCriteria() ? history.push(TOP_MATCHES): openNotification();

  const checkBasicCriteria = () => {
    const criteriaData = criteriaStore.retrieveCriteriaData();
    return !lodashIsEmpty(criteriaData.locations);
  }

  const renderHeader = () => (
    <div className="main-header">
      <h1 className="main-header__title">
        <FormattedMessage
          id={user.first_name ? "dashboard.header.homeHuntingDashboardTitle" : "dashboard.header.homeHuntingDashboardNoNameTitle"}
          values={{ name: user.first_name }}
        />
      </h1>
      <div className="main-header__left">
        <SimpleButton
          onClick={onSeeMatches}
          customClass={classNames("button-see-matches", {"disabled": !checkBasicCriteria()})}
        >
          <FormattedMessage id="dashboard.header.seeMatchesMessage" />
          <IconArrowRight />
        </SimpleButton>
      </div>
    </div>
  );

  const getDataForBubbleCluster = () => {
    const criteriaData = criteriaStore.retrieveCriteriaData();
    const criteriaDataWithPriority = _.omit(criteriaData, ['locations', 'prop_type']);
    const result = [];
    lodashMap(criteriaDataWithPriority, (item, key) => (item.priority && result.push({ value: item.priority, key})));
    return result;
  };

  const renderBubbleCluster = () => {
    const bubblesData = getDataForBubbleCluster();

    return (
      <div className="bubble-cluster-container">
        <div className="bubble-cluster-container__title">
          <div className="bubble-cluster-container__title-message fs-16">
            <FormattedMessage id="dashboard.criteriaPriorityMap" />
          </div>
        </div>
        <div className="bubble-cluster">
          {lodashIsEmpty(bubblesData)
            ?
            <div className="bubble-cluster__empty">
              <div className="bubble-cluster__empty-image">
                <img src={emptyBubbleCluster} alt="Mountain"/>
              </div>
              <div className="bubble-cluster__empty-text">
                <FormattedMessage id="dashboard.emptyBubblesMessage" />
              </div>
            </div>
            :
            <BubbleCluster data={getDataForBubbleCluster()} />
          }
        </div>
      </div>
    )
  }

  return (
    <div className="main-wrapper">
      {/*<div className="criteria-error-message">*/}
      {/*  <IconExclamationPoint className="criteria-error-message__icon" />*/}
      {/*  Please choose all basic criteria!*/}
      {/*  <Button>*/}
      {/*    <IconClose />*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div className="main">
        {renderHeader()}
        <div className="main-content">
          <Criteria
            activeFilter={activeFilter}
            handleActiveFilter={handleActiveFilter}
            criteriaData={criteriaStore.retrieveCriteriaData()}
          />
          {activeFilter && (
            <div className="filters-wrapper">
              <Filters
                activeFilter={activeFilter}
                handleActiveFilter={handleActiveFilter}
              />
            </div>
          )}
        </div>
        {renderBubbleCluster()}
      </div>
    </div>
  );
});

export default inject("criteriaStore")(injectIntl(Dashboard));
