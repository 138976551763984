import React from "react";

const IconPriority = (props) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7618 4.80805L14.7621 11.664C14.7621 11.9104 14.5623 11.8827 14.316 11.8826L10.7652 11.8823C10.5188 11.8822 10.319 11.91 10.319 11.6636L10.3187 4.80761C10.3186 4.4665 10.0421 4.18995 9.70096 4.18991C9.15072 4.18985 8.87508 3.52457 9.26413 3.13551L12.1031 0.296581C12.3442 0.0553892 12.7353 0.0554522 12.9765 0.296676L15.816 3.13614C16.2051 3.52526 15.9296 4.19051 15.3793 4.19045C15.0383 4.19045 14.7617 4.46697 14.7618 4.80805V4.80805Z"
      fill="url(#paint0_linear_826_1437)"
    />
    <path
      d="M10.8231 11.38L10.8228 4.80775C10.8228 4.19018 10.3203 3.68772 9.70272 3.68765C9.65159 3.68765 9.61579 3.66369 9.59621 3.61647C9.57667 3.56925 9.58501 3.527 9.62119 3.49085L12.4601 0.651921C12.488 0.623999 12.5199 0.618164 12.5416 0.618164C12.5634 0.618164 12.5952 0.624031 12.6231 0.651921L15.4626 3.49139C15.4988 3.52753 15.5072 3.56979 15.4876 3.617C15.4681 3.66422 15.4323 3.68816 15.3811 3.68816C14.7636 3.68809 14.2612 4.19046 14.2613 4.80803L14.2615 11.3803L10.8231 11.38Z"
      fill="url(#paint1_linear_826_1437)"
    />
    <path
      d="M14.3153 11.8826C14.5617 11.8826 14.7614 11.9104 14.7614 11.664L14.7611 4.80799C14.7611 4.46688 15.0375 4.19039 15.3787 4.19042C15.9289 4.19048 16.2044 3.52523 15.8153 3.13612L12.9758 0.296648C12.8552 0.176052 12.6971 0.115723 12.5391 0.115723L12.5398 11.8824L14.3153 11.8826Z"
      fill="url(#paint2_linear_826_1437)"
    />
    <path
      d="M4.46089 11.664C4.46089 11.9112 4.26027 11.8834 4.01315 11.8834H2.23046V11.883H0.448094C0.200627 11.883 0 11.9108 0 11.6637V8.28126C0 8.16528 0.0940235 8.07129 0.209976 8.07129H4.25089C4.36687 8.07129 4.46086 8.16531 4.46086 8.28126V11.664H4.46089Z"
      fill="url(#paint3_linear_826_1437)"
    />
    <path
      d="M3.95791 8.28895L3.95826 11.3781L2.23211 11.3778H0.505991V8.2886C0.505991 8.21403 0.498775 8.1409 0.484375 8.07031H3.97918C3.96513 8.1409 3.95791 8.21403 3.95791 8.28895V8.28895Z"
      fill="url(#paint4_linear_826_1437)"
    />
    <path
      d="M4.4609 8.28898L4.46128 11.6631C4.46128 11.9102 4.26065 11.8824 4.01353 11.8824H2.23085V11.3778L2.23047 8.07031H4.25111C4.36957 8.07031 4.46137 8.1682 4.46093 8.28666C4.46093 8.28744 4.4609 8.28819 4.4609 8.28898V8.28898Z"
      fill="url(#paint5_linear_826_1437)"
    />
    <path
      d="M9.38014 5.12256C9.49612 5.12256 9.59015 5.21658 9.59011 5.33257L9.58977 11.6624C9.58977 11.9099 9.38914 11.8818 9.14168 11.8822H5.577C5.32953 11.8825 5.12891 11.9103 5.12891 11.6628L5.12925 5.3325C5.12925 5.21655 5.22327 5.12256 5.33923 5.12256H9.38014Z"
      fill="url(#paint6_linear_826_1437)"
    />
    <path
      d="M9.08512 5.12256V11.3775H7.35897L5.63281 11.3779L5.63316 5.12256H9.08512Z"
      fill="url(#paint7_linear_826_1437)"
    />
    <path
      d="M9.38018 5.12256C9.49616 5.12256 9.59018 5.21658 9.59015 5.33257L9.58981 11.6625C9.58981 11.9099 9.38918 11.8818 9.14171 11.8822H7.35938V11.3775L7.35972 5.12256H9.38018Z"
      fill="url(#paint8_linear_826_1437)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_826_1437"
        x1="10.2891"
        y1="5.05624"
        x2="16.7183"
        y2="9.69547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#91F27F" />
        <stop offset="1" stopColor="#4AB172" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_826_1437"
        x1="13.6495"
        y1="6.91812"
        x2="6.50492"
        y2="3.55801"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#91F27F" stopOpacity="0" />
        <stop offset="1" stopColor="#FFED82" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_826_1437"
        x1="14.3703"
        y1="4.85957"
        x2="6.78659"
        y2="16.1636"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4AB172" stopOpacity="0" />
        <stop offset="1" stopColor="#009145" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_826_1437"
        x1="0.877898"
        y1="9.00428"
        x2="4.72407"
        y2="11.7796"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" />
        <stop offset="1" stopColor="#F82814" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_826_1437"
        x1="2.94913"
        y1="10.0563"
        x2="-1.34941"
        y2="8.03474"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7044" stopOpacity="0" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_826_1437"
        x1="3.75941"
        y1="9.2934"
        x2="0.437096"
        y2="14.2455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F82814" stopOpacity="0" />
        <stop offset="1" stopColor="#C0272D" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_826_1437"
        x1="5.59633"
        y1="7.23327"
        x2="10.6082"
        y2="10.8498"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" />
        <stop offset="1" stopColor="#FFA325" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_826_1437"
        x1="8.27925"
        y1="8.68303"
        x2="2.81493"
        y2="6.11317"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" stopOpacity="0" />
        <stop offset="1" stopColor="#FFED82" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_826_1437"
        x1="9.15269"
        y1="7.42518"
        x2="3.95023"
        y2="15.1798"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD945" stopOpacity="0" />
        <stop offset="1" stopColor="#B43759" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconPriority;
