import React from "react";
import { FormattedMessage } from "react-intl";

const PRIORITY_COLUMNS = (renderFirstColumn, renderRadioButton) => ([
  {
    dataIndex: "kind",
    className: "criteria-column",
    render: (_, record) => renderFirstColumn(record),
  },
  {
    title: <FormattedMessage id="onboarding.slightlyImportant" />,
    key: "slightly_important",
    className: "slightly-important",
    render: (_, record) => renderRadioButton(record, 1)
  },
  {
    title: <FormattedMessage id="onboarding.somewhatImportant" />,
    key: "somewhat_important",
    className: "somewhat-important",
    render: (_, record) => renderRadioButton(record, 2)
  },
  {
    title: <FormattedMessage id="onboarding.important" />,
    key: "important",
    className: "important",
    render: (_, record) => renderRadioButton(record, 3)
  },
  {
    title: <FormattedMessage id="onboarding.veryImportant" />,
    key: "very_important",
    className: "very-important",
    render: (_, record) => renderRadioButton(record, 4)
  },
  {
    title: <FormattedMessage id="onboarding.mostImportant" />,
    key: "most_important",
    className: "most-important",
    render: (_, record) => renderRadioButton(record, 5)
  }
]);

export { PRIORITY_COLUMNS };
