import React from "react";

const IconParking = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_716_2591)">
      <path
        d="M26.7725 32H5.22329C4.24566 32 3.45312 31.2075 3.45312 30.2298V1.77016C3.45312 0.792533 4.24566 0 5.22329 0H26.7725C27.7501 0 28.5426 0.792533 28.5426 1.77016V30.2298C28.5426 31.2075 27.7501 32 26.7725 32Z"
        fill="url(#paint0_linear_716_2591)"
      />
      <path
        d="M6.0829 30.8684H25.914C26.8185 30.8684 27.5516 30.1352 27.5516 29.2308V2.76893C27.5516 1.86452 26.8185 1.13135 25.914 1.13135H6.0829C5.17849 1.13135 4.44531 1.86452 4.44531 2.76893V29.2308C4.44531 30.1352 5.17849 30.8684 6.0829 30.8684Z"
        fill="url(#paint1_linear_716_2591)"
      />
      <path
        d="M24.646 30.1575H7.35259C6.18465 30.1575 5.23438 29.2072 5.23438 28.0393V3.9605C5.23438 2.79256 6.18465 1.84229 7.35259 1.84229H24.646C25.8139 1.84229 26.7642 2.79256 26.7642 3.9605V28.0393C26.7642 29.2072 25.814 30.1575 24.646 30.1575ZM7.35259 3.15993C6.91118 3.15993 6.55202 3.51909 6.55202 3.9605V28.0393C6.55202 28.4807 6.91118 28.8399 7.35259 28.8399H24.646C25.0874 28.8399 25.4465 28.4807 25.4465 28.0393V3.9605C25.4465 3.51909 25.0874 3.15993 24.646 3.15993H7.35259Z"
        fill="url(#paint2_linear_716_2591)"
      />
      <path
        d="M28.5438 17.6574L19.8066 8.91928C18.9066 8.11828 17.702 7.71777 16.1912 7.71777H11.7731C11.5009 7.71777 11.2783 7.79357 11.1051 7.9451C10.9303 8.09663 10.8438 8.27759 10.8438 8.48941V23.4895C10.8438 23.7323 10.9643 23.9241 11.2072 24.0679L19.1395 32.0003H26.7736C27.7512 32.0003 28.5438 31.2077 28.5438 30.2301V17.6574Z"
        fill="url(#paint3_linear_716_2591)"
      />
      <path
        d="M10.8438 23.4896V8.48822C10.8438 8.27689 10.9305 8.09556 11.1043 7.94441C11.2778 7.79351 11.5009 7.71777 11.7729 7.71777H16.1916C17.7021 7.71777 18.907 8.11821 19.8061 8.91871C20.7047 9.71966 21.1543 10.9809 21.1543 12.7031V12.8843C21.1543 14.6066 20.6898 15.872 19.7608 16.68C18.8316 17.4883 17.5966 17.8924 16.0557 17.8924H13.2457V23.4895C13.2457 23.7314 13.1285 23.924 12.8945 24.0674C12.6602 24.2112 12.3769 24.2827 12.0448 24.2827C11.7275 24.2827 11.4477 24.2112 11.2063 24.0674C10.9645 23.9241 10.8438 23.7315 10.8438 23.4896ZM13.2458 15.9889H16.0558C16.9168 15.9889 17.5814 15.7436 18.0499 15.2525C18.518 14.7617 18.7524 14.0252 18.7524 13.043V12.7711C18.7524 11.7893 18.518 11.0566 18.0499 10.573C17.5814 10.0897 16.9168 9.84784 16.0558 9.84784H13.2458V15.9889Z"
        fill="url(#paint4_linear_716_2591)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_716_2591"
        x1="7.5416"
        y1="5.62736"
        x2="33.705"
        y2="37.7199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FA9F5" />
        <stop offset="1" stopColor="#666AD6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_2591"
        x1="23.8295"
        y1="6.39409"
        x2="-0.399554"
        y2="36.114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FA9F5" />
        <stop offset="1" stopColor="#666AD6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_2591"
        x1="5.23444"
        y1="15.9999"
        x2="26.7642"
        y2="15.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4F7FF" />
        <stop offset="1" stopColor="#A2E3E9" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_2591"
        x1="26.5472"
        y1="25.4098"
        x2="-0.736362"
        y2="6.25117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A34A9E" stopOpacity="0" />
        <stop offset="1" stopColor="#343168" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_2591"
        x1="15.9991"
        y1="12.294"
        x2="15.9991"
        y2="25.8028"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4F7FF" />
        <stop offset="1" stopColor="#A2E3E9" />
      </linearGradient>
      <clipPath id="clip0_716_2591">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconParking;
