import React from "react";
import { FormattedMessage } from "react-intl";
import _, { cloneDeep as lodashCloneDeep, debounce, find as lodashFind } from "lodash";
import { GEO_SEARCH_API_URL } from "constants/apiUrls";
import { CRITERIA_MAP } from "constants/criteria";
import { get } from "../requests";

const MAX_LOCATIONS_NUMBER_FOR_SEARCH = 10;

const MIN_SYMBOLS_FOR_GEO_SEARCH = 2;

const DEBOUNCE_TIME = 500;

const REQUEST_TIMEOUT = 1000 * 10;

const getLocationFromQuery = (gid, form, setLocations, onValuesChange) => {
  let newLocations = [];
  makePlaceQuery(gid).then(item => {
    newLocations.push({...item, kind: CRITERIA_MAP.location});
    if (!_.isNil(form)) {
      updateLocationsValue(form, newLocations, setLocations, onValuesChange);
    }
  });
};

const getNoContentMessage = (isLoading, symbolsLength) => {
  if (isLoading) {
    return "loadingMessage";
  }
  if (symbolsLength <= 2) {
    return "searchQueryTooShort";
  }
  return "noContent";
};

const getNoContentMultipleMessage = isLoading => {
  if (isLoading) {
    return "loadingMessage";
  }
  return "noContent";
};

const getNoContentInfo = (isLoading, symbolsLength, isMultipleSelect = false) => {
  const method = isMultipleSelect ? getNoContentMultipleMessage : getNoContentMessage;
  const message = method(isLoading, symbolsLength);
  return <FormattedMessage id={`autocomplete.${message}`} />;
};

const processGeoResult = results =>
  results.map(item => ({ value: item.address, label: item.address, ...item }));

// Get autocomplete results
const makeSearchQuery = q =>
  get(`${GEO_SEARCH_API_URL}autocomplete/?q=${q}`, {timeout: REQUEST_TIMEOUT})
  .then(results => processGeoResult(results.data))
  .catch(() => []);

// Get the full information about the place
// with coordinates
const makePlaceQuery = gid =>
  get(`${GEO_SEARCH_API_URL}places/?ids=${gid}`, {timeout: REQUEST_TIMEOUT})
  .then(results => results.data[0])
  .catch(() => {});

const handleNewLocationSelect = (form, locations, newLocation, setLocations, onValuesChange) => {
  if (!lodashFind(locations, (item) => item.address === newLocation.address)) {
    makePlaceQuery(newLocation.gid).then(item => {
      const newLocations = lodashCloneDeep(locations) || [];
      newLocations.push({ ...item, kind: CRITERIA_MAP.location });
      updateLocationsValue(form, newLocations, setLocations, onValuesChange);
    });
  }
};

// Handler outside function to support debounce effect
const handleQueryTextChange = debounce(
  (query, setOptions, setIsLoading, locations = null) => {
    makeSearchQuery(query).then((results) => {
      if (!_.isNull(locations) && !_.isEmpty(locations)) {
        const resultsAddresses = results.map(result => result.address);
        // get selected locations not from results options and make options for them
        let selectedLocations = locations.filter(location => !resultsAddresses.includes(location.address));
        selectedLocations = selectedLocations.map(location => (
          { label: location.address, value: location.address, disabled: false, ...location }
        ));
        // get selected by user locations which are in results options list
        const selectedAddressesFromResults = locations
          .filter(location => resultsAddresses.includes(location.address))
          .map(location => location.address);
        // update results value
        results = results.map(result => {
          const disabled = selectedAddressesFromResults.includes(result.address) ?
            false :
            locations.length >= MAX_LOCATIONS_NUMBER_FOR_SEARCH;
          return { ...result, disabled };
        });
        results = _.union(selectedLocations, results);
      }
      setOptions(results);
      setIsLoading(false);
    });
  }
, DEBOUNCE_TIME, false);

const updateLocationsValue = (form, locations, setLocations, onValuesChange) => {
  form.setFieldsValue({ locations });
  if (_.isFunction(setLocations)) {
    setLocations(locations);
  }
  if (_.isFunction(onValuesChange)) {
    onValuesChange({ locations });
  }
};

export {
  MAX_LOCATIONS_NUMBER_FOR_SEARCH,
  MIN_SYMBOLS_FOR_GEO_SEARCH,
  getLocationFromQuery,
  getNoContentInfo,
  handleNewLocationSelect,
  handleQueryTextChange,
  updateLocationsValue,
};
