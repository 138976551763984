import React from 'react';
import HelmetIntl from "components/Meta/HelmetIntl";
import LogIn from 'containers/LogIn';

const LogInPage = () => (
  <div>
    <HelmetIntl id="helmet.loginTitle" />
    <LogIn />
  </div>
);

export default LogInPage;
