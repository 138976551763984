import React from "react";

const IconArchitecturalStyle = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2_171)">
      <path
        d="M5.58779 10.2449H24.4113V25.7351H5.58779V10.2449Z"
        fill="url(#paint0_linear_2_171)"
      />
      <path
        d="M5.58779 10.2449H11.2459V25.7351H5.58779V10.2449Z"
        fill="url(#paint1_linear_2_171)"
      />
      <path
        d="M12.1714 10.2449H17.8296V25.7351H12.1714V10.2449Z"
        fill="url(#paint2_linear_2_171)"
      />
      <path
        d="M18.7544 10.2449H24.4125V25.7351H18.7544V10.2449Z"
        fill="url(#paint3_linear_2_171)"
      />
      <path
        d="M5.58779 10.2449H24.4113V15.6219H5.58779V10.2449Z"
        fill="url(#paint4_linear_2_171)"
      />
      <path
        d="M14.0646 24.7059H9.582L10.3233 11.353H13.3233L14.0646 24.7059Z"
        fill="url(#paint5_linear_2_171)"
      />
      <path
        d="M11.4213 11.353L10.68 24.7059H14.0646L13.3233 11.353H11.4213Z"
        fill="url(#paint6_linear_2_171)"
      />
      <path
        d="M10.3233 11.353L9.582 24.7059H12.9665L12.2252 11.353H10.3233Z"
        fill="url(#paint7_linear_2_171)"
      />
      <path
        d="M13.5742 15.8683L13.3236 11.353H10.3235L10.257 12.5511L13.5742 15.8683Z"
        fill="url(#paint8_linear_2_171)"
      />
      <path
        d="M9.582 24.7059H14.0646L13.7902 19.7647H9.85636L9.582 24.7059Z"
        fill="url(#paint9_linear_2_171)"
      />
      <path
        d="M13.9176 23.7648H9.9065C9.30856 23.7648 8.82379 24.2496 8.82379 24.8475L8.70615 26.5295H15.2944L15.0003 24.8475C15.0003 24.2496 14.5155 23.7648 13.9176 23.7648Z"
        fill="url(#paint10_linear_2_171)"
      />
      <path
        d="M13.3817 12.572H10.4421C10.0038 12.572 9.64854 12.2167 9.64854 11.7785V10.5458H14.1752V11.7785C14.1752 12.2167 13.8199 12.572 13.3817 12.572Z"
        fill="url(#paint11_linear_2_171)"
      />
      <path
        d="M13.3817 12.572H10.4421C10.0038 12.572 9.64854 12.2167 9.64854 11.7785V10.5458H14.1752V11.7785C14.1752 12.2167 13.8199 12.572 13.3817 12.572Z"
        fill="url(#paint12_linear_2_171)"
      />
      <path
        d="M7.88838 24.7059H3.40579L4.14709 11.353H7.14709L7.88838 24.7059Z"
        fill="url(#paint13_linear_2_171)"
      />
      <path
        d="M5.2451 11.353L4.50381 24.7059H7.88834L7.14705 11.353H5.2451Z"
        fill="url(#paint14_linear_2_171)"
      />
      <path
        d="M4.14709 11.353L3.40579 24.7059H6.79033L6.04903 11.353H4.14709Z"
        fill="url(#paint15_linear_2_171)"
      />
      <path
        d="M7.39805 15.8683L7.1474 11.353H4.1474L4.08087 12.5511L7.39805 15.8683Z"
        fill="url(#paint16_linear_2_171)"
      />
      <path
        d="M3.40579 24.7059H7.88838L7.61403 19.7647H3.68015L3.40579 24.7059Z"
        fill="url(#paint17_linear_2_171)"
      />
      <path
        d="M8.82411 24.8475V26.5295H2.64764V24.8475C2.64764 24.2496 3.1324 23.7648 3.73034 23.7648H7.7414C8.33934 23.7648 8.82411 24.2496 8.82411 24.8475Z"
        fill="url(#paint18_linear_2_171)"
      />
      <path
        d="M7.20562 12.572H4.26598C3.82775 12.572 3.47245 12.2167 3.47245 11.7785V10.5458H7.99909V11.7785C7.99915 12.2167 7.64386 12.572 7.20562 12.572Z"
        fill="url(#paint19_linear_2_171)"
      />
      <path
        d="M7.20562 12.572H4.26598C3.82775 12.572 3.47245 12.2167 3.47245 11.7785V10.5458H7.99909V11.7785C7.99915 12.2167 7.64386 12.572 7.20562 12.572Z"
        fill="url(#paint20_linear_2_171)"
      />
      <path
        d="M26.4186 24.7059H21.936L22.6773 11.353H25.6773L26.4186 24.7059Z"
        fill="url(#paint21_linear_2_171)"
      />
      <path
        d="M23.7754 11.353L23.0341 24.7059H26.4186L25.6773 11.353H23.7754Z"
        fill="url(#paint22_linear_2_171)"
      />
      <path
        d="M22.6773 11.353L21.936 24.7059H25.3205L24.5792 11.353H22.6773Z"
        fill="url(#paint23_linear_2_171)"
      />
      <path
        d="M25.9283 15.8683L25.6776 11.353H22.6777L22.6111 12.5511L25.9283 15.8683Z"
        fill="url(#paint24_linear_2_171)"
      />
      <path
        d="M21.936 24.7059H26.4186L26.1443 19.7647H22.2104L21.936 24.7059Z"
        fill="url(#paint25_linear_2_171)"
      />
      <path
        d="M26.2716 23.7648H22.2605C21.6626 23.7648 21.1778 24.2496 21.1778 24.8475L20.8837 26.5295H27.3543V24.8475C27.3543 24.2496 26.8695 23.7648 26.2716 23.7648Z"
        fill="url(#paint26_linear_2_171)"
      />
      <path
        d="M25.7359 12.572H22.7962C22.358 12.572 22.0027 12.2167 22.0027 11.7785V10.5458H26.5293V11.7785C26.5294 12.2167 26.1741 12.572 25.7359 12.572Z"
        fill="url(#paint27_linear_2_171)"
      />
      <path
        d="M25.7359 12.572H22.7962C22.358 12.572 22.0027 12.2167 22.0027 11.7785V10.5458H26.5293V11.7785C26.5294 12.2167 26.1741 12.572 25.7359 12.572Z"
        fill="url(#paint28_linear_2_171)"
      />
      <path
        d="M20.2408 24.7059H15.7582L16.4995 11.353H19.4995L20.2408 24.7059Z"
        fill="url(#paint29_linear_2_171)"
      />
      <path
        d="M17.5975 11.353L16.8562 24.7059H20.2407L19.4994 11.353H17.5975Z"
        fill="url(#paint30_linear_2_171)"
      />
      <path
        d="M16.4994 11.353L15.7582 24.7059H19.1427L18.4014 11.353H16.4994Z"
        fill="url(#paint31_linear_2_171)"
      />
      <path
        d="M19.7504 15.8683L19.4998 11.353H16.4998L16.4332 12.5511L19.7504 15.8683Z"
        fill="url(#paint32_linear_2_171)"
      />
      <path
        d="M15.7582 24.7059H20.2408L19.9664 19.7647H16.0325L15.7582 24.7059Z"
        fill="url(#paint33_linear_2_171)"
      />
      <path
        d="M20.0938 23.7648H16.0827C15.4848 23.7648 15 24.2496 15 24.8475V26.5295H21.1765V24.8475C21.1765 24.2496 20.6917 23.7648 20.0938 23.7648Z"
        fill="url(#paint34_linear_2_171)"
      />
      <path
        d="M19.558 12.572H16.6184C16.1801 12.572 15.8248 12.2167 15.8248 11.7785V10.5458H20.3515V11.7785C20.3515 12.2167 19.9962 12.572 19.558 12.572Z"
        fill="url(#paint35_linear_2_171)"
      />
      <path
        d="M19.558 12.572H16.6184C16.1801 12.572 15.8248 12.2167 15.8248 11.7785V10.5458H20.3515V11.7785C20.3515 12.2167 19.9962 12.572 19.558 12.572Z"
        fill="url(#paint36_linear_2_171)"
      />
      <path
        d="M28.3244 27.7743H1.67523C1.49876 27.7743 1.3557 27.6313 1.3557 27.4548V26.7253C1.3557 26.1784 1.79911 25.735 2.346 25.735H27.6536C28.2005 25.735 28.6439 26.1784 28.6439 26.7253V27.4548C28.6439 27.6313 28.5009 27.7743 28.3244 27.7743Z"
        fill="url(#paint37_linear_2_171)"
      />
      <path
        d="M29.5562 29.9999H0.443749C0.198632 29.9999 -1.52588e-05 29.8012 -1.52588e-05 29.5561V28.5431C-1.52588e-05 27.7835 0.615749 27.1677 1.37534 27.1677H28.6246C29.3842 27.1677 30 27.7835 30 28.5431V29.5561C30 29.8012 29.8013 29.9999 29.5562 29.9999Z"
        fill="url(#paint38_linear_2_171)"
      />
      <path
        d="M12.5507 0.770236L0.39725 9.508C-0.38275 10.0688 0.0644856 11.1874 1.06872 11.1874H28.9323C29.9365 11.1874 30.3838 10.0688 29.6037 9.508L17.4503 0.770236C16.0223 -0.256411 13.9787 -0.256411 12.5507 0.770236V0.770236Z"
        fill="url(#paint39_linear_2_171)"
      />
      <path
        d="M3.76868 9.71669C3.41133 9.71669 3.2638 9.25863 3.55391 9.05005L13.4099 1.9641C13.8524 1.64593 14.4176 1.47069 15.0012 1.47069C15.5849 1.47069 16.15 1.64593 16.5926 1.9641L26.4485 9.05005C26.7386 9.25863 26.5911 9.71669 26.2337 9.71669H3.76868Z"
        fill="url(#paint40_linear_2_171)"
      />
      <path
        d="M0.397237 9.50768C-0.382763 10.0685 0.0644723 11.1871 1.06871 11.1871H28.9322C29.9365 11.1871 30.3838 10.0684 29.6037 9.50768L24.4971 5.83627H5.50383L0.397237 9.50768Z"
        fill="url(#paint41_linear_2_171)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2_171"
        x1="7.54815"
        y1="10.5386"
        x2="18.8573"
        y2="21.8477"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A8C98" />
        <stop offset="1" stopColor="#465A61" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_171"
        x1="9.32209"
        y1="17.9325"
        x2="7.46915"
        y2="18.0501"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#465A61" stopOpacity="0" />
        <stop offset="0.4733" stopColor="#454E51" stopOpacity="0.473" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_171"
        x1="15.3803"
        y1="17.9659"
        x2="13.5274"
        y2="18.0835"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#465A61" stopOpacity="0" />
        <stop offset="0.4733" stopColor="#454E51" stopOpacity="0.473" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2_171"
        x1="21.6721"
        y1="17.9844"
        x2="19.8192"
        y2="18.102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#465A61" stopOpacity="0" />
        <stop offset="0.4733" stopColor="#454E51" stopOpacity="0.473" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2_171"
        x1="14.9996"
        y1="14.0784"
        x2="14.9996"
        y2="11.1741"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#465A61" stopOpacity="0" />
        <stop offset="0.4733" stopColor="#454E51" stopOpacity="0.473" />
        <stop offset="1" stopColor="#444444" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2_171"
        x1="9.76447"
        y1="18.0295"
        x2="13.8838"
        y2="18.0295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2_171"
        x1="12.4287"
        y1="18.1267"
        x2="3.56595"
        y2="20.8719"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2_171"
        x1="11.2854"
        y1="18.1023"
        x2="15.5991"
        y2="18.9259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2_171"
        x1="11.9156"
        y1="17.5687"
        x2="11.9156"
        y2="9.50815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2_171"
        x1="11.8233"
        y1="21.451"
        x2="11.8233"
        y2="24.2062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2_171"
        x1="12.0003"
        y1="23.3236"
        x2="12.0003"
        y2="26.2156"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2_171"
        x1="11.9119"
        y1="10.0589"
        x2="11.9119"
        y2="14.0005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2_171"
        x1="11.9119"
        y1="12.3236"
        x2="11.9119"
        y2="11.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_2_171"
        x1="3.58826"
        y1="18.0295"
        x2="7.70762"
        y2="18.0295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_2_171"
        x1="6.25252"
        y1="18.1267"
        x2="-2.61032"
        y2="20.8719"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_2_171"
        x1="5.10921"
        y1="18.1023"
        x2="9.42292"
        y2="18.9259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_2_171"
        x1="5.73946"
        y1="17.5687"
        x2="5.73946"
        y2="9.50815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_2_171"
        x1="5.64709"
        y1="21.451"
        x2="5.64709"
        y2="24.2062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_2_171"
        x1="5.73587"
        y1="23.0001"
        x2="5.73587"
        y2="27.2419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_2_171"
        x1="5.7358"
        y1="10.0589"
        x2="5.7358"
        y2="14.0005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_2_171"
        x1="5.7358"
        y1="12.3236"
        x2="5.7358"
        y2="11.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_2_171"
        x1="22.1185"
        y1="18.0295"
        x2="26.2379"
        y2="18.0295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_2_171"
        x1="24.7828"
        y1="18.1267"
        x2="15.92"
        y2="20.8718"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_2_171"
        x1="23.6394"
        y1="18.1023"
        x2="27.9531"
        y2="18.9259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_2_171"
        x1="24.2697"
        y1="17.5687"
        x2="24.2697"
        y2="9.50815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_2_171"
        x1="24.1773"
        y1="21.451"
        x2="24.1773"
        y2="24.2062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_2_171"
        x1="24.119"
        y1="23.3236"
        x2="24.119"
        y2="26.2156"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_2_171"
        x1="24.266"
        y1="10.0589"
        x2="24.266"
        y2="14.0005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_2_171"
        x1="24.266"
        y1="12.3236"
        x2="24.266"
        y2="11.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_2_171"
        x1="15.9406"
        y1="18.0295"
        x2="20.06"
        y2="18.0295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_2_171"
        x1="18.6049"
        y1="18.1267"
        x2="9.74212"
        y2="20.8718"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_2_171"
        x1="17.4616"
        y1="18.1023"
        x2="21.7753"
        y2="18.9259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_2_171"
        x1="18.0918"
        y1="17.5687"
        x2="18.0918"
        y2="9.50815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_2_171"
        x1="17.9995"
        y1="21.451"
        x2="17.9995"
        y2="24.2062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_2_171"
        x1="18.0882"
        y1="23.0001"
        x2="18.0882"
        y2="27.2419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_2_171"
        x1="18.0882"
        y1="10.0589"
        x2="18.0882"
        y2="14.0005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_2_171"
        x1="18.0882"
        y1="12.3236"
        x2="18.0882"
        y2="11.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_2_171"
        x1="14.9998"
        y1="25.0588"
        x2="14.9998"
        y2="29.9415"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_2_171"
        x1="15"
        y1="26.2352"
        x2="15"
        y2="31.4758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_2_171"
        x1="9.17313"
        y1="0.123295"
        x2="18.5256"
        y2="16.3578"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_2_171"
        x1="20.5466"
        y1="22.3549"
        x2="9.4878"
        y2="-3.58637"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDFCF" />
        <stop offset="1" stopColor="#9E9797" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_2_171"
        x1="15.0005"
        y1="8.17639"
        x2="15.0005"
        y2="12.1872"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9E9797" stopOpacity="0" />
        <stop offset="0.3691" stopColor="#938C8C" stopOpacity="0.369" />
        <stop offset="1" stopColor="#766E6E" />
      </linearGradient>
      <clipPath id="clip0_2_171">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconArchitecturalStyle;
