import React from "react";
import _ from "lodash";
import {
  IconBasement,
  IconBathrooms,
  IconBedrooms,
  IconBikeability,
  IconCentralAc,
  IconCommute,
  IconCustomDistanceTo,
  IconGarage,
  IconHomeType,
  IconLotSize,
  IconLowCrime,
  IconNewlyBuilt,
  IconParking,
  IconPriceRange,
  IconProximityToGrocery,
  IconProximityToParks,
  IconPublicTransit,
  IconQuietness,
  IconSchoolBag,
  IconSingleStory,
  IconSolarPower,
  IconSquareFootage,
  IconWalkability
} from "components/Svgs";
import { CRITERIA_MAP } from "constants/criteria";

// Gets the list of criteria data in the specific order
const getCriteriaData = criteriaList => (
  criteriaList.map(criteriaKey => _.find(ALL_CRITERIA_DATA, item => item.key === criteriaKey))
);

// Stores all criteria data which we need for buttons and table render
const ALL_CRITERIA_DATA = [
  {
    message: "dashboard.form.basement",
    key: CRITERIA_MAP.basement,
    icon: <IconBasement />,
  },
  {
    message: "dashboard.criteria.bathroomsMessage",
    key: CRITERIA_MAP.bathrooms,
    icon: <IconBathrooms />,
  },
  {
    message: "dashboard.criteria.bedroomsMessage",
    key: CRITERIA_MAP.bedrooms,
    icon: <IconBedrooms />,
  },
  {
    message: "dashboard.form.bikeability",
    key: CRITERIA_MAP.bikeability,
    icon: <IconBikeability />,
  },
  {
    message: "dashboard.form.squareFootage",
    key: CRITERIA_MAP.building_size,
    icon: <IconSquareFootage />,
  },
  {
    message: "dashboard.form.centralAc",
    key: CRITERIA_MAP.central_ac,
    icon: <IconCentralAc />,
  },
  {
    message: "dashboard.form.commute",
    key: CRITERIA_MAP.commute,
    icon: <IconCommute />,
  },
  {
    message: "dashboard.form.lowCrime",
    key: CRITERIA_MAP.crime,
    icon: <IconLowCrime />,
  },
  {
    message: "dashboard.form.customDistanceTo",
    key: CRITERIA_MAP.custom_distance,
    icon: <IconCustomDistanceTo />,
  },
  {
    message: "dashboard.form.garage",
    key: CRITERIA_MAP.garage,
    icon: <IconGarage />,
  },
  {
    message: "dashboard.form.proximityToGrocery",
    key: CRITERIA_MAP.grocery,
    icon: <IconProximityToGrocery />,
  },
  {
    message: "dashboard.form.lotSize",
    key: CRITERIA_MAP.lot_size,
    icon: <IconLotSize />,
  },
  {
    message: "dashboard.form.newlyBuilt",
    key: CRITERIA_MAP.newly_built,
    icon: <IconNewlyBuilt />,
  },
  {
    message: "dashboard.form.parking",
    key: CRITERIA_MAP.parking,
    icon: <IconParking />,
  },
  {
    message: "dashboard.form.proximityToParks",
    key: CRITERIA_MAP.parks,
    icon: <IconProximityToParks />,
  },
  {
    message: "dashboard.criteria.priceRangeMessage",
    key: CRITERIA_MAP.price,
    icon: <IconPriceRange />,
  },
  {
    message: "dashboard.criteria.homeTypeMessage",
    key: CRITERIA_MAP.prop_type,
    icon: <IconHomeType />,
  },
  {
    message: "dashboard.form.quietness",
    key: CRITERIA_MAP.quietness,
    icon: <IconQuietness />,
  },
  {
    message: "dashboard.form.walkability",
    key: CRITERIA_MAP.walkability,
    icon: <IconWalkability />,
  },
  {
    message: "dashboard.form.singleStory",
    key: CRITERIA_MAP.single_story,
    icon: <IconSingleStory />,
  },
  {
    message: "dashboard.form.schoolQuality",
    key: CRITERIA_MAP.school_quality,
    icon: <IconSchoolBag />,
  },
  {
    message: "dashboard.form.solarPowerPotential",
    key: CRITERIA_MAP.solar_power,
    icon: <IconSolarPower />,
  },
  {
    message: "dashboard.form.publicTransitScore",
    key: CRITERIA_MAP.transit,
    icon: <IconPublicTransit />,
  },
];

const BASIC_CRITERIA_FIRST_ROW = getCriteriaData([CRITERIA_MAP.price, CRITERIA_MAP.bedrooms]);

const BASIC_CRITERIA_SECOND_ROW = getCriteriaData([CRITERIA_MAP.prop_type, CRITERIA_MAP.bathrooms]);

const OTHER_CRITERIA_FIRST_ROW = getCriteriaData([
  CRITERIA_MAP.lot_size,
  CRITERIA_MAP.school_quality,
  CRITERIA_MAP.crime,
  CRITERIA_MAP.commute,
  CRITERIA_MAP.custom_distance,
  CRITERIA_MAP.transit,
  CRITERIA_MAP.grocery,
  CRITERIA_MAP.basement
]);

const OTHER_CRITERIA_SECOND_ROW = getCriteriaData([
  CRITERIA_MAP.building_size,
  CRITERIA_MAP.parks,
  CRITERIA_MAP.walkability,
  CRITERIA_MAP.quietness,
  CRITERIA_MAP.bikeability,
  CRITERIA_MAP.single_story
]);

const OTHER_CRITERIA_THIRD_ROW = getCriteriaData([
  CRITERIA_MAP.garage,
  CRITERIA_MAP.parking,
  CRITERIA_MAP.central_ac,
  CRITERIA_MAP.solar_power,
  CRITERIA_MAP.newly_built
]);

export  {
  ALL_CRITERIA_DATA,
  BASIC_CRITERIA_FIRST_ROW,
  BASIC_CRITERIA_SECOND_ROW,
  OTHER_CRITERIA_FIRST_ROW,
  OTHER_CRITERIA_SECOND_ROW,
  OTHER_CRITERIA_THIRD_ROW
};
