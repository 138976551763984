import { makeAutoObservable, toJS } from "mobx";
import _ from "lodash";
import { firebaseCheckAndUpdateIdToken } from "utils/firebase";
import globalErrorHandler from "utils/globalErrorHandler";
import {
  DATA_KEYS_BY_STEP,
  getOnboardingDataFromStorage,
  processDataFromStorageToSave
} from "utils/helpers/onboardingHelper";
import { USER_KEY } from "constants/localStorage";
import { getOnboardingDataApi, saveOnboardingDataApi } from "./requests";

class OnboardingStore {
  currentStep = null;
  isNextButtonDisabled = true;
  isOnboardingFinished = false;
  loading = false;
  onboardingData = {};
  stepData = {};

  constructor() {
    makeAutoObservable(this);
  }

  retrieveCurrentStep = () => this.currentStep;

  retrieveLoading = () => this.loading;

  retrieveIsOnboardingFinished = () => this.isOnboardingFinished;

  retrieveOnboardingData = () => toJS(this.onboardingData);

  retrieveStepData = () => toJS(this.stepData);

  setCurrentStep = number => this.currentStep = number;

  setIsOnboardingFinished = isFinished => this.isOnboardingFinished = isFinished;

  setLoading = isLoading => this.loading = isLoading;

  setStepData = () => {
    const stepData = {};
    const dataKeys =  _.has(DATA_KEYS_BY_STEP, this.currentStep) ? DATA_KEYS_BY_STEP[this.currentStep] : [];
    dataKeys.forEach(key => {
      if (_.has(this.onboardingData, key)) {
        stepData[key] = this.onboardingData[key];
      }
    });
    this.stepData = stepData;
  };

  updateOnboardingData = values => {
    // check if it's necessary, remove this logic if not
    const valuesToRemove = Object.keys(values).filter(key => _.isNull(values[key]));
    const valuesToUpdate = _.omit(values, valuesToRemove);
    if (!_.isEmpty(valuesToRemove)) {
      this.onboardingData = _.omit(this.onboardingData, valuesToRemove);
    }
    return _.assign(this.onboardingData, valuesToUpdate);
  };

  updateStepData = values => {
    const valuesToRemove = Object.keys(values).filter(key => _.isNull(values[key]));
    const valuesToUpdate = _.omit(values, valuesToRemove);
    if (!_.isEmpty(valuesToRemove)) {
      this.stepData = _.omit(this.stepData, valuesToRemove);
    }
    return _.assign(this.stepData, valuesToUpdate);
  };

  getOnboardingDataRequest = (handleSuccess, handleError) => {
    this.loading = true;
    this.getOnboardingData(handleSuccess, handleError);
  };

  requestFailure(handleError, errorResponse) {
    if (errorResponse && errorResponse.status !== 401) {
      this.loading = false;
    }
    globalErrorHandler(errorResponse, handleError);
  };

  requestSuccess(handleSuccess, data) {
    this.loading = false;

    if (handleSuccess) {
      handleSuccess(data);
    }
  };

  saveOnboardingDataRequest = (data, handleSuccess, handleError) => {
    this.loading = true;
    this.saveOnboardingData(data, handleSuccess, handleError);
  };

  *getOnboardingData(handleSuccess, handleError) {
    try {
      let onboardingData;
      const user = localStorage.getItem(USER_KEY);
      if (user) {
        yield firebaseCheckAndUpdateIdToken();
        const response = yield getOnboardingDataApi();
        onboardingData = response.data;
      } else {
        onboardingData = getOnboardingDataFromStorage();
      }
      const { data, is_finished, step } = onboardingData;
      this.currentStep = _.isNull(step) ? 0 : step + 1;
      this.isOnboardingFinished = is_finished;
      this.onboardingData = data;
      this.setStepData();
      this.requestSuccess(handleSuccess, onboardingData);
    } catch (error) {
      if (error && _.has(error, "response") && error.response.status === 404) {
        this.currentStep = 0;
      }
      this.requestFailure(handleError, error);
    }
  };

  *saveOnboardingData(requestBody, handleSuccess, handleError) {
    try {
      let onboardingData;
      const user = localStorage.getItem(USER_KEY);
      if (user) {
        yield firebaseCheckAndUpdateIdToken();
        const response = yield saveOnboardingDataApi(requestBody);
        onboardingData = response.data;
      } else {
        onboardingData = processDataFromStorageToSave(this.retrieveOnboardingData(), requestBody, DATA_KEYS_BY_STEP);
      }
      this.isOnboardingFinished = onboardingData.is_finished;
      this.onboardingData = onboardingData.data;
      this.requestSuccess(handleSuccess, onboardingData);
    } catch (error) {
      this.requestFailure(handleError, error);
    }
  };
}

export default OnboardingStore;
