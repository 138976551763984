import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import {SWITCH_MOVEMENT_TYPE} from "containers/Dashboard/constants"
import {SwitchButton} from "../../Controls/Buttons";
import "../styles.scss";

const MovementTypeField = ({
  value = [],
  form,
}) => {
  const [transportType, setTransportType] = useState(value);

  const onChangeActiveView = (type) => {
    setTransportType(type);
    form.setFieldsValue({
      transport_type: type,
    });
  }

  return (
    <div className="movement-type">
      <div className="switcher-wrapper">
        <div className="switcher">
          {SWITCH_MOVEMENT_TYPE.map(item => (
            <SwitchButton
              icon={item.icon({fill: item.key === transportType ? "#F4F7FC" : "#1B235E"})}
              isActive={item.key === transportType}
              onClick={() => onChangeActiveView(item.key)}
            >
              {item.key === transportType && <FormattedMessage id={item.message} />}
            </SwitchButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MovementTypeField;
