import React from "react";

const IconGraniteCountertops = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2_242)">
      <path
        d="M4.24487 25.4846L1.10124 22.797C-0.330547 21.5729 -0.330382 19.5885 1.10163 18.3646C1.78927 17.7769 2.17562 16.9797 2.17573 16.1485L2.17584 15.081C2.176 13.1283 1.69144 11.1978 0.754151 9.417C-0.418994 7.18799 -0.213441 4.60567 1.30213 2.53318C2.45463 0.957161 4.47568 -0.000164685 6.65002 2.12495e-08L14.9436 0.000658845C17.099 0.000823551 19.166 0.732886 20.69 2.03582L25.5553 6.19547C26.4327 6.94559 26.9256 7.96287 26.9255 9.02358L26.9249 16.1111C26.9249 16.6999 26.6512 17.2646 26.1641 17.6809C25.1498 18.5478 25.1497 19.9535 26.1638 20.8205L27.237 21.738C28.2472 22.6017 28.2517 24.0006 27.247 24.8689L25.3564 26.5029C24.2479 27.461 22.737 28.0001 21.1605 27.9999L11.345 27.9991C8.68183 27.999 6.12785 27.0944 4.24487 25.4846V25.4846Z"
        fill="url(#paint0_linear_2_242)"
      />
      <path
        d="M2.16654 16.1484L2.16665 15.0809C2.16681 13.1283 1.68225 11.1978 0.744961 9.41708C0.616819 9.17359 0.506082 8.92571 0.410772 8.67487H1.78942C3.84665 8.67487 5.5144 10.3425 5.5144 12.3998C5.5144 13.3877 5.12196 14.3351 4.42339 15.0336L1.46061 17.9964C1.91613 17.4632 2.16648 16.8169 2.16654 16.1484V16.1484Z"
        fill="url(#paint1_linear_2_242)"
      />
      <path
        d="M27.2513 24.8675L25.3607 26.5014C25.2611 26.5875 25.1581 26.6701 25.0522 26.7494L23.2342 24.9313C22.0419 23.739 22.0419 21.806 23.2342 20.6138L25.9037 17.9443C25.1637 18.8125 25.2517 20.0357 26.1681 20.8192L27.2413 21.7367C28.2515 22.6003 28.2559 23.9992 27.2513 24.8675V24.8675Z"
        fill="url(#paint2_linear_2_242)"
      />
      <path
        d="M23.3036 14.896C24.3496 14.896 25.1975 14.0481 25.1975 13.0021C25.1975 11.9562 24.3496 11.1083 23.3036 11.1083C22.2576 11.1083 21.4096 11.9562 21.4096 13.0021C21.4096 14.0481 22.2576 14.896 23.3036 14.896Z"
        fill="url(#paint3_linear_2_242)"
      />
      <path
        d="M22.5871 17.8329C23.1915 17.8329 23.6815 17.3429 23.6815 16.7384C23.6815 16.134 23.1915 15.644 22.5871 15.644C21.9826 15.644 21.4926 16.134 21.4926 16.7384C21.4926 17.3429 21.9826 17.8329 22.5871 17.8329Z"
        fill="url(#paint4_linear_2_242)"
      />
      <path
        d="M5.83029 7.61242C6.87629 7.61242 7.72424 6.76447 7.72424 5.71847C7.72424 4.67247 6.87629 3.82451 5.83029 3.82451C4.78429 3.82451 3.93634 4.67247 3.93634 5.71847C3.93634 6.76447 4.78429 7.61242 5.83029 7.61242Z"
        fill="url(#paint5_linear_2_242)"
      />
      <path
        d="M3.93006 10.407C4.5302 10.407 5.01671 9.92049 5.01671 9.3204C5.01671 8.7203 4.5302 8.23383 3.93006 8.23383C3.32992 8.23383 2.84342 8.7203 2.84342 9.3204C2.84342 9.92049 3.32992 10.407 3.93006 10.407Z"
        fill="url(#paint6_linear_2_242)"
      />
      <path
        d="M18.6676 3.8342C19.2677 3.8342 19.7542 3.34771 19.7542 2.74761C19.7542 2.1475 19.2677 1.66101 18.6676 1.66101C18.0675 1.66101 17.581 2.1475 17.581 2.74761C17.581 3.34771 18.0675 3.8342 18.6676 3.8342Z"
        fill="url(#paint7_linear_2_242)"
      />
      <path
        d="M8.82391 26.2595C9.42406 26.2595 9.91058 25.773 9.91058 25.1729C9.91058 24.5728 9.42406 24.0864 8.82391 24.0864C8.22375 24.0864 7.73723 24.5728 7.73723 25.1729C7.73723 25.773 8.22375 26.2595 8.82391 26.2595Z"
        fill="url(#paint8_linear_2_242)"
      />
      <path
        d="M26.1632 17.6786C26.6503 17.2623 26.924 16.6976 26.924 16.1089L26.9242 12.8448H1.96153C2.1028 13.5814 2.17499 14.3283 2.17494 15.0786L2.17483 16.1461C2.17477 16.9773 1.78837 17.7744 1.10073 18.3621C-0.331227 19.586 -0.331392 21.5704 1.10034 22.7944L4.24397 25.482C6.12695 27.0918 8.68087 27.9962 11.344 27.9965L21.1596 27.9973C22.7361 27.9974 24.247 27.4584 25.3555 26.5004L27.2461 24.8664C28.2508 23.9982 28.2463 22.5993 27.2361 21.7357L26.1629 20.8182C25.1488 19.9511 25.1489 18.5455 26.1632 17.6786V17.6786Z"
        fill="url(#paint9_linear_2_242)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2_242"
        x1="5.56543"
        y1="6.73147"
        x2="24.4033"
        y2="25.5693"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1B0A2" />
        <stop offset="1" stopColor="#CA6E59" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_242"
        x1="2.0093"
        y1="11.6396"
        x2="5.96187"
        y2="20.0391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#883F2E" stopOpacity="0" />
        <stop offset="1" stopColor="#883F2E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_242"
        x1="25.2328"
        y1="23.3757"
        x2="23.8054"
        y2="17.1173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#883F2E" stopOpacity="0" />
        <stop offset="1" stopColor="#883F2E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2_242"
        x1="24.7931"
        y1="14.4916"
        x2="23.0748"
        y2="12.7731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1B0A2" />
        <stop offset="1" stopColor="#CA6E59" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2_242"
        x1="23.4478"
        y1="17.5992"
        x2="22.4548"
        y2="16.6062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1B0A2" />
        <stop offset="1" stopColor="#CA6E59" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2_242"
        x1="7.28327"
        y1="7.17145"
        x2="3.54132"
        y2="3.42944"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1B0A2" />
        <stop offset="1" stopColor="#CA6E59" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2_242"
        x1="5.99756"
        y1="11.3878"
        x2="2.484"
        y2="7.87398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1B0A2" />
        <stop offset="1" stopColor="#CA6E59" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2_242"
        x1="20.8223"
        y1="4.90235"
        x2="17.6512"
        y2="1.73131"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1B0A2" />
        <stop offset="1" stopColor="#CA6E59" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2_242"
        x1="10.7089"
        y1="27.0577"
        x2="8.67762"
        y2="25.0263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1B0A2" />
        <stop offset="1" stopColor="#CA6E59" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2_242"
        x1="14.0117"
        y1="21.7715"
        x2="14.0117"
        y2="28.2815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#883F2E" stopOpacity="0" />
        <stop offset="1" stopColor="#883F2E" />
      </linearGradient>
      <clipPath id="clip0_2_242">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconGraniteCountertops;
