import React from "react";

const IconSquareFootage = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.4272 23.3184C31.1114 23.3184 30.8555 23.5743 30.8555 23.8901V26.1771C30.8555 26.4928 31.1114 26.7488 31.4272 26.7488C31.7429 26.7488 31.9989 26.4928 31.9989 26.1771V23.8902C31.9989 23.5744 31.7429 23.3184 31.4272 23.3184Z"
      fill="url(#paint0_linear_716_2292)"
    />
    <path
      d="M31.4272 17.2959C31.1114 17.2959 30.8555 17.5519 30.8555 17.8676V20.1546C30.8555 20.4703 31.1114 20.7263 31.4272 20.7263C31.7429 20.7263 31.9989 20.4703 31.9989 20.1546V17.8676C31.9989 17.5518 31.7429 17.2959 31.4272 17.2959Z"
      fill="url(#paint1_linear_716_2292)"
    />
    <path
      d="M31.4272 11.2734C31.1114 11.2734 30.8555 11.5294 30.8555 11.8452V14.1321C30.8555 14.4479 31.1114 14.7038 31.4272 14.7038C31.7429 14.7038 31.9989 14.4478 31.9989 14.1321V11.8452C31.9989 11.5294 31.7429 11.2734 31.4272 11.2734Z"
      fill="url(#paint2_linear_716_2292)"
    />
    <path
      d="M31.4272 5.25098C31.1114 5.25098 30.8555 5.50698 30.8555 5.82271V8.10965C30.8555 8.42544 31.1114 8.68138 31.4272 8.68138C31.7429 8.68138 31.9989 8.42544 31.9989 8.10965V5.82271C31.9989 5.50698 31.7429 5.25098 31.4272 5.25098Z"
      fill="url(#paint3_linear_716_2292)"
    />
    <path
      d="M31.4105 29.1414C31.0976 29.1508 30.8563 29.4238 30.8563 29.7368V30.8563H29.8067C29.4936 30.8563 29.2206 31.0976 29.2112 31.4105C29.2015 31.7343 29.4611 31.9998 29.7827 31.9998H31.3733C31.7193 31.9998 31.9998 31.7193 31.9998 31.3733V29.7129C31.9998 29.3912 31.7343 29.1317 31.4105 29.1414Z"
      fill="url(#paint4_linear_716_2292)"
    />
    <path
      d="M8.10873 30.8564H5.82173C5.50594 30.8564 5.25 31.1124 5.25 31.4282C5.25 31.7439 5.506 31.9999 5.82173 31.9999H8.10867C8.42447 31.9999 8.6804 31.7439 8.6804 31.4282C8.68047 31.1124 8.42447 30.8564 8.10873 30.8564Z"
      fill="url(#paint5_linear_716_2292)"
    />
    <path
      d="M14.1321 30.8564H11.8452C11.5294 30.8564 11.2734 31.1124 11.2734 31.4282C11.2734 31.7439 11.5294 31.9999 11.8452 31.9999H14.1321C14.4479 31.9999 14.7038 31.7439 14.7038 31.4282C14.7038 31.1124 14.4479 30.8564 14.1321 30.8564Z"
      fill="url(#paint6_linear_716_2292)"
    />
    <path
      d="M20.1555 30.8564H17.8686C17.5528 30.8564 17.2969 31.1124 17.2969 31.4282C17.2969 31.7439 17.5529 31.9999 17.8686 31.9999H20.1555C20.4713 31.9999 20.7273 31.7439 20.7273 31.4282C20.7273 31.1124 20.4713 30.8564 20.1555 30.8564Z"
      fill="url(#paint7_linear_716_2292)"
    />
    <path
      d="M26.179 30.8564H23.892C23.5762 30.8564 23.3203 31.1124 23.3203 31.4282C23.3203 31.7439 23.5763 31.9999 23.892 31.9999H26.179C26.4948 31.9999 26.7507 31.7439 26.7507 31.4282C26.7507 31.1124 26.4948 30.8564 26.179 30.8564Z"
      fill="url(#paint8_linear_716_2292)"
    />
    <path
      d="M2.26296 30.8568H1.14347V29.8071C1.14347 29.4941 0.902212 29.2211 0.589302 29.2117C0.265537 29.202 0 29.4615 0 29.7832V31.3738C0 31.7197 0.280533 32.0003 0.626573 32.0003H2.287C2.60863 32.0003 2.8682 31.7347 2.85848 31.4109C2.84907 31.098 2.57606 30.8568 2.26296 30.8568Z"
      fill="url(#paint9_linear_716_2292)"
    />
    <path
      d="M0.571733 8.68145C0.887529 8.68145 1.14347 8.42551 1.14347 8.10971V5.82271C1.14347 5.50698 0.887529 5.25098 0.571733 5.25098C0.256 5.25098 0 5.50698 0 5.82271V8.10965C0 8.42545 0.256 8.68145 0.571733 8.68145Z"
      fill="url(#paint10_linear_716_2292)"
    />
    <path
      d="M0.571733 14.7038C0.887529 14.7038 1.14347 14.4478 1.14347 14.1321V11.8452C1.14347 11.5294 0.887529 11.2734 0.571733 11.2734C0.256 11.2734 0 11.5294 0 11.8452V14.1321C0 14.4479 0.256 14.7038 0.571733 14.7038Z"
      fill="url(#paint11_linear_716_2292)"
    />
    <path
      d="M0.571733 20.7263C0.887529 20.7263 1.14347 20.4703 1.14347 20.1546V17.8676C1.14347 17.5518 0.887529 17.2959 0.571733 17.2959C0.256 17.2959 0 17.5519 0 17.8676V20.1546C0 20.4704 0.256 20.7263 0.571733 20.7263Z"
      fill="url(#paint12_linear_716_2292)"
    />
    <path
      d="M0.571733 26.7492C0.887529 26.7492 1.14347 26.4932 1.14347 26.1775V23.8906C1.14347 23.5748 0.887529 23.3188 0.571733 23.3188C0.256 23.3188 0 23.5748 0 23.8906V26.1775C0 26.4932 0.256 26.7492 0.571733 26.7492Z"
      fill="url(#paint13_linear_716_2292)"
    />
    <path
      d="M0.589365 2.85842C0.902275 2.849 1.14353 2.576 1.14353 2.26296V1.14347H2.19319C2.50623 1.14347 2.77923 0.902212 2.78864 0.589302C2.79831 0.265537 2.53873 0 2.2171 0H0.626573C0.280533 0 0 0.280533 0 0.62651V2.28693C0 2.60856 0.265537 2.86814 0.589365 2.85842Z"
      fill="url(#paint14_linear_716_2292)"
    />
    <path
      d="M23.892 1.14347H26.179C26.4948 1.14347 26.7507 0.887529 26.7507 0.571733C26.7507 0.256 26.4947 0 26.179 0H23.892C23.5762 0 23.3203 0.256 23.3203 0.571733C23.3202 0.887529 23.5762 1.14347 23.892 1.14347Z"
      fill="url(#paint15_linear_716_2292)"
    />
    <path
      d="M17.8686 1.14347H20.1555C20.4713 1.14347 20.7273 0.887529 20.7273 0.571733C20.7273 0.256 20.4713 0 20.1555 0H17.8686C17.5528 0 17.2969 0.256 17.2969 0.571733C17.2969 0.887529 17.5528 1.14347 17.8686 1.14347Z"
      fill="url(#paint16_linear_716_2292)"
    />
    <path
      d="M11.8452 1.14347H14.1321C14.4479 1.14347 14.7038 0.887529 14.7038 0.571733C14.7038 0.256 14.4478 0 14.1321 0H11.8452C11.5294 0 11.2734 0.256 11.2734 0.571733C11.2734 0.887529 11.5294 1.14347 11.8452 1.14347Z"
      fill="url(#paint17_linear_716_2292)"
    />
    <path
      d="M5.82173 1.14347H8.10867C8.42447 1.14347 8.6804 0.887529 8.6804 0.571733C8.6804 0.256 8.4244 0 8.10867 0H5.82173C5.50594 0 5.25 0.256 5.25 0.571733C5.25 0.887529 5.50594 1.14347 5.82173 1.14347Z"
      fill="url(#paint18_linear_716_2292)"
    />
    <path
      d="M31.3728 0H29.7124C29.3907 0 29.1312 0.265537 29.1409 0.589302C29.1503 0.902212 29.4233 1.14347 29.7363 1.14347H30.8558V2.19313C30.8558 2.50617 31.0971 2.77917 31.41 2.78858C31.7338 2.79831 31.9993 2.53873 31.9993 2.2171V0.62651C31.9994 0.280533 31.7188 0 31.3728 0V0Z"
      fill="url(#paint19_linear_716_2292)"
    />
    <path
      d="M5.96875 5.96826H26.0323V26.0318H5.96875V5.96826Z"
      fill="url(#paint20_linear_716_2292)"
    />
    <path
      d="M26.0327 8.24121H23.7266L26.0327 10.5474V8.24121Z"
      fill="url(#paint21_linear_716_2292)"
    />
    <path
      d="M8.28516 23.771V26.0318H10.546L8.28516 23.771Z"
      fill="url(#paint22_linear_716_2292)"
    />
    <path
      d="M26.0314 23.4711V26.0309H23.4987L5.96875 8.50091V5.96826H8.52718L26.0314 23.4711Z"
      fill="url(#paint23_linear_716_2292)"
    />
    <path
      d="M7.15625 7.15674H24.8421V24.8426H7.15625V7.15674Z"
      fill="url(#paint24_linear_716_2292)"
    />
    <path
      d="M7.15625 19.5322H24.8421V24.8425H7.15625V19.5322Z"
      fill="url(#paint25_linear_716_2292)"
    />
    <path
      d="M8.28516 23.771V24.843H9.35716L8.28516 23.771Z"
      fill="url(#paint26_linear_716_2292)"
    />
    <path
      d="M7.15625 9.68938L22.3094 24.8426H24.8421V22.2829L9.71481 7.15674H7.15625V9.68938Z"
      fill="url(#paint27_linear_716_2292)"
    />
    <path
      d="M24.8439 8.24121H23.7266L24.8439 9.35857V8.24121Z"
      fill="url(#paint28_linear_716_2292)"
    />
    <path
      d="M7.60941 8.52697H6.18277C5.67623 8.52697 5.26562 8.11637 5.26562 7.60983V6.18326C5.26562 5.67672 5.67623 5.26611 6.18277 5.26611H7.60941C8.11595 5.26611 8.52655 5.67672 8.52655 6.18326V7.60989C8.52649 8.11637 8.11588 8.52697 7.60941 8.52697Z"
      fill="url(#paint29_linear_716_2292)"
    />
    <path
      d="M6.49098 5.96826H7.30334C7.59178 5.96826 7.82557 6.20205 7.82557 6.49049V7.30285C7.82557 7.59129 7.59178 7.82508 7.30334 7.82508H6.49098C6.20254 7.82508 5.96875 7.59129 5.96875 7.30285V6.49049C5.96875 6.20211 6.2026 5.96826 6.49098 5.96826Z"
      fill="url(#paint30_linear_716_2292)"
    />
    <path
      d="M25.8164 8.52697H24.3898C23.8833 8.52697 23.4727 8.11637 23.4727 7.60983V6.18326C23.4727 5.67672 23.8833 5.26611 24.3898 5.26611H25.8164C26.323 5.26611 26.7336 5.67672 26.7336 6.18326V7.60989C26.7336 8.11637 26.323 8.52697 25.8164 8.52697Z"
      fill="url(#paint31_linear_716_2292)"
    />
    <path
      d="M24.698 5.96826H25.5104C25.7988 5.96826 26.0326 6.20205 26.0326 6.49049V7.30285C26.0326 7.59129 25.7987 7.82508 25.5104 7.82508H24.698C24.4096 7.82508 24.1758 7.59129 24.1758 7.30285V6.49049C24.1758 6.20211 24.4096 5.96826 24.698 5.96826Z"
      fill="url(#paint32_linear_716_2292)"
    />
    <path
      d="M7.60941 26.7336H6.18277C5.67623 26.7336 5.26562 26.323 5.26562 25.8164V24.3898C5.26562 23.8833 5.67623 23.4727 6.18277 23.4727H7.60941C8.11595 23.4727 8.52655 23.8833 8.52655 24.3898V25.8164C8.52649 26.323 8.11588 26.7336 7.60941 26.7336Z"
      fill="url(#paint33_linear_716_2292)"
    />
    <path
      d="M6.49098 24.1748H7.30334C7.59178 24.1748 7.82557 24.4087 7.82557 24.697V25.5094C7.82557 25.7978 7.59178 26.0316 7.30334 26.0316H6.49098C6.20254 26.0316 5.96875 25.7978 5.96875 25.5094V24.697C5.96875 24.4086 6.2026 24.1748 6.49098 24.1748Z"
      fill="url(#paint34_linear_716_2292)"
    />
    <path
      d="M25.8164 26.7336H24.3898C23.8833 26.7336 23.4727 26.323 23.4727 25.8164V24.3898C23.4727 23.8833 23.8833 23.4727 24.3898 23.4727H25.8164C26.323 23.4727 26.7336 23.8833 26.7336 24.3898V25.8164C26.7336 26.323 26.323 26.7336 25.8164 26.7336Z"
      fill="url(#paint35_linear_716_2292)"
    />
    <path
      d="M24.698 24.1748H25.5104C25.7988 24.1748 26.0326 24.4087 26.0326 24.697V25.5094C26.0326 25.7978 25.7987 26.0316 25.5104 26.0316H24.698C24.4096 26.0316 24.1758 25.7978 24.1758 25.5094V24.697C24.1758 24.4086 24.4096 24.1748 24.698 24.1748Z"
      fill="url(#paint36_linear_716_2292)"
    />
    <path
      d="M16.219 16.4978H15.7774C15.6242 16.4978 15.5 16.3737 15.5 16.2205V15.7789C15.5 15.6256 15.6242 15.5015 15.7774 15.5015H16.219C16.3722 15.5015 16.4964 15.6256 16.4964 15.7789V16.2205C16.4964 16.3737 16.3722 16.4978 16.219 16.4978Z"
      fill="url(#paint37_linear_716_2292)"
    />
    <path
      d="M27.281 30.1608H29.646C29.9301 30.1608 30.1604 29.9306 30.1604 29.6465V27.2815C30.1604 26.8811 29.6763 26.6806 29.3932 26.9637L29.0109 27.3459L27.6744 26.0094C27.5428 25.8777 27.3294 25.8777 27.1978 26.0094L26.0089 27.1983C25.8772 27.3299 25.8772 27.5432 26.0089 27.6749L27.3454 29.0114L26.9632 29.3937C26.6801 29.6768 26.8806 30.1608 27.281 30.1608Z"
      fill="url(#paint38_linear_716_2292)"
    />
    <path
      d="M26.0089 27.1985C25.8772 27.3301 25.8772 27.5434 26.0089 27.6751L27.3454 29.0116L26.9632 29.3938C26.6801 29.6769 26.8806 30.161 27.281 30.161H29.646C29.7881 30.161 29.9167 30.1035 30.0097 30.0104L26.6033 26.604L26.0089 27.1985Z"
      fill="url(#paint39_linear_716_2292)"
    />
    <path
      d="M4.71922 1.83936H2.35417C2.07012 1.83936 1.83984 2.06963 1.83984 2.35368V4.71873C1.83984 5.1191 2.32392 5.31964 2.60703 5.03653L2.98927 4.65429L4.3258 5.99082C4.45744 6.12246 4.67078 6.12246 4.80242 5.99082L5.99131 4.80193C6.12295 4.67029 6.12295 4.45696 5.99131 4.32532L4.65478 2.98878L5.03702 2.60654C5.32013 2.32343 5.11959 1.83936 4.71922 1.83936Z"
      fill="url(#paint40_linear_716_2292)"
    />
    <path
      d="M5.993 4.80193C6.12464 4.67029 6.12464 4.45696 5.993 4.32532L4.65647 2.98878L5.03871 2.60654C5.32182 2.32343 5.12129 1.83936 4.72091 1.83936H2.35586C2.2138 1.83936 2.08524 1.89689 1.99219 1.99001L5.39856 5.39638L5.993 4.80193Z"
      fill="url(#paint41_linear_716_2292)"
    />
    <path
      d="M1.83984 27.2815V29.6465C1.83984 29.9306 2.07012 30.1608 2.35417 30.1608H4.71922C5.11959 30.1608 5.32013 29.6768 5.03702 29.3937L4.65478 29.0114L5.99131 27.6749C6.12295 27.5432 6.12295 27.3299 5.99131 27.1983L4.80242 26.0094C4.67078 25.8777 4.45744 25.8777 4.3258 26.0094L2.98927 27.3459L2.60703 26.9637C2.32392 26.6806 1.83984 26.8811 1.83984 27.2815Z"
      fill="url(#paint42_linear_716_2292)"
    />
    <path
      d="M4.80242 26.0094C4.67078 25.8777 4.45744 25.8777 4.3258 26.0094L2.98927 27.3459L2.60703 26.9637C2.32392 26.6806 1.83984 26.8811 1.83984 27.2815V29.6465C1.83984 29.7886 1.89738 29.9172 1.99049 30.0102L5.39686 26.6038L4.80242 26.0094Z"
      fill="url(#paint43_linear_716_2292)"
    />
    <path
      d="M30.1604 4.71873V2.35368C30.1604 2.06963 29.9301 1.83936 29.646 1.83936H27.281C26.8806 1.83936 26.6801 2.32343 26.9632 2.60654L27.3454 2.98878L26.0089 4.32532C25.8772 4.45696 25.8772 4.67029 26.0089 4.80193L27.1978 5.99082C27.3294 6.12246 27.5428 6.12246 27.6744 5.99082L29.0109 4.65429L29.3932 5.03653C29.6763 5.31964 30.1604 5.1191 30.1604 4.71873Z"
      fill="url(#paint44_linear_716_2292)"
    />
    <path
      d="M27.196 5.99105C27.3276 6.12269 27.541 6.12269 27.6726 5.99105L29.0092 4.65452L29.3914 5.03676C29.6745 5.31987 30.1586 5.11933 30.1586 4.71896V2.35391C30.1586 2.21185 30.101 2.08329 30.0079 1.99023L26.6016 5.3966L27.196 5.99105Z"
      fill="url(#paint45_linear_716_2292)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_716_2292"
        x1="7.83769"
        y1="1.44408"
        x2="26.8689"
        y2="20.4753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_716_2292"
        x1="10.8489"
        y1="-1.56716"
        x2="29.8802"
        y2="17.4641"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_716_2292"
        x1="13.8602"
        y1="-4.57844"
        x2="32.8915"
        y2="14.4528"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_716_2292"
        x1="16.8714"
        y1="-7.58963"
        x2="35.9027"
        y2="11.4416"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_716_2292"
        x1="4.65877"
        y1="4.62382"
        x2="23.6901"
        y2="23.6551"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_716_2292"
        x1="-7.59061"
        y1="16.8724"
        x2="11.4407"
        y2="35.9037"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_716_2292"
        x1="-4.57844"
        y1="13.8612"
        x2="14.4528"
        y2="32.8925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_716_2292"
        x1="-1.56619"
        y1="10.8499"
        x2="17.465"
        y2="29.8811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_716_2292"
        x1="1.44599"
        y1="7.83867"
        x2="20.4772"
        y2="26.8699"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_716_2292"
        x1="-10.3521"
        y1="19.6352"
        x2="8.67925"
        y2="38.6664"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_716_2292"
        x1="1.4442"
        y1="7.83865"
        x2="20.4755"
        y2="26.8699"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_716_2292"
        x1="-1.56706"
        y1="10.8498"
        x2="17.4642"
        y2="29.8811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_716_2292"
        x1="-4.57832"
        y1="13.8611"
        x2="14.4529"
        y2="32.8923"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_716_2292"
        x1="-7.58959"
        y1="16.8728"
        x2="11.4417"
        y2="35.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_716_2292"
        x1="4.62394"
        y1="4.65889"
        x2="23.6553"
        y2="23.6902"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_716_2292"
        x1="16.8742"
        y1="-7.58958"
        x2="35.9055"
        y2="11.4417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_716_2292"
        x1="13.862"
        y1="-4.57832"
        x2="32.8933"
        y2="14.4529"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_716_2292"
        x1="10.8498"
        y1="-1.56706"
        x2="29.8811"
        y2="17.4642"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_716_2292"
        x1="7.83767"
        y1="1.4442"
        x2="26.869"
        y2="20.4755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_716_2292"
        x1="19.6343"
        y1="-10.3521"
        x2="38.6655"
        y2="8.67925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_716_2292"
        x1="9.48737"
        y1="9.48688"
        x2="25.5166"
        y2="25.5161"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_716_2292"
        x1="25.7835"
        y1="10.3598"
        x2="24.6966"
        y2="7.81229"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1B444F" stopOpacity="0" />
        <stop offset="1" stopColor="#1B444F" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_716_2292"
        x1="9.88402"
        y1="26.2163"
        x2="8.16298"
        y2="24.801"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1B444F" stopOpacity="0" />
        <stop offset="1" stopColor="#1B444F" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_716_2292"
        x1="10.1645"
        y1="10.1503"
        x2="4.95592"
        y2="4.94181"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1B444F" stopOpacity="0" />
        <stop offset="1" stopColor="#1B444F" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_716_2292"
        x1="10.2579"
        y1="10.2584"
        x2="24.3876"
        y2="24.388"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF5D9" />
        <stop offset="1" stopColor="#FFCE47" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_716_2292"
        x1="15.9992"
        y1="21.2075"
        x2="15.9992"
        y2="27.4192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9356" stopOpacity="0" />
        <stop offset="1" stopColor="#FF755C" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_716_2292"
        x1="8.95709"
        y1="25.7376"
        x2="8.67467"
        y2="23.8791"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE47" stopOpacity="0" />
        <stop offset="1" stopColor="#FF9356" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_716_2292"
        x1="13.7787"
        y1="13.7657"
        x2="5.74775"
        y2="5.73468"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEBB4" stopOpacity="0" />
        <stop offset="1" stopColor="#FFCE47" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_716_2292"
        x1="25.8568"
        y1="9.66088"
        x2="24.4794"
        y2="8.49011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEBB4" stopOpacity="0" />
        <stop offset="1" stopColor="#FFCE47" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_716_2292"
        x1="6.01192"
        y1="6.0124"
        x2="8.18791"
        y2="8.1884"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_716_2292"
        x1="7.40066"
        y1="7.40023"
        x2="6.16157"
        y2="6.16108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_716_2292"
        x1="24.2189"
        y1="6.0124"
        x2="26.3949"
        y2="8.18841"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_716_2292"
        x1="25.6077"
        y1="7.40023"
        x2="24.3686"
        y2="6.16108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_716_2292"
        x1="6.01192"
        y1="24.2189"
        x2="8.18793"
        y2="26.3949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_716_2292"
        x1="7.40066"
        y1="25.6067"
        x2="6.16157"
        y2="24.3676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_716_2292"
        x1="24.2189"
        y1="24.2189"
        x2="26.395"
        y2="26.395"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_716_2292"
        x1="25.6077"
        y1="25.6067"
        x2="24.3686"
        y2="24.3676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_716_2292"
        x1="15.7275"
        y1="15.729"
        x2="16.3937"
        y2="16.3951"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F73A0" />
        <stop offset="1" stopColor="#43765A" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_716_2292"
        x1="30.2156"
        y1="28.0239"
        x2="26.7983"
        y2="28.0239"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_716_2292"
        x1="27.4865"
        y1="28.5754"
        x2="30.587"
        y2="27.6055"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ACBFF" stopOpacity="0" />
        <stop offset="1" stopColor="#2F73A0" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_716_2292"
        x1="1.75783"
        y1="3.95967"
        x2="5.17516"
        y2="3.95962"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_716_2292"
        x1="4.4886"
        y1="3.40826"
        x2="1.38804"
        y2="4.37817"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ACBFF" stopOpacity="0" />
        <stop offset="1" stopColor="#2F73A0" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_716_2292"
        x1="3.95167"
        y1="30.2211"
        x2="3.95162"
        y2="26.8038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_716_2292"
        x1="3.40026"
        y1="27.492"
        x2="4.37013"
        y2="30.5926"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ACBFF" stopOpacity="0" />
        <stop offset="1" stopColor="#2F73A0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_716_2292"
        x1="28.0148"
        y1="1.76236"
        x2="28.0149"
        y2="5.1797"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7E9FF" />
        <stop offset="1" stopColor="#7ACBFF" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_716_2292"
        x1="28.5645"
        y1="4.49167"
        x2="27.5946"
        y2="1.39116"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ACBFF" stopOpacity="0" />
        <stop offset="1" stopColor="#2F73A0" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconSquareFootage;
