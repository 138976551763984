import React from "react";

const IconCriteria = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.25 0H7.5C8.1897 0 8.75 0.559998 8.75 1.25V7.5C8.75 8.19 8.1897 8.75 7.5 8.75H1.25C0.560303 8.75 0 8.19 0 7.5V1.25C0 0.559998 0.560303 0 1.25 0Z"
    />
    <path
      d="M1.25 11.25H7.5C8.1897 11.25 8.75 11.81 8.75 12.5V18.75C8.75 19.44 8.1897 20 7.5 20H1.25C0.560303 20 0 19.44 0 18.75V12.5C0 11.81 0.560303 11.25 1.25 11.25Z"
    />
    <path
      d="M18.75 0H12.5C11.8103 0 11.25 0.559998 11.25 1.25V7.5C11.25 8.19 11.8103 8.75 12.5 8.75H18.75C19.4397 8.75 20 8.19 20 7.5V1.25C20 0.559998 19.4397 0 18.75 0Z"
    />
    <path
      d="M12.5 11.25H18.75C19.4397 11.25 20 11.81 20 12.5V18.75C20 19.44 19.4397 20 18.75 20H12.5C11.8103 20 11.25 19.44 11.25 18.75V12.5C11.25 11.81 11.8103 11.25 12.5 11.25Z"
    />
  </svg>
);

export default IconCriteria;
