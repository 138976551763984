import React from "react";

const IconYearBuilt = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6199_15806)">
      <path d="M11.4336 1.00659H0.566352C0.253412 1.00659 0 1.26024 0 1.57295V11.4337C0 11.7466 0.253412 12 0.566352 12H11.4336C11.7466 12 12 11.7466 12 11.4337V1.57295C12 1.26024 11.7466 1.00659 11.4336 1.00659ZM4.1687 2.56354C4.09835 2.63389 4.00141 2.67718 3.89411 2.67718H2.05882C1.84447 2.67718 1.67059 2.50354 1.67059 2.28895C1.67059 2.18165 1.71412 2.08471 1.78423 2.01436C1.85459 1.94424 1.95153 1.90071 2.05882 1.90071H3.89411C4.10847 1.90071 4.28235 2.07459 4.28235 2.28895C4.28235 2.39624 4.23882 2.49318 4.1687 2.56354ZM10.2158 2.56354C10.1454 2.63389 10.0485 2.67718 9.94117 2.67718H8.10588C7.89152 2.67718 7.71764 2.50354 7.71764 2.28895C7.71764 2.18165 7.76117 2.08471 7.83129 2.01436C7.90164 1.94424 7.99858 1.90071 8.10588 1.90071H9.94117C10.1555 1.90071 10.3294 2.07459 10.3294 2.28895C10.3294 2.39624 10.2859 2.49318 10.2158 2.56354Z" fill="url(#paint0_linear_6199_15806)"/>
      <path d="M11.4336 1.00659H0.566352C0.253412 1.00659 0 1.26024 0 1.57294V4.04706H12V1.57294C12 1.26024 11.7466 1.00659 11.4336 1.00659ZM4.1687 2.56353C4.09835 2.63388 4.00141 2.67718 3.89411 2.67718H2.05882C1.84447 2.67718 1.67059 2.50353 1.67059 2.28894C1.67059 2.18165 1.71412 2.08471 1.78423 2.01436C1.85459 1.94424 1.95153 1.90071 2.05882 1.90071H3.89411C4.10847 1.90071 4.28235 2.07459 4.28235 2.28894C4.28235 2.39624 4.23882 2.49318 4.1687 2.56353ZM10.2158 2.56353C10.1454 2.63388 10.0485 2.67718 9.94117 2.67718H8.10588C7.89152 2.67718 7.71764 2.50353 7.71764 2.28894C7.71764 2.18165 7.76117 2.08471 7.83129 2.01436C7.90164 1.94424 7.99858 1.90071 8.10588 1.90071H9.94117C10.1555 1.90071 10.3294 2.07459 10.3294 2.28894C10.3294 2.39624 10.2859 2.49318 10.2158 2.56353Z" fill="url(#paint1_linear_6199_15806)"/>
      <path d="M4.10538 1.00669L4.10524 1.00659H3.12891V1.90071H3.89361C4.10797 1.90071 4.28185 2.07459 4.28185 2.28894C4.28185 2.39624 4.23832 2.49318 4.1682 2.56353C4.09785 2.63388 4.00091 2.67718 3.89361 2.67718H3.12891V4.04706H7.14575L4.10538 1.00669Z" fill="url(#paint2_linear_6199_15806)"/>
      <path d="M10.1523 1.00669L10.1521 1.00659H9.17578V1.90071H9.94049C10.1548 1.90071 10.3287 2.07459 10.3287 2.28894C10.3287 2.39624 10.2852 2.49318 10.2151 2.56353C10.1447 2.63388 10.0478 2.67718 9.94049 2.67718H9.17578V4.04706H11.9993V2.85374L10.1523 1.00669Z" fill="url(#paint3_linear_6199_15806)"/>
      <path d="M2.32989 6.62366C2.85618 6.62366 3.28283 6.19701 3.28283 5.67072C3.28283 5.14442 2.85618 4.71777 2.32989 4.71777C1.8036 4.71777 1.37695 5.14442 1.37695 5.67072C1.37695 6.19701 1.8036 6.62366 2.32989 6.62366Z" fill="url(#paint4_linear_6199_15806)"/>
      <path d="M1.37695 5.68237C1.38321 6.2033 1.80721 6.6237 2.32961 6.6237C2.85201 6.6237 3.27601 6.2033 3.28226 5.68237H1.37695Z" fill="url(#paint5_linear_6199_15806)"/>
      <path d="M4.77716 6.62366C5.30345 6.62366 5.7301 6.19701 5.7301 5.67072C5.7301 5.14442 5.30345 4.71777 4.77716 4.71777C4.25086 4.71777 3.82422 5.14442 3.82422 5.67072C3.82422 6.19701 4.25086 6.62366 4.77716 6.62366Z" fill="url(#paint6_linear_6199_15806)"/>
      <path d="M3.82422 5.68237C3.83048 6.2033 4.25448 6.6237 4.77688 6.6237C5.29927 6.6237 5.72327 6.2033 5.72953 5.68237H3.82422Z" fill="url(#paint7_linear_6199_15806)"/>
      <path d="M7.22442 6.62366C7.75072 6.62366 8.17736 6.19701 8.17736 5.67072C8.17736 5.14442 7.75072 4.71777 7.22442 4.71777C6.69813 4.71777 6.27148 5.14442 6.27148 5.67072C6.27148 6.19701 6.69813 6.62366 7.22442 6.62366Z" fill="url(#paint8_linear_6199_15806)"/>
      <path d="M6.27148 5.68237C6.27774 6.2033 6.70174 6.6237 7.22414 6.6237C7.74654 6.6237 8.17054 6.2033 8.1768 5.68237H6.27148Z" fill="url(#paint9_linear_6199_15806)"/>
      <path d="M9.67169 6.62366C10.198 6.62366 10.6246 6.19701 10.6246 5.67072C10.6246 5.14442 10.198 4.71777 9.67169 4.71777C9.1454 4.71777 8.71875 5.14442 8.71875 5.67072C8.71875 6.19701 9.1454 6.62366 9.67169 6.62366Z" fill="url(#paint10_linear_6199_15806)"/>
      <path d="M8.71875 5.68237C8.72501 6.2033 9.14901 6.6237 9.67141 6.6237C10.1938 6.6237 10.6178 6.2033 10.6241 5.68237H8.71875Z" fill="url(#paint11_linear_6199_15806)"/>
      <path d="M2.32989 8.9884C2.85619 8.9884 3.28283 8.56175 3.28283 8.03546C3.28283 7.50916 2.85619 7.08252 2.32989 7.08252C1.8036 7.08252 1.37695 7.50916 1.37695 8.03546C1.37695 8.56175 1.8036 8.9884 2.32989 8.9884Z" fill="url(#paint12_linear_6199_15806)"/>
      <path d="M1.37695 8.04712C1.38321 8.56804 1.80721 8.98843 2.32961 8.98843C2.85201 8.98843 3.27601 8.56804 3.28226 8.04712H1.37695Z" fill="url(#paint13_linear_6199_15806)"/>
      <path d="M4.77716 8.9884C5.30345 8.9884 5.7301 8.56175 5.7301 8.03546C5.7301 7.50916 5.30345 7.08252 4.77716 7.08252C4.25086 7.08252 3.82422 7.50916 3.82422 8.03546C3.82422 8.56175 4.25086 8.9884 4.77716 8.9884Z" fill="url(#paint14_linear_6199_15806)"/>
      <path d="M3.82422 8.04712C3.83048 8.56804 4.25448 8.98843 4.77688 8.98843C5.29927 8.98843 5.72327 8.56804 5.72953 8.04712H3.82422Z" fill="url(#paint15_linear_6199_15806)"/>
      <path d="M7.22442 8.9884C7.75072 8.9884 8.17736 8.56175 8.17736 8.03546C8.17736 7.50916 7.75072 7.08252 7.22442 7.08252C6.69813 7.08252 6.27148 7.50916 6.27148 8.03546C6.27148 8.56175 6.69813 8.9884 7.22442 8.9884Z" fill="url(#paint16_linear_6199_15806)"/>
      <path d="M6.27148 8.04712C6.27774 8.56804 6.70174 8.98843 7.22414 8.98843C7.74654 8.98843 8.17054 8.56804 8.1768 8.04712H6.27148Z" fill="url(#paint17_linear_6199_15806)"/>
      <path d="M9.67169 8.9884C10.198 8.9884 10.6246 8.56175 10.6246 8.03546C10.6246 7.50916 10.198 7.08252 9.67169 7.08252C9.1454 7.08252 8.71875 7.50916 8.71875 8.03546C8.71875 8.56175 9.1454 8.9884 9.67169 8.9884Z" fill="url(#paint18_linear_6199_15806)"/>
      <path d="M8.71875 8.04712C8.72501 8.56804 9.14901 8.98843 9.67141 8.98843C10.1938 8.98843 10.6178 8.56804 10.6241 8.04712H8.71875Z" fill="url(#paint19_linear_6199_15806)"/>
      <path d="M2.32989 11.3531C2.85619 11.3531 3.28283 10.9265 3.28283 10.4002C3.28283 9.87391 2.85619 9.44727 2.32989 9.44727C1.8036 9.44727 1.37695 9.87391 1.37695 10.4002C1.37695 10.9265 1.8036 11.3531 2.32989 11.3531Z" fill="url(#paint20_linear_6199_15806)"/>
      <path d="M1.37695 10.4119C1.38321 10.9328 1.80721 11.3532 2.32961 11.3532C2.85201 11.3532 3.27601 10.9328 3.28226 10.4119H1.37695Z" fill="url(#paint21_linear_6199_15806)"/>
      <path d="M4.77716 11.3531C5.30345 11.3531 5.7301 10.9265 5.7301 10.4002C5.7301 9.87391 5.30345 9.44727 4.77716 9.44727C4.25086 9.44727 3.82422 9.87391 3.82422 10.4002C3.82422 10.9265 4.25086 11.3531 4.77716 11.3531Z" fill="url(#paint22_linear_6199_15806)"/>
      <path d="M3.82422 10.4119C3.83048 10.9328 4.25448 11.3532 4.77688 11.3532C5.29927 11.3532 5.72327 10.9328 5.72953 10.4119H3.82422Z" fill="url(#paint23_linear_6199_15806)"/>
      <path d="M7.22442 11.3531C7.75072 11.3531 8.17736 10.9265 8.17736 10.4002C8.17736 9.87391 7.75072 9.44727 7.22442 9.44727C6.69813 9.44727 6.27148 9.87391 6.27148 10.4002C6.27148 10.9265 6.69813 11.3531 7.22442 11.3531Z" fill="url(#paint24_linear_6199_15806)"/>
      <path d="M6.27148 10.4119C6.27774 10.9328 6.70174 11.3532 7.22414 11.3532C7.74654 11.3532 8.17054 10.9328 8.1768 10.4119H6.27148Z" fill="url(#paint25_linear_6199_15806)"/>
      <path d="M0 9.81641V11.4336C0 11.7464 0.253529 11.9999 0.566258 11.9999H11.4337C11.7465 11.9999 12 11.7464 12 11.4336V9.81641H0Z" fill="url(#paint26_linear_6199_15806)"/>
      <path d="M3.91676 2.08628H2.03735C1.93257 2.08628 1.84766 2.00134 1.84766 1.89659V0.189694C1.84766 0.0849412 1.9326 0 2.03735 0H3.91676C4.02154 0 4.10648 0.0849412 4.10648 0.189694V1.89656C4.10648 2.00134 4.02154 2.08628 3.91676 2.08628Z" fill="url(#paint27_linear_6199_15806)"/>
      <path d="M1.84766 1.00659V1.8965C1.84766 2.00127 1.9326 2.08619 2.03735 2.08619H3.91676C4.02154 2.08619 4.10648 2.00125 4.10648 1.8965V1.00659H1.84766Z" fill="url(#paint28_linear_6199_15806)"/>
      <path d="M9.96364 2.08628H8.08422C7.97945 2.08628 7.89453 2.00134 7.89453 1.89659V0.189694C7.89451 0.0849412 7.97945 0 8.0842 0H9.96361C10.0684 0 10.1533 0.0849412 10.1533 0.189694V1.89656C10.1533 2.00134 10.0684 2.08628 9.96364 2.08628Z" fill="url(#paint29_linear_6199_15806)"/>
      <path d="M7.89453 1.00659V1.8965C7.89453 2.00127 7.97947 2.08619 8.08423 2.08619H9.96364C10.0684 2.08619 10.1533 2.00125 10.1533 1.8965V1.00659H7.89453Z" fill="url(#paint30_linear_6199_15806)"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_6199_15806" x1="4.40188" y1="4.90521" x2="15.0488" y2="15.5521" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EAF6FF"/>
    <stop offset="0.2575" stopColor="#DCE8FA"/>
    <stop offset="0.7423" stopColor="#B8C4ED"/>
    <stop offset="1" stopColor="#A2AEE6"/>
    </linearGradient>
    <linearGradient id="paint1_linear_6199_15806" x1="5.99999" y1="1.92946" x2="5.99999" y2="5.10663" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FD4755"/>
    <stop offset="0.1913" stopColor="#F64452"/>
    <stop offset="0.4736" stopColor="#E23C4B"/>
    <stop offset="0.8106" stopColor="#C22E40"/>
    <stop offset="1" stopColor="#AC2538"/>
    </linearGradient>
    <linearGradient id="paint2_linear_6199_15806" x1="5.13733" y1="2.52548" x2="5.13733" y2="0.743368" gradientUnits="userSpaceOnUse">
    <stop stopColor="#972437" stopOpacity="0"/>
    <stop offset="1" stopColor="#972437"/>
    </linearGradient>
    <linearGradient id="paint3_linear_6199_15806" x1="10.5876" y1="2.52548" x2="10.5876" y2="0.743368" gradientUnits="userSpaceOnUse">
    <stop stopColor="#972437" stopOpacity="0"/>
    <stop offset="1" stopColor="#972437"/>
    </linearGradient>
    <linearGradient id="paint4_linear_6199_15806" x1="1.65006" y1="4.99088" x2="3.14959" y2="6.49041" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EAF6FF"/>
    <stop offset="0.2575" stopColor="#DCE8FA"/>
    <stop offset="0.7423" stopColor="#B8C4ED"/>
    <stop offset="1" stopColor="#A2AEE6"/>
    </linearGradient>
    <linearGradient id="paint5_linear_6199_15806" x1="2.32961" y1="5.9148" x2="2.32961" y2="6.75154" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8795DE" stopOpacity="0"/>
    <stop offset="0.4678" stopColor="#7D8DDC" stopOpacity="0.468"/>
    <stop offset="1" stopColor="#6C7FD8"/>
    </linearGradient>
    <linearGradient id="paint6_linear_6199_15806" x1="4.09732" y1="4.99088" x2="5.59686" y2="6.4904" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EAF6FF"/>
    <stop offset="0.2575" stopColor="#DCE8FA"/>
    <stop offset="0.7423" stopColor="#B8C4ED"/>
    <stop offset="1" stopColor="#A2AEE6"/>
    </linearGradient>
    <linearGradient id="paint7_linear_6199_15806" x1="4.77688" y1="5.9148" x2="4.77688" y2="6.75154" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8795DE" stopOpacity="0"/>
    <stop offset="0.4678" stopColor="#7D8DDC" stopOpacity="0.468"/>
    <stop offset="1" stopColor="#6C7FD8"/>
    </linearGradient>
    <linearGradient id="paint8_linear_6199_15806" x1="6.54459" y1="4.99088" x2="8.04412" y2="6.49041" gradientUnits="userSpaceOnUse">
    <stop stopColor="#A2E62E"/>
    <stop offset="1" stopColor="#02905D"/>
    </linearGradient>
    <linearGradient id="paint9_linear_6199_15806" x1="7.22414" y1="5.9148" x2="7.22414" y2="6.75154" gradientUnits="userSpaceOnUse">
    <stop stopColor="#02905D" stopOpacity="0"/>
    <stop offset="0.4554" stopColor="#028A58" stopOpacity="0.455"/>
    <stop offset="1" stopColor="#017B4E"/>
    </linearGradient>
    <linearGradient id="paint10_linear_6199_15806" x1="8.99186" y1="4.99088" x2="10.4914" y2="6.49041" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FD4755"/>
    <stop offset="0.1913" stopColor="#F64452"/>
    <stop offset="0.4736" stopColor="#E23C4B"/>
    <stop offset="0.8106" stopColor="#C22E40"/>
    <stop offset="1" stopColor="#AC2538"/>
    </linearGradient>
    <linearGradient id="paint11_linear_6199_15806" x1="9.67141" y1="5.9148" x2="9.67141" y2="6.75154" gradientUnits="userSpaceOnUse">
    <stop stopColor="#972437" stopOpacity="0"/>
    <stop offset="1" stopColor="#972437"/>
    </linearGradient>
    <linearGradient id="paint12_linear_6199_15806" x1="1.65006" y1="7.35562" x2="3.14958" y2="8.85515" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EAF6FF"/>
    <stop offset="0.2575" stopColor="#DCE8FA"/>
    <stop offset="0.7423" stopColor="#B8C4ED"/>
    <stop offset="1" stopColor="#A2AEE6"/>
    </linearGradient>
    <linearGradient id="paint13_linear_6199_15806" x1="2.32961" y1="8.27954" x2="2.32961" y2="9.11627" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8795DE" stopOpacity="0"/>
    <stop offset="0.4678" stopColor="#7D8DDC" stopOpacity="0.468"/>
    <stop offset="1" stopColor="#6C7FD8"/>
    </linearGradient>
    <linearGradient id="paint14_linear_6199_15806" x1="4.09732" y1="7.35562" x2="5.59685" y2="8.85515" gradientUnits="userSpaceOnUse">
    <stop stopColor="#A2E62E"/>
    <stop offset="1" stopColor="#02905D"/>
    </linearGradient>
    <linearGradient id="paint15_linear_6199_15806" x1="4.77688" y1="8.27954" x2="4.77688" y2="9.11627" gradientUnits="userSpaceOnUse">
    <stop stopColor="#02905D" stopOpacity="0"/>
    <stop offset="0.4554" stopColor="#028A58" stopOpacity="0.455"/>
    <stop offset="1" stopColor="#017B4E"/>
    </linearGradient>
    <linearGradient id="paint16_linear_6199_15806" x1="6.54459" y1="7.35562" x2="8.04411" y2="8.85515" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EAF6FF"/>
    <stop offset="0.2575" stopColor="#DCE8FA"/>
    <stop offset="0.7423" stopColor="#B8C4ED"/>
    <stop offset="1" stopColor="#A2AEE6"/>
    </linearGradient>
    <linearGradient id="paint17_linear_6199_15806" x1="7.22414" y1="8.27954" x2="7.22414" y2="9.11627" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8795DE" stopOpacity="0"/>
    <stop offset="0.4678" stopColor="#7D8DDC" stopOpacity="0.468"/>
    <stop offset="1" stopColor="#6C7FD8"/>
    </linearGradient>
    <linearGradient id="paint18_linear_6199_15806" x1="8.99186" y1="7.35562" x2="10.4914" y2="8.85515" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FD4755"/>
    <stop offset="0.1913" stopColor="#F64452"/>
    <stop offset="0.4736" stopColor="#E23C4B"/>
    <stop offset="0.8106" stopColor="#C22E40"/>
    <stop offset="1" stopColor="#AC2538"/>
    </linearGradient>
    <linearGradient id="paint19_linear_6199_15806" x1="9.67141" y1="8.27954" x2="9.67141" y2="9.11627" gradientUnits="userSpaceOnUse">
    <stop stopColor="#972437" stopOpacity="0"/>
    <stop offset="1" stopColor="#972437"/>
    </linearGradient>
    <linearGradient id="paint20_linear_6199_15806" x1="1.65006" y1="9.72037" x2="3.14958" y2="11.2199" gradientUnits="userSpaceOnUse">
    <stop stopColor="#A2E62E"/>
    <stop offset="1" stopColor="#02905D"/>
    </linearGradient>
    <linearGradient id="paint21_linear_6199_15806" x1="2.32961" y1="10.6443" x2="2.32961" y2="11.481" gradientUnits="userSpaceOnUse">
    <stop stopColor="#02905D" stopOpacity="0"/>
    <stop offset="0.4554" stopColor="#028A58" stopOpacity="0.455"/>
    <stop offset="1" stopColor="#017B4E"/>
    </linearGradient>
    <linearGradient id="paint22_linear_6199_15806" x1="4.09732" y1="9.72037" x2="5.59685" y2="11.2199" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EAF6FF"/>
    <stop offset="0.2575" stopColor="#DCE8FA"/>
    <stop offset="0.7423" stopColor="#B8C4ED"/>
    <stop offset="1" stopColor="#A2AEE6"/>
    </linearGradient>
    <linearGradient id="paint23_linear_6199_15806" x1="4.77688" y1="10.6443" x2="4.77688" y2="11.481" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8795DE" stopOpacity="0"/>
    <stop offset="0.4678" stopColor="#7D8DDC" stopOpacity="0.468"/>
    <stop offset="1" stopColor="#6C7FD8"/>
    </linearGradient>
    <linearGradient id="paint24_linear_6199_15806" x1="6.54459" y1="9.72037" x2="8.04412" y2="11.2199" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EAF6FF"/>
    <stop offset="0.2575" stopColor="#DCE8FA"/>
    <stop offset="0.7423" stopColor="#B8C4ED"/>
    <stop offset="1" stopColor="#A2AEE6"/>
    </linearGradient>
    <linearGradient id="paint25_linear_6199_15806" x1="7.22414" y1="10.6443" x2="7.22414" y2="11.481" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8795DE" stopOpacity="0"/>
    <stop offset="0.4678" stopColor="#7D8DDC" stopOpacity="0.468"/>
    <stop offset="1" stopColor="#6C7FD8"/>
    </linearGradient>
    <linearGradient id="paint26_linear_6199_15806" x1="5.99999" y1="10.4517" x2="5.99999" y2="12.8526" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8795DE" stopOpacity="0"/>
    <stop offset="0.4678" stopColor="#7D8DDC" stopOpacity="0.468"/>
    <stop offset="1" stopColor="#6C7FD8"/>
    </linearGradient>
    <linearGradient id="paint27_linear_6199_15806" x1="2.29276" y1="0.358824" x2="4.35417" y2="2.42024" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FEE45A"/>
    <stop offset="1" stopColor="#FE860A"/>
    </linearGradient>
    <linearGradient id="paint28_linear_6199_15806" x1="2.97707" y1="1.52932" x2="2.97707" y2="2.1765" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FE860A" stopOpacity="0"/>
    <stop offset="1" stopColor="#FE7701"/>
    </linearGradient>
    <linearGradient id="paint29_linear_6199_15806" x1="8.33961" y1="0.358824" x2="10.401" y2="2.42024" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FEE45A"/>
    <stop offset="1" stopColor="#FE860A"/>
    </linearGradient>
    <linearGradient id="paint30_linear_6199_15806" x1="9.02395" y1="1.52932" x2="9.02395" y2="2.1765" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FE860A" stopOpacity="0"/>
    <stop offset="1" stopColor="#FE7701"/>
    </linearGradient>
    <clipPath id="clip0_6199_15806">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default IconYearBuilt;
