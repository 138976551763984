import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { SimpleButton } from "components/Controls/Buttons"
import { CustomModal } from "components/Modals";
import { IconArrowRight, IconClose } from "components/Svgs";
import { SIGN_UP } from "constants/paths";
import { emptyFavoritesNonAuth } from "images";
import "./styles.scss";

const FavoritesForNonAuthModal = props => {
  const { isVisible, onClose } = props;
  const history = useHistory();

  const signUp = () => history.push(SIGN_UP);

  return(
    <CustomModal
      customClassName="favorites-modal"
      width="33%"
      isVisible={isVisible}
    >
      <SimpleButton customClass="favorites-modal__close-btn" onClick={onClose}>
        <IconClose />
      </SimpleButton>
      <div className="favorites-modal__body">
        <div className="favorites-modal__body__image">
          <img
            src={emptyFavoritesNonAuth} alt="non-auth-favorites-image"
            width={378}
            height={290}
          />
        </div>
        <div className="favorites-modal__body__title">
          <FormattedMessage id="modal.favorites.title" />
        </div>
        <div className="favorites-modal__body__description">
          <FormattedMessage id="modal.favorites.description" />
        </div>
        {/*<div className="favorites-modal__body__redirect-btn">*/}
          <SimpleButton customClass="favorites-modal__body__redirect-btn" onClick={signUp}>
            <FormattedMessage id="modal.favorites.button" />
            <IconArrowRight />
          </SimpleButton>
        {/*</div>*/}
      </div>
    </CustomModal>
  );
};

FavoritesForNonAuthModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FavoritesForNonAuthModal;
