import React from "react";
import { Table } from "antd";
import classNames from "classnames";
import "./styles.scss";

const CustomTable = (props) => {
  const { className, ...otherProps } = props;

  return (
    <div className={classNames("custom-table-container", className)}>
      <Table className="table_custom" {...otherProps} />
    </div>
  );
};

export default CustomTable;
