import React from "react";
import PropTypes from "prop-types";
import { InfoWindow } from "@react-google-maps/api";
import classNames from "classnames";
import _, { get as lodashGet } from "lodash";
import { FormattedMessage } from "react-intl";
import ImageWithPreview from "components/DataDisplay/ImageWithPreview";
import { DaysOnHiHome } from "components/DataDisplay";
import { getValueClassnameByScoreRangeType } from "utils/helpers/housesHelper/common";
import { nFormatter, toLocalePrice } from "utils/helpers/housesHelper/formatter";
import { getClassnameForStatus, getFullAddress, getStatusValue } from "utils/helpers/housesHelper/getter";
import "./styles.scss";

const UnitsInfoWindow = props => {
  const { activeUnitsData, viewedHouseList, setViewedHouseList, handleOnClickHouse } = props;

  const getSquareFootageValue = houseData => {
    const buildingSizeValue = lodashGet(houseData, 'building_size.value');
    const buildingSizeClassName = getValueClassnameByScoreRangeType(
      lodashGet(houseData, 'building_size.score'),
      buildingSizeValue
    );
    return (
      <div className={classNames("criteria-tag", buildingSizeClassName)}>
        {_.isNil(buildingSizeValue) ? (
          <FormattedMessage id="general.noData" />
        ) : (
          <>
            {nFormatter(buildingSizeValue, 1, false)}
            &nbsp;
            <span style={{ textTransform: "capitalize" }}>
              {lodashGet(houseData, 'building_size.units')}
            </span>
          </>
        )}
      </div>
    );
  };

  const renderAddress = houseData => (
    <div
      className={classNames("house-item__address", { isViewed: viewedHouseList.includes(houseData.id) })}
      onClick={() => handleOnClickHouse(houseData.id, viewedHouseList, setViewedHouseList)}
    >
      {getFullAddress(houseData.address)}
    </div>
  );

  const renderCriteria = houseData => {
    const bathroomsPillClassName = getValueClassnameByScoreRangeType(
      lodashGet(houseData, 'bathrooms.score'),
      lodashGet(houseData, 'bathrooms.value')
    );
    const bedroomsPillClassName = getValueClassnameByScoreRangeType(
      lodashGet(houseData, 'bedrooms.score'),
      lodashGet(houseData, 'bedrooms.value')
    );
    return (
      <div className="house-item__criteria-tags">
        <div className={classNames("criteria-tag", bedroomsPillClassName)}>
          <span>{lodashGet(houseData, "bedrooms.value")}</span>
          &nbsp;
          <FormattedMessage id="houses.card.beds" />
        </div>
        <div className={classNames("criteria-tag", bathroomsPillClassName)}>
          <span>{lodashGet(houseData, "bathrooms.value")}</span>
          &nbsp;
          <FormattedMessage id="houses.card.bath" />
        </div>
        {getSquareFootageValue(houseData)}
      </div>
    );
};

  const renderPrice = houseData => {
    const priceClassName = getValueClassnameByScoreRangeType(
      lodashGet(houseData, 'price.score'),
      lodashGet(houseData, 'price.value')
    );
    return (
      <div className={classNames("criteria-price-tag", priceClassName)}>
        {toLocalePrice(lodashGet(houseData, 'price.value'))}
      </div>
    );
  };

  return (
    <InfoWindow position={{ lat: activeUnitsData.lat, lng: activeUnitsData.lng }}>
      <div className="units-info-window">
        {activeUnitsData.data.map(item => (
          <>
            <div className="house-item" key={`units_info_window_item_${item.id}`}>
              <div className="house-item__general">
                <div>
                  <ImageWithPreview data={item} height={52} width={80} withRadius />
                </div>
                <div className="house-item__general__info">
                  {renderPrice(item)}
                  <div className="house-item__general__info__statuses">
                    <div className={classNames("status", getClassnameForStatus(item.listing_status))}>
                      {getStatusValue(item)}
                    </div>
                    <DaysOnHiHome propertyItem={item} />
                  </div>
                </div>
              </div>
              {renderAddress(item)}
              {renderCriteria(item)}
            </div>
            <div className="divider" />
          </>
        ))}
      </div>
    </InfoWindow>
  );
};

UnitsInfoWindow.propTypes = {
  activeUnitsData: PropTypes.object,
  viewedHouseList: PropTypes.array,
  handleOnClickHouse: PropTypes.func,
  setViewedHouseList: PropTypes.func,
};

export default UnitsInfoWindow;
