import React from "react";
import Slider from "@mui/material/Slider";
import classNames from "classnames";
import { normalizeNumber } from "utils/helpers/criteriaHelper";
import "../styles.scss";

const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const CustomSlider = props => {
  const { invertedSlider, max, withPadding } = props;
  return (
    <div className={classNames("custom-slider-wrapper", { "with-padding": withPadding, "inverted-slider": invertedSlider })}>
      <Slider
        track="normal"
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => value >= max ? `${numberWithCommas(max)}+` : normalizeNumber(value.toString())}
        {...props}
      />
    </div>
  );
};

export default CustomSlider;
