import React from "react";
import { OverlayView } from "@react-google-maps/api";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import _, {
  get as lodashGet,
} from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { HOUSES_PER_PAGE } from "constants/houses";
import {
  getClassnameForScore,
  getClassnameForScoreViewed,
  getImageByScore,
  getViewedDotImageByScore
} from "utils/helpers/housesHelper/common";
import { priceFormatter, toLocalePrice} from "utils/helpers/housesHelper/formatter";
import "./styles.scss";

const MapMarkers = observer((
  {
    housesStore,
    viewedHouseList,
    zoom,
    handleOnClickHouse,
    onUnitsMarkerClick,
    setViewedHouseList
  }
) => {
  const housesData = housesStore.retrieveHousesData();
  const endPageIndex = housesStore.currentPage * HOUSES_PER_PAGE;

  const collectHousesByLocation = houseList => {
    // Make the list of objects with the info of coordinates, number of houses for the coordinates and houses data
    let dataByLocation = [];
    houseList.forEach(house => {
      const coordinates = {
        lat: formatCoordinate(house.address.location.lat),
        lng: formatCoordinate(house.address.location.lon)
      };
      const existingItemIndex = dataByLocation.findIndex(item => (
        item.lat === coordinates.lat && item.lng === coordinates.lng
      ));
      if (existingItemIndex === -1) {
        const itemByLocation = { ...coordinates, numberOfHouses: 1, data: [house] };
        dataByLocation.push(itemByLocation);
      } else {
        const existingItem = dataByLocation[existingItemIndex];
        existingItem.numberOfHouses += 1;
        existingItem.data.push(house);
      }
    });
    return dataByLocation;
  };

  const formatCoordinate = value => parseFloat(value.toFixed(4));

  const getClassNameByScore = houseData => {
    const classNameMethod = viewedHouseList.includes(houseData.id) ? getClassnameForScoreViewed : getClassnameForScore;
    return classNameMethod(houseData.total_score);
  };

  const getDotImage = houseData => {
    const method = viewedHouseList.includes(houseData.id) ? getViewedDotImageByScore : getImageByScore;
    return method(houseData.total_score);
  };

  const getHouseCoordinates = item => ({
    lat: lodashGet(item, "address.location.lat"),
    lng: lodashGet(item, "address.location.lon"),
  });

  const getHousePrice = item => toLocalePrice(priceFormatter(item.price.value));

  const getSameLocationsHouses = dataByLocation => {
    // Filter the list of objects and leave only objects with 2+ numberOfHouses
    let housesIndexes = [];
    const sameLocationsHousesList = dataByLocation.filter(item => {
      if (item.numberOfHouses > 1) {
        const combinedHousesIndexes = item.data.map(houseData => houseData.id);
        housesIndexes = _.union(housesIndexes, combinedHousesIndexes);
        return true;
      }
      return false;
    });
    return { data: sameLocationsHousesList, houseIds: housesIndexes };
  };

  const getCombinedByLocationHouses = houseList => {
    // Combine houses by location coordinates to display them as one dot on the map
    let dataByLocation = collectHousesByLocation(houseList);
    const sameLocationsData = getSameLocationsHouses(dataByLocation);
    const sameLocationsHousesIds = sameLocationsData.houseIds;

    // remove houses with the same coordinates from the current page house list
    const currentPageHouseList = houseList.slice(endPageIndex - HOUSES_PER_PAGE, endPageIndex);
    const newCurrentPageHouseList = currentPageHouseList.filter(house => !sameLocationsHousesIds.includes(house.id));

    // remove houses with the same coordinates from the other pages house list
    const housesFromPreviousPages = housesData.data.slice(0, endPageIndex - HOUSES_PER_PAGE);
    const housesFromNextPages = housesData.data.slice(endPageIndex, housesData.data.length);
    const otherPagesHouseList = _.union(housesFromPreviousPages, housesFromNextPages);
    const newOtherPagesHouseList = otherPagesHouseList.filter(house => !sameLocationsHousesIds.includes(house.id));
    return {
      combinedByLocationHouses: sameLocationsData.data,
      currentPageHouseList: newCurrentPageHouseList,
      otherPagesHouseList: newOtherPagesHouseList
    };
  };

  const renderCurrentPageHouses = currentPageHouses => currentPageHouses.map(item =>
    <OverlayView
      position={getHouseCoordinates(item)}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        id={`marker${item.id}`}
        className="current-page-marker"
        style={{ zIndex: item.total_score + 100 }}
        onClick={() => handleOnClickHouse(item.id, viewedHouseList, setViewedHouseList)}
        onMouseEnter={() => housesStore.setHoveredItem(item)}
        onMouseLeave={() => housesStore.setHoveredItem(null)}
      >
        <div className="current-page-marker__label" id={`marker_label_${item.id}`}>
          {getHousePrice(item)}
        </div>
        <div className={classNames("current-page-marker__dot", getClassNameByScore(item))}>
          {item.total_score}
        </div>
      </div>
    </OverlayView>
  );

  const renderMarker = (houseData) => (
    <OverlayView
      position={getHouseCoordinates(houseData)}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        className="house-marker"
        id={`marker${houseData.id}`}
        style={{ zIndex: houseData.total_score }}
        onClick={() => handleOnClickHouse(houseData.id, viewedHouseList, setViewedHouseList)}
        onMouseEnter={() => housesStore.setHoveredItem(houseData)}
        onMouseLeave={() => housesStore.setHoveredItem(null)}
      >
        {zoom > 11 && <div className="house-marker__label">
          {getHousePrice(houseData)}
        </div>}
        <div className="house-marker__dot">
          <img src={getDotImage(houseData)} alt={`house_${houseData.id}`} />
        </div>
      </div>
    </OverlayView>
  );

  const renderCombinedByLocationMarker = housesData => {
    const maxHousesScore = Math.max(...housesData.data.map(house => house.total_score));
    const housesId = housesData.data.map(item => item.id).join('_');
    return (
      <OverlayView
        position={{ lat: housesData.lat, lng: housesData.lng }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div
          className="house-marker"
          id={`marker${housesId}`}
          style={{ zIndex: maxHousesScore }}
          onClick={() => onUnitsMarkerClick(housesData)}
        >
          <div className="house-marker__label">
            <FormattedMessage id="houses.map.unitsNumber" values={{ value: housesData.numberOfHouses }} />
          </div>
          <div className="house-marker__dot">
            <img src={getImageByScore(maxHousesScore)} alt={`houses_${housesId}`} />
          </div>
        </div>
      </OverlayView>
    );
  };

  if (_.isEmpty(housesData.data)) {
    return null;
  }

  const markersData = getCombinedByLocationHouses(housesData.data);
  return (
    <>
      {renderCurrentPageHouses(markersData.currentPageHouseList)}
      {markersData.otherPagesHouseList.map(houseItem => renderMarker(houseItem))}
      {markersData.combinedByLocationHouses.map(housesData => renderCombinedByLocationMarker(housesData))}
    </>
  );
});

MapMarkers.propTypes = {
  viewedHouseList: PropTypes.array,
  zoom: PropTypes.number,
  handleOnClickHouse: PropTypes.func,
  onUnitsMarkerClick: PropTypes.func,
  setViewedHouseList: PropTypes.func
};

export default inject("housesStore")(MapMarkers);
