import React from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE, translationMessages } from '../../i18n';

const messages = translationMessages[DEFAULT_LOCALE];

function LanguageProvider(props) {
  return (
    <IntlProvider
      locale={DEFAULT_LOCALE}
      key={DEFAULT_LOCALE}
      messages={messages}
    >
      {React.Children.only(props.children)}
    </IntlProvider>
  );
}

export default LanguageProvider;
