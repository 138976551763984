import React from "react";
import { Slider } from "antd";
import { FormattedMessage } from "react-intl";
import { forEach as lodashForEach } from "lodash";
import { IconPriority } from "components/Svgs";
import { PRIORITY_MESSAGES } from "containers/Dashboard/constants"
import "../styles.scss";


const PriorityField = ({ value = {}, onChange, description }) => {
  const marks = {};
  lodashForEach(PRIORITY_MESSAGES, (value, key) => marks[key] = <FormattedMessage id={value} /> );

  return (
    <div className="priority-field">
      <h2 className="priority-field__title filters-title fs-16">
        <div className="filters-icon">
          <IconPriority />
        </div>
        <FormattedMessage id="dashboard.form.priorityPriceTitle" />
      </h2>
      <div className="priority-field__description">{description}</div>
      <Slider
        marks={marks}
        step={1}
        onChange={onChange}
        defaultValue={value}
        tooltipVisible={false}
        className="priority-field__slider"
        min={0}
        max={5}
      />
    </div>
  );
}

export default PriorityField;
