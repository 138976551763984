import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "antd";
import { SimpleButton } from "components/Controls/Buttons";
import classNames from "classnames";
import "../styles.scss";
import {IconClose} from "../../Svgs";
import {MaskedInput} from "../../Controls/Inputs";
import {PHONE_MASK, PHONE_MASK_PLACEHOLDER_CHAR} from "../../Forms/constants";

const PhoneInputModal = (props) => {
  const { modalVisible, handleModalVisible, handleSubmit } = props;
  const [enteredPhone, setEnteredPhone] = useState('');

  const onClickOk = () => {
    handleSubmit({
      phone: enteredPhone,
      notifying_allowed: true,
    })
    handleModalVisible();
  };

  const onPhoneChange = e => {
    setEnteredPhone(`+1${e.unmaskedValue}`);
  }

  return (
    <Modal
      className="general-modal"
      visible={modalVisible}
      footer={null}
      width={510}
      onCancel={handleModalVisible}
      closable={false}
    >
      <div className="general-modal-container">
        <div className="general-modal-close">
          <SimpleButton customClass="modal-close" onClick={handleModalVisible}>
            <IconClose />
          </SimpleButton>
        </div>
        <div className={classNames("general-content", "general-content__centered")}>
          <div className="general-content__title">
            <FormattedMessage id="modal.getInstantUpdates" />
          </div>
          <div className="general-content__description">
            <FormattedMessage id="modal.getInstantUpdatesDescription" />
          </div>
          <div className="profile-form-columns__phone">
            <div className="form-input">
              <div className="form-input__message">
                <FormattedMessage id="profile.form.phoneNumber" />
              </div>
              <MaskedInput
                onChange={onPhoneChange}
                maskOptions={{
                  placeholderChar: PHONE_MASK_PLACEHOLDER_CHAR,
                  mask: PHONE_MASK
                }}
              />
            </div>
          </div>
          <SimpleButton disabled={enteredPhone.length < 12}onClick={onClickOk} customClass="btn-default">
            <FormattedMessage id="modal.getInstantUpdates" />
          </SimpleButton>
        </div>
      </div>
    </Modal>
  );
};

export default PhoneInputModal;
