import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import _, {
  get as lodashGet,
  map as lodashMap,
} from "lodash";
import classNames from "classnames";
import { getColorForScore } from "utils/helpers/housesHelper/common";
import { getAmountOfDaysOnHiHome, getHighestCriteria } from "utils/helpers/housesHelper/getter";
import HouseCard from "components/DataDisplay/HouseCard";
import CustomPagination from "components/Controls/CustomPagination";
import { TOKEN_KEY } from "constants/localStorage";
import {HOUSE_VIEWS, HOUSES_PER_PAGE} from "constants/houses";
import { NoResultsView } from "containers/Houses/Views";
import { getCurrentPageNumber } from "utils/helpers/favoritesHelper";
import "./styles.scss";

const SORT_ORDER = {
  asc: "asc",
  desc: "desc",
};

const HouseList = observer((
  {
    activeTab,
    criteriaStore,
    housesStore,
    view,
    viewedHouseIds,
    handleFavorites,
    handleOnClickHouse,
    handleNonAuthFavorites,
  }
) => {
  const [scoreSortOrder, setScoreSortOrder] = useState(SORT_ORDER.desc);
  const [recentlyAddedSortOrder, setRecentlyAddedSortOrder] = useState(SORT_ORDER.desc);
  const [isActive, setIsActive] = useState(false);

  const housesData = housesStore.retrieveHousesData();
  const criteriaData = criteriaStore.retrieveCriteriaData();
  const highestCriteria = getHighestCriteria(housesData);
  const endPageIndex = housesStore.currentPage * HOUSES_PER_PAGE;

  useEffect(() => {
    setIsActive(false);
  }, [housesStore.currentPage]);

  const checkIsActive = key => {
    const sortOrder = key === "score" ? scoreSortOrder : recentlyAddedSortOrder;
    return sortOrder === SORT_ORDER.asc;
  };

  const onSortByScore = () => {
    const newSortOrder = scoreSortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
    setScoreSortOrder(newSortOrder);
    const sortedData = housesData.data.sort((item1, item2) => (
      newSortOrder === SORT_ORDER.asc ? item1.total_score - item2.total_score : item2.total_score - item1.total_score
    ));
    housesStore.setHousesData(sortedData);
  };

  const onSortByRecentlyAdded = () => {
    const newSortOrder = recentlyAddedSortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
    setRecentlyAddedSortOrder(newSortOrder);
    const sortedData = housesData.data.sort((item1, item2) => {
      const daysOnHiHomeFirstItem = getAmountOfDaysOnHiHome(item1);
      const daysOnHiHomeSecondItem = getAmountOfDaysOnHiHome(item2);
      if (newSortOrder === SORT_ORDER.asc) {
        return daysOnHiHomeFirstItem - daysOnHiHomeSecondItem;
      }
      return daysOnHiHomeSecondItem -  daysOnHiHomeFirstItem;
    });
    housesStore.setHousesData(sortedData);
  };

  const onMouseHoverIcon = (item) => {
    const marker = document.getElementById(`marker${item.id}`);
    if (marker) {
      marker.style.zIndex = "201";
      const markerLabel = document.getElementById(`marker_label_${item.id}`);
      markerLabel.style.borderColor = getColorForScore(item.total_score);
      housesStore.setHoveredItem(item);
    }
  }

  const onMouseLeaveIcon = (item) => {
    const marker = document.getElementById(`marker${item.id}`);
    if (marker) {
      marker.style.zIndex = item.total_score + 100;
      const markerLabel = document.getElementById(`marker_label_${item.id}`);
      markerLabel.style.borderColor = "#f4f7fc";
      housesStore.setHoveredItem(null);
    }
  }

  const onClickFavorites = item => {
    onMouseLeaveIcon(item);
    const handleSuccessUpdate = isLastItemOnPage => {
      const housesData = housesStore.retrieveHousesData();
      housesStore.currentPage = getCurrentPageNumber(housesStore.currentPage, housesData, isLastItemOnPage);
      // if (_.isEmpty(housesData.data)) {
      //   checkResultsExistence(false);
      // }
    }
    if (!localStorage.getItem(TOKEN_KEY)) {
      handleNonAuthFavorites();
    } else {
      handleFavorites(item.id, housesStore, handleSuccessUpdate);
    }
  };

  const renderSortButtons = () => {
    const sortButtonsData = [
      { key: "score", messageId: "houses.card.score", onClick: onSortByScore },
      { key: "recently_added", messageId: "houses.card.recentlyAdded", onClick: onSortByRecentlyAdded },
    ];
    return (
      <div className={classNames("criteria-sorting", { isFavorites: view === HOUSE_VIEWS.favorites })}>
        {sortButtonsData.map(item => {
          const isActive = checkIsActive(item.key);
          return (
            <div className="list-house-header__sorting" onClick={item.onClick}>
              <FormattedMessage id={item.messageId}/>
              <div
                className={classNames(
                  "list-house-header__sorting-icon",
                  {"is-active": isActive}
                )}
              >
                <KeyboardArrowDownIcon/>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  if (_.get(housesData, "total_results") === 0 || _.isEmpty(housesData)) {
    return (
      <NoResultsView activeTab={activeTab} isLoading={housesStore.loading} view={view} />
    );
  }

  return (
    <div className="list-house-container">
      {renderSortButtons()}
      <div className="list-house">
        {lodashMap(lodashGet(housesData, 'data', []).slice(endPageIndex - HOUSES_PER_PAGE, endPageIndex), item => (
          <div
            className="list-house__card"
            onMouseEnter={() => onMouseHoverIcon(item)}
            onMouseLeave={() => onMouseLeaveIcon(item)}
          >
            <HouseCard
              criteriaData={criteriaData}
              houseData={item}
              isActive={isActive}
              setIsActive={setIsActive}
              handleFavorites={() => onClickFavorites(item)}
              handleOnClickHouse={handleOnClickHouse}
              highestCriteria={highestCriteria}
              activeFavorite={item.is_favorite}
              viewedHouseIds={viewedHouseIds}
            />
          </div>))}
      </div>
      <div className="list-house-pagination">
        <CustomPagination
          pageSize={HOUSES_PER_PAGE}
          total={housesData.data && housesData.data.length}
          current={housesStore.currentPage}
          onChange={page => housesStore.setCurrentPage(page)}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
})

export default inject("criteriaStore", "housesStore")(HouseList);
