import React, {useEffect, useState} from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SimpleButton } from "components/Controls/Buttons";
import { MultipleSearchLocationField } from "components/Fields";
import { IconSearch } from "components/Svgs";
import "./styles.scss";

const FiltersBarSearchLocationForm = props => {
  const { customClassName, initialData, onSubmitForm } = props;
  const [form] = Form.useForm();
  const [locationsNumber, setLocationsNumber] = useState();
  const initialValues = { locations: initialData };

  useEffect(() => {
    setLocationsNumber(initialData.length);
  }, []);

  const onValuesChange = formValues => {
    setLocationsNumber(formValues.locations.length);
  };

  const onFinish = values => onSubmitForm(values.locations);

  return (
    <Form
      className={classNames("locations-search-form", customClassName)}
      form={form}
      initialValues={initialValues}
      name="FiltersBarSearchLocationForm"
      onFinish={onFinish}
    >
      <Form.Item name="locations">
        <MultipleSearchLocationField
          dropdownClassName="locations-search-form__dropdown"
          form={form}
          placeholder={<FormattedMessage id="houses.filtersBar.enterLocations" />}
          onValuesChange={onValuesChange}
        />
      </Form.Item>
      <SimpleButton
        customClass="search-button"
        disabled={locationsNumber === 0}
        role="button"
        htmlType="submit"
      >
        <IconSearch />
      </SimpleButton>
    </Form>
  );
};

FiltersBarSearchLocationForm.propTypes = {
  initialData: PropTypes.array,
  onSubmitForm: PropTypes.func,
};

export default FiltersBarSearchLocationForm;
