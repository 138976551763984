import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import {
  DashboardPage,
  FavoritesPage,
  EmailConfirmPage,
  HouseDetailsPage,
  HousesPage,
  LogInPage,
  NotFoundPage,
  OnboardingPage,
  ProfilePage,
  SignUpPage,
} from 'pages';
import {
  DASHBOARD_PATH,
  FAVORITES,
  EMAIL_CONFIRM,
  HOUSE_PATH,
  LOGIN_PATH,
  NOT_FOUND_PATH,
  ONBOARDING_PATH,
  PROFILE,
  SIGN_UP,
  TOP_MATCHES,
} from 'constants/paths'
import { updateIntercomWidget, initializeGTM } from "utils/helpers/userHelper";
import AnonymousRoute from "./AnonymousRoute";
// import 'antd/dist/antd.css';


const App = () => {
  useEffect(() => {
    // when the page is reloaded or opened new one we start the widget
    window.onload = function () {
      updateIntercomWidget();
      initializeGTM();
    };
  });

  return (
    <>
      <Helmet titleTemplate="HiHome - %s" />
      <BrowserRouter>
        <Switch>
          <ProtectedRoute exact path={DASHBOARD_PATH} component={DashboardPage} />
          <ProtectedRoute exact path={FAVORITES} component={FavoritesPage} />
          <ProtectedRoute exact path={PROFILE} component={ProfilePage} />
          <ProtectedRoute exact path={TOP_MATCHES} component={HousesPage} />
          <AnonymousRoute exact path={HOUSE_PATH} component={HouseDetailsPage} />
          <AnonymousRoute path={EMAIL_CONFIRM} component={EmailConfirmPage} />
          <AnonymousRoute path={LOGIN_PATH} exact component={LogInPage} />
          <AnonymousRoute exact path={ONBOARDING_PATH} component={OnboardingPage} />
          <AnonymousRoute path={SIGN_UP} component={SignUpPage} />
          <AnonymousRoute exact path={NOT_FOUND_PATH} component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
